import { datadogLogs } from '@datadog/browser-logs';

import type { ApiAplId } from 'api/types/appliance/apiAplId';
import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';

export enum TrackedEventType {
  IncompatibilityStepSaved = 'incompatibility:step:saved',
  IncompatibilityStepResolved = 'incompatibility:step:resolved',
  IncompatibilityStepFixClicked = 'incompatibility:step:fix-clicked',
  IncompatibilityPublished = 'incompatibility:published',
  UnusedIngredientsPublished = 'unused-ingredients:published',
  NoIngredientsPublished = 'no-ingredients:published',
  NoStepsPublished = 'no-steps:published',
}

interface TrackedEventBase<TEventType extends TrackedEventType> {
  type: TEventType;
}

interface WithRecipeId {
  recipeId: ApiRcpRecipeId;
}

interface WithStepNumber {
  stepNumber: number;
}

interface WithCapabilityId {
  capabilityId: ApiEntityId;
}

interface WithApplianceIds {
  applianceIds: ApiAplId[];
}

export type TrackedEventIncompatibilityStepSaved =
  TrackedEventBase<TrackedEventType.IncompatibilityStepSaved> &
    Partial<WithRecipeId> &
    WithStepNumber &
    WithCapabilityId &
    WithApplianceIds;

export type TrackedEventIncompatibilityStepResolved =
  TrackedEventBase<TrackedEventType.IncompatibilityStepResolved> &
    Partial<WithRecipeId> &
    WithStepNumber &
    Partial<WithCapabilityId>;

export type TrackedEventIncompatibilityStepFixClicked =
  TrackedEventBase<TrackedEventType.IncompatibilityStepFixClicked> &
    Partial<WithRecipeId> &
    WithStepNumber &
    WithCapabilityId;

export type TrackedEventIncompatibilityPublished =
  TrackedEventBase<TrackedEventType.IncompatibilityPublished> & WithRecipeId;

export type TrackedEventUnusedIngredientsPublished =
  TrackedEventBase<TrackedEventType.UnusedIngredientsPublished> & WithRecipeId;

export type TrackedEventNoIngredientsPublished =
  TrackedEventBase<TrackedEventType.NoIngredientsPublished> & WithRecipeId;

export type TrackedEventNoStepsPublished =
  TrackedEventBase<TrackedEventType.NoStepsPublished> & WithRecipeId;

export type TrackedEvent =
  | TrackedEventIncompatibilityStepSaved
  | TrackedEventIncompatibilityStepResolved
  | TrackedEventIncompatibilityStepFixClicked
  | TrackedEventIncompatibilityPublished
  | TrackedEventUnusedIngredientsPublished
  | TrackedEventNoIngredientsPublished
  | TrackedEventNoStepsPublished;

export function trackEvent(payload: TrackedEvent) {
  datadogLogs.logger.info(payload.type, { extraDetails: payload });
}
