import { call, put, takeEvery } from 'redux-saga/effects';

import type { ApiRequestSagaReturnType } from 'api/createApiRequestSaga';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
import { apiGetHomeFeeds } from 'api/homeFeed';
import { errorOccurred } from 'features/error/errorSlice';
import { errorSliceConstants } from 'features/error/errorSlice.constants';
import {
  homeFeedsFetchFailed,
  homeFeedsFetchRequested,
  homeFeedsFetchSucceed,
} from 'features/homeFeed/homeFeedsSlice';

export const apiFetchHomeFeedsSaga = createApiRequestSaga(apiGetHomeFeeds);

export function* fetchHomeFeeds() {
  const fetchResponse = (yield call(
    apiFetchHomeFeedsSaga
  )) as ApiRequestSagaReturnType<typeof apiFetchHomeFeedsSaga>;

  if (!fetchResponse.ok) {
    yield put(homeFeedsFetchFailed(fetchResponse.details.message));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  yield put(homeFeedsFetchSucceed(fetchResponse.data.items));
}

function* homeFeedsFetchWatcher() {
  yield takeEvery(homeFeedsFetchRequested, fetchHomeFeeds);
}

export const homeFeedsRestartableSagas = [homeFeedsFetchWatcher];
