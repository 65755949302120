import { Skeleton, SkeletonVariant } from '@dropkitchen/pantry-react';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { recipeMediaStrings } from 'features/recipe/recipeBasicInformation/recipeMedia/RecipeMedia.constants';

const { ariaLabel } = recipeMediaStrings;

export const RecipeMediaSkeleton: FC = memo(function RecipeMediaSkeleton() {
  return (
    <Box
      aria-label={ariaLabel.skeleton}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ height: 240, width: '100%', borderRadius: 1 }}
      />
    </Box>
  );
});
