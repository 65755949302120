import { PantryColor } from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { useAppSelector } from 'app/store/hooks';
import { Card } from 'components/Card/Card';
import {
  selectRecipeLocale,
  selectRecipePrimaryUnitSystem,
} from 'features/recipe/recipeSlice';
import { RecipeIngredientDetails } from 'features/recipe/shared/RecipeIngredientDetails/RecipeIngredientDetails';
import { recipeIngredientDetailsStrings } from 'features/recipe/shared/RecipeIngredientDetails/RecipeIngredientDetails.constants';
import { RecipeIngredientIssue } from 'features/recipe/shared/RecipeIngredientIssue/RecipeIngredientIssue';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';

export enum RecipeReviewIngredientStyle {
  Default = 'default',
  Muted = 'muted',
}
interface RecipeReviewIngredientProps {
  ingredient: AppRecipeIngredient;
  index: number;
  recipeId?: ApiRcpRecipeId;
  style?: RecipeReviewIngredientStyle;
  sx?: SxProps;
}

export const RecipeReviewIngredient: FC<RecipeReviewIngredientProps> = memo(
  function RecipeReviewIngredient({
    ingredient,
    index,
    recipeId,
    style = RecipeReviewIngredientStyle.Default,
    sx,
  }) {
    const locale = useAppSelector(selectRecipeLocale);
    const primaryUnitSystem = useAppSelector(selectRecipePrimaryUnitSystem);

    const nameStyle: SxProps = (() => {
      if (style === RecipeReviewIngredientStyle.Muted) {
        return { color: PantryColor.TextMuted };
      }
      return { color: PantryColor.TextDefault };
    })();

    const quantityStyle: SxProps = (() => {
      if (style === RecipeReviewIngredientStyle.Muted) {
        return { color: PantryColor.TextMuted };
      }
      return { color: PantryColor.TextDefault };
    })();

    const preparationsStyle: SxProps = (() => {
      if (style === RecipeReviewIngredientStyle.Muted) {
        return { color: PantryColor.TextMuted };
      }
      return { color: PantryColor.TextSubtle };
    })();

    return (
      <Card
        sx={sx}
        topSectionChildren={
          <RecipeIngredientDetails
            ingredient={ingredient}
            locale={locale}
            styles={{
              name: nameStyle,
              preparations: preparationsStyle,
              quantity: quantityStyle,
            }}
            ariaPrefix={
              recipeIngredientDetailsStrings.ingredientPrefix.addedIngredient
            }
          />
        }
        bottomSectionChildren={
          <RecipeIngredientIssue
            ingredient={ingredient}
            index={index}
            recipeId={recipeId}
            primaryUnitSystem={primaryUnitSystem}
          />
        }
      />
    );
  }
);
