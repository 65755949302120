import type { FC } from 'react';
import { memo } from 'react';

import { useAppSelector } from 'app/store/hooks';
import { Card } from 'components/Card/Card';
import type { DraggableContentProps } from 'components/DragAndDrop/Draggable';
import { recipeIngredientStrings } from 'features/recipe/ingredients/list/RecipeIngredient.constants';
import { selectRecipeLocale } from 'features/recipe/recipeSlice';
import {
  CardDeleteButton,
  CardDeleteButtonAlignment,
} from 'features/recipe/shared/CardDeleteButton/CardDeleteButton';
import { RecipeIngredientDetails } from 'features/recipe/shared/RecipeIngredientDetails/RecipeIngredientDetails';
import { recipeIngredientDetailsStrings } from 'features/recipe/shared/RecipeIngredientDetails/RecipeIngredientDetails.constants';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';

const { labels } = recipeIngredientStrings;

export interface RecipeIngredientProps extends DraggableContentProps {
  draggable?: boolean;
  ingredient: AppRecipeIngredient;
  onClick?: () => void;
  onDelete?: () => void;
  selected?: boolean;
}

export const RecipeIngredient: FC<RecipeIngredientProps> = memo(
  function RecipeIngredient({
    draggable = false,
    dragging,
    ingredient,
    onClick,
    onDelete,
    selected,
  }) {
    const locale = useAppSelector(selectRecipeLocale);

    return (
      <Card
        sx={{ mb: 6 }}
        onClick={onClick}
        selected={selected}
        ariaLabel={ingredient.name}
        draggable={draggable}
        dragging={dragging}
        topSectionChildren={
          <RecipeIngredientDetails
            ingredient={ingredient}
            locale={locale}
            ariaPrefix={
              recipeIngredientDetailsStrings.ingredientPrefix.addedIngredient
            }
          />
        }
        actionButtonChildren={
          onDelete && (
            <CardDeleteButton
              label={labels.deleteButton}
              alignment={CardDeleteButtonAlignment.Center}
              onDelete={onDelete}
            />
          )
        }
      />
    );
  }
);
