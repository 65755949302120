import { generalCapabilitiesSagas } from 'features/referenceData/generalCapabilities/generalCapabilitiesSagas';
import { ingredientsSagas } from 'features/referenceData/ingredients/ingredientsSagas';
import { measurementSystemsSagas } from 'features/referenceData/measurementSystems/measurementSystemsSagas';
import { preparationsSagas } from 'features/referenceData/preparations/preparationsSagas';
import { tagsSagas } from 'features/referenceData/tags/tagsSagas';

export const referenceDataRestartableSagas = [
  ...ingredientsSagas,
  ...preparationsSagas,
  ...generalCapabilitiesSagas,
  ...tagsSagas,
  ...measurementSystemsSagas,
];
