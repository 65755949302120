import type { SxProps } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { memo } from 'react';
import type { FC } from 'react';

import type { ApiEntityState } from 'api/types/common/apiEntityState';
import { StatusBadge } from 'components/Badge/StatusBadge';

interface RecipeStatusBadgeProps {
  status: ApiEntityState;
  sx?: SxProps;
}

export const RecipeStatusBadge: FC<RecipeStatusBadgeProps> = memo(
  function RecipeStatusBadge({ status, sx }) {
    return <StatusBadge status={status} sx={sx} text={capitalize(status)} />;
  }
);
