import { BadgeStatus } from '@dropkitchen/pantry-react';

import type { ApiEntityState } from 'api/types/common/apiEntityState';
import { ApiCcCuratedCollectionState } from 'api/types/curatedCollection/apiCcCuratedCollectionState';
import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';

export const getStatusColor = (status: ApiEntityState) => {
  switch (status) {
    case ApiRcpRecipeState.Draft:
    case ApiCcCuratedCollectionState.Draft:
      return BadgeStatus.InteractiveYellow;
    case ApiRcpRecipeState.Published:
    case ApiCcCuratedCollectionState.Published:
      return BadgeStatus.SurfaceAccentEmphasis;
    default:
      return BadgeStatus.SurfaceSubtle;
  }
};
