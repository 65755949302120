export const isPowerSettingId = (id: string) =>
  id.match(/[a-zA-Z0-9]+:PowerSetting$/);

export const isPressureSettingId = (id: string) =>
  id.match(/[a-zA-Z0-9]+:PressureSetting$/);

export const isSpeedSettingId = (id: string) =>
  id.match(/[a-zA-Z0-9]+:SpeedSetting$/);

export const isTemperatureSettingId = (id: string) =>
  id.match(/[a-zA-Z0-9]+:TemperatureSetting$/);

export const isInternalTemperatureSettingId = (id: string) =>
  id.match(/[a-zA-Z0-9]+:InternalTemperatureSetting$/);

export const isTimeSettingId = (id: string) =>
  id.match(/[a-zA-Z0-9]+:[a-zA-Z]*Time[a-zA-Z]*Setting$/);

export const isVentingSettingId = (id: string) =>
  id.match(/[a-zA-Z0-9]+:VentingSetting$/);

export const isVentingTimeSettingId = (id: string) =>
  id.match(/[a-zA-Z0-9]+:VentingTimeSetting$/);
