import { appCommonStrings } from 'types/appCommon.constants';

export const homeFeedCollectionRecipesStrings = {
  labels: {
    deleteRecipeButton: 'Delete recipe',
  },
  table: {
    headers: {
      order: 'No.',
      mainInfo: 'Recipe / Author',
      ingredientsCount: 'Ingredient No.',
      stepsCount: 'Step No.',
      totalTime: 'Total Time',
      id: 'ID',
      state: 'Status',
      action: 'Action',
    },
  },
} as const;

export enum HomeFeedCollectionRecipesColumnField {
  Order = 'order',
  MainInfo = 'main-info',
  IngredientsCount = 'ingredients-count',
  StepsCount = 'steps-count',
  TotalTime = 'total-time',
  Id = 'id',
  State = 'state',
  Action = 'action',
}

const {
  table: { headers },
} = homeFeedCollectionRecipesStrings;

export const homeFeedCollectionRecipesConstants = {
  table: {
    rowHeight: 92,
    emptyCell: appCommonStrings.placeholders.noValue,
    columns: {
      order: {
        field: HomeFeedCollectionRecipesColumnField.Order,
        headerName: headers.order,
        sortable: false,
        width: 56,
        headerAlign: 'center',
        align: 'center',
      },
      mainInfo: {
        field: HomeFeedCollectionRecipesColumnField.MainInfo,
        headerName: headers.mainInfo,
        sortable: false,
        minWidth: 200,
        flex: 1,
      },
      ingredientsCount: {
        field: HomeFeedCollectionRecipesColumnField.IngredientsCount,
        headerName: headers.ingredientsCount,
        sortable: false,
        width: 140,
        headerAlign: 'center',
        align: 'center',
      },
      stepsCount: {
        field: HomeFeedCollectionRecipesColumnField.StepsCount,
        headerName: headers.stepsCount,
        sortable: false,
        width: 140,
        headerAlign: 'center',
        align: 'center',
      },
      totalTime: {
        field: HomeFeedCollectionRecipesColumnField.TotalTime,
        headerName: headers.totalTime,
        sortable: false,
        width: 140,
      },
      id: {
        field: HomeFeedCollectionRecipesColumnField.Id,
        headerName: headers.id,
        sortable: false,
        width: 140,
      },
      state: {
        field: HomeFeedCollectionRecipesColumnField.State,
        headerName: headers.state,
        sortable: false,
        width: 140,
        headerAlign: 'center',
        align: 'center',
      },
    },
  },
} as const;
