import {
  AddCircleIcon,
  Button,
  ButtonSize,
  ButtonStyle,
  CheckIcon,
  PantryColor,
  PantryTypography,
  Spinner,
} from '@dropkitchen/pantry-react';
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { ApiCcCuratedCollectionId } from 'api/types/curatedCollection/apiCcCuratedCollectionId';
import { appHomeFeedRoutes } from 'app/routes/constants';
import { generateHomeFeedRoute } from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { stylesCenterContent } from 'app/theme';
import { RecipeStatusBadge } from 'components/Badge/RecipeStatusBadge';
import { ButtonWithSkeleton } from 'components/ButtonWithSkeleton/ButtonWithSkeleton';
import { EmptyContent } from 'components/EmptyContent/EmptyContent';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { localeFieldStrings } from 'components/LocaleField/LocaleField.constants';
import { MenuButton } from 'components/MenuButton/MenuButton';
import { ReadOnlyField } from 'components/ReadOnlyField/ReadOnlyField';
import { HomeFeedAddCollectionRecipesDialog } from 'features/homeFeed/HomeFeedAddCollectionRecipesDialog/HomeFeedAddCollectionRecipesDialog';
import { homeFeedAddCollectionRecipesDialogOpened } from 'features/homeFeed/HomeFeedAddCollectionRecipesDialog/homeFeedAddCollectionRecipesDialogSlice';
import { homeFeedCollectionPageStrings } from 'features/homeFeed/HomeFeedCollectionPage/HomeFeedCollectionPage.constants';
import {
  HomeFeedCollectionFieldsToValidate,
  homeFeedCollectionRecipesChanged,
  homeFeedCollectionFetchRequested,
  homeFeedCollectionSaveRequested,
  homeFeedCollectionStateChanged,
  homeFeedCollectionSubtitleChanged,
  homeFeedCollectionTitleChanged,
  selectHomeFeedCollection,
  selectHomeFeedCollectionErrors,
  selectIsHomeFeedCollectionFetching,
  selectIsHomeFeedCollectionSaving,
  selectIsHomeFeedCollectionSubmitted,
} from 'features/homeFeed/HomeFeedCollectionPage/homeFeedCollectionSlice';
import { HomeFeedCollectionRecipes } from 'features/homeFeed/HomeFeedCollectionRecipes/HomeFeedCollectionRecipes';
import { generateRecipeTotalMessage } from 'features/homeFeed/homeFeeds.utils';
import { DetailLayout } from 'features/layout/DetailLayout';
import { HeaderScrolled } from 'features/layout/HeaderScrolled';
import { ReactComponent as EmptyContentImage } from 'features/recipes/no-recipes.svg';
import { appCuratedCollectionStates } from 'types/curatedCollection/appCcCuratedCollectionState';
import { generateChipsFromTags } from 'utils/chips';

const { labels, placeholders, emptyRecipesList, errorMessages } =
  homeFeedCollectionPageStrings;

export const HomeFeedCollectionPage: FC = memo(
  function HomeFeedCollectionPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { collectionId } = useParams<{
      collectionId: ApiCcCuratedCollectionId;
    }>();

    const collection = useAppSelector(selectHomeFeedCollection);
    const isSaving = useAppSelector(selectIsHomeFeedCollectionSaving);
    const isFetching = useAppSelector(selectIsHomeFeedCollectionFetching);
    const isSubmitted = useAppSelector(selectIsHomeFeedCollectionSubmitted);
    const errors = useAppSelector(selectHomeFeedCollectionErrors);

    const isEdit = !!collectionId;

    useEffect(() => {
      if (collectionId) {
        dispatch(homeFeedCollectionFetchRequested(collectionId));
      }
    }, [collectionId, dispatch]);

    useEffect(() => {
      if (!isEdit && !collection) {
        navigate(`/${appHomeFeedRoutes.root}`);
      }
    }, [collection, isEdit, navigate]);

    const hasError = (field: HomeFeedCollectionFieldsToValidate): boolean =>
      isSubmitted && !!errors[field];

    if (isFetching) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: 8,
          }}
        >
          <Spinner size={64} />
        </Box>
      );
    }

    if (!collection) {
      return;
    }

    const { title, subtitle, locale, state, applianceTags, recipes } =
      collection;

    const headerElement = (
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, gap: 4 }}
        >
          <Typography
            variant={PantryTypography.H6}
            color={PantryColor.TextDefault}
          >
            {isEdit ? collection.title : labels.createTitle}
          </Typography>
          {!!collectionId && (
            <>
              <Grid item>
                <RecipeStatusBadge status={state} />
              </Grid>
              <Grid item>
                <MenuButton label={labels.stateButton} minWidth="144px">
                  {appCuratedCollectionStates.map((option) => (
                    <MenuItem
                      key={option.id}
                      onClick={() => {
                        dispatch(homeFeedCollectionStateChanged(option.id));
                      }}
                      disableRipple
                    >
                      {option.name}
                      {state === option.id && (
                        <CheckIcon sx={{ ml: 2.5 }} size={24} />
                      )}
                    </MenuItem>
                  ))}
                </MenuButton>
              </Grid>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 4,
          }}
        >
          <ButtonWithSkeleton
            button={
              <Button
                label={labels.saveButton}
                buttonStyle={ButtonStyle.Default}
                size={ButtonSize.Large}
                onClick={() =>
                  dispatch(homeFeedCollectionSaveRequested({ collectionId }))
                }
                loading={isSaving}
              />
            }
          />
          <Button
            label={labels.closeButton}
            buttonStyle={ButtonStyle.Emphasis}
            size={ButtonSize.Large}
            onClick={() => navigate(generateHomeFeedRoute({ locale }))}
          />
        </Box>
      </Box>
    );

    return (
      <>
        <HeaderScrolled>{headerElement}</HeaderScrolled>
        <Grid container sx={{ ...stylesCenterContent, pt: 10, pb: 4, mb: 4 }}>
          {headerElement}
        </Grid>
        <DetailLayout sx={{ px: 8, py: 6 }}>
          <Grid container gap={4}>
            <Grid item container gap={6}>
              <Grid item>
                <ReadOnlyField
                  label={labels.locale}
                  value={localeFieldStrings.getOptionText(locale)}
                />
              </Grid>
              <Grid item>
                <ReadOnlyField
                  label={labels.appliances}
                  value={generateChipsFromTags(applianceTags)}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  flexGrow: 1,
                }}
              >
                <Button
                  label={labels.addRecipesButton}
                  buttonStyle={ButtonStyle.Default}
                  size={ButtonSize.Medium}
                  onClick={() =>
                    dispatch(
                      homeFeedAddCollectionRecipesDialogOpened({
                        selectedRecipes: collection.recipes,
                      })
                    )
                  }
                  leadingIcon={AddRecipesIcon}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container flexWrap="nowrap" gap={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={labels.titleField}
                  type="text"
                  placeholder={placeholders.titleField}
                  variant="outlined"
                  fullWidth
                  required
                  value={title}
                  onChange={(event) =>
                    dispatch(
                      homeFeedCollectionTitleChanged({
                        title: event.target.value,
                      })
                    )
                  }
                  InputLabelProps={{ shrink: true }}
                  error={hasError(HomeFeedCollectionFieldsToValidate.Title)}
                  helperText={
                    hasError(HomeFeedCollectionFieldsToValidate.Title) && (
                      <HelperText
                        message={errorMessages.titleField.required}
                        severity={HelperTextSeverity.Critical}
                      />
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={labels.subtitleField}
                  type="text"
                  placeholder={placeholders.subtitleField}
                  variant="outlined"
                  fullWidth
                  required
                  value={subtitle}
                  onChange={(event) =>
                    dispatch(
                      homeFeedCollectionSubtitleChanged({
                        subtitle: event.target.value,
                      })
                    )
                  }
                  InputLabelProps={{ shrink: true }}
                  error={hasError(HomeFeedCollectionFieldsToValidate.Subtitle)}
                  helperText={
                    hasError(HomeFeedCollectionFieldsToValidate.Subtitle) && (
                      <HelperText
                        message={errorMessages.subtitleField.required}
                        severity={HelperTextSeverity.Critical}
                      />
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Typography variant={PantryTypography.Body2}>
                {labels.recipeTotal}&nbsp;
                {generateRecipeTotalMessage(recipes.length)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <HomeFeedCollectionRecipes
                ariaLabel={labels.selectedRecipes}
                rowCount={recipes.length}
                recipes={recipes}
                hidePagination
                hideCheckboxSelection
                components={{
                  /* eslint-disable @typescript-eslint/naming-convention */
                  NoRecipes,
                  /* eslint-enable @typescript-eslint/naming-convention */
                }}
                onRecipesReordered={(reorderedRecipes) =>
                  dispatch(
                    homeFeedCollectionRecipesChanged({
                      recipes: reorderedRecipes,
                    })
                  )
                }
                onRecipeRemoved={(updatedRecipes) =>
                  dispatch(
                    homeFeedCollectionRecipesChanged({
                      recipes: updatedRecipes,
                    })
                  )
                }
              />
            </Grid>
          </Grid>
        </DetailLayout>
        <HomeFeedAddCollectionRecipesDialog
          onSubmit={(selectedRecipes) => {
            dispatch(
              homeFeedCollectionRecipesChanged({ recipes: selectedRecipes })
            );
          }}
        />
      </>
    );
  }
);

const AddRecipesIcon: FC = memo(function AddRecipesIcon() {
  return (
    <AddCircleIcon size={16} color={PantryColor.IconDefault} sx={{ mr: 2 }} />
  );
});

const NoRecipes: FC = memo(function NoRecipes() {
  const dispatch = useAppDispatch();
  return (
    <EmptyContent
      sx={{ py: 10 }}
      components={{
        image: <EmptyContentImage />,
        actions: (
          <Button
            label={labels.addRecipesButton}
            buttonStyle={ButtonStyle.Subtle}
            size={ButtonSize.Large}
            onClick={() =>
              dispatch(
                homeFeedAddCollectionRecipesDialogOpened({
                  selectedRecipes: [],
                })
              )
            }
            leadingIcon={AddRecipesIcon}
          />
        ),
      }}
      text={emptyRecipesList.text}
      title={emptyRecipesList.title}
    />
  );
});
