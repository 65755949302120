import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import type { ApiRequestSagaReturnType } from 'api/createApiRequestSaga';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
import { apiGetRecipes } from 'api/recipes';
import { fromApiRcpRecipe } from 'api/types/curatedCollection/apiCcCuratedCollectionRecipe';
import {
  selectRecipesAppliedFilters,
  selectRecipesSearchTerm,
} from 'components/RecipesSearch/recipesSearchSlice';
import { recipeSearchUtils } from 'components/RecipesSearch/recipesSearchUtils';
import { appConfig } from 'config/config';
import { errorOccurred } from 'features/error/errorSlice';
import { errorSliceConstants } from 'features/error/errorSlice.constants';
import {
  homeFeedAddCollectionRecipesDialogFetchRecipesFailed,
  homeFeedAddCollectionRecipesDialogFetchRecipesRequested,
  homeFeedAddCollectionRecipesDialogFetchRecipesSucceed,
  selectHomeFeedAddCollectionRecipesDialogPage,
  selectHomeFeedAddCollectionRecipesDialogRowsPerPage,
} from 'features/homeFeed/HomeFeedAddCollectionRecipesDialog/homeFeedAddCollectionRecipesDialogSlice';

export const apiFetchRecipesSaga = createApiRequestSaga(apiGetRecipes);

export const debounceFetchDelay = appConfig.isUnitTestEnv() ? 0 : 300;

export function* homeFeedAddCollectionRecipesDialogFetchRecipes() {
  /** Debounce user input */
  yield delay(debounceFetchDelay);

  const page = (yield select(
    selectHomeFeedAddCollectionRecipesDialogPage
  )) as ReturnType<typeof selectHomeFeedAddCollectionRecipesDialogPage>;

  const size = (yield select(
    selectHomeFeedAddCollectionRecipesDialogRowsPerPage
  )) as ReturnType<typeof selectHomeFeedAddCollectionRecipesDialogRowsPerPage>;

  const searchTerm = (yield select(selectRecipesSearchTerm)) as ReturnType<
    typeof selectRecipesSearchTerm
  >;

  const filters = (yield select(selectRecipesAppliedFilters)) as ReturnType<
    typeof selectRecipesAppliedFilters
  >;

  const fetchResponse = (yield call(apiFetchRecipesSaga, {
    from: page * size,
    searchTerm,
    size,
    tags: recipeSearchUtils.getTags(filters),
    state: recipeSearchUtils.getState(filters),
    locales: recipeSearchUtils.getLocales(filters),
    author: recipeSearchUtils.getAuthor(filters),
  })) as ApiRequestSagaReturnType<typeof apiFetchRecipesSaga>;

  if (!fetchResponse.ok) {
    yield put(
      homeFeedAddCollectionRecipesDialogFetchRecipesFailed(
        fetchResponse.details.message
      )
    );

    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  const {
    data: { items, total },
  } = fetchResponse;
  yield put(
    homeFeedAddCollectionRecipesDialogFetchRecipesSucceed({
      recipes: items.map(fromApiRcpRecipe),
      total,
    })
  );
}

function* homeFeedAddCollectionRecipesDialogFetchRecipesWatcher() {
  yield takeLatest(
    homeFeedAddCollectionRecipesDialogFetchRecipesRequested,
    homeFeedAddCollectionRecipesDialogFetchRecipes
  );
}

export const homeFeedAddCollectionRecipesDialogRestartableSagas = [
  homeFeedAddCollectionRecipesDialogFetchRecipesWatcher,
];
