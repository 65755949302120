import type { FC } from 'react';
import { lazy, useEffect, useState, memo } from 'react';
import { useParams } from 'react-router-dom';
import type { SnakeCaseKeys } from 'snakecase-keys';
import snakecaseKeys from 'snakecase-keys';

import { getAppliance } from 'api/appliances';
import type { ApiAplAppliance } from 'api/types/appliance/apiAplAppliance';
import type { ApiAplId } from 'api/types/appliance/apiAplId';
import type { appRouteParams } from 'app/routes/constants';
import { appliancesListConstants } from 'features/appliances/list/AppliancesList.constants';
import { ListLayout } from 'features/layout/ListLayout';

const ApplianceProfileViewerForm = lazy(() =>
  import('./profileForm/ApplianceProfileViewerForm').then((module) => ({
    default: module.ApplianceProfileViewerForm,
  }))
);

const { table } = appliancesListConstants;

export const AppliancePage: FC = memo(function AppliancePage() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const applianceId = useParams<{
    [appRouteParams.applianceId]: ApiAplId;
  }>().applianceId!;

  const [rawProfile, setRawProfile] =
    useState<SnakeCaseKeys<ApiAplAppliance, true>>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getAppliance({ locale: table.locale, applianceId }).then((response) => {
      if (response.ok) {
        setRawProfile(
          snakecaseKeys(response.data, {
            deep: true,
          })
        );
      }
    });
  }, [applianceId]);

  return (
    <ListLayout title={rawProfile?.name ?? ''} shouldWrapChildren={false}>
      {rawProfile && (
        <ApplianceProfileViewerForm rawApplianceProfile={rawProfile} />
      )}
    </ListLayout>
  );
});
