import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';

export interface AppRecipeState {
  id: ApiRcpRecipeState;
  name: string;
}

export const recipeStates = [
  { id: ApiRcpRecipeState.Draft, name: 'Draft' },
  { id: ApiRcpRecipeState.Published, name: 'Published' },
];
