export const imageConstants = {
  delayToRetryFetch: 2000,
  maxFetchTries: 5,
} as const;

export const imageStrings = {
  testIds: {
    imagePlaceholder: 'image-placeholder',
    imageSkeleton: 'image-loader',
  },
};
