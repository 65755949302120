import {
  Button,
  PantryTypography,
  DeleteIcon,
  ButtonSize,
  ButtonStyle,
} from '@dropkitchen/pantry-react';
import type { AutocompleteRenderInputParams } from '@mui/material';
import { Box, Typography, Autocomplete, TextField } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { useMemo, useEffect, memo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { AutocompleteWithRetry } from 'components/AutocompleteWithRetry/AutocompleteWithRetry';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import {
  RecipesAdvancedSearchConnector,
  RecipesAdvancedSearchFilterSelectionType,
  recipeAdvancedSearchFilterConstants,
  recipeAdvancedSearchFilterStrings,
  recipesAdvancedSearchFilterOperators,
  recipesAdvancedSearchFilterSelection,
  recipesAdvancedSearchFilterValues,
} from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.constants';
import type {
  RecipesAdvancedSearchFilterValue,
  RecipesAdvancedSearchFilters,
} from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.types';
import {
  selectAvailableRecipesFilterKeys,
  selectRecipesFilterKeys,
  recipesSearchFilterUpdated,
  recipesSearchFilterDeleted,
  selectRecipesFilterErrorsByIndex,
  selectRecipesHasAppliedFilters,
  selectRecipesSearchFiltersLocale,
} from 'components/RecipesSearch/recipesSearchSlice';
import { selectAuthUserLocale } from 'features/auth/authSlice';

export interface RecipesAdvancedSearchFilterProps {
  filter: RecipesAdvancedSearchFilters;
  index: number;
}

const { buttons, errorMessages } = recipeAdvancedSearchFilterStrings;
const { style, autocomplete } = recipeAdvancedSearchFilterConstants;

export const RecipesAdvancedSearchFilter: FC<RecipesAdvancedSearchFilterProps> =
  memo(function RecipesAdvancedSearchFilter({
    filter: { key, operator, value },
    index,
  }) {
    const dispatch = useAppDispatch();
    const availableKeys = useAppSelector(selectAvailableRecipesFilterKeys);
    const filterKeys = useAppSelector(selectRecipesFilterKeys);
    const userLocale = useAppSelector(selectAuthUserLocale);
    const filtersLocale = useAppSelector(selectRecipesSearchFiltersLocale);
    const errors = useAppSelector(
      useMemo(() => selectRecipesFilterErrorsByIndex(index), [index])
    );
    const hasApplied = useAppSelector(selectRecipesHasAppliedFilters);
    const locale = filtersLocale ?? userLocale;
    const isMultiple = !!(
      operator &&
      recipesAdvancedSearchFilterSelection[operator] ===
        RecipesAdvancedSearchFilterSelectionType.Multiple
    );
    const isFreeText = !!(
      operator &&
      recipesAdvancedSearchFilterSelection[operator] ===
        RecipesAdvancedSearchFilterSelectionType.Text
    );

    const { requestValues, isLoadingValues, hasRequestValuesFailed, values } =
      (key && recipesAdvancedSearchFilterValues[key]) || {};

    useEffect(() => {
      if (requestValues) {
        requestValues(dispatch, locale);
      }
    }, [dispatch, requestValues, locale]);

    return (
      <Box
        data-testid={`filter-row-${index}`}
        sx={{
          display: 'grid',
          gridTemplateColumns: `1fr ${autocomplete.style.fullWidth} ${autocomplete.style.width} ${autocomplete.style.fullWidth} 44px`,
          height: style.height,
          gap: 2,
          pl: 3,
        }}
      >
        <CenterContent>
          <Typography variant={PantryTypography.Body1}>
            {index === 0
              ? RecipesAdvancedSearchConnector.Where
              : RecipesAdvancedSearchConnector.And}
          </Typography>
        </CenterContent>
        <Autocomplete
          value={key}
          onChange={(_, option) =>
            dispatch(
              recipesSearchFilterUpdated({ index, filter: { key: option } })
            )
          }
          options={filterKeys}
          filterOptions={(options) =>
            options.filter(
              (option) => option === key || availableKeys.includes(option)
            )
          }
          renderInput={(props) => (
            <AutocompleteInput index={index} prop="key" {...props} />
          )}
        />
        <Autocomplete
          value={operator}
          onChange={(_, option) =>
            dispatch(
              recipesSearchFilterUpdated({
                index,
                filter: { operator: option },
              })
            )
          }
          options={key ? recipesAdvancedSearchFilterOperators[key] : []}
          renderInput={(props) => (
            <AutocompleteInput index={index} prop="operator" {...props} />
          )}
        />
        {isFreeText ? (
          <TextField
            value={value ?? ''}
            onChange={(e) =>
              dispatch(
                recipesSearchFilterUpdated({
                  index,
                  filter: {
                    value: e.currentTarget.value.trimStart(),
                  } as RecipesAdvancedSearchFilters,
                })
              )
            }
            error={hasApplied && errors.value}
            helperText={
              hasApplied &&
              errors.value && (
                <HelperText
                  message={errorMessages.textRequired}
                  severity={HelperTextSeverity.Critical}
                />
              )
            }
          />
        ) : (
          <AutocompleteWithRetry
            value={value as RecipesAdvancedSearchFilterValue<string>[]}
            onChange={(_, selected) =>
              dispatch(
                recipesSearchFilterUpdated({
                  index,
                  filter: { value: selected } as RecipesAdvancedSearchFilters,
                })
              )
            }
            loading={isLoadingValues?.(locale)}
            options={values?.(locale) ?? []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, currentValue) =>
              option.id === currentValue.id
            }
            multiple={isMultiple}
            renderTags={renderSelectedValues}
            limitTags={0}
            disableCloseOnSelect={isMultiple}
            renderInput={(props) => (
              <AutocompleteInput index={index} prop="value" {...props} />
            )}
            onRetry={() => requestValues?.(dispatch, locale)}
            hasError={!!hasRequestValuesFailed?.(locale)}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
          />
        )}
        <CenterContent>
          <Button
            label={buttons.deleteFilter}
            hideLabel
            leadingIcon={DeleteIcon}
            onClick={() => dispatch(recipesSearchFilterDeleted(index))}
            size={ButtonSize.Medium}
            buttonStyle={ButtonStyle.Subtle}
          />
        </CenterContent>
      </Box>
    );
  });

const CenterContent: FC<{ children: ReactNode }> = memo(function Center({
  children,
}) {
  return (
    <Box
      sx={{
        height: autocomplete.style.height,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
});

interface AutocompleteInputProps extends AutocompleteRenderInputParams {
  index: number;
  prop: Extract<
    keyof RecipesAdvancedSearchFilters,
    'key' | 'operator' | 'value'
  >;
}

const AutocompleteInput: FC<AutocompleteInputProps> = memo(
  function AutocompleteInput({ index, prop, ...props }) {
    const errors = useAppSelector(
      useMemo(() => selectRecipesFilterErrorsByIndex(index), [index])
    );
    const hasApplied = useAppSelector(selectRecipesHasAppliedFilters);

    return (
      <TextField
        {...props}
        variant="outlined"
        error={hasApplied && errors[prop]}
        helperText={
          hasApplied &&
          errors[prop] && (
            <HelperText
              message={errorMessages.required}
              severity={HelperTextSeverity.Critical}
            />
          )
        }
      />
    );
  }
);

export const renderSelectedValues = (
  values: RecipesAdvancedSearchFilterValue<string>[]
): string | null => {
  if (!values.length) {
    return null;
  }
  const extras = values.length > 1 ? ` (+${values.length - 1})` : '';
  const maxLength = extras ? 8 : 13;
  const { name } = values[0];
  if (name.length < maxLength) {
    return `${name}${extras}`;
  }
  return `${name.slice(0, maxLength).trim()}...${extras}`;
};
