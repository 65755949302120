import { Grid } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { generateRecipeRoute } from 'app/routes/routesUtils';
import { useAppSelector } from 'app/store/hooks';
import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import { recipeIngredientFormConstants } from 'features/recipe/ingredients/form/RecipeIngredientForm.constants';
import { recipeBasicInformationConstants } from 'features/recipe/recipeBasicInformation/RecipeBasicInformation.constants';
import {
  selectRecipe,
  selectRecipeFetching,
} from 'features/recipe/recipeSlice';
import { recipeReviewStrings } from 'features/recipe/review/RecipeReview.constants';
import { RecipeReviewBasicInformation } from 'features/recipe/review/basicInformation/RecipeReviewBasicInformation';
import { recipeReviewBasicInformationStrings } from 'features/recipe/review/basicInformation/RecipeReviewBasicInformation.constants';
import { RecipeReviewBasicInformationSkeleton } from 'features/recipe/review/basicInformation/RecipeReviewBasicInformationSkeleton';
import { RecipeReviewIngredients } from 'features/recipe/review/ingredients/RecipeReviewIngredients';
import { RecipeReviewEmptyValue } from 'features/recipe/review/shared/RecipeReviewEmptyValue/RecipeReviewEmptyValue';
import { RecipeReviewListSkeleton } from 'features/recipe/review/shared/RecipeReviewListSkeleton/RecipeReviewListSkeleton';
import { RecipeReviewSteps } from 'features/recipe/review/steps/RecipeReviewSteps';
import { RecipeTabContainer } from 'features/recipe/shared/RecipeTabContainer/RecipeTabContainer';
import { recipeStepsFormConstants } from 'features/recipe/steps/form/RecipeStepsForm.constants';
import { AppIssueType } from 'types/appIssueType';

const { sections } = recipeReviewStrings;
const { emptyRequiredFields } = recipeReviewBasicInformationStrings;
const { fieldIds } = recipeBasicInformationConstants;

export const RecipeReview: FC = memo(function RecipeReview() {
  const recipe = useAppSelector(selectRecipe);
  const isFetching = useAppSelector(selectRecipeFetching);

  return (
    <RecipeTabContainer
      title={
        !isFetching
          ? {
              string: recipe.name,
              component: !recipe.name && (
                <RecipeReviewEmptyValue
                  type={AppIssueType.Critical}
                  emptyLink={generateRecipeRoute({
                    id: recipe.id,
                    tab: RecipeTabName.Information,
                    autofocusedFieldId: fieldIds.recipeName,
                  })}
                  message={emptyRequiredFields.name}
                />
              ),
            }
          : undefined
      }
    >
      <Grid container>
        <Grid item sm={7}>
          {isFetching ? (
            <RecipeReviewBasicInformationSkeleton />
          ) : (
            <RecipeReviewBasicInformation />
          )}
          {isFetching ? (
            <RecipeReviewListSkeleton title={sections.ingredients} />
          ) : (
            <RecipeReviewIngredients
              ingredients={recipe.ingredients}
              emptyLink={generateRecipeRoute({
                id: recipe.id,
                tab: RecipeTabName.Ingredients,
                autofocusedFieldId:
                  recipeIngredientFormConstants.fieldIds.ingredient,
              })}
              recipeId={recipe.id}
            />
          )}
          {isFetching ? (
            <RecipeReviewListSkeleton title={sections.steps} />
          ) : (
            <RecipeReviewSteps
              steps={recipe.steps}
              emptyLink={generateRecipeRoute({
                id: recipe.id,
                tab: RecipeTabName.Steps,
                autofocusedFieldId: recipeStepsFormConstants.fieldIds.text,
              })}
              recipeId={recipe.id}
            />
          )}
        </Grid>
      </Grid>
    </RecipeTabContainer>
  );
});
