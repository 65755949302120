import type { ApiLocale } from 'api/types/common/apiLocale';
import type { AppRecipe } from 'types/recipe/appRecipe';

import type { ApiRcpRecipeWithRefs } from './apiRcpRecipeWithRefs';
import { fromAppRecipe as toRecipeWithRefs } from './apiRcpRecipeWithRefs';

export const noLocaleError =
  "It's not possible to map into ApiRecipe if there is no locale";

export interface ApiRcpNewRecipeWithRefs extends ApiRcpRecipeWithRefs {
  locale: ApiLocale;
}

export function fromAppRecipe(recipe: AppRecipe): ApiRcpNewRecipeWithRefs {
  if (!recipe.locale) {
    throw new Error(noLocaleError);
  }

  return {
    ...toRecipeWithRefs(recipe),
    locale: recipe.locale,
  };
}
