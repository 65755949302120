import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  CloseIcon,
  DeleteIcon,
  PantryColor,
  PantryTypography,
  DragHandleIcon,
  sxCompose,
} from '@dropkitchen/pantry-react';
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import type { FC, SyntheticEvent } from 'react';
import { useRef, useEffect, memo, useMemo } from 'react';

import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import { useAutoFocus } from 'app/routes/hooks/useAutoFocus';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { AutocompleteWithRetry } from 'components/AutocompleteWithRetry/AutocompleteWithRetry';
import { dragAndDropConstants } from 'components/DragAndDrop/DragAndDrop.constants';
import type { DropResult } from 'components/DragAndDrop/DragZone';
import { DragZone } from 'components/DragAndDrop/DragZone';
import { Draggable } from 'components/DragAndDrop/Draggable';
import { ListRow } from 'components/ListRow/ListRow';
import {
  ValueInMultipleSystemsField,
  fromStringToNumberValueWithUnit,
} from 'components/ValueInMultipleSystemsField/ValueInMultipleSystemsField';
import { ValueWithUnitField } from 'components/ValueWithUnitField/ValueWithUnitField';
import {
  recipeIngredientFormConstants,
  recipeIngredientFormStrings,
} from 'features/recipe/ingredients/form/RecipeIngredientForm.constants';
import { toNumber } from 'features/recipe/ingredients/form/recipeIngredientForm.utils';
import {
  selectIngredient as selectFormIngredient,
  selectAmount as selectFormAmount,
  selectIndex as selectFormIndex,
  selectPreparations as selectFormPreparations,
  selectUnit as selectFormUnit,
  selectErrors as selectFormErrors,
  ingredientUpdated,
  ingredientPreparationsUpdated,
  ingredientUnitUpdated,
  ingredientAmountUpdated,
  ingredientReset,
  selectIsIngredientUnsaved,
  selectIsFormShowing,
  ingredientPreparationMoved,
  ingredientFormPopulated,
  ingredientFormShown,
  selectMetricValueWithUnit,
  selectUsCustomaryValueWithUnit,
  ingredientQuantityMultipleSystemsUpdated,
  isFormQuantityComplete,
  selectShouldTriggerAutoconversion,
  selectIsAutoConversionEnabled,
  ingredientAutoConversionToggled,
} from 'features/recipe/ingredients/form/recipeIngredientFormSlice';
import {
  recipeIngredientAdded,
  recipeIngredientUpdated,
  selectRecipeIngredient,
  selectRecipeIngredientUsage,
  selectRecipeLocale,
} from 'features/recipe/recipeSlice';
import {
  selectIngredients,
  selectIngredient,
  selectIngredientsFetching,
  ingredientsFetchRequested,
  selectIngredientsFetchError,
} from 'features/referenceData/ingredients/ingredientsSlice';
import {
  selectMetricMeasurementSystemUnitsDictionary,
  selectUsCustomaryMeasurementSystemUnitsDictionary,
} from 'features/referenceData/measurementSystems/measurementSystemsSlice';
import {
  preparationsFetchRequested,
  selectPreparations,
  selectPreparationsFetchError,
  selectPreparationsFetching,
} from 'features/referenceData/preparations/preparationsSlice';
import { disambiguateTerms } from 'features/referenceData/referenceData.utils';
import { AppUnitSystem } from 'types/appUnitSystem';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import type { AppRecipeValueInMultipleSystems } from 'types/recipe/appRecipeValueInMultipleSystems';
import type { AppRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';

interface RecipeIngredientFormProps {
  onCancel?: () => void;
}

const { labels, messages } = recipeIngredientFormStrings;
const { fieldIds } = recipeIngredientFormConstants;

export const RecipeIngredientForm: FC<RecipeIngredientFormProps> = memo(
  function RecipeIngredientForm({ onCancel }) {
    const dispatch = useAppDispatch();

    const locale = useAppSelector(selectRecipeLocale);

    const isLoadingIngredients = useAppSelector(
      selectIngredientsFetching(locale)
    );

    const preparations = useAppSelector(selectPreparations(locale));
    const isLoadingPreparations = useAppSelector(
      selectPreparationsFetching(locale)
    );
    const preparationsFetchError = useAppSelector(
      selectPreparationsFetchError(locale)
    );
    const metricUnits = useAppSelector(
      selectMetricMeasurementSystemUnitsDictionary(locale)
    );
    const usCustomaryUnits = useAppSelector(
      selectUsCustomaryMeasurementSystemUnitsDictionary(locale)
    );

    const selectedIngredientIndex = useAppSelector(selectFormIndex);
    const isFormShowing = useAppSelector(selectIsFormShowing);
    const ingredientUsage = useAppSelector(
      selectRecipeIngredientUsage(selectedIngredientIndex)
    );

    const selectedRecipeIngredient = useAppSelector(
      selectRecipeIngredient(selectedIngredientIndex)
    );

    const selectIngredientMemoized = useMemo(
      () => selectIngredient(selectedRecipeIngredient?.id || '', locale),
      [selectedRecipeIngredient, locale]
    );
    const selectedIngredient = useAppSelector(selectIngredientMemoized);

    const ingredient = useAppSelector(selectFormIngredient);
    const amount = useAppSelector(selectFormAmount);
    const unit = useAppSelector(selectFormUnit);
    const metricValueWithUnit = useAppSelector(selectMetricValueWithUnit);
    const usCustomaryValueWithUnit = useAppSelector(
      selectUsCustomaryValueWithUnit
    );
    const ingredientPreparations = useAppSelector(selectFormPreparations);
    const hasChanges = useAppSelector(selectIsIngredientUnsaved);
    const shouldTriggerAutoConversion = useAppSelector(
      selectShouldTriggerAutoconversion
    );
    const isAutoConversionEnabled = useAppSelector(
      selectIsAutoConversionEnabled
    );
    const formErrors = useAppSelector(selectFormErrors);
    const hasError = !isEmpty(formErrors);

    const shouldDisplayMultipleSystems =
      !unit || metricUnits[unit.id] || usCustomaryUnits[unit.id];

    const areQuantitiesComplete = shouldDisplayMultipleSystems
      ? isFormQuantityComplete(metricValueWithUnit) &&
        isFormQuantityComplete(usCustomaryValueWithUnit)
      : !!unit;
    const isSaveEnabled =
      ingredient &&
      areQuantitiesComplete &&
      !hasError &&
      hasChanges &&
      !isLoadingIngredients;

    useEffect(() => {
      // Only load from recipe slice store if there is a selected ingredient and the form has no changes
      if (selectedRecipeIngredient && selectedIngredient && !hasChanges) {
        dispatch(
          ingredientFormPopulated({
            ingredient: selectedIngredient,
            recipeIngredient: selectedRecipeIngredient,
            usage: ingredientUsage,
          })
        );
      }
    }, [
      dispatch,
      selectedRecipeIngredient,
      selectedIngredient,
      hasChanges,
      ingredientUsage,
    ]);

    const { allowedMetricUnits, allowedUsCustomaryUnits, allowedUnits } =
      useMemo(() => {
        if (!ingredient) {
          return { allowedMetricUnits: [], allowedUsCustomaryUnits: [] };
        }
        const disambiguatedUnits = disambiguateTerms(ingredient.allowedUnits);
        const allUnits = ingredient.allowedUnits.map((allowed) => ({
          ...allowed,
          name: disambiguatedUnits[allowed.id] ?? allowed.name,
        }));
        return {
          allowedMetricUnits: allUnits.filter(
            ({ id }) => !usCustomaryUnits[id]
          ),
          allowedUsCustomaryUnits: allUnits.filter(
            ({ id }) => !metricUnits[id]
          ),
          allowedUnits: allUnits,
        };
      }, [ingredient, metricUnits, usCustomaryUnits]);

    const disambiguatedPreparations = useMemo(
      () => disambiguateTerms(preparations ?? []),
      [preparations]
    );

    const handleRemovePreparation = (preparationId: ApiRefId) => {
      dispatch(
        ingredientPreparationsUpdated(
          ingredientPreparations.filter(
            (preparation) => preparation.id !== preparationId
          )
        )
      );
    };

    const handleSubmit = (e: SyntheticEvent) => {
      e.preventDefault();

      if (!ingredient || hasError) {
        return;
      }

      const newQuantity = shouldDisplayMultipleSystems
        ? ({
            metric: fromStringToNumberValueWithUnit(metricValueWithUnit),
            usCustomary: fromStringToNumberValueWithUnit(
              usCustomaryValueWithUnit
            ),
          } as AppRecipeValueInMultipleSystems)
        : ({ amount: toNumber(amount), unit } as AppRecipeValueWithUnit);

      const newIngredient: AppRecipeIngredient = {
        id: ingredient.id,
        name: ingredient.name,
        quantity: newQuantity,
        ...(ingredientPreparations.length > 0 && {
          preparations: ingredientPreparations,
        }),
        sourceText: selectedRecipeIngredient?.sourceText,
      };

      if (selectedIngredientIndex !== null) {
        dispatch(
          recipeIngredientUpdated({
            index: selectedIngredientIndex,
            ingredient: newIngredient,
          })
        );
        return;
      }

      dispatch(recipeIngredientAdded(newIngredient));

      // Clear the form to add a new ingredient
      dispatch(ingredientReset());
    };

    const handleCancel = () => {
      dispatch(ingredientFormShown(false));
      onCancel?.();
    };

    const handleDropPreparation = ({ from, to }: DropResult) => {
      dispatch(ingredientPreparationMoved({ from, to }));
    };

    const getFormTitle = () =>
      selectedIngredientIndex === null
        ? labels.formAdd
        : labels.formEdit(selectedRecipeIngredient?.name ?? '');

    if (!isFormShowing) {
      return (
        <Typography
          variant={PantryTypography.Body1}
          color={PantryColor.TextSubtle}
          align="center"
          sx={{ mt: 3 }}
        >
          {messages.noIngredientSelected}
        </Typography>
      );
    }

    return (
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        aria-label={getFormTitle()}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
          }}
        >
          <Typography
            variant={PantryTypography.Body1SemiBold}
            color={PantryColor.TextDefault}
          >
            {getFormTitle()}
          </Typography>
          <Button
            hideLabel
            buttonStyle={ButtonStyle.Subtle}
            size={ButtonSize.Medium}
            leadingIcon={CloseIcon}
            onClick={handleCancel}
            label={labels.closeButton}
          />
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <RecipeIngredientField />
          </Grid>
          <Grid item xs={12}>
            {shouldDisplayMultipleSystems ? (
              <ValueInMultipleSystemsField
                id={fieldIds.quantity}
                label={labels.quantityField}
                locale={locale}
                metricField={{
                  quantity: metricValueWithUnit,
                  isLoadingUnits: false,
                  allowedUnits: allowedMetricUnits || [],
                  errors: { value: formErrors.metricAmount },
                }}
                usCustomaryField={{
                  quantity: usCustomaryValueWithUnit,
                  isLoadingUnits: false,
                  allowedUnits: allowedUsCustomaryUnits || [],
                  errors: { value: formErrors.usCustomaryAmount },
                }}
                onChange={(value) => {
                  dispatch(
                    ingredientQuantityMultipleSystemsUpdated({
                      ...value,
                      isMetricUnit:
                        !value.metric.unit ||
                        !!metricUnits[value.metric.unit.id],
                      isUsCustomaryUnit:
                        !value.usCustomary.unit ||
                        !!usCustomaryUnits[value.usCustomary.unit.id],
                      usage: ingredientUsage,
                    })
                  );
                }}
                context={{
                  ingredientReferenceId: ingredient?.id,
                  preparationReferenceIds: ingredientPreparations.map(
                    ({ id }) => id
                  ),
                }}
                shouldTriggerAutoConversion={shouldTriggerAutoConversion}
                isAutoConversionEnabled={isAutoConversionEnabled}
                onAutoConversionLinkClick={(currentState) => {
                  dispatch(ingredientAutoConversionToggled(currentState));
                }}
              />
            ) : (
              <ValueWithUnitField
                valueField={{
                  id: fieldIds.quantity,
                  label: labels.quantityField,
                  placeholder: labels.quantityField,
                  value: amount,
                  onChange: (value) => {
                    dispatch(
                      ingredientAmountUpdated({
                        amount: value,
                        usage: ingredientUsage,
                      })
                    );
                  },
                }}
                unitField={{
                  id: fieldIds.unit,
                  label: labels.unitField,
                  placeholder: labels.quantityField,
                  options: allowedUnits || [],
                  value: unit,
                  onChange: (value) => {
                    const system = (() => {
                      if (!value || !!metricUnits[value.id]) {
                        return AppUnitSystem.Metric;
                      }
                      if (usCustomaryUnits[value.id]) {
                        return AppUnitSystem.UsCustomary;
                      }
                      return undefined;
                    })();
                    dispatch(
                      ingredientUnitUpdated({
                        unit: value,
                        system,
                      })
                    );
                  },
                  isLoadingOptions: isLoadingIngredients !== false,
                  required: true,
                }}
                errors={{
                  value: formErrors.amount,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <AutocompleteWithRetry
              multiple
              data-testid="preparations"
              options={preparations || []}
              getOptionLabel={(preparation) =>
                disambiguatedPreparations[preparation.id] ?? preparation.name
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={ingredientPreparations}
              loading={isLoadingPreparations !== false}
              onChange={(_event, newValue) => {
                dispatch(ingredientPreparationsUpdated(newValue));
              }}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={labels.preparationsField}
                />
              )}
              renderOption={(props, option, { selected }) => {
                return (
                  <Box
                    component="li"
                    {...props}
                    key={option.id}
                    sx={{
                      typography: PantryTypography.Body2,
                      color: PantryColor.TextDefault,
                      pl: 0,
                    }}
                  >
                    <Checkbox checked={selected} />
                    {disambiguatedPreparations[option.id] ?? option.name}
                  </Box>
                );
              }}
              renderTags={() => null}
              onRetry={() => dispatch(preparationsFetchRequested({ locale }))}
              hasError={!!preparationsFetchError}
            />
          </Grid>
          {!!ingredientPreparations.length && (
            <Grid item xs={12}>
              <Typography
                variant={PantryTypography.Body2SemiBold}
                color={PantryColor.FrescoPrimary}
              >
                {labels.addedPreparations}
              </Typography>
              <Divider
                sx={{ borderColor: PantryColor.BorderSubtle, mb: 3, mt: 2 }}
              />
              <DragZone
                dragZoneId="preparations-list"
                onDrop={handleDropPreparation}
                componentProps={{ placeholder: { sx: { pb: 2 } } }}
              >
                {ingredientPreparations.map(({ name, id }, index) => {
                  return (
                    <Draggable
                      key={id}
                      draggableId={id}
                      index={index}
                      renderContent={({ dragging, ...props }) => (
                        <ListRow
                          title={name}
                          icon={
                            <DragHandleIcon
                              size={16}
                              color={PantryColor.IconDefault}
                            />
                          }
                          secondaryAction={
                            <Button
                              buttonStyle={ButtonStyle.Subtle}
                              hideLabel
                              label={labels.removePreparationButton(name)}
                              leadingIcon={DeletePreparationIcon}
                              onClick={() => handleRemovePreparation(id)}
                              size={ButtonSize.Small}
                              sx={{ mr: 2 }}
                            />
                          }
                          sx={sxCompose(
                            { mb: 2 },
                            dragAndDropConstants.styles.draggable,
                            dragging && dragAndDropConstants.styles.dragging
                          )}
                          {...props}
                        />
                      )}
                    />
                  );
                })}
              </DragZone>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type={ButtonType.Submit}
              label={
                selectedIngredientIndex !== null
                  ? labels.updateButton
                  : labels.addButton
              }
              buttonStyle={ButtonStyle.Emphasis}
              size={ButtonSize.Large}
              fullWidth
              disabled={!isSaveEnabled}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
);

const RecipeIngredientField: FC = memo(function RecipeIngredient() {
  const { isFocused } = useAutoFocus();

  const dispatch = useAppDispatch();

  const locale = useAppSelector(selectRecipeLocale);
  const ingredients = useAppSelector(selectIngredients(locale));
  const ingredientsFetchError = useAppSelector(
    selectIngredientsFetchError(locale)
  );
  const isLoadingIngredients = useAppSelector(
    selectIngredientsFetching(locale)
  );
  const ingredient = useAppSelector(selectFormIngredient);

  const ingredientFieldRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isFocused(fieldIds.ingredient)) {
      ingredientFieldRef.current?.focus();
    }
  }, [isFocused]);

  const disambiguatedIngredients = useMemo(
    () => disambiguateTerms(ingredients ?? []),
    [ingredients]
  );

  return (
    <AutocompleteWithRetry
      id={fieldIds.ingredient}
      value={ingredient}
      onChange={(_event, value) => dispatch(ingredientUpdated(value))}
      options={ingredients || []}
      getOptionLabel={(value) =>
        disambiguatedIngredients[value.id] ?? value.name
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={isLoadingIngredients !== false}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ingredientFieldRef}
          required
          variant="outlined"
          label={labels.ingredientField}
        />
      )}
      onRetry={() => dispatch(ingredientsFetchRequested({ locale }))}
      hasError={!!ingredientsFetchError}
    />
  );
});

const DeletePreparationIcon: FC = memo(function DeletePreparationIcon() {
  return <DeleteIcon size={16} color={PantryColor.IconDefault} />;
});
