import { Draggable as DndDraggable } from '@hello-pangea/dnd';
import { Box } from '@mui/material';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import { memo } from 'react';

export interface DraggableContentProps {
  dragging?: boolean;
}

export interface DraggableProps {
  draggableId: string | number;
  index: number;
  renderContent: (props: DraggableContentProps) => ReactNode;
  onClick?: MouseEventHandler;
}

export const Draggable: FC<DraggableProps> = memo(function Draggable({
  draggableId,
  index,
  renderContent,
  onClick,
}) {
  return (
    <DndDraggable
      draggableId={`${draggableId}`}
      index={index}
      disableInteractiveElementBlocking
    >
      {(
        { innerRef, draggableProps, dragHandleProps },
        { isDragging, isDropAnimating }
      ) => (
        <Box
          ref={innerRef}
          // The drop animation needs a small padding to not get stuck
          sx={{ p: 0.1, position: 'relative', zIndex: 1, cursor: 'grab' }}
          data-testid={generateDraggableTestId(draggableId)}
          onClick={onClick}
          {...draggableProps}
          {...dragHandleProps}
        >
          {renderContent({ dragging: isDragging && !isDropAnimating })}
        </Box>
      )}
    </DndDraggable>
  );
});

export const generateDraggableTestId = (draggableId: string | number): string =>
  `draggable-${draggableId}`;
