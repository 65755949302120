import {
  SignoutIcon,
  ButtonStyle,
  ButtonSize,
  Button,
} from '@dropkitchen/pantry-react';
import type { FC } from 'react';
import { useState, memo } from 'react';

import { useAppDispatch } from 'app/store/hooks';
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import { authSignOutRequested } from 'features/auth/authSlice';
import { logoutStrings } from 'features/layout/Logout.constants';

const { button, confirmationDialog } = logoutStrings;

export const Logout: FC = memo(function Logout() {
  const dispatch = useAppDispatch();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        label={button.label}
        leadingIcon={SignoutIcon}
        buttonStyle={ButtonStyle.Subtle}
        size={ButtonSize.Medium}
        onClick={() => setIsConfirmationOpen(true)}
      />
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        text={{
          body: confirmationDialog.body,
          confirmButton: confirmationDialog.confirmButton,
          cancelButton: confirmationDialog.cancelButton,
          title: confirmationDialog.title,
        }}
        onClose={(hasConfirmed) => {
          setIsConfirmationOpen(false);
          if (hasConfirmed) {
            dispatch(authSignOutRequested());
          }
        }}
      />
    </>
  );
});
