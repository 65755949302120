import { string, object, number } from 'yup';

import { recipeBasicInformationStrings } from 'features/recipe/recipeBasicInformation/RecipeBasicInformation.constants';
import type { AppRecipe } from 'types/recipe/appRecipe';
import { hasTime } from 'utils/validateTimes';
import type { ValidatorErrors } from 'utils/validator';
import { Validator } from 'utils/validator';

export type AppRecipeErrors = Partial<{
  [key in keyof AppRecipe]: ValidatorErrors;
}>;

const { errorMessages } = recipeBasicInformationStrings;

export const recipeValidator = new Validator({
  name: string().required(errorMessages.recipeNameField.required),
  author: object({
    name: string().required(errorMessages.recipeAuthorField.required),
  }),
  serves: number()
    // This is to avoid error messages of casting NaN
    .typeError(errorMessages.recipeServesField.required)
    .required(errorMessages.recipeServesField.required)
    .integer(errorMessages.recipeServesField.required)
    .positive(errorMessages.recipeServesField.required),
  totalTime: object().test({
    name: 'required',
    message: errorMessages.recipeTotalTimeField.required,
    test: hasTime,
  }),
  prepTime: object().test({
    name: 'required',
    message: errorMessages.recipePrepTimeField.required,
    test: hasTime,
  }),
  cookTime: object().test({
    name: 'required',
    message: errorMessages.recipeCookTimeField.required,
    test: hasTime,
  }),
});
