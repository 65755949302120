import { ApiCapabilitySettingType } from 'api/types/common/apiCapabilitySettingType';
import type { ApiRcpRecipeStepCapabilitySettingValueBooleanWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeStepCapabilitySettingValueBooleanWithRefs';
import type { ApiRcpRecipeStepCapabilitySettingValueNominalWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeStepCapabilitySettingValueNominalWithRefs';
import type { ApiRcpRecipeStepCapabilitySettingValueNumericWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeStepCapabilitySettingValueNumericWithRefs';
import { fromAppRecipeValueInMultipleSystems } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeValueInMultipleSystemsWithRefs';
import { fromAppRecipeValueWithUnit } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeValueWithUnitWithRefs';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import type { AppRecipeStepCapabilitySettingValue } from 'types/recipe/appRecipeStepCapabilitySettingValue';
import { fromAppTimeToSeconds } from 'utils/convertTimes';
import { isValueInMultipleSystems } from 'utils/unitSystems';

export type ApiRcpRecipeStepCapabilitySettingValueWithRefs =
  | ApiRcpRecipeStepCapabilitySettingValueBooleanWithRefs
  | ApiRcpRecipeStepCapabilitySettingValueNumericWithRefs
  | ApiRcpRecipeStepCapabilitySettingValueNominalWithRefs;

export function fromAppRecipeStepCapabilitySettingValue(
  value: AppRecipeStepCapabilitySettingValue
): ApiRcpRecipeStepCapabilitySettingValueWithRefs {
  const { type } = value;
  if (type === AppCapabilitySettingType.Numeric) {
    return {
      type: ApiCapabilitySettingType.Numeric,
      value: isValueInMultipleSystems(value.value)
        ? fromAppRecipeValueInMultipleSystems(value.value)
        : fromAppRecipeValueWithUnit(value.value),
    };
  }
  if (type === AppCapabilitySettingType.Time) {
    return {
      type: ApiCapabilitySettingType.Numeric,
      value: {
        amount: fromAppTimeToSeconds(value.value),
        referenceUnitId: value.unit.id,
      },
    };
  }
  if (type === AppCapabilitySettingType.Nominal) {
    return {
      type: ApiCapabilitySettingType.Nominal,
      referenceValueId: value.referenceValue.id,
    };
  }
  return {
    type: ApiCapabilitySettingType.Boolean,
    value: value.value,
  };
}
