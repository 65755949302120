export const recipeAdvancedSearchStrings = {
  title: 'Advanced Search',
  noFilters: 'No filters have been applied to this search yet.',
  buttons: {
    search: 'Search',
    newFilter: 'New filter',
    togglePanel: 'Toggle Advanced Search',
  },
} as const;

export const recipeAdvancedSearchConstants = {
  panel: {
    id: 'advanced-search-panel',
  },
  filters: {
    maxAmount: 5,
  },
} as const;
