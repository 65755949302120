import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { generateRecipeRoute } from 'app/routes/routesUtils';
import { useAppSelector } from 'app/store/hooks';
import {
  RecipeTabName,
  recipePageConstants,
} from 'features/recipe/RecipePage.constants';
import { recipeIngredientFormConstants } from 'features/recipe/ingredients/form/RecipeIngredientForm.constants';
import {
  ingredientFormShown,
  ingredientIndexUpdated,
  ingredientReset,
} from 'features/recipe/ingredients/form/recipeIngredientFormSlice';
import type { AppRecipeIngredientsUsage } from 'features/recipe/recipeSlice';
import {
  createQuantity,
  selectRecipeIngredientUsage,
} from 'features/recipe/recipeSlice';
import { recipeIngredientIssueStrings } from 'features/recipe/shared/RecipeIngredientIssue/RecipeIngredientIssue.constants';
import { RecipeIssue } from 'features/recipe/shared/RecipeIssue/RecipeIssue';
import { recipeValueInMultipleSystemsStrings } from 'features/recipe/shared/RecipeValueInMultipleSystems/RecipeValueInMultipleSystems.constants';
import { roundIngredientAmountLeft } from 'features/recipe/shared/utils/recipeIngredientsUtils';
import type { AppUnitSystem } from 'types/appUnitSystem';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import type { AppRecipeValueInMultipleSystems } from 'types/recipe/appRecipeValueInMultipleSystems';
import {
  getSecondaryUnitSystem,
  isValueInSingleSystem,
} from 'utils/unitSystems';

// Warnings are a record defined by a field and a message: Record<field id, message>
export type RecipeIngredientWarning = Record<string, string>;

export interface RecipeIngredientIssueProps {
  ingredient: AppRecipeIngredient;
  index: number;
  primaryUnitSystem: AppUnitSystem;
  recipeId?: ApiRcpRecipeId;
}

const { fieldIds } = recipeIngredientFormConstants;
const { warningMessages, errorMessages } = recipeIngredientIssueStrings;

export const RecipeIngredientIssue: FC<RecipeIngredientIssueProps> = memo(
  function RecipeIngredientIssue({
    ingredient,
    index,
    recipeId,
    primaryUnitSystem,
  }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ingredientUsage = useAppSelector(selectRecipeIngredientUsage(index));
    const warnings = getIngredientUsageWarnings(
      ingredient,
      ingredientUsage,
      primaryUnitSystem
    );

    const onFixIssueClick = () => {
      dispatch(ingredientReset());
      dispatch(ingredientIndexUpdated(index));
      dispatch(ingredientFormShown(true));
      navigate(generateFixIngredientRoute(recipeId, warnings));
    };

    return (
      <RecipeIssue
        warnings={Object.values(warnings)}
        onClick={onFixIssueClick}
        sx={{ mt: 1 }}
      />
    );
  }
);

export const generateFixIngredientRoute = (
  recipeId: ApiRcpRecipeId | undefined,
  warnings: RecipeIngredientWarning
): string => {
  const autofocusedFieldId = (() => {
    return !isEmpty(warnings) ? Object.keys(warnings)[0] : fieldIds.ingredient;
  })();

  return generateRecipeRoute({
    tab: RecipeTabName.Ingredients,
    id: recipeId,
    autofocusedFieldId,
  });
};

export const getIngredientUsageWarnings = (
  ingredient: AppRecipeIngredient,
  ingredientUsage: AppRecipeIngredientsUsage | undefined,
  primaryUnitSystem: AppUnitSystem
): RecipeIngredientWarning => {
  if (!ingredientUsage) {
    return {};
  }

  if (isValueInSingleSystem(ingredient.quantity)) {
    if (ingredient.quantity.amount === null) {
      return ingredientUsage.used.steps.length === 0
        ? { [fieldIds.quantity]: warningMessages.unusedIngredient }
        : {};
    }

    if (
      !isValueInSingleSystem(ingredientUsage.used.quantity) ||
      !isValueInSingleSystem(ingredientUsage.left) ||
      ingredientUsage.used.quantity.amount === null ||
      ingredientUsage.left.amount === null
    ) {
      throw new Error(errorMessages.invalidUsageAmounts);
    }

    const amountLeft = getUnusedQuantity({
      usedAmount: ingredientUsage.used.quantity.amount,
      leftAmount: ingredientUsage.left.amount,
      totalAmount: ingredient.quantity.amount,
      unit: ingredient.quantity.unit,
    });
    return amountLeft?.amount
      ? {
          [fieldIds.quantity]: warningMessages.unusedIngredientWithAmount(
            amountLeft.text ?? ''
          ),
        }
      : {};
  }

  if (
    isValueInSingleSystem(ingredientUsage.used.quantity) ||
    isValueInSingleSystem(ingredientUsage.left) ||
    ingredient.quantity.metric.amount === null ||
    ingredient.quantity.usCustomary.amount === null ||
    ingredientUsage.used.quantity.metric.amount === null ||
    ingredientUsage.used.quantity.usCustomary.amount === null ||
    ingredientUsage.left.metric.amount === null ||
    ingredientUsage.left.usCustomary.amount === null
  ) {
    throw new Error(errorMessages.invalidUsageAmounts);
  }

  const quantityLeft: AppRecipeValueInMultipleSystems = {
    metric: getUnusedQuantity({
      usedAmount: ingredientUsage.used.quantity.metric.amount,
      leftAmount: ingredientUsage.left.metric.amount,
      totalAmount: ingredient.quantity.metric.amount,
      unit: ingredient.quantity.metric.unit,
    }),
    usCustomary: getUnusedQuantity({
      usedAmount: ingredientUsage.used.quantity.usCustomary.amount,
      leftAmount: ingredientUsage.left.usCustomary.amount,
      totalAmount: ingredient.quantity.usCustomary.amount,
      unit: ingredient.quantity.usCustomary.unit,
    }),
  };
  if (!quantityLeft.metric.amount && !quantityLeft.usCustomary.amount) {
    return {};
  }

  const secondaryUnitSystem = getSecondaryUnitSystem(primaryUnitSystem);
  const amountLeftBothSystems = `${quantityLeft[primaryUnitSystem].text ?? ''} ${recipeValueInMultipleSystemsStrings.separator} ${quantityLeft[secondaryUnitSystem].text ?? ''}`;
  const ids: Record<AppUnitSystem, string> = {
    metric: fieldIds.metricQuantity,
    usCustomary: fieldIds.usCustomaryQuantity,
  };
  const fieldWithWarning = quantityLeft[primaryUnitSystem].amount
    ? ids[primaryUnitSystem]
    : ids[secondaryUnitSystem];
  return {
    [fieldWithWarning]: warningMessages.unusedIngredientWithAmount(
      amountLeftBothSystems
    ),
  };
};

const hasUnusedAmount = ({
  usedAmount,
  leftAmount,
  totalAmount,
}: {
  usedAmount: number;
  leftAmount: number;
  totalAmount: number;
}) =>
  usedAmount < totalAmount &&
  leftAmount > recipePageConstants.publish.unusedIngredientThreshold;

const getUnusedQuantity = ({
  usedAmount,
  leftAmount,
  totalAmount,
  unit,
}: {
  usedAmount: number;
  leftAmount: number;
  totalAmount: number;
  unit: ApiQuantityUnit;
}) => {
  if (
    hasUnusedAmount({
      usedAmount,
      leftAmount,
      totalAmount,
    })
  ) {
    return createQuantity({
      unit,
      amount: roundIngredientAmountLeft(leftAmount),
    });
  }
  return createQuantity({
    unit,
    amount: roundIngredientAmountLeft(0),
  });
};
