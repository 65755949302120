import {
  PantryTypography,
  Tooltip,
  TooltipPosition,
} from '@dropkitchen/pantry-react';
import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import type { ApiTag } from 'api/types/common/apiTag';
import { recipesListConstants } from 'features/recipes/list/RecipesList.constants';

const { table } = recipesListConstants;

interface AppliancesCellProps {
  appliances?: ApiTag[];
}

export const AppliancesCell: FC<AppliancesCellProps> = memo(
  function AppliancesCell({ appliances }) {
    if (!appliances?.length) {
      return table.emptyCell;
    }
    const firstAppliance = appliances[0];
    if (
      appliances.length === 1 &&
      firstAppliance.name.length <= table.maxApplianceTagNameLength
    ) {
      return (
        <Typography variant={PantryTypography.Body2}>
          {firstAppliance.name}
        </Typography>
      );
    }
    return (
      <Tooltip
        message={generateApplianceTooltip(appliances)}
        hideArrow
        placement={TooltipPosition.BottomStart}
      >
        <Typography
          variant={PantryTypography.Body2}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '180px',
          }}
        >
          <Box component="span">{firstAppliance.name}</Box>
          <Box component="span">{appliances.length > 1 && ' ...'}</Box>
        </Typography>
      </Tooltip>
    );
  }
);

export const generateApplianceTooltip = (tags: ApiTag[]) =>
  tags.map(({ name }) => name).join(', ');
