import { createApiFn, fetchJson } from 'api/fetchJson';
import { apiMeasurementSystemsUrl } from 'api/paths';
import type { ApiList, ApiRequestFn, ApiResponse } from 'api/types';
import { HttpMethod } from 'api/types';
import type { ApiMeasurementSystem } from 'api/types/common/apiMeasurementSystem';
import type { ApiRefLocalizedDataRequest } from 'api/types/referenceData/apiRefLocalizedDataRequest';
import { appConfig } from 'config/config';

export const getMeasurementSystems = async ({
  locale,
  from,
}: ApiRefLocalizedDataRequest) =>
  fetchJson<ApiResponse<ApiList<ApiMeasurementSystem>>>({
    httpMethod: HttpMethod.Post,
    url: `${apiMeasurementSystemsUrl}?size=${appConfig.referenceDataApiPageSize()}&from=${from}`,
    body: { locale },
  });

export const apiGetMeasurementSystems: ApiRequestFn<
  ApiResponse<ApiList<ApiMeasurementSystem>>,
  ApiRefLocalizedDataRequest
> = createApiFn(getMeasurementSystems);
