import { PantryColor } from '@dropkitchen/pantry-react';
import { Box } from '@mui/material';
import { memo } from 'react';

import type { ImageSize } from 'components/Image/Image';

export interface ImageErrorProps {
  text?: string;
  size: ImageSize;
  borderRadius?: number;
}

export const ImageError = memo(function DefaultErrorComponent({
  text,
  borderRadius = 1,
  size,
}: ImageErrorProps) {
  return (
    <Box
      sx={{
        ...size,
        borderRadius,
        display: 'flex',
        backgroundColor: PantryColor.SurfaceMuted,
        alignItems: 'center',
        justifyContent: 'center',
        color: PantryColor.TextMuted,
      }}
    >
      {text}
    </Box>
  );
});
