export const fromBytesToText = (bytes: number): string => {
  const factor = 1024;

  if (bytes < factor) return `${bytes}B`;

  if (bytes < factor * factor) return `${bytes / factor}KB`;

  return `${bytes / (factor * factor)}MB`;
};

/**
 * @param allowedMediaTypes Example:
 * {
 *   'image/jpeg': ['.jpg', '.jpeg'],
 *   'image/png': ['.png'],
 *   'image/webp': ['.webp'],
 * }
 * @returns string 'jpg/jpeg/png/webp
 */
export const fromAllowedMediaTypesToText = (
  allowedMediaTypes: Record<string, string[]>
) =>
  Object.values(allowedMediaTypes)
    .reduce(
      (prev, current) =>
        `${prev}${current.map((item) => item.substring(1)).join('/')}/`,
      ''
    )
    .slice(0, -1);
