export const capabilityFieldParams = {
  minPlaceholder: 'minValue',
  maxPlaceholder: 'maxValue',
  stepPlaceholder: 'stepValue',
  unitAbbreviationPlaceholder: 'unitAbbreviation',
  settingNamePlaceholder: 'settingName',
  settingTypePlaceholder: 'settingType',
  capabilityNamePlaceholder: 'capabilityName',
  nominalValuePlaceholder: 'nominalValue',
} as const;

export const capabilityFieldStrings = {
  labels: {
    nominalRadioButton: 'Nominal',
    numericRadioButton: 'Numeric',
    generalCapabilityField: 'Capability',
    presetCapabilityField: 'Preset',
    phaseField: 'Phase',
    capabilityTypeRadio: {
      group: 'Choose capability type',
      general: 'General',
      appliancePreset: 'Appliance Preset',
    },
    settingsSectionTitle: 'Settings',
    incompatibilitiesSectionTitle: 'Appliance preset incompatibility',
    suggestedCapabilitiesGroup: 'Suggested capabilities',
    otherCapabilitiesGroup: 'Other capabilities',
  },
  placeholders: {
    generalCapabilityField: 'Select capability',
    presetCapabilityField: 'Select preset',
    phaseField: 'Select phase',
  },
  errors: {
    numericSetting: {
      min: `Enter a value greater than ${capabilityFieldParams.minPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
      max: `Enter a value lower than ${capabilityFieldParams.maxPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
      minAndMax: `Enter a value between ${capabilityFieldParams.minPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder} and ${capabilityFieldParams.maxPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
      step: `Enter a multiple of ${capabilityFieldParams.stepPlaceholder}`,
      stepOf1: 'Enter a whole number',
      stepWithMin: `Enter a value greater than ${capabilityFieldParams.minPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder} in steps of ${capabilityFieldParams.stepPlaceholder}`,
    },
    timeSetting: {
      min: `Enter a value greater than ${capabilityFieldParams.minPlaceholder}`,
      max: `Enter a value lower than ${capabilityFieldParams.maxPlaceholder}`,
      minAndMax: `Enter a value between ${capabilityFieldParams.minPlaceholder} and ${capabilityFieldParams.maxPlaceholder}`,
      step: `Enter a multiple of ${capabilityFieldParams.stepPlaceholder}`,
      stepWithMin: `Enter a value greater than ${capabilityFieldParams.minPlaceholder} in steps of ${capabilityFieldParams.stepPlaceholder}`,
    },
  },
  incompatibilities: {
    numericSetting: {
      max: `Only supports ${capabilityFieldParams.settingNamePlaceholder} smaller than ${capabilityFieldParams.maxPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
      min: `Only supports ${capabilityFieldParams.settingNamePlaceholder} greater than ${capabilityFieldParams.minPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
      minAndMax: `Only supports ${capabilityFieldParams.settingNamePlaceholder} between ${capabilityFieldParams.minPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder} and ${capabilityFieldParams.maxPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
      step: `Only supports ${capabilityFieldParams.settingNamePlaceholder} ranges in increments of ${capabilityFieldParams.stepPlaceholder}`,
      stepOf1: `Only supports whole numbers for ${capabilityFieldParams.settingNamePlaceholder}`,
      stepWithMin: `Only supports ${capabilityFieldParams.settingNamePlaceholder} ranges in increments of ${capabilityFieldParams.stepPlaceholder} starting from ${capabilityFieldParams.minPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
    },
    timeSetting: {
      min: `Only supports ${capabilityFieldParams.settingNamePlaceholder} greater than ${capabilityFieldParams.minPlaceholder}`,
      max: `Only supports ${capabilityFieldParams.settingNamePlaceholder} smaller than ${capabilityFieldParams.maxPlaceholder}`,
      minAndMax: `Only supports ${capabilityFieldParams.settingNamePlaceholder} between ${capabilityFieldParams.minPlaceholder} and ${capabilityFieldParams.maxPlaceholder}`,
      step: `Only supports ${capabilityFieldParams.settingNamePlaceholder} ranges in increments of ${capabilityFieldParams.stepPlaceholder}`,
      stepWithMin: `Only supports ${capabilityFieldParams.settingNamePlaceholder} ranges in increments of ${capabilityFieldParams.stepPlaceholder} starting from ${capabilityFieldParams.minPlaceholder}`,
    },
    nominalSetting: `Doesn't support option ${capabilityFieldParams.nominalValuePlaceholder} for ${capabilityFieldParams.settingNamePlaceholder}`,
    setting: `Doesn't support setting ${capabilityFieldParams.settingNamePlaceholder}`,
    settingType: `Doesn't support ${capabilityFieldParams.settingTypePlaceholder} values for setting ${capabilityFieldParams.settingNamePlaceholder}`,
    capability: `Doesn't support capability ${capabilityFieldParams.capabilityNamePlaceholder}`,
    dependency: {
      main: `${capabilityFieldParams.settingNamePlaceholder} setting requires:`,
      nominalAndBoolean: `${capabilityFieldParams.settingNamePlaceholder} setting to be ${capabilityFieldParams.nominalValuePlaceholder}`,
      numeric: {
        min: `${capabilityFieldParams.settingNamePlaceholder} setting to be greater than ${capabilityFieldParams.minPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
        max: `${capabilityFieldParams.settingNamePlaceholder} setting to be smaller than ${capabilityFieldParams.maxPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
        minAndMax: `${capabilityFieldParams.settingNamePlaceholder} setting to be between ${capabilityFieldParams.minPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder} and ${capabilityFieldParams.maxPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
        step: `${capabilityFieldParams.settingNamePlaceholder} setting to be ranges in increments of ${capabilityFieldParams.stepPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
        stepOf1: `${capabilityFieldParams.settingNamePlaceholder} setting to be a whole number`,
        stepWithMin: `${capabilityFieldParams.settingNamePlaceholder} setting to be ranges in increments of ${capabilityFieldParams.stepPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder} starting from ${capabilityFieldParams.minPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
      },
      time: {
        min: `${capabilityFieldParams.settingNamePlaceholder} setting to be greater than ${capabilityFieldParams.minPlaceholder}`,
        max: `${capabilityFieldParams.settingNamePlaceholder} setting to be smaller than ${capabilityFieldParams.maxPlaceholder}`,
        minAndMax: `${capabilityFieldParams.settingNamePlaceholder} setting to be between ${capabilityFieldParams.minPlaceholder} and ${capabilityFieldParams.maxPlaceholder}`,
        step: `${capabilityFieldParams.settingNamePlaceholder} setting to be ranges in increments of ${capabilityFieldParams.stepPlaceholder}`,
        stepWithMin: `${capabilityFieldParams.settingNamePlaceholder} setting to be ranges in increments of ${capabilityFieldParams.stepPlaceholder} starting from ${capabilityFieldParams.minPlaceholder}`,
      },
    },
  },
} as const;

export const capabilityFieldConstants = {
  fieldIds: {
    capabilityType: 'capability-type-label',
    capability: 'step-selected-capability',
    phase: 'phase-selector',
  },
} as const;
