import {
  Button,
  ButtonSize,
  ButtonStyle,
  PantryColor,
  PantryTypography,
  RefreshIcon,
} from '@dropkitchen/pantry-react';
import { Box, Link as MuiLink, Typography } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-pro';
import type { FC, SyntheticEvent } from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import type { ApiDateISO8601 } from 'api/types/common/apiDateISO8601';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiTag } from 'api/types/common/apiTag';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import type { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { RecipeType } from 'app/routes/constants';
import { generateRecipeRoute } from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { LocaleStatusBadge } from 'components/Badge/LocaleStatusBadge';
import { RecipeStatusBadge } from 'components/Badge/RecipeStatusBadge';
import { RecipesSearch } from 'components/RecipesSearch/RecipesSearch';
import {
  selectRecipesSearchTerm,
  selectRecipesAppliedFilters,
} from 'components/RecipesSearch/recipesSearchSlice';
import { AppliancesCell } from 'components/Table/AppliancesCell/AppliancesCell';
import type { TableColumn, TableRow } from 'components/Table/Table';
import { Table } from 'components/Table/Table';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { selectConfigsIsFeatureEnabled } from 'features/configs/configsSlice';
import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import { EmptyRecipes } from 'features/recipes/list/EmptyRecipes';
import {
  recipesListConstants,
  recipesListStrings,
} from 'features/recipes/list/RecipesList.constants';
import {
  RecipesListTranslateAction,
  RecipesListChangeStatusAction,
  RecipesListDeleteAction,
} from 'features/recipes/list/RecipesListActions';
import { RecipesListMainInfoCell } from 'features/recipes/list/RecipesListMainInfoCell';
import {
  RecipesListApplianceSkeleton,
  RecipesListDateSkeleton,
  RecipesListIdSkeleton,
  RecipesListSkeleton,
  RecipesListMainInfoSkeleton,
  RecipesListStateSkeleton,
  RecipesListTranslationsSkeleton,
  RecipesListLocaleSkeleton,
} from 'features/recipes/list/RecipesListSkeleton';
import {
  recipesFetchRequested,
  recipesPaginated,
  selectRecipes,
  selectRecipesFetching,
  selectRowsLoading,
  selectRecipesPage,
  selectRecipesRowCount,
  selectRecipesRowsPerPage,
  selectRecipeTranslations,
  recipeTranslationsFetchRequested,
  selectRecipeType,
} from 'features/recipes/recipesSlice';
import { appDisplayCodeByLocale } from 'types/appDisplayCodeByLocale';
import { AppFeature } from 'types/appFeature';
import { formatDateForLists, fromISOToDate } from 'utils/date';

const { table } = recipesListConstants;
const { headers } = recipesListStrings;

export interface RecipeTranslationCell {
  id: ApiRcpRecipeId;
  locale: ApiLocale;
  state: ApiRcpRecipeState;
}

export interface RecipeRow extends TableRow {
  name: string;
  state: ApiRcpRecipeState;
  appliances?: ApiTag[];
  author: string;
  modifiedAt: string;
  imageSource: string;
  translations?: RecipeTranslationCell[];
  locale: ApiLocale;
  forkedFromId?: ApiRcpRecipeId;
}

export const RecipesList: FC = function RecipesList() {
  const dispatch = useAppDispatch();
  const recipes = useAppSelector(selectRecipes);
  const page = useAppSelector(selectRecipesPage);
  const rowsPerPage = useAppSelector(selectRecipesRowsPerPage);
  const isLoading = useAppSelector(selectRecipesFetching);
  const rowCount = useAppSelector(selectRecipesRowCount);
  const rowsLoading = useAppSelector(selectRowsLoading);
  const recipeTranslations = useAppSelector(selectRecipeTranslations);
  const searchTerm = useAppSelector(selectRecipesSearchTerm);
  const recipeType = useAppSelector(selectRecipeType);
  const appliedFilters = useAppSelector(selectRecipesAppliedFilters);
  const isTranslationManagementEnabled = useAppSelector(
    selectConfigsIsFeatureEnabled(AppFeature.TranslationManagement)
  );

  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);

  useEffect(() => {
    dispatch(recipesFetchRequested());
  }, [dispatch, searchTerm, appliedFilters, page, rowsPerPage]);

  const columns: (TableColumn | null)[] = [
    {
      field: 'recipe-main-info',
      headerName: headers.mainInfo,
      ...table.columns.mainInfo,
      renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
        if (rowsLoading[row.id]) {
          return <RecipesListMainInfoSkeleton />;
        }
        return (
          <RecipesListMainInfoCell
            id={row.id}
            name={row.name}
            author={row.author}
            to={{
              href: generateRecipeRoute({
                id: row.id,
                tab: RecipeTabName.Information,
              }),
            }}
          />
        );
      },
    },
    {
      field: 'state',
      headerName: headers.status,
      ...table.columns.status,
      renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
        if (rowsLoading[row.id]) {
          return <RecipesListStateSkeleton />;
        }
        return <RecipeStatusBadge status={row.state} />;
      },
    },
    isTranslationManagementEnabled
      ? {
          field: 'locale',
          headerName: headers.locale,
          ...table.columns.locale,
          renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
            if (rowsLoading[row.id]) {
              return <RecipesListLocaleSkeleton />;
            }
            return appDisplayCodeByLocale[row.locale];
          },
        }
      : null,
    isTranslationManagementEnabled && recipeType === RecipeType.Core
      ? {
          field: 'translations',
          headerName: headers.translations,
          ...table.columns.translations,
          renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
            const {
              fetching: isFetching,
              apiError: hasApiError,
              translations,
            } = recipeTranslations[row.id] || {};
            if (rowsLoading[row.id] || isFetching) {
              return <RecipesListTranslationsSkeleton />;
            }
            if (hasApiError) {
              return (
                <Button
                  label={table.actions.translations.retryButton}
                  size={ButtonSize.Small}
                  buttonStyle={ButtonStyle.Default}
                  leadingIcon={RefreshIcon}
                  onClick={() =>
                    dispatch(
                      recipeTranslationsFetchRequested({ recipeId: row.id })
                    )
                  }
                />
              );
            }
            if (!translations?.length) {
              return table.emptyCell;
            }
            return (
              <Box sx={{ display: 'flex', flexFlow: 'wrap', gap: 1 }}>
                {translations.map(({ id, state, locale }) => (
                  <LocaleStatusBadge
                    key={id}
                    status={state}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    locale={locale!}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    recipeId={id!}
                  />
                ))}
              </Box>
            );
          },
        }
      : null,
    {
      field: 'appliances',
      headerName: headers.appliances,
      ...table.columns.appliances,
      renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
        if (rowsLoading[row.id]) {
          return <RecipesListApplianceSkeleton />;
        }
        return <AppliancesCell appliances={row.appliances} />;
      },
    },
    {
      field: 'id',
      headerName: headers.id,
      ...table.columns.id,
      renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
        if (rowsLoading[row.id]) {
          return <RecipesListIdSkeleton />;
        }
        const idLink = (
          <MuiLink
            component={Link}
            to={generateRecipeRoute({
              id: row.id,
              tab: RecipeTabName.Information,
            })}
          >
            <Typography
              variant={PantryTypography.Body2}
              sx={{
                color: PantryColor.TextDefault,
              }}
            >
              {row.id}
            </Typography>
          </MuiLink>
        );
        if (hoveredRowId !== row.id || recipeType === RecipeType.Translation) {
          return idLink;
        }
        return (
          <ToggleableFeature
            requires={AppFeature.TranslationManagement}
            components={{ whenDisabled: idLink }}
          >
            <RecipesListChangeStatusAction
              row={row}
              onAction={() => setHoveredRowId(null)}
            />
          </ToggleableFeature>
        );
      },
    },
    {
      field: 'modifiedAt',
      headerName: headers.modifiedAt,
      ...table.columns.modifiedAt,
      renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
        if (rowsLoading[row.id]) {
          return <RecipesListDateSkeleton />;
        }
        if (hoveredRowId !== row.id) {
          return (
            <Typography variant={PantryTypography.Body2}>
              {row.modifiedAt}
            </Typography>
          );
        }
        if (recipeType === RecipeType.Translation) {
          return (
            <>
              <RecipesListChangeStatusAction
                row={row}
                onAction={() => setHoveredRowId(null)}
              />
              <RecipesListDeleteAction
                row={row}
                onAction={() => setHoveredRowId(null)}
              />
            </>
          );
        }
        return (
          <>
            <ToggleableFeature
              requires={AppFeature.TranslationManagement}
              components={{
                whenDisabled: (
                  <RecipesListChangeStatusAction
                    row={row}
                    onAction={() => setHoveredRowId(null)}
                  />
                ),
              }}
            >
              <RecipesListTranslateAction
                row={row}
                existingTranslations={recipeTranslations[row.id]?.translations}
                onAction={() => setHoveredRowId(null)}
              />
            </ToggleableFeature>
            <RecipesListDeleteAction
              row={row}
              onAction={() => setHoveredRowId(null)}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ mb: 8 }}>
        <RecipesSearch sx={{ width: '832px' }} />
      </Box>
      {!recipes?.length && !isLoading ? (
        <EmptyRecipes />
      ) : (
        <Table
          ariaLabel={table.ariaLabel}
          rowHeight={table.rowHeight}
          columns={columns.filter(
            (column): column is TableColumn => column !== null
          )}
          rows={recipes.map(
            ({
              id,
              name,
              author,
              state,
              modifiedAt,
              applianceReferenceTags,
              locale,
              forkedFromId,
            }) => ({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              id: id!,
              name,
              state,
              modifiedAt: formatDateForLists(
                fromISOToDate(modifiedAt as ApiDateISO8601)
              ),
              author: author.name,
              appliances: applianceReferenceTags,
              locale,
              forkedFromId,
            })
          )}
          page={page}
          rowsPerPage={rowsPerPage}
          loading={isLoading}
          rowCount={rowCount}
          components={{
            loadingOverlay: RecipesListSkeleton,
          }}
          componentsProps={{
            row: {
              onMouseEnter: (event: SyntheticEvent<HTMLElement>) => {
                event.preventDefault();
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                setHoveredRowId(event.currentTarget.dataset.id!);
              },
              onMouseLeave: (event: SyntheticEvent<HTMLElement>) => {
                event.preventDefault();
                setHoveredRowId(null);
              },
            },
          }}
          onPaginationChange={(event) => dispatch(recipesPaginated(event))}
        />
      )}
    </>
  );
};
