import upperFirst from 'lodash/upperFirst';

import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRcpRecipeStepCapabilitySetting } from 'api/types/recipe/apiRcpRecipeStepCapabilitySetting';
import {
  fromApiRcpRecipeStepCapabilitySettingValue,
  type AppRecipeStepCapabilitySettingValue,
} from 'types/recipe/appRecipeStepCapabilitySettingValue';

export interface AppRecipeStepCapabilitySetting {
  id: ApiEntityId;
  name: string;
  value: AppRecipeStepCapabilitySettingValue;
}

export function fromApiRcpRecipeStepCapabilitySetting({
  referenceSetting,
  value,
}: ApiRcpRecipeStepCapabilitySetting): AppRecipeStepCapabilitySetting {
  return {
    id: referenceSetting.id,
    name: upperFirst(referenceSetting.name),
    value: fromApiRcpRecipeStepCapabilitySettingValue({
      referenceSetting,
      value,
    }),
  };
}
