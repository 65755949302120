import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { RootState } from 'app/store/rootReducer';
import {
  RecipesAdvancedSearchFilterType,
  RecipesAdvancedSearchKey,
  getRecipesAdvancedSearchFilterDefaultValue,
  recipesAdvancedSearchFilterOperators,
  validateRecipesAdvancedSearchFilter,
} from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.constants';
import type {
  RecipesAdvancedSearchAnchoredFilter,
  RecipesAdvancedSearchFilterErrors,
  RecipesAdvancedSearchFilters,
} from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.types';
import { selectConfigsFeatures } from 'features/configs/configsSlice';
import { AppFeature } from 'types/appFeature';

interface RecipesSearchFilters {
  keys: { key: RecipesAdvancedSearchKey; requires?: AppFeature }[];
  current: RecipesAdvancedSearchFilters[];
  applied: RecipesAdvancedSearchFilters[];
  hasApplied: boolean;
  errors: RecipesAdvancedSearchFilterErrors[];
}

export interface RecipesSearchState {
  searchTerm: string;
  filters: RecipesSearchFilters;
  isAdvancedSearchPanelOpen: boolean;
  /** The locale to use for the filter values. If not provided, user's locale will be used */
  filtersLocale?: ApiLocale;
}

export const initialState: RecipesSearchState = {
  searchTerm: '',
  filters: {
    keys: [
      { key: RecipesAdvancedSearchKey.ApplianceTags },
      { key: RecipesAdvancedSearchKey.GeneralTags },
      {
        key: RecipesAdvancedSearchKey.Locale,
        requires: AppFeature.TranslationManagement,
      },
      { key: RecipesAdvancedSearchKey.State },
      { key: RecipesAdvancedSearchKey.Author },
    ],
    current: [],
    applied: [],
    errors: [],
    hasApplied: false,
  },
  isAdvancedSearchPanelOpen: false,
};

const recipesSearchSlice = createSlice({
  name: 'recipesSearch',
  initialState,
  reducers: {
    recipesSearchTermUpdated(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
    recipesSearchAdvancedPanelOpened(state: RecipesSearchState) {
      state.isAdvancedSearchPanelOpen = true;
    },
    recipesSearchAdvancedPanelClosed(state: RecipesSearchState) {
      state.isAdvancedSearchPanelOpen = false;
    },
    recipesSearchFilterAdded(state: RecipesSearchState) {
      state.filters.hasApplied = false;

      const availableKeys = getAvailableFilterKeys(
        state.filters.current,
        state.filters.keys.map(({ key }) => key)
      );
      if (!availableKeys.length) {
        return;
      }
      const key = availableKeys[0];
      const operator = recipesAdvancedSearchFilterOperators[key][0];
      const value = getRecipesAdvancedSearchFilterDefaultValue(operator);
      const filter = {
        key,
        operator,
        value,
        type: RecipesAdvancedSearchFilterType.Manual,
      } as RecipesAdvancedSearchFilters;
      state.filters.current.push(filter);
      state.filters.errors.push(validateRecipesAdvancedSearchFilter(filter));
    },
    recipesSearchFilterUpdated(
      state: RecipesSearchState,
      {
        payload: { index, filter },
      }: PayloadAction<{
        index: number;
        filter: Partial<RecipesAdvancedSearchFilters>;
      }>
    ) {
      state.filters.hasApplied = false;

      if (
        filter.key === undefined &&
        filter.operator === undefined &&
        filter.value === undefined
      ) {
        throw new Error(
          'You have to provide at least one of the following properties: key, operator or value'
        );
      }
      const { key } = filter;
      if (key !== undefined) {
        const operator =
          key !== null ? recipesAdvancedSearchFilterOperators[key][0] : null;
        const value = getRecipesAdvancedSearchFilterDefaultValue(operator);
        state.filters.current[index].key = key;
        state.filters.current[index].operator = operator;
        state.filters.current[index].value = value;
      }
      const { operator } = filter;
      if (operator !== undefined) {
        state.filters.current[index].operator = operator;
        state.filters.current[index].value =
          getRecipesAdvancedSearchFilterDefaultValue(operator);
      }
      const { value } = filter;
      if (value !== undefined) {
        state.filters.current[index].value = value;
      }
      state.filters.errors[index] = validateRecipesAdvancedSearchFilter(
        state.filters.current[index]
      );
    },
    recipesSearchFilterDeleted(
      state: RecipesSearchState,
      { payload: index }: PayloadAction<number>
    ) {
      state.filters.hasApplied = false;
      state.filters.current.splice(index, 1);
      state.filters.errors.splice(index, 1);
    },
    recipesSearchFiltersApplied(state: RecipesSearchState) {
      state.filters.hasApplied = true;

      if (state.filters.errors.some((error) => !isEmpty(error))) {
        return;
      }

      state.filters.applied = state.filters.current;
      state.isAdvancedSearchPanelOpen = false;
    },
    recipesSearchFiltersAnchored(
      state: RecipesSearchState,
      { payload }: PayloadAction<RecipesAdvancedSearchAnchoredFilter[]>
    ) {
      /**
       * The order of the array is important for uniqBy.
       * If there are repeated filters, we want to keep the ones that are anchored
       * and discard the ones added manually
       */
      const current = uniqBy(
        [...payload, ...state.filters.current],
        'key' satisfies keyof RecipesAdvancedSearchFilters
      );
      const applied = uniqBy(
        [...payload, ...state.filters.applied],
        'key' satisfies keyof RecipesAdvancedSearchFilters
      );
      state.filters.current = current;
      state.filters.applied = applied;
      state.filters.errors = current.map(() => ({}));
    },
    recipesSearchCurrentFiltersReset(state: RecipesSearchState) {
      state.filters.current = state.filters.applied;
      state.filters.errors = state.filters.current.map(() => ({}));
    },
    recipesSearchClear(state: RecipesSearchState) {
      state.filters.current = state.filters.current.filter(
        (filter) => filter.type === RecipesAdvancedSearchFilterType.Anchored
      );
      state.filters.applied = state.filters.applied.filter(
        (filter) => filter.type === RecipesAdvancedSearchFilterType.Anchored
      );
      state.filters.errors = state.filters.current.map(() => ({}));
      state.filters.hasApplied = initialState.filters.hasApplied;
      state.searchTerm = initialState.searchTerm;
    },
    recipesSearchReset(state: RecipesSearchState) {
      state.filters.current = initialState.filters.current;
      state.filters.applied = initialState.filters.applied;
      state.filters.errors = state.filters.current.map(() => ({}));
      state.filters.hasApplied = initialState.filters.hasApplied;
      state.searchTerm = initialState.searchTerm;
    },
    recipesSearchFiltersLocaleUpdated(
      state: RecipesSearchState,
      { payload: locale }: PayloadAction<ApiLocale | undefined>
    ) {
      state.filtersLocale = locale;
    },
  },
});

export const {
  reducer: recipesSearchReducer,
  actions: {
    recipesSearchTermUpdated,
    recipesSearchAdvancedPanelOpened,
    recipesSearchAdvancedPanelClosed,
    recipesSearchFilterAdded,
    recipesSearchFilterUpdated,
    recipesSearchFilterDeleted,
    recipesSearchFiltersApplied,
    recipesSearchFiltersAnchored,
    recipesSearchCurrentFiltersReset,
    recipesSearchClear,
    recipesSearchReset,
    recipesSearchFiltersLocaleUpdated,
  },
} = recipesSearchSlice;

const selectRecipesSearchState = (state: RootState): RecipesSearchState =>
  state.recipesSearch;

export const selectRecipesSearchTerm = (state: RootState): string =>
  selectRecipesSearchState(state).searchTerm;

export const selectIsAdvancedSearchPanelOpen = (state: RootState) =>
  selectRecipesSearchState(state).isAdvancedSearchPanelOpen;

export const selectRecipesFilterKeys = createSelector(
  selectRecipesSearchState,
  selectConfigsFeatures,
  ({ filters }, features) =>
    filters.keys
      .filter(
        ({ requires }) => requires === undefined || !!features?.[requires]
      )
      .map(({ key }) => key)
);

export const selectRecipesCurrentFilters = (state: RootState) =>
  selectRecipesSearchState(state).filters.current;

export const selectRecipesAppliedFilters = (state: RootState) =>
  selectRecipesSearchState(state).filters.applied;

export const selectRecipesFilterErrors = (state: RootState) =>
  selectRecipesSearchState(state).filters.errors;

export const selectRecipesFilterErrorsByIndex = (index: number) =>
  createSelector(selectRecipesFilterErrors, (errors) => errors[index]);

export const selectRecipesHasAppliedFilters = (state: RootState) =>
  selectRecipesSearchState(state).filters.hasApplied;

export const selectRecipesManuallyAppliedFilters = (state: RootState) =>
  selectRecipesAppliedFilters(state).filter(
    ({ type }) => type === RecipesAdvancedSearchFilterType.Manual
  );

const getAvailableFilterKeys = (
  selectedFilters: RecipesAdvancedSearchFilters[],
  keys: RecipesAdvancedSearchKey[]
): RecipesAdvancedSearchKey[] =>
  keys.filter(
    (key) => !selectedFilters.some((selected) => key === selected.key)
  );

export const selectAvailableRecipesFilterKeys = createSelector(
  selectRecipesCurrentFilters,
  selectRecipesFilterKeys,
  getAvailableFilterKeys
);

export const selectRecipesSearchFiltersLocale = (state: RootState) =>
  selectRecipesSearchState(state).filtersLocale;
