export const homeFeedPreviewDialogStrings = {
  title: 'Preview Feed',
  labels: {
    cancelButton: 'Cancel',
    nextButton: 'Next',
    localeField: 'Locale',
  },
  placeholders: {
    localeField: 'Select locale',
  },
} as const;

export const homeFeedPreviewDialogConstants = {
  fieldIds: {
    locale: 'home-feed-locale',
  },
} as const;
