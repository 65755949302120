import type { ApiAplId } from 'api/types/appliance/apiAplId';
import type { AppCapability } from 'types/appCapability';

export type AppDisambiguatedApplianceCapabilities = Record<ApiAplId, string>;

export const disambiguateApplianceCapabilities = (
  capability: AppCapability[]
): AppDisambiguatedApplianceCapabilities => {
  const dictionary = capability.reduce(
    (names, { name }) => {
      if (!names[name]) {
        names[name] = 0;
      }
      names[name]++;
      return names;
    },
    {} as Record<string, number>
  );

  return capability.reduce((displayableNames, { id, name }) => {
    const partner = id.split(':')[1].replace(/[A-Z]/g, ' $&').trim();
    displayableNames[id as ApiAplId] =
      dictionary[name] > 1 ? `${name} - ${partner}` : name;
    return displayableNames;
  }, {} as AppDisambiguatedApplianceCapabilities);
};
