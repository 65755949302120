import { appliancesRestartableSagas } from 'features/appliances/appliancesSagas';
import { authRestartableSagas } from 'features/auth/authSagas';
import { homeFeedRestartableSagas } from 'features/homeFeed/HomeFeed/homeFeedSagas';
import { homeFeedAddCollectionRecipesDialogRestartableSagas } from 'features/homeFeed/HomeFeedAddCollectionRecipesDialog/homeFeedAddCollectionRecipesDialogSagas';
import { homeFeedCollectionRestartableSagas } from 'features/homeFeed/HomeFeedCollectionPage/homeFeedCollectionSagas';
import { homeFeedsRestartableSagas } from 'features/homeFeed/homeFeedsSagas';
import { mediaRestartableSagas } from 'features/media/mediaSagas';
import { recipeIngredientsFormRestartableSagas } from 'features/recipe/ingredients/form/recipeIngredientFormSagas';
import { recipeRestartableSagas } from 'features/recipe/recipeSagas';
import { recipeStepsFormRestartableSagas } from 'features/recipe/steps/form/recipeStepsFormSagas';
import { recipesRestartableSagas } from 'features/recipes/recipesSagas';
import { referenceDataRestartableSagas } from 'features/referenceData/referenceDataSagas';
import { recipeTranslationRestartableSagas } from 'features/translation/recipeTranslationSagas';

/**
 * Most of app sagas are using `takeEvery` so in case if they accidentally crash
 * it makes sense to report an error and restart them automatically so they could
 * continue handling other actions
 */
export const getRestartableSagas = (): ((...args: unknown[]) => unknown)[] => [
  ...authRestartableSagas,
  ...mediaRestartableSagas,
  ...recipeRestartableSagas,
  ...referenceDataRestartableSagas,
  ...recipesRestartableSagas,
  ...appliancesRestartableSagas,
  ...recipeTranslationRestartableSagas,
  ...recipeStepsFormRestartableSagas,
  ...recipeIngredientsFormRestartableSagas,
  ...homeFeedsRestartableSagas,
  ...homeFeedRestartableSagas,
  ...homeFeedCollectionRestartableSagas,
  ...homeFeedAddCollectionRecipesDialogRestartableSagas,
];
