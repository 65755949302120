import {
  PantryColor,
  pantryOnLightTheme,
  PantryTypography,
  WarningIcon,
} from '@dropkitchen/pantry-react';
import type { SxProps, ThemeOptions } from '@mui/material';
import {
  autocompleteClasses,
  checkboxClasses,
  createTheme,
} from '@mui/material';

export const stylesCenterContent: SxProps = {
  mx: 'auto',
  px: 16,
  width: '1440px',
};

/**
 * Mui uses an array of 25 different shadows. Here, additional shadows are created
 * to use in `sx` props. Once we have all defined, we should unify and add them to
 * theme ones
 */
export const frescoAdditionalShadows = {
  header: '0px 4px 25px rgba(0, 0, 0, 0.06)',
  modal: '0px 16px 32px 0px rgba(25, 26, 34, 0.10)',
};

export const themeOptions: ThemeOptions = {
  ...pantryOnLightTheme,
  palette: {
    ...pantryOnLightTheme.palette,
    primary: {
      main: pantryOnLightTheme.palette[PantryColor.FrescoPrimary],
    },
    divider: pantryOnLightTheme.palette[PantryColor.BorderDefault],
    background: {
      default: pantryOnLightTheme.palette[PantryColor.BackgroundDefault],
    },
    error: {
      main: pantryOnLightTheme.palette[PantryColor.TextCritical],
    },
  },
  components: {
    ...pantryOnLightTheme.components,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: <WarningIcon color={PantryColor.IconCritical} size={24} />,
        },
      },
      styleOverrides: {
        action: { color: pantryOnLightTheme.palette[PantryColor.IconDefault] },
        message: { color: pantryOnLightTheme.palette[PantryColor.TextDefault] },
        root: {
          borderRadius: '12px',
          fontSize: '12px',
          padding: '2px 14px 2px 17px',
          alignItems: 'center',
        },
        standardError: {
          backgroundColor:
            pantryOnLightTheme.palette[PantryColor.SurfaceCritical],
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',

          '&:hover': {
            color: pantryOnLightTheme.palette[PantryColor.FrescoPrimary],
          },
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          marginTop: '8px',
          color: pantryOnLightTheme.palette[PantryColor.TextDefault],
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: pantryOnLightTheme.palette[PantryColor.TextDefault],
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          [`.${autocompleteClasses.option}, .${autocompleteClasses.groupUl} .${autocompleteClasses.option}`]:
            {
              paddingLeft: pantryOnLightTheme.spacing(4),
              paddingRight: pantryOnLightTheme.spacing(4),

              '&[aria-disabled="true"]': {
                opacity: 1, // Disabled elements have their own style

                '&[aria-selected="true"]': {
                  backgroundColor: 'transparent',
                },
              },

              [`.${checkboxClasses.root}`]: {
                marginLeft: `-${pantryOnLightTheme.spacing(2)}`, // Move the checkbox to make it visually aligned with rest of elements - ignore its hover effect
                padding: `0 ${pantryOnLightTheme.spacing(2)}`,
              },
            },

          [`.${autocompleteClasses.groupLabel}`]: {
            color: pantryOnLightTheme.palette[PantryColor.TextDefault],
            padding: 0,
            margin: `${pantryOnLightTheme.spacing(
              3
            )} ${pantryOnLightTheme.spacing(4)}`,
            paddingBottom: pantryOnLightTheme.spacing(2),
            borderBottom: `1px solid ${
              pantryOnLightTheme.palette[PantryColor.BorderDefault]
            }`,
            ...pantryOnLightTheme.typography[PantryTypography.CaptionSemiBold],

            '&:empty': {
              display: 'none', // Hide option groups with empty titles
            },
          },
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);
