import {
  PantryColor,
  PantryTypography,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import { memo } from 'react';

export enum ReadOnlyFieldStyle {
  Default = 'default',
  Muted = 'muted',
  Alternative = 'alternative',
}

export interface ReadOnlyFieldProps {
  label: string;
  value: ReactNode | string | number;
  ariaLabel?: string;
  style?: ReadOnlyFieldStyle;
}

export const ReadOnlyField = memo(function ReadOnlyField({
  label,
  value,
  ariaLabel,
  style = ReadOnlyFieldStyle.Default,
}: ReadOnlyFieldProps) {
  const labelStyle: SxProps = (() => {
    if (style === ReadOnlyFieldStyle.Muted) {
      return {
        color: PantryColor.TextMuted,
        typography: PantryTypography.Caption,
      };
    }
    if (style === ReadOnlyFieldStyle.Alternative) {
      return {
        color: PantryColor.TextMuted,
        typography: PantryTypography.Overline,
      };
    }
    return {
      color: PantryColor.TextSubtle,
      typography: PantryTypography.Caption,
    };
  })();

  const valueStyle: SxProps = (() => {
    if (style === ReadOnlyFieldStyle.Muted) {
      return {
        color: PantryColor.TextMuted,
        typography: PantryTypography.Body2SemiBold,
      };
    }
    if (style === ReadOnlyFieldStyle.Alternative) {
      return {
        color: PantryColor.TextDefault,
        typography: PantryTypography.Body1,
      };
    }
    return {
      color: PantryColor.TextDefault,
      typography: PantryTypography.Body2SemiBold,
    };
  })();

  return (
    <Box display="block">
      <Typography
        aria-label={ariaLabel || label}
        sx={sxCompose({ textTransform: 'uppercase' }, labelStyle)}
      >
        {label}
      </Typography>
      <Box sx={sxCompose({ mt: 1 }, valueStyle)}>{value}</Box>
    </Box>
  );
});
