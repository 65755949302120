export const homeFeedSagasStrings = {
  errors: {
    validation: {
      title: 'Saving Error',
      message: 'There was an issue with saving your changes. Please try again.',
    },
    precondition: {
      title: 'Syncing conflict',
      message:
        'This feed has been modified by someone else since your last set of saved changes, please review the updated feed and place in any additional revisions required.',
    },
  },
} as const;
