import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import camelcaseKeys from 'camelcase-keys';
import omit from 'lodash/omit';

import { fetchJsonStrings } from 'api/fetchJson.constants';
import { getCurrentUserToken } from 'api/getCurrentUserToken';
import { HttpMethod, HttpError, ApiMimeType } from 'api/types';
import type {
  ErrorResponse,
  ErrorResponseDetails,
  ApiResponse,
  ApiRequestFn,
} from 'api/types';
import type { ApiError } from 'api/types/common/apiError';

const tryParseJson = <T>(responseText: string): T | undefined => {
  try {
    return JSON.parse(responseText) as T;
  } catch {
    return undefined;
  }
};

type MergeDataFromHeadersMap<T> = {
  [propertyName in keyof T]: string;
};

export interface FetchJsonParams<T> {
  url: string;
  body?: unknown;
  httpMethod?: HttpMethod;
  /**
   * Do not include current user Bearer token
   */
  isAnonymous?: boolean;
  mergeDataFromHeadersMap?: MergeDataFromHeadersMap<T>;
  signal?: AbortSignal;
  contentType?: string;
  headers?: HeadersInit;
}

/**
 * We create this custom class just to help us filter DataDog dashboards, views and monitors by error type.
 * Generally, we want to distinguish between API errors and runtime errors,
 * as the former are usually not caused by us.
 */
class ApiDataDogError extends Error {
  constructor(message: string, error: ErrorOptions) {
    super(message, error);
    this.name = 'ApiError';
  }
}

export const getError = (method: string, url: string, error?: unknown) =>
  new ApiDataDogError(`${method} ${url} failed`, { cause: error });

/**
 * Clean request parameters to prepare it for logging, removing sensitive data like bearer token
 */
export const cleanRequest = (request: RequestInit): RequestInit => {
  const { headers, ...rest } = request;
  const cleanHeaders = omit<HeadersInit>(
    headers,
    'Authorization'
  ) as HeadersInit;
  return { headers: cleanHeaders, ...rest };
};
/**
 * Sends HTTP request with `Accept: application/json`
 *
 * - if `body` is not passed - sends GET HTTP
 * - if `body` is `URLSearchParams` - sends POST with `Content-Type: application/x-www-form-urlencoded`
 * - if `body` is `FormData` - sends POST with `Content-Type: multipart/form-data`
 * - otherwise sends POST as `JSON.stringify(body)` with `Content-Type: application/json`
 */
export const fetchJson = async <TResponse>({
  url,
  body,
  httpMethod,
  isAnonymous,
  mergeDataFromHeadersMap,
  signal,
  contentType,
  headers,
}: FetchJsonParams<TResponse>): Promise<ApiResponse<TResponse>> => {
  let response: Response | undefined;
  const method: HttpMethod =
    httpMethod || (body ? HttpMethod.Post : HttpMethod.Get);

  const requestContentType = contentType ?? getContentType(body);

  const token = isAnonymous ? undefined : await getCurrentUserToken();
  if (!isAnonymous && !token) {
    return {
      ok: false,
      details: {
        message: fetchJsonStrings.errors.tokenMissing,
      },
    } as ErrorResponse;
  }

  const request: RequestInit = {
    method,
    body: getBody(body),
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: ApiMimeType.Json,
      ...(token && {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${token}`,
      }),
      ...(requestContentType && {
        'Content-Type': requestContentType,
      }),
      ...headers,
    },
    signal,
  };
  try {
    response = await fetch(url, request);

    if (!response.ok) {
      return await handleError({
        url,
        method,
        response,
        request,
        error: getError(method, url),
      });
    }

    const responseJson = tryParseJson<TResponse>(await response.text());
    let data = responseJson
      ? (camelcaseKeys(responseJson, {
          deep: true,
        }) as TResponse)
      : ({} as TResponse);
    if (mergeDataFromHeadersMap) {
      data = {
        ...data,
        ...getDataFromHeaders(response.headers, mergeDataFromHeadersMap),
      };
    }

    datadogLogs.logger.info(`${fetchJsonStrings.successLogSubject} ${url}`, {
      url,
      method,
      responseStatusText: response?.statusText,
      responseStatus: response?.status,
      // TODO: send user info??
    });

    return {
      data,
      ok: true,
    };
  } catch (error: unknown) {
    return handleError({
      url,
      method,
      error: getError(method, url, error),
      request,
    });
  }
};

export type ApiRequest<TResponse, TRequest> = TRequest extends undefined
  ? () => Promise<ApiResponse<TResponse>>
  : (request: TRequest) => Promise<ApiResponse<TResponse>>;

/**
 * Create a wrapper around a function that makes an API request.
 * The wrapper has the shape of an ApiRequestFn, it takes an AbortSignal an the function's request params
 * and executes the API request without passing the signal.
 * It allows us to have API requests that don't benefit from the cancellation mechanism.
 *
 * @param cb The function that makes the API request
 */
export const createApiFn = <TResponse, TRequest = undefined>(
  cb: ApiRequest<TResponse, TRequest>
): ApiRequestFn<TResponse, TRequest> => {
  const apiFn = (_signal: AbortSignal, request: TRequest) => {
    return cb(request);
  };

  return apiFn as TRequest extends undefined
    ? (_signal: AbortSignal) => ReturnType<typeof apiFn>
    : (_signal: AbortSignal, request: TRequest) => ReturnType<typeof apiFn>;
};

const handleError = async ({
  url,
  method,
  error,
  response,
  request,
}: {
  url: string;
  method: HttpMethod;
  error: ApiDataDogError;
  response?: Response;
  request: RequestInit;
}): Promise<ErrorResponse> => {
  const responseText = await response?.text();
  const details = responseText && getErrorDetails(responseText);
  const cleanedRequest = cleanRequest(request);
  const extraDetails: Record<string, unknown> = {
    url,
    method,
    details,
    responseStatusText: response?.statusText,
    responseStatus: response?.status,
    responseText,
    request: cleanedRequest,
  };

  const hasToReport =
    !(error.cause instanceof DOMException) ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    error.cause?.name !== HttpError.AbortError;

  if (document && hasToReport) {
    datadogRum.addError(error, {
      ...extraDetails,
    });
    datadogLogs.logger.error(String(error), {
      ...extraDetails,
    });
  }

  return {
    ok: false,
    details: details || {
      message: fetchJsonStrings.errors.generic,
    },
    httpStatus: response?.status,
    isAborted:
      error.cause instanceof DOMException &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      error.cause?.name === HttpError.AbortError,
  };
};

function getErrorDetails(responseText: string): ErrorResponseDetails {
  const responseJson = tryParseJson<ApiError>(responseText);

  return isPlatformError(responseJson)
    ? {
        platformError: responseJson,
        message: Array.isArray(responseJson.error.message)
          ? responseJson.error.message.map((x) => x.detail).join('\n')
          : responseJson.error.message,
      }
    : {
        message: responseText,
      };
}

export function isPlatformError(
  error: ApiError | undefined
): error is ApiError {
  if (!error || !('error' in error)) {
    return false;
  }
  return 'message' in error.error && 'type' in error.error;
}

function getBody(
  body: unknown
): string | URLSearchParams | FormData | ArrayBuffer {
  return body instanceof URLSearchParams ||
    body instanceof FormData ||
    body instanceof ArrayBuffer
    ? body
    : JSON.stringify(body);
}

function getDataFromHeaders<T extends { [key: string]: unknown }>(
  headers: Headers,
  mergeDataFromHeadersMap: MergeDataFromHeadersMap<T>
): { [key in keyof T]: string | null } {
  const data = {} as { [key in keyof T]: string | null };
  for (const key of Object.keys(mergeDataFromHeadersMap)) {
    data[key as keyof T] = headers.get(mergeDataFromHeadersMap[key]);
  }
  return data;
}

function getContentType(body: unknown): string | undefined {
  if (
    body &&
    !(body instanceof URLSearchParams) &&
    !(body instanceof FormData)
  ) {
    return ApiMimeType.Json;
  }
  return undefined;
}
