import { Skeleton, SkeletonVariant } from '@dropkitchen/pantry-react';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { useState, useCallback, useEffect, memo } from 'react';

import { MediaImageType, MediaResourceType, apiGetListImages } from 'api/media';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import type { ImageProps, ImageSize } from 'components/Image/Image';
import { Image } from 'components/Image/Image';
import { imageStrings } from 'components/Image/Image.constants';
import { ImageError } from 'components/Image/ImageError';

const { testIds } = imageStrings;

export interface RecipeImageProps extends ImageProps {
  recipeId: ApiRcpRecipeId | undefined;
  imageType?: MediaImageType;
}

export const RecipeImage: FC<RecipeImageProps> = memo(function RecipeImage({
  recipeId,
  imageType = MediaImageType.Hero,
  error = {},
  borderRadius = 1,
  width,
  maxWidth,
  height,
  maxHeight,
  ...props
}) {
  const [hasImage, setHasImage] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const fetchRecipeImagesCallback = useCallback(() => {
    if (!recipeId) {
      return;
    }

    setIsFetching(true);
    apiGetListImages({
      resourceType: MediaResourceType.Recipes,
      resourceId: recipeId,
    })
      .then((response) => {
        setIsFetching(false);
        if (!response.ok) {
          setHasImage(false);
          return;
        }
        if (response.data.items.includes(imageType)) {
          setHasImage(true);
        }
      })
      .catch(() => {
        setIsFetching(false);
        setHasImage(false);
      });
  }, [recipeId, imageType]);

  useEffect(() => {
    fetchRecipeImagesCallback();
  }, [fetchRecipeImagesCallback]);

  const size: ImageSize = { width, maxWidth, height, maxHeight };

  if (isFetching) {
    return (
      <Box data-testid={testIds.imageSkeleton}>
        <Skeleton
          variant={SkeletonVariant.Small}
          sx={{ borderRadius, width, height }}
        />
      </Box>
    );
  }

  if (!hasImage) {
    return (
      <Box sx={{ display: 'flex' }}>
        {error.component || (
          <ImageError
            text={error.text}
            size={size}
            borderRadius={borderRadius}
          />
        )}
      </Box>
    );
  }

  return (
    <Image error={error} borderRadius={borderRadius} {...size} {...props} />
  );
});
