import type { ApiAplCapabilityAllowedSettingValue } from 'api/types/appliance/apiAplCapabilityAllowedSettingValue';
import type { ApiAplCapabilitySettingValue } from 'api/types/appliance/apiAplCapabilitySettingValue';
import { ApiCapabilitySettingType } from 'api/types/common/apiCapabilitySettingType';
import type { ApiEntityId } from 'api/types/common/apiEntityId';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import { isTimeSettingId } from 'types/appCapabilitySettings.utils';
import type { AppRecipeStepCapabilitySettingValueBoolean } from 'types/recipe/appRecipeStepCapabilitySettingValueBoolean';
import type { AppRecipeStepCapabilitySettingValueNominal } from 'types/recipe/appRecipeStepCapabilitySettingValueNominal';
import type { AppRecipeStepCapabilitySettingValueNumeric } from 'types/recipe/appRecipeStepCapabilitySettingValueNumeric';
import type { AppRecipeStepCapabilitySettingValueTime } from 'types/recipe/appRecipeStepCapabilitySettingValueTime';
import { fromApiRcpRecipeValueInMultipleSystems } from 'types/recipe/appRecipeValueInMultipleSystems';
import { fromSecondsToAppTime } from 'utils/convertTimes';
import {
  isValueInMultipleSystems,
  isValueInMultipleSystemsArray,
  isValueInSingleSystem,
  isValueInSingleSystemArray,
} from 'utils/unitSystems';

export type AppCapabilitySettingDefaultValue =
  | AppRecipeStepCapabilitySettingValueBoolean
  | AppRecipeStepCapabilitySettingValueNumeric
  | AppRecipeStepCapabilitySettingValueNominal
  | AppRecipeStepCapabilitySettingValueTime;

export const fromApiAplCapabilitySettingValue = (
  defaultValue: ApiAplCapabilitySettingValue,
  allowedValues: ApiAplCapabilityAllowedSettingValue,
  settingId: ApiEntityId
): AppCapabilitySettingDefaultValue => {
  const { type, value } = defaultValue;
  if (type === ApiCapabilitySettingType.Nominal && allowedValues.nominal) {
    return {
      type: AppCapabilitySettingType.Nominal,
      referenceValue: { id: value.id, name: value.name },
    };
  }
  if (type === ApiCapabilitySettingType.Numeric && allowedValues.numeric) {
    if (
      isValueInSingleSystemArray(allowedValues.numeric) &&
      isValueInSingleSystem(value)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { referenceUnit } = allowedValues.numeric.find(
        ({ referenceUnit: { id } }) => id === value.referenceUnitId
      )!;
      if (isTimeSettingId(settingId)) {
        return {
          type: AppCapabilitySettingType.Time,
          value: fromSecondsToAppTime(value.amount, { removeZeros: true }),
          unit: referenceUnit,
        };
      }
      return {
        type: AppCapabilitySettingType.Numeric,
        value: { amount: value.amount, unit: referenceUnit, text: '' },
      };
    }
    if (
      isValueInMultipleSystemsArray(allowedValues.numeric) &&
      isValueInMultipleSystems(value)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { usCustomary } = allowedValues.numeric.find(
        ({ usCustomary: { referenceUnit } }) =>
          referenceUnit.id === value.usCustomary.referenceUnitId
      )!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { metric } = allowedValues.numeric.find(
        ({ metric: { referenceUnit } }) =>
          referenceUnit.id === value.metric.referenceUnitId
      )!;
      return {
        type: AppCapabilitySettingType.Numeric,
        value: fromApiRcpRecipeValueInMultipleSystems({
          usCustomary: {
            amount: value.usCustomary.amount,
            referenceUnit: usCustomary.referenceUnit,
            text: '',
          },
          metric: {
            amount: value.metric.amount,
            referenceUnit: metric.referenceUnit,
            text: '',
          },
        }),
      };
    }
  }
  if (type === ApiCapabilitySettingType.Boolean && allowedValues.boolean) {
    return {
      type: AppCapabilitySettingType.Boolean,
      value: value.value,
    };
  }
  throw new Error(
    `Default value: ${JSON.stringify(
      value
    )} is invalid. Allowed values are ${JSON.stringify(allowedValues)}`
  );
};
