import { PantryColor, sxCompose } from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import {
  Card as MuiCard,
  cardActionAreaClasses,
  cardContentClasses,
  Box,
  CardContent,
} from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { cardConstants } from 'components/Card/Card.constants';
import { dragAndDropConstants } from 'components/DragAndDrop/DragAndDrop.constants';

export interface CardProps {
  onClick?: () => void;
  selected?: boolean;
  topSectionChildren: ReactNode;
  bottomSectionChildren?: ReactNode;
  /**
   * Action button placed in a flex container to the right of the `topSectionChildren`.
   */
  actionButtonChildren?: ReactNode;
  ariaLabel?: string;
  dragging?: boolean;
  draggable?: boolean;
  sx?: SxProps;
}

export const Card: FC<CardProps> = memo(function Card({
  onClick,
  selected,
  topSectionChildren,
  bottomSectionChildren,
  actionButtonChildren,
  ariaLabel,
  dragging = false,
  draggable = false,
  sx,
}) {
  return (
    <MuiCard
      raised={selected}
      sx={sxCompose(
        sx,
        {
          boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.08)',
          cursor: 'unset',
          [`.${cardContentClasses.root}:last-of-type`]: {
            // Override default padding-bottom of 24px added by MUI
            p: 4,
          },
        },
        onClick && {
          cursor: 'pointer',
          border: '1px solid',
          borderRadius: 1,
          borderColor:
            !selected && !dragging
              ? PantryColor.BorderSubtle
              : PantryColor.FrescoPrimary,

          [`&:hover, &.${cardActionAreaClasses.focusVisible}`]: {
            borderColor: !selected
              ? PantryColor.BorderDefault
              : PantryColor.FrescoPrimary,

            [`.${cardActionAreaClasses.focusHighlight}`]: {
              opacity: 0,
            },
          },
        },
        draggable && dragAndDropConstants.styles.draggable,
        dragging && cardConstants.styles.dragging,
        {
          transition: `${dragAndDropConstants.styles.draggable.transition}, border-color 300ms`,
        }
      )}
      onClick={onClick}
      aria-label={ariaLabel}
      data-testid="card"
      role={onClick ? 'button' : undefined}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            gap: 5,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              alignSelf: 'center',
            }}
          >
            {topSectionChildren}
          </Box>
          {!!actionButtonChildren && (
            <Box
              sx={{
                flexShrink: 0,
                display: 'flex',
              }}
            >
              {actionButtonChildren}
            </Box>
          )}
        </Box>
        {bottomSectionChildren}
      </CardContent>
    </MuiCard>
  );
});
