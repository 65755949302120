export const timeFieldStrings = {
  placeholders: {
    hours: '0',
    minutes: '0',
    seconds: '0',
  },
  labels: {
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
  },
  adornments: {
    hours: 'hrs',
    minutes: 'mins',
    seconds: 'secs',
  },
  ids: {
    hours: '-hours',
    minutes: '-minutes',
    seconds: '-seconds',
  },
  errors: {
    hoursNotValid: 'Hours should be a number from 0 to 999',
    minutesNotValid: 'Minutes should be a number from 0 to 59',
    secondsNotValid: 'Seconds should be a number from 0 to 59',
  },
};
