import { ApiLocale } from 'api/types/common/apiLocale';
import { AppUnitSystem } from 'types/appUnitSystem';
import type { AppRecipeValueInMultipleSystems } from 'types/recipe/appRecipeValueInMultipleSystems';
import type {
  AppRecipeValueWithUnit,
  AppRecipeValueWithUnitComplete,
  AppRecipeValueWithUnitNullable,
} from 'types/recipe/appRecipeValueWithUnit';

type TValueInMultipleSystems<TValueInSingleSystem> = {
  metric: TValueInSingleSystem;
  usCustomary: TValueInSingleSystem;
};

type TValue<TValueInSingleSystem> =
  | TValueInSingleSystem
  | TValueInMultipleSystems<TValueInSingleSystem>;

export function isValueInSingleSystem<TValueInSingleSystem>(
  value: TValue<TValueInSingleSystem>
): value is TValueInSingleSystem {
  return (
    (value as TValueInMultipleSystems<TValueInSingleSystem>).metric ===
    undefined
  );
}

export const isValueInSingleSystemArray = <TValueInSingleSystem>(
  values: TValue<TValueInSingleSystem>[]
): values is TValueInSingleSystem[] => values.every(isValueInSingleSystem);

export function isValueInMultipleSystems<TValueInSingleSystem>(
  value: TValue<TValueInSingleSystem>
): value is TValueInMultipleSystems<TValueInSingleSystem> {
  return (
    (value as TValueInMultipleSystems<TValueInSingleSystem>).metric !==
    undefined
  );
}

export const isValueInMultipleSystemsArray = <TValueInSingleSystem>(
  values: TValue<TValueInSingleSystem>[]
): values is TValueInMultipleSystems<TValueInSingleSystem>[] =>
  values.every(isValueInMultipleSystems);

export const unitSystemByLocale: Record<ApiLocale, AppUnitSystem> = {
  [ApiLocale.EnUS]: AppUnitSystem.UsCustomary,
  [ApiLocale.EnGB]: AppUnitSystem.Metric,
  [ApiLocale.EnAU]: AppUnitSystem.Metric,
  [ApiLocale.EsES]: AppUnitSystem.Metric,
  [ApiLocale.ItIT]: AppUnitSystem.Metric,
  [ApiLocale.DeDE]: AppUnitSystem.Metric,
  [ApiLocale.FrFR]: AppUnitSystem.Metric,
  [ApiLocale.PlPL]: AppUnitSystem.Metric,
  [ApiLocale.CsCZ]: AppUnitSystem.Metric,
  [ApiLocale.NlNL]: AppUnitSystem.Metric,
  [ApiLocale.NbNO]: AppUnitSystem.Metric,
  [ApiLocale.SvSE]: AppUnitSystem.Metric,
  [ApiLocale.FiFI]: AppUnitSystem.Metric,
  [ApiLocale.DaDK]: AppUnitSystem.Metric,
  [ApiLocale.PtPT]: AppUnitSystem.Metric,
  [ApiLocale.RuRU]: AppUnitSystem.Metric,
};

export const getValueInMultipleSystemsAsString = (
  quantity: AppRecipeValueInMultipleSystems
): { metric: string; usCustomary: string } => {
  const { metric, usCustomary } = quantity;
  return {
    metric: getValueWithUnitAsString(metric),
    usCustomary: getValueWithUnitAsString(usCustomary),
  };
};

export const getValueWithUnitAsString = ({
  amount,
  unit,
  text,
}: AppRecipeValueWithUnit): string => {
  const quantityAmount = amount !== null ? `${amount} ` : '';
  const quantityUnit = unit.abbreviation ?? unit.name;
  return text || `${quantityAmount}${quantityUnit}`;
};

export const isQuantityComplete = (
  quantity: AppRecipeValueWithUnitNullable
): quantity is AppRecipeValueWithUnitComplete =>
  quantity.amount !== null && quantity.unit !== null;

export const getSecondaryUnitSystem = (primaryUnitSystem: AppUnitSystem) => {
  return primaryUnitSystem === AppUnitSystem.Metric
    ? AppUnitSystem.UsCustomary
    : AppUnitSystem.Metric;
};
