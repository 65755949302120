import {
  Button,
  ButtonSize,
  ButtonStyle,
  PantryColor,
  PantryTypography,
  Skeleton,
  SkeletonVariant,
} from '@dropkitchen/pantry-react';
import { TabContext, TabPanel } from '@mui/lab';
import type { Theme } from '@mui/material';
import { Box, Grid, Typography } from '@mui/material';
import type { SxProps } from '@mui/system';
import includes from 'lodash/includes';
import type { FC } from 'react';
import { useCallback, useEffect, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { RecipeType } from 'app/routes/constants';
import { useLeavePageWithUnsavedChanges } from 'app/routes/hooks/useLeavePageWithUnsavedChanges';
import {
  generateRecipeListRoute,
  generateRecipeRoute,
} from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { stylesCenterContent } from 'app/theme';
import { ButtonWithSkeleton } from 'components/ButtonWithSkeleton/ButtonWithSkeleton';
import { Tab } from 'components/Tab/Tab';
import { TabBadge } from 'components/Tab/TabBadge';
import { TabsList } from 'components/Tab/TabsList';
import { TabBadgeStatus } from 'components/Tab/tabBadgeUtils';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { appliancesFetchRequested } from 'features/appliances/appliancesSlice';
import { DetailLayout } from 'features/layout/DetailLayout';
import { headerConstants } from 'features/layout/Header.constants';
import { HeaderScrolled } from 'features/layout/HeaderScrolled';
import { mediaStorage } from 'features/media/mediaStorage';
import { AutoSaveNotifications } from 'features/recipe/AutoSaveNotifications';
import { LeaveWithUnsavedChangesDialog } from 'features/recipe/LeaveWithUnsavedChangesDialog';
import { PublishConfirmationDialog } from 'features/recipe/PublishConfirmationDialog/PublishConfirmationDialog';
import { PublishedRecipeUpdatedDialog } from 'features/recipe/PublishedRecipeUpdatedDialog/PublishedRecipeUpdatedDialog';
import {
  RecipeTabName,
  recipePageStrings,
} from 'features/recipe/RecipePage.constants';
import { recipeIngredientFormConstants } from 'features/recipe/ingredients/form/RecipeIngredientForm.constants';
import { RecipeIngredients } from 'features/recipe/ingredients/list/RecipeIngredients';
import { RecipeBasicInformation } from 'features/recipe/recipeBasicInformation/RecipeBasicInformation';
import {
  recipeSaveBeforeLeavingRequested,
  recipeFetchRequested,
  recipePublishRequested,
  recipeSaveRequested,
  recipeUnpublishRequested,
  selectRecipe,
  selectRecipeFetching,
  selectRecipeHasUnsavedChanges,
  selectRecipeLocale,
  selectRecipePublishing,
  selectRecipeSaving,
  selectRecipeUnpublishing,
  selectRecipeErrorsCount,
  selectRecipeIssuesCount,
  selectRecipeSubmitted,
} from 'features/recipe/recipeSlice';
import { RecipeReview } from 'features/recipe/review/RecipeReview';
import { recipeStepsFormConstants } from 'features/recipe/steps/form/RecipeStepsForm.constants';
import { RecipeSteps } from 'features/recipe/steps/list/RecipeSteps';
import { generalCapabilitiesFetchRequested } from 'features/referenceData/generalCapabilities/generalCapabilitiesSlice';
import { ingredientsFetchRequested } from 'features/referenceData/ingredients/ingredientsSlice';
import { measurementSystemsFetchRequested } from 'features/referenceData/measurementSystems/measurementSystemsSlice';
import { preparationsFetchRequested } from 'features/referenceData/preparations/preparationsSlice';
import { tagsFetchRequested } from 'features/referenceData/tags/tagsSlice';
import { usePreserveScrollPosition } from 'hooks/usePreserveScrollPosition';
import { AppFeature } from 'types/appFeature';

const tabPanelStyle: SxProps<Theme> = { px: 6, py: 10 };

const { labels, ariaLabels } = recipePageStrings;

export const RecipePage: FC = memo(function RecipePage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { recipeId, ...restRouteParams } = useParams<{
    recipeId: ApiRcpRecipeId;
    tab: RecipeTabName;
  }>();

  const isSaving = useAppSelector(selectRecipeSaving);
  const hasChanges = useAppSelector(selectRecipeHasUnsavedChanges);
  const isFetching = useAppSelector(selectRecipeFetching);
  const isPublishing = useAppSelector(selectRecipePublishing);
  const isUnpublishing = useAppSelector(selectRecipeUnpublishing);
  const recipe = useAppSelector(selectRecipe);
  const locale = useAppSelector(selectRecipeLocale);
  const isCoreRecipe = !recipe?.forkedFromId;

  const localeInformationText = `${
    isCoreRecipe ? labels.coreRecipe : labels.translationRecipe
  }: ${locale}`;

  const tab =
    restRouteParams.tab && includes(RecipeTabName, restRouteParams.tab)
      ? restRouteParams.tab
      : RecipeTabName.Information;

  const tabPaths: Record<RecipeTabName, string> = {
    [RecipeTabName.Information]: generateRecipeRoute({
      id: recipeId,
      tab: RecipeTabName.Information,
    }),
    [RecipeTabName.Ingredients]: generateRecipeRoute({
      id: recipeId,
      tab: RecipeTabName.Ingredients,
      autofocusedFieldId: recipeIngredientFormConstants.fieldIds.ingredient,
    }),
    [RecipeTabName.Steps]: generateRecipeRoute({
      id: recipeId,
      tab: RecipeTabName.Steps,
      autofocusedFieldId: recipeStepsFormConstants.fieldIds.text,
    }),
    [RecipeTabName.Review]: generateRecipeRoute({
      id: recipeId,
      tab: RecipeTabName.Review,
    }),
  };

  const isEdit = !!recipeId;

  const handleBeforeUnload = useCallback(() => {
    if (isEdit) {
      dispatch(recipeSaveBeforeLeavingRequested(recipeId));
    }
  }, [dispatch, isEdit, recipeId]);

  const { isShowingPrompt, confirmNavigation, cancelNavigation } =
    useLeavePageWithUnsavedChanges({
      isEnabled: hasChanges,
      excludedPaths: [
        generateRecipeRoute({ id: recipeId, tab: RecipeTabName.Information }),
        generateRecipeRoute({ id: recipeId, tab: RecipeTabName.Ingredients }),
        generateRecipeRoute({ id: recipeId, tab: RecipeTabName.Steps }),
        generateRecipeRoute({ id: recipeId, tab: RecipeTabName.Review }),
      ],
      onBeforeUnload: handleBeforeUnload,
    });

  usePreserveScrollPosition(tab);

  useEffect(() => {
    if (!isEdit && !locale) {
      navigate(generateRecipeListRoute({ type: RecipeType.Core }));
    }
  }, [isEdit, locale, navigate]);

  useEffect(() => {
    if (isEdit) {
      dispatch(recipeFetchRequested(recipeId));
    }
    /** Clear the media storage when unmounting this component - keep the uploader clean */
    return () => mediaStorage.clear();
  }, [dispatch, isEdit, recipeId]);

  /**
   * Retrieve all reference data needed for comboboxes beforehand, so they will be loaded
   * and user won't have to wait.
   */
  useEffect(() => {
    /**
     * Locale can be undefined until {@link https://frescocooks.atlassian.net/browse/PIE-895} is done.
     * Adding this validation so it doesn't trigger requests with an undefined locale.
     */
    if (!locale) {
      return;
    }
    dispatch(measurementSystemsFetchRequested({ locale }));
    dispatch(ingredientsFetchRequested({ locale }));
    dispatch(preparationsFetchRequested({ locale }));
    dispatch(generalCapabilitiesFetchRequested({ locale }));
    dispatch(tagsFetchRequested({ locale }));
    dispatch(appliancesFetchRequested({ locale }));
  }, [dispatch, locale]);

  const handleSaveRecipe = () => {
    dispatch(recipeSaveRequested(recipeId));
  };

  const getSaveButtonText = () =>
    hasChanges ? labels.saveButton : labels.savedButton;

  const headerElement = (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={8}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <ToggleableFeature
          requires={AppFeature.TranslationManagement}
          components={{ whenDisabled: null }}
        >
          {isFetching ? (
            <Box aria-label={ariaLabels.localeInfo}>
              <Skeleton
                variant={SkeletonVariant.Small}
                sx={{ height: 16, width: 70 }}
              />
            </Box>
          ) : (
            <Typography
              variant={PantryTypography.Overline}
              color={PantryColor.TextSubtle}
            >
              {localeInformationText.toUpperCase()}
            </Typography>
          )}
        </ToggleableFeature>
        {isFetching ? (
          <Box aria-label={ariaLabels.title}>
            <Skeleton
              variant={SkeletonVariant.Small}
              sx={{ width: 400, height: 34 }}
            />
          </Box>
        ) : (
          <Typography
            variant={PantryTypography.H6}
            color={PantryColor.TextDefault}
          >
            {isEdit ? recipe.name : labels.createTitle}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 4,
        }}
      >
        {isEdit && (
          <AutoSaveNotifications saving={isSaving} changed={hasChanges} />
        )}
        <ButtonWithSkeleton
          isLoading={isFetching}
          button={
            <Button
              label={getSaveButtonText()}
              buttonStyle={ButtonStyle.Default}
              size={ButtonSize.Large}
              onClick={handleSaveRecipe}
              loading={isSaving}
              disabled={!hasChanges}
            />
          }
        />
        {isEdit && recipe.state === ApiRcpRecipeState.Draft && (
          <ButtonWithSkeleton
            isLoading={isFetching}
            button={
              <Button
                label={labels.publishButton}
                buttonStyle={ButtonStyle.Emphasis}
                size={ButtonSize.Large}
                onClick={() => dispatch(recipePublishRequested(recipeId))}
                loading={isPublishing}
              />
            }
          />
        )}
        {isEdit && recipe.state === ApiRcpRecipeState.Published && (
          <ButtonWithSkeleton
            isLoading={isFetching}
            button={
              <Button
                label={labels.unpublishButton}
                buttonStyle={ButtonStyle.Emphasis}
                size={ButtonSize.Large}
                onClick={() => dispatch(recipeUnpublishRequested(recipeId))}
                loading={isUnpublishing}
              />
            }
          />
        )}
      </Grid>
    </Grid>
  );

  return (
    <>
      <HeaderScrolled>{headerElement}</HeaderScrolled>
      <Grid container sx={{ ...stylesCenterContent, pt: 10, pb: 4, mb: 4 }}>
        {headerElement}
      </Grid>
      <DetailLayout>
        <TabContext value={tab}>
          <TabsList stickTo={headerConstants.height}>
            <Tab
              label={labels.tabs[RecipeTabName.Information].title}
              subLabel={labels.tabs[RecipeTabName.Information].description}
              value={RecipeTabName.Information}
              position={1}
              to={tabPaths[RecipeTabName.Information]}
            />
            <Tab
              label={labels.tabs[RecipeTabName.Ingredients].title}
              subLabel={labels.tabs[RecipeTabName.Ingredients].description}
              value={RecipeTabName.Ingredients}
              position={2}
              to={tabPaths[RecipeTabName.Ingredients]}
            />
            <Tab
              label={labels.tabs[RecipeTabName.Steps].title}
              subLabel={labels.tabs[RecipeTabName.Steps].description}
              value={RecipeTabName.Steps}
              position={3}
              to={tabPaths[RecipeTabName.Steps]}
            />
            <Tab
              label={labels.tabs[RecipeTabName.Review].title}
              subLabel={labels.tabs[RecipeTabName.Review].description}
              value={RecipeTabName.Review}
              position={4}
              to={tabPaths[RecipeTabName.Review]}
              components={{ badge: <ReviewTabBadge /> }}
            />
          </TabsList>
          <TabPanel sx={tabPanelStyle} value={RecipeTabName.Information}>
            <RecipeBasicInformation />
          </TabPanel>
          <TabPanel sx={tabPanelStyle} value={RecipeTabName.Ingredients}>
            <RecipeIngredients />
          </TabPanel>
          <TabPanel sx={tabPanelStyle} value={RecipeTabName.Steps}>
            <RecipeSteps />
          </TabPanel>
          <TabPanel sx={tabPanelStyle} value={RecipeTabName.Review}>
            <RecipeReview />
          </TabPanel>
        </TabContext>
      </DetailLayout>
      {isShowingPrompt && (
        <LeaveWithUnsavedChangesDialog
          onCancel={cancelNavigation}
          onConfirm={confirmNavigation}
        />
      )}
      <PublishConfirmationDialog />
      <PublishedRecipeUpdatedDialog />
    </>
  );
});

function ReviewTabBadge() {
  const { recipeId } = useParams<{ recipeId: ApiRcpRecipeId }>();
  const isSubmitted = useAppSelector(selectRecipeSubmitted);
  const isFetching = useAppSelector(selectRecipeFetching);
  const errorsCount = useAppSelector(selectRecipeErrorsCount);
  const issuesCount = useAppSelector(selectRecipeIssuesCount);

  if (isFetching || !issuesCount || (!recipeId && !isSubmitted)) {
    return null;
  }

  return (
    <TabBadge
      message={`${issuesCount} ${
        issuesCount > 1
          ? labels.reviewTabBadge.multipleIssues
          : labels.reviewTabBadge.oneIssue
      }`}
      status={errorsCount > 0 ? TabBadgeStatus.Error : TabBadgeStatus.Warning}
    />
  );
}
