import {
  PantryTypography,
  PantryColor,
  ButtonSize,
  ButtonStyle,
  Button,
  WarningIcon,
} from '@dropkitchen/pantry-react';
import { Autocomplete, Box, Typography } from '@mui/material';
import type { AutocompleteProps } from '@mui/material';

import {
  autocompleteWithRetryConstants,
  autocompleteWithRetryStrings,
} from 'components/AutocompleteWithRetry/AutocompleteWithRetry.constants';

const { message, label } = autocompleteWithRetryStrings;
const { retryOptionId } = autocompleteWithRetryConstants;

export interface AutocompleteWithRetryOption {
  id: string;
  name: string;
}

export type AutocompleteWithRetryProps<
  T extends AutocompleteWithRetryOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = {
  onRetry: () => void;
  hasError?: boolean;
} & AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;

export function AutocompleteWithRetry<
  T extends AutocompleteWithRetryOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  onRetry,
  hasError,
  ...props
}: AutocompleteWithRetryProps<T, Multiple, DisableClearable, FreeSolo>) {
  if (!hasError) {
    return <Autocomplete {...props} />;
  }

  const options: AutocompleteWithRetryOption[] = [
    { id: retryOptionId, name: '' },
  ];
  return (
    <Autocomplete
      {...props}
      getOptionLabel={(option) => (option as AutocompleteWithRetryOption).name}
      options={options as T[]}
      renderOption={() => (
        <Box
          component="li"
          key={retryOptionId}
          sx={{
            typography: PantryTypography.Body1,
            color: PantryColor.TextCritical,
            py: 1.5,
            px: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <WarningIcon
            size={20}
            sx={{ mr: 3 }}
            color={PantryColor.IconCritical}
          />
          <Typography
            variant={PantryTypography.Body1}
            sx={{
              color: PantryColor.TextCritical,
              flexGrow: 1,
            }}
          >
            {message}
          </Typography>
          <Button
            label={label}
            buttonStyle={ButtonStyle.Critical}
            size={ButtonSize.Small}
            onClick={onRetry}
          />
        </Box>
      )}
    />
  );
}
