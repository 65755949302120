import { MediaResourceType, MediaImageType } from 'api/media';
import { apiGetImagesUrl } from 'api/paths';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';

export const heroImageConstants = { height: 150, width: 200 } as const;

export const generateRecipeHeroImageSource = ({
  recipeId,
  height,
  width,
}: {
  recipeId: ApiRcpRecipeId;
  height?: number;
  width?: number;
}) => {
  if (!width && !height) {
    throw new Error(
      "You have to provide either width or height when requesting the recipe's hero image"
    );
  }
  const url = `${apiGetImagesUrl}/${MediaResourceType.Recipes}/${recipeId}/${MediaImageType.Hero}`;
  const params = new URLSearchParams({
    ...(width && { width: width.toString() }),
    ...(height && { height: height.toString() }),
  });
  return {
    src: `${url}?${params.toString()}`,
    width: width ? `${width}px` : '',
    height: height ? `${height}px` : '',
  };
};
