import { PantryColor } from '@dropkitchen/pantry-react';
import { TextField, InputLabel } from '@mui/material';
import { memo, useMemo } from 'react';

import { generateRecipeRoute } from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { AutocompleteWithRetry } from 'components/AutocompleteWithRetry/AutocompleteWithRetry';
import { Image } from 'components/Image/Image';
import { ReadOnlyField } from 'components/ReadOnlyField/ReadOnlyField';
import { mediaStorage } from 'features/media/mediaStorage';
import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import {
  recipeBasicInformationConstants,
  recipeBasicInformationStrings,
} from 'features/recipe/recipeBasicInformation/RecipeBasicInformation.constants';
import {
  recipeFieldUpdated,
  recipeRecreateTagsRequested,
  selectRecipe,
  selectRecipeLocale,
} from 'features/recipe/recipeSlice';
import { recipeReviewStrings } from 'features/recipe/review/RecipeReview.constants';
import { recipeReviewBasicInformationStrings } from 'features/recipe/review/basicInformation/RecipeReviewBasicInformation.constants';
import { RecipeReviewBasicInformationContainer } from 'features/recipe/review/basicInformation/RecipeReviewBasicInformationContainer';
import { RecipeReviewEmptyValue } from 'features/recipe/review/shared/RecipeReviewEmptyValue/RecipeReviewEmptyValue';
import { disambiguateTerms } from 'features/referenceData/referenceData.utils';
import {
  selectGeneralTags,
  selectTagsFetchError,
  selectTagsFetching,
} from 'features/referenceData/tags/tagsSlice';
import { appCommonStrings } from 'types/appCommon.constants';
import { AppIssueType } from 'types/appIssueType';
import { generateChipsFromTags } from 'utils/chips';
import { fromAppTimeToSentence } from 'utils/convertTimes';
import { generateRecipeHeroImageSource, heroImageConstants } from 'utils/image';
import { hasTime } from 'utils/validateTimes';

const { labels, placeholders: fieldPlaceholders } =
  recipeBasicInformationStrings;
const { fieldIds } = recipeBasicInformationConstants;
const { errors } = recipeReviewStrings;
const { placeholders } = appCommonStrings;
const { emptyRequiredFields } = recipeReviewBasicInformationStrings;

export const RecipeReviewBasicInformation = memo(
  function RecipeReviewBasicInformation() {
    const dispatch = useAppDispatch();

    const recipe = useAppSelector(selectRecipe);
    const locale = useAppSelector(selectRecipeLocale);
    const tags = useAppSelector(selectGeneralTags(locale));
    const areTagsFetching = useAppSelector(selectTagsFetching(locale));
    const tagsFetchError = useAppSelector(selectTagsFetchError(locale));

    const { generalTags } = recipe;
    const existingImage = generateRecipeHeroImageSource({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      recipeId: recipe.id!,
      width: heroImageConstants.width,
    });
    const currentImage = mediaStorage.get()?.preview;

    const disambiguatedTags = useMemo(
      () => disambiguateTerms(tags || []),
      [tags]
    );

    return (
      <RecipeReviewBasicInformationContainer
        components={{
          image: (
            <Image
              src={currentImage || (recipe.id && existingImage.src) || ''}
              alt={recipe.name}
              bypassCache
              error={{ text: errors.image }}
              width={`${heroImageConstants.width}px`}
              height={`${heroImageConstants.height}px`}
            />
          ),

          descriptionField: (
            <ReadOnlyField
              label={labels.descriptionField}
              value={recipe.description || placeholders.noValue}
            />
          ),

          authorField: (
            <ReadOnlyField
              label={labels.authorField}
              value={
                recipe.author.name || (
                  <RecipeReviewEmptyValue
                    type={AppIssueType.Critical}
                    message={emptyRequiredFields.message}
                    emptyLink={generateRecipeRoute({
                      id: recipe.id,
                      tab: RecipeTabName.Information,
                      autofocusedFieldId: fieldIds.author,
                    })}
                  />
                )
              }
            />
          ),

          totalTimeField: (
            <ReadOnlyField
              label={labels.totalTimeField}
              value={
                hasTime(recipe.totalTime) ? (
                  fromAppTimeToSentence(recipe.totalTime)
                ) : (
                  <RecipeReviewEmptyValue
                    type={AppIssueType.Critical}
                    message={emptyRequiredFields.message}
                    emptyLink={generateRecipeRoute({
                      id: recipe.id,
                      tab: RecipeTabName.Information,
                      autofocusedFieldId: fieldIds.totalTime,
                    })}
                  />
                )
              }
            />
          ),

          prepTimeField: (
            <ReadOnlyField
              label={labels.prepTimeField}
              value={
                hasTime(recipe.prepTime) ? (
                  fromAppTimeToSentence(recipe.prepTime)
                ) : (
                  <RecipeReviewEmptyValue
                    type={AppIssueType.Critical}
                    message={emptyRequiredFields.message}
                    emptyLink={generateRecipeRoute({
                      id: recipe.id,
                      tab: RecipeTabName.Information,
                      autofocusedFieldId: fieldIds.prepTime,
                    })}
                  />
                )
              }
            />
          ),

          cookTimeField: (
            <ReadOnlyField
              label={labels.cookTimeField}
              value={
                hasTime(recipe.cookTime) ? (
                  fromAppTimeToSentence(recipe.cookTime)
                ) : (
                  <RecipeReviewEmptyValue
                    type={AppIssueType.Critical}
                    message={emptyRequiredFields.message}
                    emptyLink={generateRecipeRoute({
                      id: recipe.id,
                      tab: RecipeTabName.Information,
                      autofocusedFieldId: fieldIds.cookTime,
                    })}
                  />
                )
              }
            />
          ),

          servesField: (
            <ReadOnlyField
              label={labels.servesField}
              value={
                recipe.serves || (
                  <RecipeReviewEmptyValue
                    type={AppIssueType.Critical}
                    message={emptyRequiredFields.message}
                    emptyLink={generateRecipeRoute({
                      id: recipe.id,
                      tab: RecipeTabName.Information,
                      autofocusedFieldId: fieldIds.serves,
                    })}
                  />
                )
              }
            />
          ),

          applianceTagsField: (
            <ReadOnlyField
              label={labels.applianceTagsField}
              value={generateChipsFromTags(recipe.applianceReferenceTags)}
            />
          ),

          generalTagsField: (
            <AutocompleteWithRetry
              multiple
              id="tags"
              value={(!areTagsFetching && !tagsFetchError && generalTags) || []}
              loading={areTagsFetching !== false}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableCloseOnSelect
              onChange={(_, options) =>
                dispatch(
                  recipeFieldUpdated({
                    key: 'generalTags',
                    value: options,
                  })
                )
              }
              options={tags || []}
              getOptionLabel={(tag) => disambiguatedTags[tag.id] ?? tag.name}
              renderInput={(params) => (
                <>
                  <InputLabel
                    htmlFor="tags"
                    sx={{
                      fontSize: '10px',
                      lineHeight: '13px',
                      textTransform: 'uppercase',
                      color: PantryColor.TextSubtle,
                      mb: 2,
                      mt: 0,
                      display: 'block',
                    }}
                  >
                    {labels.tagsField}
                  </InputLabel>
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={
                      !generalTags || generalTags.length === 0
                        ? fieldPlaceholders.tagsField
                        : ''
                    }
                  />
                </>
              )}
              renderOption={(props, tag) => (
                <li {...props} key={tag.id}>
                  {disambiguatedTags[tag.id] ?? tag.name}
                </li>
              )}
              onRetry={() => dispatch(recipeRecreateTagsRequested())}
              hasError={!!tagsFetchError}
            />
          ),
        }}
      />
    );
  }
);
