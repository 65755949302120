import { Amplify } from 'aws-amplify';
import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { appConfig } from 'config/config';

export function* amplifyInitSaga(): SagaIterator<void> {
  const userPoolId = (yield call(appConfig.userPoolId)) as string;
  const userPoolClientId = (yield call(appConfig.userPoolClientId)) as string;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  yield call([Amplify, Amplify.configure], {
    /* eslint-disable @typescript-eslint/naming-convention */
    Auth: {
      Cognito: {
        userPoolId,
        userPoolClientId,
      },
    },
    /* eslint-enable @typescript-eslint/naming-convention */
  });
}
