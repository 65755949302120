import upperFirst from 'lodash/upperFirst';

import type { ApiAplCapability } from 'api/types/appliance/apiAplCapability';
import type { ApiAplCapabilityAttachment } from 'api/types/appliance/apiAplCapabilityAttachment';
import type { ApiCapabilityAllowedPhase } from 'api/types/common/apiCapabilityAllowedPhase';
import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRefCapability } from 'api/types/referenceData/apiRefCapability';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import { isApiRefId } from 'api/types/referenceData/apiRefId';
import {
  fromApiCapabilityAllowedPhase,
  type AppCapabilityAllowedPhase,
} from 'types/appCapabilityAllowedPhase';
import { appCapabilityAllowedPhaseConstants } from 'types/appCapabilityAllowedPhase.constants';
import type { AppCapabilityAllowedSetting } from 'types/appCapabilityAllowedSetting';
import {
  fromApiRefCapabilityAllowedSetting,
  fromApiAplCapabilityAllowedSetting,
} from 'types/appCapabilityAllowedSetting';

export interface AppCapability {
  id: ApiEntityId;
  name: string;
  type: AppCapabilityType;
  allowedSettings: AppCapabilityAllowedSetting[];
  attachments?: ApiAplCapabilityAttachment[][];
  allowedPhases: AppCapabilityAllowedPhase[];
  referenceCapabilityId?: ApiRefId;
}

export enum AppCapabilityType {
  General = 'General',
  AppliancePreset = 'AppliancePreset',
}

export const getCapabilityType = (capabilityId: ApiEntityId) =>
  isApiRefId(capabilityId)
    ? AppCapabilityType.General
    : AppCapabilityType.AppliancePreset;

export const fromApiAplCapability = ({
  id,
  name,
  settings,
  attachments,
  allowedPhases,
  referenceCapabilityId,
}: ApiAplCapability): AppCapability => ({
  id,
  name,
  allowedSettings: settings.map(fromApiAplCapabilityAllowedSetting),
  allowedPhases: mapCapabilityAllowedPhases(allowedPhases || []),
  type: getCapabilityType(id),
  attachments,
  referenceCapabilityId,
});

export const fromApiRefCapability = ({
  id,
  name,
  allowedSettings,
  allowedPhases,
}: ApiRefCapability): AppCapability => {
  return {
    id,
    name: upperFirst(name),
    allowedSettings: allowedSettings.map(fromApiRefCapabilityAllowedSetting),
    allowedPhases: mapCapabilityAllowedPhases(allowedPhases),
    type: getCapabilityType(id),
  };
};

const mapCapabilityAllowedPhases = (
  allowedPhases: ApiCapabilityAllowedPhase[]
) => {
  const { order } = appCapabilityAllowedPhaseConstants;
  return allowedPhases
    .map(fromApiCapabilityAllowedPhase)
    .sort((capability, anotherCapability) =>
      order[capability.id] > order[anotherCapability.id] ? 1 : -1
    );
};
