import { Skeleton, SkeletonVariant } from '@dropkitchen/pantry-react';
import { Box } from '@mui/material';

import {
  homeFeedSkeletonConstants,
  homeFeedSkeletonStrings,
} from 'features/homeFeed/HomeFeed/HomeFeedSkeleton.constants';

const { height } = homeFeedSkeletonConstants;
const { loaders } = homeFeedSkeletonStrings;

export function HomeFeedCollectionTitleSkeleton() {
  return (
    <Box aria-label={loaders.title}>
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ width: '101px', height }}
      />
    </Box>
  );
}

export function HomeFeedCollectionAppliancesSkeleton() {
  return (
    <Box aria-label={loaders.appliances}>
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ width: '205px', height }}
      />
    </Box>
  );
}

export function HomeFeedCollectionIdSkeleton() {
  return (
    <Box aria-label={loaders.id}>
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ width: '84px', height }}
      />
    </Box>
  );
}

export function HomeFeedCollectionRecipeTotalSkeleton() {
  return (
    <Box aria-label={loaders.recipeTotal}>
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ width: '42px', height }}
      />
    </Box>
  );
}

export function HomeFeedCollectionStatusSkeleton() {
  return (
    <Box aria-label={loaders.status}>
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ width: '76px', height }}
      />
    </Box>
  );
}

export function HomeFeedCollectionModifiedAtSkeleton() {
  return (
    <Box aria-label={loaders.modifiedAt}>
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ width: '101px', height }}
      />
    </Box>
  );
}
