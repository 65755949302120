import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from '@dropkitchen/pantry-react';
import type { FC } from 'react';
import { memo } from 'react';

import { Dialog } from 'components/Dialog/Dialog';
import { recipeFromTextDismissalDialogStrings } from 'features/RecipeFromTextDialog/RecipeFromTextDismissalDialog.constants';

export type RecipeFromTextDismissalDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const { texts, buttons } = recipeFromTextDismissalDialogStrings;

export const RecipeFromTextDismissalDialog: FC<RecipeFromTextDismissalDialogProps> =
  memo(function RecipeFromTextDismissalDialog({ isOpen, onClose, onSubmit }) {
    return (
      <Dialog
        title={texts.title}
        text={texts.body}
        isOpen={isOpen}
        hasCloseButton={false}
        onClose={() => {}}
        onSubmit={onSubmit}
        size="sm"
        components={{
          buttons: (
            <>
              <Button
                label={buttons.dismiss}
                buttonStyle={ButtonStyle.Default}
                size={ButtonSize.Large}
                onClick={onClose}
              />
              <Button
                label={buttons.continue}
                buttonStyle={ButtonStyle.Emphasis}
                size={ButtonSize.Large}
                type={ButtonType.Submit}
              />
            </>
          ),
        }}
      />
    );
  });
