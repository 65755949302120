export const publishConfirmationDialogStrings = {
  title: 'Recipe quality control',
  body: 'Are you sure you want to publish the recipe?',
  unusedIngredients: 'You have unused ingredients in your recipe',
  noIngredients: 'You have no ingredients in your recipe',
  noSteps: 'You have no steps in your recipe',
  noIngredientsOrSteps: 'You have no ingredients or steps in your recipe',
  incompatibilities:
    'Some of your tagged appliances are not compatible with the settings selected',
  confirmButton: 'Yes, publish',
  cancelButton: 'No, continue editing',
} as const;
