import type {
  NumberSchema as YupNumberSchema,
  StringSchema as YupStringSchema,
  ValidationError as YupValidationError,
  AnyObjectSchema as YupObjectSchema,
} from 'yup';

type ValidatorSchemaField = string;

type ValidatorSchema = Record<
  ValidatorSchemaField,
  YupNumberSchema | YupStringSchema | YupObjectSchema
>;

type ValidatorErrorType = string;
type ValidatorErrorMessage = string;

export type ValidatorError = Record<ValidatorErrorType, ValidatorErrorMessage>;
export type ValidatorErrors = Record<ValidatorSchemaField, ValidatorError>;

export class Validator {
  constructor(public schema: ValidatorSchema) {}

  validate(
    value: Record<ValidatorSchemaField, unknown>
  ): ValidatorErrors | undefined {
    const result = Object.entries(this.schema).reduce(
      (errors, [field, validator]) => {
        try {
          validator.validateSync(value[field]);
          return errors;
        } catch (e) {
          const { message, type } = e as YupValidationError;
          return {
            ...errors,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field]: { ...(errors[field] || {}), [type!]: message },
          };
        }
      },
      {} as ValidatorErrors
    );
    return Object.values(result).length ? result : undefined;
  }

  validateField(
    field: ValidatorSchemaField,
    value: unknown
  ): ValidatorError | undefined {
    const validator = this.schema[field];
    if (!validator) {
      return undefined;
    }
    try {
      validator.validateSync(value);
      return undefined;
    } catch (e) {
      const { message, type } = e as YupValidationError;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return { [type!]: message };
    }
  }
}
