import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from 'app/store/rootReducer';

import type { AppDispatch } from './store';

/**
 * Typed version of `useDispatch` that uses app store dispatch typing
 */
export const useAppDispatch: () => AppDispatch = useDispatch;
/**
 * Typed version of `useSelector` that uses app root state typing
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
