import { memo } from 'react';
import type { FC } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { ApplianceTagsField } from 'components/ApplianceTagsField/ApplianceTagsField';
import { TimeField } from 'components/timeField/TimeField';
import { selectConfigsAuthor } from 'features/configs/configsSlice';
import {
  recipeBasicInformationConstants,
  recipeBasicInformationStrings,
} from 'features/recipe/recipeBasicInformation/RecipeBasicInformation.constants';
import { RecipeBasicInformationFormContainer } from 'features/recipe/recipeBasicInformation/RecipeBasicInformationFormContainer';
import type { AppRecipeFieldUpdateKeys } from 'features/recipe/recipeSlice';
import {
  selectRecipe,
  recipeFieldUpdated,
  selectRecipeErrors,
  selectRecipeSubmitted,
  selectRecipeLocale,
  recipeRecreateTagsRequested,
} from 'features/recipe/recipeSlice';
import { RecipeAuthorField } from 'features/recipe/shared/RecipeAuthorField/RecipeAuthorField';
import { RecipeDescriptionField } from 'features/recipe/shared/RecipeDescriptionField/RecipeDescriptionField';
import { RecipeNameField } from 'features/recipe/shared/RecipeNameField/RecipeNameField';
import { RecipeServesField } from 'features/recipe/shared/RecipeServesField/RecipeServesField';

const { ariaLabel, labels, placeholders } = recipeBasicInformationStrings;

const { fieldIds } = recipeBasicInformationConstants;

export const RecipeBasicInformationForm: FC = memo(
  function RecipeBasicInformationForm() {
    const dispatch = useAppDispatch();

    const recipe = useAppSelector(selectRecipe);
    const locale = useAppSelector(selectRecipeLocale);
    const errors = useAppSelector(selectRecipeErrors);
    const isSubmitted = useAppSelector(selectRecipeSubmitted);
    const {
      name,
      description,
      author,
      prepTime,
      cookTime,
      totalTime,
      applianceReferenceTags,
      serves,
    } = recipe;
    const authorData = useAppSelector(selectConfigsAuthor);

    const hasError = (field: AppRecipeFieldUpdateKeys): boolean =>
      isSubmitted && !!errors[field];

    return (
      <RecipeBasicInformationFormContainer
        ariaLabel={ariaLabel.form}
        components={{
          recipeName: (
            <RecipeNameField
              id={fieldIds.recipeName}
              showErrors={hasError('name')}
              value={name}
              onChange={(change) => {
                dispatch(
                  recipeFieldUpdated({ key: 'name', value: change.value })
                );
              }}
            />
          ),

          description: (
            <RecipeDescriptionField
              id={fieldIds.description}
              value={description}
              onChange={(change) => {
                dispatch(
                  recipeFieldUpdated({
                    key: 'description',
                    value: change.value,
                  })
                );
              }}
            />
          ),

          author: (
            <RecipeAuthorField
              id={fieldIds.author}
              showErrors={hasError('author')}
              value={author.name}
              onChange={(change) => {
                dispatch(
                  recipeFieldUpdated({
                    key: 'author',
                    value: { ...authorData, name: change.value },
                  })
                );
              }}
            />
          ),

          applianceTags: (
            <ApplianceTagsField
              id={fieldIds.applianceTags}
              label={labels.applianceTagsField}
              placeholder={placeholders.applianceTagsField}
              value={applianceReferenceTags || []}
              locale={locale}
              onChange={(value) => {
                dispatch(
                  recipeFieldUpdated({
                    key: 'applianceReferenceTags',
                    value,
                  })
                );
              }}
              onRetry={() => dispatch(recipeRecreateTagsRequested())}
              showErrors
            />
          ),

          totalTime: (
            <TimeField
              id={fieldIds.totalTime}
              label={labels.totalTimeField}
              placeholder={placeholders.totalTimeField}
              fullWidth
              variant="outlined"
              required
              onChange={(value) =>
                dispatch(recipeFieldUpdated({ key: 'totalTime', value }))
              }
              errors={hasError('totalTime') ? errors.totalTime : undefined}
              showSeconds={false}
              value={totalTime}
            />
          ),

          prepTime: (
            <TimeField
              id={fieldIds.prepTime}
              label={labels.prepTimeField}
              placeholder={placeholders.prepTimeField}
              fullWidth
              variant="outlined"
              required
              onChange={(value) =>
                dispatch(recipeFieldUpdated({ key: 'prepTime', value }))
              }
              errors={hasError('prepTime') ? errors.prepTime : undefined}
              showSeconds={false}
              value={prepTime}
            />
          ),

          cookTime: (
            <TimeField
              id={fieldIds.cookTime}
              label={labels.cookTimeField}
              placeholder={placeholders.cookTimeField}
              fullWidth
              variant="outlined"
              required
              onChange={(value) =>
                dispatch(recipeFieldUpdated({ key: 'cookTime', value }))
              }
              errors={hasError('cookTime') ? errors.cookTime : undefined}
              showSeconds={false}
              value={cookTime}
            />
          ),

          serves: (
            <RecipeServesField
              id={fieldIds.serves}
              showErrors={hasError('serves')}
              value={serves || ''}
              onChange={(change) => {
                dispatch(
                  recipeFieldUpdated({
                    key: 'serves',
                    value: parseInt(change.value),
                  })
                );
              }}
            />
          ),
        }}
      />
    );
  }
);
