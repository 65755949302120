import { getCurrentUser, signIn, signOut } from 'aws-amplify/auth';

import { authConstants } from 'api/auth.constants';
import type { ApiResponse, ErrorResponse } from 'api/types';

export enum AmplifyAuthErrorCode {
  UserNotFoundException = 'UserNotFoundException',
  NotAuthorizedException = 'NotAuthorizedException',
}

export interface AmplifyUser {
  username: string;
  attributes: {
    email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    given_name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    family_name: string;
  };
}

export type AmplifyAuthError =
  | string
  | {
      code: AmplifyAuthErrorCode;
      name?: string;
      message?: string;
    };

const getAuthErrorResponse = (error: AmplifyAuthError): ErrorResponse => {
  if (typeof error === 'string') {
    return { ok: false, details: { message: error } };
  }
  if (
    error.code === AmplifyAuthErrorCode.NotAuthorizedException ||
    error.code === AmplifyAuthErrorCode.UserNotFoundException
  ) {
    return {
      ok: false,
      details: { message: authConstants.errorMessages.signIn },
    };
  }
  return {
    ok: false,
    details: {
      message: error.message ?? authConstants.errorMessages.unknown,
    },
  };
};

export const apiGetUserIsAuthenticated = async () => {
  try {
    await getCurrentUser();
    return { ok: true, data: undefined } as ApiResponse<undefined>;
  } catch (error) {
    return getAuthErrorResponse(error as AmplifyAuthError);
  }
};

interface ApiAuthSignInRequest {
  username: string;
  password: string;
}

export const apiAuthSignIn = async ({
  username,
  password,
}: ApiAuthSignInRequest) => {
  try {
    await signIn({ username, password });
    return { ok: true, data: undefined } as ApiResponse<undefined>;
  } catch (error) {
    return getAuthErrorResponse(error as AmplifyAuthError);
  }
};

export const apiAuthSignOut = async () => {
  try {
    await signOut();
    return { ok: true, data: undefined } as ApiResponse<undefined>;
  } catch (error) {
    return getAuthErrorResponse(error as AmplifyAuthError);
  }
};
