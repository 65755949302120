import type { FC, ReactNode } from 'react';
import { createContext, memo, useContext, useMemo, useState } from 'react';

interface HeaderContextValue {
  scrollHeaderChildren: ReactNode | undefined;
  setScrollHeaderChildren: (children: ReactNode) => void;
}

/**
 * A context that allows children to control the content of the `<Header />`
 *
 * Do not use this context directly in your components!\
 * Exported for testing purposes only.\
 * Use the `HeaderContextProvider` and `useHeaderContext` hook instead.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const HeaderContext = createContext<HeaderContextValue>({
  scrollHeaderChildren: undefined,
  setScrollHeaderChildren: () => {},
});

/**
 * Allows `<HeaderContextProvider />` children to control the content of the `<Header />`
 */
export const useHeaderContext = () => useContext(HeaderContext);

interface HeaderContextProviderProps {
  children: ReactNode;
}

/**
 * A provider that allows children to control the content of the `<Header />`
 */
export const HeaderContextProvider: FC<HeaderContextProviderProps> = memo(
  function HeaderProvider({ children }) {
    const [scrollHeaderChildren, setScrollHeaderChildren] =
      useState<ReactNode>();

    const value = useMemo(() => {
      return { scrollHeaderChildren, setScrollHeaderChildren };
    }, [scrollHeaderChildren]);

    return (
      <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
    );
  }
);
