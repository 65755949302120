export enum ApiCapabilitySettingType {
  Boolean = 'boolean',
  Numeric = 'numeric',
  Nominal = 'nominal',
}

export type ApiCapabilitySettingTypes =
  | ApiCapabilitySettingType.Boolean
  | ApiCapabilitySettingType.Numeric
  | ApiCapabilitySettingType.Nominal;
