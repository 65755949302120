import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ApiCcCuratedCollectionRecipe } from 'api/types/curatedCollection/apiCcCuratedCollectionRecipe';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import type { RootState } from 'app/store/rootReducer';
import {
  recipesSearchFiltersApplied,
  recipesSearchClear,
  recipesSearchTermUpdated,
  recipesSearchReset,
} from 'components/RecipesSearch/recipesSearchSlice';
import type { TablePaginationEvent } from 'components/Table/Table';
import { tableDefaults } from 'components/Table/Table.constants';
import { authSignOutFinished } from 'features/auth/authSlice';

export interface HomeFeedAddCollectionRecipesDialogState {
  isFetching: boolean;
  isOpen: boolean;
  page: number;
  rowsPerPage: number;
  rowCount: number;
  recipes: ApiCcCuratedCollectionRecipe[];
  selectedRecipes: Record<ApiRcpRecipeId, ApiCcCuratedCollectionRecipe>;
  apiError?: string;
}

export const initialState: HomeFeedAddCollectionRecipesDialogState = {
  isFetching: false,
  isOpen: false,
  page: tableDefaults.firstPage,
  rowsPerPage: tableDefaults.rowsPerPage,
  rowCount: 0,
  recipes: [],
  selectedRecipes: {},
};

export const homeFeedAddCollectionRecipesDialogSlice = createSlice({
  name: 'homeFeedAddCollectionRecipesDialogSlice',
  initialState,
  reducers: {
    homeFeedAddCollectionRecipesDialogOpened(
      state,
      {
        payload: { selectedRecipes },
      }: PayloadAction<{ selectedRecipes: ApiCcCuratedCollectionRecipe[] }>
    ) {
      state.isOpen = true;
      state.selectedRecipes = selectedRecipes.reduce(
        (selected, recipe) => {
          selected[recipe.id] = recipe;
          return selected;
        },
        {} as Record<ApiRcpRecipeId, ApiCcCuratedCollectionRecipe>
      );
    },
    homeFeedAddCollectionRecipesDialogClosed(state) {
      state.isOpen = false;
      state.selectedRecipes = initialState.selectedRecipes;
    },
    homeFeedAddCollectionRecipesDialogFetchRecipesRequested(state) {
      state.isFetching = true;
    },
    homeFeedAddCollectionRecipesDialogFetchRecipesSucceed(
      state,
      {
        payload,
      }: PayloadAction<{
        recipes: ApiCcCuratedCollectionRecipe[];
        total: number;
      }>
    ) {
      state.isFetching = false;
      state.recipes = payload.recipes;
      state.rowCount = payload.total;
    },
    homeFeedAddCollectionRecipesDialogFetchRecipesFailed(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.apiError = payload;
      state.isFetching = false;
    },
    homeFeedAddCollectionRecipesDialogFetchRecipesPaginated(
      state,
      { payload: { page, rowsPerPage } }: PayloadAction<TablePaginationEvent>
    ) {
      state.page = page;
      state.rowsPerPage = rowsPerPage;
    },
    homeFeedAddCollectionRecipesDialogSelectedRecipesChanged(
      state,
      { payload: { recipeIds } }: PayloadAction<{ recipeIds: ApiRcpRecipeId[] }>
    ) {
      /** Get complete recipes and add them to the selected recipes */
      recipeIds.forEach((id) => {
        if (state.selectedRecipes[id]) {
          return;
        }
        state.selectedRecipes[id] = state.recipes.find(
          (recipe) => recipe.id === id
        )!;
      });
      /** Remove recipes that are no longer selected */
      Object.keys(state.selectedRecipes).forEach((id) => {
        if (recipeIds.includes(id)) {
          return;
        }
        delete state.selectedRecipes[id];
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authSignOutFinished, () => initialState)
      .addMatcher(
        shouldResetPage,
        (state: HomeFeedAddCollectionRecipesDialogState) => {
          state.page = initialState.page;
        }
      );
  },
});

export const {
  reducer: homeFeedAddCollectionRecipesDialogReducer,
  actions: {
    homeFeedAddCollectionRecipesDialogOpened,
    homeFeedAddCollectionRecipesDialogClosed,
    homeFeedAddCollectionRecipesDialogFetchRecipesRequested,
    homeFeedAddCollectionRecipesDialogFetchRecipesSucceed,
    homeFeedAddCollectionRecipesDialogFetchRecipesFailed,
    homeFeedAddCollectionRecipesDialogFetchRecipesPaginated,
    homeFeedAddCollectionRecipesDialogSelectedRecipesChanged,
  },
} = homeFeedAddCollectionRecipesDialogSlice;

const selectHomeFeedAddCollectionRecipesDialogState = (state: RootState) =>
  state.homeFeedAddCollectionRecipesDialog;

export const selectHomeFeedAddCollectionRecipesDialogIsOpen = (
  state: RootState
) => selectHomeFeedAddCollectionRecipesDialogState(state).isOpen;

export const selectHomeFeedAddCollectionRecipesDialogIsFetching = (
  state: RootState
) => selectHomeFeedAddCollectionRecipesDialogState(state).isFetching;

export const selectHomeFeedAddCollectionRecipesDialogPage = (
  state: RootState
) => selectHomeFeedAddCollectionRecipesDialogState(state).page;

export const selectHomeFeedAddCollectionRecipesDialogRowsPerPage = (
  state: RootState
) => selectHomeFeedAddCollectionRecipesDialogState(state).rowsPerPage;

export const selectHomeFeedAddCollectionRecipesDialogRowCount = (
  state: RootState
) => selectHomeFeedAddCollectionRecipesDialogState(state).rowCount;

export const selectHomeFeedAddCollectionRecipesDialogRecipes = (
  state: RootState
) => selectHomeFeedAddCollectionRecipesDialogState(state).recipes;

export const selectHomeFeedAddCollectionRecipesDialogSelectedRecipes = (
  state: RootState
): ApiCcCuratedCollectionRecipe[] =>
  Object.values(
    selectHomeFeedAddCollectionRecipesDialogState(state).selectedRecipes
  );

export const shouldResetPage = (action: Action<string>): boolean =>
  [
    recipesSearchTermUpdated.type,
    recipesSearchFiltersApplied.type,
    recipesSearchClear.type,
    recipesSearchReset.type,
  ].includes(action.type);
