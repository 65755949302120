import { fetchJson } from 'api/fetchJson';
import { apiUserUrl } from 'api/paths';
import { HttpMethod } from 'api/types';
import type { ApiUsrUser } from 'api/types/user/apiUsrUser';

export const apiGetUser = async () =>
  fetchJson<ApiUsrUser>({
    httpMethod: HttpMethod.Get,
    url: apiUserUrl,
  });
