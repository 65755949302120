import type { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { createApiRequestSaga } from 'api/createApiRequestSaga';
import { apiGetPreparations } from 'api/preparations';
import type { ApiResponse } from 'api/types';
import type { ApiIngredientPreparation } from 'api/types/common/apiIngredientPreparation';
import {
  preparationsFetchFailed,
  preparationsFetching,
  preparationsFetchRequested,
  preparationsFetchSucceed,
  selectShouldFetchPreparations,
} from 'features/referenceData/preparations/preparationsSlice';
import {
  fetchItemsRecursively,
  refDataEntitiesToSentenceCase,
} from 'features/referenceData/referenceData.utils';
import type { AppLocalizedFetchActionPayload } from 'types/appLocalizedFetchActionPayload';

export const apiFetchPreparationsSaga =
  createApiRequestSaga(apiGetPreparations);

export function* fetchPreparations({
  payload: { locale },
}: PayloadAction<AppLocalizedFetchActionPayload>) {
  const shouldFetch = (yield select(
    selectShouldFetchPreparations(locale)
  )) as boolean;
  if (!shouldFetch) {
    return;
  }

  yield put(preparationsFetching({ locale }));

  const response = (yield call(fetchItemsRecursively, {
    locale,
    fetchSaga: apiFetchPreparationsSaga,
  })) as ApiResponse<ApiIngredientPreparation[]>;

  if (!response.ok) {
    const { message } = response.details;
    yield put(preparationsFetchFailed({ locale, data: message }));
    return;
  }

  yield put(
    preparationsFetchSucceed({
      locale,
      data: refDataEntitiesToSentenceCase(response.data),
    })
  );
}

function* preparationsFetchWatcher() {
  yield takeEvery(preparationsFetchRequested, fetchPreparations);
}

export const preparationsSagas = [preparationsFetchWatcher];
