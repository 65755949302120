import type { ApiRcpRecipe } from 'api/types/recipe/apiRcpRecipe';
import type { ApiRcpRecipeIngredientWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeIngredientWithRefs';
import { fromAppRecipeIngredient } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeIngredientWithRefs';
import type { ApiRcpRecipeStepWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeStepWithRefs';
import { fromAppRecipeStep } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeStepWithRefs';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { AppRecipe } from 'types/recipe/appRecipe';
import { fromAppTimeToISO } from 'utils/convertTimes';

export interface ApiRcpRecipeWithRefs
  extends Pick<
    ApiRcpRecipe,
    | 'name'
    | 'description'
    | 'author'
    | 'sourceUrl'
    | 'prepTime'
    | 'cookTime'
    | 'totalTime'
    | 'difficulty'
    | 'serves'
  > {
  ingredients: ApiRcpRecipeIngredientWithRefs[];
  steps: ApiRcpRecipeStepWithRefs[];
  referenceTagIds?: ApiRefId[];
}

export function fromAppRecipe({
  ingredients,
  name,
  description,
  prepTime,
  cookTime,
  totalTime,
  steps,
  author,
  applianceReferenceTags,
  generalTags,
  serves,
}: AppRecipe): ApiRcpRecipeWithRefs {
  return {
    ingredients: ingredients.map((ingredient) =>
      fromAppRecipeIngredient(ingredient)
    ),
    name,
    description,
    prepTime: prepTime ? fromAppTimeToISO(prepTime) : undefined,
    cookTime: cookTime ? fromAppTimeToISO(cookTime) : undefined,
    totalTime: totalTime ? fromAppTimeToISO(totalTime) : undefined,
    steps: steps.map(fromAppRecipeStep),
    author,
    referenceTagIds: [
      ...(applianceReferenceTags?.map((tag) => tag.id) ?? []),
      ...(generalTags?.map((tag) => tag.id) ?? []),
    ],
    serves,
  };
}
