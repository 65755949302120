export const homeFeedAddCollectionRecipesDialogStrings = {
  title: 'Add recipes',
  labels: {
    cancelButton: 'Cancel',
    saveButton: 'Save',
    locale: 'Locale',
    appliances: 'Appliance(s)',
    recipeTotal: 'Recipe total:',
    recipeList: 'Recipe list',
  },
  emptySearch: {
    title: 'No recipes found',
    text: "We couldn't find the recipe you're looking for. Try searching again with new criteria to help find a match.",
  },
  limitReached: {
    label: 'Maximum reached',
    description:
      'The collection’s recipe limit has been reached. If you wish to add a new recipe you will need to remove one first.',
  },
} as const;
