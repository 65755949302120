export const recipeFromTextDismissalDialogStrings = {
  texts: {
    title: 'Are you sure you want to cancel?',
    body: 'You will lose all progress made if you continue with this action.',
  },
  buttons: {
    dismiss: 'No, continue editing',
    continue: 'Yes, cancel',
  },
} as const;
