import upperFirst from 'lodash/upperFirst';

import type { ApiAplCapabilityAllowedSettingValueNominal } from 'api/types/appliance/apiAplCapabilityAllowedSettingValueNominal';
import type { ApiEntityId } from 'api/types/common/apiEntityId';

export interface AppCapabilityAllowedSettingValueNominal {
  id: ApiEntityId;
  name: string;
}

export const fromApiAplCapabilityAllowedSettingValueNominal = ({
  id,
  name,
}: ApiAplCapabilityAllowedSettingValueNominal): AppCapabilityAllowedSettingValueNominal => ({
  id,
  name: upperFirst(name),
});
