import type { FC } from 'react';
import { useEffect, useState, memo } from 'react';

import { useAutoFocus } from 'app/routes/hooks/useAutoFocus';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import type { DropResult } from 'components/DragAndDrop/DragZone';
import {
  calculateIndexAfterReordering,
  DragZone,
} from 'components/DragAndDrop/DragZone';
import { Draggable } from 'components/DragAndDrop/Draggable';
import {
  recipeStepDeleted,
  selectRecipeSteps,
  recipeStepMoved,
} from 'features/recipe/recipeSlice';
import { RecipePageEmptyList } from 'features/recipe/shared/RecipePageEmptyList/RecipePageEmptyList';
import { RecipePageListWithFormContainer } from 'features/recipe/shared/RecipePageListWithFormContainer/RecipePageListWithFormContainer';
import { RecipeStepsForm } from 'features/recipe/steps/form/RecipeStepsForm';
import { recipeStepsFormConstants } from 'features/recipe/steps/form/RecipeStepsForm.constants';
import {
  stepReset,
  selectIndex,
  selectIsStepUnsaved,
  stepIndexUpdated,
  selectIsFormShowing,
  stepFormShown,
} from 'features/recipe/steps/form/recipeStepsFormSlice';
import { RecipeStep } from 'features/recipe/steps/list/RecipeStep';
import {
  recipeStepParams,
  recipeStepsStrings,
} from 'features/recipe/steps/list/RecipeSteps.constants';
import { RecipeStepsEmptyImg } from 'features/recipe/steps/list/RecipeStepsEmptyImg';

const {
  title,
  messages,
  buttons,
  errors,
  ids,
  deleteStepDialog: { title: deleteTitle, body, confirmButton, cancelButton },
} = recipeStepsStrings;
const { fieldIds } = recipeStepsFormConstants;

export const RecipeSteps: FC = memo(function RecipeSteps() {
  const { setFocused } = useAutoFocus();

  const dispatch = useAppDispatch();
  const recipeSteps = useAppSelector(selectRecipeSteps);
  const selectedStepIndex = useAppSelector(selectIndex);
  const hasUnsavedStep = useAppSelector(selectIsStepUnsaved);
  const isFormShowing = useAppSelector(selectIsFormShowing);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [stepToDeleteIndex, setStepToDeleteIndex] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (selectedStepIndex !== null || hasUnsavedStep) {
      dispatch(stepFormShown(true));
    }
  }, [selectedStepIndex, hasUnsavedStep, dispatch]);

  const resetStep = () => dispatch(stepReset());

  const selectStep = (stepIndex: number) => {
    dispatch(stepReset());
    dispatch(stepIndexUpdated(stepIndex));
  };

  const deleteStep = () => {
    if (stepToDeleteIndex === null) {
      throw new Error(errors.deleteNoIndex);
    }
    if (stepToDeleteIndex === selectedStepIndex) {
      resetStep();
    }
    dispatch(recipeStepDeleted(stepToDeleteIndex));
    if (selectedStepIndex && stepToDeleteIndex < selectedStepIndex) {
      dispatch(stepIndexUpdated(selectedStepIndex - 1));
    }
    setStepToDeleteIndex(null);
  };

  const handleDrop = ({ from, to }: DropResult) => {
    dispatch(recipeStepMoved({ from, to }));

    if (selectedStepIndex === null) {
      return;
    }

    dispatch(
      stepIndexUpdated(
        calculateIndexAfterReordering(selectedStepIndex, from, to)
      )
    );
  };

  const shouldShowAddButton = !isFormShowing || selectedStepIndex !== null;

  const showForm = () => {
    setFocused(fieldIds.text);
    dispatch(stepFormShown(true));
  };

  return (
    <>
      <RecipePageListWithFormContainer
        title={title}
        components={{
          list: !recipeSteps.length ? (
            <RecipePageEmptyList
              text={messages.empty}
              img={<RecipeStepsEmptyImg sx={{ width: 336, height: 184 }} />}
            />
          ) : (
            <DragZone
              dragZoneId={ids.dragZone}
              onDrop={handleDrop}
              componentProps={{ placeholder: { sx: { pb: 6 } } }}
            >
              {recipeSteps.map((recipeStep, index) => (
                <Draggable
                  key={recipeStep.id}
                  draggableId={recipeStep.id}
                  index={index}
                  renderContent={(props) => (
                    <RecipeStep
                      onClick={() => {
                        if (selectedStepIndex !== index) {
                          selectStep(index);
                        }
                        showForm();
                      }}
                      onDelete={() => {
                        setStepToDeleteIndex(index);
                        setIsDeleteDialogOpen(true);
                      }}
                      selected={index === selectedStepIndex}
                      step={recipeStep}
                      stepIndex={index}
                      totalSteps={recipeSteps.length}
                      sx={{ mb: 6 }}
                      draggable
                      {...props}
                    />
                  )}
                />
              ))}
            </DragZone>
          ),
          form: isFormShowing ? <RecipeStepsForm /> : null,
        }}
        buttons={{
          add: shouldShowAddButton
            ? {
                label: buttons.add,
                onClick: () => {
                  resetStep();
                  showForm();
                },
              }
            : undefined,
        }}
      />
      {stepToDeleteIndex !== null && (
        <ConfirmationDialog
          isOpen={isDeleteDialogOpen}
          text={{
            body,
            confirmButton,
            cancelButton,
            title: deleteTitle.replace(
              recipeStepParams.stepNumber,
              `${stepToDeleteIndex + 1}`
            ),
          }}
          onClose={(hasConfirmed) => {
            setIsDeleteDialogOpen(false);
            if (hasConfirmed) {
              deleteStep();
            }
          }}
        />
      )}
    </>
  );
});
