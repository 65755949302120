import {
  PantryTypography,
  PantryColor,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import type { ApiLocale } from 'api/types/common/apiLocale';
import { ContentByLocale } from 'components/ContentByLocale/ContentByLocale';
import { recipeValueInMultipleSystemsStrings } from 'features/recipe/shared/RecipeValueInMultipleSystems/RecipeValueInMultipleSystems.constants';

const { separator } = recipeValueInMultipleSystemsStrings;

export interface RecipeValueInMultipleSystemsProps {
  metric: string;
  usCustomary: string;
  locale: ApiLocale;
  styles?: { value?: SxProps; empty?: SxProps };
}

export const RecipeValueInMultipleSystems: FC<RecipeValueInMultipleSystemsProps> =
  memo(function RecipeValueInMultipleSystems({
    metric,
    usCustomary,
    locale,
    styles,
  }) {
    return (
      <Box sx={{ display: 'flex', gap: 2 }}>
        <ContentByLocale
          metricContent={<RecipeValueText text={metric} sx={styles?.value} />}
          usCustomaryContent={
            <RecipeValueText text={usCustomary} sx={styles?.value} />
          }
          locale={locale}
          separator={<RecipeValueText text={separator} sx={styles?.value} />}
        />
      </Box>
    );
  });

export const RecipeValueText: FC<{ text: string; sx?: SxProps }> = memo(
  function RecipeValueText({ text, sx }) {
    return (
      <Typography
        component="span"
        variant={PantryTypography.Body2}
        sx={sxCompose({ color: PantryColor.TextDefault }, sx)}
      >
        {text}
      </Typography>
    );
  }
);
