import type { ErrorResponseDetails } from 'api/types';

export enum AppErrorType {
  Blocking = 'blocking',
  Informative = 'informative',
}

export type AppError = Pick<ErrorResponseDetails, 'message'> & {
  type?: AppErrorType;
  description?: string;
};
