import type { ApiRcpRecipe } from 'api/types/recipe/apiRcpRecipe';

type ApiCcCuratedCollectionRecipeBase = Required<
  Pick<ApiRcpRecipe, 'id' | 'name' | 'author' | 'state'>
> &
  Partial<Pick<ApiRcpRecipe, 'referenceTags' | 'totalTime'>>;

export interface ApiCcCuratedCollectionRecipe
  extends ApiCcCuratedCollectionRecipeBase {
  ingredientsCount: number;
  stepsCount: number;
}

export const fromApiRcpRecipe = ({
  id,
  name,
  author,
  state,
  referenceTags,
  totalTime,
  ingredients,
  steps,
}: ApiRcpRecipe): ApiCcCuratedCollectionRecipe => {
  return {
    id,
    name,
    author,
    state,
    referenceTags,
    totalTime,
    ingredientsCount: ingredients.length,
    stepsCount: steps.length,
  };
};
