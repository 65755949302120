import { number } from 'yup';

import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import {
  capabilityFieldParams,
  capabilityFieldStrings,
} from 'components/CapabilityField/CapabilityField.constants';
import type {
  CapabilitySettingsValidator,
  CapabilitySettingsErrors,
  CapabilitySettingsErrorInMultipleSystems,
} from 'components/CapabilityField/CapabilityField.types';
import type { AppCapabilityAllowedSetting } from 'types/appCapabilityAllowedSetting';
import type { AppCapabilityAllowedSettingValueNumericInSingleSystem } from 'types/appCapabilityAllowedSettingValueNumeric';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import { isTimeSettingId } from 'types/appCapabilitySettings.utils';
import type { AppRecipeStepCapabilitySetting } from 'types/recipe/appRecipeStepCapabilitySetting';
import {
  fromAppTimeToSeconds,
  fromAppTimeToSentence,
  fromSecondsToAppTime,
} from 'utils/convertTimes';
import {
  isValueInMultipleSystems,
  isValueInSingleSystem,
} from 'utils/unitSystems';
import type { ValidatorErrors } from 'utils/validator';
import { Validator } from 'utils/validator';

export const generateNumericSettingValidatorId = (
  settingId: ApiEntityId,
  unitId: ApiRefId
): string => `${settingId}-${unitId}`;

export const generateNumericSettingValidatorMessages = ({
  settingId,
  unit,
  min = 0,
  max = 0,
  step = 0,
  messages,
}: {
  settingId: ApiEntityId;
  unit: string;
  min?: number;
  max?: number;
  step?: number;
  messages: Record<string, Record<string, string>>;
}) => {
  const {
    minPlaceholder,
    maxPlaceholder,
    stepPlaceholder,
    unitAbbreviationPlaceholder,
  } = capabilityFieldParams;

  if (isTimeSettingId(settingId)) {
    const errorMessages = messages.timeSetting;
    return {
      min: errorMessages.min.replace(
        minPlaceholder,
        fromAppTimeToSentence(fromSecondsToAppTime(min, { removeZeros: false }))
      ),
      max: errorMessages.max.replace(
        maxPlaceholder,
        fromAppTimeToSentence(fromSecondsToAppTime(max, { removeZeros: false }))
      ),
      minAndMax: errorMessages.minAndMax
        .replace(
          minPlaceholder,
          fromAppTimeToSentence(
            fromSecondsToAppTime(min, { removeZeros: false })
          )
        )
        .replace(
          maxPlaceholder,
          fromAppTimeToSentence(
            fromSecondsToAppTime(max, { removeZeros: false })
          )
        ),
      step: errorMessages.step.replace(
        stepPlaceholder,
        fromAppTimeToSentence(
          fromSecondsToAppTime(step, { removeZeros: false })
        )
      ),
      stepWithMin: errorMessages.stepWithMin
        .replace(
          minPlaceholder,
          fromAppTimeToSentence(
            fromSecondsToAppTime(min, { removeZeros: false })
          )
        )
        .replace(
          stepPlaceholder,
          fromAppTimeToSentence(
            fromSecondsToAppTime(step, { removeZeros: false })
          )
        ),
    };
  }

  const errorMessages = messages.numericSetting;
  return {
    min: errorMessages.min
      .replace(minPlaceholder, `${min}`)
      .replaceAll(unitAbbreviationPlaceholder, `${unit}`),
    max: errorMessages.max
      .replace(maxPlaceholder, `${max}`)
      .replaceAll(unitAbbreviationPlaceholder, `${unit}`),
    minAndMax: errorMessages.minAndMax
      .replace(minPlaceholder, `${min}`)
      .replace(maxPlaceholder, `${max}`)
      .replaceAll(unitAbbreviationPlaceholder, `${unit}`),
    step:
      step === 1
        ? errorMessages.stepOf1
        : errorMessages.step
            .replace(stepPlaceholder, `${step}`)
            .replaceAll(unitAbbreviationPlaceholder, `${unit}`),
    stepWithMin: errorMessages.stepWithMin
      .replace(minPlaceholder, `${min}`)
      .replaceAll(unitAbbreviationPlaceholder, `${unit}`)
      .replace(stepPlaceholder, `${step}`),
  };
};

export const generateNumericSettingValidator = ({
  settingId,
  unit,
  min,
  max,
  step,
}: {
  settingId: ApiEntityId;
  unit: string;
  min?: number;
  max?: number;
  step?: number;
}) => {
  const errorMessages = generateNumericSettingValidatorMessages({
    settingId,
    unit,
    min,
    max,
    step,
    messages: capabilityFieldStrings.errors,
  });
  return generateNumericValidator({
    min,
    max,
    step,
    errorMessages,
  });
};

export const generateNumericValidator = ({
  min,
  max,
  step,
  errorMessages,
}: {
  min?: number;
  max?: number;
  step?: number;
  errorMessages: Record<string, string>;
}): Validator => {
  let validator = number();
  if (step) {
    validator = validator.test(
      'step',
      min ? errorMessages.stepWithMin : errorMessages.step,
      (value) => {
        if (value === undefined) {
          return true;
        }
        if (min) {
          return Number.isInteger((value - min) / step);
        }
        return Number.isInteger(value / step);
      }
    );
  }
  const maxAndMinMessage =
    min !== undefined && max !== undefined && errorMessages.minAndMax;
  if (min !== undefined) {
    validator = validator.min(min, maxAndMinMessage || errorMessages.min);
  }
  if (max !== undefined) {
    validator = validator.max(max, maxAndMinMessage || errorMessages.max);
  }
  return new Validator({ numeric: validator });
};

export const generateCapabilitySettingsValidator = (
  settings: AppCapabilityAllowedSetting[]
): CapabilitySettingsValidator => {
  return settings.reduce((validators, setting) => {
    if (setting.allowedValues.numeric) {
      return {
        ...validators,
        ...setting.allowedValues.numeric.reduce(
          (numericValidators: CapabilitySettingsValidator, allowedValue) => {
            const createValidator = ({
              unit,
              min,
              max,
              step,
            }: AppCapabilityAllowedSettingValueNumericInSingleSystem): {
              validatorId: string;
              validator: Validator;
            } => {
              const validatorId = generateNumericSettingValidatorId(
                setting.id,
                unit.id
              );
              const validator = generateNumericSettingValidator({
                settingId: setting.id,
                unit: unit.abbreviation ?? '',
                min,
                max,
                step,
              });
              return { validatorId, validator };
            };

            const addValidator = ({
              validatorId,
              validator,
            }: {
              validatorId: string;
              validator: Validator;
            }) => {
              if (numericValidators[validatorId]) {
                numericValidators[validatorId].push(validator);
              } else {
                numericValidators[validatorId] = [validator];
              }
            };

            if (isValueInSingleSystem(allowedValue)) {
              addValidator(createValidator(allowedValue));
            }
            if (isValueInMultipleSystems(allowedValue)) {
              addValidator(createValidator(allowedValue.metric));
              addValidator(createValidator(allowedValue.usCustomary));
            }

            return numericValidators;
          },
          {}
        ),
      };
    }
    return validators;
  }, {} as CapabilitySettingsValidator);
};

export const validateCapabilitySettings = (
  validator: CapabilitySettingsValidator,
  settings: AppRecipeStepCapabilitySetting[]
): CapabilitySettingsErrors | undefined => {
  if (!settings.length) {
    return undefined;
  }
  const result = settings.reduce((errors, { id: settingId, value }) => {
    if (value.type === AppCapabilitySettingType.Time) {
      const validatorId = generateNumericSettingValidatorId(
        settingId,
        value.unit.id
      );
      if (validator[validatorId]) {
        const timeErrors = validator[validatorId].map((item) =>
          item.validate({ numeric: fromAppTimeToSeconds(value.value) })
        );
        if (!timeErrors.includes(undefined)) {
          errors[settingId] = timeErrors as ValidatorErrors[];
        }
      }
    }

    if (
      value.type === AppCapabilitySettingType.Numeric &&
      isValueInSingleSystem(value.value)
    ) {
      const { amount, unit } = value.value;
      const validatorId = generateNumericSettingValidatorId(settingId, unit.id);
      if (validator[validatorId]) {
        const numericErrors = validator[validatorId].map((item) =>
          item.validate({ numeric: amount })
        );
        if (!numericErrors.includes(undefined)) {
          errors[settingId] = numericErrors as ValidatorErrors[];
        }
      }
    }

    if (
      value.type === AppCapabilitySettingType.Numeric &&
      isValueInMultipleSystems(value.value)
    ) {
      const settingErrors: Required<CapabilitySettingsErrorInMultipleSystems> =
        {
          metric: [],
          usCustomary: [],
        };
      const { metric, usCustomary } = value.value;
      const metricValidatorId = generateNumericSettingValidatorId(
        settingId,
        metric.unit.id
      );
      if (validator[metricValidatorId]) {
        const metricErrors = validator[metricValidatorId].map((item) =>
          item.validate({ numeric: metric.amount })
        );
        if (!metricErrors.includes(undefined)) {
          settingErrors.metric.push(...(metricErrors as ValidatorErrors[]));
        }
      }
      const usCustomaryValidatorId = generateNumericSettingValidatorId(
        settingId,
        usCustomary.unit.id
      );
      if (validator[usCustomaryValidatorId]) {
        const usCustomaryErrors = validator[usCustomaryValidatorId].map(
          (item) => item.validate({ numeric: usCustomary.amount })
        );
        if (!usCustomaryErrors.includes(undefined)) {
          settingErrors.usCustomary.push(
            ...(usCustomaryErrors as ValidatorErrors[])
          );
        }
      }

      if (settingErrors.metric.length || settingErrors.usCustomary.length) {
        errors[settingId] = {
          ...(settingErrors.metric.length && {
            metric: settingErrors.metric,
          }),
          ...(settingErrors.usCustomary.length && {
            usCustomary: settingErrors.usCustomary,
          }),
        };
      }
    }

    return errors;
  }, {} as CapabilitySettingsErrors);
  return Object.values(result).length ? result : undefined;
};
