import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { Box, Link as MuiLink, Typography } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-pro';
import type { FC } from 'react';
import { useMemo, useEffect, memo } from 'react';
import { Link } from 'react-router-dom';

import type { ApiAplId } from 'api/types/appliance/apiAplId';
import { generateApplianceRoute } from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { Chip } from 'components/Chip/Chip';
import type { TableColumn } from 'components/Table/Table';
import { Table } from 'components/Table/Table';
import {
  appliancesFetchRequested,
  selectAppliances,
  selectAppliancesFetching,
} from 'features/appliances/appliancesSlice';
import {
  appliancesListStrings,
  appliancesListConstants,
} from 'features/appliances/list/AppliancesList.constants';
import { AppliancesListSkeleton } from 'features/appliances/list/AppliancesListSkeleton';
import { ApplianceImage } from 'features/recipe/shared/ApplianceImage/ApplianceImage';
import {
  selectApplianceTags,
  tagsFetchRequested,
} from 'features/referenceData/tags/tagsSlice';

const { image, table } = appliancesListConstants;
const { headers } = appliancesListStrings;

interface ApplianceMainInfoCellProps {
  id: ApiAplId;
  name: string;
}

const ApplianceMainInfoCell: FC<ApplianceMainInfoCellProps> = memo(
  function ApplianceMainInfoCell({ id, name }) {
    const { width, height } = image;
    const to = generateApplianceRoute({ id });

    return (
      <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <MuiLink component={Link} to={to}>
          <ApplianceImage
            applianceId={id}
            locale={table.locale}
            sx={{
              width,
              height,
            }}
          />
        </MuiLink>
        <MuiLink
          component={Link}
          to={to}
          sx={{ color: PantryColor.TextDefault }}
        >
          <Typography variant={PantryTypography.Body1SemiBold}>
            {name}
          </Typography>
        </MuiLink>
      </Box>
    );
  }
);

interface ApplianceRow {
  id: ApiAplId;
  name: string;
  tags: string[];
}

const columns: TableColumn[] = [
  {
    field: 'name',
    headerName: headers.mainInfo,
    ...table.columns.mainInfo,
    renderCell: ({ row }: GridRenderCellParams<undefined, ApplianceRow>) => {
      return <ApplianceMainInfoCell id={row.id} name={row.name} />;
    },
  },
  {
    field: 'id',
    headerName: headers.id,
    ...table.columns.id,
    renderCell: ({ row }: GridRenderCellParams<undefined, ApplianceRow>) => (
      <Typography
        variant={PantryTypography.Body2}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {row.id}
      </Typography>
    ),
  },
  {
    field: 'partner',
    headerName: headers.partner,
    ...table.columns.partner,
    renderCell: ({ row }: GridRenderCellParams<undefined, ApplianceRow>) => {
      return (
        <Typography
          variant={PantryTypography.Body2}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {row.id.split(':')[1]}
        </Typography>
      );
    },
  },
  {
    field: 'tags',
    headerName: headers.tags,
    ...table.columns.tags,
    renderCell: ({ row }: GridRenderCellParams<undefined, ApplianceRow>) => {
      return (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: '100%',
          }}
        >
          {row.tags.length
            ? row.tags.map((tag) => <Chip label={tag} key={tag} />)
            : table.emptyCell}
        </Box>
      );
    },
  },
];

/**
 * Fetches and displays a list of all appliances on platform.
 */
export const AppliancesList: FC = memo(function AppliancesPage() {
  const dispatch = useAppDispatch();

  const isLoadingAppliances = useAppSelector(
    selectAppliancesFetching(table.locale)
  );
  const appliances = useAppSelector(selectAppliances(table.locale));
  const rowCount = appliances?.length ?? 0;

  const applianceTagsList = useAppSelector(selectApplianceTags(table.locale));

  const appliancesRows: ApplianceRow[] = useMemo(
    () =>
      appliances?.map((appliance) => ({
        id: appliance.id,
        name: appliance.name,
        tags: appliance.referenceTagIds.map(
          (tagId) => applianceTagsList?.[tagId]?.name || tagId
        ),
      })) ?? [],
    [applianceTagsList, appliances]
  );

  useEffect(() => {
    dispatch(appliancesFetchRequested({ locale: table.locale }));
    dispatch(tagsFetchRequested({ locale: table.locale }));
  }, [dispatch]);

  return (
    <Table
      ariaLabel={table.ariaLabel}
      rowHeight={table.rowHeight}
      columns={columns}
      rows={appliancesRows}
      loading={isLoadingAppliances !== false}
      rowCount={rowCount}
      paginationMode="client"
      components={{
        loadingOverlay: AppliancesListSkeleton,
      }}
    />
  );
});
