import {
  Button,
  ButtonSize,
  ButtonStyle,
  AddCircleIcon,
  PantryColor,
  PantryTypography,
  TuneIcon,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps, Theme } from '@mui/material';
import { Box, Popover, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useRef, memo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { theme } from 'app/theme';
import {
  recipeAdvancedSearchConstants,
  recipeAdvancedSearchStrings,
} from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearch.constants';
import { RecipesAdvancedSearchFilter } from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter';
import {
  RecipesAdvancedSearchFilterType,
  recipeAdvancedSearchFilterConstants,
} from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.constants';
import {
  selectIsAdvancedSearchPanelOpen,
  selectRecipesCurrentFilters,
  selectRecipesFilterKeys,
  recipesSearchFilterAdded,
  recipesSearchAdvancedPanelClosed,
  recipesSearchCurrentFiltersReset,
  recipesSearchAdvancedPanelOpened,
  recipesSearchFiltersApplied,
  selectRecipesManuallyAppliedFilters,
} from 'components/RecipesSearch/recipesSearchSlice';
import { WithSurface, SurfaceType } from 'components/WithSurface/WithSurface';

const { panel, filters } = recipeAdvancedSearchConstants;
const { title, buttons, noFilters } = recipeAdvancedSearchStrings;
const { style } = recipeAdvancedSearchFilterConstants;

export interface RecipesAdvancedSearchProps {
  anchorEl: Element | null;
  sx?: SxProps<Theme>;
}

export const RecipesAdvancedSearch: FC<RecipesAdvancedSearchProps> = memo(
  function RecipesAdvancedSearch({ anchorEl, sx }) {
    const dispatch = useAppDispatch();

    const timeoutRef = useRef<number | null>(null);
    const isPanelOpen = useAppSelector(selectIsAdvancedSearchPanelOpen);
    const currentFilters = useAppSelector(selectRecipesCurrentFilters);
    const manuallyAppliedFilters = useAppSelector(
      selectRecipesManuallyAppliedFilters
    );
    const filterKeys = useAppSelector(selectRecipesFilterKeys);

    const hasAppliedFilters = !!manuallyAppliedFilters.length;

    useEffect(() => {
      if (!hasAppliedFilters && isPanelOpen) {
        dispatch(recipesSearchFilterAdded());
      }
    }, [dispatch, isPanelOpen, hasAppliedFilters]);

    useEffect(() => {
      return () => {
        if (timeoutRef.current === null) {
          return;
        }
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      };
    }, []);

    const handlePanelClose = () => {
      dispatch(recipesSearchAdvancedPanelClosed());
      /** Wait for the closing animation to finish before resetting the filters */
      timeoutRef.current = window.setTimeout(
        () => dispatch(recipesSearchCurrentFiltersReset()),
        theme.transitions.duration.leavingScreen
      );
    };

    return (
      <>
        <Button
          label={buttons.togglePanel}
          hideLabel
          buttonStyle={ButtonStyle.Subtle}
          size={ButtonSize.Medium}
          onClick={() => dispatch(recipesSearchAdvancedPanelOpened())}
          leadingIcon={RecipesAdvancedSearchIcon}
          trailingIcon={AppliedFiltersBadge}
        />
        <Popover
          id={panel.id}
          open={isPanelOpen}
          anchorEl={anchorEl}
          onClose={handlePanelClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          PaperProps={{
            sx: sxCompose(
              {
                border: '1px solid',
                borderColor: PantryColor.FrescoPrimary,
                borderRadius: 1,
                marginTop: 3,
                py: style.extraPadding,
                px: style.padding,
                display: 'flex',
                flexDirection: 'column',
                gap: 6,
              },
              sx
            ),
          }}
        >
          <Typography
            variant={PantryTypography.Subtitle1}
            sx={{ px: style.padding }}
          >
            {title}
          </Typography>
          <Box>
            {currentFilters.length ? (
              currentFilters.map(
                (filter, index) =>
                  filter.type === RecipesAdvancedSearchFilterType.Manual && (
                    <RecipesAdvancedSearchFilter
                      key={filter.key ?? index}
                      index={index}
                      filter={filter}
                    />
                  )
              )
            ) : (
              <Typography
                variant={PantryTypography.Body2}
                sx={{ px: style.padding, mb: 4 }}
              >
                {noFilters}
              </Typography>
            )}
            {currentFilters.length < filters.maxAmount &&
            currentFilters.length < filterKeys.length ? (
              <Button
                label={buttons.newFilter}
                buttonStyle={ButtonStyle.Subtle}
                size={ButtonSize.Small}
                onClick={() => dispatch(recipesSearchFilterAdded())}
                leadingIcon={AddCircleIcon}
              />
            ) : null}
          </Box>
          <Box
            sx={{
              borderTop: '1px solid',
              borderColor: PantryColor.BorderDefault,
              mx: style.padding,
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              px: style.padding,
            }}
          >
            <Button
              label={buttons.search}
              buttonStyle={ButtonStyle.Emphasis}
              size={ButtonSize.Medium}
              onClick={() => dispatch(recipesSearchFiltersApplied())}
            />
          </Box>
        </Popover>
      </>
    );
  }
);

const RecipesAdvancedSearchIcon: FC = memo(
  function RecipesAdvancedSearchIcon() {
    const isPanelOpen = useAppSelector(selectIsAdvancedSearchPanelOpen);
    const manuallyAppliedFilters = useAppSelector(
      selectRecipesManuallyAppliedFilters
    );

    return (
      <TuneIcon
        size={24}
        color={
          isPanelOpen || !!manuallyAppliedFilters.length
            ? PantryColor.IconEmphasis
            : PantryColor.IconDefault
        }
      />
    );
  }
);

const AppliedFiltersBadge: FC = memo(function AppliedFiltersBadge() {
  const manuallyAppliedFilters = useAppSelector(
    selectRecipesManuallyAppliedFilters
  );

  return manuallyAppliedFilters.length ? (
    <WithSurface surface={SurfaceType.Dark}>
      <Box
        sx={{
          height: '22px',
          width: '22px',
          borderRadius: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          typography: PantryTypography.CaptionSemiBold,
          color: PantryColor.TextDefault,
          backgroundColor: PantryColor.SurfaceMuted,
          ml: 1,
        }}
      >
        {manuallyAppliedFilters.length}
      </Box>
    </WithSurface>
  ) : null;
});
