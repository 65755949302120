export enum RecipeTabName {
  Information = 'information',
  Ingredients = 'ingredients',
  Steps = 'steps',
  Review = 'review',
}

export const recipePageStrings = {
  labels: {
    createTitle: 'Create a recipe',
    saveButton: 'Save',
    savedButton: 'Saved',
    publishButton: 'Publish',
    unpublishButton: 'Unpublish',
    leaveModal: {
      title: 'Recipe has not been saved',
      body: 'Are you sure you want to exit?',
      bodySavedAgo: (timeAgo: string) =>
        `Recipe was last saved ${timeAgo} ago.`,
      bodyErrors: 'We are unable to save your work',
      bodyValidationErrors: 'because of one or more errors in your recipe.',
      confirmButton: 'Exit without saving',
      cancelButton: 'Cancel',
    },
    localeField: {
      label: 'Recipe locale',
      placeholder: 'Select recipe locale',
      hint: "A recipe's locale cannot be changed at a later stage",
    },
    coreRecipe: 'Core',
    translationRecipe: 'Translations',
    reviewTabBadge: {
      oneIssue: 'issue found',
      multipleIssues: 'issues found',
    },
    tabs: {
      [RecipeTabName.Information]: {
        title: 'Basic Information',
        description: 'Enter information about this recipe',
      },
      [RecipeTabName.Ingredients]: {
        title: 'Ingredients',
        description: 'Add ingredients and how to prepare them',
      },
      [RecipeTabName.Steps]: {
        title: 'Steps',
        description: 'Add steps to be taken in cooking this dish',
      },
      [RecipeTabName.Review]: {
        title: 'Review',
        description: 'Review and publish this recipe',
      },
    },
  },
  ariaLabels: {
    title: 'Loading title',
    localeInfo: 'Loading locale information',
  },
} as const;

export const recipePageConstants = {
  autosave: {
    delay: 45000, // ms
    changeMessageDelay: 10000, // ms
  },
  publish: {
    unusedIngredientThreshold: 0.001,
  },
} as const;
