import {
  Button,
  ButtonSize,
  ButtonStyle,
  CheckIcon,
  DeleteIcon,
  PantryColor,
  PantryTypography,
} from '@dropkitchen/pantry-react';
import { Box, MenuItem, Typography } from '@mui/material';
import type { FC } from 'react';
import { useMemo, useState, memo } from 'react';

import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import { MenuButton } from 'components/MenuButton/MenuButton';
import { selectConfigsLocales } from 'features/configs/configsSlice';
import type { RecipeRow } from 'features/recipes/list/RecipesList';
import {
  recipesListConstants,
  recipesListParams,
} from 'features/recipes/list/RecipesList.constants';
import {
  recipesDeleteRequested,
  recipesTranslateRequested,
  recipesPublishRequested,
  recipesUnpublishRequested,
  selectRecipesFetching,
} from 'features/recipes/recipesSlice';
import { appDisplayCodeByLocale } from 'types/appDisplayCodeByLocale';
import { appRegionByLocale } from 'types/appRegionByLocale';
import type { AppRecipe } from 'types/recipe/appRecipe';
import { recipeStates } from 'types/recipe/appRecipeState';

const {
  table: { actions },
} = recipesListConstants;

export interface RecipesListActionsProps {
  row: RecipeRow;
  onAction: () => void;
}

export interface RecipesListTranslateActionProps
  extends RecipesListActionsProps {
  existingTranslations?: AppRecipe[];
}

export const RecipesListTranslateAction: FC<RecipesListTranslateActionProps> =
  memo(function RecipesListTranslateAndDeleteActions({
    row: { id: recipeId, locale: recipeLocale },
    existingTranslations = [],
    onAction,
  }) {
    const dispatch = useAppDispatch();
    const supportedLocales = useAppSelector(selectConfigsLocales);
    const existingLocales = useMemo(
      () => existingTranslations.map((translation) => translation.locale),
      [existingTranslations]
    );
    const availableLocales = useMemo(
      () =>
        supportedLocales?.filter(
          (locale) =>
            !existingLocales.includes(locale) && locale !== recipeLocale
        ),
      [existingLocales, supportedLocales, recipeLocale]
    );
    return (
      <MenuButton label={actions.translate.label} minWidth="130px">
        {!availableLocales?.length ? (
          <Typography
            variant={PantryTypography.Body1}
            sx={{
              px: 4,
              py: 2,
              color: PantryColor.TextDefault,
            }}
          >
            {actions.translate.noLocales}
          </Typography>
        ) : (
          availableLocales?.map((locale) => (
            <MenuItem
              onClick={() => {
                dispatch(
                  recipesTranslateRequested({
                    recipeId,
                    locale,
                  })
                );
                onAction();
              }}
              disableRipple
              key={locale}
            >
              {appDisplayCodeByLocale[locale]}, {appRegionByLocale[locale]}
            </MenuItem>
          ))
        )}
      </MenuButton>
    );
  });

export const RecipesListDeleteAction: FC<RecipesListActionsProps> = memo(
  function RecipesListDeleteAction({
    row: { id: recipeId, state, name },
    onAction,
  }) {
    const dispatch = useAppDispatch();
    const isDeleting = useAppSelector(selectRecipesFetching);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
      useState<boolean>(false);

    const handleDeleteDialogClose = (hasConfirmed: boolean) => {
      setIsDeleteDialogOpen(false);
      if (hasConfirmed) {
        dispatch(recipesDeleteRequested([{ recipeId, state }]));
      }
      onAction();
    };

    return (
      <>
        <Button
          label={actions.delete.ariaLabel}
          hideLabel
          leadingIcon={DeleteRecipeIcon}
          onClick={() => setIsDeleteDialogOpen(true)}
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.Subtle}
          sx={{ ml: 3 }}
        />
        <ConfirmationDialog
          text={{
            title: actions.delete.dialog.title.replace(
              recipesListParams.recipeName,
              name
            ),
            cancelButton: actions.delete.dialog.cancelButton,
            ...(state === ApiRcpRecipeState.Published
              ? actions.delete.dialog.published
              : actions.delete.dialog.draft),
          }}
          isOpen={isDeleteDialogOpen}
          onClose={handleDeleteDialogClose}
          isConfirmButtonLoading={isDeleting}
        />
      </>
    );
  }
);

const DeleteRecipeIcon: FC = memo(function DeleteRecipeIcon() {
  return <DeleteIcon size={24} color={PantryColor.IconDefault} />;
});

export const RecipesListChangeStatusAction: FC<RecipesListActionsProps> = memo(
  function RecipesListChangeStatusAction({
    row: { id: recipeId, state },
    onAction,
  }) {
    const dispatch = useAppDispatch();

    return (
      <Box>
        <MenuButton label={actions.changeStatus} minWidth="130px">
          {recipeStates.map((option) => (
            <MenuItem
              key={option.id}
              onClick={() => {
                if (option.id === ApiRcpRecipeState.Draft) {
                  dispatch(recipesUnpublishRequested([recipeId]));
                }
                if (option.id === ApiRcpRecipeState.Published) {
                  dispatch(recipesPublishRequested([recipeId]));
                }
                onAction();
              }}
              disableRipple
            >
              {option.name}
              {state === option.id && <CheckIcon sx={{ ml: 2.5 }} size={24} />}
            </MenuItem>
          ))}
        </MenuButton>
      </Box>
    );
  }
);
