export interface AppMediaStorageFile extends File {
  preview: string;
}

let file: AppMediaStorageFile | undefined;

export interface AppMediaStorage {
  get: () => AppMediaStorageFile | undefined;
  add: (file: File) => void;
  clear: () => void;
}

const get = () => file;

const clear = () => {
  if (file?.preview) {
    window.URL.revokeObjectURL(file.preview);
  }
  file = undefined;
};

const add = (addedFile: File) => {
  clear();
  file = Object.assign(addedFile, {
    preview: window.URL.createObjectURL(addedFile),
  });
};

export const mediaStorage: AppMediaStorage = { get, clear, add };
