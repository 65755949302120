/* Disabling rule of hooks so we can declare them in this configuration file, separated from the component file */
/* eslint-disable react-hooks/rules-of-hooks */
import isEmpty from 'lodash/isEmpty';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import { useAppSelector } from 'app/store/hooks';
import type { AppDispatch } from 'app/store/store';
import type {
  RecipesAdvancedSearchFilterErrors,
  RecipesAdvancedSearchFilterValue,
  RecipesAdvancedSearchFilters,
} from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.types';
import {
  selectConfigsFetching,
  selectConfigsLocales,
} from 'features/configs/configsSlice';
import {
  selectTagsFetching,
  selectApplianceTags,
  tagsFetchRequested,
  selectTagsFetchError,
  selectGeneralTagsDisambiguated,
} from 'features/referenceData/tags/tagsSlice';
import { appDisplayCodeByLocale } from 'types/appDisplayCodeByLocale';
import { recipeStates } from 'types/recipe/appRecipeState';

export const recipeAdvancedSearchFilterStrings = {
  buttons: {
    deleteFilter: 'Delete filter',
  },
  errorMessages: {
    required: 'Please select an option',
    textRequired: 'Please enter a value',
  },
} as const;

export const recipeAdvancedSearchFilterConstants = {
  autocomplete: {
    style: {
      width: '180px',
      fullWidth: '240px',
      height: '56px',
    },
  },
  style: {
    padding: 3,
    extraPadding: 6,
    height: '88px',
  },
} as const;

/** Filter connectors */
export enum RecipesAdvancedSearchConnector {
  Where = 'Where',
  And = 'And',
}

/** Filter Keys */
export enum RecipesAdvancedSearchKey {
  ApplianceTags = 'Recipe Appliance',
  GeneralTags = 'Recipe Tags',
  State = 'Status',
  Locale = 'Locale',
  Author = 'Author',
}

/** Filter Operators */
export enum RecipesAdvancedSearchOperator {
  AllOf = 'All of',
  OneOrMoreOf = 'One or more of',
  AnyOf = 'Any of',
  NoneOf = 'None of',
  Is = 'Is',
  IsNot = 'Is not',
  Contains = 'Contains',
}

export enum RecipesAdvancedSearchFilterType {
  Manual = 'Manual',
  Anchored = 'Anchored',
}

export enum RecipesAdvancedSearchFilterSelectionType {
  Single = 'Single',
  Multiple = 'Multiple',
  Text = 'Text',
}

type RecipesAdvancedSearchFilterOperators = Record<
  RecipesAdvancedSearchKey,
  readonly RecipesAdvancedSearchOperator[]
>;
export const recipesAdvancedSearchFilterOperators: RecipesAdvancedSearchFilterOperators =
  {
    [RecipesAdvancedSearchKey.ApplianceTags]: [
      RecipesAdvancedSearchOperator.OneOrMoreOf,
      /** @todo add NoneOf once {@link https://frescocooks.atlassian.net/browse/CD-90} is done */
    ],
    [RecipesAdvancedSearchKey.GeneralTags]: [
      RecipesAdvancedSearchOperator.AllOf,
      /** @todo add NoneOf once {@link https://frescocooks.atlassian.net/browse/CD-90} is done */
    ],
    [RecipesAdvancedSearchKey.State]: [
      RecipesAdvancedSearchOperator.Is,
      /** @todo add IsNot once {@link https://frescocooks.atlassian.net/browse/CD-90} is done */
    ],
    [RecipesAdvancedSearchKey.Locale]: [
      RecipesAdvancedSearchOperator.AnyOf,
      /** @todo add NoneOf once {@link https://frescocooks.atlassian.net/browse/CD-90} is done */
    ],
    [RecipesAdvancedSearchKey.Author]: [RecipesAdvancedSearchOperator.Contains],
  } as const;

type RecipesAdvancedSearchFilterSelection = Record<
  RecipesAdvancedSearchOperator,
  RecipesAdvancedSearchFilterSelectionType
>;
export const recipesAdvancedSearchFilterSelection: RecipesAdvancedSearchFilterSelection =
  {
    [RecipesAdvancedSearchOperator.AllOf]:
      RecipesAdvancedSearchFilterSelectionType.Multiple,
    [RecipesAdvancedSearchOperator.OneOrMoreOf]:
      RecipesAdvancedSearchFilterSelectionType.Multiple,
    [RecipesAdvancedSearchOperator.AnyOf]:
      RecipesAdvancedSearchFilterSelectionType.Multiple,
    [RecipesAdvancedSearchOperator.NoneOf]:
      RecipesAdvancedSearchFilterSelectionType.Multiple,
    [RecipesAdvancedSearchOperator.Is]:
      RecipesAdvancedSearchFilterSelectionType.Single,
    [RecipesAdvancedSearchOperator.IsNot]:
      RecipesAdvancedSearchFilterSelectionType.Single,
    [RecipesAdvancedSearchOperator.Contains]:
      RecipesAdvancedSearchFilterSelectionType.Text,
  } as const;

export const getRecipesAdvancedSearchFilterDefaultValue = (
  operator: RecipesAdvancedSearchOperator | null
) =>
  operator !== null &&
  recipesAdvancedSearchFilterSelection[operator] ===
    RecipesAdvancedSearchFilterSelectionType.Multiple
    ? []
    : null;

/** Filter Values */
type RecipesAdvancedSearchFilterValues = Record<
  RecipesAdvancedSearchKey,
  {
    requestValues?: (dispatch: AppDispatch, locale?: ApiLocale) => void;
    isLoadingValues: (locale?: ApiLocale) => boolean;
    hasRequestValuesFailed?: (locale?: ApiLocale) => boolean;
    values?: (locale?: ApiLocale) => RecipesAdvancedSearchFilterValue<string>[];
  }
>;
export const recipesAdvancedSearchFilterValues: RecipesAdvancedSearchFilterValues =
  {
    [RecipesAdvancedSearchKey.ApplianceTags]: {
      requestValues: (dispatch: AppDispatch, locale?: ApiLocale) =>
        !!locale && dispatch(tagsFetchRequested({ locale })),
      isLoadingValues: (locale?: ApiLocale): boolean =>
        !!locale && useAppSelector(selectTagsFetching(locale)) !== false,
      hasRequestValuesFailed: (locale?: ApiLocale): boolean =>
        !locale || !!useAppSelector(selectTagsFetchError(locale)),
      values: (
        locale?: ApiLocale
      ): RecipesAdvancedSearchFilterValue<ApiRefId>[] =>
        locale
          ? Object.values(useAppSelector(selectApplianceTags(locale)) ?? {})
          : [],
    },
    [RecipesAdvancedSearchKey.GeneralTags]: {
      requestValues: (dispatch: AppDispatch, locale?: ApiLocale) =>
        locale && dispatch(tagsFetchRequested({ locale })),
      isLoadingValues: (locale?: ApiLocale): boolean =>
        !!locale && useAppSelector(selectTagsFetching(locale)) !== false,
      hasRequestValuesFailed: (locale?: ApiLocale): boolean =>
        !locale || !!useAppSelector(selectTagsFetchError(locale)),
      values: (
        locale?: ApiLocale
      ): RecipesAdvancedSearchFilterValue<ApiRefId>[] =>
        (locale && useAppSelector(selectGeneralTagsDisambiguated(locale))) ??
        [],
    },
    [RecipesAdvancedSearchKey.State]: {
      isLoadingValues: (): boolean => false,
      values: (): RecipesAdvancedSearchFilterValue<ApiRcpRecipeState>[] =>
        recipeStates,
    },
    [RecipesAdvancedSearchKey.Locale]: {
      isLoadingValues: (): boolean => useAppSelector(selectConfigsFetching),
      values: (): RecipesAdvancedSearchFilterValue<ApiLocale>[] => {
        const supportedLocales = useAppSelector(selectConfigsLocales) || [];
        return supportedLocales.map((locale) => ({
          id: locale,
          name: appDisplayCodeByLocale[locale],
        }));
      },
    },
    [RecipesAdvancedSearchKey.Author]: {
      isLoadingValues: (): boolean => false,
    },
  } as const;

export const validateRecipesAdvancedSearchFilter = ({
  key,
  operator,
  value,
}: RecipesAdvancedSearchFilters): RecipesAdvancedSearchFilterErrors => {
  const errors: RecipesAdvancedSearchFilterErrors = {};
  if (key === null) {
    errors.key = true;
  }
  if (operator === null) {
    errors.operator = true;
  }
  if (isEmpty(value)) {
    errors.value = true;
  }
  return errors;
};
