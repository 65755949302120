export const recipeStepParams = {
  stepNumber: 'stepNumber',
} as const;

export const recipeStepsStrings = {
  title: 'Steps',
  buttons: {
    add: 'New Step',
  },
  labels: {
    emptyImg: 'Empty list image',
  },
  messages: {
    empty: 'Bringing it all together...',
  },
  deleteStepDialog: {
    title: `Delete "Step ${recipeStepParams.stepNumber}"`,
    body: 'Are you sure you want to delete this step?',
    confirmButton: 'Yes, delete',
    cancelButton: 'Cancel',
  },
  errors: {
    deleteNoIndex: 'You have to set the index of the step to be deleted first',
  },
  ids: {
    dragZone: 'steps-list',
  },
} as const;
