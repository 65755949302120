import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createAction, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';

import type { ApiAplId } from 'api/types/appliance/apiAplId';
import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { RootState } from 'app/store/rootReducer';
import type { AppCapability } from 'types/appCapability';
import type { AppCapabilityAllowedPhase } from 'types/appCapabilityAllowedPhase';
import type { AppCapabilityAllowedSetting } from 'types/appCapabilityAllowedSetting';
import type { AppCapabilityAllowedSettingValueBoolean } from 'types/appCapabilityAllowedSettingValueBoolean';
import type { AppCapabilityAllowedSettingValueNominal } from 'types/appCapabilityAllowedSettingValueNominal';
import type {
  AppCapabilityAllowedSettingValueNumeric,
  AppCapabilityAllowedSettingValueNumericInSingleSystem,
} from 'types/appCapabilityAllowedSettingValueNumeric';
import type { AppLocalizedData } from 'types/appLocalizedData';
import type { AppLocalizedFetchActionPayload } from 'types/appLocalizedFetchActionPayload';
import type { AppLocalizedFetchActionResult } from 'types/appLocalizedFetchActionResult';
import type { AppLocalizedFetchingActionPayload } from 'types/appLocalizedFetchingActionPayload';
import type { AppAppliance } from 'types/appliance/appAppliance';
import { isValueInSingleSystem } from 'utils/unitSystems';

export type CapabilitiesById = Record<
  ApiEntityId,
  Pick<
    AppCapability,
    'id' | 'name' | 'type' | 'allowedSettings' | 'referenceCapabilityId'
  >
>;

export interface ApplianceWithCapabilities {
  id: ApiAplId;
  name: string;
  referenceTagIds: ApiRefId[];
  supportedCapabilities: CapabilitiesById;
}

export interface AppliancesState {
  fetchError: AppLocalizedData<string>;
  fetching: AppLocalizedData<boolean>;
  appliances: AppLocalizedData<AppAppliance[]>;
  capabilities: AppLocalizedData<AppCapability[]>;
  appliancesWithCapabilities: AppLocalizedData<ApplianceWithCapabilities[]>;
}

export const initialState: AppliancesState = {
  fetchError: {},
  fetching: {},
  appliances: {},
  capabilities: {},
  appliancesWithCapabilities: {},
};

export const appliancesSlice = createSlice({
  name: 'appliancesSlice',
  initialState,
  reducers: {
    appliancesFetching(
      state,
      { payload: { locale } }: PayloadAction<AppLocalizedFetchingActionPayload>
    ) {
      delete state.fetchError[locale];
      state.fetching[locale] = true;
    },
    appliancesFetchSucceed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<AppLocalizedFetchActionResult<AppAppliance[]>>
    ) {
      state.fetching[locale] = false;
      state.appliances[locale] = data;
      state.capabilities[locale] = mergeApplianceCapabilities(data);
      state.appliancesWithCapabilities[locale] =
        getAppliancesWithCapabilities(data);
    },
    appliancesFetchFailed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<AppLocalizedFetchActionResult<string>>
    ) {
      state.fetchError[locale] = data;
      state.fetching[locale] = false;
    },
  },
});

export const appliancesFetchRequested =
  createAction<AppLocalizedFetchActionPayload>(
    'appliancesSlice/appliancesFetchRequested'
  );

export const {
  reducer: appliancesReducer,
  actions: {
    appliancesFetching,
    appliancesFetchSucceed,
    appliancesFetchFailed,
  },
} = appliancesSlice;

const selectAppliancesState = (state: RootState) => state.appliances;

export const selectAppliances = (locale: ApiLocale) =>
  createSelector(selectAppliancesState, ({ appliances }) => appliances[locale]);

export const selectAppliance = (locale: ApiLocale, id: ApiAplId) =>
  createSelector(selectAppliances(locale), (appliances) =>
    appliances?.find((appliance) => appliance.id === id)
  );

export const selectAppliancesFetching = (locale: ApiLocale) =>
  createSelector(selectAppliancesState, ({ fetching }) => fetching[locale]);

export const selectAppliancesFetchError = (locale: ApiLocale) =>
  createSelector(selectAppliancesState, ({ fetchError }) => fetchError[locale]);

export const selectShouldFetchAppliances = (locale: ApiLocale) =>
  createSelector(
    selectAppliancesFetching(locale),
    selectAppliances(locale),
    (fetching, appliances) => !fetching && appliances === undefined
  );

export const selectApplianceCapabilities = (locale: ApiLocale) =>
  createSelector(
    selectAppliancesState,
    ({ capabilities }) => capabilities[locale]
  );

export const selectAppliancesWithCapabilities = (locale: ApiLocale) =>
  createSelector(
    selectAppliancesState,
    ({ appliancesWithCapabilities }) => appliancesWithCapabilities[locale]
  );

export const mergeApplianceCapabilities = (
  appliances: AppAppliance[]
): AppCapability[] => {
  const mergedCapabilities = appliances
    .reduce(
      (capabilities, { applianceModules }) => [
        ...capabilities,
        ...applianceModules.flatMap((module) => module.capabilities),
      ],
      [] as AppCapability[]
    )
    .reduce(
      (capabilities, capability) => {
        if (!capabilities[capability.id]) {
          capabilities[capability.id] = cloneDeep(capability);
          return capabilities;
        }
        capabilities[capability.id].allowedSettings = mergeCapabilitySettings([
          ...capabilities[capability.id].allowedSettings,
          ...capability.allowedSettings,
        ]);
        capabilities[capability.id].allowedPhases = mergeCapabilityPhases([
          ...capabilities[capability.id].allowedPhases,
          ...capability.allowedPhases,
        ]);
        return capabilities;
      },
      {} as Record<ApiEntityId, AppCapability>
    );

  return Object.values(mergedCapabilities);
};

export const mergeCapabilityPhases = (
  phases: AppCapabilityAllowedPhase[]
): AppCapabilityAllowedPhase[] => {
  const key: keyof AppCapabilityAllowedPhase = 'id';
  return uniqBy(phases, key);
};

export const mergeCapabilitySettings = (
  settings: AppCapabilityAllowedSetting[]
): AppCapabilityAllowedSetting[] => {
  const mergedSettings = settings.reduce(
    (settingsById, setting) => {
      if (!settingsById[setting.id]) {
        settingsById[setting.id] = cloneDeep(setting);
        return settingsById;
      }
      const {
        allowedValues: { nominal, numeric, boolean },
        defaultValue,
        dependsOnSetting,
      } = setting;
      if (nominal) {
        const key: keyof AppCapabilityAllowedSettingValueNominal = 'id';
        settingsById[setting.id].allowedValues.nominal = uniqBy(
          [
            ...(settingsById[setting.id].allowedValues.nominal || []),
            ...nominal,
          ],
          key
        );
      }
      if (boolean) {
        const key: keyof AppCapabilityAllowedSettingValueBoolean = 'value';
        settingsById[setting.id].allowedValues.boolean = uniqBy(
          [
            ...(settingsById[setting.id].allowedValues.boolean || []),
            ...boolean,
          ],
          key
        );
      }
      if (numeric) {
        settingsById[setting.id].allowedValues.numeric =
          mergeNumericSettingValues([
            ...(settingsById[setting.id].allowedValues.numeric || []),
            ...numeric,
          ]);
      }
      /**
       * Pick the default value from the first appliance that has one.
       * If multiple appliances have default values, consider only the first one for now.
       */
      if (!settingsById[setting.id].defaultValue && defaultValue) {
        settingsById[setting.id].defaultValue = cloneDeep(defaultValue);
      }
      /**
       * Pick the settings dependencies from the first appliance that have them.
       * If multiple appliances have settings dependencies, do not consider any of them here.
       */
      if (dependsOnSetting) {
        settingsById[setting.id].dependsOnSetting = settingsById[setting.id]
          .dependsOnSetting
          ? undefined
          : dependsOnSetting;
      }
      return settingsById;
    },
    {} as Record<ApiEntityId, AppCapabilityAllowedSetting>
  );
  return Object.values(mergedSettings);
};

export const mergeNumericSettingValues = (
  values: AppCapabilityAllowedSettingValueNumeric[]
): AppCapabilityAllowedSettingValueNumeric[] => {
  const mergedValues = values.reduce(
    (valuesByUnit, value) => {
      if (isValueInSingleSystem(value)) {
        const current = valuesByUnit[value.unit.id] as
          | AppCapabilityAllowedSettingValueNumericInSingleSystem
          | undefined;
        valuesByUnit[value.unit.id] = mergeNumericSettingValueInSingleSystem(
          current,
          value
        );
        return valuesByUnit;
      }
      const currentMetric = valuesByUnit[value.metric.unit.id] as
        | AppCapabilityAllowedSettingValueNumericInSingleSystem
        | undefined;
      const currentUsCustomary = valuesByUnit[value.usCustomary.unit.id] as
        | AppCapabilityAllowedSettingValueNumericInSingleSystem
        | undefined;
      valuesByUnit[value.metric.unit.id] =
        mergeNumericSettingValueInSingleSystem(currentMetric, value.metric);
      valuesByUnit[value.usCustomary.unit.id] =
        mergeNumericSettingValueInSingleSystem(
          currentUsCustomary,
          value.usCustomary
        );
      return valuesByUnit;
    },
    {} as Record<ApiRefId, AppCapabilityAllowedSettingValueNumeric>
  );
  return Object.values(mergedValues);
};

const mergeNumericSettingValueInSingleSystem = (
  current: AppCapabilityAllowedSettingValueNumericInSingleSystem | undefined,
  value: AppCapabilityAllowedSettingValueNumericInSingleSystem
): AppCapabilityAllowedSettingValueNumericInSingleSystem => {
  if (!current) {
    return cloneDeep(value);
  }
  let { min, max, step } = current;
  if (value.min === undefined || (min !== undefined && min > value.min)) {
    min = value.min;
  }
  if (value.max === undefined || (max !== undefined && max < value.max)) {
    max = value.max;
  }
  if (value.step === undefined || (step !== undefined && step > value.step)) {
    step = value.step;
  }
  return { min, max, step, unit: current.unit };
};

export const getAppliancesWithCapabilities = (appliances: AppAppliance[]) => {
  const appliancesWithCapabilities: ApplianceWithCapabilities[] = [];
  for (const appliance of appliances) {
    const { id: applianceId, applianceModules } = appliance;
    const applianceData: ApplianceWithCapabilities = {
      id: applianceId,
      name: appliance.name,
      referenceTagIds: appliance.referenceTagIds,
      supportedCapabilities: {},
    };

    for (const module of applianceModules) {
      const { capabilities } = module;
      for (const capability of capabilities) {
        const { id: capabilityId } = capability;
        applianceData.supportedCapabilities[capabilityId] = {
          ...pick<AppCapability, keyof AppCapability>(capability, [
            'id',
            'name',
            'type',
            'allowedSettings',
            'referenceCapabilityId',
          ]),
        };
      }
    }
    appliancesWithCapabilities.push(applianceData);
  }
  return appliancesWithCapabilities;
};
