import { Grid } from '@mui/material';

import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { RecipeBasicInformationForm } from 'features/recipe/recipeBasicInformation/RecipeBasicInformationForm';
import { RecipeBasicInformationFormSkeleton } from 'features/recipe/recipeBasicInformation/RecipeBasicInformationFormSkeleton';
import { RecipeMedia } from 'features/recipe/recipeBasicInformation/recipeMedia/RecipeMedia';
import { RecipeMediaSkeleton } from 'features/recipe/recipeBasicInformation/recipeMedia/RecipeMediaSkeleton';
import {
  recipeHasChanged,
  selectRecipe,
  selectRecipeFetching,
} from 'features/recipe/recipeSlice';

export function RecipeBasicInformation() {
  const dispatch = useAppDispatch();

  const recipe = useAppSelector(selectRecipe);
  const isFetching = useAppSelector(selectRecipeFetching);

  const isPublished = recipe.state === ApiRcpRecipeState.Published;

  const handleMediaPreviewClick = () => {
    dispatch(recipeHasChanged(true));
  };

  return (
    <Grid container spacing={30}>
      <Grid item md={5}>
        {isFetching ? (
          <RecipeBasicInformationFormSkeleton />
        ) : (
          <RecipeBasicInformationForm />
        )}
      </Grid>
      <Grid item md={7}>
        {isFetching ? (
          <RecipeMediaSkeleton />
        ) : (
          <RecipeMedia
            disabled={isPublished}
            onMediaPreviewClick={
              isPublished ? handleMediaPreviewClick : undefined
            }
          />
        )}
      </Grid>
    </Grid>
  );
}
