/* istanbul ignore file */
import type { ApiOrganizationId } from 'api/types/common/apiOrganizationId';

import appPackageJson from '../../package.json';

export enum AppEnvironment {
  Development = 'development',
  Production = 'production',
  Stage = 'stage',
  Test = 'test',
  E2E = 'e2e',
}

const packageJsonValues = {
  version: (): string => appPackageJson.version,
  appName: (): string => appPackageJson.name,
};

const envVarsGetters = {
  /**
   * Environment where the application is running.
   */
  environment: (): AppEnvironment =>
    process.env.REACT_APP_ENV as AppEnvironment,
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  /**
   * KitchenOS API gateway url
   */
  apiGatewayUrl: (): string => process.env.REACT_APP_API_URL!,
  /**
   * KitchenOS API media url
   */
  apiMediaUrl: (): string => process.env.REACT_APP_API_MEDIA_URL!,
  /**
   * Appliances config url
   */
  configsUrl: (): string => process.env.REACT_APP_CONFIGS_URL!,
  /**
   * Amazon Cognito Region
   */
  region: (): string => process.env.REACT_APP_COGNITO_REGION!,
  /**
   * Amazon Cognito User Pool ID
   */
  userPoolId: (): string => process.env.REACT_APP_COGNITO_USER_POOL_ID!,
  /**
   * Amazon Cognito Web Client ID
   */
  userPoolClientId: (): string =>
    process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID!,
  /**
   * Amazon Cognito Web Client ID
   */
  organizationId: () =>
    process.env.REACT_APP_ORGANIZATION_ID as ApiOrganizationId,
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
};

const isEnv = (...args: AppEnvironment[]) => {
  const environment = envVarsGetters.environment();
  return args.includes(environment);
};

const isDevEnv = () => isEnv(AppEnvironment.Development);
const isUnitTestEnv = () => isEnv(AppEnvironment.Test);
const isE2ETestEnv = () => isEnv(AppEnvironment.E2E);
const isProdEnv = () => isEnv(AppEnvironment.Production);
const isDevOrTestEnv = () => isDevEnv() || isUnitTestEnv() || isE2ETestEnv();

const referenceDataApiPageSize = () => 500;

export const appConfig = {
  ...envVarsGetters,
  ...packageJsonValues,
  isEnv,
  isDevEnv,
  isUnitTestEnv,
  isE2ETestEnv,
  isProdEnv,
  isDevOrTestEnv,
  referenceDataApiPageSize,
};
