import type { ApiAplAppliance } from 'api/types/appliance/apiAplAppliance';
import type { ApiAplCapabilityType } from 'api/types/appliance/apiAplCapabilityType';
import type { ApiAplId } from 'api/types/appliance/apiAplId';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { AppApplianceModule } from 'types/appliance/appApplianceModule';
import { fromApiAplApplianceModule } from 'types/appliance/appApplianceModule';

export interface AppAppliance {
  id: ApiAplId;
  name: string;
  applianceModules: AppApplianceModule[];
  referenceTagIds: ApiRefId[];
}

export const fromApiAplAppliance = (
  appliance: ApiAplAppliance,
  supportedApplianceCapabilityTypes: ApiAplCapabilityType[]
): AppAppliance => ({
  ...appliance,
  applianceModules: appliance.applianceModules.map((module) =>
    fromApiAplApplianceModule(module, supportedApplianceCapabilityTypes)
  ),
});
