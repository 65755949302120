import {
  AddCircleIcon,
  BaselinePostAddIcon,
  Button,
  ButtonSize,
  ButtonStyle,
  WebIcon,
  PantryColor,
  RoundTextFieldsIcon,
} from '@dropkitchen/pantry-react';
import { Menu } from '@mui/material';
import { memo, useState } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { generateRecipeRoute } from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { RecipeFromScratchDialog } from 'features/RecipeFromScratchDialog/RecipeFromScratchDialog';
import { RecipeFromTextDialog } from 'features/RecipeFromTextDialog/RecipeFromTextDialog';
import { RecipeFromUrlDialog } from 'features/RecipeFromUrlDialog/RecipeFromUrlDialog';
import {
  selectConfigsIsFeatureEnabled,
  selectConfigsLocales,
} from 'features/configs/configsSlice';
import { errorOccurred } from 'features/error/errorSlice';
import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import { recipeFromScratchRequested } from 'features/recipe/recipeSlice';
import { recipesPageStrings } from 'features/recipes/RecipesPage.constants';
import { addRecipeStrings } from 'features/recipes/addRecipe/AddRecipe.constants';
import type { AddRecipeMenuItemProps } from 'features/recipes/addRecipe/AddRecipeMenuItem';
import { AddRecipeMenuItem } from 'features/recipes/addRecipe/AddRecipeMenuItem';
import { AppFeature } from 'types/appFeature';

const { buttons } = recipesPageStrings;
const { errors } = addRecipeStrings;

interface AddRecipeMenuItemOptions
  extends Omit<AddRecipeMenuItemProps, 'isFirst'> {
  key: string;
}

export const AddRecipe: FC = memo(function AddRecipe() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isTranslationManagementEnabled = useAppSelector(
    selectConfigsIsFeatureEnabled(AppFeature.TranslationManagement)
  );
  const supportedLocales = useAppSelector(selectConfigsLocales);
  const defaultLocale = supportedLocales?.[0] ?? null;

  const [addRecipeMenuAnchorEl, setAddRecipeMenuAnchorEl] =
    useState<null | Element>(null);
  const [isRecipeFromTextDialogOpen, setIsRecipeFromTextDialogOpen] =
    useState<boolean>(false);

  const [isRecipeFromUrlDialogOpen, setIsRecipeFromUrlDialogOpen] =
    useState<boolean>(false);

  const [isRecipeFromScratchDialogOpen, setIsRecipeFromScratchDialogOpen] =
    useState<boolean>(false);

  const handleAddRecipeMenuClose = () => {
    setAddRecipeMenuAnchorEl(null);
  };

  const handleFromScratchOnClick = () => {
    if (isTranslationManagementEnabled) {
      setIsRecipeFromScratchDialogOpen(true);
    } else {
      if (!defaultLocale) {
        dispatch(errorOccurred({ message: errors.noLocaleSelected }));
        return;
      }
      dispatch(recipeFromScratchRequested(defaultLocale));
      navigate(generateRecipeRoute({ tab: RecipeTabName.Information }));
    }
  };

  const addRecipeMenuOptions: AddRecipeMenuItemOptions[] = [
    {
      ...buttons.createFromScratch,
      icon: <BaselinePostAddIcon size={24} />,
      key: 'from-scratch',
      onClick: handleFromScratchOnClick,
    },
    {
      ...buttons.createFromText,
      icon: <RoundTextFieldsIcon size={24} />,
      key: 'from-text',
      onClick: () => setIsRecipeFromTextDialogOpen(true),
    },
  ];

  return (
    <>
      <Button
        label={buttons.add}
        buttonStyle={ButtonStyle.Default}
        size={ButtonSize.Large}
        onClick={(e) => setAddRecipeMenuAnchorEl(e.currentTarget)}
        leadingIcon={AddCircleIcon}
      />
      <Menu
        open={!!addRecipeMenuAnchorEl}
        anchorEl={addRecipeMenuAnchorEl}
        onClose={handleAddRecipeMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: 2, p: 0 }}
        MenuListProps={{
          sx: {
            backgroundColor: PantryColor.SurfaceSubtle,
            p: 4,
            width: '472px',
          },
        }}
      >
        {addRecipeMenuOptions.map((item, index) => (
          <AddRecipeMenuItem
            key={item.key}
            icon={item.icon}
            title={item.title}
            description={item.description}
            onClick={() => {
              handleAddRecipeMenuClose();
              item.onClick();
            }}
            isFirst={index === 0}
          />
        ))}
        <ToggleableFeature
          requires={AppFeature.CreateRecipeFromUrl}
          components={{ whenDisabled: null }}
        >
          <AddRecipeMenuItem
            key="from-url"
            icon={<WebIcon size={24} />}
            title={buttons.createFromUrl.title}
            description={buttons.createFromUrl.description}
            onClick={() => {
              handleAddRecipeMenuClose();
              setIsRecipeFromUrlDialogOpen(true);
            }}
            isFirst={false}
          />
        </ToggleableFeature>
      </Menu>
      <RecipeFromTextDialog
        isOpen={isRecipeFromTextDialogOpen}
        onClose={() => setIsRecipeFromTextDialogOpen(false)}
      />
      <RecipeFromUrlDialog
        isOpen={isRecipeFromUrlDialogOpen}
        onClose={() => setIsRecipeFromUrlDialogOpen(false)}
      />
      <RecipeFromScratchDialog
        isOpen={isRecipeFromScratchDialogOpen}
        onClose={() => setIsRecipeFromScratchDialogOpen(false)}
      />
    </>
  );
});
