export enum AppCapabilitySettingType {
  Boolean = 'boolean',
  Nominal = 'nominal',
  Numeric = 'numeric',
  Time = 'time',
}

export type AppCapabilitySettingTypes =
  | AppCapabilitySettingType.Boolean
  | AppCapabilitySettingType.Numeric
  | AppCapabilitySettingType.Nominal
  | AppCapabilitySettingType.Time;
