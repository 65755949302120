import { CheckIcon, Toast } from '@dropkitchen/pantry-react';
import { memo, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { toastsConstants } from 'features/toasts/Toasts.constants';
import {
  selectToastsCurrent,
  ToastDataType,
  toastDismissed,
  type ToastData,
} from 'features/toasts/toastsSlice';

export const Toasts = memo(function Toasts() {
  const dispatch = useAppDispatch();

  const [currentToast, setCurrentToast] = useState<ToastData>();
  const [currentKey, setCurrentKey] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);

  const enqueued = useAppSelector(selectToastsCurrent);

  useEffect(() => {
    // Close previous toast when new one is enqueued
    setIsOpen(false);
  }, [enqueued]);

  useEffect(() => {
    if (!isOpen && enqueued) {
      setCurrentToast(enqueued);
      // Assign new key to every toast to ensure all transitions are played properly
      setCurrentKey(Date.now());
      setIsOpen(true);
    }
  }, [enqueued, isOpen]);

  const handleClose = () => {
    dispatch(toastDismissed());
  };

  if (!currentToast) {
    return null;
  }

  const getIcon = () => {
    switch (currentToast.type) {
      case ToastDataType.Success:
        return CheckIcon;
      default:
        return undefined;
    }
  };

  return (
    <Toast
      label={currentToast.label}
      open={isOpen}
      onClose={handleClose}
      toastKey={currentKey?.toString()}
      sx={{
        bottom: { xs: toastsConstants.offset.default },
      }}
      icon={getIcon()}
    />
  );
});
