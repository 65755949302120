import type { FC } from 'react';
import { memo } from 'react';

import { RecipeReviewListContainer } from 'features/recipe/review/shared/RecipeReviewListContainer/RecipeReviewListContainer';
import { RecipePageListSkeleton } from 'features/recipe/shared/RecipePageListSkeleton/RecipePageListSkeleton';

export interface RecipeReviewListSkeletonProps {
  title: string;
}

export const RecipeReviewListSkeleton: FC<RecipeReviewListSkeletonProps> = memo(
  function RecipeReviewListSkeleton({ title }) {
    return (
      <RecipeReviewListContainer
        components={{
          title,
          list: <RecipePageListSkeleton title={title} />,
        }}
      />
    );
  }
);
