import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import { routes } from 'app/routes/routes';
import { AppStoreProvider } from 'app/store/store';
import { theme } from 'app/theme';
import { ConfigLoaderWrapper } from 'components/ConfigLoaderWrapper/ConfigLoaderWrapper';
import { appConfig } from 'config/config';
import { AuthWrapper } from 'features/auth/AuthWrapper';
import { ErrorDialog } from 'features/error/ErrorDialog';
import { Toasts } from 'features/toasts/Toasts';

const appRouter = createBrowserRouter([
  {
    element: (
      <ThemeProvider theme={theme}>
        <AppStoreProvider>
          <CssBaseline />
          <AuthWrapper>
            <ConfigLoaderWrapper>
              <Outlet />
            </ConfigLoaderWrapper>
          </AuthWrapper>
          <ErrorDialog />
          <Toasts />
        </AppStoreProvider>
      </ThemeProvider>
    ),
    children: routes,
  },
]);

export function App() {
  useEffect(() => {
    initDataDog();
  }, []);

  return <RouterProvider router={appRouter} />;
}

const initDataDog = () => {
  if (!appConfig.isDevOrTestEnv()) {
    datadogRum.init({
      applicationId: '1c5b5346-8191-48e6-bbf6-567b4d9e44b2',
      clientToken: 'pub9edd7885705f3ad9d4ba9b52ecd3edd9',
      env: appConfig.environment(),
      version: appConfig.version(),
      site: 'datadoghq.com',
      service: appConfig.appName(),
      defaultPrivacyLevel: 'allow',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      allowedTracingUrls: [appConfig.apiGatewayUrl(), appConfig.apiMediaUrl()],
    });

    datadogRum.startSessionReplayRecording();
    datadogRum.setGlobalContextProperty(
      'organization',
      appConfig.organizationId()
    );

    datadogLogs.onReady(() => {
      datadogLogs.init({
        clientToken: 'pub9b2e64827d1d6c6751f88b275167e082',
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        service: appConfig.appName(),
        silentMultipleInit: true,
        env: appConfig.environment(),
      });
    });
    datadogLogs.setGlobalContextProperty(
      'organization',
      appConfig.organizationId()
    );
  }
};
