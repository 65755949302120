import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { RecipesAdvancedSearchFilterType } from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.constants';
import { RecipesAdvancedSearchKey } from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.constants';
import type {
  RecipesAdvancedSearchAuthorFilter,
  RecipesAdvancedSearchFilters,
  RecipesAdvancedSearchLocaleFilter,
  RecipesAdvancedSearchStateFilter,
} from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.types';

export const recipeSearchUtils = {
  getTags: (filters: RecipesAdvancedSearchFilters[]): ApiRefId[] => {
    return filters.reduce((ids, { key, value }) => {
      if (
        key !== RecipesAdvancedSearchKey.ApplianceTags &&
        key !== RecipesAdvancedSearchKey.GeneralTags
      ) {
        return ids;
      }
      if (value === null || !value.length) {
        return ids;
      }
      return [...ids, ...value.map(({ id }) => id)];
    }, [] as ApiRefId[]);
  },
  getLocales: (
    filters: RecipesAdvancedSearchFilters[]
  ): ApiLocale[] | undefined => {
    const locales = filters.find(
      (
        filter
      ): filter is RecipesAdvancedSearchLocaleFilter<
        | RecipesAdvancedSearchFilterType.Manual
        | RecipesAdvancedSearchFilterType.Anchored
      > => filter.key === RecipesAdvancedSearchKey.Locale
    )?.value;

    return locales?.map(({ id }) => id);
  },
  getState: (
    filters: RecipesAdvancedSearchFilters[]
  ): ApiRcpRecipeState | undefined => {
    const state = filters.find(
      (
        filter
      ): filter is RecipesAdvancedSearchStateFilter<
        | RecipesAdvancedSearchFilterType.Manual
        | RecipesAdvancedSearchFilterType.Anchored
      > =>
        filter.key === RecipesAdvancedSearchKey.State && filter.value !== null
    )?.value;
    return state?.id;
  },
  getAuthor: (filters: RecipesAdvancedSearchFilters[]): string | undefined => {
    const author = filters.find(
      (
        filter
      ): filter is RecipesAdvancedSearchAuthorFilter<
        | RecipesAdvancedSearchFilterType.Manual
        | RecipesAdvancedSearchFilterType.Anchored
      > => filter.key === RecipesAdvancedSearchKey.Author
    )?.value;
    return author ?? undefined;
  },
} as const;
