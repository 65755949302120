import { Button, ButtonSize, ButtonStyle } from '@dropkitchen/pantry-react';
import { Grid, TextField } from '@mui/material';
import type { FC, SyntheticEvent } from 'react';
import { useEffect, memo, useState } from 'react';
import { string } from 'yup';

import type { ApiLocale } from 'api/types/common/apiLocale';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { Dialog } from 'components/Dialog/Dialog';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { LocaleField } from 'components/LocaleField/LocaleField';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import {
  recipeFromTextDialogConstants,
  recipeFromTextDialogStrings,
} from 'features/RecipeFromTextDialog/RecipeFromTextDialog.constants';
import { RecipeFromTextDismissalDialog } from 'features/RecipeFromTextDialog/RecipeFromTextDismissalDialog';
import { selectConfigsLocales } from 'features/configs/configsSlice';
import { recipePageStrings } from 'features/recipe/RecipePage.constants';
import {
  recipeGetFromTextRequested,
  selectRecipeFetching,
} from 'features/recipe/recipeSlice';
import { AppFeature } from 'types/appFeature';
import { Validator } from 'utils/validator';

export type RecipeFromTextDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const { titles, labels, helpers, buttons } = recipeFromTextDialogStrings;
const { defaults } = recipeFromTextDialogConstants;

const formValidator = new Validator({
  locale: string().required(),
  name: string().required(),
  ingredients: string().required(),
  steps: string().required(),
});

export const RecipeFromTextDialog: FC<RecipeFromTextDialogProps> = memo(
  function RecipeFromTextDialog({ isOpen, onClose }) {
    const dispatch = useAppDispatch();

    const isFetching = useAppSelector(selectRecipeFetching);
    const supportedLocales = useAppSelector(selectConfigsLocales);

    const defaultLocale = supportedLocales?.[0] ?? null;
    const [locale, setLocale] = useState<ApiLocale | null>(defaultLocale);
    const [name, setName] = useState<string>(defaults.name);
    const [description, setDescription] = useState<string>(
      defaults.description
    );
    const [ingredients, setIngredients] = useState<string>(
      defaults.ingredients
    );
    const [steps, setSteps] = useState<string>(defaults.steps);
    const [isFormValid, setIsFormValid] = useState<boolean>(
      defaults.isFormValid
    );
    const [isMainDialogOpen, setIsMainDialogOpen] = useState<boolean>(isOpen);
    const [isDismissalDialogOpen, setIsDismissalDialogOpen] =
      useState<boolean>(false);

    useEffect(() => {
      setIsMainDialogOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
      setLocale(defaultLocale);
    }, [defaultLocale, supportedLocales]);

    const resetForm = () => {
      setName(defaults.name);
      setDescription(defaults.description);
      setIngredients(defaults.ingredients);
      setSteps(defaults.steps);
      setLocale(defaultLocale);
      setIsFormValid(defaults.isFormValid);
    };

    const handleClose = () => {
      if (!!name || !!description || !!ingredients || !!steps) {
        setIsMainDialogOpen(false);
        setIsDismissalDialogOpen(true);
      } else {
        handleCloseConfirmation();
      }
    };

    const handleCloseConfirmation = () => {
      resetForm();
      setIsDismissalDialogOpen(false);
      onClose();
    };

    const convertToArray = (value: string) =>
      value
        .split('\n')
        .map((item) => item.trim())
        .filter((item) => !!item);

    const updateFormValidity = (
      value: string,
      field: 'locale' | 'name' | 'ingredients' | 'steps'
    ): void => {
      const getRawValue = (item: string) => convertToArray(item).join('');
      setIsFormValid(
        !formValidator.validate({
          locale,
          name: getRawValue(name),
          ingredients: getRawValue(ingredients),
          steps: getRawValue(steps),
          [field]: getRawValue(value),
        })
      );
    };

    const handleSubmit = (e: SyntheticEvent) => {
      if (!locale) {
        // This shouldn't happen as the button is disabled if there isn't a locale
        return;
      }

      if (e.type === 'click') {
        dispatch(
          recipeGetFromTextRequested({
            locale,
            name: name.trim(),
            description: description.trim(),
            ingredients: convertToArray(ingredients),
            steps: convertToArray(steps),
          })
        );
      }
    };

    return (
      <>
        <Dialog
          title={titles.createFromText}
          isOpen={isMainDialogOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
          size="sm"
          components={{
            content: (
              <Grid container spacing={6}>
                <ToggleableFeature
                  requires={AppFeature.TranslationManagement}
                  components={{ whenDisabled: null }}
                >
                  <Grid item xs={12}>
                    <LocaleField
                      label={recipePageStrings.labels.localeField.label}
                      placeholder={
                        recipePageStrings.labels.localeField.placeholder
                      }
                      hint={recipePageStrings.labels.localeField.hint}
                      onChange={(currentLocale) => {
                        setLocale(currentLocale);
                        updateFormValidity(currentLocale ?? '', 'locale');
                      }}
                      value={locale}
                      autoFocus
                    />
                  </Grid>
                </ToggleableFeature>
                <Grid item xs={12}>
                  <TextField
                    value={name}
                    name="name"
                    InputLabelProps={{ shrink: true }}
                    required
                    onChange={({ target: { value: currentName } }) => {
                      setName(currentName);
                      updateFormValidity(currentName, 'name');
                    }}
                    label={labels.name}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={description}
                    name="description"
                    multiline
                    rows={4}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ sx: { lineHeight: '18px' } }}
                    onChange={(event) => setDescription(event.target.value)}
                    label={labels.description}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={ingredients}
                    name="ingredients"
                    multiline
                    required
                    rows={4}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ sx: { lineHeight: '18px' } }}
                    onChange={({ target: { value: currentIngredients } }) => {
                      setIngredients(currentIngredients);
                      updateFormValidity(currentIngredients, 'ingredients');
                    }}
                    label={labels.ingredients}
                    helperText={
                      <HelperText
                        message={helpers.ingredients}
                        severity={HelperTextSeverity.Neutral}
                      />
                    }
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={steps}
                    name="steps"
                    multiline
                    required
                    rows={4}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ sx: { lineHeight: '18px' } }}
                    onChange={({ target: { value: currentSteps } }) => {
                      setSteps(currentSteps);
                      updateFormValidity(currentSteps, 'steps');
                    }}
                    label={labels.steps}
                    helperText={
                      <HelperText
                        message={helpers.steps}
                        severity={HelperTextSeverity.Neutral}
                      />
                    }
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            ),
            buttons: (
              <>
                <Button
                  label={buttons.cancel}
                  buttonStyle={ButtonStyle.Default}
                  size={ButtonSize.Large}
                  onClick={handleClose}
                />
                <Button
                  label={buttons.continue}
                  buttonStyle={ButtonStyle.Emphasis}
                  size={ButtonSize.Large}
                  loading={isFetching}
                  disabled={!isFormValid}
                  onClick={(e) => handleSubmit(e)}
                />
              </>
            ),
          }}
        />
        <RecipeFromTextDismissalDialog
          isOpen={isDismissalDialogOpen}
          onClose={() => {
            setIsDismissalDialogOpen(false);
            setIsMainDialogOpen(true);
          }}
          onSubmit={handleCloseConfirmation}
        />
      </>
    );
  }
);
