import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiTag } from 'api/types/common/apiTag';
import type { ApiCcCuratedCollectionRecipe } from 'api/types/curatedCollection/apiCcCuratedCollectionRecipe';
import { ApiCcCuratedCollectionState } from 'api/types/curatedCollection/apiCcCuratedCollectionState';
import type { RootState } from 'app/store/rootReducer';
import type { AppCollectionErrors } from 'features/homeFeed/HomeFeedCollectionPage/homeFeedCollectionValidator';
import { collectionValidator } from 'features/homeFeed/HomeFeedCollectionPage/homeFeedCollectionValidator';
import type { AppCcCuratedCollection } from 'types/curatedCollection/appCcCuratedCollection';

export interface HomeFeedCollectionState {
  collection?: AppCcCuratedCollection;
  apiError?: string;
  isSaving: boolean;
  isFetching: boolean;
  isSubmitted: boolean;
  collectionErrors: AppCollectionErrors;
}

export const initialState: HomeFeedCollectionState = {
  isSaving: false,
  isFetching: false,
  isSubmitted: false,
  collectionErrors: collectionValidator.validate({}) ?? {},
};

export enum HomeFeedCollectionFieldsToValidate {
  Title = 'title',
  Subtitle = 'subtitle',
}

export const homeFeedCollectionSlice = createSlice({
  name: 'homeFeedCollectionSlice',
  initialState,
  reducers: {
    homeFeedCollectionCreationRequested(
      state,
      {
        payload: { locale, applianceTags },
      }: PayloadAction<HomeFeedCollectionCreationRequestedPayload>
    ) {
      // Clean up the previous state and set the right locale and appliance tags
      state.collection = {
        title: '',
        subtitle: '',
        state: ApiCcCuratedCollectionState.Draft,
        recipes: [],
        locale,
        applianceTags,
      };
    },
    homeFeedCollectionTitleChanged(
      state,
      { payload: { title } }: PayloadAction<{ title: string }>
    ) {
      checkErrors(state, {
        field: HomeFeedCollectionFieldsToValidate.Title,
        value: title,
      });
      state.collection!.title = title;
    },
    homeFeedCollectionSubtitleChanged(
      state,
      { payload: { subtitle } }: PayloadAction<{ subtitle: string }>
    ) {
      checkErrors(state, {
        field: HomeFeedCollectionFieldsToValidate.Subtitle,
        value: subtitle,
      });
      state.collection!.subtitle = subtitle;
    },
    homeFeedCollectionRecipesChanged(
      state,
      {
        payload: { recipes },
      }: PayloadAction<{ recipes: ApiCcCuratedCollectionRecipe[] }>
    ) {
      state.collection!.recipes = recipes;
    },
    homeFeedCollectionStateChanged(
      state,
      { payload }: PayloadAction<ApiCcCuratedCollectionState>
    ) {
      state.collection!.state = payload;
    },
    homeFeedCollectionSaveRequested(
      state,
      _action: PayloadAction<{ collectionId?: string }>
    ) {
      state.apiError = undefined;
      state.isSaving = true;
      state.isSubmitted = true;
    },
    homeFeedCollectionSaveSucceed(
      state,
      {
        payload: { etag, modifiedAt },
      }: PayloadAction<Pick<AppCcCuratedCollection, 'etag' | 'modifiedAt'>>
    ) {
      state.collection!.etag = etag;
      state.collection!.modifiedAt = modifiedAt;
      state.isSaving = false;
    },
    homeFeedCollectionSaveFailed(state, { payload }: PayloadAction<string>) {
      state.apiError = payload;
      state.isSaving = false;
    },
    homeFeedCollectionFetchRequested(state, _: PayloadAction<string>) {
      state.isFetching = true;
      state.apiError = undefined;
      state.isSubmitted = false;
    },
    homeFeedCollectionFetchSucceed(
      state,
      { payload }: PayloadAction<AppCcCuratedCollection>
    ) {
      state.isFetching = false;
      state.collection = payload;
      state.collectionErrors =
        collectionValidator.validate({ ...payload }) ?? {};
    },
    homeFeedCollectionFetchFailed(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.apiError = payload;
    },
  },
});

export interface HomeFeedCollectionCreationRequestedPayload {
  locale: ApiLocale;
  applianceTags: ApiTag[];
}

export const {
  reducer: homeFeedCollectionReducer,
  actions: {
    homeFeedCollectionCreationRequested,
    homeFeedCollectionTitleChanged,
    homeFeedCollectionSubtitleChanged,
    homeFeedCollectionRecipesChanged,
    homeFeedCollectionStateChanged,
    homeFeedCollectionSaveRequested,
    homeFeedCollectionSaveSucceed,
    homeFeedCollectionSaveFailed,
    homeFeedCollectionFetchRequested,
    homeFeedCollectionFetchSucceed,
    homeFeedCollectionFetchFailed,
  },
} = homeFeedCollectionSlice;

const selectHomeFeedCollectionState = (state: RootState) =>
  state.homeFeedCollection;

export const selectHomeFeedCollection = (state: RootState) =>
  selectHomeFeedCollectionState(state).collection;

export const selectHomeFeedCollectionId = (state: RootState) =>
  selectHomeFeedCollection(state)?.id;

export const selectIsHomeFeedCollectionSaving = (state: RootState) =>
  selectHomeFeedCollectionState(state).isSaving;

export const selectIsHomeFeedCollectionFetching = (state: RootState) =>
  selectHomeFeedCollectionState(state).isFetching;

export const selectIsHomeFeedCollectionSubmitted = (state: RootState) =>
  selectHomeFeedCollectionState(state).isSubmitted;

export const selectHomeFeedCollectionErrors = (state: RootState) =>
  selectHomeFeedCollectionState(state).collectionErrors;

export const selectHasFeedCollectionErrors = (state: RootState) => {
  const errors = selectHomeFeedCollectionErrors(state);
  return Object.values(errors).length > 0;
};

const checkErrors = (
  state: HomeFeedCollectionState,
  {
    field,
    value,
  }: {
    field: HomeFeedCollectionFieldsToValidate;
    value: string;
  }
) => {
  const errors = collectionValidator.validateField(field, value);
  if (errors) {
    state.collectionErrors[field] = errors;
  } else {
    delete state.collectionErrors[field];
  }
};
