import { datadogRum } from '@datadog/browser-rum';
import type { PayloadAction } from '@reduxjs/toolkit';
import { put, call, select, takeEvery, all } from 'redux-saga/effects';

import { apiGetAppliance, apiGetAppliances } from 'api/appliances';
import type { ApiRequestSagaReturnType } from 'api/createApiRequestSaga';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
import type { ApiAplCapabilityType } from 'api/types/appliance/apiAplCapabilityType';
import type { ApiAplId } from 'api/types/appliance/apiAplId';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiOrganizationId } from 'api/types/common/apiOrganizationId';
import {
  appliancesFetchFailed,
  appliancesFetching,
  appliancesFetchRequested,
  appliancesFetchSucceed,
  selectShouldFetchAppliances,
} from 'features/appliances/appliancesSlice';
import { runConfigSelector } from 'features/configs/configsSagas';
import {
  selectConfigsSupportedOrganizationIds,
  selectConfigsSupportedApplianceCapabilityTypes,
} from 'features/configs/configsSlice';
import type { AppLocalizedFetchActionPayload } from 'types/appLocalizedFetchActionPayload';
import { fromApiAplAppliance } from 'types/appliance/appAppliance';

export const apiFetchApplianceSaga = createApiRequestSaga(apiGetAppliance);
export const apiFetchAppliancesSaga = createApiRequestSaga(apiGetAppliances);

export function* fetchAppliances({
  payload: { locale },
}: PayloadAction<AppLocalizedFetchActionPayload>) {
  const shouldFetch = (yield select(
    selectShouldFetchAppliances(locale)
  )) as ReturnType<ReturnType<typeof selectShouldFetchAppliances>>;
  if (!shouldFetch) {
    return;
  }

  yield put(appliancesFetching({ locale }));

  const response = (yield call(apiFetchAppliancesSaga, {
    locale,
  })) as ApiRequestSagaReturnType<typeof apiFetchAppliancesSaga>;

  if (!response.ok) {
    yield put(
      appliancesFetchFailed({ locale, data: response.details.message })
    );
    return;
  }

  const supportedOrganizationIds = (yield call(
    runConfigSelector,
    selectConfigsSupportedOrganizationIds
  )) as ApiOrganizationId[];

  const appliances = (yield all(
    response.data.items
      .filter(({ id }) =>
        supportedOrganizationIds.some((organizationId) =>
          id.includes(organizationId)
        )
      )
      .map((appliance) =>
        call(fetchAppliance, { locale, applianceId: appliance.id })
      )
  )) as ApiRequestSagaReturnType<typeof fetchAppliance>[];

  // If there is an API error for any of the appliances, fetchAppliance saga will return null.
  // Therefore, we remove null values from the array
  yield put(
    appliancesFetchSucceed({ locale, data: appliances.filter(Boolean) })
  );
}

export interface FetchApplianceSagaArgs {
  locale: ApiLocale;
  applianceId: ApiAplId;
}
export function* fetchAppliance({
  locale,
  applianceId,
}: FetchApplianceSagaArgs) {
  const response = (yield call(apiFetchApplianceSaga, {
    locale,
    applianceId,
  })) as ApiRequestSagaReturnType<typeof apiFetchApplianceSaga>;

  if (!response.ok) {
    return null;
  }
  const supportedApplianceCapabilityTypes = (yield call(
    runConfigSelector,
    selectConfigsSupportedApplianceCapabilityTypes
  )) as ApiAplCapabilityType[];
  try {
    return fromApiAplAppliance(
      response.data,
      supportedApplianceCapabilityTypes
    );
  } catch (error) {
    datadogRum.addError(error);
    return null;
  }
}

function* appliancesFetchWatcher() {
  yield takeEvery(appliancesFetchRequested, fetchAppliances);
}

export const appliancesRestartableSagas = [appliancesFetchWatcher];
