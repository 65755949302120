import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import type { ApiLocale } from 'api/types/common/apiLocale';
import { AppUnitSystem } from 'types/appUnitSystem';
import { unitSystemByLocale } from 'utils/unitSystems';

export interface ContentByLocaleProps {
  metricContent: ReactNode;
  usCustomaryContent: ReactNode;
  locale: ApiLocale;
  separator?: ReactNode;
}

export const ContentByLocale: FC<ContentByLocaleProps> = memo(
  function ContentByLocale({
    metricContent,
    usCustomaryContent,
    locale,
    separator,
  }) {
    const primaryUnitSystem = unitSystemByLocale[locale];

    return (
      <>
        {primaryUnitSystem === AppUnitSystem.Metric
          ? metricContent
          : usCustomaryContent}
        {separator}
        {primaryUnitSystem === AppUnitSystem.Metric
          ? usCustomaryContent
          : metricContent}
      </>
    );
  }
);
