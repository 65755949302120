import type { FC, ReactNode } from 'react';
import { memo, useEffect } from 'react';

import { useHeaderContext } from './HeaderContextProvider';

export interface HeaderScrolledProps {
  children: ReactNode;
}

/**
 * A component that renders its children in the `<Header />` when it's in scrolled state.
 */
export const HeaderScrolled: FC<HeaderScrolledProps> = memo(
  function HeaderScrolled({ children }) {
    const { setScrollHeaderChildren } = useHeaderContext();

    useEffect(() => {
      setScrollHeaderChildren(children);

      return () => {
        setScrollHeaderChildren(undefined);
      };
    }, [children, setScrollHeaderChildren]);

    return null;
  }
);
