import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiTag } from 'api/types/common/apiTag';
import type { ApiCcCuratedCollection } from 'api/types/curatedCollection/apiCcCuratedCollection';
import type { ApiCcCuratedCollectionId } from 'api/types/curatedCollection/apiCcCuratedCollectionId';
import type { ApiHfHomeFeed } from 'api/types/homeFeed/apiHfHomeFeed';
import type { RootState } from 'app/store/rootReducer';

export interface HomeFeedState {
  isFetching: boolean;
  isSaving: boolean;
  homeFeed?: ApiHfHomeFeed;
  apiError?: string;
  rowsLoading: { [key: string]: boolean };
}

export const initialState: HomeFeedState = {
  isFetching: false,
  isSaving: false,
  rowsLoading: {},
};

const homeFeedSlice = createSlice({
  name: 'homeFeed',
  initialState,
  reducers: {
    homeFeedFetchRequested(
      state,
      _action: PayloadAction<HomeFeedFetchRequestedPayload>
    ) {
      state.isFetching = true;
    },
    homeFeedFetchSucceed(state, { payload }: PayloadAction<ApiHfHomeFeed>) {
      state.isFetching = false;
      state.homeFeed = payload;
    },
    homeFeedFetchFailed(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.apiError = payload;
    },
    homeFeedUpdatePending(state, { payload }: PayloadAction<ApiHfHomeFeed>) {
      state.isSaving = true;
      state.homeFeed = payload;
    },
    homeFeedUpdateSucceed(
      state,
      { payload: { etag } }: PayloadAction<Pick<ApiHfHomeFeed, 'etag'>>
    ) {
      state.isSaving = false;
      if (state.homeFeed) {
        state.homeFeed.etag = etag;
      }
    },
    homeFeedUpdateFailed(state, { payload }: PayloadAction<ApiHfHomeFeed>) {
      state.isSaving = false;
      state.homeFeed = payload;
    },
    homeFeedDeleteCollectionRequested(
      state,
      { payload }: PayloadAction<ApiCcCuratedCollectionId>
    ) {
      state.rowsLoading[payload] = true;
    },
    homeFeedDeleteCollectionSucceeded(
      state,
      {
        payload: { collectionId, etag },
      }: PayloadAction<{ collectionId: ApiCcCuratedCollectionId; etag: string }>
    ) {
      if (state.homeFeed) {
        state.homeFeed.items = state.homeFeed?.items.filter(
          ({ id }) => id !== collectionId
        );
        state.homeFeed.etag = etag;
      }
      delete state.rowsLoading[collectionId];
    },
    homeFeedDeleteCollectionFailed(
      state,
      { payload }: PayloadAction<ApiCcCuratedCollectionId>
    ) {
      delete state.rowsLoading[payload];
    },
  },
});

export const {
  reducer: homeFeedReducer,
  actions: {
    homeFeedFetchRequested,
    homeFeedFetchSucceed,
    homeFeedFetchFailed,
    homeFeedUpdatePending,
    homeFeedUpdateSucceed,
    homeFeedUpdateFailed,
    homeFeedDeleteCollectionRequested,
    homeFeedDeleteCollectionSucceeded,
    homeFeedDeleteCollectionFailed,
  },
} = homeFeedSlice;

const selectHomeFeedState = (state: RootState): HomeFeedState => state.homeFeed;

export const selectHomeFeedIsFetching = (state: RootState): boolean =>
  selectHomeFeedState(state).isFetching;

export const selectHomeFeed = (state: RootState): ApiHfHomeFeed | undefined =>
  selectHomeFeedState(state).homeFeed;

export const selectHomeFeedItems = (
  state: RootState
): ApiCcCuratedCollection[] => selectHomeFeed(state)?.items ?? [];

export const selectHomeFeedIsSaving = (state: RootState): boolean =>
  selectHomeFeedState(state).isSaving;

export const selectHomeFeedRowsLoading = (state: RootState) =>
  selectHomeFeedState(state).rowsLoading;

export interface HomeFeedFetchRequestedPayload {
  locale: ApiLocale;
  applianceTags?: ApiTag[];
  preview?: boolean;
}
