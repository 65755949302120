import { AppUnitSystem } from 'types/appUnitSystem';

export const valueInMultipleSystemsFieldStrings = {
  valueField: {
    labels: {
      [AppUnitSystem.Metric]: 'Metric',
      [AppUnitSystem.UsCustomary]: 'US Customary',
    },
    placeholder: { default: 'Quantity', condensed: '0' },
  },
  unitField: {
    label: 'Unit',
    placeholder: 'Select',
  },
  generateAriaLabel: ({
    prefix,
    unitSystem,
    field,
  }: {
    prefix: string;
    unitSystem: AppUnitSystem;
    field: 'value' | 'unit';
  }) => `${prefix} ${unitSystem} ${field}`,
  buttons: {
    retry: 'Retry',
  },
};

export const valueInMultipleSystemsFieldConstants = {
  generateFieldId: ({
    prefix,
    unitSystem,
    field,
  }: {
    prefix: string;
    unitSystem: AppUnitSystem;
    field?: 'value' | 'unit';
  }) =>
    field ? `${prefix}-${unitSystem}-${field}` : `${prefix}-${unitSystem}`,
} as const;
