import type { FC } from 'react';
import { memo } from 'react';

import { appliancesPageStrings } from 'features/appliances/AppliancesPage.constants';
import { AppliancesList } from 'features/appliances/list/AppliancesList';
import { ListLayout } from 'features/layout/ListLayout';

const { titles } = appliancesPageStrings;

export const AppliancesPage: FC = memo(function AppliancesPage() {
  return (
    <ListLayout title={titles.main}>
      <AppliancesList />
    </ListLayout>
  );
});
