import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { recipeIngredientsStrings } from 'features/recipe/ingredients/list/RecipeIngredients.constants';

const { labels } = recipeIngredientsStrings;

export const RecipeIngredientsEmptyImg: FC<SvgIconProps> = memo(
  function RecipeIngredientsEmptyImg(props) {
    return (
      <SvgIcon titleAccess={labels.emptyImg} viewBox="0 0 336 184" {...props}>
        <path
          d="M307.035 184C307.035 107.785 244.786 46 168 46C91.2136 46 28.9656 107.785 28.9656 184H307.035Z"
          fill="white"
        />
        <path
          d="M42.8691 101.2V126.5C42.8691 154.446 65.6928 177.1 93.8485 177.1H98.3613C117.625 177.1 133.242 161.599 133.242 142.479V101.2H42.8691Z"
          fill="#58595B"
        />
        <path
          d="M240.993 128.8C238.434 128.8 236.359 126.779 236.359 124.286V73.5137C236.359 71.0205 238.434 69 240.993 69C243.553 69 245.628 71.0205 245.628 73.5137V124.286C245.628 126.779 243.553 128.8 240.993 128.8Z"
          fill="#996600"
        />
        <path
          d="M242.158 126.5C241.505 126.501 240.861 126.365 240.265 126.101C239.669 125.837 239.136 125.452 238.701 124.97C238.266 124.488 237.938 123.92 237.739 123.304C237.54 122.687 237.474 122.036 237.546 121.393L242.181 79.9928C242.319 78.7811 242.934 77.6729 243.894 76.911C244.853 76.149 246.077 75.7954 247.298 75.9275C248.52 76.0622 249.638 76.673 250.406 77.6256C251.173 78.5782 251.529 79.7946 251.393 81.0071L246.759 122.407C246.633 123.531 246.094 124.57 245.245 125.325C244.397 126.081 243.297 126.499 242.158 126.5Z"
          fill="#CC9966"
        />
        <path
          d="M244.471 127.65C243.737 127.65 243.013 127.476 242.359 127.144C241.706 126.812 241.141 126.33 240.711 125.739C240.282 125.148 240 124.463 239.889 123.743C239.778 123.022 239.841 122.286 240.073 121.594L256.293 73.2952C256.683 72.1391 257.519 71.1836 258.618 70.6383C259.717 70.0929 260.988 70.0021 262.154 70.3857C263.32 70.772 264.284 71.6019 264.833 72.693C265.383 73.7841 265.474 75.0472 265.086 76.2047L248.866 124.504C248.559 125.419 247.969 126.216 247.18 126.78C246.391 127.345 245.444 127.649 244.471 127.65Z"
          fill="#996600"
        />
        <path
          d="M244.464 127.65C243.639 127.65 242.829 127.43 242.119 127.013C241.595 126.707 241.136 126.301 240.769 125.819C240.403 125.337 240.136 124.788 239.983 124.203C239.83 123.618 239.795 123.009 239.88 122.411C239.964 121.813 240.166 121.237 240.475 120.717L263.648 81.6189C263.956 81.0981 264.365 80.6427 264.85 80.2787C265.336 79.9148 265.889 79.6495 266.479 79.4979C267.068 79.3463 267.681 79.3115 268.284 79.3955C268.887 79.4794 269.467 79.6804 269.991 79.9871C270.516 80.2931 270.975 80.6987 271.341 81.1808C271.708 81.663 271.975 82.2121 272.128 82.7968C272.281 83.3815 272.316 83.9904 272.232 84.5886C272.147 85.1869 271.945 85.7627 271.636 86.2833L248.464 125.381C248.055 126.073 247.47 126.646 246.768 127.044C246.067 127.442 245.272 127.651 244.464 127.65Z"
          fill="#CC9966"
        />
        <path
          d="M129.765 94.3H46.3448C44.5011 94.3 42.7329 95.027 41.4292 96.321C40.1255 97.615 39.3931 99.37 39.3931 101.2H136.717C136.717 99.37 135.985 97.615 134.681 96.321C133.377 95.027 131.609 94.3 129.765 94.3Z"
          fill="#BBBDBF"
        />
        <path d="M216.662 184H258.372V112.7H216.662V184Z" fill="#6D6E70" />
        <path
          d="M258.372 111.55H236.359V118.45H258.372C259.294 118.45 260.178 118.087 260.83 117.44C261.482 116.793 261.848 115.915 261.848 115C261.848 114.085 261.482 113.207 260.83 112.56C260.178 111.913 259.294 111.55 258.372 111.55Z"
          fill="#58595B"
        />
        <path
          d="M47.5036 170.2C46.2745 170.2 45.0957 170.685 44.2266 171.547C43.3574 172.41 42.8691 173.58 42.8691 174.8V184H91.5312V170.2H47.5036Z"
          fill="#CC9966"
        />
        <path
          d="M228.248 4.59998H107.752C105.294 4.59998 102.936 5.56926 101.198 7.29459C99.4595 9.01993 98.4829 11.36 98.4829 13.8V184H237.517V13.8C237.517 11.36 236.541 9.01993 234.803 7.29459C233.064 5.56926 230.707 4.59998 228.248 4.59998Z"
          fill="#FFCC99"
        />
        <path
          d="M207.393 111.55H128.607C127.992 111.55 127.403 111.792 126.968 112.224C126.534 112.655 126.29 113.24 126.29 113.85V123.05C126.29 123.66 126.534 124.245 126.968 124.676C127.403 125.108 127.992 125.35 128.607 125.35H207.393C208.008 125.35 208.597 125.108 209.032 124.676C209.466 124.245 209.71 123.66 209.71 123.05V113.85C209.71 113.24 209.466 112.655 209.032 112.224C208.597 111.792 208.008 111.55 207.393 111.55Z"
          fill="#FF6633"
        />
        <path
          d="M149.462 170.2H82.2622C81.033 170.2 79.8542 170.685 78.9851 171.547C78.116 172.41 77.6277 173.58 77.6277 174.8V184H154.097V174.8C154.097 173.58 153.608 172.41 152.739 171.547C151.87 170.685 150.691 170.2 149.462 170.2Z"
          fill="#996633"
        />
        <path
          d="M201.6 132.25H134.4C134.093 132.25 133.798 132.371 133.581 132.587C133.364 132.802 133.241 133.095 133.241 133.4V138C133.241 138.305 133.364 138.598 133.581 138.813C133.798 139.029 134.093 139.15 134.4 139.15H201.6C201.907 139.15 202.202 139.029 202.419 138.813C202.637 138.598 202.759 138.305 202.759 138V133.4C202.759 133.095 202.637 132.802 202.419 132.587C202.202 132.371 201.907 132.25 201.6 132.25ZM201.6 142.6H134.4C134.093 142.6 133.798 142.721 133.581 142.937C133.364 143.152 133.241 143.445 133.241 143.75V148.35C133.241 148.655 133.364 148.948 133.581 149.163C133.798 149.379 134.093 149.5 134.4 149.5H201.6C201.907 149.5 202.202 149.379 202.419 149.163C202.637 148.948 202.759 148.655 202.759 148.35V143.75C202.759 143.445 202.637 143.152 202.419 142.937C202.202 142.721 201.907 142.6 201.6 142.6ZM191.172 152.95H144.828C144.52 152.95 144.226 153.071 144.008 153.287C143.791 153.502 143.669 153.795 143.669 154.1V158.7C143.669 159.005 143.791 159.298 144.008 159.513C144.226 159.729 144.52 159.85 144.828 159.85H191.172C191.48 159.85 191.774 159.729 191.992 159.513C192.209 159.298 192.331 159.005 192.331 158.7V154.1C192.331 153.795 192.209 153.502 191.992 153.287C191.774 153.071 191.48 152.95 191.172 152.95Z"
          fill="#FF9966"
        />
        <path
          d="M202.759 66.7C202.759 85.7532 187.196 101.2 168 101.2C148.804 101.2 133.241 85.7532 133.241 66.7C133.241 47.6467 148.804 32.2 168 32.2C187.196 32.2 202.759 47.6467 202.759 66.7Z"
          fill="white"
        />
        <path
          d="M195.807 66.7C195.807 81.9432 183.358 94.3 168 94.3C152.642 94.3 140.193 81.9432 140.193 66.7C140.193 51.4567 152.642 39.1 168 39.1C183.358 39.1 195.807 51.4567 195.807 66.7Z"
          fill="#FFCCCC"
        />
        <path
          d="M181.904 59.8C181.904 52.1789 175.678 46 168 46C160.322 46 154.097 52.1789 154.097 59.8C154.097 67.421 160.322 73.6 168 73.6C175.678 73.6 181.904 67.421 181.904 59.8Z"
          fill="#FF6600"
        />
        <path
          d="M168 57.5L164.524 54.05L168 50.6L171.476 54.05L168 57.5Z"
          fill="#CCCC33"
        />
        <path d="M165.683 56.35H170.317V51.75H165.683V56.35Z" fill="#CCCC33" />
        <path
          d="M169.159 54.05C169.159 53.745 169.037 53.4525 168.819 53.2368C168.602 53.0211 168.307 52.9 168 52.9C167.693 52.9 167.398 53.0211 167.181 53.2368C166.964 53.4525 166.842 53.745 166.842 54.05C166.842 54.355 166.964 54.6475 167.181 54.8631C167.398 55.0788 167.693 55.2 168 55.2C168.307 55.2 168.602 55.0788 168.819 54.8631C169.037 54.6475 169.159 54.355 169.159 54.05Z"
          fill="#009933"
        />
        <path
          d="M147.503 85.3472C152.586 90.8488 159.886 94.3 167.999 94.3C176.114 94.3 183.416 90.8488 188.498 85.3472C182.698 80.853 175.635 78.2 168 78.2C160.365 78.2 153.303 80.853 147.503 85.3472Z"
          fill="#FF6600"
        />
        <path
          d="M86.8944 163.3C85.4356 163.299 84.014 162.843 82.8309 161.996C81.6477 161.149 80.763 159.954 80.3018 158.58L66.4007 117.183C66.1044 116.321 65.9827 115.41 66.0427 114.502C66.1027 113.594 66.3432 112.706 66.7503 111.891C67.1575 111.075 67.7231 110.348 68.4148 109.75C69.1065 109.152 69.9105 108.696 70.7804 108.409C71.6504 108.121 72.5691 108.007 73.4837 108.073C74.3983 108.139 75.2907 108.384 76.1094 108.794C76.9281 109.204 77.657 109.771 78.254 110.462C78.8511 111.153 79.3045 111.954 79.5881 112.82L93.4893 154.217C93.8383 155.255 93.9338 156.359 93.768 157.44C93.6022 158.521 93.1797 159.548 92.5356 160.435C91.8914 161.322 91.0439 162.045 90.0631 162.543C89.0823 163.041 87.9962 163.3 86.8944 163.3Z"
          fill="#339999"
        />
        <path
          d="M115.862 128.8H67.2C65.3563 128.8 63.5881 129.527 62.2844 130.821C60.9807 132.115 60.2483 133.87 60.2483 135.7V142.6C60.2483 157.843 72.6977 170.2 88.0552 170.2H95.0069C110.364 170.2 122.814 157.843 122.814 142.6V135.7C122.814 133.87 122.081 132.115 120.778 130.821C119.474 129.527 117.706 128.8 115.862 128.8Z"
          fill="#CCFFFF"
        />
        <path
          d="M228.248 128.8H223.614C217.225 128.8 212.028 133.959 212.028 140.3V154.1C212.028 155.32 212.516 156.49 213.385 157.353C214.254 158.215 215.433 158.7 216.662 158.7C217.891 158.7 219.07 158.215 219.939 157.353C220.808 156.49 221.297 155.32 221.297 154.1V140.3C221.297 139.032 222.336 138 223.614 138H228.248C229.477 138 230.656 137.515 231.525 136.653C232.394 135.79 232.883 134.62 232.883 133.4C232.883 132.18 232.394 131.01 231.525 130.147C230.656 129.285 229.477 128.8 228.248 128.8Z"
          fill="#339966"
        />
        <path
          d="M294.29 151.8H254.896V156.4H298.924C298.924 155.18 298.436 154.01 297.567 153.147C296.698 152.285 295.519 151.8 294.29 151.8Z"
          fill="#FF9999"
        />
        <path
          d="M229.407 156.4V163.3C229.407 174.732 238.744 184 250.262 184H275.752C287.27 184 296.607 174.732 296.607 163.3V156.4H229.407Z"
          fill="#CC6666"
        />
        <path
          d="M272.276 174.8C272.276 177.24 271.299 179.58 269.561 181.305C267.823 183.031 265.465 184 263.007 184H235.2C232.742 184 230.384 183.031 228.646 181.305C226.908 179.58 225.931 177.24 225.931 174.8V128.8H272.276L272.566 128.805C274.973 128.879 277.256 129.881 278.932 131.597C280.608 133.313 281.545 135.61 281.545 138C279.137 138 276.823 138.93 275.094 140.594C273.365 142.257 272.356 144.523 272.281 146.912L272.276 147.2V174.8Z"
          fill="#66CC99"
        />
      </SvgIcon>
    );
  }
);
