import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/store/rootReducer';

export interface TabsListState {
  sticking: boolean;
}

export const initialState: TabsListState = {
  sticking: false,
};

export const tabsListSlice = createSlice({
  name: 'tabsListSlice',
  initialState,
  reducers: {
    tabsSticked(state, { payload }: PayloadAction<boolean>) {
      state.sticking = payload;
    },
  },
});

export const {
  reducer: tabsListReducer,
  actions: { tabsSticked },
} = tabsListSlice;

const selectTabsListState = (state: RootState): TabsListState => state.tabsList;

export const selectTabsListSticking = (state: RootState) =>
  selectTabsListState(state).sticking;
