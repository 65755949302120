import type { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import type { ApiRequestSagaReturnType } from 'api/createApiRequestSaga';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
import type { ApiPostImageRequest } from 'api/media';
import { apiPostImage } from 'api/media';
import { errorOccurred } from 'features/error/errorSlice';
import { errorSliceConstants } from 'features/error/errorSlice.constants';
import {
  mediaUploadFailed,
  mediaUploadRequested,
  mediaUploadFinished,
} from 'features/media/mediaSlice';
import type { AppMediaStorageFile } from 'features/media/mediaStorage';
import { mediaStorage } from 'features/media/mediaStorage';

export const apiPostImageSaga = createApiRequestSaga(apiPostImage);

export type UploadImagePayload = Omit<ApiPostImageRequest, 'image'>;

export function* uploadImage({ payload }: PayloadAction<UploadImagePayload>) {
  const image = (yield call(mediaStorage.get)) as
    | AppMediaStorageFile
    | undefined;

  if (!image) {
    yield put(mediaUploadFinished());
    return;
  }

  const response = (yield call(apiPostImageSaga, {
    ...payload,
    image,
  })) as ApiRequestSagaReturnType<typeof apiPostImageSaga>;

  if (!response.ok) {
    yield put(mediaUploadFailed(response.details.message));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }
  yield put(mediaUploadFinished());
}

function* mediaUploadWatcher() {
  yield takeLatest(mediaUploadRequested, uploadImage);
}

export const mediaRestartableSagas = [mediaUploadWatcher];
