import { Button, ButtonSize, ButtonStyle } from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { recipeReviewFixButtonStrings } from 'features/recipe/review/shared/RecipeReviewFixButton/RecipeReviewFixButton.constants';
import { AppIssueType } from 'types/appIssueType';

const { buttons } = recipeReviewFixButtonStrings;

interface RecipeReviewFixButtonProps {
  onClick: () => void;
  severity: AppIssueType;
  sx?: SxProps;
}

export const RecipeReviewFixButton: FC<RecipeReviewFixButtonProps> = memo(
  function RecipeReviewFixButton({ onClick, severity, sx }) {
    return (
      <Button
        buttonStyle={
          severity === AppIssueType.Warning
            ? ButtonStyle.Warning
            : ButtonStyle.Critical
        }
        size={ButtonSize.Small}
        label={buttons.fix}
        onClick={onClick}
        sx={sx}
        aria-label={buttons.fix}
      />
    );
  }
);
