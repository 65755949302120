import { TextField } from '@mui/material';
import type { FC } from 'react';
import { useRef, memo, useEffect, useCallback } from 'react';

import { useAutoFocus } from 'app/routes/hooks/useAutoFocus';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { recipeStepTextFieldStrings } from 'features/recipe/shared/RecipeStepTextField/RecipeStepTextField.constants';
import type { RecipeFieldProps } from 'features/recipe/shared/types/appRecipeFieldProps';

const { placeholder, label, errorMessages } = recipeStepTextFieldStrings;

export interface RecipeStepTextFieldProps
  extends Omit<RecipeFieldProps, 'value'> {
  value?: string;
  showErrors?: boolean;
  hideLabel?: boolean;
}

export const RecipeStepTextField: FC<RecipeStepTextFieldProps> = memo(
  function RecipeStepTextField({
    id,
    showErrors = false,
    hideLabel,
    value,
    onChange,
  }) {
    const { isFocused } = useAutoFocus();
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleTextChange = useCallback(
      (text = '') => {
        onChange({ value: text });
      },
      [onChange]
    );

    useEffect(() => {
      handleTextChange(value);
    }, [handleTextChange, value, showErrors]);

    useEffect(() => {
      if (isFocused(id)) {
        inputRef.current?.focus();
      }
    }, [id, isFocused]);

    return (
      <TextField
        inputRef={inputRef}
        id={id}
        label={hideLabel ? undefined : label}
        placeholder={placeholder}
        type="text"
        variant="outlined"
        multiline
        maxRows="6"
        fullWidth
        required
        value={value}
        error={showErrors}
        helperText={
          showErrors && (
            <HelperText
              message={errorMessages.required}
              severity={HelperTextSeverity.Critical}
            />
          )
        }
        onChange={(event) => handleTextChange(event.target.value)}
        inputProps={{ 'aria-label': label }}
      />
    );
  }
);
