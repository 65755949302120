import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { AppConfig, AppConfigAuthor } from 'api/configs';
import type { ApiAplCapabilityType } from 'api/types/appliance/apiAplCapabilityType';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiOrganizationId } from 'api/types/common/apiOrganizationId';
import type { RootState } from 'app/store/rootReducer';
import { appConfig } from 'config/config';
import type { AppFeature } from 'types/appFeature';

export interface ConfigsState {
  fetching?: boolean;
  apiError?: string;
  locales?: ApiLocale[];
  author: AppConfigAuthor;
  features?: Partial<Record<AppFeature, boolean>>;
  supportedOrganizationIds?: ApiOrganizationId[];
  supportedApplianceCapabilityTypes?: ApiAplCapabilityType[];
}

export const initialState: ConfigsState = {
  author: {
    image: '',
    url: '',
  },
};

export const configsSlice = createSlice({
  name: 'configsSlice',
  initialState,
  reducers: {
    configsFetching(state) {
      state.fetching = true;
    },
    configsFetchSucceed(state, { payload }: PayloadAction<AppConfig>) {
      state.locales = payload.locales;
      state.author = payload.author;
      state.features = payload.features;
      state.supportedOrganizationIds = payload.supportedOrganizationIds ?? [
        appConfig.organizationId(),
      ];
      state.supportedApplianceCapabilityTypes =
        payload.supportedApplianceCapabilityTypes;
      state.fetching = false;
    },
    configsFetchFailed(state, { payload }: PayloadAction<string>) {
      state.apiError = payload;
    },
  },
});

export const {
  reducer: configsReducer,
  actions: { configsFetching, configsFetchSucceed, configsFetchFailed },
} = configsSlice;

export const selectConfigsState = (state: RootState): ConfigsState =>
  state.configs;

export const selectConfigsFetching = (state: RootState): boolean =>
  !!selectConfigsState(state).fetching;

export const selectConfigsLocales = (state: RootState) =>
  selectConfigsState(state).locales;

export const selectConfigsAuthor = (state: RootState) =>
  selectConfigsState(state).author;

export const selectConfigsFeatures = (state: RootState) =>
  selectConfigsState(state).features;

export const selectConfigsIsFeatureEnabled = (feature: AppFeature) =>
  createSelector(
    selectConfigsFeatures,
    (features): boolean => !!features?.[feature]
  );

export const selectConfigsSupportedApplianceCapabilityTypes = (
  state: RootState
) => selectConfigsState(state).supportedApplianceCapabilityTypes;

export const selectConfigsSupportedOrganizationIds = (state: RootState) =>
  selectConfigsState(state).supportedOrganizationIds;
