export const logoutStrings = {
  button: {
    label: 'Log out',
  },
  confirmationDialog: {
    title: 'Log out',
    body: 'Are you sure you want to log out?',
    confirmButton: 'Yes, log out',
    cancelButton: 'Cancel',
  },
} as const;
