import upperFirst from 'lodash/upperFirst';
import type { SagaIterator } from 'redux-saga';
import { all, call } from 'redux-saga/effects';

import type { ApiList, ApiResponse } from 'api/types';
import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiRefEntity } from 'api/types/referenceData/apiRefEntity';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { ApiRefLocalizedDataRequest } from 'api/types/referenceData/apiRefLocalizedDataRequest';
import { appConfig } from 'config/config';
import type { AppCapability } from 'types/appCapability';

export const referenceDataIdToHumanText = (id: ApiEntityId) =>
  id.replace('cckg:', '').replace(/[A-Z]/g, ' $&').toLocaleLowerCase().slice(1);

export type AppRefEntityDisplayName = Record<ApiEntityId, string>;

export const disambiguateTerms = <T extends ApiRefEntity | AppCapability>(
  terms: T[]
): AppRefEntityDisplayName => {
  const dictionary = terms.reduce(
    (names, { name }) => {
      if (!names[name]) {
        names[name] = 0;
      }
      names[name]++;
      return names;
    },
    {} as Record<string, number>
  );

  return terms.reduce((disambiguatedTerms, { id, name }) => {
    let displayName = name;
    if (dictionary[name] > 1) {
      // There are more terms with the same name. Adding ID to disambiguate
      const idText = referenceDataIdToHumanText(id);
      const disambiguationText = idText === name ? '' : ` - ${idText}`;
      displayName = `${name}${disambiguationText}`;
    }
    disambiguatedTerms[id] = displayName;
    return disambiguatedTerms;
  }, {} as AppRefEntityDisplayName);
};

export function* fetchItemsRecursively<TResponse>({
  fetchSaga,
  locale,
}: {
  fetchSaga: (
    args: ApiRefLocalizedDataRequest
  ) => SagaIterator<ApiResponse<ApiList<TResponse>>>;
  locale: ApiLocale;
}) {
  const data: TResponse[] = [];
  const firstResponse = (yield call(fetchSaga, {
    from: 0,
    locale,
  })) as ApiResponse<ApiList<TResponse>>;

  if (!firstResponse.ok) {
    return firstResponse;
  }

  data.push(...firstResponse.data.items);
  const { total } = firstResponse.data;

  if (total > appConfig.referenceDataApiPageSize()) {
    const parallelCalls = Array.from(
      { length: Math.ceil(total / appConfig.referenceDataApiPageSize() - 1) },
      (_, i) =>
        call(fetchSaga, {
          from: (i + 1) * appConfig.referenceDataApiPageSize(),
          locale,
        })
    );
    const allResponses = (yield all(parallelCalls)) as ApiResponse<
      ApiList<TResponse>
    >[];

    for (const response of allResponses) {
      if (!response.ok) {
        return response;
      }
      data.push(...response.data.items);
    }
  }
  return { ok: true, data };
}

export const refDataEntityToSentenceCase = <TID extends ApiRefId>(
  entity: ApiRefEntity & { id: TID }
) => ({
  ...entity,
  id: entity.id as TID,
  name: upperFirst(entity.name),
});

export const refDataEntitiesToSentenceCase = <T extends ApiRefEntity[]>(
  entities: T
): T => entities.map(refDataEntityToSentenceCase) as T;
