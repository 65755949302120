import snakecaseKeys from 'snakecase-keys';

import { fetchJson } from 'api/fetchJson';
import { apiConvertUnits } from 'api/paths';
import { HttpMethod } from 'api/types';
import type { ApiUnitSystemId } from 'api/types/common/apiUnitSystemId';
import type { ApiRefConvertedQuantity } from 'api/types/referenceData/apiRefConvertedQuantity';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';

export interface ApiRefConvertQuantityRequestContext {
  preparationReferenceIds?: ApiRefId[];
  ingredientReferenceId?: ApiRefId;
  capabilityReferenceId?: ApiRefId;
  settingReferenceId?: ApiRefId;
}

export interface ApiRefConvertQuantityRequest {
  entities: {
    sourceUnit: {
      value: number;
      unitReferenceId: ApiRefId;
      unitSystemReferenceId: ApiUnitSystemId;
      context?: ApiRefConvertQuantityRequestContext;
    };
    targetUnit: {
      unitSystemReferenceId: ApiUnitSystemId;
      preferredUnitReferenceId?: ApiRefId;
      rounding?: boolean;
    };
  }[];
}

export interface ApiRefConvertQuantityResponse {
  entities: ApiRefConvertedQuantity[];
}

export const apiPostConvertQuantity = (
  body: ApiRefConvertQuantityRequest,
  signal: AbortSignal
) =>
  fetchJson<ApiRefConvertQuantityResponse>({
    url: apiConvertUnits,
    body: snakecaseKeys(body),
    httpMethod: HttpMethod.Post,
    signal,
  });
