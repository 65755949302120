import { appConfig } from 'config/config';

export const apiPathParams = {
  recipeId: 'recipe_id',
  applianceId: 'appliance_id',
  resourceType: 'resource_type',
  resourceId: 'resource_id',
  locale: 'locale',
  collectionId: 'collection_id',
};

const apiHostUrl = appConfig.isDevOrTestEnv()
  ? '/api'
  : appConfig.apiGatewayUrl();

const apiMediaHostUrl = (() => {
  if (appConfig.isDevEnv() || appConfig.isUnitTestEnv()) {
    return `http://${window.location.host}/api-media`;
  }
  return appConfig.apiMediaUrl();
})();

const configsUrl = appConfig.isDevOrTestEnv()
  ? '/configs'
  : appConfig.configsUrl();

export const recipesUrl = `/recipes/` as const;
export const apiRecipesUrl = `${apiHostUrl}${recipesUrl}` as const;
export const createRecipeUrl = recipesUrl;
export const apiCreateRecipeUrl = apiRecipesUrl;
export const apiGetRecipeUrl =
  `${apiRecipesUrl}:${apiPathParams.recipeId}` as const;
export const updateRecipeUrl =
  `${recipesUrl}:${apiPathParams.recipeId}` as const;
export const apiUpdateRecipeUrl = `${apiHostUrl}${updateRecipeUrl}` as const;
export const apiDeleteRecipeUrl =
  `${apiRecipesUrl}:${apiPathParams.recipeId}` as const;
export const apiForkRecipeUrl =
  `${apiRecipesUrl}:${apiPathParams.recipeId}/fork` as const;
export const apiPublishRecipeUrl =
  `${apiRecipesUrl}:${apiPathParams.recipeId}/publish` as const;
export const apiUnpublishRecipeUrl =
  `${apiRecipesUrl}:${apiPathParams.recipeId}/unpublish` as const;

const searchUrl = `/search`;

const apiSearchReferenceDataPrefix =
  `${apiHostUrl}${searchUrl}/reference` as const;
export const apiIngredientsUrl =
  `${apiSearchReferenceDataPrefix}/ingredients` as const;
export const apiPreparationsUrl =
  `${apiSearchReferenceDataPrefix}/preparations` as const;
export const apiGeneralCapabilitiesUrl =
  `${apiSearchReferenceDataPrefix}/capabilities` as const;
export const apiTagsUrl = `${apiSearchReferenceDataPrefix}/tags` as const;
export const apiMeasurementSystemsUrl =
  `${apiSearchReferenceDataPrefix}/measurement-systems` as const;
const apiReferenceDataPrefix = `${apiHostUrl}/reference` as const;
export const apiConvertUnits =
  `${apiReferenceDataPrefix}/convert-units` as const;

const recipeUnderstandingPrefix = `/recipe-understanding` as const;
export const recipeFromUrl = `${recipeUnderstandingPrefix}/from-url` as const;
export const apiRecipeFromUrl = `${apiHostUrl}${recipeFromUrl}` as const;
export const recipeFromTextUrl =
  `${recipeUnderstandingPrefix}/from-text` as const;
export const apiRecipeFromTextUrl =
  `${apiHostUrl}${recipeFromTextUrl}` as const;

export const apiImagesUrl = `${apiHostUrl}/media/images` as const;
export const apiGetListImagesUrl =
  `${apiImagesUrl}/:${apiPathParams.resourceType}/:${apiPathParams.resourceId}` as const;
export const apiGetImagesUrl = `${apiMediaHostUrl}/media/images` as const;

export const getRecipesUrl = `${searchUrl}/recipes` as const;
export const apiGetRecipesUrl = `${apiHostUrl}${getRecipesUrl}` as const;

export const apiAppliancesUrl = `${apiHostUrl}/appliances/` as const;
export const apiGetApplianceUrl =
  `${apiAppliancesUrl}:${apiPathParams.applianceId}` as const;

export const configAppliancesPath =
  `${configsUrl}/appliances_list.json` as const;
export const configPath = `${configsUrl}/cms_configs.json` as const;

export const apiUserUrl = `${apiHostUrl}/user/` as const;

export const apiHomeFeedsPrefix = `${apiHostUrl}/home-feeds` as const;
export const apiHomeFeedsUrl = `${apiHomeFeedsPrefix}/` as const;
export const apiHomeFeedUrl =
  `${apiHomeFeedsUrl}:${apiPathParams.locale}` as const;

export const apiCuratedCollectionsPrefix =
  `${apiHostUrl}/curated-collections` as const;
export const apiCuratedCollectionsUrl =
  `${apiCuratedCollectionsPrefix}/` as const;
export const apiCuratedCollectionUrl =
  `${apiCuratedCollectionsPrefix}/:${apiPathParams.collectionId}` as const;
