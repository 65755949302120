import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { useAppSelector } from 'app/store/hooks';
import { EmptyContent } from 'components/EmptyContent/EmptyContent';
import {
  selectRecipesHasAppliedFilters,
  selectRecipesSearchTerm,
} from 'components/RecipesSearch/recipesSearchSlice';
import { recipesListStrings } from 'features/recipes/list/RecipesList.constants';
import { ReactComponent as NoRecipesIcon } from 'features/recipes/no-recipes.svg';

export const EmptyRecipes: FC = memo(function EmptyRecipes() {
  const searchTerm = useAppSelector(selectRecipesSearchTerm);
  const hasFilters = useAppSelector(selectRecipesHasAppliedFilters);

  const isSearch = searchTerm !== '' || hasFilters;
  const { emptyList, emptySearch } = recipesListStrings;
  const { text, title } = isSearch ? emptySearch : emptyList;
  const image = <NoRecipesIcon />;

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <EmptyContent
        components={{ image }}
        sx={{ width: '530px', minHeight: '700px' }}
        text={text}
        title={title}
      />
    </Box>
  );
});
