import { Box, TextField } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import type { ApiLocale } from 'api/types/common/apiLocale';
import { useAppSelector } from 'app/store/hooks';
import { AutocompleteWithRetry } from 'components/AutocompleteWithRetry/AutocompleteWithRetry';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import type { TagsWithImages } from 'features/referenceData/tags/tagsSlice';
import {
  selectApplianceTags,
  selectTagsFetchError,
  selectTagsFetching,
} from 'features/referenceData/tags/tagsSlice';

export interface ApplianceTagsFieldProps {
  id: string;
  label: string;
  placeholder: string;
  value: TagsWithImages[];
  locale: ApiLocale | null;
  onChange: (tags: TagsWithImages[]) => void;
  onRetry: () => void;
  hint?: string;
  showErrors?: boolean;
}

export const ApplianceTagsField: FC<ApplianceTagsFieldProps> = memo(
  function ApplianceTagsField({
    id,
    label,
    placeholder,
    value,
    locale,
    onChange,
    onRetry,
    hint,
    showErrors,
  }) {
    const tags = Object.values(
      useAppSelector(selectApplianceTags(locale)) || {}
    );
    const isFetching = useAppSelector(selectTagsFetching(locale));
    const fetchError = useAppSelector(selectTagsFetchError(locale));
    return (
      <AutocompleteWithRetry
        multiple
        id={id}
        value={(!isFetching && !fetchError && value) || []}
        isOptionEqualToValue={(option, selected) => option.id === selected.id}
        loading={isFetching !== false}
        disableCloseOnSelect
        onChange={(_, options) => onChange(options)}
        options={tags}
        getOptionLabel={(tag) => tag.name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={placeholder}
            helperText={
              hint && (
                <HelperText
                  message={hint}
                  severity={HelperTextSeverity.Neutral}
                />
              )
            }
          />
        )}
        renderOption={(props, tag) => (
          <Box component="li" {...props} key={tag.id}>
            {tag.name}
          </Box>
        )}
        onRetry={onRetry}
        hasError={showErrors && !!fetchError}
      />
    );
  }
);
