export const homeFeedCollectionSagasStrings = {
  errors: {
    precondition: {
      title: 'Syncing conflict',
      message:
        'This collection has been modified by someone else since your last set of saved changes, please review the updated collection and place in any additional revisions required.',
    },
    deleteCollectionNoHomeFeed:
      'Deleting a collection from a non-existing home feed',
  },
  deleteCollectionSuccess: 'Collection was successfully deleted',
} as const;
