import {
  NotificationBadge,
  NotificationBadgeStatus,
  PantryColor,
  PantryCornerRadius,
  PantryTypography,
} from '@dropkitchen/pantry-react';
import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import type { ApiAplId } from 'api/types/appliance/apiAplId';
import { useAppSelector } from 'app/store/hooks';
import { selectAppliance } from 'features/appliances/appliancesSlice';
import { selectRecipeLocale } from 'features/recipe/recipeSlice';
import { ApplianceImage } from 'features/recipe/shared/ApplianceImage/ApplianceImage';
import type { IncompatibilityWithChildren } from 'features/recipe/steps/form/recipeStepsApplianceIncompatibilities.utils';

interface ApplianceIncompatibilityProps {
  applianceId: ApiAplId;
  incompatibilities: (string | IncompatibilityWithChildren)[];
}

function isComposedMessage(
  message: string | IncompatibilityWithChildren
): message is IncompatibilityWithChildren {
  return (message as IncompatibilityWithChildren).children !== undefined;
}

export const ApplianceIncompatibility: FC<ApplianceIncompatibilityProps> = memo(
  function ApplianceIncompatibility({ applianceId, incompatibilities }) {
    const locale = useAppSelector(selectRecipeLocale);
    const appliance = useAppSelector(selectAppliance(locale, applianceId));

    if (!appliance) {
      return null;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          p: 4,
          alignItems: 'center',
          gap: 4,
          alignSelf: 'stretch',
          borderRadius: PantryCornerRadius.Small,
          backgroundColor: PantryColor.SurfaceMuted,
          mb: 2,
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <ApplianceImage
            applianceId={applianceId}
            locale={locale}
            sx={{
              width: '44px',
              height: '44px',
            }}
          />
          <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
            <NotificationBadge status={NotificationBadgeStatus.Warning} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant={PantryTypography.Body1SemiBold}>
            {appliance.name}
          </Typography>
          {incompatibilities?.map((message, i) => {
            return isComposedMessage(message) ? (
              // eslint-disable-next-line react/no-array-index-key
              <ComposedMessage message={message} key={i} />
            ) : (
              <Typography
                variant={PantryTypography.Caption}
                sx={{ mt: 2 }}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              >
                {message}
              </Typography>
            );
          })}
        </Box>
      </Box>
    );
  }
);

interface ComposedMessageProps {
  message: IncompatibilityWithChildren;
}

const ComposedMessage: FC<ComposedMessageProps> = memo(
  function ComposedMessage({ message: { message, children } }) {
    return (
      <>
        <Typography variant={PantryTypography.Caption} sx={{ mt: 2 }}>
          {message}
        </Typography>
        <Box component="ul" sx={{ mt: 2, mb: 0, pl: 6 }}>
          {children.map((child, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <Typography variant={PantryTypography.Caption}>
                {child}
              </Typography>
            </li>
          ))}
        </Box>
      </>
    );
  }
);
