import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';

export enum ApiMeasurementSystemId {
  Metric = 'cckg:MetricSystem',
  UsCustomary = 'cckg:USCustomarySystem',
}

export interface ApiMeasurementSystem {
  id: ApiMeasurementSystemId;
  name: string;
  units: ApiQuantityUnit[];
}
