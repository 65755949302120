import {
  Button,
  ButtonSize,
  ButtonStyle,
  DeleteIcon,
  PantryColor,
  PantryTypography,
  Spinner,
} from '@dropkitchen/pantry-react';
import { Box, Typography } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-pro';
import produce from 'immer';
import type { FC, SyntheticEvent } from 'react';
import { memo, useCallback, useMemo, useState } from 'react';

import type { ApiCcCuratedCollectionRecipe } from 'api/types/curatedCollection/apiCcCuratedCollectionRecipe';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import type { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { generateRecipeRoute } from 'app/routes/routesUtils';
import { RecipeStatusBadge } from 'components/Badge/RecipeStatusBadge';
import type {
  TableColumn,
  TableLoadingOverlayProps,
  TableProps,
  TableRow,
  TableRowReorderParams,
} from 'components/Table/Table';
import { Table } from 'components/Table/Table';
import {
  HomeFeedCollectionRecipesColumnField,
  homeFeedCollectionRecipesConstants,
  homeFeedCollectionRecipesStrings,
} from 'features/homeFeed/HomeFeedCollectionRecipes/HomeFeedCollectionRecipes.constants';
import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import { RecipesListMainInfoCell } from 'features/recipes/list/RecipesListMainInfoCell';
import type { AppRecipeAuthor } from 'types/recipe/appRecipeAuthor';
import { fromAppTimeToSentence, fromISOToAppTime } from 'utils/convertTimes';

const { labels } = homeFeedCollectionRecipesStrings;
const { table: tableConstants } = homeFeedCollectionRecipesConstants;

export interface HomeFeedCollectionRecipesColumn extends TableColumn {
  field: HomeFeedCollectionRecipesColumnField;
}

export interface HomeFeedCollectionRecipesProps
  extends Pick<
    TableProps,
    | 'ariaLabel'
    | 'page'
    | 'rowsPerPage'
    | 'rowCount'
    | 'onPaginationChange'
    | 'hidePagination'
    | 'isCheckboxSelectionDisabled'
  > {
  recipes: ApiCcCuratedCollectionRecipe[];
  selectedRecipes?: ApiCcCuratedCollectionRecipe[];
  isLoading?: boolean;
  hideCheckboxSelection?: boolean;
  components?: {
    /* eslint-disable @typescript-eslint/naming-convention */
    NoRecipes?: FC;
    /* eslint-enable @typescript-eslint/naming-convention */
  };
  hideColumns?: HomeFeedCollectionRecipesColumnField[];
  onSelectedRecipesChange?: (recipeIds: ApiRcpRecipeId[]) => void;
  onRecipesReordered?: (recipes: ApiCcCuratedCollectionRecipe[]) => void;
  onRecipeRemoved?: (recipes: ApiCcCuratedCollectionRecipe[]) => void;
}

export const HomeFeedCollectionRecipes: FC<HomeFeedCollectionRecipesProps> =
  memo(function HomeFeedCollectionRecipes({
    ariaLabel,
    recipes,
    page,
    rowsPerPage,
    rowCount,
    components,
    selectedRecipes = [],
    isLoading = false,
    hideCheckboxSelection = false,
    isCheckboxSelectionDisabled = false,
    hidePagination = false,
    hideColumns,
    onPaginationChange,
    onSelectedRecipesChange,
    onRecipesReordered,
    onRecipeRemoved,
  }) {
    const handleRecipeRemoval = useCallback(
      (row: HomeFeedCollectionRecipeRow) => {
        onRecipeRemoved?.(recipes.filter(({ id }) => id !== row.id));
        setHoveredRowId(null);
      },
      [onRecipeRemoved, recipes]
    );

    const [hoveredRowId, setHoveredRowId] = useState<ApiRcpRecipeId | null>(
      null
    );

    const columns: HomeFeedCollectionRecipesColumn[] = useMemo(() => {
      return [
        {
          ...tableConstants.columns.order,
          renderCell: ({
            row,
          }: GridRenderCellParams<undefined, HomeFeedCollectionRecipeRow>) => {
            return (
              <Typography variant={PantryTypography.Body2}>
                {row.order}
              </Typography>
            );
          },
        },
        {
          ...tableConstants.columns.mainInfo,
          renderCell: ({
            row,
          }: GridRenderCellParams<undefined, HomeFeedCollectionRecipeRow>) => {
            return (
              <RecipesListMainInfoCell
                id={row.id}
                name={row.name}
                author={row.author.name}
                to={{
                  href: generateRecipeRoute({
                    id: row.id,
                    tab: RecipeTabName.Information,
                  }),
                  target: '_blank',
                }}
              />
            );
          },
        },
        {
          ...tableConstants.columns.ingredientsCount,
          renderCell: ({
            row,
          }: GridRenderCellParams<undefined, HomeFeedCollectionRecipeRow>) =>
            row.ingredientsCount || tableConstants.emptyCell,
        },
        {
          ...tableConstants.columns.stepsCount,
          renderCell: ({
            row,
          }: GridRenderCellParams<undefined, HomeFeedCollectionRecipeRow>) =>
            row.stepsCount || tableConstants.emptyCell,
        },
        {
          ...tableConstants.columns.totalTime,
          renderCell: ({
            row,
          }: GridRenderCellParams<undefined, HomeFeedCollectionRecipeRow>) =>
            row.totalTime
              ? fromAppTimeToSentence(
                  fromISOToAppTime(row.totalTime, { removeZeros: true })
                )
              : tableConstants.emptyCell,
        },
        {
          ...tableConstants.columns.id,
          renderCell: ({
            row,
          }: GridRenderCellParams<undefined, HomeFeedCollectionRecipeRow>) =>
            row.id,
        },
        {
          ...tableConstants.columns.state,
          renderCell: ({
            row,
          }: GridRenderCellParams<undefined, HomeFeedCollectionRecipeRow>) => {
            if (
              !hideColumns?.includes(
                HomeFeedCollectionRecipesColumnField.Action
              ) &&
              hoveredRowId === row.id
            ) {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Button
                    label={labels.deleteRecipeButton}
                    hideLabel
                    leadingIcon={DeleteRecipeIcon}
                    buttonStyle={ButtonStyle.Subtle}
                    size={ButtonSize.Medium}
                    onClick={() => handleRecipeRemoval(row)}
                  />
                </Box>
              );
            }
            return <RecipeStatusBadge status={row.state} />;
          },
        },
      ].filter((column) => !hideColumns?.includes(column.field));
    }, [handleRecipeRemoval, hideColumns, hoveredRowId]);

    const handleRowReorder = ({ to, from }: TableRowReorderParams) => {
      onRecipesReordered?.(
        produce(recipes, (draft) => {
          const [removed] = draft.splice(from, 1);
          draft.splice(to, 0, removed);
        })
      );
    };

    return (
      <Table
        ariaLabel={ariaLabel}
        rowHeight={tableConstants.rowHeight}
        columns={columns}
        rows={
          recipes.map((recipe, index) => ({
            ...recipe,
            order: index + 1,
          })) satisfies HomeFeedCollectionRecipeRow[]
        }
        loading={isLoading}
        page={page}
        rowsPerPage={rowsPerPage}
        rowCount={rowCount}
        selectedRows={selectedRecipes.map(({ id }) => id)}
        components={{
          loadingOverlay: LoadingOverlay,
          noRowsOverlay: components?.NoRecipes,
        }}
        componentsProps={{
          row: {
            onMouseEnter: (event: SyntheticEvent<HTMLElement>) => {
              event.preventDefault();
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              setHoveredRowId(event.currentTarget.dataset.id!);
            },
            onMouseLeave: (event: SyntheticEvent<HTMLElement>) => {
              event.preventDefault();
              setHoveredRowId(null);
            },
          },
        }}
        checkboxSelection={!hideCheckboxSelection}
        isCheckboxSelectionDisabled={isCheckboxSelectionDisabled}
        hidePagination={!recipes.length || hidePagination}
        onSelectedRowsChange={onSelectedRecipesChange}
        onPaginationChange={onPaginationChange}
        isRowReorderEnabled={!!onRecipesReordered}
        onRowReorder={handleRowReorder}
        rowDraggingLabelField={({ order, name }) => `${order} ${name}`}
      />
    );
  });

export interface HomeFeedCollectionRecipeRow extends TableRow {
  order: number;
  name: string;
  author: AppRecipeAuthor;
  ingredientsCount: number;
  stepsCount: number;
  totalTime?: string;
  id: ApiRcpRecipeId;
  state: ApiRcpRecipeState;
}

export const LoadingOverlay: FC<TableLoadingOverlayProps> = memo(
  function LoadingOverlay() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '300px',
        }}
      >
        <Spinner size={64} />
      </Box>
    );
  }
);

const DeleteRecipeIcon: FC = memo(function DeleteRecipeIcon() {
  return <DeleteIcon size={20} color={PantryColor.IconDefault} />;
});
