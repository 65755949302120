export const recipeMediaStrings = {
  ariaLabel: {
    skeleton: 'Loading media',
  },
  fields: {
    file: 'Select files or drag and drop',
  },
  messages: {
    fileError:
      'Your image could not be uploaded. Kindly check the error and try again:',
    noFiles: 'Your images/videos will appear here when you upload them',
    retrying: 'Searching for images...',
  },
  image: {
    maxWidth: 640,
    maxHeight: 500,
    alt: 'Hero image',
  },
} as const;
