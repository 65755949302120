import type { AppUnitSystem } from 'types/appUnitSystem';

export enum AppQuantityChangeTriggeredByField {
  Unit = 'unit',
  Amount = 'amount',
}

export interface AppQuantityChangeTriggeredBy {
  system: AppUnitSystem;
  field: AppQuantityChangeTriggeredByField;
}
