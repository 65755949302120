import { PantryColor } from '@dropkitchen/pantry-react';
import { Box } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export interface RecipeTranslationColumnProps {
  children?: ReactNode;
}

export const RecipeTranslationColumn: FC<RecipeTranslationColumnProps> = memo(
  function RecipeTranslationColumn({ children }) {
    return (
      <Box
        sx={(theme) => ({
          px: 4,
          // The first row (first 2 columns) have special margin and padding
          '&:nth-of-type(-n+2)': {
            mb: 6,
            px: 0,
          },
          // All the rows but the first one (every column but the first 2) create the page's left and right borders
          '&:not(:nth-of-type(-n+2))': {
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderColor: PantryColor.BorderDefault,
            pt: 4,
          },
          // The second row (thrid and fourth columns) create the page's border top
          '&:nth-of-type(n+3):nth-of-type(-n+4)': {
            borderTop: '1px solid',
            borderColor: PantryColor.BorderDefault,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            pt: 6,
          },
          // The row (last 2 columns) create the page's border bottom
          '&:nth-last-of-type(-n+2)': {
            borderBottom: '1px solid',
            borderColor: PantryColor.BorderDefault,
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
            pb: 6,
          },
        })}
      >
        {children}
      </Box>
    );
  }
);
