import {
  PantryColor,
  PantryCornerRadius,
  PantryTypography,
} from '@dropkitchen/pantry-react';
import { Box, Grid, Typography } from '@mui/material';
import type { SxProps } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { generateRecipeRoute } from 'app/routes/routesUtils';
import { useAppSelector } from 'app/store/hooks';
import { capabilityFieldConstants } from 'components/CapabilityField/CapabilityField.constants';
import { CapabilitySettingFieldIcon } from 'components/CapabilityField/CapabilitySettingFieldIcon';
import { Card } from 'components/Card/Card';
import type { DraggableContentProps } from 'components/DragAndDrop/Draggable';
import {
  TrackedEventType,
  trackEvent,
} from 'features/eventTracking/eventTracking';
import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import { selectRecipeLocale } from 'features/recipe/recipeSlice';
import {
  CardDeleteButton,
  CardDeleteButtonAlignment,
} from 'features/recipe/shared/CardDeleteButton/CardDeleteButton';
import { RecipeIngredientDetails } from 'features/recipe/shared/RecipeIngredientDetails/RecipeIngredientDetails';
import { recipeIngredientDetailsStrings } from 'features/recipe/shared/RecipeIngredientDetails/RecipeIngredientDetails.constants';
import { RecipeIssue } from 'features/recipe/shared/RecipeIssue/RecipeIssue';
import { RecipeStepTextField } from 'features/recipe/shared/RecipeStepTextField/RecipeStepTextField';
import { RecipeValueInMultipleSystems } from 'features/recipe/shared/RecipeValueInMultipleSystems/RecipeValueInMultipleSystems';
import {
  stepFormShown,
  stepIndexUpdated,
  stepReset,
} from 'features/recipe/steps/form/recipeStepsFormSlice';
import { recipeStepStrings } from 'features/recipe/steps/list/RecipeStep.constants';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import {
  isVentingTimeSettingId,
  isTimeSettingId,
  isSpeedSettingId,
} from 'types/appCapabilitySettings.utils';
import type { AppRecipeStep } from 'types/recipe/appRecipeStep';
import type { AppRecipeStepCapability } from 'types/recipe/appRecipeStepCapability';
import type { AppRecipeStepCapabilitySetting } from 'types/recipe/appRecipeStepCapabilitySetting';
import type { AppRecipeStepIngredient } from 'types/recipe/appRecipeStepIngredient';
import { fromAppTimeToSentence } from 'utils/convertTimes';
import {
  getValueInMultipleSystemsAsString,
  isValueInMultipleSystems,
  isValueInSingleSystem,
} from 'utils/unitSystems';
import type { ValidatorError } from 'utils/validator';

const { titles, labels } = recipeStepStrings;

export enum RecipeStepVariant {
  Default = 'default',
  Condensed = 'condensed',
}

export interface RecipeStepProps extends DraggableContentProps {
  draggable?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
  onChange?: (change: { text: string; errors?: ValidatorError }) => void;
  selected?: boolean;
  step: AppRecipeStep;
  stepIndex: number;
  totalSteps: number;
  sx?: SxProps;
  variant?: RecipeStepVariant;
  recipeId?: ApiRcpRecipeId;
  hasError?: boolean;
}

export const RecipeStep: FC<RecipeStepProps> = memo(function RecipeStep({
  draggable = false,
  dragging,
  onClick,
  onDelete,
  onChange,
  selected,
  step,
  stepIndex,
  totalSteps,
  sx,
  variant = RecipeStepVariant.Default,
  recipeId,
  hasError = false,
}) {
  const locale = useAppSelector(selectRecipeLocale);

  const stepNumber = `STEP ${stepIndex + 1} OF ${totalSteps}`;
  return (
    <Card
      onClick={onClick}
      selected={selected}
      ariaLabel={stepNumber}
      dragging={dragging}
      draggable={draggable}
      topSectionChildren={
        <Box
          sx={(theme) => ({
            maxWidth: onDelete ? `calc(100% - ${theme.spacing(10)})` : '100%',
          })}
        >
          <Typography
            variant={PantryTypography.Overline}
            color={PantryColor.TextMuted}
            sx={{ mb: 1, display: 'block', boxShadow: 'none' }}
          >
            {stepNumber}
          </Typography>
          {onChange ? (
            <RecipeStepTextField
              id={`${step.id}-text-field`}
              hideLabel
              value={step.text}
              onChange={({ value }) => onChange({ text: value })}
              showErrors={hasError}
            />
          ) : (
            <Typography
              variant={PantryTypography.Body1SemiBold}
              color={PantryColor.TextDefault}
            >
              {step.text}
            </Typography>
          )}
        </Box>
      }
      bottomSectionChildren={
        <>
          {variant === RecipeStepVariant.Default &&
            !!step.ingredients?.length && (
              <IngredientsCard ingredients={step.ingredients} locale={locale} />
            )}
          {variant === RecipeStepVariant.Default && !!step.capability && (
            <CapabilityCard
              capability={step.capability}
              hasIncompatibilities={!!step.hasIncompatibilities}
              stepIndex={stepIndex}
              recipeId={recipeId}
              locale={locale}
            />
          )}
        </>
      }
      actionButtonChildren={
        onDelete && (
          <CardDeleteButton
            label={labels.deleteButton}
            alignment={CardDeleteButtonAlignment.Top}
            onDelete={onDelete}
          />
        )
      }
      sx={sx}
    />
  );
});

interface SmartCardProps {
  title: string;
  children: ReactNode;
}
const SmartCard: FC<SmartCardProps> = memo(function SmartCard({
  title,
  children,
}) {
  return (
    <Box
      sx={{
        backgroundColor: PantryColor.SurfaceMuted,
        mt: 2,
        px: 4,
        py: 3,
        borderRadius: PantryCornerRadius.Small,
      }}
    >
      <Typography
        variant={PantryTypography.Body2SemiBold}
        color={PantryColor.FrescoPrimary}
        sx={{
          mb: 2,
        }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
});

interface IngredientsCardProps {
  locale: ApiLocale;
  ingredients: AppRecipeStepIngredient[];
}

const IngredientsCard: FC<IngredientsCardProps> = memo(
  function IngredientsCard({ ingredients, locale }) {
    return (
      <SmartCard title={titles.ingredients}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          {ingredients.map((ingredient) => {
            return (
              <RecipeIngredientDetails
                key={ingredient.ingredientIdx}
                ingredient={ingredient.ingredient}
                quantity={ingredient.quantity}
                locale={locale}
                styles={{
                  container: {
                    gap: 1,
                  },
                }}
                ariaRole="listitem"
                ariaPrefix={
                  recipeIngredientDetailsStrings.ingredientPrefix.usedIngredient
                }
              />
            );
          })}
        </Box>
      </SmartCard>
    );
  }
);

interface CapabilityCardProps {
  capability: AppRecipeStepCapability;
  hasIncompatibilities: boolean;
  stepIndex: number;
  locale: ApiLocale;
  recipeId?: ApiRcpRecipeId;
}
const CapabilityCard: FC<CapabilityCardProps> = memo(function ApplianceCard({
  capability,
  hasIncompatibilities,
  stepIndex,
  recipeId,
  locale,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFixIssueOnClick = () => {
    if (hasIncompatibilities) {
      trackEvent({
        type: TrackedEventType.IncompatibilityStepFixClicked,
        recipeId,
        stepNumber: stepIndex + 1,
        capabilityId: capability.id,
      });
    }
    dispatch(stepReset());
    dispatch(stepIndexUpdated(stepIndex));
    dispatch(stepFormShown(true));
    navigate(
      generateRecipeRoute({
        tab: RecipeTabName.Steps,
        id: recipeId,
        autofocusedFieldId: capabilityFieldConstants.fieldIds.capability,
      })
    );
  };

  const warnings = [...(hasIncompatibilities ? [titles.incompatibility] : [])];

  return (
    <SmartCard title={titles.capabilities}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography
          variant={PantryTypography.Body2SemiBold}
          color={PantryColor.TextDefault}
        >
          {capability.name}
        </Typography>
        {capability.phase && (
          <Typography
            variant={PantryTypography.Body2}
            color={PantryColor.TextDefault}
          >
            {capability.phase.name}
          </Typography>
        )}
      </Box>
      {!!capability.settings?.length && (
        <Grid container spacing={7}>
          {capability.settings.map((setting) => (
            <Grid
              item
              key={setting.id}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <CapabilitySettingFieldIcon
                settingId={setting.id}
                sx={{ color: PantryColor.IconDefault, mr: 1 }}
              />
              {setting.value.type === AppCapabilitySettingType.Numeric &&
              isValueInMultipleSystems(setting.value.value) ? (
                <RecipeValueInMultipleSystems
                  {...getValueInMultipleSystemsAsString(setting.value.value)}
                  locale={locale}
                />
              ) : (
                <Typography
                  variant={PantryTypography.Body2}
                  color={PantryColor.TextDefault}
                >
                  {getSettingForReviewCard(setting)}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      )}
      {!!warnings.length && (
        <RecipeIssue
          warnings={warnings}
          onClick={handleFixIssueOnClick}
          sx={{ mt: 2 }}
        />
      )}
    </SmartCard>
  );
});

const getSettingForReviewCard = (
  setting: AppRecipeStepCapabilitySetting
): string => {
  const { id, name, value } = setting;
  if (value.type === AppCapabilitySettingType.Time) {
    if (isVentingTimeSettingId(id)) {
      return `${name}: ${fromAppTimeToSentence(value.value, true)}`;
    }

    if (isTimeSettingId(id)) {
      return fromAppTimeToSentence(value.value, true);
    }
  }
  if (
    value.type === AppCapabilitySettingType.Numeric &&
    isValueInSingleSystem(value.value)
  ) {
    if (isSpeedSettingId(id)) {
      return `${value.value.amount} Speed`;
    }
    return `${value.value.amount}${value.value.unit.abbreviation || ''}`;
  }

  if (value.type === AppCapabilitySettingType.Nominal) {
    return value.referenceValue.name;
  }

  // Boolean
  return `${name}: ${value.value ? 'Yes' : 'No'}`;
};
