import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { Button } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';

export interface NavigationItemProps {
  children: ReactNode;
  isScrolled: boolean;
  to: string;
}

export const NavigationItem: FC<NavigationItemProps> = memo(
  function NavigationItem({ children, isScrolled, to }) {
    return (
      <Button
        component={NavLink}
        to={to}
        sx={{
          typography: PantryTypography.Body1,
          mr: 6,
          borderRadius: 0.5,
          px: 3,
          py: 1,
          textTransform: 'none',
          height: 'auto',
          color: PantryColor.TextDefault,
          backgroundColor: 'none',
          '&.active': {
            typography: PantryTypography.Body1SemiBold,
            color: isScrolled
              ? PantryColor.TextContrast
              : PantryColor.TextDefault,
            backgroundColor: '#923157',
          },
        }}
      >
        {children}
      </Button>
    );
  }
);
