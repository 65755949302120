import {
  Skeleton,
  SkeletonVariant,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { stylesCenterContent } from 'app/theme';
import {
  recipeTranslationStrings,
  recipeTranslationStyles,
} from 'features/translation/RecipeTranslation.constants';
import { RecipeTranslationColumn as Column } from 'features/translation/RecipeTranslationColumn';
import { RecipeTranslationColumnGrid as ColumnGrid } from 'features/translation/RecipeTranslationColumnGrid';
import { RecipeTranslationDetailsSection as DetailsSection } from 'features/translation/RecipeTranslationDetailsSection';
import { RecipeTranslationHeader as Header } from 'features/translation/RecipeTranslationHeader';
import { RecipeTranslationRow as Row } from 'features/translation/RecipeTranslationRow';

const { labels } = recipeTranslationStrings;

export const RecipeTranslationSkeleton: FC = memo(
  function RecipeTranslationSkeleton() {
    return (
      <>
        <Box
          sx={sxCompose(stylesCenterContent, {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: 0,
            mt: 8,
          })}
        >
          <Content height={52} width={85} />
        </Box>
        <Box
          sx={recipeTranslationStyles.container}
          aria-label={labels.fetching}
        >
          {/* Recipe locale row */}
          <Row>
            <Column>
              <Content height={44} width={200} />
            </Column>
            <Column>
              <Header>
                <Content height={44} width={200} />
                <Content height={44} width={250} />
              </Header>
            </Column>
          </Row>
          {/* Recipe name row */}
          <Row>
            <Column>
              <Content height={28} width={300} />
            </Column>
            <Column>
              <Content height={56} />
            </Column>
          </Row>
          {/* Recipe details row */}
          <Row>
            <Column>
              <DetailsSection>
                <Title />
                <Content height={150} />
                <Content height={150} />
              </DetailsSection>
            </Column>
          </Row>
          <Row>
            <Column>
              <DetailsSection>
                <Title />
                <Content height={150} />
                <Content height={150} />
              </DetailsSection>
            </Column>
          </Row>
          {/* First read-only field row */}
          <Row>
            <Column>
              <ColumnGrid>
                <Content height={40} />
                <Content height={40} />
                <Content height={40} />
              </ColumnGrid>
            </Column>
            <Column>
              <ColumnGrid>
                <Content height={40} />
                <Content height={40} />
                <Content height={40} />
              </ColumnGrid>
            </Column>
          </Row>
          {/* Second read-only field row */}
          <Row>
            <Column>
              <ColumnGrid>
                <Content height={40} />
                <Content height={40} />
              </ColumnGrid>
            </Column>
            <Column>
              <ColumnGrid>
                <Content height={40} />
                <Content height={40} />
              </ColumnGrid>
            </Column>
          </Row>
          {/* Appliances tags row */}
          <Row>
            <Column>
              <Content height={56} />
            </Column>
            <Column>
              <Content height={56} />
            </Column>
          </Row>
          {/* General tags row */}
          <Row>
            <Column>
              <Content height={56} />
            </Column>
            <Column>
              <Content height={56} />
            </Column>
          </Row>
          {/* Ingredients rows */}
          <Row>
            <Column>
              <Title />
              <Content height={100} sx={{ mt: 6 }} />
            </Column>
            <Column>
              <Title />
              <Content height={100} sx={{ mt: 6 }} />
            </Column>
          </Row>
          {/* Steps rows */}
          <Row>
            <Column>
              <Title />
              <Content height={230} sx={{ mt: 6 }} />
            </Column>
            <Column>
              <Title />
              <Content height={230} sx={{ mt: 6 }} />
            </Column>
          </Row>
        </Box>
      </>
    );
  }
);

const Content: FC<{ height: number; width?: number; sx?: SxProps }> = memo(
  function Content({ height, width, sx }) {
    return (
      <Skeleton variant={SkeletonVariant.Small} sx={{ ...sx, height, width }} />
    );
  }
);

const Title: FC = memo(function Title() {
  return (
    <Skeleton
      sx={{ ...recipeTranslationStyles.sectionTitle, height: 24, width: 100 }}
      variant={SkeletonVariant.Small}
    />
  );
});
