import { Box, Grid } from '@mui/material';
import type { FC, ReactElement } from 'react';
import { memo } from 'react';

export interface RecipeBasicInformationFormContainerProps {
  ariaLabel: string;
  components: {
    recipeName: ReactElement;
    description: ReactElement;
    author: ReactElement;
    applianceTags: ReactElement;
    totalTime: ReactElement;
    prepTime: ReactElement;
    cookTime: ReactElement;
    serves: ReactElement;
  };
}

export const RecipeBasicInformationFormContainer: FC<RecipeBasicInformationFormContainerProps> =
  memo(function RecipeBasicInformationFormContainer({ ariaLabel, components }) {
    return (
      <Box aria-label={ariaLabel} component="form" autoComplete="off">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {components.recipeName}
          </Grid>
          <Grid item xs={12}>
            {components.description}
          </Grid>
          <Grid item xs={12}>
            {components.author}
          </Grid>
          <Grid item xs={12}>
            {components.applianceTags}
          </Grid>
          <Grid item xs={12}>
            {components.totalTime}
          </Grid>
          <Grid item sm={6} xs={12}>
            {components.prepTime}
          </Grid>
          <Grid item sm={6} xs={12}>
            {components.cookTime}
          </Grid>
          <Grid item xs={12}>
            {components.serves}
          </Grid>
        </Grid>
      </Box>
    );
  });
