import { ApiLocale } from 'api/types/common/apiLocale';

export const appDisplayCodeByLocale: Record<ApiLocale, string> = {
  [ApiLocale.EnUS]: 'EN-US',
  [ApiLocale.EnGB]: 'EN-GB',
  [ApiLocale.EnAU]: 'EN-AU',
  [ApiLocale.EsES]: 'ES-ES',
  [ApiLocale.ItIT]: 'IT-IT',
  [ApiLocale.DeDE]: 'DE-DE',
  [ApiLocale.FrFR]: 'FR-FR',
  [ApiLocale.PlPL]: 'PL-PL',
  [ApiLocale.CsCZ]: 'CS-CZ',
  [ApiLocale.NlNL]: 'NL-NL',
  [ApiLocale.NbNO]: 'NB-NO',
  [ApiLocale.SvSE]: 'SV-SE',
  [ApiLocale.FiFI]: 'FI-FI',
  [ApiLocale.DaDK]: 'DA-DK',
  [ApiLocale.PtPT]: 'PT-PT',
  [ApiLocale.RuRU]: 'RU-RU',
};
