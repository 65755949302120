import { ApiMimeType } from 'api/types';

export const apiRecipesConstants = {
  recipeV3: {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: ApiMimeType.JsonV3,
    },
  },
} as const;
