import { PantryTypography, PantryColor } from '@dropkitchen/pantry-react';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import sortBy from 'lodash/sortBy';
import type { FC } from 'react';
import { useMemo, memo, useEffect, useState } from 'react';

import type { ApiAplId } from 'api/types/appliance/apiAplId';
import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import { useAutoFocus } from 'app/routes/hooks/useAutoFocus';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { ApplianceIncompatibility } from 'components/ApplianceIncompatibility/ApplianceIncompatibility';
import { AutocompleteWithRetry } from 'components/AutocompleteWithRetry/AutocompleteWithRetry';
import {
  capabilityFieldConstants,
  capabilityFieldStrings,
} from 'components/CapabilityField/CapabilityField.constants';
import { generateCapabilityAttachmentsHint } from 'components/CapabilityField/CapabilityField.utils';
import { CapabilitySettingField } from 'components/CapabilityField/CapabilitySettingField';
import {
  areDependenciesMet,
  generateCapabilitySettingFieldDependsOn,
} from 'components/CapabilityField/CapabilitySettingFieldDependencies';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { disambiguateApplianceCapabilities } from 'features/appliances/appliances.utils';
import {
  appliancesFetchRequested,
  selectApplianceCapabilities,
  selectAppliancesFetchError,
  selectAppliancesFetching,
  selectAppliancesWithCapabilities,
} from 'features/appliances/appliancesSlice';
import {
  selectRecipeApplianceTags,
  selectRecipeLocale,
} from 'features/recipe/recipeSlice';
import {
  selectCapability,
  selectCapabilityType,
  stepCapabilityUpdated,
  stepCapabilityTypeUpdated,
  stepSettingsUpdated,
  stepSettingsDependenciesUpdated,
  selectPhase,
  stepPhaseUpdated,
  selectAppliancesIncompatibilities,
} from 'features/recipe/steps/form/recipeStepsFormSlice';
import {
  generalCapabilitiesFetchRequested,
  selectGeneralCapabilities,
  selectGeneralCapabilitiesFetchError,
  selectGeneralCapabilitiesFetching,
} from 'features/referenceData/generalCapabilities/generalCapabilitiesSlice';
import { disambiguateTerms } from 'features/referenceData/referenceData.utils';
import type { AppCapability } from 'types/appCapability';
import { AppCapabilityType } from 'types/appCapability';
import { AppCapabilityAllowedPhaseId } from 'types/appCapabilityAllowedPhase.constants';
import type { AppCapabilitySettingDefaultValue } from 'types/recipe/appCapabilitySettingDefaultValue';

const { labels, placeholders } = capabilityFieldStrings;
const { fieldIds } = capabilityFieldConstants;

export const CapabilityField: FC = memo(function CapabilityField() {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { isFocused } = useAutoFocus();
  const locale = useAppSelector(selectRecipeLocale);
  const generalCapabilities = useAppSelector(selectGeneralCapabilities(locale));
  const generalCapabilitiesFetchError = useAppSelector(
    selectGeneralCapabilitiesFetchError(locale)
  );
  const applianceCapabilities = useAppSelector(
    selectApplianceCapabilities(locale)
  );
  const appliancesFetchError = useAppSelector(
    selectAppliancesFetchError(locale)
  );
  const incompatibilities = useAppSelector(selectAppliancesIncompatibilities);
  const isFetchingAppliances = useAppSelector(selectAppliancesFetching(locale));
  const isFetchingGeneralCapabilities = useAppSelector(
    selectGeneralCapabilitiesFetching(locale)
  );
  const isFetchingCapabilities =
    isFetchingAppliances !== false || isFetchingGeneralCapabilities !== false;

  const selectedCapabilityType = useAppSelector(selectCapabilityType);
  const selectedCapability = useAppSelector(selectCapability);
  const selectedPhase = useAppSelector(selectPhase);
  const appliancesWithCapabilities = useAppSelector(
    selectAppliancesWithCapabilities(locale)
  );
  const appliancesTags = useAppSelector(selectRecipeApplianceTags);

  const [availableCapabilities, setAvailableCapabilities] = useState<
    AppCapability[]
  >([]);

  const disambiguatedGeneralCapabilities = useMemo(
    () => disambiguateTerms(generalCapabilities || []),
    [generalCapabilities]
  );

  const disambiguatedApplianceCapabilities = useMemo(
    () =>
      applianceCapabilities
        ? disambiguateApplianceCapabilities(applianceCapabilities)
        : {},
    [applianceCapabilities]
  );

  useEffect(() => {
    if (!generalCapabilities?.length && !applianceCapabilities?.length) {
      return;
    }

    const capabilities =
      selectedCapabilityType === AppCapabilityType.AppliancePreset
        ? applianceCapabilities
        : generalCapabilities;
    setAvailableCapabilities(capabilities || []);
  }, [applianceCapabilities, generalCapabilities, selectedCapabilityType]);

  useEffect(() => {
    // Set default value for phase
    if (selectedCapability && !selectedPhase) {
      const defaultPhase =
        selectedCapability.allowedPhases.find(
          ({ id }) => id === AppCapabilityAllowedPhaseId.Execution
        ) ?? null;
      dispatch(stepPhaseUpdated(defaultPhase));
    }

    dispatch(
      stepSettingsDependenciesUpdated(selectedCapability?.allowedSettings || [])
    );
  }, [dispatch, selectedCapability, selectedPhase]);

  const { groupedAvailableCapabilities, capabilitiesFieldGroupByFn } =
    useMemo(() => {
      const capabilityIds = appliancesTags?.flatMap((tag) =>
        appliancesWithCapabilities?.reduce(
          (ids, { referenceTagIds, supportedCapabilities }) => {
            if (!referenceTagIds.includes(tag.id)) {
              return ids;
            }
            Object.entries(supportedCapabilities).forEach(
              ([capabilityId, { referenceCapabilityId }]) => {
                ids.push(capabilityId as ApiEntityId);
                if (referenceCapabilityId) {
                  ids.push(referenceCapabilityId);
                }
              }
            );
            return ids;
          },
          [] as ApiEntityId[]
        )
      );

      if (!capabilityIds?.length) {
        return {
          groupedAvailableCapabilities: availableCapabilities,
          capabilitiesFieldGroupByFn: undefined,
        };
      }

      return {
        groupedAvailableCapabilities: sortBy(availableCapabilities, ({ id }) =>
          capabilityIds.includes(id) ? -1 : 1
        ),
        capabilitiesFieldGroupByFn: (capability: AppCapability) =>
          capabilityIds.includes(capability.id)
            ? labels.suggestedCapabilitiesGroup
            : labels.otherCapabilitiesGroup,
      };
    }, [appliancesTags, appliancesWithCapabilities, availableCapabilities]);

  const refetchCapabilities = () => {
    if (
      selectedCapabilityType === AppCapabilityType.AppliancePreset &&
      appliancesFetchError
    ) {
      dispatch(appliancesFetchRequested({ locale }));
    }
    if (
      selectedCapabilityType === AppCapabilityType.General &&
      generalCapabilitiesFetchError
    ) {
      dispatch(generalCapabilitiesFetchRequested({ locale }));
    }
  };

  const hasCapabilitiesError =
    (selectedCapabilityType === AppCapabilityType.AppliancePreset &&
      !!appliancesFetchError) ||
    (selectedCapabilityType === AppCapabilityType.General &&
      !!generalCapabilitiesFetchError);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id={fieldIds.capabilityType}>
            {labels.capabilityTypeRadio.group}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby={fieldIds.capabilityType}
            name="capability-type"
            value={selectedCapabilityType}
            onChange={(event) => {
              dispatch(
                stepCapabilityTypeUpdated(
                  event.target.value as AppCapabilityType
                )
              );
              dispatch(stepCapabilityUpdated(null));
              dispatch(stepSettingsUpdated([]));
            }}
          >
            <FormControlLabel
              value={AppCapabilityType.General}
              control={<Radio />}
              label={labels.capabilityTypeRadio.general}
            />
            <FormControlLabel
              value={AppCapabilityType.AppliancePreset}
              control={<Radio />}
              label={labels.capabilityTypeRadio.appliancePreset}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <AutocompleteWithRetry
          id={fieldIds.capability}
          value={selectedCapability}
          onChange={(_, selectedOption) => {
            dispatch(stepCapabilityUpdated(selectedOption));
            const settingsWithDefaultValues = selectedOption
              ? selectedOption.allowedSettings
                  .filter(({ defaultValue }) => defaultValue)
                  .map(({ id, name, defaultValue, dependsOnSetting }) => ({
                    id,
                    name,
                    value: defaultValue as AppCapabilitySettingDefaultValue,
                    dependsOnSetting,
                  }))
              : [];

            const selectedSettings = settingsWithDefaultValues.filter(
              ({ dependsOnSetting }) => {
                if (!dependsOnSetting) {
                  return true;
                }
                const dependencies =
                  generateCapabilitySettingFieldDependsOn(dependsOnSetting);
                return (
                  !dependencies ||
                  areDependenciesMet(dependencies, settingsWithDefaultValues)
                );
              }
            );
            dispatch(stepSettingsUpdated(selectedSettings));
          }}
          options={groupedAvailableCapabilities}
          loading={isFetchingCapabilities}
          groupBy={capabilitiesFieldGroupByFn}
          getOptionLabel={(option) =>
            disambiguatedGeneralCapabilities[option.id as ApiRefId] ||
            disambiguatedApplianceCapabilities[option.id as ApiAplId] ||
            option.name
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              autoFocus={isFocused(fieldIds.capability)}
              placeholder={
                selectedCapabilityType === AppCapabilityType.General
                  ? placeholders.generalCapabilityField
                  : placeholders.presetCapabilityField
              }
              label={
                selectedCapabilityType === AppCapabilityType.General
                  ? labels.generalCapabilityField
                  : labels.presetCapabilityField
              }
              helperText={
                !!selectedCapability?.attachments?.length && (
                  <HelperText
                    severity={HelperTextSeverity.Neutral}
                    message={generateCapabilityAttachmentsHint(
                      selectedCapability.attachments
                    )}
                  />
                )
              }
            />
          )}
          onRetry={refetchCapabilities}
          hasError={hasCapabilitiesError}
        />
      </Grid>
      {selectedCapability && (
        <>
          <Grid item xs={12}>
            <Autocomplete
              id={fieldIds.phase}
              value={selectedPhase}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, phase) => dispatch(stepPhaseUpdated(phase))}
              loading={isFetchingCapabilities}
              options={selectedCapability.allowedPhases}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={labels.phaseField}
                  placeholder={placeholders.phaseField}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  variant={PantryTypography.Body1}
                  sx={{
                    pb: 2,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    color: PantryColor.FrescoPrimary,
                  }}
                >
                  {labels.settingsSectionTitle}
                </Typography>
              </Grid>
              {selectedCapability.allowedSettings.map((setting) => (
                <CapabilitySettingField
                  setting={setting}
                  key={`${selectedCapability.id}-${setting.id}`}
                />
              ))}
            </Grid>
          </Grid>
          {!!Object.values(incompatibilities).length && (
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography
                    variant={PantryTypography.Body1}
                    sx={{
                      pb: 2,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      color: PantryColor.FrescoPrimary,
                    }}
                  >
                    {labels.incompatibilitiesSectionTitle}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {Object.values(incompatibilities).map((incompatibility) => (
                    <ApplianceIncompatibility
                      key={incompatibility.applianceId}
                      applianceId={incompatibility.applianceId}
                      incompatibilities={incompatibility.incompatibilities}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
});
