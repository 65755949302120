import { PantryTypography } from '@dropkitchen/pantry-react';
import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import { publishConfirmationDialogStrings } from 'features/recipe/PublishConfirmationDialog/PublishConfirmationDialog.constants';
import {
  recipePublishCanceled,
  recipePublishConfirmed,
  selectRecipeIncompatibilitiesCount,
  selectRecipeUnusedIngredientsCount,
  selectRecipePublishConfirmationRequired,
  selectRecipeIngredients,
  selectRecipeSteps,
} from 'features/recipe/recipeSlice';

const {
  title,
  body,
  unusedIngredients,
  incompatibilities,
  noIngredients,
  noSteps,
  noIngredientsOrSteps,
  confirmButton,
  cancelButton,
} = publishConfirmationDialogStrings;

export const PublishConfirmationDialog: FC = memo(
  function PublishConfirmationDialog() {
    const dispatch = useAppDispatch();
    const isConfirmationRequired = useAppSelector(
      selectRecipePublishConfirmationRequired
    );
    const unusedIngredientsCount = useAppSelector(
      selectRecipeUnusedIngredientsCount
    );
    const incompatibilitiesCount = useAppSelector(
      selectRecipeIncompatibilitiesCount
    );
    const ingredientsCount = useAppSelector(selectRecipeIngredients).length;
    const stepsCount = useAppSelector(selectRecipeSteps).length;
    const issues = [
      !!unusedIngredientsCount && unusedIngredients,
      !!incompatibilitiesCount && incompatibilities,
      !ingredientsCount && !!stepsCount && noIngredients,
      !!ingredientsCount && !stepsCount && noSteps,
      !ingredientsCount && !stepsCount && noIngredientsOrSteps,
    ];

    return (
      <ConfirmationDialog
        isOpen={isConfirmationRequired}
        text={{
          title,
          body: getBody(issues),
          confirmButton,
          cancelButton,
        }}
        onClose={(hasConfirmed) =>
          dispatch(
            hasConfirmed ? recipePublishConfirmed() : recipePublishCanceled()
          )
        }
      />
    );
  }
);

const getBody = (issues: (string | boolean)[]) => {
  return (
    <>
      <Typography variant={PantryTypography.Body1}>{body}</Typography>
      <Box component="ul" sx={{ ml: 4 }}>
        {issues.map(
          (issue, key) =>
            !!issue && (
              // eslint-disable-next-line react/no-array-index-key
              <li key={key}>
                <Typography variant={PantryTypography.Body1} sx={{ mt: 4 }}>
                  {issue}
                </Typography>
              </li>
            )
        )}
      </Box>
    </>
  );
};
