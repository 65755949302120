import { TabContext } from '@mui/lab';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { RecipeType } from 'app/routes/constants';
import { generateRecipeListRoute } from 'app/routes/routesUtils';
import { useAppDispatch } from 'app/store/hooks';
import { ListTab } from 'components/ListTabs/ListTab';
import { ListTabPanel } from 'components/ListTabs/ListTabPanel';
import { ListTabs } from 'components/ListTabs/ListTabs';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { ListLayout } from 'features/layout/ListLayout';
import { recipesPageStrings } from 'features/recipes/RecipesPage.constants';
import { AddRecipe } from 'features/recipes/addRecipe/AddRecipe';
import { RecipesList } from 'features/recipes/list/RecipesList';
import { recipeTypeChanged } from 'features/recipes/recipesSlice';
import { AppFeature } from 'types/appFeature';

const { titles, tabs } = recipesPageStrings;

export interface RecipesPageProps {
  recipeType: RecipeType;
}

export const RecipesPage: FC<RecipesPageProps> = memo(function RecipesPage({
  recipeType,
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(recipeTypeChanged(recipeType));
  }, [dispatch, recipeType]);

  const handleTabClick = (type: RecipeType) =>
    navigate(generateRecipeListRoute({ type }));

  return (
    <ListLayout
      title={titles.main}
      actionComponent={
        <Box sx={{ display: 'flex', gap: 4 }}>
          <AddRecipe />
        </Box>
      }
    >
      <ToggleableFeature
        requires={AppFeature.TranslationManagement}
        components={{
          whenDisabled: <RecipesList />,
        }}
      >
        <>
          <ListTabs variant="standard" value={recipeType}>
            <ListTab<RecipeType>
              label={tabs[RecipeType.Core]}
              value={RecipeType.Core}
              onClick={handleTabClick}
            />
            <ListTab<RecipeType>
              label={tabs[RecipeType.Translation]}
              value={RecipeType.Translation}
              onClick={handleTabClick}
            />
          </ListTabs>
          <TabContext value={recipeType}>
            <ListTabPanel value={RecipeType.Core}>
              <RecipesList />
            </ListTabPanel>
            <ListTabPanel value={RecipeType.Translation}>
              <RecipesList />
            </ListTabPanel>
          </TabContext>
        </>
      </ToggleableFeature>
    </ListLayout>
  );
});
