import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ApiIngredient } from 'api/types/common/apiIngredient';
import type { ApiIngredientPreparation } from 'api/types/common/apiIngredientPreparation';
import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import type { RootState } from 'app/store/rootReducer';
import type { ValueInMultipleSystemsValue } from 'components/ValueInMultipleSystemsField/ValueInMultipleSystemsField';
import { authSignOutFinished } from 'features/auth/authSlice';
import { getIngredientAmountError } from 'features/recipe/ingredients/form/recipeIngredientForm.utils';
import type { AppRecipeIngredientsUsage } from 'features/recipe/recipeSlice';
import {
  recipeDiscardChanges,
  recipeFetchRequested,
  recipeFromScratchRequested,
  recipeGetFromTextRequested,
  recipeGetFromUrlRequested,
  recipeReset,
} from 'features/recipe/recipeSlice';
import type {
  AppFormValueWithUnit,
  AppFormValueWithUnitComplete,
} from 'types/appFormValueWithUnit';
import { AppUnitSystem } from 'types/appUnitSystem';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import type { AppRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';
import { isValueInSingleSystem } from 'utils/unitSystems';

export enum RecipeIngredientFormErrorField {
  Amount = 'amount',
  MetricAmount = 'metricAmount',
  UsCustomaryAmount = 'usCustomaryAmount',
}

export type RecipeIngredientFormStateErrors = Partial<
  Record<RecipeIngredientFormErrorField, string>
>;

export interface RecipeIngredientFormState {
  ingredient: ApiIngredient | null;
  metric: AppFormValueWithUnit;
  usCustomary: AppFormValueWithUnit;
  amount: string;
  unit: ApiQuantityUnit | null;
  ingredientPreparations: ApiIngredientPreparation[];
  index: number | null;
  isUnsaved: boolean;
  isShowing: boolean;
  errors: RecipeIngredientFormStateErrors;
  shouldTriggerAutoconversion: boolean;
  isAutoConversionEnabled: boolean;
}

export const initialState: RecipeIngredientFormState = {
  ingredient: null,
  metric: { amount: '', unit: null },
  usCustomary: { amount: '', unit: null },
  amount: '',
  unit: null,
  ingredientPreparations: [],
  index: null,
  isUnsaved: false,
  isShowing: true,
  errors: {},
  shouldTriggerAutoconversion: false,
  isAutoConversionEnabled: true,
};

const resetStateActions = [
  authSignOutFinished.type,
  recipeReset.type,
  recipeFetchRequested.type,
  recipeFromScratchRequested.type,
  recipeGetFromTextRequested.type,
  recipeGetFromUrlRequested.type,
];
const shouldResetState = (action: Action<string>) =>
  resetStateActions.includes(action.type);

export const recipeIngredientFormSlice = createSlice({
  name: 'recipeIngredientFormSlice',
  initialState,
  reducers: {
    ingredientFormPopulated(
      state,
      {
        payload: { recipeIngredient, ingredient, usage },
      }: PayloadAction<{
        recipeIngredient: AppRecipeIngredient;
        ingredient: ApiIngredient;
        usage: AppRecipeIngredientsUsage | undefined;
      }>
    ) {
      const { quantity, preparations = [] } = recipeIngredient;
      if (isValueInSingleSystem(quantity)) {
        const amount = quantity.amount
          ? `${quantity.amount}`
          : initialState.amount;
        state.amount = amount;
        state.unit = quantity.unit;
        updateFieldError({
          field: RecipeIngredientFormErrorField.Amount,
          error: getIngredientAmountError({
            amount,
            usedQuantity:
              usage && isValueInSingleSystem(usage?.used.quantity)
                ? usage.used.quantity
                : undefined,
          }),
          errors: state.errors,
        });
        state.metric = initialState.metric;
        state.usCustomary = initialState.usCustomary;
      } else {
        state.metric = {
          ...quantity.metric,
          amount: `${quantity.metric.amount}`,
        };
        state.usCustomary = {
          ...quantity.usCustomary,
          amount: `${quantity.usCustomary.amount}`,
        };
        state.amount = initialState.amount;
        state.unit = initialState.unit;
        updateFieldError({
          field: RecipeIngredientFormErrorField.MetricAmount,
          error: getIngredientAmountError({
            amount: `${quantity.metric.amount ?? ''}`,
            usedQuantity:
              usage && !isValueInSingleSystem(usage?.used.quantity)
                ? usage.used.quantity.metric
                : undefined,
          }),
          errors: state.errors,
        });
        updateFieldError({
          field: RecipeIngredientFormErrorField.UsCustomaryAmount,
          error: getIngredientAmountError({
            amount: `${quantity.usCustomary.amount ?? ''}`,
            usedQuantity:
              usage && !isValueInSingleSystem(usage?.used.quantity)
                ? usage.used.quantity.usCustomary
                : undefined,
          }),
          errors: state.errors,
        });
      }
      state.ingredientPreparations = preparations;
      state.ingredient = ingredient;
    },
    ingredientIndexUpdated(
      state,
      { payload: index }: PayloadAction<number | null>
    ) {
      state.index = index;
    },
    ingredientUpdated(
      state,
      { payload: ingredient }: PayloadAction<ApiIngredient | null>
    ) {
      state.ingredient = ingredient;
      state.amount = initialState.amount;
      state.unit = initialState.unit;
      state.metric = initialState.metric;
      state.usCustomary = initialState.usCustomary;
      state.ingredientPreparations = initialState.ingredientPreparations;
      state.errors = initialState.errors;
    },
    ingredientAmountUpdated(
      state,
      {
        payload: { amount, usage },
      }: PayloadAction<{
        amount: string;
        usage: AppRecipeIngredientsUsage | undefined;
      }>
    ) {
      state.amount = amount;
      updateFieldError({
        field: RecipeIngredientFormErrorField.Amount,
        error: getIngredientAmountError({
          amount,
          usedQuantity:
            usage && isValueInSingleSystem(usage.used.quantity)
              ? usage.used.quantity
              : undefined,
        }),
        errors: state.errors,
      });
    },
    ingredientUnitUpdated(
      state,
      {
        payload: { unit, system },
      }: PayloadAction<{
        unit: ApiQuantityUnit | null;
        system: AppUnitSystem | undefined;
      }>
    ) {
      if (!system) {
        state.unit = unit;
        return;
      }
      if (system === AppUnitSystem.Metric) {
        state.metric = { amount: state.amount, unit };
      }
      if (system === AppUnitSystem.UsCustomary) {
        state.usCustomary = { amount: state.amount, unit };
      }
      delete state.errors[RecipeIngredientFormErrorField.Amount];
      state.isAutoConversionEnabled = true;
      state.shouldTriggerAutoconversion = true;
      state.unit = initialState.unit;
      state.amount = initialState.amount;
    },
    ingredientQuantityMultipleSystemsUpdated(
      state,
      {
        payload: {
          metric,
          usCustomary,
          isMetricUnit,
          isUsCustomaryUnit,
          usage,
        },
      }: PayloadAction<
        ValueInMultipleSystemsValue & {
          isMetricUnit: boolean;
          isUsCustomaryUnit: boolean;
          usage: AppRecipeIngredientsUsage | undefined;
        }
      >
    ) {
      state.shouldTriggerAutoconversion = false;
      if (!isMetricUnit || !isUsCustomaryUnit) {
        state.metric = initialState.metric;
        state.usCustomary = initialState.usCustomary;
        state.unit = !isMetricUnit ? metric.unit : usCustomary.unit;
        const amount = !isMetricUnit
          ? `${metric.amount ?? ''}`
          : `${usCustomary.amount ?? ''}`;
        state.amount = amount;
        const usedQuantity = ((): AppRecipeValueWithUnit | undefined => {
          if (!usage) {
            return undefined;
          }
          if (isValueInSingleSystem(usage?.used.quantity)) {
            return usage.used.quantity;
          }
          return !isMetricUnit
            ? usage.used.quantity.metric
            : usage.used.quantity.usCustomary;
        })();
        updateFieldError({
          field: RecipeIngredientFormErrorField.Amount,
          error: getIngredientAmountError({
            amount,
            usedQuantity,
          }),
          errors: state.errors,
        });
        delete state.errors[RecipeIngredientFormErrorField.MetricAmount];
        delete state.errors[RecipeIngredientFormErrorField.UsCustomaryAmount];
        return;
      }

      state.metric = metric;
      state.usCustomary = usCustomary;
      updateFieldError({
        field: RecipeIngredientFormErrorField.MetricAmount,
        error: getIngredientAmountError({
          amount: metric.amount,
          usedQuantity:
            usage && !isValueInSingleSystem(usage?.used.quantity)
              ? usage.used.quantity.metric
              : undefined,
        }),
        errors: state.errors,
      });
      updateFieldError({
        field: RecipeIngredientFormErrorField.UsCustomaryAmount,
        error: getIngredientAmountError({
          amount: usCustomary.amount,
          usedQuantity:
            usage && !isValueInSingleSystem(usage?.used.quantity)
              ? usage.used.quantity.usCustomary
              : undefined,
        }),
        errors: state.errors,
      });
    },
    ingredientPreparationsUpdated(
      state,
      { payload: preparations }: PayloadAction<ApiIngredientPreparation[]>
    ) {
      state.ingredientPreparations = preparations;
      state.shouldTriggerAutoconversion = true;
    },
    ingredientPreparationMoved(
      state,
      { payload: { from, to } }: PayloadAction<{ from: number; to: number }>
    ) {
      const preparationToMove = state.ingredientPreparations.splice(from, 1);
      state.ingredientPreparations.splice(to, 0, preparationToMove[0]);
    },
    ingredientReset(state) {
      // Do not reset whether the form is shown or not
      return { ...initialState, isShowing: state.isShowing };
    },
    ingredientFormShown(state, { payload: isShowing }: PayloadAction<boolean>) {
      if (!isShowing) {
        return { ...initialState, isShowing };
      }
      state.isShowing = isShowing;
      return state;
    },
    ingredientHasChanged(
      state,
      { payload: { hasChanged } }: PayloadAction<{ hasChanged: boolean }>
    ) {
      state.isUnsaved = hasChanged;
    },
    ingredientAutoConversionToggled(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.isAutoConversionEnabled = !payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(recipeDiscardChanges, () => initialState)
      .addMatcher(shouldResetState, () => initialState);
  },
});

export const {
  reducer: recipeIngredientFormReducer,
  actions: {
    ingredientFormPopulated,
    ingredientAmountUpdated,
    ingredientUpdated,
    ingredientPreparationsUpdated,
    ingredientPreparationMoved,
    ingredientIndexUpdated,
    ingredientUnitUpdated,
    ingredientQuantityMultipleSystemsUpdated,
    ingredientReset,
    ingredientFormShown,
    ingredientHasChanged,
    ingredientAutoConversionToggled,
  },
} = recipeIngredientFormSlice;

const selectState = (state: RootState) => state.recipeIngredientForm;

export const selectIndex = (state: RootState) => selectState(state).index;

export const selectIngredient = (state: RootState) =>
  selectState(state).ingredient;

export const selectAmount = (state: RootState) => selectState(state).amount;

export const selectUnit = (state: RootState) => selectState(state).unit;

export const selectMetricValueWithUnit = (state: RootState) =>
  selectState(state).metric;

export const selectUsCustomaryValueWithUnit = (state: RootState) =>
  selectState(state).usCustomary;

export const selectPreparations = (state: RootState) =>
  selectState(state).ingredientPreparations;

export const selectIsIngredientUnsaved = (state: RootState) =>
  selectState(state).isUnsaved;

export const selectIsFormShowing = (state: RootState) =>
  selectState(state).isShowing;

export const selectShouldTriggerAutoconversion = (state: RootState) =>
  selectState(state).shouldTriggerAutoconversion;

export const selectIsAutoConversionEnabled = (state: RootState) =>
  selectState(state).isAutoConversionEnabled;

export const selectErrors = (state: RootState) => selectState(state).errors;

export interface UpdateFieldErrorParams {
  field: keyof RecipeIngredientFormStateErrors;
  error: string | null;
  errors: RecipeIngredientFormStateErrors;
}
export const updateFieldError = ({
  field,
  error,
  errors,
}: UpdateFieldErrorParams) => {
  if (error) {
    errors[field] = error;
  } else {
    delete errors[field];
  }
};

export const isFormQuantityComplete = (
  quantity: AppFormValueWithUnit
): quantity is AppFormValueWithUnitComplete =>
  !!quantity.amount && quantity.unit !== null;
