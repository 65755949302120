import { PantryColor } from '@dropkitchen/pantry-react';
import type { TabsProps } from '@mui/material';
import { Tabs } from '@mui/material';
import type { ReactNode } from 'react';

interface ListTabsProps<TValue> extends TabsProps {
  children: ReactNode[];
  value: TValue;
}

export function ListTabs<TValue>({
  value,
  children,
  ...props
}: ListTabsProps<TValue>) {
  return (
    <Tabs
      value={value}
      sx={{
        borderBottom: '1px solid',
        borderColor: PantryColor.BorderDefault,
      }}
      {...props}
    >
      {children}
    </Tabs>
  );
}
