import type { DragStart, DragUpdate } from '@hello-pangea/dnd';
import { useState } from 'react';

export interface PlaceholderProps {
  clientY?: number;
  clientX?: number;
  clientHeight?: number;
  clientWidth?: number;
}

const getDraggedDom = (draggableId: string) => {
  const queryAttr = 'data-rfd-drag-handle-draggable-id';
  const domQuery = `[${queryAttr}='${draggableId}']`;
  return document.querySelector(domQuery);
};

const getPreviousSiblingsHeight = (previousSiblings: Element[]) =>
  previousSiblings.reduce((total, currentValue) => {
    const style = window.getComputedStyle(currentValue);
    const marginBottom = parseFloat(style.marginBottom);
    return total + currentValue.clientHeight + marginBottom;
  }, 0);

export const getVerticalPosition = (
  paddingTopParent: string,
  previousSiblings: Element[]
) => parseFloat(paddingTopParent) + getPreviousSiblingsHeight(previousSiblings);

export const useDragPlaceholder = () => {
  const [placeholderProps, setPlaceholderProps] = useState<PlaceholderProps>(
    {}
  );

  const handleDragStart = (event: DragStart) => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth, parentElement } = draggedDOM;

    if (!parentElement) {
      return;
    }

    const sourceIndex = event.source.index;

    const clientY = getVerticalPosition(
      window.getComputedStyle(parentElement).paddingTop,
      [...parentElement.children].slice(0, sourceIndex)
    );

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(parentElement).paddingLeft),
    });
  };

  const handleDragUpdate = (event: DragUpdate) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth, parentElement } = draggedDOM;

    if (!parentElement) {
      return;
    }

    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = [...parentElement.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY = getVerticalPosition(
      window.getComputedStyle(parentElement).paddingTop,
      updatedArray.slice(0, destinationIndex)
    );

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(parentElement).paddingLeft),
    });
  };

  return {
    handleDragStart,
    handleDragUpdate,
    dragPlaceholderProps: placeholderProps,
    setDragPlaceholderProps: setPlaceholderProps,
  };
};
