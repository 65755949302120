import { createApiFn, fetchJson } from 'api/fetchJson';
import { apiGeneralCapabilitiesUrl } from 'api/paths';
import type { ApiList, ApiRequestFn } from 'api/types';
import { HttpMethod } from 'api/types';
import type { ApiRefCapability } from 'api/types/referenceData/apiRefCapability';
import type { ApiRefLocalizedDataRequest } from 'api/types/referenceData/apiRefLocalizedDataRequest';
import { appConfig } from 'config/config';

export const getGeneralCapabilities = async ({
  locale,
  from,
}: ApiRefLocalizedDataRequest) =>
  fetchJson<ApiList<ApiRefCapability>>({
    httpMethod: HttpMethod.Post,
    url: `${apiGeneralCapabilitiesUrl}?size=${appConfig.referenceDataApiPageSize()}&from=${from}`,
    body: { locale },
  });

export const apiGetGeneralCapabilities: ApiRequestFn<
  ApiList<ApiRefCapability>,
  ApiRefLocalizedDataRequest
> = createApiFn(getGeneralCapabilities);
