import type { PayloadAction } from '@reduxjs/toolkit';
import { select, put, takeEvery, call } from 'redux-saga/effects';

import { createApiRequestSaga } from 'api/createApiRequestSaga';
import { apiGetGeneralCapabilities } from 'api/generalCapabilities';
import type { ApiResponse } from 'api/types';
import type { ApiRefCapability } from 'api/types/referenceData/apiRefCapability';
import {
  generalCapabilitiesFetchFailed,
  generalCapabilitiesFetching,
  generalCapabilitiesFetchRequested,
  generalCapabilitiesFetchSucceed,
  selectShouldFetchGeneralCapabilities,
} from 'features/referenceData/generalCapabilities/generalCapabilitiesSlice';
import { fetchItemsRecursively } from 'features/referenceData/referenceData.utils';
import { fromApiRefCapability } from 'types/appCapability';
import type { AppLocalizedFetchActionPayload } from 'types/appLocalizedFetchActionPayload';

export const apiFetchGeneralCapabilitiesSaga = createApiRequestSaga(
  apiGetGeneralCapabilities
);

export function* fetchGeneralCapabilities({
  payload: { locale },
}: PayloadAction<AppLocalizedFetchActionPayload>) {
  const shouldFetch = (yield select(
    selectShouldFetchGeneralCapabilities(locale)
  )) as boolean;
  if (!shouldFetch) {
    return;
  }

  yield put(generalCapabilitiesFetching({ locale }));

  const response = (yield call(fetchItemsRecursively, {
    locale,
    fetchSaga: apiFetchGeneralCapabilitiesSaga,
  })) as ApiResponse<ApiRefCapability[]>;

  if (!response.ok) {
    const { message } = response.details;
    yield put(generalCapabilitiesFetchFailed({ locale, data: message }));
    return;
  }
  yield put(
    generalCapabilitiesFetchSucceed({
      locale,
      data: response.data.map(fromApiRefCapability),
    })
  );
}

function* generalCapabilitiesFetchWatcher() {
  yield takeEvery(generalCapabilitiesFetchRequested, fetchGeneralCapabilities);
}

export const generalCapabilitiesSagas = [generalCapabilitiesFetchWatcher];
