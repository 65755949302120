import type { GridFeatureMode } from '@mui/x-data-grid-pro';

export const tableDefaults = {
  rowsPerPage: 20,
  rowsPerPageOptions: [10, 20, 40, 80],
  noRowsMinHeight: 350, // Used if we want to customize the NoRowsOverlay
  paginationMode: 'server' as GridFeatureMode,
  checkboxSelection: false,
  isCheckboxSelectionDisabled: false,
  firstPage: 0,
  ariaLabels: {
    firstPage: 'first page',
    previousPage: 'previous page',
    nextPage: 'next page',
    lastPage: 'last page',
    /** Aria label for checkboxes are autogenerated by MUI. All of the rows have the same "Select row" label */
    selectRow: 'Select row',
  },
};
