import { useRef, useEffect } from 'react';

/**
 * Stores scroll position under `key` value and restores it back when `key` value changes back to stored one
 */
export function usePreserveScrollPosition(key: string) {
  const scrollPositions = useRef({} as Record<string, number>);

  useEffect(() => {
    window.scrollTo({ top: scrollPositions.current[key] || 0 });

    const scrollHandler = () => {
      scrollPositions.current = {
        ...scrollPositions.current,
        [key]: window.scrollY,
      };
    };

    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [key]);
}
