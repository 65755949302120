import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { Box, Link as MuiLink, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { ImageFit } from 'components/Image/Image';
import { RecipeImage } from 'features/recipe/shared/RecipeImage/RecipeImage';
import { recipesListConstants } from 'features/recipes/list/RecipesList.constants';
import { RecipesListPlaceholderImage } from 'features/recipes/list/RecipesListPlaceholderImage';
import { generateRecipeHeroImageSource } from 'utils/image';

export interface RecipesListMainInfoCellProps {
  id: ApiRcpRecipeId;
  name: string;
  author: string;
  to: {
    href: string;
    target?: '_blank' | '_self';
  };
}

export const RecipesListMainInfoCell: FC<RecipesListMainInfoCellProps> = memo(
  function RecipesListMainInfoCell({
    id,
    name,
    author,
    to: { href, target = '_self' },
  }) {
    const { width, height } = recipesListConstants.image;
    /**
     * Request double the required size for the image so it has some room to be
     * adjusted to cover its container without loosing quality due to stretching.
     */
    const maxStretchingRatio = 2;
    const { src } = generateRecipeHeroImageSource({
      recipeId: id,
      width: width * maxStretchingRatio,
      height: height * maxStretchingRatio,
    });

    return (
      <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <MuiLink component={Link} to={href} target={target} rel="noreferrer">
          <RecipeImage
            recipeId={id}
            src={src}
            width={`${width}px`}
            height={`${height}px`}
            alt={name}
            bypassCache
            boxShadow="none"
            fit={ImageFit.Cover}
            error={{
              component: (
                <RecipesListPlaceholderImage width={width} height={height} />
              ),
            }}
          />
        </MuiLink>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <MuiLink
            component={Link}
            to={href}
            target={target}
            rel="noreferrer"
            sx={{ color: PantryColor.TextDefault }}
          >
            <Typography variant={PantryTypography.Body1SemiBold}>
              {name}
            </Typography>
          </MuiLink>
          <Typography
            variant={PantryTypography.Body2}
            sx={{ color: PantryColor.TextSubtle }}
          >
            by&nbsp;
            <MuiLink
              component={Link}
              to={href}
              target={target}
              rel="noreferrer"
              sx={{ color: PantryColor.TextDefault }}
            >
              {author}
            </MuiLink>
          </Typography>
        </Box>
      </Box>
    );
  }
);
