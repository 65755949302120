import upperFirst from 'lodash/upperFirst';

import { ApiCapabilitySettingType } from 'api/types/common/apiCapabilitySettingType';
import type { ApiRcpRecipeStepCapabilitySetting } from 'api/types/recipe/apiRcpRecipeStepCapabilitySetting';
import type { ApiRcpRecipeValueWithUnit } from 'api/types/recipe/apiRcpRecipeValueWithUnit';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import { isTimeSettingId } from 'types/appCapabilitySettings.utils';
import type { AppRecipeStepCapabilitySettingValueBoolean } from 'types/recipe/appRecipeStepCapabilitySettingValueBoolean';
import type { AppRecipeStepCapabilitySettingValueNominal } from 'types/recipe/appRecipeStepCapabilitySettingValueNominal';
import type { AppRecipeStepCapabilitySettingValueNumeric } from 'types/recipe/appRecipeStepCapabilitySettingValueNumeric';
import type { AppRecipeStepCapabilitySettingValueTime } from 'types/recipe/appRecipeStepCapabilitySettingValueTime';
import { fromApiRcpRecipeValueInMultipleSystems } from 'types/recipe/appRecipeValueInMultipleSystems';
import { fromApiRcpRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';
import { fromSecondsToAppTime } from 'utils/convertTimes';
import { isValueInMultipleSystems } from 'utils/unitSystems';

export const errors = {
  singleUnitValue: 'Single unit value not supported for numeric settings',
};

export type AppRecipeStepCapabilitySettingValue =
  | AppRecipeStepCapabilitySettingValueBoolean
  | AppRecipeStepCapabilitySettingValueNumeric
  | AppRecipeStepCapabilitySettingValueNominal
  | AppRecipeStepCapabilitySettingValueTime;

export function fromApiRcpRecipeStepCapabilitySettingValue({
  referenceSetting,
  value,
}: ApiRcpRecipeStepCapabilitySetting): AppRecipeStepCapabilitySettingValue {
  if (value.type === ApiCapabilitySettingType.Nominal) {
    const { id, name } = value.referenceValue;
    return {
      type: AppCapabilitySettingType.Nominal,
      referenceValue: { id, name: upperFirst(name) },
    };
  }

  if (value.type === ApiCapabilitySettingType.Numeric) {
    if (isTimeSettingId(referenceSetting.id)) {
      const timeValue = value.value as ApiRcpRecipeValueWithUnit;
      return {
        type: AppCapabilitySettingType.Time,
        value: fromSecondsToAppTime(timeValue.amount ?? 0, {
          removeZeros: true,
        }),
        unit: timeValue.referenceUnit,
      };
    }

    return {
      type: AppCapabilitySettingType.Numeric,
      value: isValueInMultipleSystems(value.value)
        ? fromApiRcpRecipeValueInMultipleSystems(value.value)
        : fromApiRcpRecipeValueWithUnit(value.value),
    };
  }
  return { type: AppCapabilitySettingType.Boolean, value: value.value };
}
