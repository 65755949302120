import {
  PantryTypography,
  PantryColor,
  Button,
  ButtonStyle,
  ButtonSize,
  sxCompose,
} from '@dropkitchen/pantry-react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { stylesCenterContent } from 'app/theme';
import { ChipStyle } from 'components/Chip/Chip';
import {
  ReadOnlyField,
  ReadOnlyFieldStyle,
} from 'components/ReadOnlyField/ReadOnlyField';
import { selectRecipeFetching } from 'features/recipe/recipeSlice';
import {
  RecipeReviewIngredient,
  RecipeReviewIngredientStyle,
} from 'features/recipe/review/ingredients/RecipeReviewIngredient';
import { RecipeReviewEmptyValue } from 'features/recipe/review/shared/RecipeReviewEmptyValue/RecipeReviewEmptyValue';
import { RecipeDescriptionField } from 'features/recipe/shared/RecipeDescriptionField/RecipeDescriptionField';
import { RecipeImage } from 'features/recipe/shared/RecipeImage/RecipeImage';
import { RecipeNameField } from 'features/recipe/shared/RecipeNameField/RecipeNameField';
import {
  RecipeStep,
  RecipeStepVariant,
} from 'features/recipe/steps/list/RecipeStep';
import {
  recipeTranslationStrings,
  recipeTranslationStyles,
} from 'features/translation/RecipeTranslation.constants';
import { RecipeTranslationColumn as Column } from 'features/translation/RecipeTranslationColumn';
import { RecipeTranslationColumnGrid as ColumnGrid } from 'features/translation/RecipeTranslationColumnGrid';
import { RecipeTranslationDetailsSection as DetailsSection } from 'features/translation/RecipeTranslationDetailsSection';
import { RecipeTranslationHeader } from 'features/translation/RecipeTranslationHeader';
import { RecipeTranslationRow as Row } from 'features/translation/RecipeTranslationRow';
import {
  showOnlyEditableFieldsUpdated,
  recipeFetchRequested,
  selectRecipe,
  selectShowOnlyEditableFields,
  selectTranslatedRecipe,
  translatedRecipeFetchRequested,
  translatedRecipeStepUpdated,
  translatedRecipeNameUpdated,
  translatedRecipeDescriptionUpdated,
  selectRecipeTranslationSaving,
  translatedRecipeSaveRequested,
  selectRecipeTranslationErrors,
} from 'features/translation/recipeTranslationSlice';
import { appRegionByLocale } from 'types/appRegionByLocale';
import { generateChipsFromTags } from 'utils/chips';
import { fromAppTimeToSentence } from 'utils/convertTimes';
import { generateRecipeHeroImageSource, heroImageConstants } from 'utils/image';
import { hasTime } from 'utils/validateTimes';

import { RecipeTranslationSkeleton } from './RecipeTranslationSkeleton';

const { labels, sections, messages, placeholders } = recipeTranslationStrings;

export const RecipeTranslation: FC = memo(function RecipeTranslation() {
  const dispatch = useAppDispatch();

  const { recipeId, recipeLocale } = useParams<{
    recipeId: ApiRcpRecipeId;
    recipeLocale: ApiLocale;
  }>();
  const recipe = useAppSelector(selectRecipe);
  const isFetchingRecipe = useAppSelector(selectRecipeFetching);

  const translatedRecipe = useAppSelector(selectTranslatedRecipe);
  const isFetchingTranslatedRecipe = useAppSelector(selectRecipeFetching);
  const translatedRecipeErrors = useAppSelector(selectRecipeTranslationErrors);
  const isSavingTranslatedRecipe = useAppSelector(
    selectRecipeTranslationSaving
  );

  const shouldShowOnlyEditableFields = useAppSelector(
    selectShowOnlyEditableFields
  );

  useEffect(() => {
    if (!recipeId || !recipeLocale) {
      return;
    }
    dispatch(recipeFetchRequested(recipeId));
    dispatch(
      translatedRecipeFetchRequested({
        forkedFromId: recipeId,
        locale: recipeLocale,
      })
    );
  }, [dispatch, recipeId, recipeLocale]);

  if (
    isFetchingRecipe ||
    isFetchingTranslatedRecipe ||
    !recipe ||
    !translatedRecipe
  ) {
    return <RecipeTranslationSkeleton />;
  }

  const { width, height } = heroImageConstants;
  const recipeHeroImage = generateRecipeHeroImageSource({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    recipeId: recipe.id!,
    height,
    width,
  });
  const translatedRecipeHeroImage = generateRecipeHeroImageSource({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    recipeId: translatedRecipe.id!,
    height,
    width,
  });

  const steps = mergeArrays(recipe.steps, translatedRecipe.steps);
  const ingredients = mergeArrays(
    recipe.ingredients,
    translatedRecipe.ingredients
  );

  return (
    <>
      {/** @todo Temporal save button for the initial version. It'll be removed when autosaving is in place. */}
      <Box
        sx={sxCompose(stylesCenterContent, {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: 0,
          mt: 8,
        })}
      >
        <Button
          label={labels.saveButton}
          buttonStyle={ButtonStyle.Default}
          size={ButtonSize.Large}
          onClick={() => dispatch(translatedRecipeSaveRequested())}
          loading={isSavingTranslatedRecipe}
        />
      </Box>
      <Box sx={recipeTranslationStyles.container}>
        {/* Recipe locale row */}
        <Row>
          <Column>
            <ReadOnlyField
              label={labels.defaultLocale}
              ariaLabel={labels.recipeAriaLabel(labels.defaultLocale)}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value={appRegionByLocale[recipe.locale!]}
              style={ReadOnlyFieldStyle.Alternative}
            />
          </Column>
          <Column>
            <RecipeTranslationHeader>
              <ReadOnlyField
                label={labels.activeLocale}
                ariaLabel={labels.recipeAriaLabel(labels.activeLocale)}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                value={appRegionByLocale[translatedRecipe.locale!]}
                style={ReadOnlyFieldStyle.Alternative}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shouldShowOnlyEditableFields}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        showOnlyEditableFieldsUpdated(event.target.checked)
                      )
                    }
                  />
                }
                label={labels.editableFieldsOnly}
              />
            </RecipeTranslationHeader>
          </Column>
        </Row>
        {/* Recipe name row */}
        <Row>
          <Column>
            <Typography
              variant={PantryTypography.Subtitle1}
              color={PantryColor.TextDefault}
            >
              {recipe.name}
            </Typography>
          </Column>
          <Column>
            <RecipeNameField
              id="translated-recipe-name"
              value={translatedRecipe.name}
              showErrors={!!translatedRecipeErrors.name}
              onChange={({ value }) =>
                dispatch(translatedRecipeNameUpdated({ name: value }))
              }
            />
          </Column>
        </Row>
        {/* Recipe details row */}
        <Row>
          <Column>
            <DetailsSection>
              <SectionTitle
                title={sections.basicInformation}
                ariaLabel={labels.recipeAriaLabel(sections.basicInformation)}
              />
              <RecipeImage
                recipeId={recipe.id}
                src={recipeHeroImage.src}
                alt={recipe.name}
                bypassCache
                error={{ text: recipeTranslationStrings.errors.image }}
                width={recipeHeroImage.width}
                height={recipeHeroImage.height}
              />
              <ReadOnlyField
                label={labels.descriptionField}
                ariaLabel={labels.recipeAriaLabel(labels.descriptionField)}
                value={recipe.description || placeholders.noValue}
              />
            </DetailsSection>
          </Column>
          <Column>
            <DetailsSection>
              <SectionTitle
                title={sections.basicInformation}
                ariaLabel={labels.translatedRecipeAriaLabel(
                  sections.basicInformation
                )}
              />
              <RecipeImage
                recipeId={translatedRecipe.id}
                src={translatedRecipeHeroImage.src}
                alt={translatedRecipe.name}
                bypassCache
                error={{ text: recipeTranslationStrings.errors.image }}
                width={translatedRecipeHeroImage.width}
                height={translatedRecipeHeroImage.height}
              />
              <RecipeDescriptionField
                id="translated-recipe-description"
                value={translatedRecipe.description}
                onChange={({ value }) =>
                  dispatch(translatedRecipeDescriptionUpdated(value))
                }
              />
            </DetailsSection>
          </Column>
        </Row>
        {/* First read-only fields row */}
        {!shouldShowOnlyEditableFields && (
          <>
            <Row>
              <Column>
                <ColumnGrid>
                  <ReadOnlyField
                    label={labels.authorField}
                    ariaLabel={labels.recipeAriaLabel(labels.authorField)}
                    value={recipe.author.name || placeholders.noValue}
                  />
                  <ReadOnlyField
                    label={labels.servesField}
                    ariaLabel={labels.recipeAriaLabel(labels.servesField)}
                    value={recipe.serves || placeholders.noValue}
                  />
                  <ReadOnlyField
                    label={labels.totalTimeField}
                    ariaLabel={labels.recipeAriaLabel(labels.totalTimeField)}
                    value={
                      hasTime(recipe.totalTime)
                        ? fromAppTimeToSentence(recipe.totalTime)
                        : placeholders.noValue
                    }
                  />
                </ColumnGrid>
              </Column>
              <Column>
                <ColumnGrid>
                  <ReadOnlyField
                    label={labels.authorField}
                    ariaLabel={labels.translatedRecipeAriaLabel(
                      labels.authorField
                    )}
                    value={translatedRecipe.author.name || placeholders.noValue}
                    style={ReadOnlyFieldStyle.Muted}
                  />
                  <ReadOnlyField
                    label={labels.servesField}
                    ariaLabel={labels.translatedRecipeAriaLabel(
                      labels.servesField
                    )}
                    value={translatedRecipe.serves || placeholders.noValue}
                    style={ReadOnlyFieldStyle.Muted}
                  />
                  <ReadOnlyField
                    label={labels.totalTimeField}
                    ariaLabel={labels.translatedRecipeAriaLabel(
                      labels.totalTimeField
                    )}
                    value={
                      hasTime(translatedRecipe.totalTime)
                        ? fromAppTimeToSentence(translatedRecipe.totalTime)
                        : placeholders.noValue
                    }
                    style={ReadOnlyFieldStyle.Muted}
                  />
                </ColumnGrid>
              </Column>
            </Row>
            {/* Second read-only fields row */}
            <Row>
              <Column>
                <ColumnGrid>
                  <ReadOnlyField
                    label={labels.prepTimeField}
                    ariaLabel={labels.recipeAriaLabel(labels.prepTimeField)}
                    value={
                      hasTime(recipe.prepTime)
                        ? fromAppTimeToSentence(recipe.prepTime)
                        : placeholders.noValue
                    }
                  />
                  <ReadOnlyField
                    label={labels.cookTimeField}
                    ariaLabel={labels.recipeAriaLabel(labels.cookTimeField)}
                    value={
                      hasTime(recipe.cookTime)
                        ? fromAppTimeToSentence(recipe.cookTime)
                        : placeholders.noValue
                    }
                  />
                </ColumnGrid>
              </Column>
              <Column>
                <ColumnGrid>
                  <ReadOnlyField
                    label={labels.prepTimeField}
                    ariaLabel={labels.translatedRecipeAriaLabel(
                      labels.prepTimeField
                    )}
                    value={
                      hasTime(translatedRecipe.prepTime)
                        ? fromAppTimeToSentence(translatedRecipe.prepTime)
                        : placeholders.noValue
                    }
                    style={ReadOnlyFieldStyle.Muted}
                  />
                  <ReadOnlyField
                    label={labels.cookTimeField}
                    ariaLabel={labels.translatedRecipeAriaLabel(
                      labels.cookTimeField
                    )}
                    value={
                      hasTime(translatedRecipe.cookTime)
                        ? fromAppTimeToSentence(translatedRecipe.cookTime)
                        : placeholders.noValue
                    }
                    style={ReadOnlyFieldStyle.Muted}
                  />
                </ColumnGrid>
              </Column>
            </Row>
            {/* Appliances tags row */}
            <Row>
              <Column>
                <ReadOnlyField
                  label={labels.applianceTagsField}
                  ariaLabel={labels.recipeAriaLabel(labels.applianceTagsField)}
                  value={generateChipsFromTags(recipe.applianceReferenceTags)}
                />
              </Column>
              <Column>
                <ReadOnlyField
                  label={labels.applianceTagsField}
                  ariaLabel={labels.translatedRecipeAriaLabel(
                    labels.applianceTagsField
                  )}
                  value={generateChipsFromTags(
                    translatedRecipe.applianceReferenceTags,
                    ChipStyle.Muted
                  )}
                />
              </Column>
            </Row>
            {/* General tags row */}
            <Row>
              <Column>
                <ReadOnlyField
                  label={labels.generalTagsField}
                  ariaLabel={labels.recipeAriaLabel(labels.generalTagsField)}
                  value={generateChipsFromTags(recipe.generalTags)}
                />
              </Column>
              <Column>
                <ReadOnlyField
                  label={labels.generalTagsField}
                  ariaLabel={labels.translatedRecipeAriaLabel(
                    labels.generalTagsField
                  )}
                  value={generateChipsFromTags(
                    translatedRecipe.generalTags,
                    ChipStyle.Muted
                  )}
                />
              </Column>
            </Row>
            {/* Ingredients rows */}
            <Row>
              <Column>
                <SectionTitle
                  title={sections.ingredients}
                  ariaLabel={labels.recipeAriaLabel(sections.ingredients)}
                />
              </Column>
              <Column>
                <SectionTitle
                  title={sections.ingredients}
                  ariaLabel={labels.translatedRecipeAriaLabel(
                    sections.ingredients
                  )}
                />
              </Column>
            </Row>
            {!ingredients.length && (
              <Row>
                <Column>
                  <RecipeReviewEmptyValue message={messages.emptySteps} />
                </Column>
                <Column>
                  <RecipeReviewEmptyValue message={messages.emptySteps} />
                </Column>
              </Row>
            )}
            {ingredients.map(([ingredient, translatedIngredient], i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Row key={i}>
                <Column>
                  {(ingredient && (
                    <RecipeReviewIngredient
                      ingredient={ingredient}
                      index={i}
                      recipeId={recipe.id}
                    />
                  )) ||
                    (i === 0 && (
                      <RecipeReviewEmptyValue message={messages.emptySteps} />
                    ))}
                </Column>
                <Column>
                  {(translatedIngredient && (
                    <RecipeReviewIngredient
                      ingredient={translatedIngredient}
                      style={RecipeReviewIngredientStyle.Muted}
                      index={i}
                      recipeId={translatedRecipe.id}
                    />
                  )) ||
                    (i === 0 && (
                      <RecipeReviewEmptyValue message={messages.emptySteps} />
                    ))}
                </Column>
              </Row>
            ))}
          </>
        )}
        {/* Step rows */}
        <Row>
          <Column>
            <SectionTitle
              title={sections.steps}
              ariaLabel={labels.recipeAriaLabel(sections.steps)}
            />
          </Column>
          <Column>
            <SectionTitle
              title={sections.steps}
              ariaLabel={labels.translatedRecipeAriaLabel(sections.steps)}
            />
          </Column>
        </Row>
        {!steps.length && (
          <Row>
            <Column>
              <RecipeReviewEmptyValue message={messages.emptySteps} />
            </Column>
            <Column>
              <RecipeReviewEmptyValue message={messages.emptySteps} />
            </Column>
          </Row>
        )}
        {steps.map(([step, translatedStep], i) => {
          const variant = shouldShowOnlyEditableFields
            ? RecipeStepVariant.Condensed
            : RecipeStepVariant.Default;
          const emptySection = i === 0 && (
            <RecipeReviewEmptyValue message={messages.emptySteps} />
          );
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Row key={i}>
              <Column>
                {step ? (
                  <RecipeStep
                    step={step}
                    stepIndex={i}
                    totalSteps={recipe.steps.length}
                    variant={variant}
                  />
                ) : (
                  emptySection
                )}
              </Column>
              <Column>
                {translatedStep ? (
                  <RecipeStep
                    step={translatedStep}
                    stepIndex={i}
                    totalSteps={translatedRecipe.steps.length}
                    variant={variant}
                    onChange={({ text }) => {
                      dispatch(
                        translatedRecipeStepUpdated({
                          step: { ...translatedStep, text },
                          stepIndex: i,
                        })
                      );
                    }}
                    hasError={!!translatedRecipeErrors[`step-${i}`]}
                  />
                ) : (
                  emptySection
                )}
              </Column>
            </Row>
          );
        })}
      </Box>
    </>
  );
});

const SectionTitle: FC<{ title: string; ariaLabel: string }> = memo(
  function SectionTitle({ title, ariaLabel }) {
    return (
      <Typography
        variant={PantryTypography.Body1SemiBold}
        role="heading"
        aria-label={ariaLabel}
        sx={{
          color: PantryColor.FrescoPrimary,
          ...recipeTranslationStyles.sectionTitle,
        }}
      >
        {title}
      </Typography>
    );
  }
);

function mergeArrays<T>(array1: T[], array2: T[]) {
  return Array.from(Array(Math.max(array1.length, array2.length)), (_, i) => [
    array1[i] || null,
    array2[i] || null,
  ]);
}
