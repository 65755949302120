import { PantryTypography, PantryColor } from '@dropkitchen/pantry-react';
import type { SxProps, Theme } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import type { FC, ReactElement } from 'react';
import { memo } from 'react';

import { recipeReviewStrings } from 'features/recipe/review/RecipeReview.constants';

export interface RecipeReviewBasicInformationContainerProps {
  components: {
    image: ReactElement;
    descriptionField: ReactElement;
    authorField: ReactElement;
    totalTimeField: ReactElement;
    prepTimeField: ReactElement;
    cookTimeField: ReactElement;
    servesField: ReactElement;
    applianceTagsField: ReactElement;
    generalTagsField: ReactElement;
  };
}

const { sections } = recipeReviewStrings;

export const RecipeReviewBasicInformationContainer: FC<RecipeReviewBasicInformationContainerProps> =
  memo(function RecipeReviewBasicInformation({ components }) {
    const containerStyle: SxProps<Theme> = { mt: 6 };
    return (
      <>
        <Typography
          variant={PantryTypography.Body1SemiBold}
          role="heading"
          sx={{ color: PantryColor.FrescoPrimary }}
        >
          {sections.basicInformation}
        </Typography>
        <Grid container sx={containerStyle}>
          <Grid item sm={4}>
            {components.image}
          </Grid>
          <Grid item sm={8}>
            {components.descriptionField}
          </Grid>
        </Grid>
        <Grid container sx={containerStyle}>
          <Grid item sm={4}>
            {components.authorField}
          </Grid>
          <Grid item sm={4}>
            {components.totalTimeField}
          </Grid>
        </Grid>
        <Grid container sx={containerStyle}>
          <Grid item sm={4}>
            {components.prepTimeField}
          </Grid>
          <Grid item sm={4}>
            {components.cookTimeField}
          </Grid>
        </Grid>
        <Grid container sx={containerStyle}>
          <Grid item sm={4}>
            {components.servesField}
          </Grid>
          <Grid item sm={8}>
            {components.applianceTagsField}
          </Grid>
        </Grid>
        <Grid container sx={containerStyle}>
          <Grid item xs={12}>
            {components.generalTagsField}
          </Grid>
        </Grid>
      </>
    );
  });
