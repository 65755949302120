import type { ApiList } from 'api/types';
import type { ApiRcpRecipe } from 'api/types/recipe/apiRcpRecipe';
import type { TagsById } from 'features/referenceData/tags/tagsSlice';
import type { AppRecipe } from 'types/recipe/appRecipe';
import { fromApiRcpRecipe } from 'types/recipe/appRecipe';

export interface AppRecipes {
  recipes: AppRecipe[];
  total: number;
}

export const fromApiSchRecipes = (
  { items, total }: ApiList<ApiRcpRecipe>,
  applianceTags: TagsById
): AppRecipes => {
  return {
    recipes: items.map((item) => fromApiRcpRecipe(item, applianceTags)),
    total,
  };
};
