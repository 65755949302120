import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { Box, Typography } from '@mui/material';
import type { FC, ReactElement } from 'react';
import { memo } from 'react';

export interface RecipeReviewListContainerProps {
  components: {
    title: ReactElement | string;
    list: ReactElement;
  };
}

export const RecipeReviewListContainer: FC<RecipeReviewListContainerProps> =
  memo(function RecipeReviewListContainer({ components }) {
    return (
      <>
        <Typography
          variant={PantryTypography.Body1SemiBold}
          role="heading"
          sx={{
            color: PantryColor.FrescoPrimary,
            mt: 10,
          }}
        >
          {components.title}
        </Typography>
        <Box sx={{ mt: 6 }}>{components.list}</Box>
      </>
    );
  });
