import type { ApiLocale } from 'api/types/common/apiLocale';
import { appDisplayCodeByLocale } from 'types/appDisplayCodeByLocale';
import {
  appRegionByLocale,
  appRegionByLocaleAlternative,
} from 'types/appRegionByLocale';

export const localeFieldStrings = {
  getOptionText: (
    locale: ApiLocale,
    options: { alternative: boolean } = { alternative: false }
  ) => {
    if (options.alternative) {
      return `${appRegionByLocaleAlternative[locale]}`;
    }
    return `${appRegionByLocale[locale]}, ${appDisplayCodeByLocale[locale]}`;
  },
} as const;
