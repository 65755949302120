// This rule is triggered false positive because there are import and module in same file
/* eslint-disable import/no-import-module-exports */
import { datadogRum } from '@datadog/browser-rum';
import { configureStore } from '@reduxjs/toolkit';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { Provider } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';

import type { NavigateSagaParams } from 'app/routes/routesUtils';
import { rootSaga } from 'app/store/rootSaga';

import { rootReducer } from './rootReducer';
import type { RootState } from './rootReducer';

export interface SagaContext {
  navigate: (params: NavigateSagaParams) => void;
}

export interface CreateStoreArgs extends SagaContext {
  preloadedState?: RootState;
  /**
   * For testing purposes
   */
  disableSagaMiddleware?: boolean;
}

export const createStore = ({
  navigate,
  preloadedState,
  disableSagaMiddleware,
}: CreateStoreArgs) => {
  const sagaMiddleware = createSagaMiddleware<SagaContext>({
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.error('Unhandled redux-saga error:', e);
      datadogRum.addError(e);
    },
    context: {
      navigate,
    },
  });

  const store = configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
        immutableCheck: !process.env.CI,
      }).concat([sagaMiddleware]),
  });

  if (!disableSagaMiddleware) {
    sagaMiddleware.run(rootSaga);
  }

  return store;
};

export type AppDispatch = ReturnType<typeof createStore>['dispatch'];

export function AppStoreProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();

  const appStore = useMemo(() => {
    const store = createStore({
      navigate: ({ to, options }) => {
        navigate(to, options);
      },
    });

    if (process.env.NODE_ENV === 'development' && module.hot) {
      /* eslint-disable */
      module.hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').rootReducer;
        store.replaceReducer(newRootReducer);
      });
      /* eslint-enable */
    }

    return store;
  }, [navigate]);

  return <Provider store={appStore}>{children}</Provider>;
}
