import type { IconProps } from '@dropkitchen/pantry-react';
import {
  ErrorIcon,
  PantryColor,
  PantryTypography,
  WarningIcon,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { RecipeReviewFixButton } from 'features/recipe/review/shared/RecipeReviewFixButton/RecipeReviewFixButton';
import { AppIssueType } from 'types/appIssueType';

export interface RecipeReviewEmptyValueProps {
  emptyLink?: string;
  message: string;
  type?: AppIssueType;
  sx?: SxProps;
}

export const RecipeReviewEmptyValue: FC<RecipeReviewEmptyValueProps> = memo(
  function RecipeReviewEmptyValue({
    emptyLink,
    message,
    type = AppIssueType.Warning,
    sx,
  }) {
    const navigate = useNavigate();

    const handleClick = () => {
      if (emptyLink) {
        navigate(emptyLink);
      }
    };

    const colors = (() => {
      if (type === AppIssueType.Warning) {
        return {
          icon: PantryColor.IconWarning,
        };
      }
      return {
        icon: PantryColor.IconCritical,
      };
    })();

    return (
      <Box>
        <Typography
          variant={PantryTypography.Body2}
          sx={sxCompose(
            {
              display: 'flex',
              alignItems: 'center',
              color: PantryColor.TextDefault,
            },
            sx
          )}
        >
          <Icon type={type} size={16} sx={{ mr: 1 }} color={colors.icon} />
          {message}
        </Typography>
        {!!emptyLink && (
          <RecipeReviewFixButton
            onClick={handleClick}
            sx={{ mt: 1 }}
            severity={type}
          />
        )}
      </Box>
    );
  }
);

const Icon: FC<{ type: AppIssueType } & IconProps> = function Icon({
  type,
  ...props
}) {
  return type === AppIssueType.Warning ? (
    <ErrorIcon {...props} />
  ) : (
    <WarningIcon {...props} />
  );
};
