import { amplifyInitSaga } from 'api/amplifyInit';
import { authRunOnceSagas } from 'features/auth/authSagas';
import { configsRunOnceSagas } from 'features/configs/configsSagas';

/**
 * Some sagas may be designed to run only once, for example handling
 * some single action during startup. It doesn't make sense to restart them
 * even in case of unhandled error
 */
export const getRunOnceSagas = (): ((...args: unknown[]) => unknown)[] => [
  amplifyInitSaga,
  ...configsRunOnceSagas,
  ...authRunOnceSagas,
];
