import {
  Button,
  ButtonSize,
  ButtonStyle,
  DeleteIcon,
} from '@dropkitchen/pantry-react';
import type { FC } from 'react';
import { memo } from 'react';

export enum CardDeleteButtonAlignment {
  Top = 'top',
  Center = 'center',
}

export interface CardDeleteButtonProps {
  label: string;
  /**
   * Button self alignment in parent flex container.
   */
  alignment: CardDeleteButtonAlignment;
  onDelete: () => void;
}

export const CardDeleteButton: FC<CardDeleteButtonProps> = memo(
  function CardDeleteButton({ label, alignment, onDelete }) {
    return (
      /**
       * Workaround to position the delete button on the card's header.
       * MUI's CardActionArea generates an HTML button that wraps the entire card. We can't use CardActionArea and
       * CardHeader's action property together as that'll generate a button inside a button.
       */
      <Button
        sx={{
          alignSelf:
            alignment === CardDeleteButtonAlignment.Center
              ? 'center'
              : 'flex-start',
        }}
        label={label}
        hideLabel
        leadingIcon={DeleteIcon}
        buttonStyle={ButtonStyle.Subtle}
        size={ButtonSize.Small}
        onClick={(event) => {
          event.stopPropagation();
          onDelete();
        }}
      />
    );
  }
);
