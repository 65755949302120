import type { SagaIterator } from 'redux-saga';
import { call, cancelled, select, take } from 'redux-saga/effects';

import type { ApiRequestFn, ApiResponse } from 'api/types';
import {
  authInitialized,
  selectAuthIsInitialized,
} from 'features/auth/authSlice';

/**
 * Creates saga that waits for auth initialization,
 * performs request, handles errors and returns response
 */
export function createApiRequestSaga<TResponse, TRequest = undefined>(
  /**
   * Factory function that accepts request arguments and returns request function of type `ApiRequestFn`
   */
  apiRequestFn: ApiRequestFn<TResponse, TRequest>
) {
  function* apiRequestSaga(
    args?: TRequest
  ): SagaIterator<ApiResponse<TResponse>> {
    const abortController = new AbortController();

    try {
      const isAuthInitialized = (yield select(
        selectAuthIsInitialized
      )) as ReturnType<typeof selectAuthIsInitialized>;

      if (!isAuthInitialized) {
        yield take(authInitialized.type);
      }

      return (
        args === undefined
          ? yield call(
              apiRequestFn as (
                signal: AbortSignal
              ) => Promise<ApiResponse<TResponse>>,
              abortController.signal
            )
          : yield call(
              apiRequestFn as (
                signal: AbortSignal,
                args: TRequest
              ) => Promise<ApiResponse<TResponse>>,
              abortController.signal,
              args
            )
      ) as ApiResponse<TResponse>;
    } finally {
      if ((yield cancelled()) as boolean) {
        abortController.abort();
      }
    }
  }

  return apiRequestSaga as TRequest extends undefined
    ? () => ReturnType<typeof apiRequestSaga>
    : (args: TRequest) => ReturnType<typeof apiRequestSaga>;
}

export type ApiRequestSagaReturnType<TSaga> = TSaga extends (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args?: any
) => SagaIterator<ApiResponse<infer TResponse>>
  ? ApiResponse<TResponse>
  : never;
