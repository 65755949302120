import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { Typography } from '@mui/material';
import type { FC } from 'react';
import { useState, useEffect, memo } from 'react';

import { autoSaveNotificationsStrings } from 'features/recipe/AutoSaveNotifications.constants';
import { recipePageConstants } from 'features/recipe/RecipePage.constants';

const { messages } = autoSaveNotificationsStrings;

export interface AutoSaveNotificationsProps {
  saving: boolean;
  changed: boolean;
}

export const AutoSaveNotifications: FC<AutoSaveNotificationsProps> = memo(
  function AutoSaveNotifications({ saving, changed }) {
    const [message, setMessage] = useState<string>(messages.momentsAgo);

    useEffect(() => {
      if (!changed) {
        setMessage(messages.momentsAgo);
        return;
      }

      const timerId = window.setTimeout(() => {
        setMessage(messages.minuteAgo);
      }, recipePageConstants.autosave.changeMessageDelay);

      return () => clearTimeout(timerId);
    }, [changed]);

    if (saving || !changed) {
      return null;
    }

    return (
      <Typography
        variant={PantryTypography.Caption}
        color={PantryColor.TextSubtle}
      >
        {message}
      </Typography>
    );
  }
);
