import isEmpty from 'lodash/isEmpty';

import type { ApiRcpRecipeValueInMultipleSystems } from 'api/types/recipe/apiRcpRecipeValueInMultipleSystems';
import type { ApiRcpRecipeValueWithUnit } from 'api/types/recipe/apiRcpRecipeValueWithUnit';
import { AppUnitSystem } from 'types/appUnitSystem';
import type { AppRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';
import { fromApiRcpRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';

export type AppRecipeValueInMultipleSystems<TValue = number> = {
  [AppUnitSystem.Metric]: AppRecipeValueWithUnit<TValue>;
  [AppUnitSystem.UsCustomary]: AppRecipeValueWithUnit<TValue>;
};

export function fromApiRcpRecipeValueInMultipleSystems(
  value: ApiRcpRecipeValueInMultipleSystems
): AppRecipeValueInMultipleSystems {
  /**
   * @todo: remove when both systems are mandatory in the API
   */
  const values = {
    [AppUnitSystem.Metric]: isEmpty(value[AppUnitSystem.Metric])
      ? placeholderValue
      : value[AppUnitSystem.Metric],
    [AppUnitSystem.UsCustomary]: isEmpty(value[AppUnitSystem.UsCustomary])
      ? placeholderValue
      : value[AppUnitSystem.UsCustomary],
  };

  return {
    [AppUnitSystem.UsCustomary]: fromApiRcpRecipeValueWithUnit(
      values[AppUnitSystem.UsCustomary]
    ),
    [AppUnitSystem.Metric]: fromApiRcpRecipeValueWithUnit(
      values[AppUnitSystem.Metric]
    ),
  };
}

export const placeholderValue: ApiRcpRecipeValueWithUnit = {
  amount: 0,
  text: '0 Missing unit',
  referenceUnit: {
    id: 'cckg:MissingUnit',
    name: 'Missing unit',
  },
};
