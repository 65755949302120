import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import type { ApiAplId } from 'api/types/appliance/apiAplId';
import type { ApiEntityId } from 'api/types/common/apiEntityId';
import {
  capabilityFieldParams,
  capabilityFieldStrings,
} from 'components/CapabilityField/CapabilityField.constants';
import type { CapabilitySettingsValidator } from 'components/CapabilityField/CapabilityField.types';
import {
  generateNumericSettingValidatorId,
  generateNumericSettingValidatorMessages,
  generateNumericValidator,
  validateCapabilitySettings,
} from 'components/CapabilityField/CapabilityField.validations';
import type { CapabilitySettingFieldDependsOn } from 'components/CapabilityField/CapabilitySettingFieldDependencies';
import {
  areDependenciesMet,
  generateCapabilitySettingFieldDependsOn,
} from 'components/CapabilityField/CapabilitySettingFieldDependencies';
import type { ApplianceWithCapabilities } from 'features/appliances/appliancesSlice';
import type { AppCapability } from 'types/appCapability';
import { AppCapabilityType } from 'types/appCapability';
import type { AppCapabilityAllowedSetting } from 'types/appCapabilityAllowedSetting';
import type { AppCapabilityAllowedSettingValueNominal } from 'types/appCapabilityAllowedSettingValueNominal';
import type { AppCapabilityAllowedSettingValueNumericInSingleSystem } from 'types/appCapabilityAllowedSettingValueNumeric';
import type { AppCapabilityAllowedSettingDependencyAllowedValues } from 'types/appCapabilitySettingDependency';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import type { AppRecipeStepCapability } from 'types/recipe/appRecipeStepCapability';
import type { AppRecipeStepCapabilitySetting } from 'types/recipe/appRecipeStepCapabilitySetting';
import type { AppRecipeStepCapabilitySettingValueNominal } from 'types/recipe/appRecipeStepCapabilitySettingValueNominal';
import {
  isValueInMultipleSystems,
  isValueInSingleSystem,
} from 'utils/unitSystems';
import type { Validator, ValidatorError } from 'utils/validator';

const { incompatibilities: messages } = capabilityFieldStrings;

export interface IncompatibilityWithChildren {
  message: string;
  children: string[];
}

export type ApplianceIncompatibility = Record<
  ApiAplId,
  {
    applianceId: ApiAplId;
    incompatibilities: (string | IncompatibilityWithChildren)[];
  }
>;

export const checkApplianceIncompatibilities = ({
  capability,
  selectedSettings,
  appliances,
}: {
  capability: AppCapability | AppRecipeStepCapability;
  selectedSettings: AppRecipeStepCapabilitySetting[];
  appliances: ApplianceWithCapabilities[];
}): ApplianceIncompatibility => {
  let incompatibilities: ApplianceIncompatibility = {};

  if (capability.type === AppCapabilityType.General) {
    return incompatibilities;
  }

  appliances.forEach((appliance) => {
    const supportedCapability = appliance.supportedCapabilities[capability.id];
    if (!supportedCapability) {
      incompatibilities = addIncompatibility({
        applianceId: appliance.id,
        message: generateIncompatibilitiesMessage({
          message: messages.capability,
          capabilityName: capability.name,
        }),
        incompatibilities,
      });
      return;
    }

    // Validate each setting
    selectedSettings.forEach((selectedSetting) => {
      const allowedSetting = supportedCapability.allowedSettings.find(
        (item) => item.id === selectedSetting.id
      );
      if (!allowedSetting) {
        incompatibilities = addIncompatibility({
          applianceId: appliance.id,
          message: generateIncompatibilitiesMessage({
            message: messages.setting,
            settingName: selectedSetting.name,
          }),
          incompatibilities,
        });
        return;
      }

      const settingType =
        selectedSetting.value.type === AppCapabilitySettingType.Time
          ? AppCapabilitySettingType.Numeric
          : selectedSetting.value.type;

      if (isEmpty(allowedSetting.allowedValues[settingType])) {
        incompatibilities = addIncompatibility({
          applianceId: appliance.id,
          message: generateIncompatibilitiesMessage({
            message: messages.settingType,
            settingType: selectedSetting.value.type,
            settingName: selectedSetting.name,
          }),
          incompatibilities,
        });
        return;
      }

      if (selectedSetting.value.type === AppCapabilitySettingType.Nominal) {
        incompatibilities = checkNominalIncompatibilities({
          allowedSetting,
          selectedSetting,
          incompatibilities,
          appliance,
        });
      }

      if (
        selectedSetting.value.type === AppCapabilitySettingType.Numeric ||
        selectedSetting.value.type === AppCapabilitySettingType.Time
      ) {
        if (!allowedSetting.allowedValues.numeric) {
          return;
        }
        incompatibilities = checkNumericIncompatibilities({
          allowedSetting,
          selectedSetting,
          incompatibilities,
          appliance,
        });
      }

      const dependencies = generateCapabilitySettingFieldDependsOn(
        allowedSetting.dependsOnSetting
      );
      incompatibilities = checkDependenciesIncompatibilities({
        dependencies,
        selectedSettings,
        selectedSetting,
        incompatibilities,
        applianceId: appliance.id,
        allowedSettings: supportedCapability.allowedSettings,
      });
    });
  });
  return incompatibilities;
};

const addIncompatibility = ({
  applianceId,
  message,
  incompatibilities,
}: {
  applianceId: ApiAplId;
  message: string | IncompatibilityWithChildren;
  incompatibilities: ApplianceIncompatibility;
}): ApplianceIncompatibility => {
  if (!incompatibilities[applianceId]) {
    incompatibilities[applianceId] = {
      applianceId,
      incompatibilities: [],
    };
  }
  incompatibilities[applianceId].incompatibilities.push(message);
  return incompatibilities;
};

export const generateNumericIncompatibilityValidator = ({
  settingId,
  settingName,
  unit,
  min,
  max,
  step,
}: {
  settingId: ApiEntityId;
  settingName: string;
  unit: string;
  min?: number;
  max?: number;
  step?: number;
}): Validator => {
  const errorMessages = generateNumericIncompatibilitiesMessages({
    settingId,
    settingName,
    unit,
    min,
    max,
    step,
  });

  return generateNumericValidator({
    min,
    max,
    step,
    errorMessages,
  });
};

export const generateNumericIncompatibilitiesMessages = ({
  settingId,
  settingName,
  unit,
  min = 0,
  max = 0,
  step = 0,
}: {
  settingId: ApiEntityId;
  settingName: string;
  unit: string;
  min?: number;
  max?: number;
  step?: number;
}) => {
  const { settingNamePlaceholder } = capabilityFieldParams;

  const errorMessages = generateNumericSettingValidatorMessages({
    settingId,
    unit,
    min,
    max,
    step,
    messages: {
      numericSetting: messages.numericSetting,
      timeSetting: messages.timeSetting,
    },
  });

  return {
    min: errorMessages.min.replace(settingNamePlaceholder, settingName),
    max: errorMessages.max.replace(settingNamePlaceholder, settingName),
    minAndMax: errorMessages.minAndMax.replace(
      settingNamePlaceholder,
      settingName
    ),
    step: errorMessages.step.replace(settingNamePlaceholder, settingName),
    stepWithMin: errorMessages.stepWithMin.replace(
      settingNamePlaceholder,
      settingName
    ),
  };
};

const checkNominalIncompatibilities = ({
  allowedSetting,
  selectedSetting,
  incompatibilities,
  appliance,
}: {
  allowedSetting: AppCapabilityAllowedSetting;
  selectedSetting: AppRecipeStepCapabilitySetting;
  incompatibilities: ApplianceIncompatibility;
  appliance: ApplianceWithCapabilities;
}) => {
  if (
    !allowedSetting.allowedValues.nominal?.find(
      (item) =>
        item.id ===
        (selectedSetting.value as AppRecipeStepCapabilitySettingValueNominal)
          .referenceValue.id
    )
  ) {
    return addIncompatibility({
      applianceId: appliance.id,
      message: generateIncompatibilitiesMessage({
        message: messages.nominalSetting,
        settingName: selectedSetting.name,
        nominalValue: (
          selectedSetting.value as AppRecipeStepCapabilitySettingValueNominal
        ).referenceValue.name,
      }),
      incompatibilities,
    });
  }
  return incompatibilities;
};

export const checkNumericIncompatibilities = ({
  allowedSetting,
  selectedSetting,
  incompatibilities,
  appliance,
}: {
  allowedSetting: AppCapabilityAllowedSetting;
  selectedSetting: AppRecipeStepCapabilitySetting;
  incompatibilities: ApplianceIncompatibility;
  appliance: ApplianceWithCapabilities;
}): ApplianceIncompatibility => {
  if (!allowedSetting.allowedValues.numeric) {
    return incompatibilities;
  }
  const settingValidator = allowedSetting.allowedValues.numeric.reduce(
    (settingValidators: CapabilitySettingsValidator, allowedValue) => {
      const createValidator = ({
        unit,
        min,
        max,
        step,
      }: AppCapabilityAllowedSettingValueNumericInSingleSystem): {
        validatorId: string;
        validator: Validator;
      } => {
        const validatorId = generateNumericSettingValidatorId(
          allowedSetting.id,
          unit.id
        );
        const validator = generateNumericIncompatibilityValidator({
          settingId: selectedSetting.id,
          settingName: selectedSetting.name,
          unit: unit.abbreviation || unit.name,
          min,
          max,
          step,
        });
        return { validatorId, validator };
      };

      const addValidator = ({
        validatorId,
        validator,
      }: {
        validatorId: string;
        validator: Validator;
      }) => {
        if (settingValidators[validatorId]) {
          settingValidators[validatorId].push(validator);
        } else {
          settingValidators[validatorId] = [validator];
        }
      };

      if (isValueInSingleSystem(allowedValue)) {
        addValidator(createValidator(allowedValue));
      }
      if (isValueInMultipleSystems(allowedValue)) {
        addValidator(createValidator(allowedValue.metric));
        addValidator(createValidator(allowedValue.usCustomary));
      }

      return settingValidators;
    },
    {}
  );

  const errors = validateCapabilitySettings(
    settingValidator,
    Object.values([selectedSetting])
  );

  if (!errors) {
    return incompatibilities;
  }

  const numericIncompatibilities = cloneDeep(incompatibilities);

  Object.values(errors)
    .flatMap((error) =>
      isArray(error)
        ? error
        : [...(error.metric ?? []), ...(error.usCustomary ?? [])]
    )
    .filter((error): error is { numeric: ValidatorError } => !!error.numeric)
    .flatMap(({ numeric }) => Object.values(numeric))
    .forEach((message) =>
      addIncompatibility({
        applianceId: appliance.id,
        message,
        incompatibilities: numericIncompatibilities,
      })
    );
  return numericIncompatibilities;
};

interface NumericDependencyIncompatibilitiesMessages {
  min?: string;
  max?: string;
  minAndMax?: string;
  step?: string;
  stepWithMin?: string;
}

export const generateNumericDependencyIncompatibilitiesMessages = ({
  settingId,
  settingName,
  unit,
  min = 0,
  max = 0,
  step = 0,
}: {
  settingId: ApiEntityId;
  settingName: string;
  unit: string;
  min?: number;
  max?: number;
  step?: number;
}) => {
  const { settingNamePlaceholder } = capabilityFieldParams;

  const errorMessages = generateNumericSettingValidatorMessages({
    settingId,
    unit,
    min,
    max,
    step,
    messages: {
      numericSetting: messages.dependency.numeric,
      timeSetting: messages.dependency.time,
    },
  });

  return {
    min: errorMessages.min.replace(settingNamePlaceholder, settingName),
    max: errorMessages.max.replace(settingNamePlaceholder, settingName),
    minAndMax: errorMessages.minAndMax.replace(
      settingNamePlaceholder,
      settingName
    ),
    step: errorMessages.step.replace(settingNamePlaceholder, settingName),
    stepWithMin: errorMessages.stepWithMin.replace(
      settingNamePlaceholder,
      settingName
    ),
  };
};

export const generateIncompatibilitiesMessage = ({
  message,
  capabilityName,
  nominalValue,
  settingName,
  settingType,
}: {
  message: string;
  capabilityName?: string;
  nominalValue?: string;
  settingName?: string;
  settingType?: string;
}) => {
  let newMessage = message;
  if (capabilityName) {
    newMessage = newMessage.replace(
      capabilityFieldParams.capabilityNamePlaceholder,
      capabilityName
    );
  }
  if (settingName) {
    newMessage = newMessage.replace(
      capabilityFieldParams.settingNamePlaceholder,
      settingName
    );
  }
  if (settingType) {
    newMessage = newMessage.replace(
      capabilityFieldParams.settingTypePlaceholder,
      settingType
    );
  }
  if (nominalValue) {
    newMessage = newMessage.replace(
      capabilityFieldParams.nominalValuePlaceholder,
      nominalValue
    );
  }
  return newMessage;
};

export const checkDependenciesIncompatibilities = ({
  dependencies,
  selectedSettings,
  selectedSetting,
  incompatibilities,
  applianceId,
  allowedSettings,
}: {
  dependencies: CapabilitySettingFieldDependsOn | undefined;
  selectedSettings: AppRecipeStepCapabilitySetting[];
  selectedSetting: AppRecipeStepCapabilitySetting;
  incompatibilities: ApplianceIncompatibility;
  applianceId: ApiAplId;
  allowedSettings: AppCapabilityAllowedSetting[];
}) => {
  let dependencyIncompatibilities: ApplianceIncompatibility =
    cloneDeep(incompatibilities);

  if (!dependencies) {
    return dependencyIncompatibilities;
  }

  if (!areDependenciesMet(dependencies, selectedSettings)) {
    const settingMessages: (string | null)[] = [];
    Object.keys(dependencies).forEach((dependentSettingId) => {
      const setting = allowedSettings.find(
        (item) => item.id === dependentSettingId
      );
      if (!setting) {
        return;
      }

      const dependencySelectedSetting = selectedSettings.find(
        (item) => item.id === dependentSettingId
      );
      const dependency = dependencies[dependentSettingId as ApiEntityId];

      if (
        !dependencySelectedSetting ||
        dependencySelectedSetting.value.type ===
          AppCapabilitySettingType.Nominal
      ) {
        settingMessages.push(
          checkNominalDependenciesIncompatibilities({
            dependency,
            setting,
          })
        );
      }

      if (
        !dependencySelectedSetting ||
        dependencySelectedSetting.value.type ===
          AppCapabilitySettingType.Boolean
      ) {
        settingMessages.push(
          checkBooleanDependenciesIncompatibilities({
            dependency,
            setting,
          })
        );
      }

      if (
        !dependencySelectedSetting ||
        dependencySelectedSetting.value.type ===
          AppCapabilitySettingType.Numeric ||
        dependencySelectedSetting.value.type === AppCapabilitySettingType.Time
      ) {
        settingMessages.push(
          ...checkNumericDependenciesIncompatibilities({
            dependency,
            setting,
          })
        );
      }
    });

    const incompatibilityMessage: IncompatibilityWithChildren = {
      message: generateIncompatibilitiesMessage({
        message: messages.dependency.main,
        settingName: selectedSetting.name,
      }),
      children: settingMessages.filter(Boolean) as string[],
    };
    dependencyIncompatibilities = addIncompatibility({
      applianceId,
      message: incompatibilityMessage,
      incompatibilities: dependencyIncompatibilities,
    });
  }
  return dependencyIncompatibilities;
};

export const getNominalSettingsNames = (
  valueIds: ApiEntityId[],
  nominalValues: AppCapabilityAllowedSettingValueNominal[] | undefined
): string[] => {
  if (!nominalValues) {
    return [];
  }

  return valueIds
    .map(
      (id) =>
        nominalValues.find((data) => data.id === id)?.name.toLowerCase() ?? ''
    )
    .filter(Boolean);
};

const checkNominalDependenciesIncompatibilities = ({
  dependency,
  setting,
}: {
  dependency: AppCapabilityAllowedSettingDependencyAllowedValues;
  setting: AppCapabilityAllowedSetting;
}) => {
  if (!dependency.nominal) {
    return null;
  }

  const nominalValue = getNominalSettingsNames(
    dependency.nominal,
    setting.allowedValues.nominal
  ).join(', ');
  return generateIncompatibilitiesMessage({
    message: messages.dependency.nominalAndBoolean,
    settingName: setting.name,
    nominalValue,
  });
};

export const checkBooleanDependenciesIncompatibilities = ({
  dependency,
  setting,
}: {
  dependency: AppCapabilityAllowedSettingDependencyAllowedValues;
  setting: AppCapabilityAllowedSetting;
}) => {
  if (!dependency.boolean) {
    return null;
  }

  const booleanValue = dependency.boolean.toString();
  return generateIncompatibilitiesMessage({
    message: messages.dependency.nominalAndBoolean,
    settingName: setting.name,
    nominalValue: booleanValue,
  });
};

export const checkNumericDependenciesIncompatibilities = ({
  dependency,
  setting,
}: {
  dependency: AppCapabilityAllowedSettingDependencyAllowedValues;
  setting: AppCapabilityAllowedSetting;
}) => {
  if (!dependency.numeric) {
    return [];
  }

  const numericMessages: string[] = [];
  dependency.numeric?.forEach((numericDependency) => {
    if (isValueInSingleSystem(numericDependency)) {
      const { min, max, step, unit } = numericDependency;
      const errorMessages = generateNumericDependencyIncompatibilitiesMessages({
        settingId: setting.id,
        settingName: setting.name,
        unit: unit.abbreviation || unit.name,
        min,
        max,
        step,
      });
      const message = getNumericIncompatibilityMessage(errorMessages, {
        min,
        max,
        step,
      });
      if (message) {
        numericMessages.push(message);
      }
    } else {
      const { metric, usCustomary } = numericDependency;
      const metricErrorMessages =
        generateNumericDependencyIncompatibilitiesMessages({
          settingId: setting.id,
          settingName: setting.name,
          unit: metric.unit.abbreviation || metric.unit.name,
          min: metric.min,
          max: metric.max,
          step: metric.step,
        });
      const metricMessage = getNumericIncompatibilityMessage(
        metricErrorMessages,
        metric
      );
      if (metricMessage) {
        numericMessages.push(metricMessage);
      }

      const usCustomaryErrorMessages =
        generateNumericDependencyIncompatibilitiesMessages({
          settingId: setting.id,
          settingName: setting.name,
          unit: usCustomary.unit.abbreviation || usCustomary.unit.name,
          min: usCustomary.min,
          max: usCustomary.max,
          step: usCustomary.step,
        });
      const usCustomaryMessage = getNumericIncompatibilityMessage(
        usCustomaryErrorMessages,
        usCustomary
      );
      if (usCustomaryMessage) {
        numericMessages.push(usCustomaryMessage);
      }
    }
  });
  return numericMessages;
};

const getNumericIncompatibilityMessage = (
  errorMessages: NumericDependencyIncompatibilitiesMessages,
  { min, max, step }: { min?: number; max?: number; step?: number }
): string | undefined => {
  if (min !== undefined && max !== undefined) {
    return errorMessages.minAndMax;
  }
  if (step !== undefined && min !== undefined) {
    return errorMessages.stepWithMin;
  }
  if (min !== undefined) {
    return errorMessages.min;
  }
  if (max !== undefined) {
    return errorMessages.max;
  }
  if (step !== undefined && min === undefined) {
    return errorMessages.step;
  }
  return undefined;
};
