import { PantryColor } from '@dropkitchen/pantry-react';
import type { AppBarProps } from '@mui/material';
import {
  AppBar,
  Box,
  Toolbar,
  useScrollTrigger,
  Link as MuiLink,
} from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { appHomeFeedRoutes, appRecipeRoutes } from 'app/routes/constants';
import { stylesCenterContent } from 'app/theme';
import { FrescoLogo } from 'components/FrescoLogo/FrescoLogo';
import { frescoLogoStrings } from 'components/FrescoLogo/FrescoLogo.constants';
import { SurfaceType, WithSurface } from 'components/WithSurface/WithSurface';
import { headerConstants } from 'features/layout/Header.constants';
import { useHeaderContext } from 'features/layout/HeaderContextProvider';
import { Logout } from 'features/layout/Logout';
import type { NavigationMenuItem } from 'features/layout/navigationMenu/NavigationMenu';
import { NavigationMenu } from 'features/layout/navigationMenu/NavigationMenu';

export const defaultMenuItems: NavigationMenuItem[] = [
  {
    title: 'Recipes',
    id: 'recipes',
    route: `/${appRecipeRoutes.root}`,
  },
  {
    title: 'Home Feed',
    id: 'home-feed',
    route: `/${appHomeFeedRoutes.root}`,
  },
];

export interface HeaderProps {
  menuItems?: NavigationMenuItem[];
  position?: AppBarProps['position'];
}

export const Header: FC<HeaderProps> = memo(function Header({
  menuItems = defaultMenuItems,
  position = 'sticky',
}) {
  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
  });

  const { scrollHeaderChildren } = useHeaderContext();

  return (
    <AppBar
      position={position}
      sx={{
        backgroundColor: isScrolled
          ? PantryColor.SurfaceDefault
          : PantryColor.FrescoPrimary,
        transition: 'background-color 0.5s',
        boxShadow: isScrolled ? '0px 4px 35px rgba(0, 0, 0, 0.06)' : 'none',
        borderBottom: isScrolled ? 'none' : '1px solid rgba(145, 36, 86, 1)',
        height: `${headerConstants.height}px`,
        justifyContent: 'center',
      }}
    >
      <WithSurface surface={isScrolled ? SurfaceType.Light : SurfaceType.Dark}>
        <Toolbar sx={{ ...stylesCenterContent }} disableGutters>
          {isScrolled && scrollHeaderChildren}
          {(!isScrolled || !scrollHeaderChildren) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                <MuiLink
                  aria-label={frescoLogoStrings.labels.logo}
                  component={Link}
                  to={appRecipeRoutes.root}
                >
                  <FrescoLogo
                    sx={{
                      height: 'auto',
                      width: 100,
                      color: isScrolled
                        ? PantryColor.FrescoPrimary
                        : PantryColor.TextDefault,
                    }}
                  />
                </MuiLink>
                <NavigationMenu isScrolled={isScrolled} items={menuItems} />
              </Box>
              <Logout />
            </Box>
          )}
        </Toolbar>
      </WithSurface>
    </AppBar>
  );
});
