import type { TabPanelProps } from '@mui/lab';
import { TabPanel } from '@mui/lab';

export function ListTabPanel({ value, children }: TabPanelProps) {
  return (
    <TabPanel value={value} sx={{ py: 8 }}>
      {children}
    </TabPanel>
  );
}
