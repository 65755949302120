import {
  PantryColor,
  PantryTypography,
  sxCompose,
  ErrorIcon,
  WarningIcon,
} from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { useParams } from 'react-router-dom';

import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import { RecipeReviewFixButton } from 'features/recipe/review/shared/RecipeReviewFixButton/RecipeReviewFixButton';
import { recipeIssueStrings } from 'features/recipe/shared/RecipeIssue/RecipeIssue.constants';
import { AppIssueType } from 'types/appIssueType';

const { labels } = recipeIssueStrings;

export interface RecipeIssueProps {
  warnings?: string[];
  errors?: string[];
  sx?: SxProps;
  onClick: () => void;
}

export const RecipeIssue: FC<RecipeIssueProps> = memo(function RecipeIssue({
  warnings = [],
  errors = [],
  sx,
  onClick,
}) {
  const { tab } = useParams();
  if (!warnings.length && !errors.length) {
    return null;
  }

  return (
    <Box
      sx={sxCompose(
        {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        sx
      )}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        {!!errors.length && tab !== RecipeTabName.Review && <IssueErrorIcon />}
        {tab === RecipeTabName.Review &&
          errors.map((error, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
              <IssueErrorIcon />
              <Typography
                variant={PantryTypography.Body2}
                color={PantryColor.TextDefault}
              >
                {error}
              </Typography>
            </Box>
          ))}
        {tab === RecipeTabName.Review &&
          warnings.map((warning, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
              <IssueWarningIcon />
              <Typography
                variant={PantryTypography.Body2}
                color={PantryColor.TextDefault}
              >
                {warning}
              </Typography>
            </Box>
          ))}
      </Box>
      {tab === RecipeTabName.Review && (
        <RecipeReviewFixButton
          onClick={onClick}
          severity={
            errors.length ? AppIssueType.Critical : AppIssueType.Warning
          }
        />
      )}
    </Box>
  );
});

const IssueErrorIcon: FC = memo(function IssueErrorIcon() {
  return (
    <WarningIcon
      aria-label={labels.error}
      size={16}
      sx={{ color: PantryColor.IconCritical, mr: 2 }}
    />
  );
});

const IssueWarningIcon: FC = memo(function IssueWarningIcon() {
  return (
    <ErrorIcon
      aria-label={labels.warning}
      size={16}
      sx={{ color: PantryColor.IconWarning, mr: 2 }}
    />
  );
});
