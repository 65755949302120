import { datadogLogs } from '@datadog/browser-logs';
import type { Action, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import type { SagaIterator } from 'redux-saga';
import type { ActionPattern } from 'redux-saga/effects';
import {
  actionChannel,
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import type { ApiRequestSagaReturnType } from 'api/createApiRequestSaga';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
import { MediaImageType, MediaResourceType } from 'api/media';
import type { ApiGetRecipeFromTextRequest } from 'api/recipes';
import {
  apiGetRecipe,
  apiPostRecipe,
  apiPublishRecipe,
  apiPutRecipe,
  apiGetRecipeFromUrl,
  apiGetRecipeFromText,
  apiUnpublishRecipe,
} from 'api/recipes';
import type { ApiAplId } from 'api/types/appliance/apiAplId';
import type { ApiError } from 'api/types/common/apiError';
import { ApiErrorType } from 'api/types/common/apiError';
import type { ApiLocale } from 'api/types/common/apiLocale';
import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { fromAppRecipe as toNewRecipeWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpNewRecipeWithRefs';
import { fromAppRecipe as toExistingRecipeWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeWithRefs';
import { generateRecipeRoute, navigateSaga } from 'app/routes/routesUtils';
import type { ApplianceWithCapabilities } from 'features/appliances/appliancesSlice';
import {
  appliancesFetchFailed,
  appliancesFetchRequested,
  appliancesFetchSucceed,
  selectAppliancesWithCapabilities,
  selectAppliancesFetching,
  selectShouldFetchAppliances,
} from 'features/appliances/appliancesSlice';
import { errorOccurred } from 'features/error/errorSlice';
import { errorSliceConstants } from 'features/error/errorSlice.constants';
import {
  TrackedEventType,
  trackEvent,
} from 'features/eventTracking/eventTracking';
import {
  mediaUploadFailed,
  mediaUploadRequested,
  mediaUploadFinished,
} from 'features/media/mediaSlice';
import {
  recipePageConstants,
  RecipeTabName,
} from 'features/recipe/RecipePage.constants';
import { recipeSagasStrings } from 'features/recipe/recipeSagas.constants';
import type {
  AppRecipeFieldUpdateKeys,
  RecipeGetFromUrlPayload,
  RecipeStepAddedPayload,
  RecipeStepUpdatedPayload,
  RecipeUpdateRequestedPayload,
} from 'features/recipe/recipeSlice';
import {
  selectRecipeIngredients,
  selectRecipeSteps,
  selectRecipeIssuesCount,
  recipeRecreateTagsRequested,
  recipeRecreateTagsSucceed,
  selectRecipeSaving,
  recipeFetchFailed,
  recipeFetchRequested,
  recipeFetchSucceed,
  recipePublishFailed,
  recipePublishing,
  recipePublishRequested,
  recipePublishSucceed,
  recipeSaveFailed,
  recipeSaveRequested,
  recipeSaveFinished,
  recipeSaving,
  selectRecipe,
  recipeGetFromUrlRequested,
  recipeSubmitted,
  selectRecipeErrorsCount,
  recipeUnpublishRequested,
  recipeUnpublishFailed,
  recipeUnpublishing,
  recipeUnpublishSucceed,
  recipeGetFromTextRequested,
  recipeHasChanged,
  selectRecipeHasUnsavedChanges,
  recipeAutoSaveRequested,
  recipeFieldUpdated,
  recipeIngredientAdded,
  recipeIngredientDeleted,
  recipeIngredientMoved,
  recipeIngredientUpdated,
  recipeStepAdded,
  recipeStepDeleted,
  recipeStepMoved,
  recipeStepUpdated,
  recipeSaveBeforeLeavingRequested,
  selectRecipeUnusedIngredientsCount,
  recipePublishConfirmationRequired,
  recipePublishConfirmed,
  recipePublishCanceled,
  recipeCreationRequested,
  recipeUpdateRequested,
  recipeStepIncompatibilitiesUpdated,
  selectRecipeIncompatibilitiesCount,
  selectRecipePublishing,
} from 'features/recipe/recipeSlice';
import { checkApplianceIncompatibilities } from 'features/recipe/steps/form/recipeStepsApplianceIncompatibilities.utils';
import { getApplianceTags } from 'features/referenceData/tags/tagsSagas';
import type { TagsById } from 'features/referenceData/tags/tagsSlice';
import type { AppError } from 'types/appError';
import { fromApiRcpRecipe, fromApiRusRecipe } from 'types/recipe/appRecipe';
import type { AppRecipe } from 'types/recipe/appRecipe';

export const apiFetchRecipeSaga = createApiRequestSaga(apiGetRecipe);
export const apiPostRecipeSaga = createApiRequestSaga(apiPostRecipe);
export const apiPutRecipeSaga = createApiRequestSaga(apiPutRecipe);
export const apiPublishRecipeSaga = createApiRequestSaga(apiPublishRecipe);
export const apiUnpublishRecipeSaga = createApiRequestSaga(apiUnpublishRecipe);
export const apiGetRecipeFromUrlSaga =
  createApiRequestSaga(apiGetRecipeFromUrl);
export const apiGetRecipeFromTextSaga =
  createApiRequestSaga(apiGetRecipeFromText);

export const recipeActionsThat = {
  autosave: [
    recipeAutoSaveRequested.type,
    recipeSaveBeforeLeavingRequested.type,
  ],
  triggerAutosaving: [
    recipeIngredientAdded.type,
    recipeIngredientUpdated.type,
    recipeIngredientDeleted.type,
    recipeIngredientMoved.type,
    recipeStepAdded.type,
    recipeStepUpdated.type,
    recipeStepDeleted.type,
    recipeStepMoved.type,
    recipeFieldUpdated.type,
  ],
  cancelAutosaving: [
    recipeSaveRequested.type,
    recipeSaveBeforeLeavingRequested.type,
  ],
};

export function* saveRecipe({
  payload: recipeId,
  type,
}: PayloadAction<string | undefined>): SagaIterator {
  yield put(recipeSubmitted());

  const errorsCount = (yield select(selectRecipeErrorsCount)) as ReturnType<
    typeof selectRecipeErrorsCount
  >;
  if (errorsCount) {
    datadogLogs.logger.info(
      `[SAVE] Save recipe ${recipeId} - Has errors. Aborting`,
      {
        debugType: 'save',
        recipeId,
      }
    );
    yield put(recipeSaveFinished({}));
    return;
  }

  yield put(recipeSaving());

  if (!recipeId) {
    yield call(createRecipe);
  } else {
    datadogLogs.logger.info(`[SAVE] Save recipe ${recipeId} - Updating`, {
      debugType: 'save',
      recipeId,
    });
    yield call(updateRecipe, recipeUpdateRequested({ originator: type }));
  }
}

export function* recipeSaveWatcher(): SagaIterator {
  // We want to process all the update requests but we only want to process one at a time, sequentially
  const queue = (yield actionChannel([
    recipeSaveRequested.type,
    recipeAutoSaveRequested.type,
  ])) as ActionPattern<Action<typeof recipeSaveRequested.type>>;
  while (true) {
    const action = (yield take(queue)) as ReturnType<
      typeof recipeSaveRequested
    >;
    datadogLogs.logger.info('[SAVE] Save watcher', {
      action,
      debugType: 'save',
    });
    yield call(saveRecipe, action);
  }
}

export function* createRecipe(): SagaIterator {
  const recipe = (yield select(selectRecipe)) as AppRecipe;
  const response = (yield call(apiPostRecipeSaga, {
    recipe: toNewRecipeWithRefs(recipe),
  })) as ApiRequestSagaReturnType<typeof apiPostRecipeSaga>;

  if (!response.ok) {
    yield put(recipeSaveFailed(response.details.message));
    yield call(recipeSaveErrorOcurred, response.details.platformError);
    return;
  }

  yield put(
    mediaUploadRequested({
      resourceType: MediaResourceType.Recipes,
      resourceId: response.data.id,
      imageType: MediaImageType.Hero,
    })
  );
  yield take([mediaUploadFailed, mediaUploadFinished]);
  yield call(navigateSaga, {
    to: generateRecipeRoute({ id: response.data.id }),
  });
  yield put(recipeSaveFinished({ etag: response.data.etag }));
}

function* recipeCreationWatcher(): SagaIterator {
  yield takeEvery(recipeCreationRequested, createRecipe);
}

export function* updateRecipe({
  payload: { originator },
}: PayloadAction<RecipeUpdateRequestedPayload>): SagaIterator {
  const isAutosaving = recipeActionsThat.autosave.includes(originator);
  const recipe = (yield select(selectRecipe)) as AppRecipe;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const recipeId = recipe.id!;
  datadogLogs.logger.info(`[SAVE] Update recipe ${recipeId}`, {
    debugType: 'save',
    recipeId,
    etag: recipe.etag,
  });
  const putRecipeResponse = (yield call(apiPutRecipeSaga, {
    recipeId,
    recipe: toExistingRecipeWithRefs(recipe),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    etag: recipe.etag!,
  })) as ApiRequestSagaReturnType<typeof apiPutRecipeSaga>;

  if (!putRecipeResponse.ok) {
    datadogLogs.logger.info(`[SAVE] Update recipe ${recipeId} - Fail`, {
      debugType: 'save',
      recipeId,
    });
    yield put(recipeSaveFailed(putRecipeResponse.details.message));
    if (!isAutosaving) {
      yield call(
        recipeSaveErrorOcurred,
        putRecipeResponse.details.platformError
      );
    }
    return;
  }

  const fetchRecipeResponse = (yield call(apiFetchRecipeSaga, {
    recipeId,
  })) as ApiRequestSagaReturnType<typeof apiFetchRecipeSaga>;

  if (!fetchRecipeResponse.ok) {
    datadogLogs.logger.info(`[SAVE] Update recipe ${recipeId} - Fetch Fail`, {
      debugType: 'save',
      recipeId,
    });
    yield put(recipeSaveFailed(fetchRecipeResponse.details.message));
    if (!isAutosaving) {
      yield put(errorOccurred(errorSliceConstants.genericError));
    }
    return;
  }
  datadogLogs.logger.info(`[SAVE] Update recipe ${recipeId} - Fetch ok`, {
    debugType: 'save',
    recipeId,
    etag: fetchRecipeResponse.data.etag,
  });
  yield put(recipeSaveFinished({ etag: fetchRecipeResponse.data.etag }));
}

function* recipeUpdateWatcher(): SagaIterator {
  yield takeEvery(recipeUpdateRequested, updateRecipe);
}

/**
 * Formats recipe save error and dispatches the `errorOccurred` action
 */
export function* recipeSaveErrorOcurred(
  platformError: ApiError | undefined
): SagaIterator {
  yield put(
    errorOccurred(
      ((): AppError => {
        switch (platformError?.error.type) {
          case ApiErrorType.PreconditionFailed:
            return {
              description: recipeSagasStrings.errors.precondition.title,
              message: recipeSagasStrings.errors.precondition.message,
            };
          case ApiErrorType.Validation:
            return {
              description: recipeSagasStrings.errors.validation.title,
              message: recipeSagasStrings.errors.validation.message,
            };
          default:
            return errorSliceConstants.genericError;
        }
      })()
    )
  );
}

export function* publishRecipe({
  payload: recipeId,
}: PayloadAction<string>): SagaIterator {
  const isSaving = (yield select(selectRecipeSaving)) as boolean;
  /** If the recipe is currently being saved, wait for it to finish */
  if (isSaving) {
    yield race({
      saveFinished: take(recipeSaveFinished),
      saveFailed: take(recipeSaveFailed),
    });
  }

  const hasUnsavedChanges = (yield select(
    selectRecipeHasUnsavedChanges
  )) as boolean;
  if (hasUnsavedChanges) {
    yield put(recipeSaveRequested(recipeId));
    const { saveFinished, saveFailed } = (yield race({
      saveFinished: take(recipeSaveFinished),
      saveFailed: take(recipeSaveFailed),
    })) as {
      saveFinished: ReturnType<typeof recipeSaveFinished> | undefined;
      saveFailed: ReturnType<typeof recipeSaveFailed> | undefined;
    };
    if (saveFailed || !saveFinished?.payload.etag) {
      return;
    }
  } else {
    yield put(recipeSubmitted());
    const errorsCount = (yield select(selectRecipeErrorsCount)) as ReturnType<
      typeof selectRecipeErrorsCount
    >;
    if (errorsCount) {
      return;
    }
  }

  const issuesCount = (yield select(selectRecipeIssuesCount)) as ReturnType<
    typeof selectRecipeIssuesCount
  >;

  if (issuesCount > 0) {
    yield put(recipePublishConfirmationRequired());
    const { publishCanceled } = (yield race({
      publishConfirmed: take(recipePublishConfirmed),
      publishCanceled: take(recipePublishCanceled),
    })) as {
      publishConfirmed: ReturnType<typeof recipePublishConfirmed> | undefined;
      publishCanceled: ReturnType<typeof recipePublishCanceled> | undefined;
    };
    if (publishCanceled) {
      return;
    }
  }

  yield put(recipePublishing());

  const response = (yield call(apiPublishRecipeSaga, {
    recipeId,
  })) as ApiRequestSagaReturnType<typeof apiPublishRecipeSaga>;

  if (!response.ok) {
    yield put(recipePublishFailed(response.details.message));
    yield call(publishErrorOccurred, response.details.platformError);
    return;
  }
  yield put(recipePublishSucceed());
}

export function* unpublishRecipe({
  payload: recipeId,
}: PayloadAction<string>): SagaIterator {
  yield put(recipeUnpublishing());

  const response = (yield call(apiUnpublishRecipeSaga, {
    recipeId,
  })) as ApiRequestSagaReturnType<typeof apiUnpublishRecipeSaga>;

  if (!response.ok) {
    yield put(recipeUnpublishFailed(response.details.message));
    yield call(publishErrorOccurred, response.details.platformError);
    return;
  }
  yield put(recipeUnpublishSucceed());
}

export function* publishErrorOccurred(
  platformError: ApiError | undefined
): SagaIterator {
  yield put(
    errorOccurred(
      ((): AppError => {
        switch (platformError?.error.type) {
          case ApiErrorType.InvalidOperation:
            return {
              message: platformError.error.message,
            };

          default:
            return errorSliceConstants.genericError;
        }
      })()
    )
  );
}

export function* fetchRecipe({
  payload: recipeId,
}: PayloadAction<string>): SagaIterator {
  const response = (yield call(apiFetchRecipeSaga, {
    recipeId,
  })) as ApiRequestSagaReturnType<typeof apiFetchRecipeSaga>;
  if (!response.ok) {
    yield put(recipeFetchFailed(response.details.message));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  const [applianceTags] = (yield all([
    call(getApplianceTags, response.data.locale),
    call(getAppliances, response.data.locale),
  ])) as [TagsById, ApplianceWithCapabilities[]];

  yield put(recipeFetchSucceed(fromApiRcpRecipe(response.data, applianceTags)));

  yield call(checkRecipeIncompatibilities);
}

export function* getAppliances(locale: ApiLocale): SagaIterator {
  // We need the appliances in order to check for incompatibilities
  // so we put the fetch action and if they are already in the state, they won't be fetched again
  const shouldFetchAppliances = (yield select(
    selectShouldFetchAppliances(locale)
  )) as ReturnType<ReturnType<typeof selectShouldFetchAppliances>>;
  if (shouldFetchAppliances) {
    yield put(appliancesFetchRequested({ locale }));
    yield take([appliancesFetchSucceed, appliancesFetchFailed]);
  }

  const isFetchingAppliances = (yield select(
    selectAppliancesFetching(locale)
  )) as ReturnType<ReturnType<typeof selectAppliancesFetching>>;
  if (isFetchingAppliances) {
    yield take([appliancesFetchSucceed, appliancesFetchFailed]);
  }

  return (yield select(selectAppliancesWithCapabilities(locale))) as ReturnType<
    ReturnType<typeof selectAppliancesWithCapabilities>
  >;
}

export function* recreateRecipeTags(): SagaIterator {
  const recipe = (yield select(selectRecipe)) as AppRecipe;

  const applianceTags = (yield call(
    getApplianceTags,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    recipe.locale!
  )) as TagsById;

  const recipeTags = [
    ...(recipe.applianceReferenceTags || []),
    ...(recipe.generalTags || []),
  ];
  yield put(
    recipeRecreateTagsSucceed({
      applianceReferenceTags: recipeTags.filter(
        (tag) => !!applianceTags[tag.id]
      ),
      generalTags: recipeTags.filter((tag) => !applianceTags[tag.id]),
    })
  );
}

export function* getRecipeFromUrl({
  payload: { url, locale },
}: PayloadAction<RecipeGetFromUrlPayload>): SagaIterator {
  const response = (yield call(apiGetRecipeFromUrlSaga, {
    url,
  })) as ApiRequestSagaReturnType<typeof apiGetRecipeFromUrlSaga>;
  if (!response.ok) {
    yield put(recipeFetchFailed(response.details.message));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  const applianceTags = (yield call(getApplianceTags, locale)) as TagsById;
  yield put(
    recipeFetchSucceed(
      fromApiRusRecipe(response.data, { applianceTags, locale })
    )
  );
  yield call(navigateSaga, {
    to: generateRecipeRoute({ tab: RecipeTabName.Information }),
  });
}

export function* getRecipeFromText({
  payload,
}: PayloadAction<ApiGetRecipeFromTextRequest>): SagaIterator {
  const response = (yield call(
    apiGetRecipeFromTextSaga,
    payload
  )) as ApiRequestSagaReturnType<typeof apiGetRecipeFromTextSaga>;
  if (!response.ok) {
    yield put(recipeFetchFailed(response.details.message));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  const applianceTags = (yield call(
    getApplianceTags,
    payload.locale
  )) as TagsById;
  yield put(
    recipeFetchSucceed(
      fromApiRusRecipe(response.data, { applianceTags, locale: payload.locale })
    )
  );
  yield call(navigateSaga, {
    to: generateRecipeRoute({ tab: RecipeTabName.Information }),
  });
}

function* recipeSaveBeforeLeavingRequestedWatcher(): SagaIterator {
  yield takeLatest(recipeSaveBeforeLeavingRequested, saveRecipe);
}

function* recipePublishWatcher(): SagaIterator {
  yield takeLatest(recipePublishRequested, publishRecipe);
}

function* recipeUnpublishWatcher(): SagaIterator {
  yield takeLatest(recipeUnpublishRequested, unpublishRecipe);
}

function* recipeFetchWatcher(): SagaIterator {
  yield takeLatest(recipeFetchRequested, fetchRecipe);
}

function* recipeGetFromUrlWatcher(): SagaIterator {
  yield takeLatest(recipeGetFromUrlRequested, getRecipeFromUrl);
}

function* recipeGetFromTextWatcher(): SagaIterator {
  yield takeLatest(recipeGetFromTextRequested, getRecipeFromText);
}

function* recreateRecipeTagsWatcher(): SagaIterator {
  yield takeLatest(recipeRecreateTagsRequested, recreateRecipeTags);
}

export function* autosaveRecipe(action: Action<string>): SagaIterator {
  /** Let the incoming action cancel the previous saga but do nothing else */
  if (recipeActionsThat.cancelAutosaving.includes(action.type)) {
    return;
  }

  yield put(recipeHasChanged(true));

  const { id, state } = (yield select(selectRecipe)) as ReturnType<
    typeof selectRecipe
  >;
  const isCreating = !id;
  const isPublished = state === ApiRcpRecipeState.Published;
  const isPublishing = (yield select(selectRecipePublishing)) as ReturnType<
    typeof selectRecipePublishing
  >;
  if (isCreating || isPublished || isPublishing) {
    return;
  }

  const isSaving = (yield select(selectRecipeSaving)) as ReturnType<
    typeof selectRecipeSaving
  >;
  if (isSaving) {
    // There has been a change while the recipe is being saved. Enqueue a new save request
    // so when the current save finished, it will start a new one with the new changes
    yield put(recipeSaveRequested(id));
    return;
  }

  yield delay(recipePageConstants.autosave.delay);
  yield put(recipeAutoSaveRequested(id));
}

export const shouldAutosave = (action: Action<string>): boolean =>
  recipeActionsThat.triggerAutosaving.includes(action.type) ||
  recipeActionsThat.cancelAutosaving.includes(action.type);

function* recipeAutosaveWatcher(): SagaIterator {
  yield takeLatest(shouldAutosave, autosaveRecipe);
}

export function* checkRecipeIncompatibilities(): SagaIterator {
  const recipe = (yield select(selectRecipe)) as ReturnType<
    typeof selectRecipe
  >;

  const appliances = (yield select(
    selectAppliancesWithCapabilities(recipe.locale!)
  )) as ReturnType<ReturnType<typeof selectAppliancesWithCapabilities>>;

  const appliancesToCheck: ApplianceWithCapabilities[] =
    appliances?.filter((appliance) =>
      recipe.applianceReferenceTags?.some((tag) =>
        appliance.referenceTagIds.includes(tag.id)
      )
    ) ?? [];

  for (let i = 0; i < recipe.steps.length; i++) {
    const step = recipe.steps[i];
    if (step.capability?.settings) {
      const incompatibilities = checkApplianceIncompatibilities({
        capability: step.capability,
        selectedSettings: step.capability.settings,
        appliances: appliancesToCheck,
      });
      yield put(
        recipeStepIncompatibilitiesUpdated({
          index: i,
          hasIncompatibilities: !isEmpty(incompatibilities),
        })
      );
    }
  }
}

export const shouldCheckIncompatibilities = (action: Action<string>): boolean =>
  action.type === recipeFieldUpdated.type &&
  (
    action as PayloadAction<{
      key: AppRecipeFieldUpdateKeys;
      value: AppRecipe[AppRecipeFieldUpdateKeys];
    }>
  ).payload.key === 'applianceReferenceTags';

function* recipeCheckIncompatibilitiesWatcher(): SagaIterator {
  yield takeLatest(shouldCheckIncompatibilities, checkRecipeIncompatibilities);
}

export function* trackRecipeStepIncompatibilities({
  payload,
  type,
}: PayloadAction<
  RecipeStepUpdatedPayload | RecipeStepAddedPayload
>): SagaIterator {
  const { step, incompatibilities } = payload;
  const recipe = (yield select(selectRecipe)) as ReturnType<
    typeof selectRecipe
  >;
  const stepNumber =
    type === recipeStepUpdated.type
      ? (payload as RecipeStepUpdatedPayload).index + 1
      : recipe.steps.length;

  if (step.hasIncompatibilities && step.capability) {
    yield call(trackEvent, {
      type: TrackedEventType.IncompatibilityStepSaved,
      recipeId: recipe.id,
      stepNumber,
      capabilityId: step.capability.id,
      applianceIds: Object.keys(incompatibilities) as ApiAplId[],
    });
  }
  if ((payload as RecipeStepUpdatedPayload).areIncompatibilitiesFixed) {
    yield call(trackEvent, {
      type: TrackedEventType.IncompatibilityStepResolved,
      recipeId: recipe.id,
      stepNumber,
      capabilityId: step.capability?.id,
    });
  }
}

function* recipeStepSavedWatcher(): SagaIterator {
  yield takeLatest(
    [recipeStepAdded, recipeStepUpdated],
    trackRecipeStepIncompatibilities
  );
}

export function* trackIssuesOnPublish(): SagaIterator {
  const recipe = (yield select(selectRecipe)) as ReturnType<
    typeof selectRecipe
  >;
  const incompatibilitiesCount = (yield select(
    selectRecipeIncompatibilitiesCount
  )) as ReturnType<typeof selectRecipeIncompatibilitiesCount>;

  if (incompatibilitiesCount) {
    yield call(trackEvent, {
      type: TrackedEventType.IncompatibilityPublished,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      recipeId: recipe.id!,
    });
  }

  const unusedIngredientsCount = (yield select(
    selectRecipeUnusedIngredientsCount
  )) as ReturnType<typeof selectRecipeUnusedIngredientsCount>;
  if (unusedIngredientsCount) {
    yield call(trackEvent, {
      type: TrackedEventType.UnusedIngredientsPublished,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      recipeId: recipe.id!,
    });
  }

  const ingredients = (yield select(selectRecipeIngredients)) as ReturnType<
    typeof selectRecipeIngredients
  >;
  if (!ingredients.length) {
    yield call(trackEvent, {
      type: TrackedEventType.NoIngredientsPublished,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      recipeId: recipe.id!,
    });
  }

  const steps = (yield select(selectRecipeSteps)) as ReturnType<
    typeof selectRecipeSteps
  >;
  if (!steps.length) {
    yield call(trackEvent, {
      type: TrackedEventType.NoStepsPublished,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      recipeId: recipe.id!,
    });
  }
}

function* recipePublishConfirmedWatcher(): SagaIterator {
  yield takeLatest(recipePublishConfirmed, trackIssuesOnPublish);
}

export const recipeRestartableSagas = [
  recipeFetchWatcher,
  recipeSaveWatcher,
  recipePublishWatcher,
  recipeUnpublishWatcher,
  recipeGetFromUrlWatcher,
  recipeGetFromTextWatcher,
  recipeAutosaveWatcher,
  recipeSaveBeforeLeavingRequestedWatcher,
  recipeCreationWatcher,
  recipeUpdateWatcher,
  recipeCheckIncompatibilitiesWatcher,
  recipeStepSavedWatcher,
  recipePublishConfirmedWatcher,
  recreateRecipeTagsWatcher,
];
