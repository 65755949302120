import {
  format as fnsFormat,
  parseISO,
  isYesterday as fnsIsYesterday,
  isToday as fnsIsToday,
  startOfToday,
  startOfYesterday,
} from 'date-fns';

import type { ApiDateISO8601 } from 'api/types/common/apiDateISO8601';

export const isYesterday = (date: Date) => fnsIsYesterday(date);

export const getToday = (): Date => startOfToday();

export const getYesterday = (): Date => startOfYesterday();

export const isToday = (date: Date) => fnsIsToday(date);

export const fromISOToDate = (date: ApiDateISO8601): Date => parseISO(date);

export const formatDate = (date: Date, format: string): string =>
  fnsFormat(date, format);

export const dateStrings = {
  today: (time: string) => `Today @ ${time}`,
  yesterday: 'Yesterday',
} as const;

export const formatDateForLists = (date: Date): string => {
  if (isToday(date)) {
    return dateStrings.today(formatDate(date, 'HH:mma'));
  }
  if (isYesterday(date)) {
    return dateStrings.yesterday;
  }
  return formatDate(date, 'd MMM, yyyy');
};
