import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { RootState } from 'app/store/rootReducer';
import type {
  CapabilitySettingsError,
  CapabilitySettingsErrors,
} from 'components/CapabilityField/CapabilityField.types';
import {
  generateCapabilitySettingsValidator,
  validateCapabilitySettings,
} from 'components/CapabilityField/CapabilityField.validations';
import type {
  CapabilitySettingFieldDependencies,
  CapabilitySettingFieldDependents,
} from 'components/CapabilityField/CapabilitySettingFieldDependencies';
import {
  areDependenciesMet,
  generateCapabilitySettingFieldDependsOn,
} from 'components/CapabilityField/CapabilitySettingFieldDependencies';
import { authSignOutFinished } from 'features/auth/authSlice';
import {
  recipeFetchRequested,
  recipeGetFromTextRequested,
  recipeGetFromUrlRequested,
  recipeReset,
  recipeIngredientDeleted,
  recipeIngredientUpdated,
  recipeDiscardChanges,
  recipeFromScratchRequested,
  recipeIngredientMoved,
  recipeStepAdded,
  updateIngredientQuantityInSteps,
} from 'features/recipe/recipeSlice';
import {
  areIngredientsSortedByIngredientIdx,
  sortIngredientsByIngredientIdx,
  updateStepIngredientsAfterMoving,
  updateStepIngredientsAfterDeleting,
} from 'features/recipe/shared/utils/recipeIngredientsUtils';
import { recipeStepValidator } from 'features/recipe/shared/validators/stepValidator';
import type { ApplianceIncompatibility } from 'features/recipe/steps/form/recipeStepsApplianceIncompatibilities.utils';
import type { AppCapability } from 'types/appCapability';
import { AppCapabilityType } from 'types/appCapability';
import type { AppCapabilityAllowedPhase } from 'types/appCapabilityAllowedPhase';
import type { AppCapabilityAllowedSetting } from 'types/appCapabilityAllowedSetting';
import type { AppRecipeStepCapabilitySetting } from 'types/recipe/appRecipeStepCapabilitySetting';
import type { AppRecipeStepIngredient } from 'types/recipe/appRecipeStepIngredient';
import type { ValidatorError } from 'utils/validator';

export type Settings = Record<ApiEntityId, AppRecipeStepCapabilitySetting>;

export interface Errors {
  text?: ValidatorError;
  settings?: CapabilitySettingsErrors;
}

export interface RecipeStepsFormState {
  text: string;
  ingredients: AppRecipeStepIngredient[];
  capabilityType: AppCapabilityType;
  capability: AppCapability | null;
  phase: AppCapabilityAllowedPhase | null;
  settings: Settings;
  settingsDependencies: CapabilitySettingFieldDependencies | null;
  errors: Errors;
  submitted: boolean;
  index: number | null;
  isUnsaved: boolean;
  isShowing: boolean;
  appliancesIncompatibilities: ApplianceIncompatibility;
  isAutoConversionEnabled: Record<ApiEntityId, boolean>; // It stores the state of the auto conversion for each setting
}

export const initialState: RecipeStepsFormState = {
  text: '',
  ingredients: [],
  capabilityType: AppCapabilityType.General,
  capability: null,
  phase: null,
  settings: {},
  settingsDependencies: null,
  errors: recipeStepValidator.validate({ text: '' }) ?? {},
  submitted: false,
  index: null,
  isUnsaved: false,
  isShowing: true,
  appliancesIncompatibilities: {},
  isAutoConversionEnabled: {},
};

const resetStateActions = [
  authSignOutFinished.type,
  recipeReset.type,
  recipeFetchRequested.type,
  recipeFromScratchRequested.type,
  recipeGetFromTextRequested.type,
  recipeGetFromUrlRequested.type,
];
const shouldResetState = (action: Action<string>) =>
  resetStateActions.includes(action.type);

export const recipeStepsFormSlice = createSlice({
  name: 'recipeStepsFormSlice',
  initialState,
  reducers: {
    stepFormPopulated(
      state,
      {
        payload: {
          text,
          ingredients,
          capabilityType,
          capability,
          phase,
          settings,
        },
      }: PayloadAction<{
        text: string;
        ingredients?: AppRecipeStepIngredient[];
        capabilityType: AppCapabilityType;
        capability: AppCapability | null;
        phase: AppCapabilityAllowedPhase | null;
        settings: AppRecipeStepCapabilitySetting[];
      }>
    ) {
      state.submitted = false;
      state.text = text;
      state.ingredients = ingredients ?? [];
      state.capabilityType = capabilityType;
      state.capability = capability;
      state.phase = phase;
      state.settings = settingsToDictionary(settings);

      setFormErrors({
        text: state.text,
        capability: state.capability,
        settings: Object.values(state.settings),
        errors: state.errors,
      });
    },
    stepIndexUpdated(state, { payload }: PayloadAction<number | null>) {
      state.index = payload;
    },
    stepTextUpdated(state, { payload: text }: PayloadAction<string>) {
      state.text = text;
      setTextErrors({ text, errors: state.errors });
    },
    stepIngredientsSet(
      state,
      {
        payload: ingredients,
      }: PayloadAction<AppRecipeStepIngredient[] | undefined>
    ) {
      state.ingredients = ingredients ?? [];
    },
    stepIngredientAdded(
      state,
      {
        payload: ingredients,
      }: PayloadAction<AppRecipeStepIngredient[] | undefined>
    ) {
      const wereIngredientsSorted = areIngredientsSortedByIngredientIdx(
        state.ingredients
      );

      const ingredientsToAdd = differenceWith(
        ingredients,
        state.ingredients,
        isEqual
      );
      ingredientsToAdd.forEach((ingredient) =>
        state.ingredients.push(ingredient)
      );

      if (wereIngredientsSorted) {
        state.ingredients = sortIngredientsByIngredientIdx(state.ingredients);
      }
    },
    stepIngredientMoved(
      state,
      { payload: { from, to } }: PayloadAction<{ from: number; to: number }>
    ) {
      const ingredientToMove = state.ingredients.splice(from, 1)[0];
      state.ingredients.splice(to, 0, ingredientToMove);
    },
    stepCapabilityTypeUpdated(
      state,
      { payload: capabilityType }: PayloadAction<AppCapabilityType>
    ) {
      state.capabilityType = capabilityType;
    },
    stepCapabilityUpdated(
      state,
      { payload: capability }: PayloadAction<AppCapability | null>
    ) {
      state.capability = capability;
      state.phase = null;
      state.appliancesIncompatibilities = {};
      delete state.errors.settings;
      state.isAutoConversionEnabled = {};
    },
    stepPhaseUpdated(
      state,
      { payload: phase }: PayloadAction<AppCapabilityAllowedPhase | null>
    ) {
      state.phase = phase;
    },
    stepSettingUpdated(
      state,
      {
        payload: { settingId, setting },
      }: PayloadAction<{
        settingId: ApiEntityId;
        setting: AppRecipeStepCapabilitySetting | null;
      }>
    ) {
      const clearSetting = (id: ApiEntityId) => delete state.settings[id];

      const dependents =
        state.settingsDependencies?.dependents[settingId] || [];

      if (!setting) {
        clearSetting(settingId);
        Object.values(dependents).forEach((dependent) =>
          clearSetting(dependent.setting.id)
        );
        delete state.errors.settings?.[settingId];
        return;
      }

      state.settings[settingId] = setting;
      Object.values(dependents).forEach((dependent) => {
        const wereDependenciesMet = areDependenciesMet(
          { [setting.id]: dependent.dependency },
          [setting]
        );
        if (!wereDependenciesMet) {
          clearSetting(dependent.setting.id);
          return;
        }
        const hasDependencyValue = !!state.settings[dependent.setting.id];
        if (hasDependencyValue) {
          return;
        }
        if (dependent.setting.defaultValue) {
          state.settings[dependent.setting.id] = {
            id: dependent.setting.id,
            name: dependent.setting.name,
            value: dependent.setting.defaultValue,
          };
        }
      });

      setSettingsErrors({
        capability: state.capability,
        settings: Object.values(state.settings),
        errors: state.errors,
      });
    },
    stepSettingsUpdated(
      state,
      { payload: settings }: PayloadAction<AppRecipeStepCapabilitySetting[]>
    ) {
      state.settings = settingsToDictionary(settings);

      setSettingsErrors({
        capability: state.capability,
        settings: Object.values(state.settings),
        errors: state.errors,
      });
    },
    stepIncompatibilitiesUpdated(
      state,
      { payload }: PayloadAction<ApplianceIncompatibility>
    ) {
      state.appliancesIncompatibilities = payload;
    },
    stepSubmitted(state, { payload: submitted }: PayloadAction<boolean>) {
      state.submitted = submitted;
    },
    stepSettingsDependenciesUpdated(
      state,
      { payload: settings }: PayloadAction<AppCapabilityAllowedSetting[]>
    ) {
      if (!settings.length) {
        state.settingsDependencies = null;
        return;
      }
      state.settingsDependencies =
        settings.reduce<CapabilitySettingFieldDependencies>(
          (dependencies, setting) => {
            if (!setting.dependsOnSetting) {
              return dependencies;
            }
            return {
              ...dependencies,
              dependsOn: {
                ...dependencies.dependsOn,
                [setting.id]: generateCapabilitySettingFieldDependsOn(
                  setting.dependsOnSetting
                ),
              },
              dependents: {
                ...dependencies.dependents,
                ...setting.dependsOnSetting.reduce<CapabilitySettingFieldDependents>(
                  (previous, dependency) => {
                    return {
                      ...previous,
                      [dependency.referenceSettingId]: {
                        ...dependencies.dependents[
                          dependency.referenceSettingId
                        ],
                        ...previous[dependency.referenceSettingId],
                        [setting.id]: {
                          setting,
                          dependency: dependency.allowedValues,
                        },
                      },
                    };
                  },
                  {}
                ),
              },
            };
          },
          { dependents: {}, dependsOn: {} }
        );
    },
    stepReset(state) {
      // Do not reset whether the form is shown or not
      return { ...initialState, isShowing: state.isShowing };
    },
    stepFormShown(state, { payload: isShowing }: PayloadAction<boolean>) {
      if (!isShowing) {
        return { ...initialState, isShowing };
      }
      state.isShowing = isShowing;
      return state;
    },
    stepHasChanged(
      state,
      { payload: { hasChanged } }: PayloadAction<{ hasChanged: boolean }>
    ) {
      state.isUnsaved = hasChanged;
    },
    stepSettingAutoConversionToggled(
      state,
      {
        payload: { settingId, currentValue },
      }: PayloadAction<{ settingId: ApiEntityId; currentValue: boolean }>
    ) {
      state.isAutoConversionEnabled[settingId] = !currentValue;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        recipeIngredientDeleted,
        (state, { payload: ingredientIdxToRemove }) => {
          state.ingredients = updateStepIngredientsAfterDeleting(
            state.ingredients,
            ingredientIdxToRemove
          );
        }
      )
      .addCase(
        recipeIngredientUpdated,
        (state, { payload: { index, ingredient } }) => {
          for (const [
            iterator,
            stepIngredient,
          ] of state.ingredients.entries()) {
            if (stepIngredient.ingredientIdx !== index) {
              continue;
            }

            const oldTotalQuantity =
              state.ingredients[iterator].ingredient.quantity;
            stepIngredient.ingredient = ingredient;
            updateIngredientQuantityInSteps({
              oldTotalQuantity,
              newTotalQuantity: ingredient.quantity,
              stepIngredient,
            });
          }
        }
      )
      .addCase(recipeIngredientMoved, (state, { payload: { from, to } }) => {
        state.ingredients = updateStepIngredientsAfterMoving(
          state.ingredients,
          from,
          to
        );
      })
      .addCase(recipeDiscardChanges, () => initialState)
      .addCase(recipeStepAdded, (state) => ({
        ...initialState,
        isShowing: state.isShowing,
      }))
      .addMatcher(shouldResetState, () => initialState);
  },
});

export const {
  reducer: recipeStepsFormReducer,
  actions: {
    stepFormPopulated,
    stepIndexUpdated,
    stepTextUpdated,
    stepIngredientsSet,
    stepIngredientAdded,
    stepIngredientMoved,
    stepCapabilityTypeUpdated,
    stepCapabilityUpdated,
    stepPhaseUpdated,
    stepSettingUpdated,
    stepSettingsUpdated,
    stepIncompatibilitiesUpdated,
    stepSettingsDependenciesUpdated,
    stepSubmitted,
    stepReset,
    stepFormShown,
    stepHasChanged,
    stepSettingAutoConversionToggled,
  },
} = recipeStepsFormSlice;

const selectState = (state: RootState) => state.recipeStepsForm;

export const selectIndex = (state: RootState) => selectState(state).index;

export const selectText = (state: RootState) => selectState(state).text;

export const selectIngredients = (state: RootState) =>
  selectState(state).ingredients;

export const selectCapabilityType = (state: RootState) =>
  selectState(state).capabilityType;

export const selectCapability = (state: RootState) =>
  selectState(state).capability;

export const selectPhase = (state: RootState) => selectState(state).phase;

export const selectErrors = (state: RootState) => selectState(state).errors;

export const selectErrorBySettingId =
  (settingId: ApiEntityId) =>
  (state: RootState): CapabilitySettingsError | undefined =>
    (selectState(state).errors.settings || {})[settingId];

export const selectHasErrors = (state: RootState) =>
  !!Object.values(selectState(state).errors).length;

export const selectSettings = (state: RootState) => selectState(state).settings;

export const selectSettingsDependencies = (state: RootState) =>
  selectState(state).settingsDependencies;

export const selectSettingById =
  (settingId: ApiEntityId) =>
  (state: RootState): AppRecipeStepCapabilitySetting | undefined =>
    selectState(state).settings[settingId];

export const selectSubmitted = (state: RootState) =>
  selectState(state).submitted;

export const selectIsStepUnsaved = (state: RootState) =>
  selectState(state).isUnsaved;

export const selectIsFormShowing = (state: RootState) =>
  selectState(state).isShowing;

export const selectAppliancesIncompatibilities = (state: RootState) =>
  selectState(state).appliancesIncompatibilities;

export const selectIsSettingAutoConversionEnabled = (settingId: ApiEntityId) =>
  createSelector(selectState, ({ isAutoConversionEnabled }) =>
    settingId in isAutoConversionEnabled
      ? isAutoConversionEnabled[settingId]
      : true
  );

export const setSettingsErrors = ({
  capability,
  settings,
  errors,
}: {
  capability: AppCapability | null;
  settings: AppRecipeStepCapabilitySetting[];
  errors: Errors;
}) => {
  const validator = capability
    ? generateCapabilitySettingsValidator(capability.allowedSettings)
    : {};

  const settingErrors = validateCapabilitySettings(validator, settings);
  if (!settingErrors) {
    delete errors.settings;
  } else {
    errors.settings = settingErrors;
  }
};

const setTextErrors = ({
  text,
  errors,
}: {
  text: string | null;
  errors: Errors;
}) => {
  const error = recipeStepValidator.validateField('text', text);
  if (!error) {
    delete errors.text;
  } else {
    errors.text = error;
  }
};

const setFormErrors = ({
  text,
  capability,
  settings,
  errors,
}: {
  text: string | null;
  capability: AppCapability | null;
  settings: AppRecipeStepCapabilitySetting[];
  errors: Errors;
}) => {
  setTextErrors({ text, errors });
  setSettingsErrors({ capability, settings, errors });
};

const settingsToDictionary = (
  settings: AppRecipeStepCapabilitySetting[]
): Settings =>
  settings.reduce(
    (currentSettings, setting) => ({
      ...currentSettings,
      [setting.id]: setting,
    }),
    {}
  );
