import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/store/rootReducer';
import { AppErrorType, type AppError } from 'types/appError';

export interface ErrorState {
  errors: AppError[];
}
export const initialState: ErrorState = {
  errors: [],
};

export const errorSlice = createSlice({
  name: 'errorSlice',
  initialState,
  reducers: {
    errorOccurred(
      state,
      {
        payload: { message, description, type = AppErrorType.Informative },
      }: PayloadAction<AppError>
    ) {
      state.errors.push({
        message,
        description,
        type,
      });
    },
    errorDismissed(state, { payload: index }: PayloadAction<number>) {
      state.errors.splice(index, 1);
    },
  },
});

export const {
  reducer: errorReducer,
  actions: { errorOccurred, errorDismissed },
} = errorSlice;

export const selectErrors = (state: RootState) => state.error.errors;
