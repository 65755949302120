import type { FC, ReactNode } from 'react';
import { memo } from 'react';

/**
 * This component is used to help developers read the grid in the RecipeTransation component as a structure of rows + columns. It generates no DOM.
 */
export const RecipeTranslationRow: FC<{ children: ReactNode }> = memo(
  function RecipeTranslationRow({ children }) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
);
