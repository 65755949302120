import upperFirst from 'lodash/upperFirst';

import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRcpRecipeStepCapability } from 'api/types/recipe/apiRcpRecipeStepCapability';
import type { AppCapabilityType } from 'types/appCapability';
import { getCapabilityType } from 'types/appCapability';
import {
  fromApiCapabilityAllowedPhase,
  type AppCapabilityAllowedPhase,
} from 'types/appCapabilityAllowedPhase';
import type { AppRecipeStepCapabilitySetting } from 'types/recipe/appRecipeStepCapabilitySetting';
import { fromApiRcpRecipeStepCapabilitySetting } from 'types/recipe/appRecipeStepCapabilitySetting';

export interface AppRecipeStepCapability {
  id: ApiEntityId;
  name: string;
  type: AppCapabilityType;
  settings?: AppRecipeStepCapabilitySetting[];
  phase?: AppCapabilityAllowedPhase;
}

export function fromApiRcpRecipeStepCapability({
  referenceCapability,
  settings,
  phase,
}: ApiRcpRecipeStepCapability): AppRecipeStepCapability {
  return {
    id: referenceCapability.id,
    name: upperFirst(referenceCapability.name),
    type: getCapabilityType(referenceCapability.id),
    ...(settings && {
      settings: settings?.map(fromApiRcpRecipeStepCapabilitySetting),
    }),
    phase: phase && fromApiCapabilityAllowedPhase(phase),
  };
}
