import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from '@dropkitchen/pantry-react';
import { useTheme } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { Dialog } from 'components/Dialog/Dialog';
import { errorDialogStrings } from 'features/error/ErrorDialog.constants';
import { errorDismissed, selectErrors } from 'features/error/errorSlice';
import { AppErrorType } from 'types/appError';

const { title, buttons } = errorDialogStrings;

export const ErrorDialog: FC = function ErrorDialog() {
  const dispatch = useAppDispatch();
  const errors = useAppSelector(selectErrors);
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (errors.length) {
      setIsOpen(true);
    }
  }, [errors]);

  useEffect(() => {
    if (isOpen || !errors.length) {
      return;
    }
    /** Wait for the close animation to finish so the error message doesn't disappear from the modal while it's being closed */
    const timeoutId = setTimeout(
      () => dispatch(errorDismissed(0)),
      theme.transitions.duration.leavingScreen
    );
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const { description, message, type } = errors[0] || {};
  return (
    <Dialog
      title={description || title}
      isOpen={isOpen}
      hasCloseButton={false}
      onClose={() => {}}
      onSubmit={() => setIsOpen(false)}
      size="sm"
      components={{
        content: message,
        buttons: <DialogButton errorType={type} />,
      }}
    />
  );
};

interface DialogButtonProps {
  errorType: AppErrorType | undefined;
}

const DialogButton: FC<DialogButtonProps> = function DialogButton({
  errorType,
}) {
  const navigate = useNavigate();

  const handleReloadPageClicked = () => {
    navigate(0);
  };

  return errorType === AppErrorType.Blocking ? (
    <Button
      label={buttons.reloadPage}
      type={ButtonType.Button}
      buttonStyle={ButtonStyle.Fresco}
      size={ButtonSize.Large}
      onClick={handleReloadPageClicked}
    />
  ) : (
    <Button
      label={buttons.ok}
      type={ButtonType.Submit}
      buttonStyle={ButtonStyle.Fresco}
      size={ButtonSize.Large}
    />
  );
};
