import type { ReactNode } from 'react';

import type { ApiTag } from 'api/types/common/apiTag';
import type { ChipStyle } from 'components/Chip/Chip';
import { Chip } from 'components/Chip/Chip';
import { appCommonStrings } from 'types/appCommon.constants';

export const generateChipsFromTags = (
  tags?: ApiTag[],
  chipStyle?: ChipStyle
): ReactNode => {
  return tags?.length
    ? tags.map((tag) => (
        <Chip label={tag.name} key={tag.id} style={chipStyle} />
      ))
    : appCommonStrings.placeholders.noValue;
};
