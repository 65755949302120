export enum ApiErrorType {
  Validation = 'ValidationError',
  PreconditionFailed = 'PreconditionFailedError',
  InvalidOperation = 'InvalidOperationError',
}

export type ApiError = ApiBaseError | ApiValidationError;

export interface ApiBaseError {
  error: ApiErrorDetails;
}

export interface ApiErrorDetails {
  message: string;
  type: string;
}

/**
 * An error indicating that the request parameters are invalid.
 */
export interface ApiValidationError {
  error: ApiValidationErrorDetails;
}

export interface ApiValidationErrorDetails {
  message: ApiValidationErrorDetailsMessage[];
  type: ApiErrorType.Validation;
}

export interface ApiValidationErrorDetailsMessage {
  /**
   * The path to the invalid value. The path is an array combining keys and indexes.
   */
  path: (string | number)[];
  detail: string;
}
