export const homeFeedPreviewStrings = {
  title: 'Preview Feed',
  labels: {
    applianceTagsField: 'Appliance(s)',
    closeButton: 'Close',
    getCollectionTotal: (collectionTotal: number) =>
      `Collection total: ${collectionTotal} / ${homeFeedPreviewConstants.collectionsLimit}`,
    completeHomeFeed:
      "Your homefeed is completed, you have successfully reached the total number of collections that can be seen on an app's homefeed.",
  },
  placeholders: {
    applianceTagsField: 'Select appliance(s)',
  },
  emptyHomeFeed: {
    title: 'No collections found',
    text: "We couldn't find any collections. Try previewing again with new criteria.",
  },
} as const;

export const homeFeedPreviewConstants = {
  fieldIds: {
    applianceTags: 'preview-appliance-tags',
  },
  collectionsLimit: 8,
} as const;
