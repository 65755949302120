export const recipeSagasStrings = {
  errors: {
    validation: {
      title: 'Missing required fields',
      message:
        'This recipe contains one or more invalid fields. To save, please find the errors on the review tab and amend any issues.',
    },
    precondition: {
      title: 'Syncing conflict',
      message:
        'This recipe has been modified by someone else since your last set of saved changes, please review the updated recipe and place in any additional revisions required.',
    },
  },
} as const;
