import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { frescoAdditionalShadows, stylesCenterContent } from 'app/theme';

export interface ListLayoutProps {
  title: string;
  children: ReactNode;
  actionComponent?: ReactNode;
  shouldWrapChildren?: boolean;
}

export const ListLayout: FC<ListLayoutProps> = memo(function ListLayout({
  title,
  children,
  actionComponent,
  shouldWrapChildren = true,
}) {
  return (
    <>
      <Box
        sx={{
          height: 287,
          backgroundColor: PantryColor.FrescoPrimary,
          width: '100%',
          boxShadow: frescoAdditionalShadows.header,
        }}
      >
        <Grid
          container
          sx={{ ...stylesCenterContent, pt: 16 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              variant={PantryTypography.H4}
              color={PantryColor.TextContrast}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item>{actionComponent}</Grid>
        </Grid>
      </Box>
      <Box sx={{ ...stylesCenterContent }}>
        <Box
          sx={{
            mt: -26,
          }}
        >
          {shouldWrapChildren ? (
            <Paper sx={{ p: 8, boxShadow: '0px 4px 40px rgb(0 0 0 / 10%)' }}>
              {children}
            </Paper>
          ) : (
            children
          )}
        </Box>
      </Box>
    </>
  );
});
