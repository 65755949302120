import { createAction, type PayloadAction } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import type { ApiRequestSagaReturnType } from 'api/createApiRequestSaga';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
import type { ApiGetHomeFeedRequest } from 'api/homeFeed';
import { apiGetHomeFeed, apiPutHomeFeed } from 'api/homeFeed';
import type { ApiResponse } from 'api/types';
import type { ApiError } from 'api/types/common/apiError';
import { ApiErrorType } from 'api/types/common/apiError';
import type { ApiCcCuratedCollection } from 'api/types/curatedCollection/apiCcCuratedCollection';
import type { ApiHfHomeFeed } from 'api/types/homeFeed/apiHfHomeFeed';
import { errorOccurred } from 'features/error/errorSlice';
import { errorSliceConstants } from 'features/error/errorSlice.constants';
import { homeFeedSagasStrings } from 'features/homeFeed/HomeFeed/homeFeedSagas.constants';
import type { HomeFeedFetchRequestedPayload } from 'features/homeFeed/HomeFeed/homeFeedSlice';
import {
  homeFeedFetchFailed,
  homeFeedFetchRequested,
  homeFeedFetchSucceed,
  homeFeedUpdatePending,
  homeFeedUpdateSucceed,
  homeFeedUpdateFailed,
  selectHomeFeed,
} from 'features/homeFeed/HomeFeed/homeFeedSlice';
import { homeFeedPreviewConstants } from 'features/homeFeed/HomeFeedPreview/homeFeedPreview.constants';
import type { AppError } from 'types/appError';

export const apiFetchHomeFeedSaga = createApiRequestSaga(apiGetHomeFeed);
export const apiPutHomeFeedSaga = createApiRequestSaga(apiPutHomeFeed);

export function* fetchHomeFeed({
  payload: { locale, applianceTags, preview },
}: PayloadAction<HomeFeedFetchRequestedPayload>) {
  const request = preview
    ? () =>
        call(apiFetchHomeFeedSaga, {
          locale,
          applianceTagIds: applianceTags?.map(({ id }) => id),
          preview,
          limit: homeFeedPreviewConstants.collectionsLimit,
        })
    : () =>
        call(fetchCollectionsRecursively, {
          locale,
          applianceTagIds: applianceTags?.map(({ id }) => id),
        });
  const fetchResponse = (yield request()) as ApiResponse<ApiHfHomeFeed>;

  if (!fetchResponse.ok) {
    yield put(homeFeedFetchFailed(fetchResponse.details.message));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  yield put(homeFeedFetchSucceed(fetchResponse.data));
}

function* homeFeedFetchWatcher() {
  yield takeLatest(homeFeedFetchRequested, fetchHomeFeed);
}

export function* fetchCollectionsRecursively(request: ApiGetHomeFeedRequest) {
  const collections: ApiCcCuratedCollection[] = [];
  const firstResponse = (yield call(
    apiFetchHomeFeedSaga,
    request
  )) as ApiRequestSagaReturnType<typeof apiFetchHomeFeedSaga>;

  if (!firstResponse.ok) {
    return firstResponse;
  }

  collections.push(...firstResponse.data.items);
  let { next } = firstResponse.data;

  while (next) {
    const response = (yield call(apiFetchHomeFeedSaga, {
      ...request,
      cursor: next,
    })) as ApiRequestSagaReturnType<typeof apiFetchHomeFeedSaga>;

    if (!response.ok) {
      return response;
    }

    collections.push(...response.data.items);
    next = response.data.next;
  }
  return {
    ok: true,
    data: { ...firstResponse.data, items: collections, next: null },
  };
}

export const updateHomeFeedRequested = createAction<ApiHfHomeFeed>(
  'homeFeedSlice/updateHomeFeedRequested'
);

export function* requestUpdateHomeFeed({
  payload: homeFeed,
}: ReturnType<typeof updateHomeFeedRequested>) {
  const originalHomeFeed = (yield select(selectHomeFeed)) as ReturnType<
    typeof selectHomeFeed
  >;

  if (!originalHomeFeed) {
    return;
  }

  yield put(homeFeedUpdatePending(homeFeed));

  const updateHomeFeedResponse = (yield call(apiPutHomeFeedSaga, {
    locale: homeFeed.locale,
    etag: homeFeed.etag,
    homeFeed: {
      items: homeFeed.items.map(({ id }) => ({ id })),
    },
  })) as ApiRequestSagaReturnType<typeof apiPutHomeFeedSaga>;

  if (!updateHomeFeedResponse.ok) {
    yield put(homeFeedUpdateFailed(originalHomeFeed));
    yield call(
      homeFeedSaveErrorOcurred,
      updateHomeFeedResponse.details.platformError
    );
    return;
  }

  yield put(
    homeFeedUpdateSucceed({
      etag: updateHomeFeedResponse.data.etag,
    })
  );
}

/**
 * Formats recipe save error and dispatches the `errorOccurred` action
 */
export function* homeFeedSaveErrorOcurred(
  platformError: ApiError | undefined
): SagaIterator {
  yield put(
    errorOccurred(
      ((): AppError => {
        switch (platformError?.error.type) {
          case ApiErrorType.PreconditionFailed:
            return {
              description: homeFeedSagasStrings.errors.precondition.title,
              message: homeFeedSagasStrings.errors.precondition.message,
            };
          case ApiErrorType.Validation:
            return {
              description: homeFeedSagasStrings.errors.validation.title,
              message: homeFeedSagasStrings.errors.validation.message,
            };
          default:
            return errorSliceConstants.genericError;
        }
      })()
    )
  );
}

export function* updateHomeFeedRequestedWatcher() {
  yield takeEvery(updateHomeFeedRequested, requestUpdateHomeFeed);
}

export const homeFeedRestartableSagas = [
  homeFeedFetchWatcher,
  updateHomeFeedRequestedWatcher,
];
