import { PantryColor } from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';

import { stylesCenterContent } from 'app/theme';
import { recipePageStrings } from 'features/recipe/RecipePage.constants';
import { recipeBasicInformationStrings } from 'features/recipe/recipeBasicInformation/RecipeBasicInformation.constants';
import { recipeReviewStrings } from 'features/recipe/review/RecipeReview.constants';
import { recipeReviewIngredientsStrings } from 'features/recipe/review/ingredients/RecipeReviewIngredients.constants';
import { recipeReviewStepsStrings } from 'features/recipe/review/steps/RecipeReviewSteps.constants';
import { appCommonStrings } from 'types/appCommon.constants';

export const recipeTranslationStrings = {
  labels: {
    authorField: 'Recipe author',
    recipeNameField: recipeBasicInformationStrings.labels.recipeNameField,
    descriptionField: recipeBasicInformationStrings.labels.descriptionField,
    applianceTagsField: recipeBasicInformationStrings.labels.applianceTagsField,
    prepTimeField: recipeBasicInformationStrings.labels.prepTimeField,
    cookTimeField: recipeBasicInformationStrings.labels.cookTimeField,
    totalTimeField: recipeBasicInformationStrings.labels.totalTimeField,
    generalTagsField: 'Tags',
    servesField: recipeBasicInformationStrings.labels.servesField,
    fetching: 'Loading recipes',
    defaultLocale: 'Default locale',
    activeLocale: 'Active locale',
    editableFieldsOnly: 'Show editable fields only',
    baseRecipe: 'Base recipe',
    translatedRecipe: 'Translated recipe',
    recipeAriaLabel(label: string) {
      return `${this.baseRecipe} - ${label}`;
    },
    translatedRecipeAriaLabel(label: string) {
      return `${this.translatedRecipe} - ${label}`;
    },
    saveButton: recipePageStrings.labels.saveButton,
  },
  placeholders: {
    noValue: appCommonStrings.placeholders.noValue,
  },
  errors: {
    image: recipeReviewStrings.errors.image,
    translationNotFound:
      'Recipe not found. Sorry, your locale request could not be located',
  },
  sections: {
    basicInformation: 'Details',
    ingredients: recipeReviewStrings.sections.ingredients,
    steps: recipeReviewStrings.sections.steps,
  },
  messages: {
    emptyIngredients: recipeReviewIngredientsStrings.messages.empty,
    emptySteps: recipeReviewStepsStrings.messages.empty,
  },
} as const;

export const recipeTranslationStyles: Record<string, SxProps> = {
  container: {
    ...stylesCenterContent,
    backgroundColor: PantryColor.SurfaceDefault,
    boxShadow: '0px 24px 40px rgba(0, 0, 0, 0.06)',
    px: 7.5,
    py: 4,
    mt: 8,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: 10,
  },
  sectionTitle: {
    mt: 5,
  },
} as const;
