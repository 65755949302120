import { createApiFn, fetchJson } from 'api/fetchJson';
import { apiIngredientsUrl } from 'api/paths';
import type { ApiList, ApiRequestFn } from 'api/types';
import { HttpMethod } from 'api/types';
import type { ApiIngredient } from 'api/types/common/apiIngredient';
import type { ApiRefLocalizedDataRequest } from 'api/types/referenceData/apiRefLocalizedDataRequest';
import { appConfig } from 'config/config';

export const getIngredients = async ({
  from,
  locale,
}: ApiRefLocalizedDataRequest) =>
  fetchJson<ApiList<ApiIngredient>>({
    httpMethod: HttpMethod.Post,
    url: `${apiIngredientsUrl}?size=${appConfig.referenceDataApiPageSize()}&from=${from}`,
    body: { locale },
  });

export const apiGetIngredients: ApiRequestFn<
  ApiList<ApiIngredient>,
  ApiRefLocalizedDataRequest
> = createApiFn(getIngredients);
