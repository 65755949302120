import type { FC, ReactElement } from 'react';
import { memo } from 'react';

import { useAppSelector } from 'app/store/hooks';
import {
  selectConfigsFeatures,
  selectConfigsIsFeatureEnabled,
} from 'features/configs/configsSlice';
import type { AppFeature } from 'types/appFeature';

export interface ToggleableFeatureProps {
  requires: AppFeature;
  children: ReactElement;
  components: {
    whenDisabled: ReactElement | null;
  };
}

export const ToggleableFeature: FC<ToggleableFeatureProps> = memo(
  function ToggleableFeature({
    requires: requiredFeature,
    components,
    children,
  }) {
    const features = useAppSelector(selectConfigsFeatures);
    const isFeatureEnabled = useAppSelector(
      selectConfigsIsFeatureEnabled(requiredFeature)
    );

    /** Wait for the features to be set based on the configuration before validating */
    if (features === undefined) {
      return null;
    }

    if (!isFeatureEnabled) {
      return components.whenDisabled;
    }

    return children;
  }
);
