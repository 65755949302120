import {
  ExpandMoreIcon,
  PantryColor,
  PantryTypography,
  Spinner,
} from '@dropkitchen/pantry-react';
import type { SxProps, Theme } from '@mui/material';
import {
  Box,
  useTheme,
  TextField,
  MenuItem,
  Paper,
  Select,
  InputLabel,
  Typography,
  InputAdornment,
  inputClasses,
  selectClasses,
} from '@mui/material';
import uniqBy from 'lodash/uniqBy';
import type { FC } from 'react';
import { useEffect, useState, memo, useMemo } from 'react';

import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import { capabilitySettingFieldConstants } from 'components/CapabilityField/CapabilitySettingField.constants';
import { ErrorHelperText } from 'components/ErrorHelperText/ErrorHelperText';
import type { AppNullable } from 'types/appNullable';
import { AppQuantityChangeTriggeredByField } from 'types/appQuantityChangeTriggeredBy';
import type { AppRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';
import type { ValidatorError } from 'utils/validator';

const borderWidth = 1;
const paddingX = 14;
const paddingY = 11;

export interface QuantityFieldProps {
  quantity: AppNullable<AppRecipeValueWithUnit> | null;
  units: ApiQuantityUnit[];
  onChange: (
    quantity: {
      amount: number | null;
      unit: ApiQuantityUnit;
    },
    triggeredBy: AppQuantityChangeTriggeredByField
  ) => void;
  placeholder: string;
  isLoadingValue?: boolean;
  errors?: ValidatorError;
  hideLabel?: boolean;
  id?: string;
  sx?: SxProps<Theme>;
}

export const QuantityField: FC<QuantityFieldProps> = memo(
  function QuantityField({
    quantity,
    units,
    onChange,
    placeholder,
    isLoadingValue,
    errors = {},
    hideLabel,
    id: fieldId,
    sx,
  }) {
    const [unit, setUnit] = useState<ApiQuantityUnit | null>(null);
    const [amount, setAmount] = useState<number | null>(null);
    const theme = useTheme();

    const uniqueUnits = useMemo(
      () => uniqBy(units, (item) => item.id),
      [units]
    );

    useEffect(() => {
      setAmount(quantity?.amount ?? null);
      const unitId = quantity?.unit?.id;
      const selectedUnit = unitId && units.find(({ id }) => id === unitId);
      setUnit(selectedUnit || units[0]);
    }, [quantity, units]);

    const handleAmountChange = (value: number | null): void => {
      setAmount(value);
      if (value === 0 || !unit) {
        return;
      }
      onChange(
        { amount: value, unit },
        AppQuantityChangeTriggeredByField.Amount
      );
    };

    const handleUnitChange = (unitId: string): void => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const selectedUnit = units.find(({ id }) => id === unitId)!;
      setUnit(selectedUnit);
      if (!amount) {
        return;
      }
      onChange(
        {
          amount: +amount,
          unit: selectedUnit,
        },
        AppQuantityChangeTriggeredByField.Unit
      );
    };

    const hasErrors = !!Object.values(errors).length;
    const errorMessage = Object.values(errors).join(
      capabilitySettingFieldConstants.errorsJoinWord
    );

    return (
      <Box data-testid={fieldId} sx={sx}>
        {!hideLabel && unit && (
          <InputLabel htmlFor={fieldId} sx={{ mb: 2 }}>
            <Typography
              variant={PantryTypography.Body2SemiBold}
              color={PantryColor.TextSubtle}
            >
              {unit.name}
            </Typography>
          </InputLabel>
        )}
        <Paper
          variant="outlined"
          sx={{
            alignItems: 'center',
            backgroundColor: 'unset',
            border: `${borderWidth}px solid ${
              hasErrors ? theme.palette.error.main : theme.palette.grey[400]
            }`,
            boxSizing: 'border-box',
            display: 'flex',
            gap: `${paddingY}px`,
            justifyContent: 'space-between',
            px: `${paddingX}px`,
            py: `${paddingY}px`,
            width: '100%',
            '&:hover': {
              borderColor: (() => {
                if (hasErrors) {
                  return theme.palette.error.main;
                }
                if (isLoadingValue) {
                  return theme.palette[PantryColor.TransparentGrey400];
                }
                return theme.palette[PantryColor.TransparentGrey900];
              })(),
            },
            '&:focus-within': {
              border: `${borderWidth * 2}px solid ${
                hasErrors
                  ? theme.palette.error.main
                  : theme.palette.primary.main
              }`,
              py: `${paddingY - borderWidth}px`,
              px: `${paddingX - borderWidth}px`,
            },
          }}
        >
          <TextField
            id={fieldId}
            fullWidth
            variant="standard"
            placeholder={!isLoadingValue ? placeholder : undefined}
            type="number"
            disabled={isLoadingValue}
            inputProps={{
              'aria-label': 'Amount',
            }}
            InputProps={{
              startAdornment: <LoadingIcon loading={isLoadingValue} />,
            }}
            sx={{
              /*
               * Remove underline added by the standard TextField so Amount and Unit controls
               * look and feel as a unique control
               */
              [`& .${inputClasses.underline}:before`]: {
                content: 'none',
              },
              [`& .${inputClasses.underline}:after`]: {
                content: 'none',
              },
              [`& .${inputClasses.input}`]: {
                visibility: isLoadingValue ? 'hidden' : 'show',
              },
            }}
            onChange={(event) =>
              handleAmountChange(
                event.target.value !== '' ? +event.target.value : null
              )
            }
            value={amount || ''}
          />
          {uniqueUnits.length === 1 ? (
            <Typography aria-label="Unit" variant={PantryTypography.Body2}>
              {unit?.abbreviation}
            </Typography>
          ) : (
            <Select
              variant="standard"
              sx={{
                visibility: isLoadingValue ? 'hidden' : 'show',
                '&::before': {
                  content: 'none',
                },
                '&::after': {
                  content: 'none',
                },
                [`& .${selectClasses.standard}:focus`]: {
                  backgroundColor: 'unset',
                },
                // This way we make the custom ExpandIcon clickable
                [`& .${selectClasses.standard}`]: {
                  zIndex: 1,
                },
              }}
              IconComponent={ExpandIcon}
              value={unit?.id || ''}
              disabled={isLoadingValue}
              inputProps={{ 'aria-label': 'Unit' }}
              onChange={(event) => handleUnitChange(event.target.value)}
            >
              {uniqueUnits.map(({ id, abbreviation, name }) => (
                <MenuItem key={id} value={id}>
                  {abbreviation || name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Paper>
        {errorMessage && (
          <ErrorHelperText sx={{ mt: 2 }} message={errorMessage} />
        )}
      </Box>
    );
  }
);

const ExpandIcon: FC = memo(function ExpandIcon() {
  return (
    <ExpandMoreIcon
      color={PantryColor.IconDefault}
      size={20}
      sx={{ position: 'absolute', right: 0 }}
    />
  );
});

const LoadingIcon: FC<{ loading?: boolean }> = memo(function LoadingIcon({
  loading,
}) {
  if (!loading) {
    return null;
  }

  return (
    <InputAdornment position="start">
      <Spinner size={20} />
    </InputAdornment>
  );
});
