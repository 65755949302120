import {
  PantryColor,
  PantryTypography,
  sxCompose,
} from '@dropkitchen/pantry-react';
import { Grid, MenuItem, Typography } from '@mui/material';
import { memo } from 'react';
import type { FC, ReactNode } from 'react';

export interface AddRecipeMenuItemProps {
  icon: ReactNode;
  title: string;
  description: string;
  onClick: () => void;
  isFirst: boolean;
}

export const AddRecipeMenuItem: FC<AddRecipeMenuItemProps> = memo(
  function AddRecipeMenuItem({ icon, title, description, onClick, isFirst }) {
    return (
      <MenuItem
        sx={sxCompose(
          {
            backgroundColor: PantryColor.SurfaceDefault,
            p: 3,
            borderRadius: 1,
          },
          !isFirst && { mt: 2 }
        )}
        onClick={onClick}
      >
        <Grid container>
          <Grid
            item
            sx={{
              mr: 3,
              color: PantryColor.IconDefault,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {icon}
          </Grid>
          <Grid item>
            <Typography
              variant={PantryTypography.Subtitle2}
              color={PantryColor.TextDefault}
            >
              {title}
            </Typography>
            <Typography
              variant={PantryTypography.Body2}
              sx={{ mt: 1 }}
              color={PantryColor.TextSubtle}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>
    );
  }
);
