import { Grid } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export const RecipeTranslationColumnGrid: FC<{ children: ReactNode[] }> = memo(
  function RecipeTranslationColumnGrid({ children }) {
    return (
      <Grid container columnSpacing={6}>
        {children.map((child, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item sm={4} key={i}>
            {child}
          </Grid>
        ))}
      </Grid>
    );
  }
);
