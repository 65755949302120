import type { AppError } from 'types/appError';

export const errorSliceStrings = {
  errors: {
    generic: {
      title: 'Temporary Issue',
      message:
        'Sorry an unexpected error has occurred. To continue, please try refreshing the page.',
    },
  },
} as const;

export const errorSliceConstants = {
  genericError: {
    description: errorSliceStrings.errors.generic.title,
    message: errorSliceStrings.errors.generic.message,
  } satisfies AppError,
} as const;
