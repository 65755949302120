import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { NavigationItem } from 'features/layout/navigationMenu/NavigationItem';

export interface NavigationMenuItem {
  title: string;
  id: string;
  route: string;
}

export interface NavigationMenuProps {
  items: NavigationMenuItem[];
  isScrolled: boolean;
}

export const NavigationMenu: FC<NavigationMenuProps> = memo(
  function NavigationMenu({ isScrolled, items }) {
    return (
      <Box sx={{ ml: 10 }}>
        {items.map(({ id, route, title }) => (
          <NavigationItem key={id} to={route} isScrolled={isScrolled}>
            {title}
          </NavigationItem>
        ))}
      </Box>
    );
  }
);
