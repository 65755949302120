export const recipeBasicInformationStrings = {
  ariaLabel: {
    form: 'Basic Information',
    skeleton: 'Loading Basic Information',
  },
  labels: {
    recipeNameField: 'Recipe name',
    descriptionField: 'Description',
    authorField: 'Original recipe author',
    applianceTagsField: 'What appliance is this recipe suited for?',
    prepTimeField: 'Prep time',
    cookTimeField: 'Cooking time',
    totalTimeField: 'Total time',
    tagsField: 'Recipe Tags',
    servesField: 'Number of servings',
  },
  placeholders: {
    recipeNameField: 'Enter recipe name',
    descriptionField: 'Enter description here',
    authorField: 'Enter recipe author',
    applianceTagsField: 'Add appliances',
    prepTimeField: 'Enter prep time',
    cookTimeField: 'Enter cooking time',
    totalTimeField: 'Enter total time',
    tagsField:
      'Add tags to this recipe e.g. chinese, christmas, on-the-go, autumn, etc.',
    servesField: 'Enter no. of servings',
  },
  errorMessages: {
    recipeNameField: {
      required: 'Recipe name is required',
    },
    recipeAuthorField: {
      required: 'Original recipe author is required',
    },
    recipeServesField: {
      required: 'A whole number greater than zero is required',
    },
    recipeTotalTimeField: {
      required: 'Total time is required',
    },
    recipePrepTimeField: {
      required: 'Prep time is required',
    },
    recipeCookTimeField: {
      required: 'Cooking time is required',
    },
  },
  hintMessages: {
    authorField: 'This is the source originator of the recipe',
  },
} as const;

export const recipeBasicInformationConstants = {
  fieldIds: {
    recipeName: 'recipe-name',
    description: 'recipe-description',
    author: 'recipe-author',
    applianceTags: 'recipe-appliance-tags',
    totalTime: 'recipe-total-time',
    prepTime: 'recipe-prep-time',
    cookTime: 'recipe-cook-time',
    serves: 'recipe-serves',
  },
} as const;
