import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { RootState } from 'app/store/rootReducer';
import type { AppUser } from 'types/user/appUser';

interface AuthState {
  isInitialized: boolean;
  user?: AppUser;
  isSignInPending: boolean;
  signInError?: string;
}

export const initialState: AuthState = {
  isInitialized: false,
  isSignInPending: false,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    authInitialized(
      _state,
      { payload: user }: PayloadAction<AppUser | undefined>
    ) {
      return { ...initialState, isInitialized: true, user };
    },
    authSignInRequested(
      state,
      _: PayloadAction<{
        username: string;
        password: string;
      }>
    ) {
      state.isSignInPending = true;
      state.signInError = undefined;
    },
    authSignInFinished(state, { payload }: PayloadAction<AppUser>) {
      state.isSignInPending = false;
      state.signInError = undefined;
      state.user = payload;
    },
    authSignInFailed(state, { payload }: PayloadAction<string>) {
      state.isSignInPending = false;
      state.signInError = payload;
    },
    authSignOutFinished(state) {
      state.user = undefined;
    },
  },
});

export const authSignOutRequested = createAction(
  'authSlice/authSignOutRequested'
);

export const {
  reducer: authReducer,
  actions: {
    authInitialized,
    authSignInRequested,
    authSignInFinished,
    authSignInFailed,
    authSignOutFinished,
  },
} = authSlice;

const selectAuthState = (state: RootState): AuthState => state.auth;

export const selectAuthIsInitialized = (state: RootState): boolean =>
  selectAuthState(state).isInitialized;

export const selectAuthIsSignInPending = (state: RootState): boolean => {
  return selectAuthState(state).isSignInPending;
};

export const selectAuthSignInError = (state: RootState): string | undefined => {
  return selectAuthState(state).signInError;
};

export const selectAuthUser = (state: RootState): AppUser | undefined => {
  return selectAuthState(state).user;
};

export const selectAuthUserLocale = (
  state: RootState
): ApiLocale | undefined => {
  return selectAuthUser(state)?.locale;
};

export const selectAuthIsAuthenticated = (state: RootState): boolean => {
  return !!selectAuthUser(state);
};
