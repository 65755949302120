import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { Box, Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export interface RecipePageEmptyListProps {
  text: string;
  img: ReactNode;
}

export const RecipePageEmptyList: FC<RecipePageEmptyListProps> = memo(
  function RecipePageEmptyList({ text, img }) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant={PantryTypography.Body1}
          color={PantryColor.TextSubtle}
          sx={{ mb: 10 }}
        >
          {text}
        </Typography>
        {img}
      </Box>
    );
  }
);
