export const recipeIngredientIssueStrings = {
  warningMessages: {
    unusedIngredient: 'Unused',
    unusedIngredientWithAmount: (amount: string) => `${amount} unused`,
  },
  errorMessages: {
    invalidUsageAmounts:
      'Ingredient amount is not null but its usage is, or unit systems do not match',
  },
} as const;
