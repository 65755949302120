import type { TableColumn } from 'components/Table/Table';
import { appConfig } from 'config/config';
import { appCommonStrings } from 'types/appCommon.constants';

export const homeFeedStrings = {
  table: {
    ariaLabel: 'collections list',
    skeletonAriaLabel: 'collections list loading',
    headers: {
      order: 'No.',
      title: 'Collections',
      appliances: 'Appliance(s)',
      id: 'ID',
      recipeTotal: 'Recipe total',
      status: 'Status',
      modifiedAt: 'Modified',
    },
    actions: {
      delete: 'Delete collection',
    },
  },
  deleteDialog: {
    title: (collectionName: string) => `Delete ${collectionName}`,
    message: {
      draft:
        'Are you sure you want to delete this collection? This action cannot be undone',
      published:
        'This collection is published and may be seen by users currently. Do you want to unpublish and delete it?',
    },
    confirm: {
      draft: 'Yes, delete',
      published: 'Yes, unpublish and delete',
    },
    cancel: 'No, cancel',
  },
} as const;

const {
  table: { headers },
} = homeFeedStrings;

export enum HomeFeedCollectionsColumnField {
  Order = 'home-feed-collection-order',
  Title = 'home-feed-collection-title',
  Appliances = 'home-feed-collection-appliances',
  Id = 'home-feed-collection-id',
  RecipeTotal = 'home-feed-recipe-total',
  Status = 'home-feed-collection-status',
  ModifiedAt = 'home-feed-collection-modified-at',
}

export const homeFeedConstants = {
  table: {
    rowHeight: 52,
    emptyCell: appCommonStrings.placeholders.noValue,
    maxApplianceTagNameLength: 27,
    columns: {
      order: {
        field: HomeFeedCollectionsColumnField.Order,
        headerName: headers.order,
        sortable: false,
        width: 56,
        headerAlign: 'left',
        align: 'left',
      },
      title: {
        field: HomeFeedCollectionsColumnField.Title,
        headerName: headers.title,
        sortable: false,
        flex: 1,
      },
      appliances: {
        field: HomeFeedCollectionsColumnField.Appliances,
        headerName: headers.appliances,
        sortable: false,
        flex: 1,
      },
      id: {
        field: HomeFeedCollectionsColumnField.Id,
        headerName: headers.id,
        sortable: false,
        flex: 0.5,
      },
      recipeTotal: {
        field: HomeFeedCollectionsColumnField.RecipeTotal,
        headerName: headers.recipeTotal,
        sortable: false,
        width: 110,
        headerAlign: 'center',
        align: 'center',
      },
      status: {
        field: HomeFeedCollectionsColumnField.Status,
        headerName: headers.status,
        sortable: false,
        flex: 0.5,
      },
      modifiedAt: {
        field: HomeFeedCollectionsColumnField.ModifiedAt,
        headerName: headers.modifiedAt,
        sortable: false,
        flex: 0.5,
        headerAlign: 'right',
        align: 'right',
      },
    } satisfies Record<string, Partial<TableColumn>>,
  },
  fetchLimit: 20,
  recipeTotalLimit: appConfig.isUnitTestEnv() ? 2 : 30,
} as const;
