import { dragAndDropConstants } from 'components/DragAndDrop/DragAndDrop.constants';

export const cardConstants = {
  styles: {
    dragging: {
      boxShadow: '0px 16px 32px 0px rgba(25, 26, 34, 0.1)',
      ...dragAndDropConstants.styles.dragging,
    },
  },
} as const;
