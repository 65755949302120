import type { ApiAplCapabilityAllowedSettingDependency } from 'api/types/appliance/apiAplCapabilityAllowedSettingDependency';
import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRefCapabilityAllowedSettingDependency } from 'api/types/referenceData/apiRefCapabilityAllowedSettingDependency';
import type { AppCapabilityAllowedSettingValueNumeric } from 'types/appCapabilityAllowedSettingValueNumeric';
import {
  fromApiRefCapabilityAllowedSettingDependencyValueNumeric,
  fromApiAplCapabilityAllowedSettingValueNumeric,
} from 'types/appCapabilityAllowedSettingValueNumeric';

export interface AppCapabilityAllowedSettingDependencyAllowedValues {
  nominal?: ApiEntityId[];
  numeric?: AppCapabilityAllowedSettingValueNumeric[];
  boolean?: boolean;
}
export interface AppCapabilityAllowedSettingDependency {
  referenceSettingId: ApiEntityId;
  allowedValues: AppCapabilityAllowedSettingDependencyAllowedValues;
}

export const fromApiAplCapabilityAllowedSettingDependency = ({
  referenceSettingId,
  allowedValues: { nominal, numeric, boolean },
}: ApiAplCapabilityAllowedSettingDependency): AppCapabilityAllowedSettingDependency => {
  return {
    referenceSettingId,
    allowedValues: {
      nominal,
      numeric:
        numeric && numeric.map(fromApiAplCapabilityAllowedSettingValueNumeric),
      boolean,
    },
  };
};

export const fromApiRefCapabilityAllowedSettingDependency = ({
  referenceSettingId,
  allowedValues: { nominal, numeric, boolean },
}: ApiRefCapabilityAllowedSettingDependency): AppCapabilityAllowedSettingDependency => {
  return {
    referenceSettingId,
    allowedValues: {
      nominal,
      numeric: numeric?.map(
        fromApiRefCapabilityAllowedSettingDependencyValueNumeric
      ),
      boolean,
    },
  };
};
