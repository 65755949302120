import { TextField } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { useAutoFocus } from 'app/routes/hooks/useAutoFocus';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { recipeBasicInformationStrings } from 'features/recipe/recipeBasicInformation/RecipeBasicInformation.constants';
import type { RecipeFieldProps } from 'features/recipe/shared/types/appRecipeFieldProps';

const { labels, placeholders, hintMessages, errorMessages } =
  recipeBasicInformationStrings;

export interface RecipeAuthorFieldProps extends RecipeFieldProps {
  showErrors?: boolean;
}

export const RecipeAuthorField: FC<RecipeAuthorFieldProps> = memo(
  function RecipeAuthorField({ id, value, showErrors, onChange }) {
    const { isFocused } = useAutoFocus();

    return (
      <TextField
        id={id}
        label={labels.authorField}
        type="text"
        placeholder={placeholders.authorField}
        variant="outlined"
        fullWidth
        required
        autoFocus={isFocused(id)}
        error={showErrors}
        value={value}
        helperText={
          showErrors ? (
            <HelperText
              message={errorMessages.recipeAuthorField.required}
              severity={HelperTextSeverity.Critical}
            />
          ) : (
            <HelperText
              message={hintMessages.authorField}
              severity={HelperTextSeverity.Neutral}
            />
          )
        }
        onChange={(event) => onChange({ value: event.target.value })}
      />
    );
  }
);
