import { SurfaceDark, SurfaceLight } from '@dropkitchen/pantry-react';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export enum SurfaceType {
  Light = 'light',
  Dark = 'dark',
}

export interface WithSurfaceProps {
  surface: SurfaceType;
  children: ReactNode;
}

export const WithSurface: FC<WithSurfaceProps> = memo(function WithSurface({
  surface,
  children,
}) {
  const Surface = surface === SurfaceType.Dark ? SurfaceDark : SurfaceLight;
  return <Surface>{children}</Surface>;
});
