import type { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  apiGetUserIsAuthenticated,
  apiAuthSignIn,
  apiAuthSignOut,
} from 'api/auth';
import { authConstants } from 'api/auth.constants';
import type { ApiRequestFnResponseType, ApiResponse } from 'api/types';
import { ApiUsrRole } from 'api/types/user/apiUsrRole';
import { apiGetUser } from 'api/users';
import {
  authInitialized,
  authSignInRequested,
  authSignInFailed,
  authSignInFinished,
  authSignOutRequested,
  authSignOutFinished,
} from 'features/auth/authSlice';
import { errorOccurred } from 'features/error/errorSlice';
import { errorSliceConstants } from 'features/error/errorSlice.constants';
import { fromApiUsrUser } from 'types/user/appUser';

export function* authInit(): SagaIterator<void> {
  const userAuthentication = (yield call(
    apiGetUserIsAuthenticated
  )) as ApiResponse<undefined>;

  if (!userAuthentication.ok) {
    // There is no way to distinguish if user is not authenticated or if there is an error
    yield put(authInitialized(undefined));
    return;
  }

  const userResponse = (yield call(apiGetUser)) as ApiRequestFnResponseType<
    typeof apiGetUser
  >;

  if (!userResponse.ok) {
    yield put(errorOccurred(errorSliceConstants.genericError));
    yield put(authInitialized(undefined));
    yield put(authSignOutRequested());
    return;
  }

  yield put(authInitialized(fromApiUsrUser(userResponse.data)));
}

export function* requestSignIn({
  payload: { username, password },
}: ReturnType<typeof authSignInRequested>): SagaIterator<void> {
  const signInResponse = (yield call(apiAuthSignIn, {
    username,
    password,
  })) as ApiResponse<undefined>;

  if (!signInResponse.ok) {
    yield put(authSignInFailed(signInResponse.details.message));
    return;
  }

  const userResponse = (yield call(apiGetUser)) as ApiRequestFnResponseType<
    typeof apiGetUser
  >;

  if (!userResponse.ok) {
    yield put(authSignInFailed(userResponse.details.message));
    return;
  }

  if (!userResponse.data.roles.includes(ApiUsrRole.Admin)) {
    yield put(authSignInFailed(authConstants.errorMessages.permissions));
    yield put(authSignOutRequested());
    return;
  }

  yield put(authSignInFinished(fromApiUsrUser(userResponse.data)));
}

export function* requestSignOut(): SagaIterator<void> {
  const result = (yield call(apiAuthSignOut)) as ApiResponse<undefined>;
  if (!result.ok) {
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }
  yield put(authSignOutFinished());
}

export function* requestSignInWatcher(): SagaIterator<void> {
  yield takeLatest(authSignInRequested, requestSignIn);
}

export function* requestSignOutWatcher(): SagaIterator<void> {
  yield takeLatest(authSignOutRequested, requestSignOut);
}

export const authRunOnceSagas = [authInit];

export const authRestartableSagas = [
  requestSignInWatcher,
  requestSignOutWatcher,
];
