import type { PayloadAction } from '@reduxjs/toolkit';
import { select, put, takeEvery, call } from 'redux-saga/effects';

import { createApiRequestSaga } from 'api/createApiRequestSaga';
import { apiGetMeasurementSystems } from 'api/measurementSystems';
import type { ApiList, ApiResponse } from 'api/types';
import type { ApiMeasurementSystem } from 'api/types/common/apiMeasurementSystem';
import { errorOccurred } from 'features/error/errorSlice';
import {
  measurementSystemsFetching,
  measurementSystemsFetchFailed,
  measurementSystemsFetchSucceed,
  measurementSystemsFetchRequested,
  selectShouldFetchMeasurementSystems,
} from 'features/referenceData/measurementSystems/measurementSystemsSlice';
import type { AppLocalizedFetchActionPayload } from 'types/appLocalizedFetchActionPayload';

export const apiFetchMeasurementSystemsSaga = createApiRequestSaga(
  apiGetMeasurementSystems
);

export function* fetchMeasurementSystems({
  payload: { locale },
}: PayloadAction<AppLocalizedFetchActionPayload>) {
  const shouldFetch = (yield select(
    selectShouldFetchMeasurementSystems(locale)
  )) as boolean;
  if (!shouldFetch) {
    return;
  }

  yield put(measurementSystemsFetching({ locale }));

  const response = (yield call(apiFetchMeasurementSystemsSaga, {
    from: 0,
    locale,
  })) as ApiResponse<ApiList<ApiMeasurementSystem>>;
  if (!response.ok) {
    const { message } = response.details;
    yield put(errorOccurred({ message }));
    yield put(measurementSystemsFetchFailed({ locale, data: message }));
    return;
  }
  yield put(
    measurementSystemsFetchSucceed({ locale, data: response.data.items })
  );
}

function* measurementSystemsFetchWatcher() {
  yield takeEvery(measurementSystemsFetchRequested, fetchMeasurementSystems);
}

export const measurementSystemsSagas = [measurementSystemsFetchWatcher];
