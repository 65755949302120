import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { Tab as MuiTab, Box, Typography } from '@mui/material';
import type { FC, ReactElement } from 'react';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { useAppSelector } from 'app/store/hooks';
import type { TabBadgeProps } from 'components/Tab/TabBadge';
import { tabsListConstants } from 'components/Tab/TabsList.constants';
import { selectTabsListSticking } from 'components/Tab/tabsListSlice';

export interface TabProps {
  to: string;
  subLabel: string;
  label: string;
  value: string;
  position: number;
  components?: {
    badge?: ReactElement<TabBadgeProps>;
  };
}

const { tab, animationDuration } = tabsListConstants;

export const Tab: FC<TabProps> = memo(function Tab({
  label,
  subLabel,
  value,
  position,
  to,
  components = {},
  ...props
}: TabProps) {
  const isSticking = useAppSelector(selectTabsListSticking);

  return (
    <MuiTab
      sx={(theme) => ({
        backgroundColor: PantryColor.BackgroundDefault,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        py: isSticking ? 4 : 6,
        px: 6,
        height: isSticking ? `${tab.condensed.height}px` : `${tab.height}px`,

        transitionDuration: animationDuration,
        transitionTimingFunction: 'ease-in-out',
        transitionProperty: 'height, padding, border-radius',

        '&:first-of-type': {
          borderTopLeftRadius: isSticking ? 0 : theme.shape.borderRadius,
        },

        '&:last-of-type': {
          borderTopRightRadius: isSticking ? 0 : theme.shape.borderRadius,
        },

        '&:not(:first-of-type)': {
          borderLeft: '1px solid',
          borderColor: PantryColor.BorderMuted,
        },

        '&.Mui-selected, &.Mui-selected + &': {
          borderLeft: 'none',
        },

        '&.Mui-selected': {
          backgroundColor: PantryColor.SurfaceDefault,

          '& .fresco-tab': {
            '&__icon': {
              backgroundColor: PantryColor.FrescoPrimary,
            },
            '&__label': {
              color: PantryColor.FrescoPrimary,
            },
            '&__sub-label': {
              color: PantryColor.TextDefault,
            },
          },
        },
      })}
      component={Link}
      to={to}
      label={
        <Box
          className="fresco-tab"
          sx={{
            display: 'flex',
            gap: 6,
            alignItems: isSticking ? 'center' : 'flex-start',
            textAlign: 'left',
            textTransform: 'none',
          }}
        >
          <Box
            className="fresco-tab__icon"
            component="i"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: `0 0 ${tab.iconSize}`,
              width: `${tab.iconSize}`,
              height: `${tab.iconSize}`,
              backgroundColor: PantryColor.IconDefault,
              color: PantryColor.TextContrast,
              borderRadius: '50%',
              fontStyle: 'normal',
              fontSize: '16px',
              position: 'relative',
            }}
          >
            {position}
            {components.badge && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                {components.badge}
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant={PantryTypography.Subtitle2}
              className="fresco-tab__label"
              sx={{ color: PantryColor.TextSubtle }}
            >
              {label}
            </Typography>
            <Typography
              variant={PantryTypography.Body2}
              className="fresco-tab__sub-label"
              sx={{
                color: PantryColor.TextMuted,
                opacity: isSticking ? 0 : 1,
                height: isSticking ? 0 : 'auto',
                paddingTop: isSticking ? 0 : 2,

                transitionDuration: animationDuration,
                transitionTimingFunction: 'ease-in-out',
                transitionProperty: 'height, padding, opacity',
              }}
            >
              {subLabel}
            </Typography>
          </Box>
        </Box>
      }
      {...props}
      value={value}
    />
  );
});
