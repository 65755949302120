import { useCallback, useState } from 'react';
import { useBlocker } from 'react-router-dom';

export interface UseNavigationPromptParams {
  isEnabled: boolean;
  /**
   * Array of paths that should not block navigation, regardless the when parameter value
   */
  excludedPaths?: string[];
}

export function useNavigationPrompt({
  isEnabled,
  excludedPaths,
}: UseNavigationPromptParams) {
  const [isShowingPrompt, setIsShowingPrompt] = useState(false);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (!isEnabled || excludedPaths?.includes(nextLocation.pathname)) {
      return false;
    }
    if (currentLocation.pathname !== nextLocation.pathname) {
      setIsShowingPrompt(true);
      return true;
    }
    return false;
  });
  const cancelNavigation = useCallback(() => {
    setIsShowingPrompt(false);
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
  }, [blocker]);
  const confirmNavigation = useCallback(() => {
    setIsShowingPrompt(false);
    if (blocker.state === 'blocked') {
      blocker.proceed();
    }
  }, [blocker]);
  return { isShowingPrompt, confirmNavigation, cancelNavigation };
}
