import type { ApiRcpRecipeValueWithUnit } from 'api/types/recipe/apiRcpRecipeValueWithUnit';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { AppRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';

export interface ApiRcpRecipeValueWithUnitWithRefs
  extends Pick<ApiRcpRecipeValueWithUnit, 'amount'> {
  referenceUnitId: ApiRefId;
}

export function fromAppRecipeValueWithUnit({
  amount,
  unit,
}: AppRecipeValueWithUnit): ApiRcpRecipeValueWithUnitWithRefs {
  return {
    amount: amount ?? undefined,
    referenceUnitId: unit.id,
  };
}
