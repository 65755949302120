import { AppUnitSystem } from 'types/appUnitSystem';

export enum ApiUnitSystemId {
  Metric = 'cckg:MetricSystem',
  UsCustomary = 'cckg:USCustomarySystem',
}

export const getUnitSystemId = (system: AppUnitSystem): ApiUnitSystemId => {
  switch (system) {
    case AppUnitSystem.Metric:
      return ApiUnitSystemId.Metric;
    case AppUnitSystem.UsCustomary:
      return ApiUnitSystemId.UsCustomary;
  }
};
