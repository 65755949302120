import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from '@dropkitchen/pantry-react';
import { Grid } from '@mui/material';
import type { FC } from 'react';
import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiTag } from 'api/types/common/apiTag';
import { generateHomeFeedCollectionRoute } from 'app/routes/routesUtils';
import { useAppDispatch } from 'app/store/hooks';
import { ApplianceTagsField } from 'components/ApplianceTagsField/ApplianceTagsField';
import type { DialogProps } from 'components/Dialog/Dialog';
import { Dialog, DialogCloseReason } from 'components/Dialog/Dialog';
import { LocaleField } from 'components/LocaleField/LocaleField';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import {
  homeFeedAddCollectionDialogConstants,
  homeFeedAddCollectionDialogStrings,
} from 'features/homeFeed/HomeFeedAddCollectionDialog/HomeFeedAddCollectionDialog.constants';
import { homeFeedCollectionCreationRequested } from 'features/homeFeed/HomeFeedCollectionPage/homeFeedCollectionSlice';
import { tagsFetchRequested } from 'features/referenceData/tags/tagsSlice';
import { AppFeature } from 'types/appFeature';

const { title, labels, placeholders, hints } =
  homeFeedAddCollectionDialogStrings;
const { fieldIds } = homeFeedAddCollectionDialogConstants;

export interface HomeFeedAddCollectionDialogProps
  extends Pick<DialogProps, 'isOpen' | 'onClose' | 'onSubmit'> {
  defaultLocale: ApiLocale;
}

export const HomeFeedAddCollectionDialog: FC<HomeFeedAddCollectionDialogProps> =
  memo(function HomeFeedAddCollectionDialog({
    isOpen,
    onClose,
    onSubmit,
    defaultLocale,
  }) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [locale, setLocale] = useState<ApiLocale | null>(defaultLocale);

    const [applianceTags, setApplianceTags] = useState<ApiTag[]>([]);

    useEffect(() => {
      setLocale(defaultLocale);
    }, [defaultLocale]);

    useEffect(() => {
      if (locale) {
        dispatch(tagsFetchRequested({ locale }));
        return;
      }
      setApplianceTags([]);
    }, [dispatch, locale]);

    const resetFields = () => {
      setLocale(defaultLocale);
      setApplianceTags([]);
    };

    const closeDialog = (reason: DialogCloseReason) => {
      resetFields();
      onClose(reason);
    };

    return (
      <Dialog
        title={title}
        isOpen={isOpen}
        onClose={closeDialog}
        onSubmit={(event) => {
          if (!locale) {
            return;
          }
          dispatch(
            homeFeedCollectionCreationRequested({
              locale,
              applianceTags,
            })
          );
          navigate(generateHomeFeedCollectionRoute());
          resetFields();
          onSubmit(event);
        }}
        size="sm"
        components={{
          content: (
            <Grid container spacing={6}>
              <ToggleableFeature
                requires={AppFeature.TranslationManagement}
                components={{ whenDisabled: null }}
              >
                <Grid item xs={12}>
                  <LocaleField
                    id={fieldIds.locale}
                    label={labels.localeField}
                    placeholder={placeholders.localeField}
                    hint={hints.localeField}
                    onChange={(currentLocale) => {
                      setApplianceTags([]);
                      setLocale(currentLocale);
                    }}
                    value={locale}
                    autoFocus
                  />
                </Grid>
              </ToggleableFeature>
              <Grid item xs={12}>
                <ApplianceTagsField
                  id={fieldIds.applianceTags}
                  label={labels.applianceTagsField}
                  placeholder={placeholders.applianceTagsField}
                  hint={hints.applianceTagsField}
                  value={applianceTags}
                  locale={locale}
                  onChange={(tags) => setApplianceTags(tags)}
                  onRetry={() => {
                    if (locale) {
                      dispatch(tagsFetchRequested({ locale }));
                    }
                  }}
                  showErrors
                />
              </Grid>
            </Grid>
          ),
          buttons: (
            <>
              <Button
                label={labels.cancelButton}
                buttonStyle={ButtonStyle.Default}
                size={ButtonSize.Large}
                onClick={() => closeDialog(DialogCloseReason.CloseButtonClick)}
              />
              <Button
                label={labels.nextButton}
                type={ButtonType.Submit}
                buttonStyle={ButtonStyle.Emphasis}
                size={ButtonSize.Large}
                disabled={!locale}
              />
            </>
          ),
        }}
      />
    );
  });
