import uniqueId from 'lodash/uniqueId';

import type { ApiRcpRecipeIngredient } from 'api/types/recipe/apiRcpRecipeIngredient';
import type { ApiRcpRecipeStep } from 'api/types/recipe/apiRcpRecipeStep';
import type { AppRecipeStepCapability } from 'types/recipe/appRecipeStepCapability';
import { fromApiRcpRecipeStepCapability } from 'types/recipe/appRecipeStepCapability';
import type { AppRecipeStepIngredient } from 'types/recipe/appRecipeStepIngredient';
import { fromApiRcpRecipeStepIngredient } from 'types/recipe/appRecipeStepIngredient';

export interface AppRecipeStep {
  id: string;
  text: string;
  ingredients?: AppRecipeStepIngredient[];
  capability?: AppRecipeStepCapability;
  hasIncompatibilities?: boolean;
  sourceText?: string;
}

export function fromApiRcpRecipeStep({
  apiStep,
  recipeIngredients,
}: {
  apiStep: ApiRcpRecipeStep;
  recipeIngredients: ApiRcpRecipeIngredient[];
}): AppRecipeStep {
  return {
    id: uniqueId(),
    text: apiStep.text,
    ...(apiStep.ingredients && {
      ingredients: apiStep.ingredients?.map((ingredient) =>
        fromApiRcpRecipeStepIngredient({
          apiStepIngredient: ingredient,
          recipeIngredients,
        })
      ),
    }),
    ...(apiStep.capability && {
      capability: fromApiRcpRecipeStepCapability(apiStep.capability),
    }),
    sourceText: apiStep.sourceText,
  };
}
