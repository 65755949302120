import { datadogLogs } from '@datadog/browser-logs';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createAction, createSlice } from '@reduxjs/toolkit';
import produce from 'immer';

import type { ApiGetRecipeFromTextRequest } from 'api/recipes';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import type { RootState } from 'app/store/rootReducer';
import { authSignOutFinished } from 'features/auth/authSlice';
import { recipePageConstants } from 'features/recipe/RecipePage.constants';
import {
  updateStepIngredientsAfterMoving,
  updateStepIngredientsAfterDeleting,
  roundIngredientAmountLeft,
} from 'features/recipe/shared/utils/recipeIngredientsUtils';
import { recipeValidator } from 'features/recipe/shared/validators/recipeValidator';
import type { ApplianceIncompatibility } from 'features/recipe/steps/form/recipeStepsApplianceIncompatibilities.utils';
import { AppUnitSystem } from 'types/appUnitSystem';
import type { AppRecipe } from 'types/recipe/appRecipe';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import type { AppRecipeStep } from 'types/recipe/appRecipeStep';
import type { AppRecipeStepIngredient } from 'types/recipe/appRecipeStepIngredient';
import type { AppRecipeValueInMultipleSystems } from 'types/recipe/appRecipeValueInMultipleSystems';
import type { AppRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';
import { noTime } from 'utils/convertTimes';
import { getCurrentTime } from 'utils/getCurrentTime';
import {
  getSecondaryUnitSystem,
  getValueWithUnitAsString,
  isValueInMultipleSystems,
  isValueInSingleSystem,
  unitSystemByLocale,
} from 'utils/unitSystems';
import type { ValidatorErrors } from 'utils/validator';

export interface RecipeState {
  apiError?: string;
  recipeErrors: ValidatorErrors;
  fetching: boolean;
  recipe: AppRecipe;
  pristineRecipe?: AppRecipe;
  saving: boolean;
  recipeIngredientsUsage: AppRecipeIngredientsUsage[];
  publishing: boolean;
  unpublishing: boolean;
  submitted: boolean;
  hasUnsavedChanges: boolean;
  lastSavedAt?: number;
  publishConfirmationRequired?: boolean;
}

export interface AppRecipeIngredientsUsageStep
  extends Pick<AppRecipeStep, 'id'> {
  index: number;
}

export interface AppRecipeIngredientsUsage {
  used: {
    steps: AppRecipeIngredientsUsageStep[];
    quantity: AppRecipeValueWithUnit | AppRecipeValueInMultipleSystems;
  };
  left: AppRecipeValueWithUnit | AppRecipeValueInMultipleSystems;
}

const initialRecipe: AppRecipe = {
  locale: undefined,
  ingredients: [],
  name: '',
  steps: [],
  description: undefined,
  author: { name: '', image: '', url: '' },
  prepTime: noTime,
  cookTime: noTime,
  totalTime: noTime,
  state: ApiRcpRecipeState.Draft,
  applianceReferenceTags: undefined,
  serves: 0,
};

export const initialState: RecipeState = {
  fetching: false,
  saving: false,
  publishing: false,
  unpublishing: false,
  submitted: false,
  hasUnsavedChanges: false,
  recipe: initialRecipe,
  recipeIngredientsUsage: [],
  recipeErrors: recipeValidator.validate({ ...initialRecipe }) || {},
  lastSavedAt: undefined,
};

/**
 * Common recipe fields that can be updated by the recipeFieldUpdated action
 */
export type AppRecipeFieldUpdateKeys = Extract<
  keyof AppRecipe,
  | 'name'
  | 'description'
  | 'author'
  | 'prepTime'
  | 'cookTime'
  | 'totalTime'
  | 'applianceReferenceTags'
  | 'generalTags'
  | 'serves'
>;

export interface RecipeGetFromUrlPayload {
  url: string;
  locale: ApiLocale;
}

export interface RecipeStepAddedPayload {
  step: AppRecipeStep;
  incompatibilities: ApplianceIncompatibility;
}

export interface RecipeStepUpdatedPayload {
  index: number;
  step: AppRecipeStep;
  incompatibilities: ApplianceIncompatibility;
  areIncompatibilitiesFixed: boolean;
}

const recipeSlice = createSlice({
  name: 'recipeSlice',
  initialState,
  reducers: {
    recipeFieldUpdated<T extends AppRecipeFieldUpdateKeys>(
      state: RecipeState,
      {
        payload,
      }: PayloadAction<{
        key: T;
        value: AppRecipe[T];
      }>
    ) {
      const { key, value } = payload;
      state.recipe[key] = value;
      const errors = recipeValidator.validateField(key, value);
      if (errors) {
        state.recipeErrors[key] = errors;
      } else {
        delete state.recipeErrors[key];
      }
    },
    recipeIngredientAdded(
      state,
      { payload }: PayloadAction<AppRecipeIngredient>
    ) {
      const newIngredient = getIngredientWithText(payload);
      state.recipe.ingredients.push(newIngredient);
      startTrackingIngredientUsage(state, newIngredient);
    },
    recipeIngredientUpdated(
      state,
      {
        payload: { index, ingredient },
      }: PayloadAction<{ index: number; ingredient: AppRecipeIngredient }>
    ) {
      const ingredientWithText = getIngredientWithText(ingredient);
      const oldQuantity = state.recipe.ingredients[index].quantity;
      const newQuantity = ingredientWithText.quantity;

      state.recipe.ingredients[index] = ingredientWithText;

      // Update all the steps where the ingredient is used
      for (const step of state.recipe.steps) {
        if (!step.ingredients) {
          continue;
        }

        for (const stepIngredient of step.ingredients) {
          if (stepIngredient.ingredientIdx !== index) {
            continue;
          }

          stepIngredient.ingredient = ingredientWithText;
          updateIngredientQuantityInSteps({
            oldTotalQuantity: oldQuantity,
            newTotalQuantity: newQuantity,
            stepIngredient,
          });
        }
      }

      // Update ingredient usage
      if (isValueInSingleSystem(newQuantity)) {
        updateSingleUnitIngredientUsage({
          state,
          index,
          newQuantity,
        });
      }
      if (isValueInMultipleSystems(newQuantity)) {
        updateMultipleUnitsIngredientUsage({
          state,
          index,
          newQuantity,
        });
      }
    },
    recipeIngredientDeleted(
      state,
      { payload: ingredientIdxToRemove }: PayloadAction<number>
    ) {
      state.recipe.steps = state.recipe.steps.map(
        ({ ingredients, ...rest }) => ({
          ...rest,
          ingredients: updateStepIngredientsAfterDeleting(
            ingredients,
            ingredientIdxToRemove
          ),
        })
      );

      state.recipe.ingredients.splice(ingredientIdxToRemove, 1);
      state.recipeIngredientsUsage.splice(ingredientIdxToRemove, 1);
    },
    recipeIngredientMoved(
      state,
      { payload: { from, to } }: PayloadAction<{ from: number; to: number }>
    ) {
      const ingredientToMove = state.recipe.ingredients[from];
      state.recipe.ingredients.splice(from, 1);
      state.recipe.ingredients.splice(to, 0, ingredientToMove);

      const ingredientUsageToMove = state.recipeIngredientsUsage[from];
      state.recipeIngredientsUsage.splice(from, 1);
      state.recipeIngredientsUsage.splice(to, 0, ingredientUsageToMove);

      if (!state.recipe.steps.length) {
        return;
      }

      state.recipe.steps.forEach((step) => {
        step.ingredients = updateStepIngredientsAfterMoving(
          step.ingredients,
          from,
          to
        );
      });
    },
    recipeStepAdded(
      state,
      { payload: { step } }: PayloadAction<RecipeStepAddedPayload>
    ) {
      state.recipe.steps.push(step);
      if (!step.ingredients?.length) {
        return;
      }
      const stepIndex = state.recipe.steps.length - 1;
      step.ingredients.forEach((ingredient) =>
        useIngredient(state, step.id, stepIndex, ingredient)
      );
    },
    recipeStepUpdated(
      state,
      { payload: { index, step } }: PayloadAction<RecipeStepUpdatedPayload>
    ) {
      const isIngredientRemoved = (
        previousIngredient: AppRecipeStepIngredient
      ) =>
        !step.ingredients?.some(
          (ingredient) =>
            previousIngredient.ingredientIdx === ingredient.ingredientIdx
        );

      const removedIngredients =
        state.recipe.steps[index].ingredients?.filter(isIngredientRemoved);

      state.recipe.steps[index] = step;

      removedIngredients?.forEach((ingredient) =>
        stopUsingIngredient(state, ingredient, index)
      );

      step.ingredients?.forEach((ingredient) =>
        useIngredient(state, step.id, index, ingredient)
      );
    },
    recipeStepIncompatibilitiesUpdated(
      state,
      {
        payload: { index, hasIncompatibilities },
      }: PayloadAction<{ index: number; hasIncompatibilities: boolean }>
    ) {
      state.recipe.steps[index].hasIncompatibilities = hasIncompatibilities;
    },
    recipeStepDeleted(
      state,
      { payload: stepIdxToRemove }: PayloadAction<number>
    ) {
      const removedIngredients =
        state.recipe.steps[stepIdxToRemove].ingredients;

      state.recipe.steps.splice(stepIdxToRemove, 1);

      removedIngredients?.forEach((ingredient) =>
        stopUsingIngredient(state, ingredient, stepIdxToRemove)
      );
    },
    recipeStepMoved(
      state,
      { payload: { from, to } }: PayloadAction<{ from: number; to: number }>
    ) {
      const stepToMove = state.recipe.steps[from];
      state.recipe.steps.splice(from, 1);
      state.recipe.steps.splice(to, 0, stepToMove);
      state.recipeIngredientsUsage.forEach((usage) => {
        usage.used.steps = usage.used.steps
          .map((step) => reassignUsedStepsIndexes(step, state.recipe.steps))
          .sort(sortUsedSteps);
      });
    },
    recipeReset() {
      return initialState;
    },
    recipeFetchRequested(state, { payload }: PayloadAction<string>) {
      state.apiError = undefined;
      state.hasUnsavedChanges = false;
      state.submitted = false;
      if (state.recipe.id !== payload) {
        state.recipe = initialState.recipe;
        state.fetching = true;
      }
    },
    recipeFetchFailed(state, { payload }: PayloadAction<string>) {
      state.apiError = payload;
      state.fetching = false;
    },
    recipeFetchSucceed(state, { payload: recipe }: PayloadAction<AppRecipe>) {
      state.fetching = false;
      state.pristineRecipe = recipe;
      setRecipe(state, recipe);
    },
    recipeSaving(state) {
      state.saving = true;
      state.apiError = undefined;
    },
    recipeSaveFailed(state, { payload }: PayloadAction<string>) {
      state.saving = false;
      state.apiError = payload;
    },
    recipeSaveFinished(
      state,
      {
        payload: { etag, savedAt },
      }: PayloadAction<{ etag?: string; savedAt: number }>
    ) {
      datadogLogs.logger.info(
        `[SAVE] Update store for recipe ${state.recipe.id}`,
        {
          debugType: 'save',
          recipeId: state.recipe.id,
          savedAt,
          pristineRecipe: state.recipe,
          etag,
        }
      );
      state.saving = false;
      if (etag) {
        state.hasUnsavedChanges = false;
        state.lastSavedAt = savedAt;
        state.pristineRecipe = state.recipe;
        state.recipe.etag = etag;
      }
    },
    recipeSubmitted(state) {
      state.submitted = true;
    },
    recipePublishing(state) {
      state.publishing = true;
      state.apiError = undefined;
    },
    recipePublishFailed(state, { payload }: PayloadAction<string>) {
      state.publishing = false;
      state.apiError = payload;
    },
    recipePublishSucceed(state) {
      state.publishing = false;
      state.recipe.state = ApiRcpRecipeState.Published;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.pristineRecipe!.state = ApiRcpRecipeState.Published;
    },
    recipeUnpublishing(state) {
      state.unpublishing = true;
      state.apiError = undefined;
    },
    recipeUnpublishFailed(state, { payload }: PayloadAction<string>) {
      state.unpublishing = false;
      state.apiError = payload;
    },
    recipeUnpublishSucceed(state) {
      state.unpublishing = false;
      state.recipe.state = ApiRcpRecipeState.Draft;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.pristineRecipe!.state = ApiRcpRecipeState.Draft;
    },
    recipeGetFromUrlRequested(
      _,
      _action: PayloadAction<RecipeGetFromUrlPayload>
    ) {
      return {
        ...initialState,
        fetching: true,
      };
    },
    recipeGetFromTextRequested(
      _,
      _action: PayloadAction<ApiGetRecipeFromTextRequest>
    ) {
      return {
        ...initialState,
        fetching: true,
      };
    },
    recipeFromScratchRequested(_, { payload }: PayloadAction<ApiLocale>) {
      const recipeState: AppRecipe = {
        ...initialState.recipe,
        locale: payload,
      };
      return { ...initialState, recipe: recipeState };
    },
    recipeHasChanged(
      state,
      {
        payload: { hasChanges, changedAt },
      }: PayloadAction<{ hasChanges: boolean; changedAt: number }>
    ) {
      if (!state.hasUnsavedChanges && hasChanges) {
        state.lastSavedAt = changedAt;
      }
      state.hasUnsavedChanges = hasChanges;
    },
    recipeDiscardChanges(state) {
      state.hasUnsavedChanges = false;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setRecipe(state, state.pristineRecipe!);
    },
    recipePublishConfirmationRequired(state) {
      state.publishConfirmationRequired = true;
    },
    recipePublishConfirmed(state) {
      state.publishConfirmationRequired = false;
    },
    recipePublishCanceled(state) {
      state.publishConfirmationRequired = false;
    },
    recipeRecreateTagsSucceed(
      state,
      {
        payload,
      }: PayloadAction<
        Pick<AppRecipe, 'applianceReferenceTags' | 'generalTags'>
      >
    ) {
      state.recipe.applianceReferenceTags = payload.applianceReferenceTags;
      state.recipe.generalTags = payload.generalTags;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authSignOutFinished, () => initialState);
  },
});

export const getIngredientWithText = (
  ingredient: AppRecipeIngredient
): AppRecipeIngredient =>
  produce(ingredient, (draft) => {
    if (isValueInSingleSystem(draft.quantity)) {
      draft.quantity.text = getValueWithUnitAsString(draft.quantity);
      return;
    }
    draft.quantity.metric.text = getValueWithUnitAsString(
      draft.quantity.metric
    );
    draft.quantity.usCustomary.text = getValueWithUnitAsString(
      draft.quantity.usCustomary
    );
  });

export const {
  reducer: recipeReducer,
  actions: {
    recipeIngredientAdded,
    recipeIngredientUpdated,
    recipeIngredientDeleted,
    recipeIngredientMoved,
    recipeStepAdded,
    recipeStepUpdated,
    recipeStepDeleted,
    recipeStepMoved,
    recipeStepIncompatibilitiesUpdated,
    recipeFetchRequested,
    recipeFetchFailed,
    recipeFetchSucceed,
    recipeReset,
    recipeSaving,
    recipeSaveFailed,
    recipeSubmitted,
    recipeFieldUpdated,
    recipePublishing,
    recipePublishFailed,
    recipePublishSucceed,
    recipeUnpublishing,
    recipeUnpublishFailed,
    recipeUnpublishSucceed,
    recipeGetFromUrlRequested,
    recipeGetFromTextRequested,
    recipeFromScratchRequested,
    recipeDiscardChanges,
    recipePublishConfirmationRequired,
    recipePublishConfirmed,
    recipePublishCanceled,
    recipeRecreateTagsSucceed,
  },
} = recipeSlice;

/**
 * A recipe has manually requested a save.
 * Components that want to manually create / update a recipe should dispatch this action.
 */
export const recipeSaveRequested = createAction<string | undefined>(
  'recipeSlice/recipeSaveRequested'
);

/**
 * A recipe has requested its creation. This action is meant to be dispatched internally by the saveRecipe saga.
 * Components that want to create a recipe should dispatch recipeSaveRequested.
 */
export const recipeCreationRequested = createAction(
  'recipeSlice/recipeCreationRequested'
);

export const recipeRecreateTagsRequested = createAction(
  'recipeSlice/recipeRecreateTagsRequested'
);

/**
 * A recipe has requested an update. This action is meant to be dispatched internally by the saveRecipe saga.
 * Components that want to update a recipe should dispatch recipeSaveRequested.
 */
export interface RecipeUpdateRequestedPayload {
  originator: string;
}
export const recipeUpdateRequested = createAction<RecipeUpdateRequestedPayload>(
  'recipeSlice/recipeUpdateRequested'
);

/**
 * A recipe has requested an autosave. This action is meant to be dispatched internally by the autosaveRecipe saga.
 * Components that want to manually update a recipe should dispatch recipeSaveRequested.
 */
export const recipeAutoSaveRequested = createAction<string>(
  'recipeSlice/recipeAutoSaveRequested'
);

export const recipeSaveBeforeLeavingRequested = createAction<string>(
  'recipeSlice/recipeSaveBeforeLeavingRequested'
);

export const recipePublishRequested = createAction<string>(
  'recipeSlice/recipePublishRequested'
);

export const recipeUnpublishRequested = createAction<string>(
  'recipeSlice/recipeUnpublishRequested'
);

export const recipeSaveFinished = createAction(
  'recipeSlice/recipeSaveFinished',
  ({ etag }: { etag?: string }) => ({
    payload: { etag, savedAt: getCurrentTime() },
  })
);

export const recipeHasChanged = createAction(
  'recipeSlice/recipeHasChanged',
  (hasChanges: boolean) => ({
    payload: { hasChanges, changedAt: getCurrentTime() },
  })
);

const selectRecipeState = (state: RootState): RecipeState => state.recipe;

export const selectRecipe = (state: RootState): AppRecipe =>
  selectRecipeState(state).recipe;

export const selectRecipeLocale = (state: RootState): ApiLocale =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  selectRecipe(state).locale!;

export const selectRecipePrimaryUnitSystem = (
  state: RootState
): AppUnitSystem => unitSystemByLocale[selectRecipeLocale(state)];

export const selectRecipeIngredients = (
  state: RootState
): AppRecipeIngredient[] => selectRecipe(state).ingredients;

export const selectRecipeIngredient =
  (index: number | null) =>
  (state: RootState): AppRecipeIngredient | undefined =>
    typeof index === 'number'
      ? selectRecipe(state).ingredients[index]
      : undefined;

export const selectRecipeIngredientsUsage = (
  state: RootState
): AppRecipeIngredientsUsage[] =>
  selectRecipeState(state).recipeIngredientsUsage;

export const selectRecipeIngredientUsage =
  (index: number | null) =>
  (state: RootState): AppRecipeIngredientsUsage | undefined =>
    typeof index === 'number'
      ? selectRecipeState(state).recipeIngredientsUsage[index]
      : undefined;

export const selectRecipeSteps = (state: RootState): AppRecipeStep[] =>
  selectRecipe(state).steps;

export const selectRecipeStep =
  (index: number | null) =>
  (state: RootState): AppRecipeStep | undefined =>
    typeof index === 'number' ? selectRecipe(state).steps[index] : undefined;

export const selectRecipeApplianceTags = (state: RootState) =>
  selectRecipe(state).applianceReferenceTags;

export const selectRecipeSaving = (state: RootState): boolean =>
  selectRecipeState(state).saving;

export const selectRecipeHasUnsavedChanges = (state: RootState): boolean =>
  selectRecipeState(state).hasUnsavedChanges;

export const selectRecipePublishConfirmationRequired = (
  state: RootState
): boolean => !!selectRecipeState(state).publishConfirmationRequired;

export const selectRecipeFetching = (state: RootState): boolean =>
  selectRecipeState(state).fetching;

export const selectRecipePublishing = (state: RootState): boolean =>
  selectRecipeState(state).publishing;

export const selectRecipeUnpublishing = (state: RootState): boolean =>
  selectRecipeState(state).unpublishing;

export const selectRecipeErrors = (state: RootState): ValidatorErrors =>
  selectRecipeState(state).recipeErrors;

export const selectRecipeErrorsCount = createSelector(
  selectRecipeErrors,
  (errors): number => {
    return Object.values(errors).length;
  }
);

export const selectRecipeSubmitted = (state: RootState): boolean =>
  selectRecipeState(state).submitted;

export const selectRecipeApiError = (state: RootState): string | undefined =>
  selectRecipeState(state).apiError;

export const selectRecipeLastSavedAt = (state: RootState): number | undefined =>
  selectRecipeState(state).lastSavedAt;

const getIngredientUsageDetails = (
  { used, left }: AppRecipeIngredientsUsage,
  stepIndex: number | null,
  primaryUnitSystem?: AppUnitSystem
) => {
  const isNewStep = stepIndex === null;
  if (
    (isValueInMultipleSystems(left) ||
      isValueInMultipleSystems(used.quantity)) &&
    !primaryUnitSystem
  ) {
    throw new Error(
      'Primary unit system is required to calculate ingredient usage for values in multiple systems'
    );
  }
  const hasLeft = isValueInMultipleSystems(left)
    ? !!left.metric.amount && !!left.usCustomary.amount
    : !!left.amount;
  const hasLimit = isValueInMultipleSystems(used.quantity)
    ? used.quantity[primaryUnitSystem!].amount !== null
    : used.quantity.amount !== null;
  const isUsed = isValueInMultipleSystems(used.quantity)
    ? !!used.quantity.metric.amount || !!used.quantity.usCustomary.amount
    : !!used.quantity.amount;
  const isUsedByCurrentStep = used.steps.some(
    ({ index }) => index === stepIndex
  );
  const isUsedByManySteps = used.steps.length > 1;
  return {
    hasLeft,
    hasLimit,
    isNewStep,
    isUsed,
    isUsedByCurrentStep,
    isUsedByManySteps,
  };
};

export const selectAvailableIngredientsByStep = (stepIndex: number | null) =>
  createSelector(
    selectRecipeIngredients,
    selectRecipeIngredientsUsage,
    selectIngredientAmountUsedByOtherSteps(stepIndex),
    selectRecipePrimaryUnitSystem,
    (
      ingredients,
      ingredientsUsage,
      amountUsedByOtherSteps,
      primarySystem
    ): AppRecipeStepIngredient[] => {
      return ingredients
        .map((ingredient, ingredientIdx) => {
          const { amount, unit } = isValueInSingleSystem(ingredient.quantity)
            ? ingredient.quantity
            : ingredient.quantity[primarySystem];
          const amountUsed = amountUsedByOtherSteps[ingredientIdx] || 0;
          const amountLeft =
            amount === null
              ? null
              : roundIngredientAmountLeft(amount - amountUsed);

          let quantity:
            | AppRecipeValueWithUnit
            | AppRecipeValueInMultipleSystems;
          if (isValueInMultipleSystems(ingredient.quantity)) {
            const secondarySystem = getSecondaryUnitSystem(primarySystem);
            const secondaryAmountLeft = (() => {
              if (
                amountLeft === null ||
                ingredient.quantity[secondarySystem].amount === null ||
                ingredient.quantity[primarySystem].amount === null
              ) {
                return null;
              }
              const percentageLeft =
                amountLeft / ingredient.quantity[primarySystem].amount!;
              return roundIngredientAmountLeft(
                ingredient.quantity[secondarySystem].amount! * percentageLeft
              );
            })();
            quantity = createQuantityMultipleSystems({
              [primarySystem]: {
                amount: amountLeft,
                unit,
              },
              [secondarySystem]: {
                amount: secondaryAmountLeft,
                unit: ingredient.quantity[secondarySystem].unit,
              },
            } as AppRecipeValueInMultipleSystems);
          } else {
            quantity = createQuantity({
              amount: amountLeft,
              unit,
            });
          }

          return {
            ingredientIdx,
            ingredient,
            quantity,
          };
        })
        .filter(({ ingredientIdx }) => {
          const { isUsed, hasLeft, isUsedByCurrentStep } =
            getIngredientUsageDetails(
              ingredientsUsage[ingredientIdx],
              stepIndex,
              primarySystem
            );
          return !isUsed || hasLeft || isUsedByCurrentStep;
        });
    }
  );

export const selectUsedIngredientsByStep = (stepIndex: number | null) =>
  createSelector(
    selectRecipeIngredients,
    selectRecipeIngredientsUsage,
    selectRecipePrimaryUnitSystem,
    (
      ingredients,
      ingredientsUsage,
      primaryUnitSystem
    ): AppRecipeStepIngredient[] => {
      return ingredients
        .map((ingredient, ingredientIdx) => {
          const {
            isUsed,
            isNewStep,
            hasLimit,
            isUsedByCurrentStep,
            isUsedByManySteps,
          } = getIngredientUsageDetails(
            ingredientsUsage[ingredientIdx],
            stepIndex,
            primaryUnitSystem
          );
          return {
            ingredientIdx,
            ingredient,
            quantity: ingredientsUsage[ingredientIdx].used.quantity,
            isUsed:
              (isUsed || !hasLimit) &&
              (isNewStep || isUsedByManySteps || !isUsedByCurrentStep),
          };
        })
        .filter(({ isUsed }) => isUsed);
    }
  );

export const selectRecipeUnusedIngredientsCount = createSelector(
  selectRecipeIngredientsUsage,
  (ingredientsUsage): number => {
    const {
      publish: { unusedIngredientThreshold },
    } = recipePageConstants;
    return ingredientsUsage.filter(({ left, used }) => {
      if (isValueInSingleSystem(left)) {
        if (left.amount === null) {
          return !used.steps.length;
        }
        return left.amount > unusedIngredientThreshold;
      }

      if (left.metric.amount === null || left.usCustomary.amount === null) {
        return !used.steps.length;
      }
      return (
        left.metric.amount > unusedIngredientThreshold ||
        left.usCustomary.amount > unusedIngredientThreshold
      );
    }).length;
  }
);

export const selectIngredientsByStep = (stepIndex: number | null) =>
  createSelector(
    selectAvailableIngredientsByStep(stepIndex),
    selectUsedIngredientsByStep(stepIndex),
    (available, used) => [...available, ...used]
  );

export const selectIngredientAmountUsedByOtherSteps = (
  stepIndex: number | null
) =>
  createSelector(
    selectRecipeSteps,
    selectRecipePrimaryUnitSystem,
    (steps, primaryUnitSystem) => {
      return steps
        .filter((_, index) => index !== stepIndex)
        .flatMap(({ ingredients }) => ingredients || [])
        .reduce(
          (amountUsedByOtherSteps, { quantity, ingredientIdx }) => {
            const amount = isValueInSingleSystem(quantity)
              ? quantity.amount!
              : quantity[primaryUnitSystem].amount;
            if (amount === null) {
              return amountUsedByOtherSteps;
            }
            return {
              ...amountUsedByOtherSteps,
              [ingredientIdx]:
                amount + (amountUsedByOtherSteps[ingredientIdx] || 0),
            };
          },
          {} as { [ingredientIdx: number]: number }
        );
    }
  );

export const selectRecipeIncompatibilitiesCount = createSelector(
  selectRecipeSteps,
  (steps): number => steps.filter((step) => step.hasIncompatibilities).length
);

export const selectRecipeStepHasIncompatibilities = (index: number | null) =>
  createSelector(
    selectRecipeSteps,
    (steps) => (index !== null && steps[index].hasIncompatibilities) ?? false
  );

/**
 * We consider an "issue" as anything that must/could be fixed in a recipe.
 * It can be something that could be improved - such as amending an appliance incompatibility - or
 * something that prevents the recipe from being saved at all - such as a missing required field.
 */
export const selectRecipeIssuesCount = createSelector(
  selectRecipeErrorsCount,
  selectRecipeIncompatibilitiesCount,
  selectRecipeUnusedIngredientsCount,
  selectRecipeIngredients,
  selectRecipeSteps,
  (
    errorsCount,
    incompatibilitiesCount,
    unusedIngredientsCount,
    ingredients,
    steps
  ): number =>
    errorsCount +
    incompatibilitiesCount +
    unusedIngredientsCount +
    Number(ingredients.length === 0) +
    Number(steps.length === 0)
);

const startTrackingIngredientUsage = (
  state: RecipeState,
  ingredient: AppRecipeIngredient
) => {
  state.recipeIngredientsUsage.push({
    left: isValueInSingleSystem(ingredient.quantity)
      ? createQuantity(ingredient.quantity)
      : createQuantityMultipleSystems(ingredient.quantity),
    used: {
      steps: [],
      quantity: isValueInSingleSystem(ingredient.quantity)
        ? createQuantity({
            unit: ingredient.quantity.unit,
            amount: 0,
          })
        : createQuantityMultipleSystems({
            metric: {
              unit: ingredient.quantity.metric.unit,
              amount: 0,
            },
            usCustomary: {
              unit: ingredient.quantity.usCustomary.unit,
              amount: 0,
            },
          }),
    },
  });
};

export const calculateIngredientUsedAmount = (
  steps: AppRecipeStep[],
  ingredientIdx: number,
  system?: AppUnitSystem
): number => {
  return steps
    .flatMap(({ ingredients }) => ingredients)
    .filter((ingredient) => ingredient?.ingredientIdx === ingredientIdx)
    .reduce((totalQuantity, ingredient) => {
      if (!ingredient) {
        return totalQuantity;
      }

      if (!isValueInSingleSystem(ingredient.quantity)) {
        if (!system) {
          throw new Error(
            'System is required to calculate ingredient used amount for values in multiple systems'
          );
        }

        return totalQuantity + (ingredient.quantity[system].amount ?? 0);
      }

      return totalQuantity + (ingredient.quantity.amount ?? 0);
    }, 0);
};

const calculateIngredientLeftAmount = (
  availableAmount: number,
  usedAmount: number | null
): number | null => {
  return availableAmount - (usedAmount ?? 0);
};

const updateIngredientUsage = (
  state: RecipeState,
  steps: AppRecipeIngredientsUsageStep[],
  { ingredientIdx, ingredient, quantity }: AppRecipeStepIngredient
) => {
  const ingredientQuantity = ingredient.quantity;
  if (
    isValueInSingleSystem(ingredientQuantity) &&
    isValueInSingleSystem(quantity)
  ) {
    updateIngredientUsageSingleUnit(state, steps, {
      quantity,
      ingredientQuantity,
      ingredientIdx,
    });
    return;
  }
  if (
    !isValueInSingleSystem(ingredientQuantity) &&
    !isValueInSingleSystem(quantity)
  ) {
    updateIngredientUsageMultipleUnits(state, steps, {
      quantity,
      ingredientQuantity,
      ingredientIdx,
    });
  }
};

const updateIngredientUsageSingleUnit = (
  state: RecipeState,
  steps: AppRecipeIngredientsUsageStep[],
  {
    ingredientIdx,
    ingredientQuantity,
    quantity,
  }: {
    ingredientIdx: number;
    ingredientQuantity: AppRecipeValueWithUnit;
    quantity: AppRecipeValueWithUnit;
  }
) => {
  const usedQuantity = createQuantity({
    unit: quantity.unit,
    amount:
      ingredientQuantity.amount !== null
        ? calculateIngredientUsedAmount(state.recipe.steps, ingredientIdx)
        : null,
  });

  state.recipeIngredientsUsage[ingredientIdx] = {
    left: createQuantity({
      amount:
        ingredientQuantity.amount !== null
          ? calculateIngredientLeftAmount(
              ingredientQuantity.amount,
              usedQuantity.amount
            )
          : null,
      unit: ingredientQuantity.unit,
    }),
    used: {
      steps,
      quantity: usedQuantity,
    },
  };
};

const updateIngredientUsageMultipleUnits = (
  state: RecipeState,
  steps: AppRecipeIngredientsUsageStep[],
  {
    ingredientIdx,
    ingredientQuantity,
    quantity,
  }: {
    ingredientIdx: number;
    ingredientQuantity: AppRecipeValueInMultipleSystems;
    quantity: AppRecipeValueInMultipleSystems;
  }
) => {
  const usedQuantity = createQuantityMultipleSystems({
    metric: {
      unit: quantity.metric.unit,
      amount: calculateIngredientUsedAmount(
        state.recipe.steps,
        ingredientIdx,
        AppUnitSystem.Metric
      ),
    },
    usCustomary: {
      unit: quantity.usCustomary.unit,
      amount: calculateIngredientUsedAmount(
        state.recipe.steps,
        ingredientIdx,
        AppUnitSystem.UsCustomary
      ),
    },
  });

  state.recipeIngredientsUsage[ingredientIdx] = {
    left: createQuantityMultipleSystems({
      metric: {
        amount: calculateIngredientLeftAmount(
          ingredientQuantity.metric.amount ?? 0,
          usedQuantity.metric.amount
        ),
        unit: ingredientQuantity.metric.unit,
      },
      usCustomary: {
        amount: calculateIngredientLeftAmount(
          ingredientQuantity.usCustomary.amount ?? 0,
          usedQuantity.usCustomary.amount
        ),
        unit: ingredientQuantity.usCustomary.unit,
      },
    }),
    used: {
      steps,
      quantity: usedQuantity,
    },
  };
};

const useIngredient = (
  state: RecipeState,
  stepId: string,
  stepIndex: number,
  ingredient: AppRecipeStepIngredient
) => {
  const { ingredientIdx } = ingredient;
  const steps: AppRecipeIngredientsUsageStep[] = [
    ...state.recipeIngredientsUsage[ingredientIdx].used.steps.filter(
      ({ index }) => index !== stepIndex
    ),
    { id: stepId, index: stepIndex },
  ].sort(sortUsedSteps);

  updateIngredientUsage(state, steps, ingredient);
};

const stopUsingIngredient = (
  state: RecipeState,
  ingredient: AppRecipeStepIngredient,
  stepIndex: number
) => {
  const { ingredientIdx } = ingredient;
  if (state.recipeIngredientsUsage[ingredientIdx].used.steps.length === 1) {
    const usedQuantity = isValueInSingleSystem(ingredient.quantity)
      ? createQuantity({
          unit: ingredient.quantity.unit,
          amount: 0,
        })
      : createQuantityMultipleSystems({
          metric: {
            unit: ingredient.quantity.metric.unit,
            amount: 0,
          },
          usCustomary: {
            unit: ingredient.quantity.usCustomary.unit,
            amount: 0,
          },
        });
    state.recipeIngredientsUsage[ingredientIdx] = {
      left: ingredient.ingredient.quantity,
      used: {
        steps: [],
        quantity: usedQuantity,
      },
    };
  } else {
    const steps = state.recipeIngredientsUsage[ingredientIdx].used.steps.filter(
      ({ index }) => index !== stepIndex
    );
    updateIngredientUsage(state, steps, ingredient);
  }
};

const sortUsedSteps = (
  step: AppRecipeIngredientsUsageStep,
  anotherStep: AppRecipeIngredientsUsageStep
) => step.index - anotherStep.index;

const reassignUsedStepsIndexes = (
  { id }: AppRecipeIngredientsUsageStep,
  steps: AppRecipeStep[]
) => ({
  id,
  index: steps.findIndex((step) => step.id === id),
});

interface RecalculateStepIngredientAmountParams {
  oldTotalAmount: number | null;
  newTotalAmount: number | null;
  stepAmount: number | null;
}
export const recalculateStepIngredientAmount = ({
  oldTotalAmount,
  newTotalAmount,
  stepAmount,
}: RecalculateStepIngredientAmountParams): number | null => {
  if (oldTotalAmount === null && newTotalAmount !== null) {
    return 0;
  }

  if (oldTotalAmount === null || newTotalAmount === null) {
    return null;
  }

  return stepAmount;
};

interface CreateQuantityParams {
  unit: ApiQuantityUnit;
  amount: number | null;
}
export const createQuantity = ({
  unit,
  amount,
}: CreateQuantityParams): AppRecipeValueWithUnit => ({
  unit,
  amount,
  text: getValueWithUnitAsString({
    unit,
    amount,
  }),
});

interface CreateQuantityMultipleSystemsParams {
  metric: CreateQuantityParams;
  usCustomary: CreateQuantityParams;
}
export const createQuantityMultipleSystems = ({
  metric,
  usCustomary,
}: CreateQuantityMultipleSystemsParams): AppRecipeValueInMultipleSystems => {
  return {
    metric: createQuantity(metric),
    usCustomary: createQuantity(usCustomary),
  };
};

const setRecipe = (state: RecipeState, recipe: AppRecipe) => {
  state.recipe = recipe;
  state.recipeIngredientsUsage = [];
  recipe.ingredients.forEach((ingredient) => {
    startTrackingIngredientUsage(state, ingredient);
  });
  recipe.steps.forEach(({ id, ingredients }, index) => {
    if (!ingredients?.length) {
      return;
    }
    ingredients.forEach((ingredient) =>
      useIngredient(state, id, index, ingredient)
    );
  });
  state.recipeErrors = recipeValidator.validate({ ...recipe }) || {};
};

const updateSingleUnitIngredientUsage = ({
  state,
  index,
  newQuantity,
}: {
  state: RecipeState;
  index: number;
  newQuantity: AppRecipeValueWithUnit;
}) => {
  const amountUsed =
    newQuantity.amount === null
      ? null
      : calculateIngredientUsedAmount(state.recipe.steps, index);
  const amountLeft =
    newQuantity.amount === null
      ? null
      : calculateIngredientLeftAmount(newQuantity.amount, amountUsed);
  state.recipeIngredientsUsage[index].used.quantity = createQuantity({
    unit: newQuantity.unit,
    amount: amountUsed,
  });
  state.recipeIngredientsUsage[index].left = createQuantity({
    unit: newQuantity.unit,
    amount: amountLeft,
  });
};

const updateMultipleUnitsIngredientUsage = ({
  state,
  index,
  newQuantity,
}: {
  state: RecipeState;
  index: number;
  newQuantity: AppRecipeValueInMultipleSystems;
}) => {
  const metricAmountUsed = calculateIngredientUsedAmount(
    state.recipe.steps,
    index,
    AppUnitSystem.Metric
  );
  const usCustomaryAmountUsed = calculateIngredientUsedAmount(
    state.recipe.steps,
    index,
    AppUnitSystem.UsCustomary
  );
  if (
    newQuantity.metric.amount === null ||
    newQuantity.usCustomary.amount === null
  ) {
    throw new Error(
      `Amount is required to update ingredient usage for values in multiple systems: metric (${newQuantity.metric.amount}), usCustomary (${newQuantity.usCustomary.amount})`
    );
  }
  const metricAmountLeft = calculateIngredientLeftAmount(
    newQuantity.metric.amount,
    metricAmountUsed
  );
  const usCustomaryAmountLeft = calculateIngredientLeftAmount(
    newQuantity.usCustomary.amount,
    usCustomaryAmountUsed
  );
  state.recipeIngredientsUsage[index].used.quantity =
    createQuantityMultipleSystems({
      metric: {
        unit: newQuantity.metric.unit,
        amount: metricAmountUsed,
      },
      usCustomary: {
        unit: newQuantity.usCustomary.unit,
        amount: usCustomaryAmountUsed,
      },
    });
  state.recipeIngredientsUsage[index].left = createQuantityMultipleSystems({
    metric: {
      unit: newQuantity.metric.unit,
      amount: metricAmountLeft,
    },
    usCustomary: {
      unit: newQuantity.usCustomary.unit,
      amount: usCustomaryAmountLeft,
    },
  });
};

export const updateIngredientQuantityInSteps = ({
  oldTotalQuantity,
  newTotalQuantity,
  stepIngredient,
}: {
  oldTotalQuantity: AppRecipeValueWithUnit | AppRecipeValueInMultipleSystems;
  newTotalQuantity: AppRecipeValueWithUnit | AppRecipeValueInMultipleSystems;
  stepIngredient: AppRecipeStepIngredient;
}) => {
  let recalculatedAmount: number | null = null;
  /**
   * A quantity in multiple units doesn't support null values, therefore the new amount will be the same as the old amount.
   * */
  if (
    isValueInMultipleSystems(oldTotalQuantity) &&
    isValueInMultipleSystems(newTotalQuantity) &&
    isValueInMultipleSystems(stepIngredient.quantity)
  ) {
    stepIngredient.quantity = createQuantityMultipleSystems({
      metric: {
        unit: newTotalQuantity.metric.unit,
        amount: stepIngredient.quantity.metric.amount,
      },
      usCustomary: {
        unit: newTotalQuantity.usCustomary.unit,
        amount: stepIngredient.quantity.usCustomary.amount,
      },
    });
    return;
  }

  /**
   * For single system values, we only have to update the amount when it changes from null to number or vice versa.
   * */
  if (
    isValueInSingleSystem(oldTotalQuantity) &&
    isValueInSingleSystem(newTotalQuantity)
  ) {
    recalculatedAmount = recalculateStepIngredientAmount({
      oldTotalAmount: oldTotalQuantity.amount,
      newTotalAmount: newTotalQuantity.amount,
      stepAmount: isValueInSingleSystem(stepIngredient.quantity)
        ? stepIngredient.quantity.amount
        : 0,
    });
    stepIngredient.quantity = createQuantity({
      unit: newTotalQuantity.unit,
      amount: recalculatedAmount,
    });
    return;
  }

  /**
   * If the ingredient is used, we don't know which amount should be added to each step so we reset it to 0
   */
  if (
    isValueInSingleSystem(oldTotalQuantity) &&
    isValueInMultipleSystems(newTotalQuantity)
  ) {
    stepIngredient.quantity = createQuantityMultipleSystems({
      metric: {
        unit: newTotalQuantity.metric.unit,
        amount: 0,
      },
      usCustomary: {
        unit: newTotalQuantity.usCustomary.unit,
        amount: 0,
      },
    });
    return;
  }

  /**
   * If the ingredient is used, we don't know which amount should be added to each step so we reset it to 0,
   * unless the new amount is null, in which case we set the amount to null
   */
  if (
    isValueInMultipleSystems(oldTotalQuantity) &&
    isValueInSingleSystem(newTotalQuantity)
  ) {
    stepIngredient.quantity = createQuantity({
      unit: newTotalQuantity.unit,
      amount: newTotalQuantity.amount ? 0 : null,
    });
  }
};
