import upperFirst from 'lodash/upperFirst';

import type { ApiIngredientPreparation } from 'api/types/common/apiIngredientPreparation';
import type { ApiRcpRecipeIngredient } from 'api/types/recipe/apiRcpRecipeIngredient';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import {
  fromApiRcpRecipeValueInMultipleSystems,
  type AppRecipeValueInMultipleSystems,
} from 'types/recipe/appRecipeValueInMultipleSystems';
import type { AppRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';
import { fromApiRcpRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';
import { isValueInSingleSystem } from 'utils/unitSystems';

export interface AppRecipeIngredient {
  id: ApiRefId;
  name: string;
  preparations?: ApiIngredientPreparation[];
  quantity: AppRecipeValueWithUnit | AppRecipeValueInMultipleSystems;
  sourceText?: string;
}

export function fromApiRcpRecipeIngredient({
  referenceIngredient,
  referencePreparations,
  quantity,
  sourceText,
}: ApiRcpRecipeIngredient): AppRecipeIngredient {
  return {
    id: referenceIngredient.id,
    name: upperFirst(referenceIngredient.name),
    preparations: referencePreparations?.map((preparation) => ({
      id: preparation.id,
      name: upperFirst(preparation.name),
    })),
    quantity: isValueInSingleSystem(quantity)
      ? fromApiRcpRecipeValueWithUnit(quantity)
      : fromApiRcpRecipeValueInMultipleSystems(quantity),
    sourceText,
  };
}
