import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import type { TabProps } from '@mui/material';
import { Tab } from '@mui/material';

export interface ListTabProps<TValue>
  extends Omit<TabProps, 'value' | 'onClick'> {
  value: TValue;
  onClick: (value: TValue) => void;
}

export function ListTab<TValue>({
  label,
  value,
  onClick,
  ...props
}: ListTabProps<TValue>) {
  return (
    <Tab
      {...props}
      sx={{
        py: 3,
        px: 12,
        typography: PantryTypography.Subtitle2,
        color: PantryColor.TextSubtle,
        textTransform: 'capitalize',
        width: '250px',
        '&.Mui-selected': {
          color: PantryColor.TextDefault,
        },
      }}
      label={label}
      value={value}
      onClick={() => onClick(value)}
    />
  );
}
