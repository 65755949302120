import { Grid } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export const RecipeTranslationHeader: FC<{ children: ReactNode[] }> = memo(
  function RecipeTranslationHeader({ children }) {
    return (
      <Grid container alignItems="flex-end" justifyContent="space-between">
        <Grid item>{children[0]}</Grid>
        <Grid item>{children[1]}</Grid>
      </Grid>
    );
  }
);
