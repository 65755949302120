import { ApiCcCuratedCollectionState } from 'api/types/curatedCollection/apiCcCuratedCollectionState';

export const appCuratedCollectionStatesDictionary: Record<
  ApiCcCuratedCollectionState,
  string
> = {
  [ApiCcCuratedCollectionState.Draft]: 'Draft',
  [ApiCcCuratedCollectionState.Published]: 'Published',
};

export interface AppCcCuratedCollectionState {
  id: ApiCcCuratedCollectionState;
  name: string;
}

export const appCuratedCollectionStates: AppCcCuratedCollectionState[] = [
  {
    id: ApiCcCuratedCollectionState.Draft,
    name: appCuratedCollectionStatesDictionary[
      ApiCcCuratedCollectionState.Draft
    ],
  },
  {
    id: ApiCcCuratedCollectionState.Published,
    name: appCuratedCollectionStatesDictionary[
      ApiCcCuratedCollectionState.Published
    ],
  },
];
