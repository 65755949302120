import {
  DragDropContext as DndDragDropContext,
  Droppable as DndDroppable,
} from '@hello-pangea/dnd';
import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { DragPlaceholder } from 'components/DragAndDrop/DragPlaceholder';
import { useDragPlaceholder } from 'components/DragAndDrop/useDragPlaceholder';

export interface DropResult {
  from: number;
  to: number;
}

export interface DragZoneComponentProps {
  placeholder?: { sx?: SxProps };
}

export interface DragZoneProps {
  dragZoneId: string;
  children: ReactNode | ReactNode[];
  onDrop: (result: DropResult) => void;
  componentProps?: DragZoneComponentProps;
}

export const DragZone: FC<DragZoneProps> = memo(function DragZone({
  dragZoneId,
  children,
  onDrop,
  componentProps,
}) {
  const {
    handleDragStart,
    handleDragUpdate,
    dragPlaceholderProps,
    setDragPlaceholderProps,
  } = useDragPlaceholder();

  return (
    <DndDragDropContext
      onDragEnd={({ destination, source }) => {
        // Item dropped outside the drop zone
        if (!destination) {
          return;
        }
        onDrop({ from: source.index, to: destination.index });
        setDragPlaceholderProps({});
      }}
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
    >
      <DndDroppable droppableId={dragZoneId}>
        {(droppableProvided, droppableSnapshot) => (
          <Box
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            data-testid={generateDragZoneTestId(dragZoneId)}
            sx={{ position: 'relative' }}
          >
            {children}
            {droppableProvided.placeholder}
            <DragPlaceholder
              isDraggingOver={droppableSnapshot.isDraggingOver}
              placeholderProps={dragPlaceholderProps}
              sx={componentProps?.placeholder?.sx}
            />
          </Box>
        )}
      </DndDroppable>
    </DndDragDropContext>
  );
});

export const generateDragZoneTestId = (dragZoneId: string): string =>
  `drag-zone-${dragZoneId}`;

export const calculateIndexAfterReordering = (
  selectedIndex: number,
  sourceIndex: number,
  destinationIndex: number
): number => {
  // Moving the selected item. We need to update its index to the destination one
  if (sourceIndex === selectedIndex) {
    return destinationIndex;
  }

  // Adding an item before the selected one. Updating its index to +1
  if (sourceIndex > selectedIndex && destinationIndex <= selectedIndex) {
    return selectedIndex + 1;
  }

  // Removing an item before the selected one, and adding it after it. Updating its index to -1
  if (sourceIndex < selectedIndex && destinationIndex >= selectedIndex) {
    return selectedIndex - 1;
  }

  return selectedIndex;
};
