import { formatDistance } from 'date-fns';
import type { FC } from 'react';
import { useEffect, useState, memo } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import { recipePageStrings } from 'features/recipe/RecipePage.constants';
import {
  selectRecipeLastSavedAt,
  selectRecipeApiError,
  selectRecipeErrorsCount,
  selectRecipeHasUnsavedChanges,
  selectRecipeSaving,
  recipeSaveBeforeLeavingRequested,
} from 'features/recipe/recipeSlice';
import { getCurrentTime } from 'utils/getCurrentTime';

export interface LeaveWithUnsavedChangesDialogProps {
  onCancel: () => void;
  onConfirm: () => void;
}
export const LeaveWithUnsavedChangesDialog: FC<LeaveWithUnsavedChangesDialogProps> =
  memo(function LeaveWithUnsavedChangesDialog({ onCancel, onConfirm }) {
    const dispatch = useAppDispatch();
    const { recipeId } = useParams();

    const hasChanges = useAppSelector(selectRecipeHasUnsavedChanges);
    const isSaving = useAppSelector(selectRecipeSaving);
    const apiError = useAppSelector(selectRecipeApiError);
    const errorsCount = useAppSelector(selectRecipeErrorsCount);
    const lastSavedAt = useAppSelector(selectRecipeLastSavedAt);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { labels: modalLabels } = recipePageStrings;
    const isEdit = !!recipeId;

    useEffect(() => {
      // There are unsaved changes. Try to save them before displaying the modal.
      if (isEdit) {
        dispatch(recipeSaveBeforeLeavingRequested(recipeId));
        return;
      }
      // We're creating a recipe. We don't dispatch anything but just show the modal.
      setIsOpen(true);
    }, [dispatch, recipeId, isEdit]);

    useEffect(() => {
      // Recipe has been saved. Go to the new location
      if (!hasChanges) {
        onConfirm();
        return;
      }
      // Recipe has an error. Display modal.
      if (apiError || errorsCount) {
        setIsOpen(true);
      }
    }, [apiError, hasChanges, errorsCount, isSaving, onConfirm]);

    const handleClose = (hasConfirmed: boolean) => {
      if (hasConfirmed) {
        onConfirm();
      } else {
        onCancel();
      }
      setIsOpen(false);
    };

    return (
      <ConfirmationDialog
        isOpen={isOpen}
        text={{
          ...modalLabels.leaveModal,
          body: getDialogBodyText({
            apiError,
            errorsCount,
            isEdit,
            lastSavedAt,
          }),
        }}
        onClose={handleClose}
      />
    );
  });

export const getDialogBodyText = ({
  apiError,
  errorsCount,
  isEdit,
  lastSavedAt,
}: {
  apiError: string | undefined;
  errorsCount: number;
  isEdit: boolean;
  lastSavedAt: number | undefined;
}) => {
  const { labels: modalLabels } = recipePageStrings;
  const now = getCurrentTime();
  const timeAgo = formatDistance(lastSavedAt ?? now, now);
  let message = '';

  if (errorsCount || apiError) {
    message += `${modalLabels.leaveModal.bodyErrors}`;
  }

  if (errorsCount) {
    message += ` ${modalLabels.leaveModal.bodyValidationErrors} `;
  }

  if (apiError) {
    message += `${!errorsCount ? '. ' : ''}${apiError} `;
  }

  message += `${modalLabels.leaveModal.body} `;

  if (isEdit) {
    message += modalLabels.leaveModal.bodySavedAgo(timeAgo);
  }

  return message;
};
