import {
  Button,
  ButtonSize,
  ButtonStyle,
  PantryColor,
  SearchIcon,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps, Theme } from '@mui/material';
import { Box, TextField, inputBaseClasses } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useRef, useState, memo } from 'react';

import type { ApiLocale } from 'api/types/common/apiLocale';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { RecipesAdvancedSearch } from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearch';
import type { RecipesAdvancedSearchAnchoredFilter } from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.types';
import { recipesSearchStrings } from 'components/RecipesSearch/RecipesSearch.constants';
import {
  selectRecipesSearchTerm,
  selectIsAdvancedSearchPanelOpen,
  recipesSearchClear,
  recipesSearchTermUpdated,
  selectRecipesManuallyAppliedFilters,
  recipesSearchFiltersAnchored,
  recipesSearchFiltersLocaleUpdated,
} from 'components/RecipesSearch/recipesSearchSlice';

const { buttons, placeholders } = recipesSearchStrings;

export interface RecipesSearchProps {
  anchoredFilters?: RecipesAdvancedSearchAnchoredFilter[];
  /** The locale to use for the filter values. If not provided, user's locale will be used */
  filtersLocale?: ApiLocale;
  sx?: SxProps<Theme>;
}

export const RecipesSearch: FC<RecipesSearchProps> = memo(
  function RecipesSearch({ anchoredFilters, filtersLocale, sx }) {
    const dispatch = useAppDispatch();

    const searchTerm = useAppSelector(selectRecipesSearchTerm);
    const isAdvancedSearchPanelOpen = useAppSelector(
      selectIsAdvancedSearchPanelOpen
    );
    const manuallyAppliedFilters = useAppSelector(
      selectRecipesManuallyAppliedFilters
    );

    const ref = useRef<HTMLDivElement | null>(null);
    const [advancedSearchAnchorEl, setAdvancedSearchAnchorEl] =
      useState<Element | null>(null);

    const hasAppliedFilters = !!manuallyAppliedFilters.length;

    const isClearButtonShowing = !!searchTerm || hasAppliedFilters;

    useEffect(() => {
      dispatch(recipesSearchFiltersLocaleUpdated(filtersLocale));
    }, [filtersLocale, dispatch]);

    useEffect(() => {
      setAdvancedSearchAnchorEl(ref.current);
    }, []);

    useEffect(() => {
      if (anchoredFilters) {
        dispatch(recipesSearchFiltersAnchored(anchoredFilters));
      }
    }, [dispatch, anchoredFilters]);

    return (
      <TextField
        ref={ref}
        InputProps={{
          endAdornment: (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {isClearButtonShowing && (
                <>
                  <Button
                    label={buttons.clear}
                    buttonStyle={ButtonStyle.Subtle}
                    size={ButtonSize.Small}
                    onClick={() => dispatch(recipesSearchClear())}
                    sx={{ flexShrink: 0 }}
                  />
                  <Box component="span" sx={{ color: PantryColor.TextMuted }}>
                    |
                  </Box>
                </>
              )}
              {advancedSearchAnchorEl && (
                <RecipesAdvancedSearch
                  anchorEl={advancedSearchAnchorEl}
                  sx={sx}
                />
              )}
            </Box>
          ),
          startAdornment: (
            <SearchIcon
              size={24}
              color={PantryColor.IconDefault}
              sx={{ mr: 2 }}
            />
          ),
          style: { paddingLeft: '16px', paddingRight: '16px' },
        }}
        onChange={(e) => {
          dispatch(recipesSearchTermUpdated(e.target.value));
        }}
        fullWidth
        placeholder={placeholders.textField}
        sx={sxCompose(sx, {
          backgroundColor: PantryColor.SurfaceDefault,
          [`& .${inputBaseClasses.root}`]: {
            '& fieldset': {
              ...(isAdvancedSearchPanelOpen && {
                borderColor: PantryColor.FrescoPrimary,
              }),
            },
          },
          borderRadius: 1,
        })}
        value={searchTerm}
        variant="outlined"
      />
    );
  }
);
