import { combineReducers } from '@reduxjs/toolkit';

import { generalCapabilitiesReducer } from 'features/referenceData/generalCapabilities/generalCapabilitiesSlice';
import { ingredientsReducer } from 'features/referenceData/ingredients/ingredientsSlice';
import { measurementSystemsReducer } from 'features/referenceData/measurementSystems/measurementSystemsSlice';
import { preparationsReducer } from 'features/referenceData/preparations/preparationsSlice';
import { tagsReducer } from 'features/referenceData/tags/tagsSlice';

export const referenceDataReducer = combineReducers({
  ingredients: ingredientsReducer,
  generalCapabilities: generalCapabilitiesReducer,
  preparations: preparationsReducer,
  tags: tagsReducer,
  measurementSystems: measurementSystemsReducer,
});
