import {
  Spinner,
  AddCircleIcon,
  Button,
  ButtonSize,
  ButtonStyle,
  PreviewIcon,
} from '@dropkitchen/pantry-react';
import { TabContext } from '@mui/lab';
import { Box } from '@mui/material';
import type { GridRowParams } from '@mui/x-data-grid';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { ApiLocale } from 'api/types/common/apiLocale';
import {
  generateHomeFeedCollectionRoute,
  generateHomeFeedPreviewRoute,
  generateHomeFeedRoute,
} from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { EmptyContent } from 'components/EmptyContent/EmptyContent';
import { ListTab } from 'components/ListTabs/ListTab';
import { ListTabPanel } from 'components/ListTabs/ListTabPanel';
import { ListTabs } from 'components/ListTabs/ListTabs';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { selectConfigsIsFeatureEnabled } from 'features/configs/configsSlice';
import type { HomeFeedCollectionRow } from 'features/homeFeed/HomeFeed/HomeFeed';
import { HomeFeed } from 'features/homeFeed/HomeFeed/HomeFeed';
import { HomeFeedCollectionsColumnField } from 'features/homeFeed/HomeFeed/HomeFeed.constants';
import { HomeFeedAddCollectionDialog } from 'features/homeFeed/HomeFeedAddCollectionDialog/HomeFeedAddCollectionDialog';
import { HomeFeedPreviewDialog } from 'features/homeFeed/HomeFeedPreviewDialog/HomeFeedPreviewDialog';
import { homeFeedsPageStrings } from 'features/homeFeed/HomeFeedsPage.constants';
import {
  homeFeedsAddCollectionDialogClosed,
  homeFeedsAddCollectionDialogOpened,
  homeFeedsFetchRequested,
  homeFeedsPreviewDialogOpened,
  selectHomeFeedIsAddCollectionDialogOpen,
  selectHomeFeeds,
  selectHomeFeedsIsFetching,
} from 'features/homeFeed/homeFeedsSlice';
import { ReactComponent as NoHomeFeedsIcon } from 'features/homeFeed/no-home-feeds.svg';
import { ListLayout } from 'features/layout/ListLayout';
import { AppFeature } from 'types/appFeature';
import { appRegionByLocale } from 'types/appRegionByLocale';

const { title, emptyHomeFeeds, emptyHomeFeed, labels } = homeFeedsPageStrings;

export const HomeFeedsPage: FC = memo(function HomeFeedsPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { locale } = useParams<{ locale: ApiLocale }>();

  const isTranslationManagementEnabled = useAppSelector(
    selectConfigsIsFeatureEnabled(AppFeature.TranslationManagement)
  );
  const isLoading = useAppSelector(selectHomeFeedsIsFetching);
  const homeFeeds = useAppSelector(selectHomeFeeds);
  const isAddCollectionDialogOpen = useAppSelector(
    selectHomeFeedIsAddCollectionDialogOpen
  );

  const currentLocale = locale ?? homeFeeds[0]?.locale;

  useEffect(() => {
    dispatch(homeFeedsFetchRequested());
  }, [dispatch]);

  const handleTabClick = (feed: ApiLocale) =>
    navigate(generateHomeFeedRoute({ locale: feed }));

  const handleRowClick = (params: GridRowParams<HomeFeedCollectionRow>) => {
    navigate(generateHomeFeedCollectionRoute({ id: params.row.id }));
  };

  const handlePreviewButtonClick = () => {
    if (isTranslationManagementEnabled) {
      dispatch(homeFeedsPreviewDialogOpened());
    } else {
      navigate(generateHomeFeedPreviewRoute({ locale: currentLocale }));
    }
  };

  if (isLoading) {
    return (
      <ListLayout title={title}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '300px',
          }}
        >
          <Spinner size={64} ariaLabel={labels.loading} />
        </Box>
      </ListLayout>
    );
  }

  return (
    <>
      <ListLayout
        title={title}
        actionComponent={
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Button
              label={labels.preview}
              buttonStyle={ButtonStyle.Default}
              size={ButtonSize.Large}
              onClick={handlePreviewButtonClick}
              leadingIcon={PreviewIcon}
              loading={isLoading}
              disabled={isLoading}
            />
            <Button
              label={labels.addCollection}
              buttonStyle={ButtonStyle.Default}
              size={ButtonSize.Large}
              onClick={() => dispatch(homeFeedsAddCollectionDialogOpened())}
              leadingIcon={AddCircleIcon}
            />
          </Box>
        }
      >
        {!homeFeeds.length && <EmptyHomeFeeds />}
        {homeFeeds.length === 1 && (
          <HomeFeed
            hideColumns={[HomeFeedCollectionsColumnField.Order]}
            locale={currentLocale}
            onCollectionClick={handleRowClick}
            // eslint-disable-next-line @typescript-eslint/naming-convention
            components={{ EmptyHomeFeed }}
          />
        )}
        {homeFeeds.length > 1 && (
          <>
            <ListTabs<ApiLocale> variant="fullWidth" value={currentLocale}>
              {homeFeeds.map((feed) => (
                <ListTab<ApiLocale>
                  key={feed.locale}
                  label={appRegionByLocale[feed.locale]}
                  value={feed.locale}
                  onClick={handleTabClick}
                />
              ))}
            </ListTabs>
            <TabContext value={currentLocale}>
              {homeFeeds.map((feed) => (
                <ListTabPanel key={feed.locale} value={feed.locale}>
                  <HomeFeed
                    hideColumns={[HomeFeedCollectionsColumnField.Order]}
                    locale={feed.locale}
                    onCollectionClick={handleRowClick}
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    components={{ EmptyHomeFeed }}
                  />
                </ListTabPanel>
              ))}
            </TabContext>
          </>
        )}
      </ListLayout>
      <HomeFeedAddCollectionDialog
        isOpen={isAddCollectionDialogOpen}
        onClose={() => dispatch(homeFeedsAddCollectionDialogClosed())}
        onSubmit={() => dispatch(homeFeedsAddCollectionDialogClosed())}
        defaultLocale={currentLocale}
      />
      <ToggleableFeature
        requires={AppFeature.TranslationManagement}
        components={{ whenDisabled: null }}
      >
        <HomeFeedPreviewDialog defaultLocale={currentLocale} />
      </ToggleableFeature>
    </>
  );
});

const EmptyHomeFeeds: FC = memo(function EmptyHomeFeeds() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <EmptyContent
        components={{ image: <NoHomeFeedsIcon /> }}
        sx={{ width: '530px', minHeight: '354px' }}
        text={emptyHomeFeeds.text}
        title={emptyHomeFeeds.title}
      />
    </Box>
  );
});

const EmptyHomeFeed: FC = memo(function EmptyHomeFeed() {
  const dispatch = useAppDispatch();

  return (
    <EmptyContent
      components={{
        image: <NoHomeFeedsIcon />,
        actions: (
          <Button
            buttonStyle={ButtonStyle.Subtle}
            size={ButtonSize.Large}
            label={labels.addCollection}
            leadingIcon={AddCircleIcon}
            onClick={() => dispatch(homeFeedsAddCollectionDialogOpened())}
          />
        ),
      }}
      text={emptyHomeFeed.text}
      title={emptyHomeFeed.title}
      sx={{ my: 6 }}
    />
  );
});
