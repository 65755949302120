import { PantryColor } from '@dropkitchen/pantry-react';
import type { SxProps, Theme } from '@mui/material';

export const recipeTabContainerConstants = {
  ariaLabels: {
    titleSkeleton: 'Loading title',
  },
} as const;

export const recipeTabContainerStyle: SxProps<Theme> = {
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: PantryColor.BorderSubtle,
  px: 4,
  py: 6,
  borderRadius: 1,
} as const;
