import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { MediaResourceType } from 'api/media';
import type { RootState } from 'app/store/rootReducer';
import type { UploadImagePayload } from 'features/media/mediaSagas';

export interface MediaFetchSuccessPayload {
  resourceType: MediaResourceType;
  resourceId: string;
  success: boolean;
}

export interface MediaState {
  apiError?: string;
  uploading: boolean;
}

export const initialState: MediaState = {
  uploading: false,
};

const mediaSlice = createSlice({
  name: 'mediaSlice',
  initialState,
  reducers: {
    mediaUploadRequested: (state, _: PayloadAction<UploadImagePayload>) => {
      state.apiError = undefined;
      state.uploading = true;
    },
    mediaUploadFinished: (state) => {
      state.uploading = false;
    },
    mediaUploadFailed: (state, { payload }: PayloadAction<string>) => {
      state.apiError = payload;
      state.uploading = false;
    },
  },
});

export const {
  reducer: mediaReducer,
  actions: { mediaUploadRequested, mediaUploadFinished, mediaUploadFailed },
} = mediaSlice;

const selectMediaState = (state: RootState): MediaState => state.media;
export const selectMediaUploadError = (state: RootState): string | undefined =>
  selectMediaState(state).apiError;

export const selectMediaUploading = (state: RootState): boolean =>
  selectMediaState(state).uploading;
