import {
  ArrowUpIcon,
  Button,
  ButtonSize,
  ButtonStyle,
} from '@dropkitchen/pantry-react';
import { Slide } from '@mui/material';
import { forwardRef, memo, useEffect, useRef, useState } from 'react';

import { backToTopButtonStrings } from 'components/BackToTopButton/BackToTopButton.constants';

const { label } = backToTopButtonStrings;

// eslint-disable-next-line react/display-name
export const BackToTopButton = memo(
  forwardRef<HTMLButtonElement>(function BackToTopButton(_, ref) {
    const previousScroll = useRef<number>(window.scrollY);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const isScrollingUp = previousScroll.current > window.scrollY;
        const hasScrolledDownMoreThanTwoFullScreens =
          window.scrollY > window.innerHeight * 2;
        setIsVisible(isScrollingUp && hasScrolledDownMoreThanTwoFullScreens);
        previousScroll.current = window.scrollY;
      };
      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleClick = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
      <Slide
        direction="up"
        ref={ref}
        in={isVisible}
        easing={{ enter: 'cubic-bezier(0.405, -0.135, 0.145, 1.380)' }}
        timeout={300}
      >
        <Button
          label={label}
          leadingIcon={ArrowUpIcon}
          size={ButtonSize.Medium}
          buttonStyle={ButtonStyle.Default}
          sx={(theme) => ({
            position: 'fixed',
            bottom: theme.spacing(16),
            right: theme.spacing(16),
            zIndex: theme.zIndex.fab,
            // Overriding the box-shadow set by The Pantry for buttons of type default
            '&': {
              boxShadow: '0px 20px 56px rgba(0, 0, 0, 0.29)',
            },
          })}
          onClick={handleClick}
        />
      </Slide>
    );
  })
);
