export const signInPageStrings = {
  labels: {
    email: 'Email',
    password: 'Password',
    title: 'Sign in using your admin account',
    signIn: 'Sign In',
  },
  errors: {
    invalidEmail: 'This must be a valid email',
  },
} as const;
