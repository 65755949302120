import { Navigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import {
  appAppliancesRoutes,
  appHomeFeedRoutes,
  appRecipeRoutes,
  RecipeType,
} from 'app/routes/constants';
import { generateRecipeListRoute } from 'app/routes/routesUtils';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { AppliancePage } from 'features/appliances/AppliancePage';
import { AppliancesPage } from 'features/appliances/AppliancesPage';
import { SignInPage } from 'features/auth/SignInPage';
import { HomeFeedCollectionPage } from 'features/homeFeed/HomeFeedCollectionPage/HomeFeedCollectionPage';
import { HomeFeedPreview } from 'features/homeFeed/HomeFeedPreview/HomeFeedPreview';
import { HomeFeedsPage } from 'features/homeFeed/HomeFeedsPage';
import { PageLayout } from 'features/layout/PageLayout';
import { RecipePage } from 'features/recipe/RecipePage';
import { RecipesPage } from 'features/recipes/RecipesPage';
import { RecipeTranslation } from 'features/translation/RecipeTranslation';
import { AppFeature } from 'types/appFeature';

export const routes: RouteObject[] = [
  {
    path: appAppliancesRoutes.root,
    element: <PageLayout headerPosition="relative" />,
    children: [
      {
        path: '',
        element: <AppliancesPage />,
      },
      {
        path: appAppliancesRoutes.edit.root,
        element: <AppliancePage />,
      },
    ],
  },
  {
    path: appHomeFeedRoutes.root,
    element: <PageLayout />,
    children: [
      {
        path: '',
        element: <HomeFeedsPage />,
      },
      {
        path: appHomeFeedRoutes.tab,
        element: <HomeFeedsPage />,
      },
      {
        path: appHomeFeedRoutes.preview,
        element: <HomeFeedPreview />,
      },
      {
        path: appHomeFeedRoutes.create.root,
        element: <HomeFeedCollectionPage />,
      },
      {
        path: appHomeFeedRoutes.edit.root,
        element: <HomeFeedCollectionPage />,
      },
    ],
  },
  {
    path: '/',
    element: <PageLayout />,
    children: [
      {
        path: appRecipeRoutes.root,
        children: [
          {
            path: appRecipeRoutes.list[RecipeType.Core],
            element: <RecipesPage recipeType={RecipeType.Core} />,
          },
          {
            path: appRecipeRoutes.list[RecipeType.Translation],
            element: (
              <ToggleableFeature
                requires={AppFeature.TranslationManagement}
                components={{
                  whenDisabled: (
                    <Navigate
                      to={generateRecipeListRoute({ type: RecipeType.Core })}
                      replace
                    />
                  ),
                }}
              >
                <RecipesPage recipeType={RecipeType.Translation} />
              </ToggleableFeature>
            ),
          },
          {
            path: appRecipeRoutes.create.tab,
            element: <RecipePage />,
          },
          {
            path: appRecipeRoutes.create.default,
            element: <RecipePage />,
          },
          {
            path: appRecipeRoutes.edit.tab,
            element: <RecipePage />,
          },
          {
            path: appRecipeRoutes.edit.root,
            element: <RecipePage />,
          },
          {
            path: appRecipeRoutes.translate.root,
            element: (
              <ToggleableFeature
                requires={AppFeature.TranslationManagement}
                components={{
                  whenDisabled: (
                    <Navigate
                      to={generateRecipeListRoute({ type: RecipeType.Core })}
                      replace
                    />
                  ),
                }}
              >
                <RecipeTranslation />
              </ToggleableFeature>
            ),
          },
          {
            path: '',
            element: (
              <Navigate
                to={generateRecipeListRoute({ type: RecipeType.Core })}
                replace
              />
            ),
          },
        ],
      },
      { path: '', element: <Navigate to={appRecipeRoutes.root} replace /> },
      { path: '*', element: <p>There is nothing here!</p> },
    ],
  },
];

export const signInRoute: RouteObject = {
  path: '*',
  element: <SignInPage />,
};
export const unauthenticatedRoutes: RouteObject[] = [signInRoute];
