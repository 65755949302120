export const homeFeedAddCollectionDialogStrings = {
  title: 'New Collection',
  labels: {
    cancelButton: 'Cancel',
    nextButton: 'Next',
    applianceTagsField: 'Appliance(s)',
    localeField: 'Collection Locale',
  },
  placeholders: {
    applianceTagsField: 'Add appliances',
    localeField: 'Select collection locale',
  },
  hints: {
    localeField: "A collection's locale cannot be changed at a later stage",
    applianceTagsField:
      "A collection's appliances cannot be changed at a later stage",
  },
} as const;

export const homeFeedAddCollectionDialogConstants = {
  fieldIds: {
    locale: 'collection-locale',
    applianceTags: 'collection-appliance-tags',
  },
} as const;
