import snakecaseKeys from 'snakecase-keys';

import { createApiFn, fetchJson } from 'api/fetchJson';
import { generateApiPath } from 'api/generateApiPath';
import { apiHomeFeedUrl, apiHomeFeedsUrl, apiPathParams } from 'api/paths';
import type { ApiList, ApiRequestFn } from 'api/types';
import { CustomHeader, HttpMethod } from 'api/types';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiCcCuratedCollection } from 'api/types/curatedCollection/apiCcCuratedCollection';
import type { ApiHfHomeFeed } from 'api/types/homeFeed/apiHfHomeFeed';
import type { ApiHfHomeFeedListItem } from 'api/types/homeFeed/apiHfHomeFeedListItem';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import { homeFeedConstants } from 'features/homeFeed/HomeFeed/HomeFeed.constants';

export const apiGetHomeFeeds: ApiRequestFn<
  ApiList<ApiHfHomeFeedListItem>
> = async (signal: AbortSignal) =>
  fetchJson({
    httpMethod: HttpMethod.Get,
    url: apiHomeFeedsUrl,
    signal,
  });

export interface ApiGetHomeFeedRequest {
  locale: ApiLocale;
  cursor?: string;
  limit?: number;
  applianceTagIds?: ApiRefId[];
  preview?: boolean;
}

export const apiGetHomeFeed = async (
  signal: AbortSignal,
  {
    locale,
    cursor,
    limit = homeFeedConstants.fetchLimit,
    applianceTagIds,
    preview,
  }: ApiGetHomeFeedRequest
) => {
  const queryParams = new URLSearchParams(
    snakecaseKeys({
      limit: limit.toString(),
      ...(!!cursor && { cursor }),
      ...(!!preview && { preview: preview.toString() }),
      ...(applianceTagIds?.length && {
        applianceTagIds: applianceTagIds.toString(),
      }),
    })
  );
  return fetchJson<ApiHfHomeFeed>({
    httpMethod: HttpMethod.Get,
    url: `${generateApiPath(apiHomeFeedUrl, { [apiPathParams.locale]: locale })}?${queryParams.toString()}`,
    signal,
  });
};

export interface ApiPutHomeFeedRequest {
  locale: ApiLocale;
  etag: string;
  homeFeed: {
    items: Pick<ApiCcCuratedCollection, 'id'>[];
  };
}
export const putHomeFeed = async ({
  locale,
  etag,
  homeFeed,
}: ApiPutHomeFeedRequest) =>
  fetchJson<Pick<ApiHfHomeFeed, 'etag'>>({
    httpMethod: HttpMethod.Put,
    url: generateApiPath(apiHomeFeedUrl, {
      [apiPathParams.locale]: locale,
    }),
    body: snakecaseKeys(homeFeed),
    headers: { [CustomHeader.IfMatch]: etag },
  });
export const apiPutHomeFeed: ApiRequestFn<
  Pick<ApiHfHomeFeed, 'etag'>,
  ApiPutHomeFeedRequest
> = createApiFn(putHomeFeed);
