import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { appSearchParams } from 'app/routes/constants';

export const useAutoFocus = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const focusedFieldId =
    searchParams.get(appSearchParams.autofocusedFieldId) ?? undefined;

  const isFocused = useCallback(
    (fieldId: string) => focusedFieldId === fieldId,
    [focusedFieldId]
  );

  const setFocused = useCallback(
    (fieldId: string) => {
      // Remove search param if it's already set so sections and fields could rerender
      // and perform autofocus to same field
      if (focusedFieldId === fieldId) {
        searchParams.delete(appSearchParams.autofocusedFieldId);
        setSearchParams(searchParams, { replace: true });
      }
      // Wait for next tick so components could rerender
      setTimeout(() => {
        searchParams.set(appSearchParams.autofocusedFieldId, fieldId);
        setSearchParams(searchParams, { replace: true });
      });
    },
    [focusedFieldId, searchParams, setSearchParams]
  );

  return {
    isFocused,
    setFocused,
    focusedFieldId,
  };
};
