import {
  Banner,
  BannerStatus,
  PantryColor,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import capitalize from 'lodash/capitalize';
import type { FC } from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import {
  ApiUnitSystemId,
  getUnitSystemId,
} from 'api/types/common/apiUnitSystemId';
import { apiPostConvertQuantity } from 'api/unitConversion';
import type {
  ApiRefConvertQuantityRequestContext,
  ApiRefConvertQuantityRequest,
} from 'api/unitConversion';
import { ContentByLocale } from 'components/ContentByLocale/ContentByLocale';
import { QuantityField } from 'components/QuantityField/QuantityField';
import {
  valueInMultipleSystemsFieldConstants,
  valueInMultipleSystemsFieldStrings,
} from 'components/ValueInMultipleSystemsField/ValueInMultipleSystemsField.constants';
import { ValueInMultipleSystemsFieldLinkButton } from 'components/ValueInMultipleSystemsField/ValueInMultipleSystemsFieldLinkButton';
import { ValueWithUnitField } from 'components/ValueWithUnitField/ValueWithUnitField';
import {
  getIngredientAmountError,
  toNumber,
} from 'features/recipe/ingredients/form/recipeIngredientForm.utils';
import { isFormQuantityComplete } from 'features/recipe/ingredients/form/recipeIngredientFormSlice';
import type {
  AppFormValueWithUnitComplete,
  AppFormValueWithUnit,
} from 'types/appFormValueWithUnit';
import type { AppQuantityChangeTriggeredBy } from 'types/appQuantityChangeTriggeredBy';
import { AppQuantityChangeTriggeredByField } from 'types/appQuantityChangeTriggeredBy';
import { AppUnitSystem } from 'types/appUnitSystem';
import type { AppRecipeValueWithUnitNullable } from 'types/recipe/appRecipeValueWithUnit';
import { getSecondaryUnitSystem, unitSystemByLocale } from 'utils/unitSystems';
import type { ValidatorError } from 'utils/validator';

export enum ValueInMultipleSystemsFieldVariant {
  Default = 'default',
  Condensed = 'condensed',
}

export interface ValueInMultipleSystemsValue {
  [AppUnitSystem.Metric]: AppFormValueWithUnit;
  [AppUnitSystem.UsCustomary]: AppFormValueWithUnit;
}

export type ValueInMultipleSystemsFieldError =
  | { value?: string; unit?: string }
  | ValidatorError;

export interface ValueInMultipleSystemsFieldProps {
  id: string;
  label: string;
  locale: ApiLocale;
  metricField: {
    quantity: AppFormValueWithUnit;
    isLoadingUnits?: boolean;
    allowedUnits: ApiQuantityUnit[];
    errors?: ValueInMultipleSystemsFieldError;
  };
  usCustomaryField: {
    quantity: AppFormValueWithUnit;
    isLoadingUnits?: boolean;
    allowedUnits: ApiQuantityUnit[];
    errors?: ValueInMultipleSystemsFieldError;
  };
  onChange: (event: ValueInMultipleSystemsValue) => void;
  variant?: ValueInMultipleSystemsFieldVariant;
  isAutoConversionEnabled: boolean;
  /** Used to trigger an autoconversion from the parent component */
  shouldTriggerAutoConversion?: boolean;
  onAutoConversionLinkClick: (currentState: boolean) => void;
  context?: ApiRefConvertQuantityRequestContext;
  sx?: SxProps<Theme>;
}

const { generateFieldId } = valueInMultipleSystemsFieldConstants;
const { valueField, unitField, generateAriaLabel, buttons } =
  valueInMultipleSystemsFieldStrings;

export const ValueInMultipleSystemsField: FC<ValueInMultipleSystemsFieldProps> =
  memo(function ValueInMultipleSystemsField({
    id,
    label,
    locale,
    metricField,
    usCustomaryField,
    onChange,
    variant = ValueInMultipleSystemsFieldVariant.Default,
    isAutoConversionEnabled,
    shouldTriggerAutoConversion = false,
    onAutoConversionLinkClick,
    context,
    sx,
  }) {
    const [isConverting, setIsConverting] = useState<{
      metric?: {
        amount: boolean;
        unit: boolean;
      };
      usCustomary?: {
        amount: boolean;
        unit: boolean;
      };
    }>({
      metric: { amount: false, unit: false },
      usCustomary: { amount: false, unit: false },
    });
    const abortControllerRef = useRef<AbortController>();
    const [autoConversionError, setAutoConversionError] = useState<
      { triggeredBy: AppQuantityChangeTriggeredBy; message: string } | undefined
    >(undefined);

    const primaryUnitSystem = unitSystemByLocale[locale];

    const autoConvertQuantity = useCallback(
      async (
        quantity: ValueInMultipleSystemsValue,
        triggeredBy: AppQuantityChangeTriggeredBy
      ) => {
        const { metric, usCustomary } = quantity;
        const secondaryUnitSystem = getSecondaryUnitSystem(primaryUnitSystem);

        if (
          triggeredBy.field === AppQuantityChangeTriggeredByField.Amount &&
          (!toNumber(quantity[triggeredBy.system].amount) ||
            getIngredientAmountError({
              amount: quantity[triggeredBy.system].amount,
            }))
        ) {
          onChange({
            metric: {
              amount: quantity[triggeredBy.system].amount,
              unit: metric.unit,
            },
            usCustomary: {
              amount: quantity[triggeredBy.system].amount,
              unit: usCustomary.unit,
            },
          });
          return;
        }

        const canNotConvertFromPrimaryToSecondary =
          triggeredBy.system === primaryUnitSystem &&
          !isFormQuantityComplete(quantity[primaryUnitSystem]);
        const canNotConvertFromSecondaryToPrimary =
          triggeredBy.system === secondaryUnitSystem &&
          !quantity[secondaryUnitSystem].unit;
        if (
          canNotConvertFromPrimaryToSecondary ||
          canNotConvertFromSecondaryToPrimary
        ) {
          return;
        }

        setIsConverting(
          getConvertingFields({
            isPrimaryQuantityComplete: isFormQuantityComplete(
              quantity[primaryUnitSystem]
            ),
            primaryUnitSystem,
            triggeredBy,
            hasSecondaryUnit: !!quantity[secondaryUnitSystem].unit,
          })
        );

        abortControllerRef.current = new AbortController();
        const response = await apiPostConvertQuantity(
          generateQuantityConversionRequest({
            quantity: { metric, usCustomary },
            triggeredBy,
            context,
            primaryUnitSystem,
          }),
          abortControllerRef.current.signal
        );

        if (!response.ok) {
          if (!response.isAborted) {
            setAutoConversionError({
              triggeredBy,
              message: response.details.message,
            });
          }
          setIsConverting({});
          return;
        }

        /** Only one conversion is performed at a time. Take the first item from the list. */
        const {
          convertedValue,
          convertedReferenceUnit,
          convertedReferenceUnitSystem,
        } = response.data.entities[0];

        const convertedQuantity: AppFormValueWithUnit = {
          amount: `${convertedValue}`,
          unit: {
            ...convertedReferenceUnit,
            name: capitalize(convertedReferenceUnit.name),
          },
        };
        onChange(
          convertedReferenceUnitSystem.id === ApiUnitSystemId.UsCustomary
            ? { metric, usCustomary: convertedQuantity }
            : { metric: convertedQuantity, usCustomary }
        );
        setIsConverting({});
      },
      [context, onChange, primaryUnitSystem]
    );

    const handleFieldChange = useCallback(
      (
        { metric, usCustomary }: ValueInMultipleSystemsValue,
        triggeredBy: AppQuantityChangeTriggeredBy
      ) => {
        abortControllerRef.current?.abort();
        // Notify the change as soon as possible to give the user perception of smoothness and allow fast typing
        onChange({ metric, usCustomary });
        setAutoConversionError(undefined);

        const canConvert =
          isFormQuantityComplete(metric) || isFormQuantityComplete(usCustomary);
        if (!isAutoConversionEnabled || !canConvert) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        autoConvertQuantity({ metric, usCustomary }, triggeredBy);
      },
      [onChange, isAutoConversionEnabled, autoConvertQuantity]
    );

    const handleAutoConversionRetry = () => {
      if (!autoConversionError?.triggeredBy) {
        return;
      }
      handleFieldChange(
        {
          metric: metricField.quantity,
          usCustomary: usCustomaryField.quantity,
        },
        autoConversionError.triggeredBy
      );
    };

    useEffect(() => {
      setAutoConversionError(undefined);
      if (shouldTriggerAutoConversion) {
        const primaryQuantity =
          primaryUnitSystem === AppUnitSystem.Metric
            ? metricField.quantity
            : usCustomaryField.quantity;
        handleFieldChange(
          {
            metric: metricField.quantity,
            usCustomary: usCustomaryField.quantity,
          },
          {
            system: isFormQuantityComplete(primaryQuantity)
              ? primaryUnitSystem
              : getSecondaryUnitSystem(primaryUnitSystem),
            field: AppQuantityChangeTriggeredByField.Amount,
          }
        );
      }
    }, [
      shouldTriggerAutoConversion,
      handleFieldChange,
      metricField,
      usCustomaryField,
      primaryUnitSystem,
    ]);

    const linkButton = (
      <ValueInMultipleSystemsFieldLinkButton
        isLinked={isAutoConversionEnabled}
        onClick={() => {
          setAutoConversionError(undefined);
          onAutoConversionLinkClick(isAutoConversionEnabled);
        }}
      />
    );

    if (variant === ValueInMultipleSystemsFieldVariant.Default) {
      return (
        <>
          <Box
            sx={sxCompose(sx, {
              display: 'flex',
              gap: 2,
            })}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 4, flex: 1 }}
            >
              <ContentByLocale
                locale={locale}
                metricContent={
                  <ValueWithUnitField
                    valueField={{
                      id: generateFieldId({
                        prefix: id,
                        unitSystem: AppUnitSystem.Metric,
                        field: 'value',
                      }),
                      label: valueField.labels.metric,
                      placeholder: valueField.placeholder.default,
                      value: `${metricField.quantity.amount ?? ''}`,
                      isLoadingValue: isConverting.metric?.amount,
                      required: true,
                      onChange: (value: string) => {
                        handleFieldChange(
                          {
                            metric: {
                              amount: value,
                              unit: metricField.quantity.unit,
                            },
                            usCustomary: usCustomaryField.quantity,
                          },
                          {
                            system: AppUnitSystem.Metric,
                            field: AppQuantityChangeTriggeredByField.Amount,
                          }
                        );
                      },
                      ariaLabel: generateAriaLabel({
                        prefix: label,
                        unitSystem: AppUnitSystem.Metric,
                        field: 'value',
                      }),
                    }}
                    unitField={{
                      id: generateFieldId({
                        prefix: id,
                        unitSystem: AppUnitSystem.Metric,
                        field: 'unit',
                      }),
                      label: unitField.label,
                      placeholder: unitField.placeholder,
                      value: metricField.quantity.unit,
                      isLoadingValue: isConverting.metric?.unit,
                      options: metricField.allowedUnits,
                      onChange: (unit: ApiQuantityUnit | null) =>
                        handleFieldChange(
                          {
                            metric: {
                              amount: metricField.quantity.amount,
                              unit,
                            },
                            usCustomary: usCustomaryField.quantity,
                          },
                          {
                            system: AppUnitSystem.Metric,
                            field: AppQuantityChangeTriggeredByField.Unit,
                          }
                        ),
                      ariaLabel: generateAriaLabel({
                        prefix: label,
                        unitSystem: AppUnitSystem.Metric,
                        field: 'unit',
                      }),
                      isLoadingOptions: metricField.isLoadingUnits,
                      required: true,
                    }}
                    errors={metricField.errors}
                  />
                }
                usCustomaryContent={
                  <ValueWithUnitField
                    valueField={{
                      id: generateFieldId({
                        prefix: id,
                        unitSystem: AppUnitSystem.UsCustomary,
                        field: 'value',
                      }),
                      label: valueField.labels.usCustomary,
                      placeholder: valueField.placeholder.default,
                      value: `${usCustomaryField.quantity.amount ?? ''}`,
                      isLoadingValue: isConverting.usCustomary?.amount,
                      required: true,
                      onChange: (value: string) =>
                        handleFieldChange(
                          {
                            metric: metricField.quantity,
                            usCustomary: {
                              amount: value,
                              unit: usCustomaryField.quantity.unit,
                            },
                          },
                          {
                            system: AppUnitSystem.UsCustomary,
                            field: AppQuantityChangeTriggeredByField.Amount,
                          }
                        ),
                      ariaLabel: generateAriaLabel({
                        prefix: label,
                        unitSystem: AppUnitSystem.UsCustomary,
                        field: 'value',
                      }),
                    }}
                    unitField={{
                      id: generateFieldId({
                        prefix: id,
                        unitSystem: AppUnitSystem.UsCustomary,
                        field: 'unit',
                      }),
                      label: unitField.label,
                      placeholder: unitField.placeholder,
                      value: usCustomaryField.quantity.unit,
                      isLoadingValue: isConverting.usCustomary?.unit,
                      options: usCustomaryField.allowedUnits,
                      onChange: (unit: ApiQuantityUnit | null) =>
                        handleFieldChange(
                          {
                            metric: metricField.quantity,
                            usCustomary: {
                              amount: usCustomaryField.quantity.amount,
                              unit,
                            },
                          },
                          {
                            system: AppUnitSystem.UsCustomary,
                            field: AppQuantityChangeTriggeredByField.Unit,
                          }
                        ),
                      ariaLabel: generateAriaLabel({
                        prefix: label,
                        unitSystem: AppUnitSystem.UsCustomary,
                        field: 'unit',
                      }),
                      isLoadingOptions: usCustomaryField.isLoadingUnits,
                      required: true,
                    }}
                    errors={usCustomaryField.errors}
                  />
                }
              />
            </Box>
            <Box sx={{ mt: 10.5 }}>{linkButton}</Box>
          </Box>
          <ErrorMessage
            error={autoConversionError?.message}
            onRetry={handleAutoConversionRetry}
          />
        </>
      );
    }

    return (
      <>
        <Box
          sx={sxCompose(sx, {
            display: 'flex',
            gap: 2,
          })}
        >
          <Box sx={{ display: 'flex', gap: 2, flex: 1 }}>
            <ContentByLocale
              locale={locale}
              metricContent={
                <QuantityField
                  sx={{ flex: 1 }}
                  id={generateFieldId({
                    prefix: id,
                    unitSystem: AppUnitSystem.Metric,
                  })}
                  placeholder={valueField.placeholder.condensed}
                  quantity={fromStringToNumberValueWithUnit(
                    metricField.quantity
                  )}
                  units={metricField.allowedUnits}
                  errors={metricField.errors}
                  isLoadingValue={isConverting.metric?.amount}
                  onChange={(metric) =>
                    handleFieldChange(
                      {
                        metric: fromNumberToStringValueWithUnit(metric),
                        usCustomary: usCustomaryField.quantity,
                      },
                      {
                        system: AppUnitSystem.Metric,
                        field: AppQuantityChangeTriggeredByField.Amount,
                      }
                    )
                  }
                />
              }
              usCustomaryContent={
                <QuantityField
                  sx={{ flex: 1 }}
                  id={generateFieldId({
                    prefix: id,
                    unitSystem: AppUnitSystem.UsCustomary,
                  })}
                  placeholder={valueField.placeholder.condensed}
                  quantity={fromStringToNumberValueWithUnit(
                    usCustomaryField.quantity
                  )}
                  units={usCustomaryField.allowedUnits}
                  errors={usCustomaryField.errors}
                  isLoadingValue={isConverting.usCustomary?.amount}
                  onChange={(usCustomary) =>
                    handleFieldChange(
                      {
                        metric: metricField.quantity,
                        usCustomary:
                          fromNumberToStringValueWithUnit(usCustomary),
                      },
                      {
                        system: AppUnitSystem.UsCustomary,
                        field: AppQuantityChangeTriggeredByField.Amount,
                      }
                    )
                  }
                />
              }
            />
          </Box>
          <Box sx={{ mt: 8.5 }}>{linkButton}</Box>
        </Box>
        <ErrorMessage
          error={autoConversionError?.message}
          onRetry={handleAutoConversionRetry}
        />
      </>
    );
  });

export const generateQuantityConversionRequest = ({
  quantity,
  triggeredBy,
  primaryUnitSystem,
  context,
}: {
  quantity: ValueInMultipleSystemsValue;
  triggeredBy: AppQuantityChangeTriggeredBy;
  primaryUnitSystem: AppUnitSystem;
  context?: ApiRefConvertQuantityRequestContext;
}): ApiRefConvertQuantityRequest => {
  const { metric, usCustomary } = quantity;
  const secondaryUnitSystem = getSecondaryUnitSystem(primaryUnitSystem);
  const primaryQuantity = quantity[primaryUnitSystem];
  const secondaryQuantity = quantity[secondaryUnitSystem];
  const primaryUnitSystemId = getUnitSystemId(primaryUnitSystem);
  const secondaryUnitSystemId = getUnitSystemId(secondaryUnitSystem);

  if (
    triggeredBy.system === primaryUnitSystem &&
    isFormQuantityComplete(primaryQuantity)
  ) {
    return generateQuantityConversionPayload({
      from: { system: primaryUnitSystemId, quantity: primaryQuantity },
      to: { system: secondaryUnitSystemId, unit: secondaryQuantity.unit },
      context,
    });
  }

  if (triggeredBy.system === secondaryUnitSystem) {
    if (
      triggeredBy.field === AppQuantityChangeTriggeredByField.Amount &&
      isFormQuantityComplete(secondaryQuantity)
    ) {
      return generateQuantityConversionPayload({
        from: { system: secondaryUnitSystemId, quantity: secondaryQuantity },
        to: { system: primaryUnitSystemId, unit: primaryQuantity.unit },
        context,
      });
    }

    if (
      triggeredBy.field === AppQuantityChangeTriggeredByField.Unit &&
      isFormQuantityComplete(primaryQuantity)
    ) {
      return generateQuantityConversionPayload({
        from: { system: primaryUnitSystemId, quantity: primaryQuantity },
        to: { system: secondaryUnitSystemId, unit: secondaryQuantity.unit },
        context,
      });
    }

    if (
      triggeredBy.field === AppQuantityChangeTriggeredByField.Unit &&
      !isFormQuantityComplete(primaryQuantity) &&
      isFormQuantityComplete(secondaryQuantity)
    ) {
      return generateQuantityConversionPayload({
        from: { system: secondaryUnitSystemId, quantity: secondaryQuantity },
        to: { system: primaryUnitSystemId, unit: primaryQuantity.unit },
        context,
      });
    }
  }
  throw new Error(
    `You tried converting from "${triggeredBy.system}" but provided "${metric.amount}" (Metric amount), "${metric.unit?.id}" (Metric unit) and "${usCustomary.amount}" (US Customary amount), "${usCustomary.unit?.id}" (US Customary unit)`
  );
};

const generateQuantityConversionPayload = ({
  from,
  to,
  context,
}: {
  from: {
    system: ApiUnitSystemId;
    quantity: AppFormValueWithUnitComplete;
  };
  to: { system: ApiUnitSystemId; unit: ApiQuantityUnit | null };
  context?: ApiRefConvertQuantityRequestContext;
}): ApiRefConvertQuantityRequest => {
  return {
    entities: [
      {
        sourceUnit: {
          value: toNumber(from.quantity.amount) ?? 0,
          unitReferenceId: from.quantity.unit.id,
          unitSystemReferenceId: from.system,
          context,
        },
        targetUnit: {
          preferredUnitReferenceId: to.unit?.id,
          unitSystemReferenceId: to.system,
        },
      },
    ],
  };
};

export interface GetTargetSystemParams {
  primaryUnitSystem: AppUnitSystem;
  triggeredBy: AppQuantityChangeTriggeredBy;
  isPrimaryQuantityComplete: boolean;
  hasSecondaryUnit: boolean;
}

export const getConvertingFields = ({
  primaryUnitSystem,
  triggeredBy,
  isPrimaryQuantityComplete,
  hasSecondaryUnit,
}: GetTargetSystemParams): Partial<
  Record<AppUnitSystem, { amount: boolean; unit: boolean }>
> => {
  const secondaryUnitSystem = getSecondaryUnitSystem(primaryUnitSystem);

  if (triggeredBy.system === primaryUnitSystem) {
    return { [secondaryUnitSystem]: { amount: true, unit: !hasSecondaryUnit } };
  }

  if (triggeredBy.system === secondaryUnitSystem) {
    if (triggeredBy.field === AppQuantityChangeTriggeredByField.Amount) {
      return { [primaryUnitSystem]: { amount: true, unit: true } };
    }
    if (
      triggeredBy.field === AppQuantityChangeTriggeredByField.Unit &&
      isPrimaryQuantityComplete
    ) {
      return { [secondaryUnitSystem]: { amount: true, unit: false } };
    }
    if (
      triggeredBy.field === AppQuantityChangeTriggeredByField.Unit &&
      !isPrimaryQuantityComplete
    ) {
      return { [primaryUnitSystem]: { amount: true, unit: true } };
    }
  }
  return { [primaryUnitSystem]: { amount: true, unit: true } };
};

export const fromNumberToStringValueWithUnit = (
  value: AppRecipeValueWithUnitNullable
): AppFormValueWithUnit => ({
  ...value,
  amount: `${value.amount ?? ''}`,
});

export const fromStringToNumberValueWithUnit = (
  value: AppFormValueWithUnit
): AppRecipeValueWithUnitNullable => ({
  ...value,
  amount: toNumber(value.amount),
});

const ErrorMessage: FC<{
  error: string | undefined;
  onRetry: () => void;
}> = memo(function ErrorMessage({ error, onRetry }) {
  if (!error) {
    return null;
  }
  return (
    <Banner
      status={BannerStatus.Warning}
      label={error}
      sx={{ mt: 2, backgroundColor: PantryColor.SurfaceDefault, px: 0 }}
      primaryButton={{
        label: buttons.retry,
        onClick: onRetry,
      }}
    />
  );
});
