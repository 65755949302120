import {
  sxCompose,
  PantryTypography,
  PantryColor,
} from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';
import type { FC } from 'react';
import { memo } from 'react';

import type { ApiLocale } from 'api/types/common/apiLocale';
import { getIngredientPropertiesAsStrings } from 'features/recipe/review/ingredients/recipeReviewIngredients.utils';
import {
  RecipeValueInMultipleSystems,
  RecipeValueText,
} from 'features/recipe/shared/RecipeValueInMultipleSystems/RecipeValueInMultipleSystems';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import type { AppRecipeValueInMultipleSystems } from 'types/recipe/appRecipeValueInMultipleSystems';
import type { AppRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';

export interface RecipeIngredientDetailsProps {
  ingredient: AppRecipeIngredient;
  quantity?: AppRecipeValueWithUnit | AppRecipeValueInMultipleSystems;
  locale: ApiLocale;
  styles?: {
    container?: SxProps;
    name?: SxProps;
    preparations?: SxProps;
    quantity?: SxProps;
    emptyQuantity?: SxProps;
  };
  ariaRole?: React.AriaRole;
  ariaPrefix?: string;
}

export const RecipeIngredientDetails: FC<RecipeIngredientDetailsProps> = memo(
  function RecipeIngredientDetails({
    ingredient,
    quantity,
    locale,
    styles,
    ariaRole,
    ariaPrefix,
  }) {
    const { name, ariaLabel, preparationsAsString, quantityAsString } =
      getIngredientPropertiesAsStrings({
        ingredient,
        quantity: quantity ?? ingredient.quantity,
        ariaPrefix,
      });

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        aria-label={ariaLabel}
        role={ariaRole}
      >
        <Box
          sx={sxCompose(
            { display: 'flex', flexDirection: 'column', gap: 2 },
            styles?.container
          )}
        >
          <Typography
            variant={PantryTypography.Body2SemiBold}
            sx={sxCompose({ color: PantryColor.TextDefault }, styles?.name)}
          >
            {name}
          </Typography>
          {!!preparationsAsString && (
            <Typography
              variant={PantryTypography.Caption}
              sx={sxCompose(
                { color: PantryColor.TextSubtle },
                styles?.preparations
              )}
            >
              {preparationsAsString}
            </Typography>
          )}
        </Box>
        {typeof quantityAsString === 'string' ? (
          <RecipeValueText text={quantityAsString} />
        ) : (
          <RecipeValueInMultipleSystems locale={locale} {...quantityAsString} />
        )}
      </Box>
    );
  },
  (prev, next) => isEqual(prev, next)
);
