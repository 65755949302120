import type { ApiRcpRecipeStepIngredient } from 'api/types/recipe/apiRcpRecipeStepIngredient';
import type { ApiRcpRecipeValueInMultipleSystemsWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeValueInMultipleSystemsWithRefs';
import { fromAppRecipeValueInMultipleSystems } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeValueInMultipleSystemsWithRefs';
import type { ApiRcpRecipeValueWithUnitWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeValueWithUnitWithRefs';
import { fromAppRecipeValueWithUnit } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeValueWithUnitWithRefs';
import type { AppRecipeStepIngredient } from 'types/recipe/appRecipeStepIngredient';
import { isValueInSingleSystem } from 'utils/unitSystems';

export interface ApiRcpRecipeStepIngredientWithRefs
  extends Pick<ApiRcpRecipeStepIngredient, 'ingredientIdx'> {
  quantity:
    | ApiRcpRecipeValueWithUnitWithRefs
    | ApiRcpRecipeValueInMultipleSystemsWithRefs;
}

export function fromAppRecipeStepIngredient({
  ingredientIdx,
  quantity,
}: AppRecipeStepIngredient): ApiRcpRecipeStepIngredientWithRefs {
  return {
    ingredientIdx,
    quantity: isValueInSingleSystem(quantity)
      ? fromAppRecipeValueWithUnit(quantity)
      : fromAppRecipeValueInMultipleSystems(quantity),
  };
}
