import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from '@dropkitchen/pantry-react';
import type { FC } from 'react';
import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { ApiLocale } from 'api/types/common/apiLocale';
import { generateHomeFeedPreviewRoute } from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { Dialog } from 'components/Dialog/Dialog';
import { LocaleField } from 'components/LocaleField/LocaleField';
import {
  homeFeedPreviewDialogConstants,
  homeFeedPreviewDialogStrings,
} from 'features/homeFeed/HomeFeedPreviewDialog/HomeFeedPreviewDialog.constants';
import {
  homeFeedsPreviewDialogClosed,
  selectHomeFeedIsPreviewDialogOpen,
} from 'features/homeFeed/homeFeedsSlice';

const { title, labels, placeholders } = homeFeedPreviewDialogStrings;
const { fieldIds } = homeFeedPreviewDialogConstants;

export interface HomeFeedPreviewDialogProps {
  defaultLocale: ApiLocale;
}

export const HomeFeedPreviewDialog: FC<HomeFeedPreviewDialogProps> = memo(
  function HomeFeedPreviewDialog({ defaultLocale }) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isOpen = useAppSelector(selectHomeFeedIsPreviewDialogOpen);

    const [locale, setLocale] = useState<ApiLocale | null>(defaultLocale);

    useEffect(() => {
      if (isOpen) {
        setLocale(defaultLocale);
      }
    }, [defaultLocale, isOpen]);

    return (
      <Dialog
        title={title}
        isOpen={isOpen}
        onClose={() => dispatch(homeFeedsPreviewDialogClosed())}
        onSubmit={() => {
          if (!locale) {
            return;
          }
          dispatch(homeFeedsPreviewDialogClosed());
          navigate(generateHomeFeedPreviewRoute({ locale }));
        }}
        size="sm"
        components={{
          content: (
            <LocaleField
              id={fieldIds.locale}
              label={labels.localeField}
              placeholder={placeholders.localeField}
              onChange={(currentLocale) => setLocale(currentLocale)}
              value={locale}
              autoFocus
            />
          ),
          buttons: (
            <>
              <Button
                label={labels.cancelButton}
                buttonStyle={ButtonStyle.Default}
                size={ButtonSize.Large}
                onClick={() => dispatch(homeFeedsPreviewDialogClosed())}
              />
              <Button
                label={labels.nextButton}
                type={ButtonType.Submit}
                buttonStyle={ButtonStyle.Emphasis}
                size={ButtonSize.Large}
                disabled={!locale}
              />
            </>
          ),
        }}
      />
    );
  }
);
