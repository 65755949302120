import upperFirst from 'lodash/upperFirst';

import type { ApiAplCapabilityAllowedSetting } from 'api/types/appliance/apiAplCapabilityAllowedSetting';
import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRefCapabilityAllowedSetting } from 'api/types/referenceData/apiRefCapabilityAllowedSetting';
import { refDataEntitiesToSentenceCase } from 'features/referenceData/referenceData.utils';
import type { AppCapabilityAllowedSettingValueBoolean } from 'types/appCapabilityAllowedSettingValueBoolean';
import type { AppCapabilityAllowedSettingValueNominal } from 'types/appCapabilityAllowedSettingValueNominal';
import { fromApiAplCapabilityAllowedSettingValueNominal } from 'types/appCapabilityAllowedSettingValueNominal';
import type { AppCapabilityAllowedSettingValueNumeric } from 'types/appCapabilityAllowedSettingValueNumeric';
import { fromApiAplCapabilityAllowedSettingValueNumeric } from 'types/appCapabilityAllowedSettingValueNumeric';
import type { AppCapabilityAllowedSettingDependency } from 'types/appCapabilitySettingDependency';
import {
  fromApiRefCapabilityAllowedSettingDependency,
  fromApiAplCapabilityAllowedSettingDependency,
} from 'types/appCapabilitySettingDependency';
import type { AppCapabilitySettingDefaultValue } from 'types/recipe/appCapabilitySettingDefaultValue';
import { fromApiAplCapabilitySettingValue } from 'types/recipe/appCapabilitySettingDefaultValue';

export interface AppCapabilityAllowedSetting {
  id: ApiEntityId;
  name: string;
  defaultValue?: AppCapabilitySettingDefaultValue;
  allowedValues: {
    nominal?: AppCapabilityAllowedSettingValueNominal[];
    numeric?: AppCapabilityAllowedSettingValueNumeric[];
    boolean?: AppCapabilityAllowedSettingValueBoolean[];
  };
  dependsOnSetting?: AppCapabilityAllowedSettingDependency[];
}

export const fromApiAplCapabilityAllowedSetting = ({
  id,
  name,
  defaultValue,
  allowedValues: { nominal, numeric, boolean },
  dependsOnSetting,
}: ApiAplCapabilityAllowedSetting): AppCapabilityAllowedSetting => ({
  id,
  name,
  defaultValue:
    defaultValue &&
    fromApiAplCapabilitySettingValue(
      defaultValue,
      {
        nominal,
        numeric,
        boolean,
      },
      id
    ),
  allowedValues: {
    nominal:
      nominal && nominal.map(fromApiAplCapabilityAllowedSettingValueNominal),
    numeric:
      numeric && numeric.map(fromApiAplCapabilityAllowedSettingValueNumeric),
    boolean,
  },
  dependsOnSetting: dependsOnSetting?.map(
    fromApiAplCapabilityAllowedSettingDependency
  ),
});

export const fromApiRefCapabilityAllowedSetting = ({
  id,
  name,
  allowedValues: { nominal, numeric },
  dependsOnSetting,
}: ApiRefCapabilityAllowedSetting): AppCapabilityAllowedSetting => ({
  id,
  name: upperFirst(name),
  allowedValues: {
    nominal: nominal && refDataEntitiesToSentenceCase(nominal),
    numeric,
  },
  dependsOnSetting: dependsOnSetting?.map(
    fromApiRefCapabilityAllowedSettingDependency
  ),
});

export enum AppGeneralCapabilitySettingId {
  Power = 'cckg:PowerSetting',
  Pressure = 'cckg:PressureSetting',
  Speed = 'cckg:SpeedSetting',
  Temperature = 'cckg:TemperatureSetting',
  Time = 'cckg:TimeSetting',
  Venting = 'cckg:VentingSetting',
  VentingTime = 'cckg:VentingTimeSetting',
}
