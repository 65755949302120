import type { ApiRcpRecipeValueWithUnitWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeValueWithUnitWithRefs';
import { fromAppRecipeValueWithUnit } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeValueWithUnitWithRefs';
import { AppUnitSystem } from 'types/appUnitSystem';
import type { AppRecipeValueInMultipleSystems } from 'types/recipe/appRecipeValueInMultipleSystems';

export type ApiRcpRecipeValueInMultipleSystemsWithRefs = {
  metric: ApiRcpRecipeValueWithUnitWithRefs;
  usCustomary: ApiRcpRecipeValueWithUnitWithRefs;
};

export function fromAppRecipeValueInMultipleSystems(
  value: AppRecipeValueInMultipleSystems
): ApiRcpRecipeValueInMultipleSystemsWithRefs {
  return {
    [AppUnitSystem.Metric]: fromAppRecipeValueWithUnit(
      value[AppUnitSystem.Metric]
    ),
    [AppUnitSystem.UsCustomary]: fromAppRecipeValueWithUnit(
      value[AppUnitSystem.UsCustomary]
    ),
  };
}
