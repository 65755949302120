import snakecaseKeys from 'snakecase-keys';

import { createApiFn, fetchJson } from 'api/fetchJson';
import { generateApiPath } from 'api/generateApiPath';
import {
  apiCuratedCollectionUrl,
  apiCuratedCollectionsUrl,
  apiPathParams,
} from 'api/paths';
import type { ApiRequestFn } from 'api/types';
import { CustomHeader, HttpMethod } from 'api/types';
import type { ApiCcCuratedCollection } from 'api/types/curatedCollection/apiCcCuratedCollection';
import type { ApiCcCuratedCollectionId } from 'api/types/curatedCollection/apiCcCuratedCollectionId';
import type { ApiCcCuratedCollectionWithRefs } from 'api/types/curatedCollection/apiCcCuratedCollectionWithRefs';

export const postCuratedCollection = async (
  body: ApiCcCuratedCollectionWithRefs
) =>
  fetchJson<ApiCcCuratedCollection>({
    httpMethod: HttpMethod.Post,
    url: apiCuratedCollectionsUrl,
    body: snakecaseKeys(body),
  });
export const apiPostCuratedCollection: ApiRequestFn<
  ApiCcCuratedCollection,
  ApiCcCuratedCollectionWithRefs
> = createApiFn(postCuratedCollection);

export interface ApiPutCuratedCollectionRequest {
  id: ApiCcCuratedCollectionId;
  body: Omit<ApiCcCuratedCollectionWithRefs, 'locale' | 'applianceTagIds'>;
  etag: string;
}
export const putCuratedCollection = async ({
  id,
  body,
  etag,
}: ApiPutCuratedCollectionRequest) =>
  fetchJson<ApiCcCuratedCollection>({
    httpMethod: HttpMethod.Put,
    url: generateApiPath(apiCuratedCollectionUrl, {
      [apiPathParams.collectionId]: id,
    }),
    body: snakecaseKeys(body),
    headers: {
      [CustomHeader.IfMatch]: etag,
    },
  });
export const apiPutCuratedCollection: ApiRequestFn<
  ApiCcCuratedCollection,
  ApiPutCuratedCollectionRequest
> = createApiFn(putCuratedCollection);

export interface ApiGetCuratedCollectionRequest {
  id: ApiCcCuratedCollectionId;
}

export const getCuratedCollection = async ({
  id,
}: ApiGetCuratedCollectionRequest) =>
  fetchJson<ApiCcCuratedCollection>({
    httpMethod: HttpMethod.Get,
    url: generateApiPath(apiCuratedCollectionUrl, {
      [apiPathParams.collectionId]: id,
    }),
  });

export const apiGetCuratedCollection: ApiRequestFn<
  ApiCcCuratedCollection,
  ApiGetCuratedCollectionRequest
> = createApiFn(getCuratedCollection);

export const deleteCuratedCollection = async (id: ApiCcCuratedCollectionId) =>
  fetchJson<void>({
    httpMethod: HttpMethod.Delete,
    url: generateApiPath(apiCuratedCollectionUrl, {
      [apiPathParams.collectionId]: id,
    }),
  });

export const apiDeleteCuratedCollection: ApiRequestFn<
  void,
  ApiCcCuratedCollectionId
> = createApiFn(deleteCuratedCollection);
