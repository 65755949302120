import { TextField } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { useAutoFocus } from 'app/routes/hooks/useAutoFocus';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { recipeBasicInformationStrings } from 'features/recipe/recipeBasicInformation/RecipeBasicInformation.constants';
import type { RecipeFieldProps } from 'features/recipe/shared/types/appRecipeFieldProps';

const { labels, placeholders, errorMessages } = recipeBasicInformationStrings;

export interface RecipeServesFieldProps extends RecipeFieldProps {
  showErrors?: boolean;
}

export const RecipeServesField: FC<RecipeServesFieldProps> = memo(
  function RecipeServesField({ id, value, showErrors, onChange }) {
    const { isFocused } = useAutoFocus();

    return (
      <TextField
        id={id}
        label={labels.servesField}
        type="number"
        placeholder={placeholders.servesField}
        variant="outlined"
        fullWidth
        required
        autoFocus={isFocused(id)}
        error={showErrors}
        value={value}
        helperText={
          showErrors && (
            <HelperText
              message={errorMessages.recipeServesField.required}
              severity={HelperTextSeverity.Critical}
            />
          )
        }
        onChange={(event) => onChange({ value: event.target.value })}
        inputProps={{ min: 1 }}
      />
    );
  }
);
