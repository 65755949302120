import {
  PantryColor,
  PantryCornerRadius,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { memo } from 'react';

import { dragAndDropConstants } from 'components/DragAndDrop/DragAndDrop.constants';
import type { PlaceholderProps } from 'components/DragAndDrop/useDragPlaceholder';

export interface DragPlaceholderProps {
  isDraggingOver: boolean;
  placeholderProps: PlaceholderProps;
  sx?: SxProps;
}

const { testIds } = dragAndDropConstants;

export const DragPlaceholder: FC<DragPlaceholderProps> = memo(
  function DragPlaceholder({ isDraggingOver, placeholderProps, sx }) {
    if (isEmpty(placeholderProps) || !isDraggingOver) {
      return null;
    }

    return (
      <Box
        sx={sxCompose(
          {
            top: placeholderProps.clientY,
            left: placeholderProps.clientX,
            height: placeholderProps.clientHeight,
            width: placeholderProps.clientWidth,
            position: 'absolute',
            zIndex: 0,
          },
          sx
        )}
        data-testid={testIds.dragPlaceholder}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: PantryColor.SurfaceSubtle,
            borderRadius: PantryCornerRadius.Small,
          }}
        />
      </Box>
    );
  }
);
