import type { Action } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import { put, select, takeLatest } from 'redux-saga/effects';

import type { ApiIngredientPreparation } from 'api/types/common/apiIngredientPreparation';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import { toNumber } from 'features/recipe/ingredients/form/recipeIngredientForm.utils';
import {
  ingredientAmountUpdated,
  ingredientHasChanged,
  ingredientPreparationMoved,
  ingredientPreparationsUpdated,
  ingredientQuantityMultipleSystemsUpdated,
  ingredientUnitUpdated,
  ingredientUpdated,
  initialState,
  selectAmount,
  selectIndex,
  selectIngredient,
  selectMetricValueWithUnit,
  selectPreparations,
  selectUnit,
  selectUsCustomaryValueWithUnit,
} from 'features/recipe/ingredients/form/recipeIngredientFormSlice';
import {
  recipeIngredientAdded,
  recipeIngredientUpdated,
  selectRecipeIngredient,
} from 'features/recipe/recipeSlice';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import { isValueInSingleSystem } from 'utils/unitSystems';

export function* recipeIngredientHasChanged() {
  const ingredientIndex = (yield select(selectIndex)) as ReturnType<
    typeof selectIndex
  >;
  const ingredient = (yield select(selectIngredient)) as ReturnType<
    typeof selectIngredient
  >;
  const amount = toNumber(
    (yield select(selectAmount)) as ReturnType<typeof selectAmount>
  );
  const unit = (yield select(selectUnit)) as ReturnType<typeof selectUnit>;
  const preparations = (yield select(selectPreparations)) as ReturnType<
    typeof selectPreparations
  >;
  const metric = (yield select(selectMetricValueWithUnit)) as ReturnType<
    typeof selectMetricValueWithUnit
  >;
  const usCustomary = (yield select(
    selectUsCustomaryValueWithUnit
  )) as ReturnType<typeof selectUsCustomaryValueWithUnit>;

  const updatedState: TrackedState = {
    id: ingredient?.id,
    amount,
    unit: unit?.id ?? null,
    preparations,
    metric: {
      amount: toNumber(metric.amount),
      unit: metric.unit?.id ?? null,
    },
    usCustomary: {
      amount: toNumber(usCustomary.amount),
      unit: usCustomary.unit?.id ?? null,
    },
  };

  if (ingredientIndex === null) {
    const currentState: TrackedState = {
      id: initialState.ingredient?.id,
      amount: toNumber(initialState.amount),
      unit: initialState.unit?.id ?? null,
      preparations: initialState.ingredientPreparations,
      metric: {
        amount: toNumber(initialState.metric.amount),
        unit: initialState.metric.unit?.id ?? null,
      },
      usCustomary: {
        amount: toNumber(initialState.usCustomary.amount),
        unit: initialState.usCustomary.unit?.id ?? null,
      },
    };
    yield put(
      ingredientHasChanged({
        hasChanged: hasChanged(currentState, updatedState),
      })
    );
    return;
  }

  const recipeIngredient = (yield select(
    selectRecipeIngredient(ingredientIndex)
  )) as AppRecipeIngredient;

  const currentState: TrackedState = {
    id: recipeIngredient.id,
    amount: isValueInSingleSystem(recipeIngredient.quantity)
      ? recipeIngredient.quantity.amount
      : toNumber(initialState.amount),
    unit: isValueInSingleSystem(recipeIngredient.quantity)
      ? recipeIngredient.quantity.unit.id
      : initialState.unit?.id ?? null,
    preparations: recipeIngredient.preparations || [],
    metric: !isValueInSingleSystem(recipeIngredient.quantity)
      ? {
          amount: recipeIngredient.quantity.metric.amount,
          unit: recipeIngredient.quantity.metric.unit.id,
        }
      : {
          amount: toNumber(initialState.metric.amount),
          unit: initialState.metric.unit?.id ?? null,
        },
    usCustomary: !isValueInSingleSystem(recipeIngredient.quantity)
      ? {
          amount: recipeIngredient.quantity.usCustomary.amount,
          unit: recipeIngredient.quantity.usCustomary.unit.id,
        }
      : {
          amount: toNumber(initialState.usCustomary.amount),
          unit: initialState.usCustomary.unit?.id ?? null,
        },
  };

  yield put(
    ingredientHasChanged({
      hasChanged: hasChanged(currentState, updatedState),
    })
  );
}

export const shouldTriggerIngredientChange = (
  action: Action<string>
): boolean =>
  [
    ingredientUpdated.type,
    ingredientAmountUpdated.type,
    ingredientUnitUpdated.type,
    ingredientQuantityMultipleSystemsUpdated.type,
    ingredientPreparationsUpdated.type,
    ingredientPreparationMoved.type,
    recipeIngredientAdded.type,
    recipeIngredientUpdated.type,
  ].includes(action.type);

function* recipeIngredientHasChangedWatcher() {
  yield takeLatest(shouldTriggerIngredientChange, recipeIngredientHasChanged);
}

export const recipeIngredientsFormRestartableSagas = [
  recipeIngredientHasChangedWatcher,
];

interface TrackedState {
  id?: ApiRefId;
  preparations: ApiIngredientPreparation[];
  amount: number | null;
  unit: ApiRefId | null;
  metric: {
    amount: number | null;
    unit: ApiRefId | null;
  };
  usCustomary: {
    amount: number | null;
    unit: ApiRefId | null;
  };
}

const hasChanged = (current: TrackedState, updated: TrackedState): boolean => {
  return (
    current.id !== updated.id ||
    current.amount !== updated.amount ||
    !isEqual(current.unit, updated.unit) ||
    !isEqual(current.preparations, updated.preparations) ||
    current.metric.amount !== updated.metric.amount ||
    !isEqual(current.metric.unit, updated.metric.unit) ||
    current.usCustomary.amount !== updated.usCustomary.amount ||
    !isEqual(current.usCustomary.unit, updated.usCustomary.unit)
  );
};
