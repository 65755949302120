import { RecipeType } from 'app/routes/constants';

export const recipesPageStrings = {
  titles: {
    main: 'Recipes',
  },
  tabs: {
    [RecipeType.Core]: 'Core',
    [RecipeType.Translation]: 'Translations',
  },
  buttons: {
    add: 'Add new recipe',
    createFromScratch: {
      title: 'Create from scratch',
      description: 'Add new recipe details manually',
    },
    createFromText: {
      title: 'Create from text',
      description: 'Copy and paste existing recipe details',
    },
    createFromUrl: {
      title: 'Create from URL',
      description: 'Copy and paste a URL from the web',
    },
  },
} as const;
