import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ApiHfHomeFeedListItem } from 'api/types/homeFeed/apiHfHomeFeedListItem';
import type { RootState } from 'app/store/rootReducer';

export interface HomeFeedsState {
  apiError?: string;
  isFetching: boolean;
  isAddCollectionDialogOpen: boolean;
  isPreviewDialogOpen: boolean;
  homeFeeds: ApiHfHomeFeedListItem[];
}

export const initialState: HomeFeedsState = {
  isFetching: false,
  isAddCollectionDialogOpen: false,
  isPreviewDialogOpen: false,
  homeFeeds: [],
};

const homeFeedsSlice = createSlice({
  name: 'homeFeeds',
  initialState,
  reducers: {
    homeFeedsFetchRequested(state) {
      state.apiError = undefined;
      state.isFetching = true;
    },
    homeFeedsFetchSucceed(
      state,
      action: PayloadAction<ApiHfHomeFeedListItem[]>
    ) {
      state.isFetching = false;
      state.homeFeeds = action.payload;
    },
    homeFeedsFetchFailed(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.apiError = action.payload;
    },
    homeFeedsAddCollectionDialogOpened(state) {
      state.isAddCollectionDialogOpen = true;
    },
    homeFeedsAddCollectionDialogClosed(state) {
      state.isAddCollectionDialogOpen = false;
    },
    homeFeedsPreviewDialogOpened(state) {
      state.isPreviewDialogOpen = true;
    },
    homeFeedsPreviewDialogClosed(state) {
      state.isPreviewDialogOpen = false;
    },
  },
});

export const {
  reducer: homeFeedsReducer,
  actions: {
    homeFeedsFetchRequested,
    homeFeedsFetchSucceed,
    homeFeedsFetchFailed,
    homeFeedsAddCollectionDialogOpened,
    homeFeedsAddCollectionDialogClosed,
    homeFeedsPreviewDialogOpened,
    homeFeedsPreviewDialogClosed,
  },
} = homeFeedsSlice;

const selectHomeFeedsState = (state: RootState): HomeFeedsState =>
  state.homeFeeds;

export const selectHomeFeedsIsFetching = (state: RootState): boolean =>
  selectHomeFeedsState(state).isFetching;

export const selectHomeFeeds = (state: RootState): ApiHfHomeFeedListItem[] =>
  selectHomeFeedsState(state).homeFeeds;

export const selectHomeFeedIsAddCollectionDialogOpen = (
  state: RootState
): boolean => selectHomeFeedsState(state).isAddCollectionDialogOpen;

export const selectHomeFeedIsPreviewDialogOpen = (state: RootState) =>
  selectHomeFeedsState(state).isPreviewDialogOpen;
