import upperFirst from 'lodash/upperFirst';

import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import type { ApiRcpRecipeValueWithUnit } from 'api/types/recipe/apiRcpRecipeValueWithUnit';
import type { AppNonNullable } from 'types/appNonNullable';
import type { AppNullable } from 'types/appNullable';

export interface AppRecipeValueWithUnit<TValue = number> {
  amount: TValue | null;
  text?: string;
  unit: ApiQuantityUnit;
}

export type AppRecipeValueWithUnitComplete = AppNonNullable<
  Pick<AppRecipeValueWithUnit, 'amount' | 'unit'>
>;

export type AppRecipeValueWithUnitNullable = AppNullable<
  Pick<AppRecipeValueWithUnit, 'amount' | 'unit'>
>;

export function fromApiRcpRecipeValueWithUnit({
  amount,
  text,
  referenceUnit,
}: ApiRcpRecipeValueWithUnit): AppRecipeValueWithUnit {
  return {
    amount: amount ?? null,
    text: upperFirst(text),
    unit: {
      id: referenceUnit.id,
      name: referenceUnit.name,
      abbreviation: referenceUnit.abbreviation,
    },
  };
}
