import type { ApiRcpRecipeIngredient } from 'api/types/recipe/apiRcpRecipeIngredient';
import {
  fromAppRecipeValueInMultipleSystems,
  type ApiRcpRecipeValueInMultipleSystemsWithRefs,
} from 'api/types/recipe/recipeWithRefs/apiRcpRecipeValueInMultipleSystemsWithRefs';
import type { ApiRcpRecipeValueWithUnitWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeValueWithUnitWithRefs';
import { fromAppRecipeValueWithUnit } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeValueWithUnitWithRefs';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import { isValueInSingleSystem } from 'utils/unitSystems';

export interface ApiRcpRecipeIngredientWithRefs
  extends Pick<ApiRcpRecipeIngredient, 'sourceText'> {
  referenceIngredientId: ApiRefId;
  referencePreparationIds?: ApiRefId[];
  quantity:
    | ApiRcpRecipeValueWithUnitWithRefs
    | ApiRcpRecipeValueInMultipleSystemsWithRefs;
}

export function fromAppRecipeIngredient({
  id,
  preparations,
  quantity,
  sourceText,
}: AppRecipeIngredient): ApiRcpRecipeIngredientWithRefs {
  return {
    referenceIngredientId: id,
    referencePreparationIds: preparations?.map((preparation) => preparation.id),
    quantity: isValueInSingleSystem(quantity)
      ? fromAppRecipeValueWithUnit(quantity)
      : fromAppRecipeValueInMultipleSystems(quantity),
    sourceText,
  };
}
