import type { ApiCapabilityAllowedPhase } from 'api/types/common/apiCapabilityAllowedPhase';
import type { ApiRefEntity } from 'api/types/referenceData/apiRefEntity';
import { refDataEntityToSentenceCase } from 'features/referenceData/referenceData.utils';
import type { AppCapabilityAllowedPhaseId } from 'types/appCapabilityAllowedPhase.constants';

export interface AppCapabilityAllowedPhase extends ApiRefEntity {
  id: AppCapabilityAllowedPhaseId;
}

export const fromApiCapabilityAllowedPhase = (
  phase: ApiCapabilityAllowedPhase
) => refDataEntityToSentenceCase(phase);
