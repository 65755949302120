export const recipeStepStrings = {
  titles: {
    capabilities: 'Appliance Settings',
    ingredients: 'Ingredients',
    incompatibility: 'Appliance preset incompatibility',
  },
  labels: {
    deleteButton: 'Delete ingredient',
  },
} as const;
