import type { IconProps } from '@dropkitchen/pantry-react';
import {
  PantryTypography,
  PantryColor,
  Skeleton,
  SkeletonVariant,
  Button,
  ButtonStyle,
  ButtonSize,
} from '@dropkitchen/pantry-react';
import { Box, Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { ButtonWithSkeleton } from 'components/ButtonWithSkeleton/ButtonWithSkeleton';
import {
  recipeTabContainerConstants,
  recipeTabContainerStyle,
} from 'features/recipe/shared/RecipeTabContainer/RecipeTabContainer.constants';

export interface RecipeTabContainerProps {
  title?: {
    component?: ReactNode;
    string?: string;
  };
  button?: {
    label: string;
    onClick: () => void;
    loading: boolean;
    leadingIcon?: FC<IconProps>;
  };
  children: ReactNode;
}

const { ariaLabels } = recipeTabContainerConstants;

export const RecipeTabContainer: FC<RecipeTabContainerProps> = memo(
  function RecipeTabContainer({ title, button, children }) {
    return (
      <Box sx={recipeTabContainerStyle}>
        <Box
          sx={{
            mb: 6,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          {title?.component}
          {title?.string && (
            <Typography
              variant={PantryTypography.Subtitle1}
              color={PantryColor.TextDefault}
            >
              {title.string}
            </Typography>
          )}
          {!title && (
            <Box aria-label={ariaLabels.titleSkeleton}>
              <Skeleton
                variant={SkeletonVariant.Small}
                sx={{ width: 150, height: 28 }}
              />
            </Box>
          )}
          {button && (
            <ButtonWithSkeleton
              isLoading={button.loading}
              button={
                <Button
                  label={button.label}
                  buttonStyle={ButtonStyle.Default}
                  size={ButtonSize.Medium}
                  onClick={button.onClick}
                  leadingIcon={button.leadingIcon}
                />
              }
            />
          )}
        </Box>
        {children}
      </Box>
    );
  }
);
