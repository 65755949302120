import { string } from 'yup';

import { homeFeedCollectionPageStrings } from 'features/homeFeed/HomeFeedCollectionPage/HomeFeedCollectionPage.constants';
import type { HomeFeedCollectionFieldsToValidate } from 'features/homeFeed/HomeFeedCollectionPage/homeFeedCollectionSlice';
import type { ValidatorError } from 'utils/validator';
import { Validator } from 'utils/validator';

export type AppCollectionErrors = Partial<
  Record<HomeFeedCollectionFieldsToValidate, ValidatorError>
>;

const { errorMessages: errors } = homeFeedCollectionPageStrings;

export const collectionValidator = new Validator({
  title: string().required(errors.titleField.required),
  subtitle: string().required(errors.subtitleField.required),
});
