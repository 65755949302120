import type { FC } from 'react';
import { memo } from 'react';

import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { recipeReviewStrings } from 'features/recipe/review/RecipeReview.constants';
import { RecipeReviewIngredient } from 'features/recipe/review/ingredients/RecipeReviewIngredient';
import { recipeReviewIngredientsStrings } from 'features/recipe/review/ingredients/RecipeReviewIngredients.constants';
import { RecipeReviewEmptyValue } from 'features/recipe/review/shared/RecipeReviewEmptyValue/RecipeReviewEmptyValue';
import { RecipeReviewListContainer } from 'features/recipe/review/shared/RecipeReviewListContainer/RecipeReviewListContainer';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';

const { sections } = recipeReviewStrings;

export interface RecipeReviewIngredientsProps {
  ingredients: AppRecipeIngredient[];
  emptyLink?: string;
  recipeId?: ApiRcpRecipeId;
}

export const RecipeReviewIngredients: FC<RecipeReviewIngredientsProps> = memo(
  function RecipeReviewIngredients({ ingredients, emptyLink, recipeId }) {
    return (
      <RecipeReviewListContainer
        components={{
          title: sections.ingredients,
          list: (
            <>
              {ingredients.length === 0 && (
                <RecipeReviewEmptyValue
                  emptyLink={emptyLink}
                  message={recipeReviewIngredientsStrings.messages.empty}
                />
              )}
              {ingredients.map((ingredient, index) => (
                <RecipeReviewIngredient
                  ingredient={ingredient}
                  // Using index as key as we can add same ingredient more than once so ingredient.id is not suitable
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  sx={{ mb: 6 }}
                  index={index}
                  recipeId={recipeId}
                />
              ))}
            </>
          ),
        }}
      />
    );
  }
);
