import { createSearchParams, generatePath } from 'react-router-dom';
import type { NavigateOptions, To } from 'react-router-dom';
import type { SagaIterator } from 'redux-saga';
import { call, getContext } from 'redux-saga/effects';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { RecipeType } from 'app/routes/constants';
import {
  appSearchParams,
  appAppliancesRoutes,
  appRecipeRoutes,
  appRouteParams,
  appHomeFeedRoutes,
} from 'app/routes/constants';
import type { SagaContext } from 'app/store/store';
import type { RecipeTabName } from 'features/recipe/RecipePage.constants';

export interface GenerateRecipeRouteParams {
  id?: string;
  tab?: RecipeTabName;
  autofocusedFieldId?: string;
}

export const generateRecipeRoute = ({
  id,
  tab,
  autofocusedFieldId,
}: GenerateRecipeRouteParams = {}): string => {
  const { root, create, edit } = appRecipeRoutes;
  const { recipeId, recipeTab } = appRouteParams;
  const route = id
    ? `/${root}/${tab ? edit.tab : edit.root}`
    : `/${root}/${tab ? create.tab : create.root}`;

  const pathname = generatePath(route, {
    [recipeId]: id,
    [recipeTab]: tab,
  });
  if (!autofocusedFieldId) {
    return pathname;
  }
  return `${pathname}?${createSearchParams({
    [appSearchParams.autofocusedFieldId]: autofocusedFieldId,
  }).toString()}`;
};

export const generateRecipeListRoute = ({
  type,
}: {
  type: RecipeType;
}): string => {
  const { root, list } = appRecipeRoutes;
  return `/${root}/${list[type]}`;
};

export const generateApplianceRoute = ({
  id,
}: {
  id?: string;
} = {}): string => {
  const { root, edit } = appAppliancesRoutes;
  const { applianceId } = appRouteParams;
  const route = id ? `/${root}/${edit.root}` : `/${root}`;
  return generatePath(route, { [applianceId]: id });
};

export const generateHomeFeedRoute = ({
  locale,
}: {
  locale: ApiLocale;
}): string => {
  const { root, tab } = appHomeFeedRoutes;
  const { homeFeedLocale } = appRouteParams;
  return generatePath(`/${root}/${tab}`, {
    [homeFeedLocale]: locale,
  });
};

export const generateHomeFeedPreviewRoute = ({
  locale,
}: {
  locale: ApiLocale;
}): string => {
  const { root, preview } = appHomeFeedRoutes;
  const { homeFeedLocale } = appRouteParams;
  return generatePath(`/${root}/${preview}`, { [homeFeedLocale]: locale });
};

export const generateHomeFeedCollectionRoute = ({
  id,
}: {
  id?: string;
} = {}): string => {
  const { root, create, edit } = appHomeFeedRoutes;
  const { collectionId } = appRouteParams;
  const route = id ? `/${root}/${edit.root}` : `/${root}/${create.root}`;
  return generatePath(route, { [collectionId]: id });
};

export interface NavigateSagaParams {
  to: To;
  options?: NavigateOptions;
}

export function* navigateSaga(params: NavigateSagaParams): SagaIterator<void> {
  const contextNavigate = (yield getContext(
    'navigate'
  )) as SagaContext['navigate'];

  yield call(contextNavigate, params);
}
