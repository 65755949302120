import type { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { createApiRequestSaga } from 'api/createApiRequestSaga';
import { apiGetIngredients } from 'api/ingredients';
import type { ApiResponse } from 'api/types';
import {
  fromApiRefIngredient,
  type ApiIngredient,
} from 'api/types/common/apiIngredient';
import {
  ingredientsFetchFailed,
  ingredientsFetching,
  ingredientsFetchRequested,
  ingredientsFetchSucceed,
  selectShouldFetchIngredients,
} from 'features/referenceData/ingredients/ingredientsSlice';
import { fetchItemsRecursively } from 'features/referenceData/referenceData.utils';
import type { AppLocalizedFetchActionPayload } from 'types/appLocalizedFetchActionPayload';

export const apiFetchIngredientsSaga = createApiRequestSaga(apiGetIngredients);

export function* fetchIngredients({
  payload: { locale },
}: PayloadAction<AppLocalizedFetchActionPayload>) {
  const shouldFetch = (yield select(
    selectShouldFetchIngredients(locale)
  )) as ReturnType<typeof selectShouldFetchIngredients>;
  if (!shouldFetch) {
    return;
  }

  yield put(ingredientsFetching({ locale }));

  const response = (yield call(fetchItemsRecursively, {
    locale,
    fetchSaga: apiFetchIngredientsSaga,
  })) as ApiResponse<ApiIngredient[]>;

  if (!response.ok) {
    const { message } = response.details;
    yield put(ingredientsFetchFailed({ locale, data: message }));
    return;
  }
  yield put(
    ingredientsFetchSucceed({
      locale,
      data: response.data.map(fromApiRefIngredient),
    })
  );
}

function* ingredientsFetchWatcher() {
  yield takeEvery(ingredientsFetchRequested, fetchIngredients);
}

export const ingredientsSagas = [ingredientsFetchWatcher];
