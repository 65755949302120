export const homeFeedsPageStrings = {
  title: 'Home Feed',
  emptyHomeFeeds: {
    title: 'Create your Home Feed',
    text: 'There are no home feeds yet in this organization',
  },
  emptyHomeFeed: {
    title: 'Create your first Collection',
    text: 'Build exciting groups of recipes to inspire people to cook more',
  },
  labels: {
    addCollection: 'New Collection',
    preview: 'Preview Feeds',
    loading: 'Loading available home feeds',
  },
} as const;
