export const recipeStepsFormStrings = {
  labels: {
    addTitle: 'Add New Step',
    ingredientsField: 'Ingredients',
    addButton: 'Add',
    updateButton: 'Update',
    form: 'Add New Step',
    removeIngredientButton: (ingredientName: string) =>
      `Remove ${ingredientName}`,
    addedIngredientsHelper: 'Click on each ingredient to edit amounts',
    editIngredientAmountCloseButton: 'Close',
  },
  placeholders: {
    ingredientsField: 'Add one or more ingredients',
  },

  helperText: {
    editIngredientAmount: 'Press enter to confirm setting',
  },
} as const;

export const recipeStepsFormConstants = {
  fieldIds: {
    text: 'step-text',
    ingredients: 'step-selected-ingredients',
  },
} as const;
