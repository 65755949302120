import { useCallback } from 'react';
import { useBeforeUnload } from 'react-router-dom';

import { useNavigationPrompt } from 'app/routes/hooks/useNavigationPrompt';

export interface UseLeavePageWithUnsavedChangesParams {
  isEnabled: boolean;
  /**
   * Array of paths that should not block navigation, regardless the when parameter value
   */
  excludedPaths?: string[];
  onBeforeUnload: () => void;
}

export function useLeavePageWithUnsavedChanges({
  isEnabled,
  excludedPaths,
  onBeforeUnload,
}: UseLeavePageWithUnsavedChangesParams) {
  useBeforeUnload(
    useCallback(
      (e) => {
        if (isEnabled) {
          e.preventDefault();
          onBeforeUnload();
        }
      },
      [isEnabled, onBeforeUnload]
    )
  );

  return useNavigationPrompt({
    isEnabled,
    excludedPaths,
  });
}
