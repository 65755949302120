import { Skeleton, SkeletonVariant } from '@dropkitchen/pantry-react';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { RecipeReviewBasicInformationContainer } from 'features/recipe/review/basicInformation/RecipeReviewBasicInformationContainer';
import { recipeReviewBasicInformationSkeletonConstants } from 'features/recipe/review/basicInformation/RecipeReviewBasicInformationSkeleton.constants';
import { heroImageConstants } from 'utils/image';

const { width } = heroImageConstants;
const { ariaLabels } = recipeReviewBasicInformationSkeletonConstants;

export const RecipeReviewBasicInformationSkeleton: FC = memo(
  function RecipeReviewBasicInformationSkeleton() {
    return (
      <RecipeReviewBasicInformationContainer
        components={{
          image: (
            <Box aria-label={ariaLabels.image}>
              <Skeleton
                variant={SkeletonVariant.Small}
                sx={{ height: heroImageConstants.height, width }}
              />
            </Box>
          ),

          descriptionField: (
            <Box aria-label={ariaLabels.descriptionField}>
              <Skeleton
                variant={SkeletonVariant.Small}
                sx={{ height: heroImageConstants.height, flexGrow: 1 }}
              />
            </Box>
          ),

          authorField: (
            <Box aria-label={ariaLabels.authorField}>
              <Skeleton
                variant={SkeletonVariant.Small}
                sx={{ height: 40, width }}
              />
            </Box>
          ),

          totalTimeField: (
            <Box aria-label={ariaLabels.totalTimeField}>
              <Skeleton
                variant={SkeletonVariant.Small}
                sx={{ height: 40, width }}
              />
            </Box>
          ),

          prepTimeField: (
            <Box aria-label={ariaLabels.prepTimeField}>
              <Skeleton
                variant={SkeletonVariant.Small}
                sx={{ height: 40, width }}
              />
            </Box>
          ),

          cookTimeField: (
            <Box aria-label={ariaLabels.cookTimeField}>
              <Skeleton
                variant={SkeletonVariant.Small}
                sx={{ height: 40, width }}
              />
            </Box>
          ),

          servesField: (
            <Box aria-label={ariaLabels.servesField}>
              <Skeleton
                variant={SkeletonVariant.Small}
                sx={{ height: 40, width }}
              />
            </Box>
          ),

          applianceTagsField: (
            <Box aria-label={ariaLabels.applianceTagsField}>
              <Skeleton
                variant={SkeletonVariant.Small}
                sx={{ height: 56, width: 350 }}
              />
            </Box>
          ),

          generalTagsField: (
            <Box aria-label={ariaLabels.generalTagsField}>
              <Skeleton variant={SkeletonVariant.Small} sx={{ height: 77 }} />
            </Box>
          ),
        }}
      />
    );
  }
);
