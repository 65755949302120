import {
  PantryColor,
  Tooltip,
  TooltipPosition,
} from '@dropkitchen/pantry-react';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { tabsListConstants } from 'components/Tab/TabsList.constants';
import {
  TabBadgeStatus,
  generateTabBadgeTestId,
} from 'components/Tab/tabBadgeUtils';

export interface TabBadgeProps {
  status: TabBadgeStatus;
  message: string;
}

const { tab, tabBadge } = tabsListConstants;

export const TabBadge: FC<TabBadgeProps> = memo(function TabBadge({
  status,
  message,
}) {
  const fillColor = (() => {
    switch (status) {
      case TabBadgeStatus.Error:
        return PantryColor.IconCritical;
      case TabBadgeStatus.Warning:
        return PantryColor.IconWarning;
      default:
        return PantryColor.IconWarning;
    }
  })();

  return (
    <Tooltip
      message={message}
      hideArrow
      placement={TooltipPosition.BottomStart}
    >
      <Box
        sx={{
          width: `${tab.iconSize}`,
          height: `${tab.iconSize}`,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          component="span"
          data-testid={generateTabBadgeTestId(status)}
          sx={{
            width: '12px',
            height: '12px',
            borderRadius: '100%',
            border: `${tabBadge.borderWidth} solid white`,
            backgroundColor: fillColor,
            display: 'block',
            mt: `-${tabBadge.borderWidth}`,
            mr: `-${tabBadge.borderWidth}`,
          }}
        />
      </Box>
    </Tooltip>
  );
});
