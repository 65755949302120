import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  CloseIcon,
  DeleteIcon,
  DragHandleIcon,
  PantryColor,
  PantryTypography,
  sxCompose,
} from '@dropkitchen/pantry-react';
import {
  Checkbox,
  Autocomplete,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import type { FC, SyntheticEvent } from 'react';
import { useCallback, memo, useEffect, useState, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { CapabilityField } from 'components/CapabilityField/CapabilityField';
import { dragAndDropConstants } from 'components/DragAndDrop/DragAndDrop.constants';
import { DragZone } from 'components/DragAndDrop/DragZone';
import { Draggable } from 'components/DragAndDrop/Draggable';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { ListRow } from 'components/ListRow/ListRow';
import {
  selectApplianceCapabilities,
  selectAppliancesWithCapabilities,
} from 'features/appliances/appliancesSlice';
import { toNumber } from 'features/recipe/ingredients/form/recipeIngredientForm.utils';
import type { AppRecipeIngredientsUsageStep } from 'features/recipe/recipeSlice';
import {
  createQuantity,
  recipeStepAdded,
  recipeStepUpdated,
  selectIngredientsByStep,
  selectRecipeIngredientsUsage,
  selectRecipeStep,
  selectRecipeLocale,
  selectRecipeStepHasIncompatibilities,
  selectRecipePrimaryUnitSystem,
} from 'features/recipe/recipeSlice';
import { RecipeStepTextField } from 'features/recipe/shared/RecipeStepTextField/RecipeStepTextField';
import type { AppRecipeFieldChange } from 'features/recipe/shared/types/appRecipeFieldChange';
import { roundIngredientAmountLeft } from 'features/recipe/shared/utils/recipeIngredientsUtils';
import {
  recipeStepsFormConstants,
  recipeStepsFormStrings,
} from 'features/recipe/steps/form/RecipeStepsForm.constants';
import { RecipeStepsFormIngredientQuantity } from 'features/recipe/steps/form/RecipeStepsFormIngredientQuantity';
import {
  initialState,
  stepReset,
  selectCapability,
  selectSettings,
  selectHasErrors,
  selectIndex,
  selectIngredients,
  selectSubmitted,
  selectText,
  stepIngredientsSet,
  stepSubmitted,
  stepTextUpdated,
  selectIsStepUnsaved,
  stepIngredientMoved,
  stepIngredientAdded,
  selectPhase,
  selectAppliancesIncompatibilities,
  selectErrors,
  stepFormPopulated,
  stepFormShown,
} from 'features/recipe/steps/form/recipeStepsFormSlice';
import { selectGeneralCapabilities } from 'features/referenceData/generalCapabilities/generalCapabilitiesSlice';
import { AppCapabilityType } from 'types/appCapability';
import type { AppRecipeStep } from 'types/recipe/appRecipeStep';
import type { AppRecipeValueInMultipleSystems } from 'types/recipe/appRecipeValueInMultipleSystems';
import {
  getSecondaryUnitSystem,
  getValueWithUnitAsString,
  isValueInMultipleSystems,
  isValueInSingleSystem,
} from 'utils/unitSystems';

const { labels, placeholders } = recipeStepsFormStrings;
const { fieldIds } = recipeStepsFormConstants;

export const getStepsAsString = (
  usageSteps: AppRecipeIngredientsUsageStep[]
): string => {
  return usageSteps.reduce((steps, { index }, i) => {
    if (i === 0) {
      return `${index + 1}`;
    }
    if (i === usageSteps.length - 1) {
      return `${steps} and ${index + 1}`;
    }
    return `${steps}, ${index + 1}`;
  }, '');
};

export const RecipeStepsForm: FC = memo(function RecipeStepsForm() {
  const dispatch = useAppDispatch();
  const selectedStepIndex = useAppSelector(selectIndex);
  const ingredients = useAppSelector(
    useMemo(
      () => selectIngredientsByStep(selectedStepIndex),
      [selectedStepIndex]
    )
  );
  const ingredientsUsage = useAppSelector(selectRecipeIngredientsUsage);
  const locale = useAppSelector(selectRecipeLocale);
  const generalCapabilities = useAppSelector(selectGeneralCapabilities(locale));
  const applianceCapabilities = useAppSelector(
    selectApplianceCapabilities(locale)
  );
  const text = useAppSelector(selectText);
  const selectedIngredients = useAppSelector(selectIngredients);
  const capability = useAppSelector(selectCapability);
  const selectedSettings = useAppSelector(selectSettings);
  const selectedPhase = useAppSelector(selectPhase);
  const isSubmitted = useAppSelector(selectSubmitted);
  const hasErrors = useAppSelector(selectHasErrors);
  const errors = useAppSelector(selectErrors);
  const selectedStep = useAppSelector(selectRecipeStep(selectedStepIndex));
  const hasChanges = useAppSelector(selectIsStepUnsaved);
  const appliancesByCapabilities = useAppSelector(
    selectAppliancesWithCapabilities(locale)
  );
  const incompatibilities = useAppSelector(selectAppliancesIncompatibilities);
  const hasRecipeStepIncompatibilities = useAppSelector(
    selectRecipeStepHasIncompatibilities(selectedStepIndex)
  );
  const primaryUnitSystem = useAppSelector(selectRecipePrimaryUnitSystem);

  const [selectedIngredientIdx, setSelectedIngredientIdx] = useState<
    number | null
  >(null);

  const handleTextChange = useCallback(
    (change: AppRecipeFieldChange) => {
      dispatch(stepTextUpdated(change.value));
    },
    [dispatch]
  );

  useEffect(() => {
    setSelectedIngredientIdx(null);

    // Only load from recipe slice store if there is selected step and there is no changes
    if (selectedStepIndex !== null && !hasChanges) {
      if (!selectedStep) {
        dispatch(stepReset());
        return;
      }

      const capabilities =
        selectedStep.capability?.type === AppCapabilityType.AppliancePreset
          ? applianceCapabilities
          : generalCapabilities;

      const selectedCapability =
        capabilities?.find(({ id }) => id === selectedStep.capability?.id) ||
        null;

      dispatch(
        stepFormPopulated({
          text: selectedStep.text,
          ingredients: selectedStep.ingredients,
          capabilityType:
            selectedStep.capability?.type ?? initialState.capabilityType,
          capability: selectedCapability ?? initialState.capability,
          phase: selectedStep.capability?.phase ?? initialState.phase,
          settings: selectedStep.capability?.settings ?? [],
        })
      );
    }
  }, [
    dispatch,
    applianceCapabilities,
    generalCapabilities,
    hasChanges,
    handleTextChange,
    selectedStep,
    selectedStepIndex,
    selectedPhase,
    appliancesByCapabilities,
  ]);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    dispatch(stepSubmitted(true));

    if (hasErrors) {
      return;
    }

    const isNewStep = selectedStepIndex === null || !selectedStep;
    const step: AppRecipeStep = {
      id: isNewStep ? uniqueId() : selectedStep.id,
      text,
      ingredients: selectedIngredients,
      capability: capability
        ? {
            id: capability.id,
            name: capability.name,
            type: capability.type,
            settings: Object.values(selectedSettings),
            ...(selectedPhase && { phase: selectedPhase }),
          }
        : undefined,
      hasIncompatibilities: !isEmpty(incompatibilities),
      sourceText: selectedStep?.sourceText,
    };

    if (isNewStep) {
      dispatch(recipeStepAdded({ step, incompatibilities }));
    } else {
      dispatch(
        recipeStepUpdated({
          index: selectedStepIndex,
          step,
          incompatibilities,
          areIncompatibilitiesFixed:
            hasRecipeStepIncompatibilities && isEmpty(incompatibilities),
        })
      );
    }
  };

  const handleRemoveIngredient = (ingredientIdx: number) => {
    dispatch(
      stepIngredientsSet(
        selectedIngredients.filter(
          (ingredient) => ingredient.ingredientIdx !== ingredientIdx
        )
      )
    );
  };

  const handleIngredientAmountEdit = (
    amount: number | null,
    stepIngredientIndex: number
  ): void => {
    const {
      quantity,
      ingredient: { quantity: ingredientQuantity },
    } = selectedIngredients[stepIngredientIndex];

    if (isValueInSingleSystem(quantity)) {
      dispatch(
        stepIngredientsSet(
          produce(selectedIngredients, (draft) => {
            draft[stepIngredientIndex].quantity = createQuantity({
              amount,
              unit: quantity.unit,
            });
          })
        )
      );
      return;
    }

    const secondaryUnitSystem = getSecondaryUnitSystem(primaryUnitSystem);
    if (isValueInMultipleSystems(ingredientQuantity)) {
      const primaryTotalAmount = ingredientQuantity[primaryUnitSystem].amount;
      const secondaryTotalAmount =
        ingredientQuantity[secondaryUnitSystem].amount;

      if (
        primaryTotalAmount === null ||
        secondaryTotalAmount === null ||
        amount === null
      ) {
        throw new Error(
          `Primary (${primaryTotalAmount}), secondary (${secondaryTotalAmount}) total amount or amount (${amount}) is null`
        );
      }

      const newPercentage = amount / primaryTotalAmount;
      const secondaryAmount = roundIngredientAmountLeft(
        secondaryTotalAmount * newPercentage
      );
      const editedQuantity = {
        [primaryUnitSystem]: createQuantity({
          unit: quantity[primaryUnitSystem].unit,
          amount,
        }),
        [secondaryUnitSystem]: createQuantity({
          unit: quantity[secondaryUnitSystem].unit,
          amount: secondaryAmount,
        }),
      } as AppRecipeValueInMultipleSystems;

      dispatch(
        stepIngredientsSet(
          produce(selectedIngredients, (draft) => {
            draft[stepIngredientIndex].quantity = editedQuantity;
          })
        )
      );
    }
  };

  const handleClose = () => {
    dispatch(stepFormShown(false));
  };

  return (
    <Box
      component="form"
      aria-label={labels.form}
      onSubmit={handleSubmit}
      autoComplete="off"
      noValidate
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Typography
          variant={PantryTypography.Body1SemiBold}
          color={PantryColor.TextDefault}
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {!!selectedStep && selectedStepIndex !== null
            ? `Step ${selectedStepIndex + 1} - ${selectedStep.text}`
            : labels.addTitle}
        </Typography>
        <Button
          hideLabel
          buttonStyle={ButtonStyle.Subtle}
          size={ButtonSize.Medium}
          leadingIcon={CloseIcon}
          onClick={handleClose}
          label="Close"
        />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <RecipeStepTextField
            id={fieldIds.text}
            value={text}
            showErrors={isSubmitted && !!errors.text}
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id={fieldIds.ingredients}
            value={selectedIngredients}
            isOptionEqualToValue={(option, value) =>
              option.ingredientIdx === value.ingredientIdx
            }
            onChange={(_, options, reason) => {
              if (reason === 'selectOption') {
                dispatch(stepIngredientAdded(options));
                return;
              }
              if (reason === 'removeOption' || reason === 'clear') {
                dispatch(stepIngredientsSet(options));
              }
            }}
            options={ingredients}
            getOptionLabel={({ ingredient }) => ingredient.name}
            disableCloseOnSelect
            renderTags={() => null}
            groupBy={({ isUsed }) => (isUsed ? 'Already used' : '')}
            getOptionDisabled={({ isUsed }) => !!isUsed}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={labels.ingredientsField}
                placeholder={placeholders.ingredientsField}
              />
            )}
            renderOption={(
              props,
              { ingredient, ingredientIdx, isUsed },
              { selected }
            ) => {
              const usage = ingredientsUsage[ingredientIdx];
              if (usage && isUsed) {
                return (
                  <Box
                    component="li"
                    {...props}
                    key={ingredientIdx}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          typography: PantryTypography.Body2SemiBold,
                          color: PantryColor.TextMuted,
                        }}
                      >
                        {ingredient.name}
                      </Box>
                      <Box
                        component="span"
                        sx={{
                          typography: PantryTypography.Nav,
                          color: PantryColor.TextMuted,
                        }}
                      >
                        used in step {getStepsAsString(usage.used.steps)}
                      </Box>
                    </Box>
                    <Box
                      component="span"
                      sx={{
                        typography: PantryTypography.Caption,
                        color: PantryColor.TextMuted,
                      }}
                    >
                      {isValueInSingleSystem(usage.used.quantity)
                        ? getValueWithUnitAsString(usage.used.quantity)
                        : getValueWithUnitAsString(
                            usage.used.quantity[primaryUnitSystem]
                          )}
                    </Box>
                  </Box>
                );
              }
              return (
                <Box
                  component="li"
                  {...props}
                  key={ingredientIdx}
                  sx={{
                    typography: PantryTypography.Body2,
                    color: PantryColor.TextDefault,
                    pl: 0,
                  }}
                >
                  <Checkbox checked={selected} />
                  {ingredient.name}
                </Box>
              );
            }}
          />
        </Grid>
        {!!selectedIngredients.length && (
          <Grid item xs={12}>
            <DragZone
              dragZoneId="ingredients-list"
              onDrop={({ from, to }) =>
                dispatch(stepIngredientMoved({ from, to }))
              }
              componentProps={{ placeholder: { sx: { pb: 2 } } }}
            >
              {selectedIngredients.map(
                (
                  { ingredient, ingredientIdx, quantity },
                  stepIngredientIndex
                ) => {
                  const quantityAsString = isValueInSingleSystem(quantity)
                    ? getValueWithUnitAsString(quantity)
                    : getValueWithUnitAsString(quantity[primaryUnitSystem]);
                  const canBeEdited =
                    (isValueInSingleSystem(ingredient.quantity) &&
                      ingredient.quantity.amount !== null) ||
                    (isValueInMultipleSystems(ingredient.quantity) &&
                      ingredient.quantity[primaryUnitSystem].amount !== null);
                  return ingredientIdx !== selectedIngredientIdx ? (
                    <Draggable
                      key={ingredientIdx}
                      draggableId={`step-ingredient-${ingredientIdx}`}
                      index={stepIngredientIndex}
                      onClick={() => {
                        if (canBeEdited) {
                          setSelectedIngredientIdx(ingredientIdx);
                        }
                      }}
                      renderContent={({ dragging, ...props }) => (
                        <ListRow
                          title={`${ingredient.name} - ${quantityAsString}`}
                          icon={
                            <DragHandleIcon
                              size={16}
                              color={PantryColor.IconDefault}
                            />
                          }
                          secondaryAction={
                            <Button
                              buttonStyle={ButtonStyle.Subtle}
                              hideLabel
                              label={labels.removeIngredientButton(
                                ingredient.name
                              )}
                              leadingIcon={DeleteIngredientIcon}
                              onClick={(event) => {
                                // Prevent this click event from reaching outer click handlers
                                event.stopPropagation();
                                handleRemoveIngredient(ingredientIdx);
                              }}
                              size={ButtonSize.Small}
                              sx={{ mr: 2 }}
                            />
                          }
                          sx={sxCompose(
                            { mb: 2 },
                            dragAndDropConstants.styles.draggable,
                            dragging && dragAndDropConstants.styles.dragging
                          )}
                          {...props}
                        />
                      )}
                    />
                  ) : (
                    <RecipeStepsFormIngredientQuantity
                      key={ingredientIdx}
                      ingredient={ingredient}
                      quantity={
                        isValueInSingleSystem(quantity)
                          ? quantity
                          : quantity[primaryUnitSystem]
                      }
                      ingredientIdx={ingredientIdx}
                      selectedStepIndex={selectedStepIndex}
                      onAccept={(amount) =>
                        handleIngredientAmountEdit(
                          toNumber(amount),
                          stepIngredientIndex
                        )
                      }
                      onClose={() => setSelectedIngredientIdx(null)}
                    />
                  );
                }
              )}
            </DragZone>
            <HelperText
              message={labels.addedIngredientsHelper}
              severity={HelperTextSeverity.Neutral}
              sx={{ mb: 2 }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CapabilityField />
        </Grid>
        <Grid item xs={12}>
          <Button
            type={ButtonType.Submit}
            label={
              selectedStepIndex !== null
                ? labels.updateButton
                : labels.addButton
            }
            size={ButtonSize.Large}
            buttonStyle={ButtonStyle.Emphasis}
            disabled={!hasChanges}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
});

const DeleteIngredientIcon: FC = memo(function DeleteIngredientIcon() {
  return <DeleteIcon size={16} color={PantryColor.IconDefault} />;
});
