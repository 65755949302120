import {
  Button,
  ButtonSize,
  ButtonStyle,
  PantryColor,
  PantryTypography,
} from '@dropkitchen/pantry-react';
import { Box, Grid, Typography } from '@mui/material';
import capitalize from 'lodash/capitalize';
import type { FC } from 'react';
import { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiTag } from 'api/types/common/apiTag';
import { ApiCcCuratedCollectionState } from 'api/types/curatedCollection/apiCcCuratedCollectionState';
import { generateHomeFeedRoute } from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { stylesCenterContent } from 'app/theme';
import { ApplianceTagsField } from 'components/ApplianceTagsField/ApplianceTagsField';
import { EmptyContent } from 'components/EmptyContent/EmptyContent';
import { localeFieldStrings } from 'components/LocaleField/LocaleField.constants';
import { HomeFeed } from 'features/homeFeed/HomeFeed/HomeFeed';
import {
  selectHomeFeedIsFetching,
  selectHomeFeedItems,
} from 'features/homeFeed/HomeFeed/homeFeedSlice';
import {
  homeFeedPreviewConstants,
  homeFeedPreviewStrings,
} from 'features/homeFeed/HomeFeedPreview/homeFeedPreview.constants';
import { ReactComponent as EmptyHomeFeedIcon } from 'features/homeFeed/no-home-feeds.svg';
import { DetailLayout } from 'features/layout/DetailLayout';
import { tagsFetchRequested } from 'features/referenceData/tags/tagsSlice';

const { fieldIds, collectionsLimit } = homeFeedPreviewConstants;
const { title, labels, placeholders, emptyHomeFeed } = homeFeedPreviewStrings;

export const HomeFeedPreview = memo(function HomeFeedPreview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { locale } = useParams<{ locale: ApiLocale }>();

  const isFetching = useAppSelector(selectHomeFeedIsFetching);
  const itemsCount = useAppSelector(selectHomeFeedItems).length;
  const collectionTotal = isFetching ? 0 : itemsCount;

  const [applianceTags, setApplianceTags] = useState<ApiTag[]>([]);

  useEffect(() => {
    if (!locale) {
      return;
    }
    dispatch(tagsFetchRequested({ locale }));
  }, [dispatch, locale]);

  if (!locale) {
    return null;
  }

  const details = `${localeFieldStrings.getOptionText(locale, { alternative: true })}, ${capitalize(ApiCcCuratedCollectionState.Published)}`;

  return (
    <>
      <Grid container gap={6} sx={{ ...stylesCenterContent, pt: 10 }}>
        <Grid item container xs={12}>
          <Grid item xs={9}>
            <Typography
              variant={PantryTypography.H6}
              color={PantryColor.TextDefault}
            >
              {title}
            </Typography>
            <Typography
              variant={PantryTypography.Body2}
              sx={{ color: PantryColor.TextSubtle, mt: 2 }}
            >
              {details}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              label={labels.closeButton}
              buttonStyle={ButtonStyle.Emphasis}
              size={ButtonSize.Large}
              onClick={() => navigate(generateHomeFeedRoute({ locale }))}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={4}>
            <ApplianceTagsField
              id={fieldIds.applianceTags}
              label={labels.applianceTagsField}
              placeholder={placeholders.applianceTagsField}
              value={applianceTags}
              locale={locale}
              onChange={(tags) => setApplianceTags(tags)}
              onRetry={() => dispatch(tagsFetchRequested({ locale }))}
              showErrors
            />
          </Grid>
        </Grid>
      </Grid>
      <DetailLayout sx={{ mt: 5, px: 8, py: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography variant={PantryTypography.Body2}>
            {labels.getCollectionTotal(collectionTotal)}
          </Typography>
        </Box>
        <HomeFeed
          locale={locale}
          applianceTags={applianceTags}
          preview
          // eslint-disable-next-line @typescript-eslint/naming-convention
          components={{ EmptyHomeFeed }}
        />
        {collectionTotal === collectionsLimit && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
            <Typography
              variant={PantryTypography.Body2}
              color={PantryColor.TextMuted}
            >
              {labels.completeHomeFeed}
            </Typography>
          </Box>
        )}
      </DetailLayout>
    </>
  );
});

const EmptyHomeFeed: FC = memo(function EmptyHomeFeed() {
  return (
    <EmptyContent
      components={{ image: <EmptyHomeFeedIcon /> }}
      text={emptyHomeFeed.text}
      title={emptyHomeFeed.title}
      sx={{ py: 6 }}
    />
  );
});
