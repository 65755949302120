import type { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import type { CallEffect } from 'redux-saga/effects';
import {
  all,
  takeLatest,
  delay,
  takeEvery,
  call,
  put,
  select,
} from 'redux-saga/effects';

import type { ApiRequestSagaReturnType } from 'api/createApiRequestSaga';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
import {
  apiDeleteRecipe,
  apiForkRecipe,
  apiGetRecipes,
  apiPublishRecipe,
  apiUnpublishRecipe,
} from 'api/recipes';
import type { ApiResponse } from 'api/types';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { RecipeType } from 'app/routes/constants';
import { generateRecipeRoute, navigateSaga } from 'app/routes/routesUtils';
import {
  selectRecipesSearchTerm,
  selectRecipesAppliedFilters,
} from 'components/RecipesSearch/recipesSearchSlice';
import { recipeSearchUtils } from 'components/RecipesSearch/recipesSearchUtils';
import { appConfig } from 'config/config';
import { selectAuthUserLocale } from 'features/auth/authSlice';
import { runConfigSelector } from 'features/configs/configsSagas';
import {
  selectConfigsIsFeatureEnabled,
  selectConfigsLocales,
} from 'features/configs/configsSlice';
import { errorOccurred } from 'features/error/errorSlice';
import { errorSliceConstants } from 'features/error/errorSlice.constants';
import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import type { selectRecipeTranslations } from 'features/recipes/recipesSlice';
import {
  recipesBatchUpdateRequested,
  recipesDeleteRequested,
  recipesFetchFailed,
  recipesFetching,
  recipesFetchRequested,
  recipesFetchSucceed,
  recipesPublishRequested,
  recipesBatchUpdateFinished,
  recipesUnpublishRequested,
  selectRecipes,
  selectRecipesPage,
  selectRecipesRowCount,
  selectRecipesRowsPerPage,
  recipeTranslationsFetchSucceed,
  recipeTranslationsFetchFailed,
  recipeTranslationsFetchRequested,
  recipeTranslationsFetching,
  selectRecipeType,
  recipesTranslateRequested,
  recipesTranslateFinished,
  selectRecipeTranslationByLocale,
} from 'features/recipes/recipesSlice';
import { getApplianceTags } from 'features/referenceData/tags/tagsSagas';
import type { TagsById } from 'features/referenceData/tags/tagsSlice';
import { AppFeature } from 'types/appFeature';
import type { AppRecipe } from 'types/recipe/appRecipe';
import { fromApiRcpRecipe } from 'types/recipe/appRecipe';
import { fromApiSchRecipes } from 'types/search/appRecipes';

export const apiFetchRecipesSaga = createApiRequestSaga(apiGetRecipes);
export const apiPublishRecipesSaga = createApiRequestSaga(apiPublishRecipe);
export const apiUnpublishRecipesSaga = createApiRequestSaga(apiUnpublishRecipe);
export const apiDeleteRecipeSaga = createApiRequestSaga(apiDeleteRecipe);
export const apiForkRecipesSaga = createApiRequestSaga(apiForkRecipe);

export const debounceFetchDelay = appConfig.isUnitTestEnv() ? 0 : 300;
const fetchAfterDeleteDelay = appConfig.isUnitTestEnv() ? 0 : 5000;

export function* fetchRecipes() {
  /** Debounce user input */
  yield delay(debounceFetchDelay);

  yield put(recipesFetching());

  const page = (yield select(selectRecipesPage)) as ReturnType<
    typeof selectRecipesPage
  >;
  const size = (yield select(selectRecipesRowsPerPage)) as ReturnType<
    typeof selectRecipesRowsPerPage
  >;

  const searchTerm = (yield select(selectRecipesSearchTerm)) as ReturnType<
    typeof selectRecipesSearchTerm
  >;

  const recipeType = (yield select(selectRecipeType)) as ReturnType<
    typeof selectRecipeType
  >;

  const filters = (yield select(selectRecipesAppliedFilters)) as ReturnType<
    typeof selectRecipesAppliedFilters
  >;

  const supportedLocales = (yield call(
    runConfigSelector,
    selectConfigsLocales
  )) as ApiLocale[];
  const filteredLocales = recipeSearchUtils.getLocales(filters);

  const fetchResponse = (yield call(apiFetchRecipesSaga, {
    from: page * size,
    searchTerm,
    size,
    forkedFromId: recipeType === RecipeType.Core ? null : '*',
    tags: recipeSearchUtils.getTags(filters),
    state: recipeSearchUtils.getState(filters),
    locales: filteredLocales?.length ? filteredLocales : supportedLocales,
    author: recipeSearchUtils.getAuthor(filters),
  })) as ApiRequestSagaReturnType<typeof apiFetchRecipesSaga>;

  if (!fetchResponse.ok) {
    yield put(recipesFetchFailed(fetchResponse.details.message));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  const locale = (yield select(selectAuthUserLocale)) as ReturnType<
    typeof selectAuthUserLocale
  >;
  // The only reason locale is not present is if the user has logged out while the saga was running.
  // In this case, it's safe not to fetch anything else.
  if (!locale) {
    return;
  }
  const applianceTags = (yield call(getApplianceTags, locale)) as TagsById;

  const { recipes, total } = fromApiSchRecipes(
    fetchResponse.data,
    applianceTags
  );
  yield put(recipesFetchSucceed({ recipes, total }));

  const isTranslationManagementEnabled = (yield select(
    selectConfigsIsFeatureEnabled(AppFeature.TranslationManagement)
  )) as boolean;
  const shouldFetchTranslations =
    isTranslationManagementEnabled && recipeType === RecipeType.Core;
  if (shouldFetchTranslations) {
    yield all(
      recipes.map(({ id }) =>
        call(
          fetchRecipeTranslations,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          recipeTranslationsFetchRequested({ recipeId: id! })
        )
      )
    );
  }
}

function* recipesFetchWatcher() {
  yield takeLatest(recipesFetchRequested, fetchRecipes);
}

export function* fetchRecipeTranslations({
  payload,
}: PayloadAction<{
  recipeId: ApiRcpRecipeId;
  locales?: ApiLocale[];
}>) {
  const { recipeId } = payload;
  yield put(recipeTranslationsFetching({ recipeId }));

  const locales =
    payload.locales ??
    ((yield call(runConfigSelector, selectConfigsLocales)) as ApiLocale[]);

  const fetchResponse = (yield call(apiFetchRecipesSaga, {
    from: 0,
    searchTerm: '',
    size: 1000, // Use a large number in order to get all of the translations for a recipe
    forkedFromId: recipeId,
    locales,
  })) as ApiRequestSagaReturnType<typeof apiFetchRecipesSaga>;

  if (!fetchResponse.ok) {
    yield put(recipeTranslationsFetchFailed({ recipeId }));
    return;
  }

  yield put(
    recipeTranslationsFetchSucceed({
      recipeId,
      translations: fetchResponse.data.items.map(
        (item) => fromApiRcpRecipe(item, {}) // We don't care about the tags of translated recipes at this point
      ),
    })
  );
}
function* recipeTranslationsFetchWatcher() {
  yield takeEvery(recipeTranslationsFetchRequested, fetchRecipeTranslations);
}

export function* publishRecipes({
  payload: recipeIds,
}: PayloadAction<string[]>) {
  yield batchUpdate<void>({
    recipeIds,
    requestApi: () => call(apiPublishRecipesSaga, { recipeId: recipeIds[0] }), // Considering only the first recipe now but preparing the ground for batch actions
    updateRecipes: (recipes: AppRecipe[]) =>
      produce(recipes, (draft) => {
        const index = recipes.findIndex(({ id }) => id === recipeIds[0]);
        draft[index].state = ApiRcpRecipeState.Published;
      }),
  });
}

function* recipesPublishWatcher() {
  yield takeEvery(recipesPublishRequested, publishRecipes);
}

export function* unpublishRecipes({
  payload: recipeIds,
}: PayloadAction<string[]>) {
  yield batchUpdate<void>({
    recipeIds,
    requestApi: () => call(apiUnpublishRecipesSaga, { recipeId: recipeIds[0] }), // Considering only the first recipe now but preparing the ground for batch actions
    updateRecipes: (recipes: AppRecipe[]) =>
      produce(recipes, (draft) => {
        const index = recipes.findIndex(({ id }) => id === recipeIds[0]);
        draft[index].state = ApiRcpRecipeState.Draft;
      }),
  });
}

function* recipesUnpublishWatcher() {
  yield takeEvery(recipesUnpublishRequested, unpublishRecipes);
}

function* batchUpdate<T>({
  recipeIds,
  requestApi,
  updateRecipes,
}: {
  recipeIds: ApiRcpRecipeId[];
  requestApi: () => CallEffect;
  updateRecipes: (recipes: AppRecipe[]) => AppRecipe[];
}) {
  yield put(recipesBatchUpdateRequested(recipeIds));

  const response = (yield requestApi()) as ApiResponse<T>;

  if (!response.ok) {
    yield put(recipesBatchUpdateFinished(recipeIds));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  const recipes = (yield select(selectRecipes)) as AppRecipe[];
  const rowCount = (yield select(selectRecipesRowCount)) as number;
  yield put(
    recipesFetchSucceed({
      recipes: updateRecipes(recipes),
      total: rowCount,
    })
  );
  yield put(recipesBatchUpdateFinished(recipeIds));
}

export interface DeleteRecipePayload {
  recipeId: ApiRcpRecipeId;
  state: ApiRcpRecipeState;
}

export function* deleteRecipes({
  payload: recipes,
}: PayloadAction<DeleteRecipePayload[]>) {
  yield put(recipesFetching());

  const { recipeId, state } = recipes[0];

  if (state === ApiRcpRecipeState.Published) {
    const unpublishResponse = (yield call(apiUnpublishRecipesSaga, {
      recipeId,
    })) as ApiRequestSagaReturnType<typeof apiUnpublishRecipesSaga>;

    if (!unpublishResponse.ok) {
      yield put(recipesFetchFailed(unpublishResponse.details.message));
      yield put(errorOccurred(errorSliceConstants.genericError));
      return;
    }
  }

  const deleteResponse = (yield call(
    apiDeleteRecipeSaga,
    recipeId
  )) as ApiRequestSagaReturnType<typeof apiDeleteRecipeSaga>;

  if (!deleteResponse.ok) {
    yield put(recipesFetchFailed(deleteResponse.details.message));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }
  // There is a gap between the deletion of the recipe from the API and the removal from the search endpoint.
  // Adding a delay to ensure the recipe is not available anymore
  yield delay(fetchAfterDeleteDelay);
  yield put(recipesFetchRequested());
}

function* recipesDeleteWatcher() {
  yield takeEvery(recipesDeleteRequested, deleteRecipes);
}

export interface ForkRecipesPayload {
  recipeId: string;
  locale: ApiLocale;
}

export function* forkRecipe({
  payload: { recipeId, locale },
}: PayloadAction<ForkRecipesPayload>) {
  const response = (yield call(apiForkRecipesSaga, {
    recipeId,
    locale,
  })) as ApiRequestSagaReturnType<typeof apiForkRecipesSaga>;

  if (!response.ok) {
    yield put(recipesTranslateFinished(recipeId));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  // As the platform is eventually consistent, there is a gap between forking the recipe and it to be available in the search service
  // We wait until the fork is available to redirect to the translation page
  yield call(waitForTranslationToBeAvailable, { recipeId, locale });
  yield put(recipesTranslateFinished(recipeId));

  yield call(navigateSaga, {
    to: generateRecipeRoute({
      id: response.data.id,
      tab: RecipeTabName.Information,
    }),
  });
}

export function* waitForTranslationToBeAvailable({
  recipeId,
  locale,
}: {
  recipeId: ApiRcpRecipeId;
  locale: ApiLocale;
}) {
  const delayToRetry = 2000;
  const maxTries = 10;
  for (let i = 0; i < maxTries; i++) {
    yield call(
      fetchRecipeTranslations,
      recipeTranslationsFetchRequested({ recipeId, locales: [locale] })
    );

    const translation = (yield select(
      selectRecipeTranslationByLocale(recipeId, locale)
    )) as ReturnType<typeof selectRecipeTranslations>;

    if (!translation) {
      yield delay(delayToRetry);
      continue;
    }
    return;
  }
}

function* recipesTranslateWatcher() {
  yield takeLatest(recipesTranslateRequested, forkRecipe);
}

export const recipesRestartableSagas = [
  recipesFetchWatcher,
  recipesPublishWatcher,
  recipesUnpublishWatcher,
  recipesDeleteWatcher,
  recipeTranslationsFetchWatcher,
  recipesTranslateWatcher,
];
