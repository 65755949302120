import { datadogRum } from '@datadog/browser-rum';
import { all, spawn, call } from 'redux-saga/effects';

import { getRestartableSagas } from 'app/store/restartableSagas';
import { getRunOnceSagas } from 'app/store/runOnceSagas';

function logError(e: unknown) {
  if (document) {
    // eslint-disable-next-line no-console
    console.error(e);
    datadogRum.addError(e);
  }
}

/**
 * We don't want unhandled errors in any saga to bubble up and crash root saga canceling all child
 * sagas, so all sagas are wrapped in `try {} catch {}` and reporting to console and Datadog.
 * It's a last resort error handling and sagas should not rely on it and handle their exceptions
 */
export function* rootSaga() {
  yield all([
    ...getRestartableSagas().map((saga) =>
      spawn(function* safeRestartSaga() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            logError(e);
          }
        }
      })
    ),
    ...getRunOnceSagas().map((saga) =>
      spawn(function* safeSaga() {
        try {
          yield call(saga);
        } catch (e) {
          logError(e);
        }
      })
    ),
  ]);
}
