import { createApiFn, fetchJson } from 'api/fetchJson';
import { generateApiPath } from 'api/generateApiPath';
import {
  apiAppliancesUrl,
  apiGetApplianceUrl,
  apiPathParams,
  configAppliancesPath,
} from 'api/paths';
import type { ApiRequestFn } from 'api/types';
import { ApiMimeType, CustomHeader, HttpMethod } from 'api/types';
import type { ApiAplAppliance } from 'api/types/appliance/apiAplAppliance';
import type { ApiAplApplianceList } from 'api/types/appliance/apiAplApplianceList';
import type { ApiAplGroupId } from 'api/types/appliance/apiAplGroupId';
import type { ApiAplLocalizedApplianceRequest } from 'api/types/appliance/apiAplLocalizedApplianceRequest';
import type { ApiLocalizedDataRequest } from 'api/types/common/apiLocalizedDataRequest';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import { getCurrentTime } from 'utils/getCurrentTime';

export interface ApplianceConfig {
  deviceTags: {
    deviceRefId: string;
    tags: {
      tagId: ApiRefId;
      tagName: string;
    }[];
    imageUrl: string;
  }[];
  deviceItems: {
    deviceRefId: ApiRefId;
    groupItemId: ApiAplGroupId;
  }[];
  deviceGroup: {
    groupImage: string;
    groupItemId: ApiAplGroupId;
  }[];
}
export const getApplianceConfig = async () =>
  fetchJson<ApplianceConfig>({
    httpMethod: HttpMethod.Get,
    url: `${configAppliancesPath}?t=${getCurrentTime()}`,
  });
export const apiGetApplianceConfig: ApiRequestFn<ApplianceConfig> =
  createApiFn(getApplianceConfig);

export const getAppliance = async ({
  locale,
  applianceId,
}: ApiAplLocalizedApplianceRequest) =>
  fetchJson<ApiAplAppliance>({
    httpMethod: HttpMethod.Get,
    url: generateApiPath(apiGetApplianceUrl, {
      [apiPathParams.applianceId]: applianceId,
    }),
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: ApiMimeType.JsonV2,
      [CustomHeader.AcceptLanguage]: locale,
    },
  });
export const apiGetAppliance: ApiRequestFn<
  ApiAplAppliance,
  ApiAplLocalizedApplianceRequest
> = createApiFn(getAppliance);

export const getAppliances = async ({ locale }: ApiLocalizedDataRequest) =>
  fetchJson<ApiAplApplianceList>({
    httpMethod: HttpMethod.Get,
    url: apiAppliancesUrl,
    headers: { [CustomHeader.AcceptLanguage]: locale },
  });
export const apiGetAppliances: ApiRequestFn<
  ApiAplApplianceList,
  ApiLocalizedDataRequest
> = createApiFn(getAppliances);
