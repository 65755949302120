import {
  Button,
  ButtonSize,
  ButtonStyle,
  DeleteIcon,
  PantryColor,
  PantryTypography,
  Spinner,
} from '@dropkitchen/pantry-react';
import { Box, Typography } from '@mui/material';
import {
  gridClasses,
  type GridRenderCellParams,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import type { FC, SyntheticEvent } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiTag } from 'api/types/common/apiTag';
import type { ApiCcCuratedCollectionId } from 'api/types/curatedCollection/apiCcCuratedCollectionId';
import { ApiCcCuratedCollectionState } from 'api/types/curatedCollection/apiCcCuratedCollectionState';
import type { ApiRefEntity } from 'api/types/referenceData/apiRefEntity';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { RecipeStatusBadge } from 'components/Badge/RecipeStatusBadge';
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import { AppliancesCell } from 'components/Table/AppliancesCell/AppliancesCell';
import type {
  TableColumn,
  TableRow,
  TableRowReorderParams,
} from 'components/Table/Table';
import { Table } from 'components/Table/Table';
import {
  HomeFeedCollectionsColumnField,
  homeFeedConstants,
  homeFeedStrings,
} from 'features/homeFeed/HomeFeed/HomeFeed.constants';
import {
  HomeFeedCollectionAppliancesSkeleton,
  HomeFeedCollectionIdSkeleton,
  HomeFeedCollectionModifiedAtSkeleton,
  HomeFeedCollectionRecipeTotalSkeleton,
  HomeFeedCollectionStatusSkeleton,
  HomeFeedCollectionTitleSkeleton,
} from 'features/homeFeed/HomeFeed/HomeFeedSkeleton';
import { updateHomeFeedRequested } from 'features/homeFeed/HomeFeed/homeFeedSagas';
import {
  homeFeedFetchRequested,
  selectHomeFeedIsFetching,
  selectHomeFeedIsSaving,
  selectHomeFeed,
  homeFeedDeleteCollectionRequested,
  selectHomeFeedRowsLoading,
} from 'features/homeFeed/HomeFeed/homeFeedSlice';
import { generateRecipeTotalMessage } from 'features/homeFeed/homeFeeds.utils';
import { formatDateForLists, fromISOToDate } from 'utils/date';

const { table: tableStrings, deleteDialog } = homeFeedStrings;
const { table } = homeFeedConstants;

export interface HomeFeedCollectionsColumn extends TableColumn {
  field: HomeFeedCollectionsColumnField;
}

export interface HomeFeedCollectionRow extends TableRow {
  title: string;
  id: string;
  state: ApiCcCuratedCollectionState;
  applianceTags: ApiRefEntity[];
  modifiedAt: string;
  order: number;
  recipeTotal: number;
}

export interface HomeFeedProps {
  locale: ApiLocale;
  applianceTags?: ApiTag[];
  preview?: boolean;
  onCollectionClick?: (params: GridRowParams<HomeFeedCollectionRow>) => void;
  components?: {
    /* eslint-disable @typescript-eslint/naming-convention */
    EmptyHomeFeed?: FC;
    /* eslint-enable @typescript-eslint/naming-convention */
  };
  hideColumns?: HomeFeedCollectionsColumnField[];
}

export const HomeFeed: FC<HomeFeedProps> = memo(function HomeFeed({
  locale,
  applianceTags,
  preview,
  onCollectionClick,
  components,
  hideColumns,
}) {
  const dispatch = useAppDispatch();

  const homeFeed = useAppSelector(selectHomeFeed);
  const isFetching = useAppSelector(selectHomeFeedIsFetching);
  const isSaving = useAppSelector(selectHomeFeedIsSaving);
  const rowsLoading = useAppSelector(selectHomeFeedRowsLoading);

  const [hoveredRowId, setHoveredRowId] =
    useState<ApiCcCuratedCollectionId | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [collectionToDelete, setCollectionToDelete] =
    useState<HomeFeedCollectionRow | null>(null);

  useEffect(() => {
    dispatch(homeFeedFetchRequested({ locale, applianceTags, preview }));
  }, [dispatch, locale, applianceTags, preview]);

  const columns: (HomeFeedCollectionsColumn | null)[] = useMemo(() => {
    return [
      {
        ...table.columns.order,
        renderCell: ({
          row,
        }: GridRenderCellParams<undefined, HomeFeedCollectionRow>) => {
          return (
            <Typography variant={PantryTypography.Body2}>
              {row.order}
            </Typography>
          );
        },
      },
      {
        ...table.columns.title,
        renderCell: ({
          row,
        }: GridRenderCellParams<undefined, HomeFeedCollectionRow>) => {
          if (rowsLoading[row.id]) {
            return <HomeFeedCollectionTitleSkeleton />;
          }
          return (
            <Typography variant={PantryTypography.Body2SemiBold}>
              {row.title}
            </Typography>
          );
        },
      },
      {
        ...table.columns.appliances,
        renderCell: ({
          row,
        }: GridRenderCellParams<undefined, HomeFeedCollectionRow>) => {
          if (rowsLoading[row.id]) {
            return <HomeFeedCollectionAppliancesSkeleton />;
          }
          return <AppliancesCell appliances={row.applianceTags} />;
        },
      },
      {
        ...table.columns.id,
        renderCell: ({
          row,
        }: GridRenderCellParams<undefined, HomeFeedCollectionRow>) => {
          if (rowsLoading[row.id]) {
            return <HomeFeedCollectionIdSkeleton />;
          }
          return (
            <Typography
              variant={PantryTypography.Body2}
              sx={{ wordBreak: 'break-all' }}
            >
              {row.id}
            </Typography>
          );
        },
      },
      {
        ...table.columns.recipeTotal,
        renderCell: ({
          row,
        }: GridRenderCellParams<undefined, HomeFeedCollectionRow>) => {
          if (rowsLoading[row.id]) {
            return <HomeFeedCollectionRecipeTotalSkeleton />;
          }
          return (
            <Typography variant={PantryTypography.Body2}>
              {generateRecipeTotalMessage(row.recipeTotal)}
            </Typography>
          );
        },
      },
      {
        ...table.columns.status,
        renderCell: ({
          row,
        }: GridRenderCellParams<undefined, HomeFeedCollectionRow>) => {
          if (rowsLoading[row.id]) {
            return <HomeFeedCollectionStatusSkeleton />;
          }
          return <RecipeStatusBadge status={row.state} />;
        },
      },
      {
        ...table.columns.modifiedAt,
        renderCell: ({
          row,
        }: GridRenderCellParams<undefined, HomeFeedCollectionRow>) => {
          if (rowsLoading[row.id]) {
            return <HomeFeedCollectionModifiedAtSkeleton />;
          }
          if (hoveredRowId === row.id && !preview) {
            return (
              <Box
                sx={{
                  display: 'flex',
                  px: 4,
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Button
                  label={tableStrings.actions.delete}
                  hideLabel
                  leadingIcon={DeleteCollectionIcon}
                  buttonStyle={ButtonStyle.Subtle}
                  size={ButtonSize.Medium}
                  onClick={(event: SyntheticEvent) => {
                    event.stopPropagation();
                    setCollectionToDelete(row);
                    setIsDeleteDialogOpen(true);
                  }}
                />
              </Box>
            );
          }
          return (
            <Typography variant={PantryTypography.Body2}>
              {formatDateForLists(fromISOToDate(row.modifiedAt))}
            </Typography>
          );
        },
      },
    ].filter((column) => !hideColumns?.includes(column.field));
  }, [hideColumns, hoveredRowId, rowsLoading, preview]);

  const rows: HomeFeedCollectionRow[] = useMemo(() => {
    // Do not show stale items when the locale changes
    return homeFeed?.locale === locale
      ? homeFeed.items.map((item, index) => ({
          ...item,
          order: index + 1,
          recipeTotal: item.recipes.length,
        }))
      : [];
  }, [homeFeed?.items, homeFeed?.locale, locale]);

  const handleRowReorder = ({ to, from }: TableRowReorderParams) => {
    if (!homeFeed) {
      return;
    }

    dispatch(
      updateHomeFeedRequested(
        produce(homeFeed, (draft) => {
          const [removed] = draft.items.splice(from, 1);
          draft.items.splice(to, 0, removed);
        })
      )
    );
  };

  const handleDeleteDialogClose = (hasConfirmed: boolean) => {
    if (hasConfirmed && collectionToDelete) {
      dispatch(homeFeedDeleteCollectionRequested(collectionToDelete.id));
      setCollectionToDelete(null);
    }
    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      <Table
        ariaLabel={tableStrings.ariaLabel}
        rowHeight={table.rowHeight}
        columns={columns.filter(
          (column): column is HomeFeedCollectionsColumn => column !== null
        )}
        rows={rows}
        rowCount={rows.length}
        loading={isFetching || isSaving}
        components={{
          loadingOverlay: LoadingOverlay,
          noRowsOverlay: components?.EmptyHomeFeed,
        }}
        componentsProps={{
          row: {
            onMouseEnter: (event: SyntheticEvent<HTMLElement>) => {
              event.preventDefault();
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              setHoveredRowId(event.currentTarget.dataset.id!);
            },
            onMouseLeave: (event: SyntheticEvent<HTMLElement>) => {
              event.preventDefault();
              setHoveredRowId(null);
            },
          },
        }}
        hidePagination
        onRowClick={onCollectionClick}
        isRowReorderEnabled={!preview && isEmpty(rowsLoading)}
        onRowReorder={handleRowReorder}
        rowDraggingLabelField="title"
        // When the delete icon is shown on row hover, the row height is increased.
        // We need to remove the padding so the height doesn't change
        sx={{
          [`&.${gridClasses['root--densityStandard']} .${gridClasses.cell}[data-field="${HomeFeedCollectionsColumnField.ModifiedAt}"]`]:
            {
              py: 0,
            },
        }}
      />
      {collectionToDelete && (
        <ConfirmationDialog
          text={{
            title: deleteDialog.title(collectionToDelete.title),
            cancelButton: deleteDialog.cancel,
            confirmButton:
              collectionToDelete.state === ApiCcCuratedCollectionState.Published
                ? deleteDialog.confirm.published
                : deleteDialog.confirm.draft,
            body:
              collectionToDelete.state === ApiCcCuratedCollectionState.Published
                ? deleteDialog.message.published
                : deleteDialog.message.draft,
          }}
          isOpen={isDeleteDialogOpen}
          onClose={handleDeleteDialogClose}
        />
      )}
    </>
  );
});

const LoadingOverlay: FC = memo(function Loading() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: table.rowHeight,
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      }}
    >
      <Spinner size={64} />
    </Box>
  );
});

const DeleteCollectionIcon: FC = memo(function DeleteRecipeIcon() {
  return <DeleteIcon size={20} color={PantryColor.IconDefault} />;
});
