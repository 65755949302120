import { createApiFn, fetchJson } from 'api/fetchJson';
import { apiTagsUrl } from 'api/paths';
import type { ApiList, ApiRequestFn } from 'api/types';
import { HttpMethod } from 'api/types';
import type { ApiTag } from 'api/types/common/apiTag';
import type { ApiRefLocalizedDataRequest } from 'api/types/referenceData/apiRefLocalizedDataRequest';
import { appConfig } from 'config/config';

export const getTags = async ({ locale, from }: ApiRefLocalizedDataRequest) =>
  fetchJson<ApiList<ApiTag>>({
    httpMethod: HttpMethod.Post,
    url: `${apiTagsUrl}?size=${appConfig.referenceDataApiPageSize()}&from=${from}`,
    body: { locale },
  });

export const apiGetTags: ApiRequestFn<
  ApiList<ApiTag>,
  ApiRefLocalizedDataRequest
> = createApiFn(getTags);
