import { Skeleton, SkeletonVariant } from '@dropkitchen/pantry-react';
import { Box } from '@mui/material';
import type { ReactNode } from 'react';
import { useCallback, memo, useState, useEffect } from 'react';
import { useInterval } from 'react-use';

import { imageConstants, imageStrings } from 'components/Image/Image.constants';
import { ImageError } from 'components/Image/ImageError';

const fetchImage = (
  originalSrc: string,
  onLoad: (src: string) => void,
  onError: () => void
) => {
  const headers = { 'x-refreshed': Date.now().toString() };

  let imageURL = originalSrc;
  if (originalSrc) {
    const urlWithParams = new URL(originalSrc);
    urlWithParams.searchParams.append('t', `${Date.now()}`);
    imageURL = urlWithParams.toString();
  }

  fetch(imageURL, { headers })
    .then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      return response.blob();
    })
    .then((blob) => onLoad(URL.createObjectURL(blob)))
    .catch(() => onError());
};

const isBlob = (src: string) => src.startsWith('blob:');

const { delayToRetryFetch, maxFetchTries } = imageConstants;
const { testIds } = imageStrings;

export enum ImageFit {
  Contain = 'contain',
  Cover = 'cover',
}

export interface ImageSize {
  width: string;
  maxWidth?: string;
  height: string;
  maxHeight?: string;
}

export interface ImageProps {
  src: string;
  alt: string;
  width: string;
  maxWidth?: string;
  height: string;
  maxHeight?: string;
  borderRadius?: number;
  boxShadow?: string;
  fit?: ImageFit;
  bypassCache?: boolean;
  error?: {
    component?: ReactNode;
    text?: string;
  };
  /** If this prop is present, the fetching of the image will be retry until maxTries is reached.
   * This is needed because the platform is eventually consistent and we may have uploaded an image
   * but it may not be available right away
   */
  retryPlaceholder?: ReactNode;
}

export const Image = memo(function Image({
  src: originalSrc,
  alt,
  width,
  maxWidth,
  height,
  maxHeight,
  borderRadius = 1,
  boxShadow = '0px 4px 15px rgba(0, 0, 0, 0.15)',
  fit = ImageFit.Contain,
  bypassCache,
  error = {},
  retryPlaceholder,
}: ImageProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [src, setSrc] = useState<string>();
  const [triesCount, setTriesCount] = useState<number>(0);
  const size: ImageSize = { width, maxWidth, height, maxHeight };
  const shouldRetryFetch =
    !!retryPlaceholder && !isLoaded && triesCount < maxFetchTries;
  const shouldSetError = !retryPlaceholder || triesCount >= maxFetchTries;

  const fetchImageCallback = useCallback(() => {
    fetchImage(
      originalSrc,
      (imageSrc) => {
        setSrc(imageSrc);
      },
      () => {
        if (shouldSetError) {
          setHasError(true);
        }
      }
    );
  }, [originalSrc, shouldSetError]);

  useEffect(() => {
    setIsLoaded(false);
    setHasError(false);

    if (isBlob(originalSrc) || !bypassCache) {
      setSrc(originalSrc);
      return;
    }

    fetchImageCallback();
  }, [bypassCache, originalSrc, fetchImageCallback]);

  useInterval(
    () => {
      fetchImageCallback();
      setTriesCount(triesCount + 1);
    },
    shouldRetryFetch ? delayToRetryFetch : null
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="img"
        src={src}
        onError={() => setHasError(true)}
        onLoad={() => setIsLoaded(true)}
        alt={alt}
        sx={{
          display: isLoaded ? 'block' : 'none',
          objectFit: fit,
          objectPosition: 'center',
          boxShadow,
          borderRadius,
          ...size,
        }}
      />
      {!isLoaded && !hasError && (
        <Box data-testid={testIds.imageSkeleton}>
          <Skeleton
            variant={SkeletonVariant.Small}
            sx={{ borderRadius, width, height }}
          />
        </Box>
      )}
      {hasError &&
        (error.component || (
          <ImageError
            text={error.text}
            size={size}
            borderRadius={borderRadius}
          />
        ))}

      {shouldRetryFetch && retryPlaceholder}
    </Box>
  );
});
