import { TextField } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { recipeBasicInformationStrings } from 'features/recipe/recipeBasicInformation/RecipeBasicInformation.constants';
import type { RecipeFieldProps } from 'features/recipe/shared/types/appRecipeFieldProps';

const { labels, placeholders } = recipeBasicInformationStrings;

export type RecipeDescriptionFieldProps = RecipeFieldProps;

export const RecipeDescriptionField: FC<RecipeDescriptionFieldProps> = memo(
  function RecipeDescriptionField({ id, value, onChange }) {
    return (
      <TextField
        id={id}
        label={labels.descriptionField}
        type="text"
        placeholder={placeholders.descriptionField}
        variant="outlined"
        multiline
        fullWidth
        rows={4}
        value={value}
        onChange={(event) => onChange({ value: event.target.value })}
      />
    );
  }
);
