import type { TextFieldProps } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import type { ApiLocale } from 'api/types/common/apiLocale';
import { useAppSelector } from 'app/store/hooks';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { localeFieldStrings } from 'components/LocaleField/LocaleField.constants';
import { selectConfigsLocales } from 'features/configs/configsSlice';

const { getOptionText } = localeFieldStrings;

interface LocaleFieldProps extends Pick<TextFieldProps, 'id' | 'autoFocus'> {
  label: string;
  placeholder: string;
  hint?: string;
  value: ApiLocale | null;
  onChange: (locale: ApiLocale | null) => void;
}

export const LocaleField: FC<LocaleFieldProps> = memo(function LocaleField({
  id,
  value,
  onChange,
  autoFocus,
  label,
  placeholder,
  hint,
}) {
  const supportedTranslationLocales = useAppSelector(selectConfigsLocales);

  return (
    <Autocomplete
      id={id}
      fullWidth
      options={supportedTranslationLocales ?? []}
      onChange={(_e, option) => {
        onChange(option);
      }}
      getOptionLabel={(locale) => getOptionText(locale)}
      value={value}
      renderInput={(props) => (
        <TextField
          type="text"
          label={label}
          placeholder={placeholder}
          variant="outlined"
          required
          autoFocus={autoFocus}
          helperText={
            hint && (
              <HelperText
                message={hint}
                severity={HelperTextSeverity.Neutral}
              />
            )
          }
          {...props}
        />
      )}
    />
  );
});
