import type { SxProps } from '@mui/material';
import { memo } from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import type { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { generateRecipeRoute } from 'app/routes/routesUtils';
import { appDisplayCodeByLocale } from 'types/appDisplayCodeByLocale';

import { StatusBadge } from './StatusBadge';

export interface LocaleStatusBadgeProps {
  locale: ApiLocale;
  status: ApiRcpRecipeState;
  recipeId: ApiRcpRecipeId;
  sx?: SxProps;
}

export const LocaleStatusBadge: FC<LocaleStatusBadgeProps> = memo(
  function LocaleStatusBadge({ locale, status, recipeId, sx }) {
    return (
      <Link
        aria-label={appDisplayCodeByLocale[locale]}
        to={generateRecipeRoute({ id: recipeId })}
        style={{ textDecorationLine: 'none' }}
      >
        <StatusBadge
          status={status}
          sx={sx}
          text={appDisplayCodeByLocale[locale]}
        />
      </Link>
    );
  }
);
