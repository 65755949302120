import {
  Skeleton,
  type ButtonProps,
  SkeletonVariant,
} from '@dropkitchen/pantry-react';
import { Box } from '@mui/material';
import type { FC, ReactElement } from 'react';
import { memo } from 'react';

import { buttonWithSkeletonConstants } from 'components/ButtonWithSkeleton/ButtonWithSkeleton.constants';

export interface ButtonWithSkeletonProps {
  button: ReactElement<ButtonProps>;
  isLoading?: boolean;
}

const { getAriaLabel } = buttonWithSkeletonConstants;

export const ButtonWithSkeleton: FC<ButtonWithSkeletonProps> = memo(
  function ButtonWithSkeleton({ button, isLoading }) {
    return isLoading ? (
      <Box aria-label={getAriaLabel(button.props.label)}>
        <Skeleton
          variant={SkeletonVariant.Full}
          sx={{ height: 52, width: 100 }}
        />
      </Box>
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{button}</>
    );
  }
);
