import type { Action } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import sortBy from 'lodash/sortBy';
import { select, put, takeLatest } from 'redux-saga/effects';

import type { ApplianceWithCapabilities } from 'features/appliances/appliancesSlice';
import { selectAppliancesWithCapabilities } from 'features/appliances/appliancesSlice';
import { shouldCheckIncompatibilities } from 'features/recipe/recipeSagas';
import {
  selectRecipeApplianceTags,
  selectRecipeLocale,
  selectRecipeStep,
} from 'features/recipe/recipeSlice';
import { checkApplianceIncompatibilities } from 'features/recipe/steps/form/recipeStepsApplianceIncompatibilities.utils';
import {
  stepHasChanged,
  stepTextUpdated,
  stepIngredientsSet,
  stepCapabilityTypeUpdated,
  stepCapabilityUpdated,
  stepSettingUpdated,
  stepSettingsUpdated,
  stepSettingsDependenciesUpdated,
  selectIndex,
  initialState,
  selectText,
  selectIngredients,
  selectCapability,
  selectSettings,
  stepIngredientMoved,
  stepIngredientAdded,
  stepPhaseUpdated,
  selectPhase,
  stepIncompatibilitiesUpdated,
  stepFormPopulated,
} from 'features/recipe/steps/form/recipeStepsFormSlice';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import type { AppRecipeStep } from 'types/recipe/appRecipeStep';
import type { AppRecipeStepCapabilitySetting } from 'types/recipe/appRecipeStepCapabilitySetting';
import type { AppRecipeStepIngredient } from 'types/recipe/appRecipeStepIngredient';

export function* recipeStepHasChanged() {
  const stepIndex = (yield select(selectIndex)) as ReturnType<
    typeof selectIndex
  >;
  const text = (yield select(selectText)) as ReturnType<typeof selectText>;
  const ingredients = (yield select(selectIngredients)) as ReturnType<
    typeof selectIngredients
  >;
  const capability = (yield select(selectCapability)) as ReturnType<
    typeof selectCapability
  >;
  const settings = Object.values(
    (yield select(selectSettings)) as ReturnType<typeof selectSettings>
  );
  const phase = (yield select(selectPhase)) as ReturnType<typeof selectPhase>;

  if (stepIndex === null) {
    const hasChanged =
      initialState.text !== text ||
      initialState.capability?.id !== capability?.id ||
      !areSameIngredients(initialState.ingredients, ingredients) ||
      !areSameSettings(Object.values(initialState.settings), settings) ||
      initialState.phase?.id !== phase?.id;
    yield put(stepHasChanged({ hasChanged }));
    return;
  }

  const step = (yield select(selectRecipeStep(stepIndex))) as AppRecipeStep;

  const hasChanged =
    step.text !== text ||
    step.capability?.id !== capability?.id ||
    !areSameIngredients(step.ingredients, ingredients) ||
    !areSameSettings(step.capability?.settings, settings) ||
    step.capability?.phase?.id !== phase?.id;

  yield put(stepHasChanged({ hasChanged }));
}

export const shouldTriggerStepChange = (action: Action<string>): boolean =>
  [
    stepTextUpdated.type,
    stepIngredientsSet.type,
    stepIngredientAdded.type,
    stepIngredientMoved.type,
    stepCapabilityTypeUpdated.type,
    stepCapabilityUpdated.type,
    stepSettingUpdated.type,
    stepSettingsUpdated.type,
    stepSettingsDependenciesUpdated.type,
    stepPhaseUpdated.type,
  ].includes(action.type);

function* recipeStepHasChangedWatcher() {
  yield takeLatest(shouldTriggerStepChange, recipeStepHasChanged);
}

const areSameIngredients = (
  previous: AppRecipeStepIngredient[] = [],
  current: AppRecipeStepIngredient[] = []
): boolean => {
  if (previous.length !== current.length) {
    return false;
  }
  return previous.every((ingredient, index) =>
    isEqual(
      omit(ingredient, 'quantity.text'),
      omit(current[index], 'quantity.text')
    )
  );
};

const areSameSettings = (
  previous: AppRecipeStepCapabilitySetting[] = [],
  current: AppRecipeStepCapabilitySetting[] = []
): boolean => {
  if (previous.length !== current.length) {
    return false;
  }
  const idKey: Extract<keyof AppRecipeStepCapabilitySetting, 'id'> = 'id';
  const sortedPrevious = sortBy(previous, idKey);
  const sortedCurrent = sortBy(current, idKey);
  return sortedPrevious.every((previousSetting, index) => {
    const currentSetting = sortedCurrent[index];
    if (
      previousSetting.value.type === AppCapabilitySettingType.Time &&
      currentSetting.value.type === AppCapabilitySettingType.Time
    ) {
      return isEqual(
        omitBy(previousSetting.value.value, isUndefined),
        omitBy(currentSetting.value.value, isUndefined)
      );
    }
    return isEqual(previousSetting, currentSetting);
  });
};

export function* checkStepIncompatibilities() {
  const recipeApplianceTags = (yield select(
    selectRecipeApplianceTags
  )) as ReturnType<typeof selectRecipeApplianceTags>;

  if (!recipeApplianceTags?.length) {
    yield put(stepIncompatibilitiesUpdated({}));
    return;
  }

  const locale = (yield select(selectRecipeLocale)) as ReturnType<
    typeof selectRecipeLocale
  >;

  const appliances = (yield select(
    selectAppliancesWithCapabilities(locale)
  )) as ReturnType<ReturnType<typeof selectAppliancesWithCapabilities>>;

  const appliancesToCheck: ApplianceWithCapabilities[] =
    appliances?.filter((appliance) =>
      recipeApplianceTags.some((tag) =>
        appliance.referenceTagIds.includes(tag.id)
      )
    ) ?? [];

  if (!appliancesToCheck.length) {
    yield put(stepIncompatibilitiesUpdated({}));
    return;
  }

  const capability = (yield select(selectCapability)) as ReturnType<
    typeof selectCapability
  >;
  if (!capability) {
    yield put(stepIncompatibilitiesUpdated({}));
    return;
  }

  const settings = (yield select(selectSettings)) as ReturnType<
    typeof selectSettings
  >;
  const incompatibilities = checkApplianceIncompatibilities({
    capability,
    selectedSettings: Object.values(settings),
    appliances: appliancesToCheck,
  });

  yield put(stepIncompatibilitiesUpdated(incompatibilities));
}

function* recipeStepCheckIncompatibilitiesWatcher() {
  yield takeLatest(
    [
      stepSettingUpdated,
      stepSettingsUpdated,
      stepFormPopulated,
      shouldCheckIncompatibilities,
    ],
    checkStepIncompatibilities
  );
}

export const recipeStepsFormRestartableSagas = [
  recipeStepHasChangedWatcher,
  recipeStepCheckIncompatibilitiesWatcher,
];
