export const homeFeedSkeletonStrings = {
  loaders: {
    title: `Loading collection's title`,
    appliances: `Loading collection's appliances`,
    id: `Loading collection's ID`,
    recipeTotal: `Loading collection's number of recipes`,
    status: `Loading collection's status`,
    modifiedAt: `Loading collection's modified at date`,
  },
};
export const homeFeedSkeletonConstants = {
  height: '20px',
} as const;
