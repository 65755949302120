import { Spinner } from '@dropkitchen/pantry-react';
import {
  Autocomplete,
  Grid,
  InputAdornment,
  TextField,
  autocompleteClasses,
  outlinedInputClasses,
} from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import { useAutoFocus } from 'app/routes/hooks/useAutoFocus';
import { ErrorHelperText } from 'components/ErrorHelperText/ErrorHelperText';

export interface ValueWithUnitFieldProps {
  valueField: {
    id: string;
    label: string;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    ariaLabel?: string;
    isLoadingValue?: boolean;
    required?: boolean;
  };
  unitField: {
    id: string;
    label: string;
    placeholder: string;
    value: ApiQuantityUnit | null;
    options: ApiQuantityUnit[];
    onChange: (value: ApiQuantityUnit | null) => void;
    ariaLabel?: string;
    isLoadingOptions?: boolean;
    isLoadingValue?: boolean;
    required?: boolean;
  };
  errors?: {
    value?: string;
    unit?: string;
  };
}

export const ValueWithUnitField: FC<ValueWithUnitFieldProps> = memo(
  function ValueWithUnitField({ valueField, unitField, errors }) {
    const { isFocused } = useAutoFocus();

    return (
      <Grid container spacing={4} direction="row">
        <Grid item xs={6}>
          <TextField
            type="text"
            label={valueField.label}
            placeholder={
              !valueField.isLoadingValue ? valueField.placeholder : undefined
            }
            id={valueField.id}
            variant="outlined"
            fullWidth
            autoFocus={isFocused(valueField.id)}
            value={valueField.value}
            error={!!errors?.value}
            disabled={valueField.isLoadingValue}
            InputLabelProps={{
              shrink: true,
              'aria-label': valueField.ariaLabel,
            }}
            InputProps={{
              startAdornment: (
                <LoadingIcon loading={valueField.isLoadingValue} />
              ),
            }}
            required={valueField.required}
            helperText={
              !!errors?.value && <ErrorHelperText message={errors.value} />
            }
            onChange={(event) => valueField.onChange(event.target.value)}
            sx={{
              [`& .${outlinedInputClasses.input}`]: {
                visibility: valueField.isLoadingValue ? 'hidden' : 'show',
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            sx={{
              ...(unitField.isLoadingValue && {
                [`.${autocompleteClasses.popupIndicator}, .${autocompleteClasses.input}`]:
                  {
                    visibility: 'hidden',
                  },
              }),
            }}
            id={unitField.id}
            options={unitField.options}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={unitField.value}
            loading={unitField.isLoadingOptions}
            disabled={unitField.isLoadingValue}
            onChange={(_e, value) => unitField.onChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                required={unitField.required}
                variant="outlined"
                label={unitField.label}
                placeholder={
                  !unitField.isLoadingValue ? unitField.placeholder : undefined
                }
                autoFocus={isFocused(unitField.id)}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: true,
                  'aria-label': unitField.ariaLabel,
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <LoadingIcon loading={unitField.isLoadingValue} />
                  ),
                }}
                error={!!errors?.unit}
                helperText={
                  !!errors?.unit && <ErrorHelperText message={errors.unit} />
                }
              />
            )}
          />
        </Grid>
      </Grid>
    );
  }
);

const LoadingIcon: FC<{ loading?: boolean }> = memo(function LoadingIcon({
  loading,
}) {
  if (!loading) {
    return null;
  }

  return (
    <InputAdornment position="start">
      <Spinner size={20} />
    </InputAdornment>
  );
});
