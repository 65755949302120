import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { RecipeType } from 'app/routes/constants';
import type { RootState } from 'app/store/rootReducer';
import {
  recipesSearchFiltersApplied,
  recipesSearchClear,
  recipesSearchTermUpdated,
  recipesSearchReset,
} from 'components/RecipesSearch/recipesSearchSlice';
import type { TablePaginationEvent } from 'components/Table/Table';
import { tableDefaults } from 'components/Table/Table.constants';
import { authSignOutFinished } from 'features/auth/authSlice';
import type {
  DeleteRecipePayload,
  ForkRecipesPayload,
} from 'features/recipes/recipesSagas';
import type { AppRecipe } from 'types/recipe/appRecipe';
import type { AppRecipes } from 'types/search/appRecipes';

export interface RecipeTranslations {
  [recipeId: ApiRcpRecipeId]: {
    translations?: AppRecipe[];
    apiError?: boolean;
    fetching?: boolean;
  };
}

export interface RecipesState {
  apiError?: string;
  fetching: boolean;
  page: number;
  recipes: AppRecipe[];
  recipeType: RecipeType;
  rowCount: number;
  rowsPerPage: number;
  rowsLoading: { [key: string]: boolean };
  recipeTranslations: RecipeTranslations;
}

export const initialState: RecipesState = {
  fetching: false,
  page: tableDefaults.firstPage,
  recipes: [],
  recipeType: RecipeType.Core,
  rowCount: 0,
  rowsPerPage: tableDefaults.rowsPerPage,
  rowsLoading: {},
  recipeTranslations: {},
};

const recipesSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    recipesPaginated(
      state,
      { payload: { page, rowsPerPage } }: PayloadAction<TablePaginationEvent>
    ) {
      state.page = page;
      state.rowsPerPage = rowsPerPage;
    },
    recipesFetching(state) {
      state.apiError = undefined;
      state.fetching = true;
    },
    recipesFetchSucceed(state, { payload }: PayloadAction<AppRecipes>) {
      state.fetching = false;
      state.recipes = payload.recipes;
      state.rowCount = payload.total;
    },
    recipesFetchFailed(state, { payload }: PayloadAction<string>) {
      state.apiError = payload;
      state.fetching = false;
    },
    recipesBatchUpdateRequested(
      state,
      { payload: recipeIds }: PayloadAction<ApiRcpRecipeId[]>
    ) {
      recipeIds.forEach((id) => {
        state.rowsLoading[id] = true;
      });
    },
    recipesBatchUpdateFinished(
      state,
      { payload: recipeIds }: PayloadAction<ApiRcpRecipeId[]>
    ) {
      recipeIds.forEach((id) => delete state.rowsLoading[id]);
    },
    recipeTypeChanged(
      state,
      { payload: recipeType }: PayloadAction<RecipeType>
    ) {
      state.recipeType = recipeType;
      state.fetching = true;
    },
    recipeTranslationsFetching(
      state,
      { payload: { recipeId } }: PayloadAction<{ recipeId: ApiRcpRecipeId }>
    ) {
      state.recipeTranslations[recipeId] = { fetching: true };
    },
    recipeTranslationsFetchSucceed(
      state,
      {
        payload: { recipeId, translations },
      }: PayloadAction<{
        recipeId: ApiRcpRecipeId;
        translations: AppRecipe[];
      }>
    ) {
      state.recipeTranslations[recipeId] = { translations };
    },
    recipeTranslationsFetchFailed(
      state,
      { payload: { recipeId } }: PayloadAction<{ recipeId: ApiRcpRecipeId }>
    ) {
      state.recipeTranslations[recipeId] = { apiError: true };
    },
    recipesTranslateRequested(
      state,
      { payload: { recipeId } }: PayloadAction<ForkRecipesPayload>
    ) {
      state.rowsLoading[recipeId] = true;
    },
    recipesTranslateFinished(
      state,
      { payload: recipeId }: PayloadAction<string>
    ) {
      state.rowsLoading[recipeId] = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authSignOutFinished, () => initialState)
      .addMatcher(shouldResetPage, (state: RecipesState) => {
        state.page = initialState.page;
      });
  },
});

export const recipesFetchRequested = createAction(
  'recipesSlice/recipesFetchRequested'
);

export const recipesPublishRequested = createAction<ApiRcpRecipeId[]>(
  'recipesSlice/recipesPublishRequested'
);

export const recipesUnpublishRequested = createAction<ApiRcpRecipeId[]>(
  'recipesSlice/recipesUnpublishRequested'
);

export const recipesDeleteRequested = createAction<DeleteRecipePayload[]>(
  'recipesSlice/recipesDeleteRequested'
);

export const recipeTranslationsFetchRequested = createAction<{
  recipeId: ApiRcpRecipeId;
  locales?: ApiLocale[];
}>('recipesSlice/recipeTranslationsFetchRequested');

export const {
  reducer: recipesReducer,
  actions: {
    recipesPaginated,
    recipesFetching,
    recipesFetchSucceed,
    recipesFetchFailed,
    recipesBatchUpdateRequested,
    recipesBatchUpdateFinished,
    recipeTypeChanged,
    recipeTranslationsFetching,
    recipeTranslationsFetchSucceed,
    recipeTranslationsFetchFailed,
    recipesTranslateRequested,
    recipesTranslateFinished,
  },
} = recipesSlice;

const selectRecipesState = (state: RootState): RecipesState => state.recipes;

export const selectRecipes = (state: RootState): AppRecipe[] =>
  selectRecipesState(state).recipes;

export const selectRecipesPage = (state: RootState): number =>
  selectRecipesState(state).page;

export const selectRecipesRowsPerPage = (state: RootState): number =>
  selectRecipesState(state).rowsPerPage;

export const selectRecipesRowCount = (state: RootState): number =>
  selectRecipesState(state).rowCount;

export const selectRecipesFetching = (state: RootState): boolean =>
  selectRecipesState(state).fetching;

export const selectRecipeType = (state: RootState): RecipeType =>
  selectRecipesState(state).recipeType;

export const selectRowsLoading = (
  state: RootState
): { [key: string]: boolean } => selectRecipesState(state).rowsLoading;

export const selectRecipeTranslations = (
  state: RootState
): RecipeTranslations => selectRecipesState(state).recipeTranslations;

export const selectRecipeTranslationByLocale =
  (recipeId: ApiRcpRecipeId, locale: ApiLocale) =>
  (state: RootState): AppRecipe | undefined =>
    selectRecipesState(state).recipeTranslations[recipeId]?.translations?.find(
      (translation) => translation.locale === locale
    );

export const shouldResetPage = (action: Action<string>): boolean =>
  [
    recipesSearchTermUpdated.type,
    recipesSearchFiltersApplied.type,
    recipesSearchClear.type,
    recipesSearchReset.type,
    recipeTypeChanged.type,
  ].includes(action.type);
