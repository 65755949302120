import {
  Media,
  MediaType,
  OvenGenIcon,
  PantryColor,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { Theme } from '@emotion/react';
import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import type { ApiAplId } from 'api/types/appliance/apiAplId';
import type { ApiLocale } from 'api/types/common/apiLocale';
import { useAppSelector } from 'app/store/hooks';
import { selectAppliance } from 'features/appliances/appliancesSlice';
import { selectApplianceTagImage } from 'features/referenceData/tags/tagsSlice';

export interface ApplianceImageProps {
  applianceId: ApiAplId;
  locale: ApiLocale;
  sx?: SxProps<Theme>;
}

export const ApplianceImage: FC<ApplianceImageProps> = memo(
  function ApplianceImage({ applianceId, locale, sx }) {
    const appliance = useAppSelector(selectAppliance(locale, applianceId));
    const image =
      useAppSelector(
        selectApplianceTagImage(locale, appliance?.referenceTagIds ?? [])
      ) ?? '';

    return (
      <Media
        type={MediaType.Image}
        url={image}
        sx={sxCompose(
          {
            borderRadius: 1,
            overflow: 'hidden',
          },
          sx
        )}
        placeholder={<ApplianceImagePlaceholder />}
      />
    );
  }
);

export const ApplianceImagePlaceholder = memo(
  function ApplianceImagePlaceholder() {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          p: 2,
          backgroundColor: PantryColor.SurfaceSubtle,
        }}
      >
        <OvenGenIcon
          color={PantryColor.FrescoPrimary}
          /**
           * @todo: Update Pantry icons size typing to accept not just numbers
           */
          size={48}
          sx={{
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
    );
  }
);
