import { sxCompose } from '@dropkitchen/pantry-react';
import type { SxProps, Theme } from '@mui/material';
import { Box, Paper } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { stylesCenterContent } from 'app/theme';

export interface DetailLayoutProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export const DetailLayout: FC<DetailLayoutProps> = memo(function DetailLayout({
  children,
  sx,
}) {
  return (
    <Box sx={{ ...stylesCenterContent }}>
      <Paper
        sx={sxCompose(
          { boxShadow: '0px 15px 40px rgba(0, 0, 0, 0.1)', borderRadius: 1 },
          sx
        )}
      >
        {children}
      </Paper>
    </Box>
  );
});
