import { combineReducers } from '@reduxjs/toolkit';

import { recipesSearchReducer } from 'components/RecipesSearch/recipesSearchSlice';
import { tabsListReducer } from 'components/Tab/tabsListSlice';
import { appliancesReducer } from 'features/appliances/appliancesSlice';
import { authReducer } from 'features/auth/authSlice';
import { configsReducer } from 'features/configs/configsSlice';
import { errorReducer } from 'features/error/errorSlice';
import { homeFeedReducer } from 'features/homeFeed/HomeFeed/homeFeedSlice';
import { homeFeedAddCollectionRecipesDialogReducer } from 'features/homeFeed/HomeFeedAddCollectionRecipesDialog/homeFeedAddCollectionRecipesDialogSlice';
import { homeFeedCollectionReducer } from 'features/homeFeed/HomeFeedCollectionPage/homeFeedCollectionSlice';
import { homeFeedsReducer } from 'features/homeFeed/homeFeedsSlice';
import { mediaReducer } from 'features/media/mediaSlice';
import { recipeIngredientFormReducer } from 'features/recipe/ingredients/form/recipeIngredientFormSlice';
import { recipeReducer } from 'features/recipe/recipeSlice';
import { recipeStepsFormReducer } from 'features/recipe/steps/form/recipeStepsFormSlice';
import { recipesReducer } from 'features/recipes/recipesSlice';
import { referenceDataReducer } from 'features/referenceData/referenceDataReducer';
import { toastsReducer } from 'features/toasts/toastsSlice';
import { recipeTranslationReducer } from 'features/translation/recipeTranslationSlice';

export const rootReducer = combineReducers({
  auth: authReducer,
  media: mediaReducer,
  recipe: recipeReducer,
  recipes: recipesReducer,
  recipesSearch: recipesSearchReducer,
  referenceData: referenceDataReducer,
  appliances: appliancesReducer,
  error: errorReducer,
  recipeIngredientForm: recipeIngredientFormReducer,
  recipeStepsForm: recipeStepsFormReducer,
  tabsList: tabsListReducer,
  recipeTranslation: recipeTranslationReducer,
  configs: configsReducer,
  homeFeeds: homeFeedsReducer,
  homeFeed: homeFeedReducer,
  homeFeedCollection: homeFeedCollectionReducer,
  homeFeedAddCollectionRecipesDialog: homeFeedAddCollectionRecipesDialogReducer,
  toasts: toastsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
