import {
  PantryCornerRadius,
  Skeleton,
  SkeletonVariant,
} from '@dropkitchen/pantry-react';
import type { Theme } from '@emotion/react';
import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import type { TableLoadingOverlayProps } from 'components/Table/Table';
import { appliancesListConstants } from 'features/appliances/list/AppliancesList.constants';

const { table } = appliancesListConstants;

const tableCellStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  /** Padding added by MUI Grid to each cell */
  px: '10px',
};
const skeletonStyle: SxProps<Theme> = { borderRadius: 0.5 };

export const AppliancesListMainInfoSkeleton: FC = memo(
  function AppliancesListMainInfoSkeleton() {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 4,
        }}
      >
        <Skeleton
          variant={SkeletonVariant.Small}
          sx={{ ...skeletonStyle, width: 56, height: 56 }}
        />
        <Skeleton
          variant={SkeletonVariant.Small}
          sx={{ ...skeletonStyle, width: 221, height: 21 }}
        />
      </Box>
    );
  }
);

export const AppliancesListIdSkeleton: FC = memo(
  function AppliancesListIdSkeleton() {
    return (
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ ...skeletonStyle, width: 250, height: 21 }}
      />
    );
  }
);

export const AppliancesListPartnerSkeleton: FC = memo(
  function AppliancesListPartnerSkeleton() {
    return (
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ ...skeletonStyle, width: 66, height: 21 }}
      />
    );
  }
);

export const AppliancesListTagsSkeleton: FC = memo(
  function AppliancesListTagsSkeleton() {
    return (
      <Skeleton
        variant={SkeletonVariant.Full}
        sx={{
          ...skeletonStyle,
          width: 172,
          height: 32,
          borderRadius: PantryCornerRadius.Full,
        }}
      />
    );
  }
);
export const AppliancesListSkeleton: FC<TableLoadingOverlayProps> = memo(
  function AppliancesListSkeleton({ rowHeight, rowsPerPage }) {
    return (
      <Box
        aria-label={table.skeletonAriaLabel}
        sx={{
          /** Take the border of the header into account */
          mt: '1px',
        }}
      >
        {Array.from({ length: rowsPerPage }, (_, index) => (
          <Box
            role="row"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            sx={{
              height: rowHeight,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                ...tableCellStyle,
                ...table.columns.mainInfo,
              }}
            >
              <AppliancesListMainInfoSkeleton />
            </Box>
            <Box
              sx={{
                ...tableCellStyle,
                ...table.columns.id,
              }}
            >
              <AppliancesListIdSkeleton />
            </Box>
            <Box
              sx={{
                ...tableCellStyle,
                ...table.columns.partner,
              }}
            >
              <AppliancesListPartnerSkeleton />
            </Box>
            <Box
              sx={{
                ...tableCellStyle,
                ...table.columns.tags,
              }}
            >
              <AppliancesListTagsSkeleton />
            </Box>
          </Box>
        ))}
      </Box>
    );
  }
);
