import type { SxProps, Theme } from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';

import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import { isApiRefId } from 'api/types/referenceData/apiRefId';
import type { ApiRefConvertQuantityRequestContext } from 'api/unitConversion';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import {
  ValueInMultipleSystemsField,
  ValueInMultipleSystemsFieldVariant,
  fromNumberToStringValueWithUnit,
  fromStringToNumberValueWithUnit,
} from 'components/ValueInMultipleSystemsField/ValueInMultipleSystemsField';
import { selectRecipeLocale } from 'features/recipe/recipeSlice';
import {
  selectCapability,
  selectIsSettingAutoConversionEnabled,
  stepSettingAutoConversionToggled,
} from 'features/recipe/steps/form/recipeStepsFormSlice';
import {
  selectMetricMeasurementSystemUnitsDictionary,
  selectUsCustomaryMeasurementSystemUnitsDictionary,
} from 'features/referenceData/measurementSystems/measurementSystemsSlice';
import type { AppCapability } from 'types/appCapability';
import type { AppCapabilityAllowedSetting } from 'types/appCapabilityAllowedSetting';
import type { AppUnitSystem } from 'types/appUnitSystem';
import type { AppRecipeValueInMultipleSystems } from 'types/recipe/appRecipeValueInMultipleSystems';
import type { AppRecipeValueWithUnitNullable } from 'types/recipe/appRecipeValueWithUnit';
import { isValueInMultipleSystems } from 'utils/unitSystems';
import type { ValidatorError } from 'utils/validator';

export interface NumericSettingFieldProps {
  setting: AppCapabilityAllowedSetting;
  value: AppRecipeValueInMultipleSystems | null;
  sx?: SxProps<Theme>;
  errors?: {
    metric?: ValidatorError;
    usCustomary?: ValidatorError;
  };
  onChange: (value: AppRecipeValueInMultipleSystems | null) => void;
}

interface ValueInMultipleSystemsValue {
  [AppUnitSystem.Metric]: AppRecipeValueWithUnitNullable;
  [AppUnitSystem.UsCustomary]: AppRecipeValueWithUnitNullable;
}

export const NumericSettingField = memo(function NumericSettingField({
  setting,
  value,
  sx,
  errors,
  onChange,
}: NumericSettingFieldProps) {
  const dispatch = useAppDispatch();

  const locale = useAppSelector(selectRecipeLocale);
  const selectedCapability = useAppSelector(selectCapability);
  const metricUnits = useAppSelector(
    useMemo(
      () => selectMetricMeasurementSystemUnitsDictionary(locale),
      [locale]
    )
  );
  const usCustomaryUnits = useAppSelector(
    useMemo(
      () => selectUsCustomaryMeasurementSystemUnitsDictionary(locale),
      [locale]
    )
  );
  const isAutoConversionEnabled = useAppSelector(
    selectIsSettingAutoConversionEnabled(setting.id)
  );

  const allowedUnits = useMemo(() => {
    const metric: ApiQuantityUnit[] = [];
    const usCustomary: ApiQuantityUnit[] = [];
    setting.allowedValues.numeric?.forEach((allowed) => {
      if (isValueInMultipleSystems(allowed)) {
        if (metricUnits[allowed.metric.unit.id]) {
          metric.push(metricUnits[allowed.metric.unit.id]);
        }
        if (usCustomaryUnits[allowed.usCustomary.unit.id]) {
          usCustomary.push(usCustomaryUnits[allowed.usCustomary.unit.id]);
        }
        return;
      }
      if (metricUnits[allowed.unit.id]) {
        metric.push(metricUnits[allowed.unit.id]);
      }
      if (usCustomaryUnits[allowed.unit.id]) {
        usCustomary.push(usCustomaryUnits[allowed.unit.id]);
      }
    });
    return { metric, usCustomary };
  }, [setting.allowedValues.numeric, metricUnits, usCustomaryUnits]);
  const [currentValue, setCurrentValue] = useState<ValueInMultipleSystemsValue>(
    {
      metric: value?.metric || {
        amount: null,
        unit: allowedUnits.metric[0],
      },
      usCustomary: value?.usCustomary || {
        amount: null,
        unit: allowedUnits.usCustomary[0],
      },
    }
  );

  useEffect(() => {
    setCurrentValue({
      metric: {
        amount: value?.metric.amount ?? null,
        unit: value?.metric.unit ?? allowedUnits.metric[0],
      },
      usCustomary: {
        amount: value?.usCustomary.amount ?? null,
        unit: value?.usCustomary.unit ?? allowedUnits.usCustomary[0],
      },
    });
  }, [
    allowedUnits,
    value?.metric.amount,
    value?.metric.unit,
    value?.usCustomary.amount,
    value?.usCustomary.unit,
  ]);

  return (
    <ValueInMultipleSystemsField
      id={setting.id}
      label={setting.name}
      locale={locale}
      metricField={{
        quantity: fromNumberToStringValueWithUnit(currentValue.metric),
        allowedUnits: allowedUnits.metric,
        errors: errors?.metric,
      }}
      usCustomaryField={{
        quantity: fromNumberToStringValueWithUnit(currentValue.usCustomary),
        allowedUnits: allowedUnits.usCustomary,
        errors: errors?.usCustomary,
      }}
      variant={ValueInMultipleSystemsFieldVariant.Condensed}
      sx={sx}
      onChange={(newValue) => {
        const valueWithNumber: ValueInMultipleSystemsValue = {
          metric: fromStringToNumberValueWithUnit(newValue.metric),
          usCustomary: fromStringToNumberValueWithUnit(newValue.usCustomary),
        };
        setCurrentValue(valueWithNumber);
        if (
          valueWithNumber.metric.amount !== null &&
          valueWithNumber.metric.unit !== null &&
          valueWithNumber.usCustomary.amount !== null &&
          valueWithNumber.usCustomary.unit !== null
        ) {
          onChange({
            metric: {
              amount: valueWithNumber.metric.amount,
              unit: valueWithNumber.metric.unit,
            },
            usCustomary: {
              amount: valueWithNumber.usCustomary.amount,
              unit: valueWithNumber.usCustomary.unit,
            },
          });
        } else if (
          valueWithNumber.metric.amount === null &&
          valueWithNumber.usCustomary.amount === null
        ) {
          onChange(null);
        }
      }}
      context={getConversionContext(selectedCapability, setting)}
      isAutoConversionEnabled={isAutoConversionEnabled}
      onAutoConversionLinkClick={(currentAutoConversionValue) => {
        dispatch(
          stepSettingAutoConversionToggled({
            settingId: setting.id,
            currentValue: currentAutoConversionValue,
          })
        );
      }}
    />
  );
});

export const getConversionContext = (
  capability: AppCapability | null,
  setting: AppCapabilityAllowedSetting
): ApiRefConvertQuantityRequestContext | undefined => {
  if (!capability) {
    return undefined;
  }
  return {
    ...(isRefId(capability.id) && {
      capabilityReferenceId: capability.id,
    }),
    ...(isRefId(capability.referenceCapabilityId) && {
      capabilityReferenceId: capability.referenceCapabilityId,
    }),
    ...(isRefId(setting.id) && { settingReferenceId: setting.id }),
  };
};

const isRefId = (id: ApiEntityId | undefined): id is ApiRefId =>
  !!id && isApiRefId(id);
