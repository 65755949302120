export const recipeIngredientsStrings = {
  title: 'Ingredients',
  labels: {
    emptyImg: 'Empty list image',
  },
  messages: {
    empty: "Let's get started and add some ingredients...",
  },
  buttons: {
    add: 'New Ingredient',
  },
  errors: {
    deleteNoIndex:
      'You have to set the index of the ingredient to be deleted first',
  },
  ids: {
    dragZone: 'ingredients-list',
  },
} as const;
