import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { BackToTopButton } from 'components/BackToTopButton/BackToTopButton';
import type { HeaderProps } from 'features/layout/Header';
import { Header } from 'features/layout/Header';
import { HeaderContextProvider } from 'features/layout/HeaderContextProvider';

interface PageLayoutProps {
  headerPosition?: HeaderProps['position'];
}

export const PageLayout: FC<PageLayoutProps> = memo(function PageLayout({
  headerPosition,
}) {
  return (
    <Box pb={13.125}>
      <HeaderContextProvider>
        <Header position={headerPosition} />
        <Outlet />
        <BackToTopButton />
      </HeaderContextProvider>
    </Box>
  );
});
