export const dragAndDropConstants = {
  testIds: {
    dragPlaceholder: 'drag-placeholder',
  },
  styles: {
    draggable: {
      transition: 'transform 0.2s',
      cursor: 'move',
    },
    dragging: {
      transform: 'rotate(3deg)',
    },
  },
} as const;
