import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import type { AppRecipeValueInMultipleSystems } from 'types/recipe/appRecipeValueInMultipleSystems';
import type { AppRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';
import {
  getValueInMultipleSystemsAsString,
  getValueWithUnitAsString,
  isValueInSingleSystem,
} from 'utils/unitSystems';

interface GetIngredientPropertiesAsStringsParams {
  ingredient: AppRecipeIngredient;
  quantity: AppRecipeValueWithUnit | AppRecipeValueInMultipleSystems;
  ariaPrefix?: string;
}

export interface IngredientPropertiesAsStringsBase {
  name: string;
  ariaLabel: string;
  preparationsAsString: string | undefined;
}

export interface IngredientPropertiesAsStringsWithSingleUnitQuantity
  extends IngredientPropertiesAsStringsBase {
  quantityAsString: string;
}

export interface IngredientPropertiesAsStringsWithMultipleSystemsQuantity
  extends IngredientPropertiesAsStringsBase {
  quantityAsString: ReturnType<typeof getValueInMultipleSystemsAsString>;
}

type IngredientPropertiesAsStrings =
  | IngredientPropertiesAsStringsWithSingleUnitQuantity
  | IngredientPropertiesAsStringsWithMultipleSystemsQuantity;

export const getIngredientPropertiesAsStrings = ({
  ingredient,
  quantity,
  ariaPrefix,
}: GetIngredientPropertiesAsStringsParams): IngredientPropertiesAsStrings => {
  const ingredientPropertiesBase: IngredientPropertiesAsStringsBase = {
    name: ingredient.name,
    ariaLabel: ariaPrefix
      ? `${ariaPrefix} - ${ingredient.name}`
      : ingredient.name,
    preparationsAsString: ingredient.preparations
      ?.map((preparation) => preparation.name)
      .join(', '),
  };

  if (isValueInSingleSystem(quantity)) {
    return {
      ...ingredientPropertiesBase,
      quantityAsString: getValueWithUnitAsString(quantity),
    };
  }

  return {
    ...ingredientPropertiesBase,
    quantityAsString: getValueInMultipleSystemsAsString(quantity),
  };
};
