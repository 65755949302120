import type { SagaIterator } from 'redux-saga';
import { call, put, race, select, take } from 'redux-saga/effects';

import type { AppConfig } from 'api/configs';
import { apiGetConfig } from 'api/configs';
import type { ApiResponse } from 'api/types';
import type { RootState } from 'app/store/rootReducer';
import {
  configsFetchFailed,
  configsFetchSucceed,
  configsFetching,
  selectConfigsFetching,
  selectConfigsState,
} from 'features/configs/configsSlice';
import { errorOccurred } from 'features/error/errorSlice';
import { AppErrorType } from 'types/appError';

export const configsFetchErrorMessage =
  'Unfortunately, we are unable to access all the information needed to complete this task. Please, reload the page and try again.';

export function* fetchConfigs() {
  yield put(configsFetching());

  const response = (yield call(apiGetConfig)) as ApiResponse<AppConfig>;
  if (!response.ok) {
    yield put(configsFetchFailed(configsFetchErrorMessage));
    yield put(
      errorOccurred({
        message: configsFetchErrorMessage,
        type: AppErrorType.Blocking,
      })
    );
    return;
  }

  yield put(configsFetchSucceed(response.data));
}

/**
 * Gets a configuration slice selector and executes it once the configuration is available in the store
 *
 * @param selector The selector to be run
 */
export function* runConfigSelector<T>(
  selector: (state: RootState) => T
): SagaIterator<T> {
  const isFetching = (yield select(selectConfigsFetching)) as ReturnType<
    typeof selectConfigsFetching
  >;
  if (isFetching) {
    /** Wait for the request succeed or fail. */
    yield race({
      fetchSucceed: take(configsFetchSucceed),
      fetchFailed: take(configsFetchFailed),
    });
  }
  const config = (yield select(selectConfigsState)) as ReturnType<
    typeof selectConfigsState
  >;
  /**
   * If there was an error retrieving the configuration log it.
   */
  if (config.apiError) {
    throw new Error(config.apiError);
  }
  /** Configuration is available, run the selector. */
  return (yield select(selector)) as ReturnType<typeof selector>;
}

export const configsRunOnceSagas = [fetchConfigs];
