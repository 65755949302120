import type { FC } from 'react';

import { imageStrings } from 'components/Image/Image.constants';

export const RecipesListPlaceholderImage: FC<{
  width: number;
  height: number;
}> = function RecipesListPlaceholderImage({ width, height }) {
  return (
    <svg
      data-testid={imageStrings.testIds.imagePlaceholder}
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.00976562"
        y="0.5"
        width="56"
        height="56"
        rx="8"
        fill="#ECECEC"
      />
      <path
        d="M28.0097 16.125L27.1893 15.7153V15.718L27.1866 15.7217L27.1811 15.7336L27.1618 15.773C27.066 15.9779 26.9773 16.1862 26.896 16.3973C26.6937 16.92 26.5355 17.4587 26.423 18.0079C26.181 19.2188 26.1012 21.0127 27.3617 22.2731C27.9346 22.846 28.0143 23.8021 27.798 24.8829C27.6699 25.5061 27.47 26.1124 27.2022 26.6896L27.1912 26.7125L27.1893 26.7153L28.0097 27.125C28.8292 27.5348 28.8302 27.5339 28.8302 27.5339V27.532L28.8329 27.5284L28.8384 27.5164L28.8577 27.477L28.9218 27.3395C29.2229 26.6675 29.4491 25.9645 29.5965 25.2431C29.8385 24.0313 29.9182 22.2374 28.6578 20.9769C28.0849 20.404 28.0052 19.4479 28.2215 18.3672C28.3495 17.7439 28.5495 17.1376 28.8173 16.5604L28.8283 16.5375L28.8302 16.5348L28.0097 16.125Z"
        fill="#3F1026"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.67639 33.0834C9.67639 32.8402 9.77297 32.6071 9.94488 32.4352C10.1168 32.2633 10.3499 32.1667 10.5931 32.1667H45.4264C45.6695 32.1667 45.9027 32.2633 46.0746 32.4352C46.2465 32.6071 46.3431 32.8402 46.3431 33.0834C46.3431 33.3265 46.2465 33.5596 46.0746 33.7315C45.9027 33.9034 45.6695 34 45.4264 34H42.6764C42.6764 35.9449 41.9038 37.8102 40.5285 39.1855C39.1532 40.5607 37.288 41.3334 35.3431 41.3334H20.6764C18.7315 41.3334 16.8662 40.5607 15.4909 39.1855C14.1157 37.8102 13.3431 35.9449 13.3431 34H10.5931C10.3499 34 10.1168 33.9034 9.94488 33.7315C9.77297 33.5596 9.67639 33.3265 9.67639 33.0834ZM15.1764 34H40.8431C40.8431 35.4587 40.2636 36.8577 39.2321 37.8891C38.2007 38.9206 36.8018 39.5 35.3431 39.5H20.6764C19.2177 39.5 17.8188 38.9206 16.7873 37.8891C15.7559 36.8577 15.1764 35.4587 15.1764 34Z"
        fill="#3F1026"
      />
      <path
        d="M19.9156 17.9043L20.6764 18.4176C21.4363 18.93 21.4373 18.9291 21.4373 18.9291L21.4253 18.9475C21.3449 19.0758 21.2697 19.2073 21.1998 19.3416C21.0265 19.6706 20.8883 20.0169 20.7873 20.3747C20.5673 21.1924 20.6462 21.8377 21.2218 22.263C22.7087 23.3649 22.6298 25.0121 22.3347 26.1039C22.1998 26.589 22.0139 27.0585 21.7801 27.5045C21.6844 27.6885 21.5811 27.8683 21.4703 28.0435L21.4519 28.0729L21.4473 28.0793L21.44 28.0903L21.4382 28.094L21.4373 28.0949C21.4373 28.0949 21.4363 28.0967 20.6764 27.5834C19.9165 27.07 19.9156 27.071 19.9156 27.071L19.9275 27.0535L19.977 26.9747C20.0387 26.8713 20.0974 26.7662 20.153 26.6594C20.2951 26.3862 20.4583 26.0195 20.5655 25.6254C20.7855 24.8077 20.7067 24.1633 20.131 23.737C18.6442 22.6352 18.723 20.9889 19.0182 19.8962C19.1694 19.3352 19.3931 18.842 19.5728 18.4955C19.6751 18.2994 19.7861 18.1079 19.9055 17.9217L19.9119 17.9107L19.9147 17.9061L19.9165 17.9043H19.9156ZM36.2598 18.4167C35.4998 17.9034 35.4989 17.9043 35.4989 17.9043V17.9061L35.4953 17.9107L35.4879 17.9217C35.3667 18.1067 35.256 18.2983 35.1561 18.4955C34.9764 18.842 34.7528 19.3352 34.6015 19.8962C34.3063 20.988 34.2275 22.6352 35.7143 23.737C36.29 24.1633 36.3688 24.8077 36.1479 25.6254C36.0416 26.0195 35.8784 26.3862 35.7354 26.6594C35.666 26.7938 35.5911 26.9253 35.5108 27.0535L35.4998 27.0719L36.2598 27.5834C37.0197 28.0967 37.0206 28.0949 37.0206 28.0949V28.094L37.0243 28.0903L37.0316 28.0793L37.0453 28.0564L37.0545 28.0435C37.1653 27.8683 37.2687 27.6885 37.3643 27.5045C37.5974 27.0583 37.783 26.5889 37.918 26.1039C38.2132 25.0121 38.292 23.3649 36.8052 22.263C36.2295 21.8368 36.1507 21.1924 36.3716 20.3747C36.4779 19.9805 36.6411 19.6139 36.7841 19.3407C36.8536 19.2064 36.9285 19.0749 37.0087 18.9465L37.0197 18.9291L36.2598 18.4167Z"
        fill="#3F1026"
      />
    </svg>
  );
};
