import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';

import type { ApiIngredientPreparation } from 'api/types/common/apiIngredientPreparation';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { RootState } from 'app/store/rootReducer';
import type { AppLocalizedData } from 'types/appLocalizedData';
import type { AppLocalizedFetchActionPayload } from 'types/appLocalizedFetchActionPayload';
import type { AppLocalizedFetchActionResult } from 'types/appLocalizedFetchActionResult';
import type { AppLocalizedFetchingActionPayload } from 'types/appLocalizedFetchingActionPayload';

export interface PreparationsState {
  fetchError: AppLocalizedData<string>;
  fetching: AppLocalizedData<boolean>;
  preparations: AppLocalizedData<ApiIngredientPreparation[]>;
}

export const initialState: PreparationsState = {
  fetching: {},
  fetchError: {},
  preparations: {},
};

export const preparationsSlice = createSlice({
  name: 'preparationsSlice',
  initialState,
  reducers: {
    preparationsFetchSucceed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<
        AppLocalizedFetchActionResult<ApiIngredientPreparation[]>
      >
    ) {
      state.preparations[locale] = data;
      state.fetching[locale] = false;
      state.fetchError[locale] = undefined;
    },
    preparationsFetching(
      state,
      { payload: { locale } }: PayloadAction<AppLocalizedFetchingActionPayload>
    ) {
      state.fetching[locale] = true;
      state.fetchError[locale] = undefined;
    },
    preparationsFetchFailed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<AppLocalizedFetchActionResult<string>>
    ) {
      state.fetching[locale] = false;
      state.fetchError[locale] = data;
    },
  },
});

export const preparationsFetchRequested =
  createAction<AppLocalizedFetchActionPayload>(
    'preparationsSlice/preparationsFetchRequested'
  );

export const {
  reducer: preparationsReducer,
  actions: {
    preparationsFetchSucceed,
    preparationsFetching,
    preparationsFetchFailed,
  },
} = preparationsSlice;

const selectPreparationsState = (state: RootState): PreparationsState =>
  state.referenceData.preparations;

export const selectPreparations =
  (locale: ApiLocale) =>
  (state: RootState): ApiIngredientPreparation[] | undefined =>
    selectPreparationsState(state).preparations[locale];

export const selectPreparationsFetching =
  (locale: ApiLocale) =>
  (state: RootState): boolean | undefined =>
    selectPreparationsState(state).fetching[locale];

export const selectPreparationsFetchError =
  (locale: ApiLocale) =>
  (state: RootState): string | undefined =>
    selectPreparationsState(state).fetchError[locale];

export const selectShouldFetchPreparations = (locale: ApiLocale) =>
  createSelector(
    selectPreparations(locale),
    selectPreparationsFetching(locale),
    (preparations, fetching) => !fetching && !preparations
  );
