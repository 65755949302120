import { ApiLocale } from 'api/types/common/apiLocale';

export const appRegionByLocale: Record<ApiLocale, string> = {
  [ApiLocale.EnUS]: 'English (United States)',
  [ApiLocale.EnGB]: 'English (United Kingdom)',
  [ApiLocale.EnAU]: 'English (Australia)',
  [ApiLocale.EsES]: 'Spanish (Spain)',
  [ApiLocale.ItIT]: 'Italian (Italy)',
  [ApiLocale.DeDE]: 'German (Germany)',
  [ApiLocale.FrFR]: 'French (France)',
  [ApiLocale.PlPL]: 'Polish (Poland)',
  [ApiLocale.CsCZ]: 'Czech (Czechia)',
  [ApiLocale.NlNL]: 'Dutch (Netherlands)',
  [ApiLocale.NbNO]: 'Norwegian (Norway)',
  [ApiLocale.SvSE]: 'Swedish (Sweden)',
  [ApiLocale.FiFI]: 'Finnish (Finland)',
  [ApiLocale.DaDK]: 'Danish (Denmark)',
  [ApiLocale.PtPT]: 'Portuguese (Portugal)',
  [ApiLocale.RuRU]: 'Russian (Russia)',
};

export const appRegionByLocaleAlternative: Record<ApiLocale, string> = {
  [ApiLocale.EnUS]: 'English-US',
  [ApiLocale.EnGB]: 'English-GB',
  [ApiLocale.EnAU]: 'English-AU',
  [ApiLocale.EsES]: 'Spanish-ES',
  [ApiLocale.ItIT]: 'Italian-IT',
  [ApiLocale.DeDE]: 'German-GE',
  [ApiLocale.FrFR]: 'French-FR',
  [ApiLocale.PlPL]: 'Polish-PL',
  [ApiLocale.CsCZ]: 'Czech-CZ',
  [ApiLocale.NlNL]: 'Dutch-NL',
  [ApiLocale.NbNO]: 'Norwegian-NO',
  [ApiLocale.SvSE]: 'Swedish-SE',
  [ApiLocale.FiFI]: 'Finnish-FI',
  [ApiLocale.DaDK]: 'Danish-DK',
  [ApiLocale.PtPT]: 'Portuguese-PT',
  [ApiLocale.RuRU]: 'Russian-RU',
};
