export const recipeReviewBasicInformationSkeletonConstants = {
  ariaLabels: {
    image: 'Loading image',
    descriptionField: 'Loading description',
    authorField: 'Loading author',
    totalTimeField: 'Loading total time',
    prepTimeField: 'Loading prep time',
    cookTimeField: 'Loading cook time',
    servesField: 'Loading serves',
    applianceTagsField: 'Loading appliance tags',
    generalTagsField: 'Loading general tags',
  },
} as const;
