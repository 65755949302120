import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/store/rootReducer';

export enum ToastDataType {
  Success = 'success',
}

export interface ToastData {
  label: string;
  type?: ToastDataType;
}

export interface ToastState {
  current?: ToastData;
}

export const initialState: ToastState = {};

const toastsSlice = createSlice({
  name: 'toastsSlice',
  initialState,
  reducers: {
    toastEnqueued(state, { payload: toast }: PayloadAction<ToastData>) {
      state.current = toast;
    },
    toastDismissed(state) {
      state.current = undefined;
    },
  },
});

export const {
  reducer: toastsReducer,
  actions: { toastEnqueued, toastDismissed },
} = toastsSlice;

export const selectToastsCurrent = (state: RootState): ToastData | undefined =>
  state.toasts.current;
