import type { SxProps } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';

export interface ErrorHelperTextProps {
  message: string;
  sx?: SxProps;
}

export const ErrorHelperText: FC<ErrorHelperTextProps> = memo(
  function ErrorHelperText({ message, sx }) {
    return (
      <HelperText
        sx={sx}
        message={message}
        severity={HelperTextSeverity.Critical}
      />
    );
  }
);
