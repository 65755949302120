import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';

import type { ApplianceConfig } from 'api/appliances';
import { apiGetApplianceConfig } from 'api/appliances';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
import { apiGetTags } from 'api/tags';
import type { ApiResponse } from 'api/types';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiTag } from 'api/types/common/apiTag';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import {
  fetchItemsRecursively,
  refDataEntitiesToSentenceCase,
} from 'features/referenceData/referenceData.utils';
import type {
  TagsById,
  TagsWithImages,
} from 'features/referenceData/tags/tagsSlice';
import {
  selectApplianceTags,
  selectShouldFetchTags,
  selectTagsFetching,
  tagsFetchRequested,
  selectShouldFetchApplianceTags,
  selectShouldFetchGeneralTags,
  tagsFetchFailed,
  tagsFetching,
  tagsFetchSucceed,
} from 'features/referenceData/tags/tagsSlice';
import type { AppLocalizedFetchActionPayload } from 'types/appLocalizedFetchActionPayload';

export const apiFetchTagsSaga = createApiRequestSaga(apiGetTags);
export const apiGetApplianceConfigSaga = createApiRequestSaga(
  apiGetApplianceConfig
);

export function* fetchTags({
  payload: { locale },
}: PayloadAction<AppLocalizedFetchActionPayload>) {
  const shouldFetchGeneralTags = (yield select(
    selectShouldFetchGeneralTags(locale)
  )) as boolean;

  const shouldFetchApplianceTags = (yield select(
    selectShouldFetchApplianceTags(locale)
  )) as boolean;

  if (!shouldFetchGeneralTags && !shouldFetchApplianceTags) {
    return;
  }

  yield put(tagsFetching({ locale }));

  const [responseTags, responseApplianceConfig] = (yield all([
    call(fetchItemsRecursively, { locale, fetchSaga: apiFetchTagsSaga }),
    call(apiGetApplianceConfigSaga),
  ])) as [ApiResponse<ApiTag[]>, ApiResponse<ApplianceConfig>];

  if (!responseTags.ok) {
    const { message } = responseTags.details;
    yield put(
      tagsFetchFailed({
        locale,
        data: message,
      })
    );
    return;
  }

  if (!responseApplianceConfig.ok) {
    const { message } = responseApplianceConfig.details;
    yield put(
      tagsFetchFailed({
        locale,
        data: message,
      })
    );
    return;
  }

  const tagsDictionary = responseTags.data.reduce<Record<ApiRefId, string>>(
    (acc, current) => {
      acc[current.id] = current.name;
      return acc;
    },
    {}
  );

  const applianceTags: TagsById =
    responseApplianceConfig.data.deviceTags.reduce<TagsById>((acc, current) => {
      current.tags.forEach((tag) => {
        acc[tag.tagId] = fromApplianceConfigTagToAppTag(
          tag,
          responseApplianceConfig.data,
          tagsDictionary[tag.tagId]
        );
      });
      return acc;
    }, {});

  const generalTags: ApiTag[] = responseTags.data.filter(
    (tag) => !applianceTags[tag.id]
  );

  yield put(
    tagsFetchSucceed({
      locale,
      data: {
        generalTags: refDataEntitiesToSentenceCase(generalTags),
        applianceTags,
      },
    })
  );
}

export const fromApplianceConfigTagToAppTag = (
  tag: {
    tagId: ApiRefId;
    tagName: string;
  },
  applianceConfig: ApplianceConfig,
  refDataLabel: string
): TagsWithImages => ({
  id: tag.tagId,
  name: refDataLabel || tag.tagName,
  imageUrl: getApplianceImage({ tagId: tag.tagId, applianceConfig }),
});

export const getApplianceImage = ({
  tagId,
  applianceConfig,
}: {
  tagId: ApiRefId;
  applianceConfig: ApplianceConfig;
}) => {
  const groupId = applianceConfig.deviceItems.find(
    (item) => item.deviceRefId === tagId
  )?.groupItemId;

  return applianceConfig.deviceGroup.find(
    (item) => item.groupItemId === groupId
  )?.groupImage;
};

function* tagsFetchWatcher() {
  yield takeEvery(tagsFetchRequested, fetchTags);
}

export const tagsSagas = [tagsFetchWatcher];

export function* getApplianceTags(locale: ApiLocale) {
  const shouldFetchTags = (yield select(
    selectShouldFetchTags(locale)
  )) as boolean;
  if (shouldFetchTags) {
    yield put(tagsFetchRequested({ locale }));
    yield take([tagsFetchSucceed, tagsFetchFailed]);
  }

  const isFetchingTags = (yield select(selectTagsFetching(locale))) as boolean;
  if (isFetchingTags) {
    yield take([tagsFetchSucceed, tagsFetchFailed]);
  }

  const applianceTags = (yield select(
    selectApplianceTags(locale)
  )) as ReturnType<ReturnType<typeof selectApplianceTags>>;
  return applianceTags || {};
}
