import type { ApiRefId } from 'api/types/referenceData/apiRefId';

export enum AppCapabilityAllowedPhaseId {
  Initialization = 'cckg:PreheatInitializationPhase',
  Execution = 'cckg:ExecutionPhase',
  Update = 'cckg:UpdatePhase',
}

export const appCapabilityAllowedPhaseConstants = {
  order: {
    [AppCapabilityAllowedPhaseId.Initialization]: 0,
    [AppCapabilityAllowedPhaseId.Execution]: 1,
    [AppCapabilityAllowedPhaseId.Update]: 2,
  } as Record<ApiRefId, number>,
};
