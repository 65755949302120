import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiTag } from 'api/types/common/apiTag';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { RootState } from 'app/store/rootReducer';
import { disambiguateTerms } from 'features/referenceData/referenceData.utils';
import type { AppLocalizedData } from 'types/appLocalizedData';
import type { AppLocalizedFetchActionPayload } from 'types/appLocalizedFetchActionPayload';
import type { AppLocalizedFetchActionResult } from 'types/appLocalizedFetchActionResult';
import type { AppLocalizedFetchingActionPayload } from 'types/appLocalizedFetchingActionPayload';

export interface TagsWithImages extends ApiTag {
  imageUrl?: string;
}

export type TagsById = Record<ApiRefId, TagsWithImages>;

export interface TagsState {
  fetchError: AppLocalizedData<string>;
  fetching: AppLocalizedData<boolean>;
  generalTags: AppLocalizedData<ApiTag[]>;
  applianceTags: AppLocalizedData<TagsById>;
}

export const initialState: TagsState = {
  fetching: {},
  fetchError: {},
  generalTags: {},
  applianceTags: {},
};

export const tagsSlice = createSlice({
  name: 'tagsSlice',
  initialState,
  reducers: {
    tagsFetchSucceed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<
        AppLocalizedFetchActionResult<{
          generalTags: ApiTag[];
          applianceTags: TagsById;
        }>
      >
    ) {
      state.generalTags[locale] = data.generalTags;
      state.applianceTags[locale] = data.applianceTags;
      state.fetching[locale] = false;
      state.fetchError[locale] = undefined;
    },
    tagsFetching(
      state,
      { payload: { locale } }: PayloadAction<AppLocalizedFetchingActionPayload>
    ) {
      state.fetching[locale] = true;
      state.fetchError[locale] = undefined;
    },
    tagsFetchFailed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<AppLocalizedFetchActionResult<string>>
    ) {
      state.fetching[locale] = false;
      state.fetchError[locale] = data;
    },
  },
});

export const tagsFetchRequested = createAction<AppLocalizedFetchActionPayload>(
  'tagsSlice/tagsFetchRequested'
);

export const {
  reducer: tagsReducer,
  actions: { tagsFetchSucceed, tagsFetching, tagsFetchFailed },
} = tagsSlice;

const selectTagsState = (state: RootState): TagsState =>
  state.referenceData.tags;

export const selectGeneralTags =
  (locale: ApiLocale) =>
  (state: RootState): ApiTag[] | undefined =>
    selectTagsState(state).generalTags[locale];

export const selectGeneralTagsDisambiguated = (locale: ApiLocale) =>
  createSelector(selectGeneralTags(locale), (tags) => {
    const disambiguatedTerms = disambiguateTerms(tags || []);
    return tags?.map(({ id, name }) => ({
      id,
      name: disambiguatedTerms[id] ?? name,
    }));
  });

export const selectApplianceTags =
  (locale: ApiLocale | null) =>
  (state: RootState): TagsById | undefined =>
    locale ? selectTagsState(state).applianceTags[locale] : undefined;

export const selectApplianceTagImage =
  (locale: ApiLocale, tagIds: ApiRefId[]) =>
  (state: RootState): string | undefined => {
    for (const tagId of tagIds) {
      const tag = selectTagsState(state).applianceTags?.[locale]?.[tagId];
      if (tag) {
        return tag.imageUrl;
      }
    }
    return undefined;
  };

export const selectTagsFetching =
  (locale: ApiLocale | null) =>
  (state: RootState): boolean | undefined =>
    locale ? selectTagsState(state).fetching[locale] : false;

export const selectTagsFetchError =
  (locale: ApiLocale | null) =>
  (state: RootState): string | undefined =>
    locale ? selectTagsState(state).fetchError[locale] : undefined;

export const selectShouldFetchGeneralTags = (locale: ApiLocale) =>
  createSelector(
    selectGeneralTags(locale),
    selectTagsFetching(locale),
    (tags, fetching) => !fetching && !tags
  );

export const selectShouldFetchApplianceTags = (locale: ApiLocale) =>
  createSelector(
    selectApplianceTags(locale),
    selectTagsFetching(locale),
    (tags, fetching) => !fetching && !tags
  );

export const selectShouldFetchTags = (locale: ApiLocale) =>
  createSelector(
    selectShouldFetchGeneralTags(locale),
    selectShouldFetchApplianceTags(locale),
    (shouldFetchGeneralTags, shouldFetchApplianceTags) => {
      return shouldFetchGeneralTags || shouldFetchApplianceTags;
    }
  );
