import { Box, CircularProgress } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { useRoutes } from 'react-router-dom';

import { unauthenticatedRoutes } from 'app/routes/routes';
import { useAppSelector } from 'app/store/hooks';
import {
  selectAuthIsAuthenticated,
  selectAuthIsInitialized,
} from 'features/auth/authSlice';

export const AuthWrapper: FC<{ children: ReactNode }> = function AuthWrapper({
  children,
}) {
  const isAuthInitialized = useAppSelector(selectAuthIsInitialized);
  const isAuthenticated = useAppSelector(selectAuthIsAuthenticated);

  if (!isAuthInitialized) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isAuthenticated ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <Routes />
  );
};

function Routes() {
  return useRoutes(unauthenticatedRoutes);
}
