import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import type { ApiRefEntity } from 'api/types/referenceData/apiRefEntity';
import {
  refDataEntitiesToSentenceCase,
  refDataEntityToSentenceCase,
} from 'features/referenceData/referenceData.utils';

export interface ApiIngredient extends ApiRefEntity {
  allowedUnits: ApiQuantityUnit[];
}

export const fromApiRefIngredient = (
  ingredient: ApiIngredient
): ApiIngredient => {
  return {
    ...refDataEntityToSentenceCase(ingredient),
    allowedUnits: refDataEntitiesToSentenceCase(ingredient.allowedUnits),
  };
};
