import { type ApiAplCapabilityAllowedSettingValueNumeric } from 'api/types/appliance/apiAplCapabilityAllowedSettingValueNumeric';
import type { ApiAplCapabilityAllowedSettingValueNumericInSingleSystem } from 'api/types/appliance/apiAplCapabilityAllowedSettingValueNumericInSingleSystem';
import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import type { ApiRefCapabilityAllowedSettingDependencyValueNumeric } from 'api/types/referenceData/apiRefCapabilityAllowedSettingDependencyValueNumeric';
import { isValueInSingleSystem } from 'utils/unitSystems';

export type AppCapabilityAllowedSettingValueNumeric =
  | AppCapabilityAllowedSettingValueNumericInSingleSystem
  | AppCapabilityAllowedSettingValueNumericInMultipleSystem;

export interface AppCapabilityAllowedSettingValueNumericInSingleSystem {
  min?: number;
  max?: number;
  step?: number;
  unit: ApiQuantityUnit;
}

export interface AppCapabilityAllowedSettingValueNumericInMultipleSystem {
  metric: AppCapabilityAllowedSettingValueNumericInSingleSystem;
  usCustomary: AppCapabilityAllowedSettingValueNumericInSingleSystem;
}

export const fromApiAplCapabilityAllowedSettingValueNumeric = (
  value: ApiAplCapabilityAllowedSettingValueNumeric
): AppCapabilityAllowedSettingValueNumeric =>
  isValueInSingleSystem<ApiAplCapabilityAllowedSettingValueNumericInSingleSystem>(
    value
  )
    ? {
        min: value.min,
        max: value.max,
        step: value.step,
        unit: value.referenceUnit,
      }
    : {
        metric: {
          min: value.metric.min,
          max: value.metric.max,
          step: value.metric.step,
          unit: value.metric.referenceUnit,
        },
        usCustomary: {
          min: value.usCustomary.min,
          max: value.usCustomary.max,
          step: value.usCustomary.step,
          unit: value.usCustomary.referenceUnit,
        },
      };

export const fromApiRefCapabilityAllowedSettingDependencyValueNumeric = ({
  min,
  max,
  referenceUnit,
}: ApiRefCapabilityAllowedSettingDependencyValueNumeric): AppCapabilityAllowedSettingValueNumeric => ({
  min,
  max,
  unit: referenceUnit,
});
