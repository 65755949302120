import type { ApiRcpRecipeIngredient } from 'api/types/recipe/apiRcpRecipeIngredient';
import type { ApiRcpRecipeStepIngredient } from 'api/types/recipe/apiRcpRecipeStepIngredient';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import { fromApiRcpRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import {
  fromApiRcpRecipeValueInMultipleSystems,
  type AppRecipeValueInMultipleSystems,
} from 'types/recipe/appRecipeValueInMultipleSystems';
import type { AppRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';
import { fromApiRcpRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';
import { isValueInSingleSystem } from 'utils/unitSystems';

export interface AppRecipeStepIngredient {
  ingredientIdx: number;
  ingredient: AppRecipeIngredient;
  quantity: AppRecipeValueWithUnit | AppRecipeValueInMultipleSystems;
  isUsed?: boolean;
}

export function fromApiRcpRecipeStepIngredient({
  apiStepIngredient,
  recipeIngredients,
}: {
  apiStepIngredient: ApiRcpRecipeStepIngredient;
  recipeIngredients: ApiRcpRecipeIngredient[];
}): AppRecipeStepIngredient {
  return {
    ingredientIdx: apiStepIngredient.ingredientIdx,
    ingredient: fromApiRcpRecipeIngredient(
      recipeIngredients[apiStepIngredient.ingredientIdx]
    ),
    quantity: isValueInSingleSystem(apiStepIngredient.quantity)
      ? fromApiRcpRecipeValueWithUnit(apiStepIngredient.quantity)
      : fromApiRcpRecipeValueInMultipleSystems(apiStepIngredient.quantity),
  };
}
