export const recipeFromUrlDialogStrings = {
  titles: {
    addFromUrl: 'Create a recipe from URL',
  },
  labels: {
    urlField: 'Enter URL',
  },
  buttons: {
    cancel: 'Cancel',
    next: 'Next',
  },
} as const;
