import { Box, CircularProgress } from '@mui/material';
import type { FC, ReactNode } from 'react';

import { useAppSelector } from 'app/store/hooks';
import { selectConfigsFetching } from 'features/configs/configsSlice';

export const ConfigLoaderWrapper: FC<{ children: ReactNode }> =
  function ConfigLoaderWrapper({ children }) {
    const isLoadingConfig = useAppSelector(selectConfigsFetching);

    if (isLoadingConfig) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            width: '100vw',
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  };
