import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiUsrUser } from 'api/types/user/apiUsrUser';

export interface AppUser {
  email: string;
  name: string;
  familyName: string;
  locale: ApiLocale;
}

export const fromApiUsrUser = ({
  email,
  name,
  familyName,
  locale,
}: ApiUsrUser): AppUser => ({ email, name, familyName, locale });
