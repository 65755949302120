import {
  DragHandleIcon,
  PantryColor,
  PantryTypography,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import { checkboxClasses } from '@mui/material';
import type {
  GridColDef,
  GridFeatureMode,
  GridRowId,
  GridRowParams,
  GridSelectionModel,
} from '@mui/x-data-grid-pro';
import { gridClasses, DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LicenseInfo } from '@mui/x-license-pro';
import omit from 'lodash/omit';
import type { FC, SyntheticEvent } from 'react';
import { useEffect, useState, memo, useMemo } from 'react';

import { tableDefaults } from 'components/Table/Table.constants';
import { TablePagination } from 'components/Table/TablePagination';

const { noRowsMinHeight } = tableDefaults;

LicenseInfo.setLicenseKey(
  '5cbed90f59a65aeaa25c68262a175711Tz04NjI4OSxFPTE3NDE4NjQ1OTUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

export interface TableRow {
  id: string;
}

export type TableColumn = GridColDef;

export type TableLoadingOverlayProps = Required<
  Pick<TableProps, 'rowHeight' | 'rowsPerPage' | 'checkboxSelection'>
>;

export interface TableRowReorderParams<TRow extends TableRow = TableRow> {
  from: number;
  to: number;
  row: TRow;
}

export interface TableProps<TRow extends TableRow = TableRow> {
  ariaLabel: string;
  columns: TableColumn[];
  rows: TRow[];
  rowCount: number;
  rowHeight: number;
  loading: boolean;
  checkboxSelection?: boolean;
  isCheckboxSelectionDisabled?: boolean;
  page?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  paginationMode?: GridFeatureMode;
  components: {
    loadingOverlay: FC<TableLoadingOverlayProps>;
    noRowsOverlay?: FC;
  };
  componentsProps?: {
    row?: {
      onMouseEnter?: (event: SyntheticEvent<HTMLElement>) => void;
      onMouseLeave?: (event: SyntheticEvent<HTMLElement>) => void;
    };
  };
  onPaginationChange?: (event: TablePaginationEvent) => void;
  onSelectedRowsChange?: (rows: string[]) => void;
  hidePagination?: boolean;
  selectedRows?: string[];
  onRowClick?: (params: GridRowParams) => void;
  isRowReorderEnabled?: boolean;
  onRowReorder?: (params: TableRowReorderParams<TRow>) => void;
  rowDraggingLabelField?: keyof TRow | ((row: TRow) => string);
  sx?: SxProps;
}

export interface TablePaginationEvent {
  page: number;
  rowsPerPage: number;
}

export const Table = memo(function Table<TRow extends TableRow = TableRow>({
  ariaLabel,
  columns,
  rows: rowsProp,
  loading,
  rowCount,
  rowHeight,
  checkboxSelection = tableDefaults.checkboxSelection,
  isCheckboxSelectionDisabled = tableDefaults.isCheckboxSelectionDisabled,
  page: initialPage = tableDefaults.firstPage,
  rowsPerPage: initialRowsPerPage = tableDefaults.rowsPerPage,
  rowsPerPageOptions = tableDefaults.rowsPerPageOptions,
  paginationMode = tableDefaults.paginationMode,
  components: { loadingOverlay, noRowsOverlay },
  componentsProps = {},
  onPaginationChange,
  onSelectedRowsChange = () => {},
  onRowClick,
  hidePagination = false,
  selectedRows = [],
  isRowReorderEnabled,
  onRowReorder,
  rowDraggingLabelField = 'id',
  sx,
}: TableProps<TRow>) {
  const gridApiRef = useGridApiRef();
  const [page, setPage] = useState<number>(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(initialRowsPerPage);
  const [draggingRowId, setDraggingRowId] = useState<GridRowId>();
  const rows = useMemo(
    () =>
      isRowReorderEnabled
        ? rowsProp.map((row) => ({
            ...row,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            __reorder__:
              typeof rowDraggingLabelField === 'function'
                ? rowDraggingLabelField(row)
                : row[rowDraggingLabelField],
          }))
        : rowsProp,

    [isRowReorderEnabled, rowDraggingLabelField, rowsProp]
  );

  useEffect(() => {
    const unsubscribeFns = [
      gridApiRef.current.subscribeEvent('rowDragStart', ({ id }) => {
        setDraggingRowId(id);
      }),
      gridApiRef.current.subscribeEvent('rowDragEnd', () => {
        setDraggingRowId(undefined);
      }),
    ];

    return () => {
      unsubscribeFns.forEach((fn) => fn());
    };
  }, [gridApiRef]);

  const minHeight = useMemo(() => {
    return !loading && !rows.length
      ? noRowsMinHeight
      : rowHeight * (rows.length || rowsPerPage);
  }, [loading, rows, rowHeight, rowsPerPage]);

  useEffect(() => {
    setPage(initialPage);
  }, [initialPage]);

  return (
    <DataGridPro
      apiRef={gridApiRef}
      sx={sxCompose(
        {
          [`&.${gridClasses.root}`]: {
            border: 'none',
            backgroundColor: PantryColor.SurfaceDefault,
            color: PantryColor.TextDefault,

            /** As we use row height auto, the row height prop is ignored so we need to give some padding to the cells */
            [`&.${gridClasses['root--densityStandard']} .${gridClasses.cell}`]:
              {
                py: 4,
              },

            /** MUI creates a wrapper around the loader component but it doesn't have any particular identifier */
            [`& .${gridClasses.main} > div[style*="position: absolute;"]`]: {
              zIndex: 1,
            },

            [`& .${gridClasses.columnHeaders}`]: {
              borderRadius: 0,
              border: 'none',

              [`& .${gridClasses.columnHeader}`]: {
                '&:focus': {
                  outline: 'none',
                },
                [`& .${gridClasses.columnSeparator}`]: {
                  display: 'none',
                },
                [`& .${gridClasses.columnHeaderTitle}`]: {
                  typography: PantryTypography.Body1,
                  color: PantryColor.TextMuted,
                },
              },
            },

            [`& .${gridClasses['row--dragging']}`]: {
              whiteSpace: 'nowrap',
              p: 2,
              display: 'flex',
              gap: 2,
            },

            [`& .${gridClasses.virtualScroller}`]: {
              [`& .${gridClasses.virtualScrollerContent}`]: {
                /**
                 * Height is automatically calculated by DataGrid when autoHeight is set to true.
                 * Since we use skeleton-styled loaders, we set a minimum height in order to force the grid to render an approximate height when loading.
                 */
                minHeight: `${minHeight}px !important`,

                [`& .${gridClasses.row}`]: {
                  borderTop: '1px dashed',
                  borderColor: PantryColor.BorderSubtle,

                  [draggingRowId ? `&[data-id="${draggingRowId}"]` : '&:hover']:
                    {
                      boxShadow: `0px 2px 12px rgba(0, 0, 0, 0.08)`,
                    },

                  '&:hover': {
                    backgroundColor: 'inherit',
                  },

                  '&.Mui-selected': {
                    backgroundColor: PantryColor.SurfaceMuted,
                  },

                  [`& .${gridClasses.cell}`]: {
                    border: 'none',
                    '&:focus, &:focus-within': {
                      outline: 'none',
                    },
                  },
                },
              },
            },

            [`& .${gridClasses.footerContainer}`]: {
              borderTop: '1px dashed',
              borderColor: PantryColor.BorderSubtle,
              pt: 2,
            },

            [`& .${checkboxClasses.root}`]: {
              color: PantryColor.BorderDefault,

              [`&.${checkboxClasses.checked}`]: {
                color: PantryColor.SurfaceEmphasis,
              },
            },

            ...(onRowClick && {
              [`& .${gridClasses.row}:hover`]: {
                cursor: 'pointer',
              },
            }),
          },
        },
        sx
      )}
      aria-label={ariaLabel}
      /** Tables won't have internal scroll. Their height will depend on the selected rows per page and scrolling will be handled by a parent container */
      autoHeight
      /** Disable every built-in column menu or filter */
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      /**
       * Disable virtualization as we'll have a maximum of around 80~100 rows being rendered at the same time and they will usually include an image
       * which - if virtualization is enabled - will be added an removed from the DOM, triggering many unnecessary requests
       */
      disableVirtualization
      /** Do not allow selection of a row on click */
      disableSelectionOnClick
      /** Do not show the number of selected rows in the footer */
      hideFooterSelectedRowCount
      /** When using server pagination, we need to keep the selected rows selected even if they are not part of the current page */
      keepNonExistentRowsSelected
      /** Data related props */
      columns={columns}
      rows={rows}
      loading={loading}
      /** Pagination related props */
      hideFooter={hidePagination}
      pagination
      paginationMode={paginationMode}
      page={page}
      pageSize={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      rowCount={rowCount}
      /** Row selection related props */
      checkboxSelection={checkboxSelection}
      selectionModel={selectedRows}
      /** Style related props */
      getRowHeight={() => 'auto'}
      getEstimatedRowHeight={() => rowHeight}
      /** Custom component and component props */
      componentsProps={{
        pagination: {
          rowsPerPageOptions,
          rowCount,
        },
        loadingOverlay: {
          rowHeight,
          rowsPerPage,
          checkboxSelection,
        },
        row: { ...componentsProps.row },
      }}
      components={{
        /* eslint-disable @typescript-eslint/naming-convention */
        Pagination: TablePagination,
        LoadingOverlay: loadingOverlay,
        RowReorderIcon,
        ...(noRowsOverlay && { NoRowsOverlay: noRowsOverlay }),
        /* eslint-enable @typescript-eslint/naming-convention */
      }}
      /** Event handlers */
      onPageChange={(value) => {
        setPage(value);
        onPaginationChange?.({ page: value, rowsPerPage });
      }}
      onPageSizeChange={(updatedRowsPerPage) => {
        setRowsPerPage(updatedRowsPerPage);
        const updatedPage = Math.ceil(
          (page * rowsPerPage) / updatedRowsPerPage
        );
        setPage(updatedPage);
        onPaginationChange?.({
          page: updatedPage,
          rowsPerPage: updatedRowsPerPage,
        });
      }}
      onSelectionModelChange={(value: GridSelectionModel) => {
        onSelectedRowsChange(value.map((v) => v.toString()));
      }}
      onRowClick={onRowClick}
      rowReordering={isRowReorderEnabled}
      onRowOrderChange={({ oldIndex, targetIndex, row }) => {
        if (oldIndex === targetIndex) {
          return;
        }

        onRowReorder?.({
          from: oldIndex,
          to: targetIndex,
          row: omit(row, '__reorder__') as TRow,
        });
      }}
      isRowSelectable={({ row }: GridRowParams<TableRow>) =>
        selectedRows.includes(row.id) || !isCheckboxSelectionDisabled
      }
    />
  );
}) as <TRow extends TableRow = TableRow>(
  props: TableProps<TRow>
) => JSX.Element;

function RowReorderIcon() {
  return <DragHandleIcon size={16} color={PantryColor.IconDefault} />;
}
