import type { AppRecipeValueWithUnit } from 'types/recipe/appRecipeValueWithUnit';
import { getValueWithUnitAsString } from 'utils/unitSystems';

export const recipeIngredientFormStrings = {
  labels: {
    formAdd: 'Add New Ingredient',
    addButton: 'Add',
    updateButton: 'Update',
    closeButton: 'Close',
    formEdit: (ingredientName: string) => `Edit ${ingredientName}`,
    ingredientField: 'Ingredient',
    quantityField: 'Quantity',
    unitField: 'Unit',
    preparationsField: 'Preparations',
    addedPreparations: 'Added preparations',
    removePreparationButton: (preparation: string) => `Remove ${preparation}`,
  },
  errors: {
    quantity: {
      notZero: 'Quantity cannot be 0',
      notNumber: 'Quantity must be a positive number',
      higherThanMax: (max: number) =>
        `Enter a value less than ${max} or increase the total amount`,
      lowerThanUsed: (quantity: AppRecipeValueWithUnit) =>
        `${getValueWithUnitAsString(
          quantity
        )} is used in one or more steps. The new amount cannot be less.`,
    },
  },
  messages: {
    noIngredientSelected: 'Your ingredient details will appear here',
  },
} as const;

export const recipeIngredientFormConstants = {
  fieldIds: {
    ingredient: 'selected-ingredient',
    unit: 'selected-unit',
    quantity: 'quantity',
    metricQuantity: 'quantity-metric-value',
    usCustomaryQuantity: 'quantity-usCustomary-value',
  },
} as const;

export const recipeIngredientAmountConstants = {
  fractionsSeparator: '/',
  precision: 3,
  pattern: /^\d+([/|.]?\d+)?$/,
} as const;
