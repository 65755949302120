import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { RootState } from 'app/store/rootReducer';
import type { AppCapability } from 'types/appCapability';
import type { AppLocalizedData } from 'types/appLocalizedData';
import type { AppLocalizedFetchActionPayload } from 'types/appLocalizedFetchActionPayload';
import type { AppLocalizedFetchActionResult } from 'types/appLocalizedFetchActionResult';
import type { AppLocalizedFetchingActionPayload } from 'types/appLocalizedFetchingActionPayload';

export interface GeneralCapabilitiesState {
  fetchError: AppLocalizedData<string>;
  fetching: AppLocalizedData<boolean>;
  capabilities: AppLocalizedData<AppCapability[]>;
}

export const initialState: GeneralCapabilitiesState = {
  fetching: {},
  fetchError: {},
  capabilities: {},
};

export const generalCapabilitiesSlice = createSlice({
  name: 'generalCapabilitiesSlice',
  initialState,
  reducers: {
    generalCapabilitiesFetchSucceed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<AppLocalizedFetchActionResult<AppCapability[]>>
    ) {
      state.capabilities[locale] = data;
      state.fetching[locale] = false;
      state.fetchError[locale] = undefined;
    },
    generalCapabilitiesFetching(
      state,
      { payload: { locale } }: PayloadAction<AppLocalizedFetchingActionPayload>
    ) {
      state.fetching[locale] = true;
      state.fetchError[locale] = undefined;
    },
    generalCapabilitiesFetchFailed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<AppLocalizedFetchActionResult<string>>
    ) {
      state.fetching[locale] = false;
      state.fetchError[locale] = data;
    },
  },
});

export const generalCapabilitiesFetchRequested =
  createAction<AppLocalizedFetchActionPayload>(
    'generalCapabilitiesSlice/capabilitiesFetchRequested'
  );

export const {
  reducer: generalCapabilitiesReducer,
  actions: {
    generalCapabilitiesFetchSucceed,
    generalCapabilitiesFetching,
    generalCapabilitiesFetchFailed,
  },
} = generalCapabilitiesSlice;

const selectGeneralCapabilitiesState = (
  state: RootState
): GeneralCapabilitiesState => state.referenceData.generalCapabilities;

export const selectGeneralCapabilities =
  (locale: ApiLocale) =>
  (state: RootState): AppCapability[] | undefined =>
    selectGeneralCapabilitiesState(state).capabilities[locale];

export const selectGeneralCapabilitiesFetching =
  (locale: ApiLocale) =>
  (state: RootState): boolean | undefined =>
    selectGeneralCapabilitiesState(state).fetching[locale];

export const selectGeneralCapabilitiesFetchError =
  (locale: ApiLocale) =>
  (state: RootState): string | undefined =>
    selectGeneralCapabilitiesState(state).fetchError[locale];

export const selectShouldFetchGeneralCapabilities = (locale: ApiLocale) =>
  createSelector(
    selectGeneralCapabilities(locale),
    selectGeneralCapabilitiesFetching(locale),
    (capabilities, fetching) => !fetching && !capabilities
  );

export const selectGeneralCapability = (id: string, locale: ApiLocale) =>
  createSelector(selectGeneralCapabilities(locale), (capabilities) => {
    return capabilities?.find((capability) => capability.id === id);
  });
