export const homeFeedCollectionPageStrings = {
  labels: {
    createTitle: 'New collection',
    saveButton: 'Save',
    closeButton: 'Close',
    stateButton: 'Status',
    locale: 'Locale',
    appliances: 'Appliance(s)',
    addRecipesButton: 'Add recipes',
    titleField: 'Collection title',
    subtitleField: 'Subtitle',
    recipeTotal: 'Recipe total:',
    selectedRecipes: 'Selected recipes',
  },
  placeholders: {
    titleField: 'Collection title',
    subtitleField: 'Subtitle',
  },
  emptyRecipesList: {
    title: 'Start Adding Recipes',
    text: 'You do not have any recipes added to your collection.',
  },
  errorMessages: {
    titleField: {
      required: 'Collection title is required',
    },
    subtitleField: {
      required: 'Collection subtitle is required',
    },
  },
} as const;
