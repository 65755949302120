import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';

import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { AppCapabilityAllowedSetting } from 'types/appCapabilityAllowedSetting';
import type {
  AppCapabilityAllowedSettingValueNumeric,
  AppCapabilityAllowedSettingValueNumericInSingleSystem,
} from 'types/appCapabilityAllowedSettingValueNumeric';
import type {
  AppCapabilityAllowedSettingDependency,
  AppCapabilityAllowedSettingDependencyAllowedValues,
} from 'types/appCapabilitySettingDependency';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import type { AppRecipeStepCapabilitySetting } from 'types/recipe/appRecipeStepCapabilitySetting';
import { fromAppTimeToSeconds } from 'utils/convertTimes';
import {
  isValueInMultipleSystems,
  isValueInSingleSystem,
} from 'utils/unitSystems';

export type CapabilitySettingFieldDependsOn = Record<
  ApiEntityId,
  AppCapabilityAllowedSettingDependencyAllowedValues
>;

export type CapabilitySettingFieldDependents = Record<
  ApiEntityId,
  {
    setting: AppCapabilityAllowedSetting;
    dependency: AppCapabilityAllowedSettingDependencyAllowedValues;
  }
>;

export type CapabilitySettingFieldDependencies = {
  dependents: Record<ApiEntityId, CapabilitySettingFieldDependents>;
  dependsOn: Record<ApiEntityId, CapabilitySettingFieldDependsOn>;
};

export const generateCapabilitySettingFieldDependsOn = (
  settings?: AppCapabilityAllowedSettingDependency[]
): CapabilitySettingFieldDependsOn | undefined => {
  if (!settings?.length) {
    return undefined;
  }
  return settings.reduce<CapabilitySettingFieldDependsOn>(
    (dependencies, dependency) => {
      return {
        ...dependencies,
        [dependency.referenceSettingId]: dependency.allowedValues,
      };
    },
    {}
  );
};

export const areDependenciesMet = (
  dependencies: CapabilitySettingFieldDependsOn,
  settings: AppRecipeStepCapabilitySetting[]
) =>
  isEmpty(dependencies) ||
  settings.some(({ id, value }) => {
    const type =
      value.type === AppCapabilitySettingType.Time
        ? AppCapabilitySettingType.Numeric
        : value.type;
    const dependency = dependencies[id]?.[type];
    if (dependency === undefined) {
      return false;
    }
    if (value.type === AppCapabilitySettingType.Nominal) {
      return (dependency as ApiEntityId[]).includes(value.referenceValue.id);
    }
    if (value.type === AppCapabilitySettingType.Boolean) {
      return (dependency as boolean) === value.value;
    }
    return (dependency as AppCapabilityAllowedSettingValueNumeric[]).some(
      (restriction) => {
        if (value.type === AppCapabilitySettingType.Time) {
          if (!isValueInSingleSystem(restriction)) {
            throw new Error(
              `Capability setting dependency of type Time has to be defined in a single unit. Passed "${JSON.stringify(restriction)}"`
            );
          }
          if (value.unit.id !== restriction.unit.id) {
            return false;
          }
          return isNumericDependencyMet(
            fromAppTimeToSeconds(value.value),
            restriction
          );
        }

        if (value.type === AppCapabilitySettingType.Numeric) {
          if (
            isValueInSingleSystem(restriction) &&
            isValueInSingleSystem(value.value)
          ) {
            if (value.value.unit.id !== restriction.unit.id) {
              return false;
            }
            return isNumericDependencyMet(value.value.amount ?? 0, restriction);
          }
          if (
            isValueInMultipleSystems(restriction) &&
            isValueInMultipleSystems(value.value)
          ) {
            const { metric, usCustomary } = value.value;
            if (
              metric.unit.id !== restriction.metric.unit.id ||
              usCustomary.unit.id !== restriction.usCustomary.unit.id
            ) {
              return false;
            }
            return (
              isNumericDependencyMet(
                toNumber(metric.amount),
                restriction.metric
              ) &&
              isNumericDependencyMet(
                toNumber(usCustomary.amount),
                restriction.usCustomary
              )
            );
          }
        }

        return false;
      }
    );
  });

const isNumericDependencyMet = (
  value: number,
  { min, max, step }: AppCapabilityAllowedSettingValueNumericInSingleSystem
): boolean => {
  if (min !== undefined && value < min) {
    return false;
  }
  if (max !== undefined && value > max) {
    return false;
  }
  if (step !== undefined && !Number.isInteger(value / step)) {
    return false;
  }
  return true;
};
