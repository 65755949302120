export enum ApiLocale {
  EnUS = 'en-US',
  EnGB = 'en-GB',
  EnAU = 'en-AU',
  EsES = 'es-ES',
  ItIT = 'it-IT',
  DeDE = 'de-DE',
  FrFR = 'fr-FR',
  PlPL = 'pl-PL',
  CsCZ = 'cs-CZ',
  NlNL = 'nl-NL',
  NbNO = 'nb-NO',
  SvSE = 'sv-SE',
  FiFI = 'fi-FI',
  DaDK = 'da-DK',
  PtPT = 'pt-PT',
  RuRU = 'ru-RU',
}
