import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { Alert, alertClasses, Box, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState, memo, useCallback } from 'react';
import type { FileRejection, FileError } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

import { MediaImageType, MediaResourceType } from 'api/media';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { appConfig } from 'config/config';
import {
  mediaUploadRequested,
  selectMediaUploadError,
  selectMediaUploading,
} from 'features/media/mediaSlice';
import { mediaStorage } from 'features/media/mediaStorage';
import { recipeMediaStrings } from 'features/recipe/recipeBasicInformation/recipeMedia/RecipeMedia.constants';
import {
  NoImagesPlaceholder,
  RecipeMediaPreview,
} from 'features/recipe/recipeBasicInformation/recipeMedia/RecipeMediaPreview';
import { selectRecipe } from 'features/recipe/recipeSlice';
import { fromAllowedMediaTypesToText, fromBytesToText } from 'utils/files';
import { generateRecipeHeroImageSource } from 'utils/image';

import { ReactComponent as UploadIcon } from './file-upload.svg';

const allowedMediaTypes = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/webp': ['.webp'],
};

const getErrorMessage = (error: FileError): string => {
  if (error.code === 'file-too-large') {
    return `File is larger than ${fromBytesToText(maxMediaSize)}`;
  }
  return error.message;
};

const maxMediaSize = 5 * 1024 * 1024; // 5MB
const maxFilesToBeUploaded = 1 as const;

const {
  messages,
  image: { maxWidth },
  fields,
} = recipeMediaStrings;

export interface RecipeMediaProps {
  disabled?: boolean;
  onMediaPreviewClick?: () => void;
}

export const RecipeMedia: FC<RecipeMediaProps> = memo(function RecipeMedia({
  disabled,
  onMediaPreviewClick,
}) {
  const dispatch = useAppDispatch();

  const recipe = useAppSelector(selectRecipe);
  const apiError = useAppSelector(selectMediaUploadError);
  const isUploading = useAppSelector(selectMediaUploading);

  const [error, setError] = useState<string | undefined>();

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setError(undefined);

      // For convenience and simplification, we only return the first error
      if (fileRejections.length > 0) {
        setError(getErrorMessage(fileRejections[0].errors[0]));
        return;
      }

      acceptedFiles.forEach((file) => {
        mediaStorage.add(file);
        // On edit, resource (recipe) already exists so we want to immediately upload the image to the server
        // On create, we need to first create the recipe and then upload the image and associate it to the recipe
        if (recipe.id) {
          dispatch(
            mediaUploadRequested({
              resourceType: MediaResourceType.Recipes,
              resourceId: recipe.id,
              imageType: MediaImageType.Hero,
            })
          );
        }
      });
    },
    [dispatch, recipe]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: allowedMediaTypes,
    maxFiles: maxFilesToBeUploaded,
    maxSize: maxMediaSize,
    onDrop,
    /** Playwright event doesn't work with useFsAccessApi.
     * @see {@link https://github.com/microsoft/playwright/issues/12369#issuecomment-1054162213}
     * and the open bug {@link https://github.com/microsoft/playwright/issues/8850} */
    useFsAccessApi: !appConfig.isE2ETestEnv(),
  });

  useEffect(() => {
    setError(apiError);
  }, [apiError]);

  const previewSrc = ((): string | undefined => {
    const image = mediaStorage.get();
    if (image) {
      return image.preview;
    }
    if (recipe.id) {
      return generateRecipeHeroImageSource({
        recipeId: recipe.id,
        width: maxWidth,
      }).src;
    }
    return undefined;
  })();

  return (
    <>
      {!disabled && (
        <Box {...getRootProps({ 'aria-label': 'drop zone' })}>
          <input {...getInputProps()} />
          <Box
            sx={{
              boxSizing: 'border-box',
              backgroundColor: PantryColor.SurfaceMuted,
              border: '1px dashed',
              borderColor: PantryColor.BorderDefault,
              borderRadius: 1,
              cursor: 'pointer',
              pt: 14,
              pb: 12,
              px: 32,
              textAlign: 'center',
            }}
          >
            <UploadIcon />
            <Typography
              variant={PantryTypography.Body1}
              color={PantryColor.TextDefault}
              sx={{ my: 1 }}
            >
              {fields.file}
            </Typography>
            <Typography
              variant={PantryTypography.Body2}
              sx={{ color: PantryColor.TextSubtle }}
            >
              Images must be in {fromAllowedMediaTypesToText(allowedMediaTypes)}{' '}
              format, with a maximum size of {fromBytesToText(maxMediaSize)}.
            </Typography>
          </Box>
        </Box>
      )}
      {error && (
        <Alert
          onClose={() => {
            setError(undefined);
          }}
          severity="error"
          sx={{
            typography: PantryTypography.Body2SemiBold,
            mt: 4,
            [`& .${alertClasses.action}`]: {
              padding: 0,
            },
          }}
        >
          {messages.fileError}
          <br />
          {error}
        </Alert>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: !disabled ? 6 : 0,
        }}
      >
        {previewSrc ? (
          <RecipeMediaPreview
            src={previewSrc}
            uploading={isUploading}
            onClick={onMediaPreviewClick}
          />
        ) : (
          <NoImagesPlaceholder />
        )}
      </Box>
    </>
  );
});
