import { ApiLocale } from 'api/types/common/apiLocale';

export const appliancesListConstants = {
  image: {
    width: 56,
    height: 56,
  },
  table: {
    ariaLabel: 'appliances list',
    skeletonAriaLabel: 'list loader',
    rowHeight: 92,
    emptyCell: '--',
    locale: ApiLocale.EnUS,
    columns: {
      mainInfo: {
        sortable: false,
        minWidth: 200,
        flex: 1,
      },
      id: {
        sortable: false,
        width: 320,
      },
      partner: {
        sortable: false,
        width: 160,
      },
      tags: {
        sortable: false,
        width: 300,
      },
    },
  },
} as const;

export const appliancesListStrings = {
  headers: {
    mainInfo: 'Appliance',
    id: 'ID',
    partner: 'Partner',
    tags: 'Tags',
  },
} as const;
