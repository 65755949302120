import { type ApiAplCapabilityAttachment } from 'api/types/appliance/apiAplCapabilityAttachment';

const attachmentsToText = (attachments: ApiAplCapabilityAttachment[]) => {
  const names = attachments.map(({ name }) => name);
  if (names.length === 0) {
    return '';
  }

  if (names.length === 1) {
    return names[0];
  }

  const lastItem = names.pop();
  const joined = names.join(', ');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return `${joined} or ${lastItem!}`;
};

export const generateCapabilityAttachmentsHint = (
  attachments: ApiAplCapabilityAttachment[][]
) =>
  `Attachments: ${attachments.map((set) => attachmentsToText(set)).join(', ')}`;
