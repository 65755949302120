import round from 'lodash/round';
import sortBy from 'lodash/sortBy';

import { calculateIndexAfterReordering } from 'components/DragAndDrop/DragZone';
import type { AppRecipeStepIngredient } from 'types/recipe/appRecipeStepIngredient';

const reassignIngredientIdxAfterDeleting =
  (deletedIngredientIdx: number) =>
  (ingredient: AppRecipeStepIngredient): AppRecipeStepIngredient => ({
    ...ingredient,
    ingredientIdx:
      ingredient.ingredientIdx > deletedIngredientIdx
        ? ingredient.ingredientIdx - 1
        : ingredient.ingredientIdx,
  });

export const areIngredientsSortedByIngredientIdx = (
  ingredients: AppRecipeStepIngredient[]
) =>
  ingredients.every((current, i) => {
    const previous = ingredients[i - 1];
    return !previous || previous.ingredientIdx < current.ingredientIdx;
  });

export const sortIngredientsByIngredientIdx = (
  ingredients: AppRecipeStepIngredient[]
) => {
  const sortingProp: keyof AppRecipeStepIngredient = 'ingredientIdx';
  return sortBy(ingredients, sortingProp);
};

export const updateStepIngredientsAfterDeleting = <
  T extends AppRecipeStepIngredient[] | undefined,
>(
  ingredients: T,
  deletedIngredientIdx: number
) => {
  if (!ingredients || !ingredients.length) {
    return ingredients;
  }
  return ingredients
    .filter(
      ({ ingredientIdx }: AppRecipeStepIngredient) =>
        ingredientIdx !== deletedIngredientIdx
    )
    .map(reassignIngredientIdxAfterDeleting(deletedIngredientIdx));
};

export const updateStepIngredientsAfterMoving = <
  T extends AppRecipeStepIngredient[] | undefined,
>(
  ingredients: T,
  from: number,
  to: number
) => {
  if (!ingredients || !ingredients.length) {
    return ingredients;
  }

  const wereIngredientsSorted =
    areIngredientsSortedByIngredientIdx(ingredients);

  const reassignedIngredientIndexes = ingredients.map((ingredient) => ({
    ...ingredient,
    ingredientIdx: calculateIndexAfterReordering(
      ingredient.ingredientIdx,
      from,
      to
    ),
  }));

  return wereIngredientsSorted
    ? sortIngredientsByIngredientIdx(reassignedIngredientIndexes)
    : reassignedIngredientIndexes;
};

export const roundIngredientAmountLeft = (amountLeft: number) =>
  round(amountLeft, 3);
