import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import type { ChipProps as MuiChipProps } from '@mui/material';
import { Chip as MuiChip } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

export enum ChipStyle {
  Default = 'default',
  Muted = 'muted',
}

export interface ChipProps extends Omit<MuiChipProps, 'style'> {
  style?: ChipStyle;
}

export const Chip: FC<ChipProps> = memo(function Chip({ label, style }) {
  const color = (() => {
    if (style === ChipStyle.Muted) {
      return PantryColor.TextMuted;
    }
    return PantryColor.TextDefault;
  })();

  return (
    <MuiChip
      sx={{
        mr: 1,
        mb: 1,
        color,
        typography: PantryTypography.Body2,
        backgroundColor: PantryColor.ActionMuted,
      }}
      label={label}
    />
  );
});
