import { Skeleton, SkeletonVariant } from '@dropkitchen/pantry-react';
import type { FC } from 'react';
import { memo } from 'react';

import { recipeBasicInformationStrings } from 'features/recipe/recipeBasicInformation/RecipeBasicInformation.constants';
import { RecipeBasicInformationFormContainer } from 'features/recipe/recipeBasicInformation/RecipeBasicInformationFormContainer';

interface FieldSkeletonProps {
  height?: number;
}

const FieldSkeleton: FC<FieldSkeletonProps> = memo(function TextFieldSkeleton({
  height = 56,
}) {
  return <Skeleton variant={SkeletonVariant.Small} sx={{ height }} />;
});

const { ariaLabel } = recipeBasicInformationStrings;

export const RecipeBasicInformationFormSkeleton: FC = memo(
  function RecipeBasicInformationFormSkeleton() {
    return (
      <RecipeBasicInformationFormContainer
        ariaLabel={ariaLabel.skeleton}
        components={{
          recipeName: <FieldSkeleton />,
          description: <FieldSkeleton height={125} />,
          author: <FieldSkeleton height={80} />,
          applianceTags: <FieldSkeleton />,
          totalTime: <FieldSkeleton />,
          prepTime: <FieldSkeleton />,
          cookTime: <FieldSkeleton />,
          serves: <FieldSkeleton />,
        }}
      />
    );
  }
);
