import { Skeleton, SkeletonVariant } from '@dropkitchen/pantry-react';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

export interface RecipePageListSkeletonProps {
  title: string;
}

export const RecipePageListSkeleton: FC<RecipePageListSkeletonProps> = memo(
  function RecipePageListSkeleton({ title }) {
    return (
      <Box aria-label={`Loading "${title}"`}>
        <Skeleton
          variant={SkeletonVariant.Small}
          sx={{ height: 76, borderRadius: 1, mb: 6 }}
        />
        <Skeleton
          variant={SkeletonVariant.Small}
          sx={{ height: 76, borderRadius: 1, mb: 6 }}
        />
        <Skeleton
          variant={SkeletonVariant.Small}
          sx={{ height: 76, borderRadius: 1, mb: 6 }}
        />
      </Box>
    );
  }
);
