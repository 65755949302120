import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createAction, createSlice } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiMeasurementSystem } from 'api/types/common/apiMeasurementSystem';
import { ApiMeasurementSystemId } from 'api/types/common/apiMeasurementSystem';
import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import type { RootState } from 'app/store/rootReducer';
import type { AppLocalizedData } from 'types/appLocalizedData';
import type { AppLocalizedFetchActionPayload } from 'types/appLocalizedFetchActionPayload';
import type { AppLocalizedFetchActionResult } from 'types/appLocalizedFetchActionResult';
import type { AppLocalizedFetchingActionPayload } from 'types/appLocalizedFetchingActionPayload';

export interface MeasurementSystemsState {
  fetchError: AppLocalizedData<string>;
  isFetching: AppLocalizedData<boolean>;
  metric: AppLocalizedData<ApiMeasurementSystem>;
  usCustomary: AppLocalizedData<ApiMeasurementSystem>;
}

export const initialState: MeasurementSystemsState = {
  isFetching: {},
  fetchError: {},
  metric: {},
  usCustomary: {},
};

export const measurementSystemsSlice = createSlice({
  name: 'measurementSystemsSlice',
  initialState,
  reducers: {
    measurementSystemsFetching(
      state,
      { payload: { locale } }: PayloadAction<AppLocalizedFetchingActionPayload>
    ) {
      state.isFetching[locale] = true;
      state.fetchError[locale] = undefined;
    },
    measurementSystemsFetchSucceed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<AppLocalizedFetchActionResult<ApiMeasurementSystem[]>>
    ) {
      state.metric[locale] = data.find(
        ({ id }) => id === ApiMeasurementSystemId.Metric
      );
      state.usCustomary[locale] = data.find(
        ({ id }) => id === ApiMeasurementSystemId.UsCustomary
      );
      state.isFetching[locale] = false;
      state.fetchError[locale] = undefined;
    },

    measurementSystemsFetchFailed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<AppLocalizedFetchActionResult<string>>
    ) {
      state.isFetching[locale] = false;
      state.fetchError[locale] = data;
    },
  },
});

export const measurementSystemsFetchRequested =
  createAction<AppLocalizedFetchActionPayload>(
    'measurementSystemsSlice/measurementSystemsFetchRequested'
  );

export const {
  reducer: measurementSystemsReducer,
  actions: {
    measurementSystemsFetching,
    measurementSystemsFetchSucceed,
    measurementSystemsFetchFailed,
  },
} = measurementSystemsSlice;

const selectMeasurementSystemsState = (
  state: RootState
): MeasurementSystemsState => state.referenceData.measurementSystems;

export const selectMetricMeasurementSystem =
  (locale: ApiLocale) =>
  (state: RootState): ApiMeasurementSystem | undefined =>
    selectMeasurementSystemsState(state).metric[locale];

export const selectUsCustomaryMeasurementSystem =
  (locale: ApiLocale) =>
  (state: RootState): ApiMeasurementSystem | undefined =>
    selectMeasurementSystemsState(state).usCustomary[locale];

export const selectMetricMeasurementSystemUnits = (locale: ApiLocale) =>
  createSelector(
    selectMetricMeasurementSystem(locale),
    (metric): ApiQuantityUnit[] => metric?.units || []
  );

export const selectMetricMeasurementSystemUnitsDictionary = (
  locale: ApiLocale
) =>
  createSelector(
    selectMetricMeasurementSystemUnits(locale),
    (units): MeasurementSystemUnitsDictionary => keyBy(units, (unit) => unit.id)
  );

export const selectUsCustomaryMeasurementSystemUnits = (locale: ApiLocale) =>
  createSelector(
    selectUsCustomaryMeasurementSystem(locale),
    (usCustomary): ApiQuantityUnit[] => usCustomary?.units || []
  );

export const selectUsCustomaryMeasurementSystemUnitsDictionary = (
  locale: ApiLocale
) =>
  createSelector(
    selectUsCustomaryMeasurementSystemUnits(locale),
    (units): MeasurementSystemUnitsDictionary => keyBy(units, (unit) => unit.id)
  );

export const selectMeasurementSystemIsFetching =
  (locale: ApiLocale) =>
  (state: RootState): boolean =>
    !!selectMeasurementSystemsState(state).isFetching[locale];

export const selectShouldFetchMeasurementSystems = (locale: ApiLocale) =>
  createSelector(
    selectMetricMeasurementSystem(locale),
    selectUsCustomaryMeasurementSystem(locale),
    selectMeasurementSystemIsFetching(locale),
    (metric, usCustomary, isFetching) =>
      (!metric || !usCustomary) && !isFetching
  );

type MeasurementSystemUnitsDictionary = Record<
  ApiQuantityUnit['id'],
  ApiQuantityUnit
>;

export interface MeasurementSystemsUnitsDictionary {
  metric: MeasurementSystemUnitsDictionary;
  usCustomary: MeasurementSystemUnitsDictionary;
}
