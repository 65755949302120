export const appRouteParams = {
  recipeId: 'recipeId',
  recipeTab: 'tab',
  recipeLocale: 'recipeLocale',
  applianceId: 'applianceId',
  homeFeedLocale: 'locale',
  collectionId: 'collectionId',
} as const;

export const appSearchParams = {
  autofocusedFieldId: 'autofocused-field-id',
} as const;

export enum RecipeType {
  Core = 'core',
  Translation = 'translation',
}

export const appRecipeRoutes = {
  root: `recipes`,
  list: {
    [RecipeType.Core]: RecipeType.Core,
    [RecipeType.Translation]: RecipeType.Translation,
  },
  create: {
    root: `create`,
    tab: `create/:${appRouteParams.recipeTab}`,
    default: `create/`,
  },
  edit: {
    root: `:${appRouteParams.recipeId}`,
    tab: `:${appRouteParams.recipeId}/:${appRouteParams.recipeTab}`,
  },
  translate: {
    root: `translate/:${appRouteParams.recipeId}/:${appRouteParams.recipeLocale}`,
  },
} as const;

export const appAppliancesRoutes = {
  root: `appliances`,
  edit: {
    root: `:${appRouteParams.applianceId}`,
  },
} as const;

export const appHomeFeedRoutes = {
  root: 'home-feed',
  tab: `:${appRouteParams.homeFeedLocale}`,
  get preview() {
    return `${this.tab}/preview`;
  },
  create: {
    root: 'collection/create',
  },
  edit: {
    root: `collection/:${appRouteParams.collectionId}`,
  },
} as const;
