import {
  AnimatedSizeContainer,
  AnimatedSizeContainerMode,
  Banner,
  BannerStatus,
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  PantryTypography,
} from '@dropkitchen/pantry-react';
import { Box, Fade, Grid, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo, useEffect, useMemo } from 'react';

import type { ApiCcCuratedCollectionRecipe } from 'api/types/curatedCollection/apiCcCuratedCollectionRecipe';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { Dialog } from 'components/Dialog/Dialog';
import { EmptyContent } from 'components/EmptyContent/EmptyContent';
import { localeFieldStrings } from 'components/LocaleField/LocaleField.constants';
import { ReadOnlyField } from 'components/ReadOnlyField/ReadOnlyField';
import {
  RecipesAdvancedSearchFilterType,
  RecipesAdvancedSearchKey,
  RecipesAdvancedSearchOperator,
} from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.constants';
import type {
  RecipesAdvancedSearchAnchoredFilter,
  RecipesAdvancedSearchAppliancesTagsFilter,
  RecipesAdvancedSearchLocaleFilter,
} from 'components/RecipesSearch/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.types';
import { RecipesSearch } from 'components/RecipesSearch/RecipesSearch';
import {
  recipesSearchReset,
  selectRecipesAppliedFilters,
  selectRecipesSearchTerm,
} from 'components/RecipesSearch/recipesSearchSlice';
import { homeFeedConstants } from 'features/homeFeed/HomeFeed/HomeFeed.constants';
import { homeFeedAddCollectionRecipesDialogStrings } from 'features/homeFeed/HomeFeedAddCollectionRecipesDialog/HomeFeedAddCollectionRecipesDialog.constants';
import {
  homeFeedAddCollectionRecipesDialogClosed,
  homeFeedAddCollectionRecipesDialogFetchRecipesPaginated,
  homeFeedAddCollectionRecipesDialogFetchRecipesRequested,
  homeFeedAddCollectionRecipesDialogSelectedRecipesChanged,
  selectHomeFeedAddCollectionRecipesDialogIsFetching,
  selectHomeFeedAddCollectionRecipesDialogIsOpen,
  selectHomeFeedAddCollectionRecipesDialogPage,
  selectHomeFeedAddCollectionRecipesDialogRecipes,
  selectHomeFeedAddCollectionRecipesDialogRowCount,
  selectHomeFeedAddCollectionRecipesDialogRowsPerPage,
  selectHomeFeedAddCollectionRecipesDialogSelectedRecipes,
} from 'features/homeFeed/HomeFeedAddCollectionRecipesDialog/homeFeedAddCollectionRecipesDialogSlice';
import { selectHomeFeedCollection } from 'features/homeFeed/HomeFeedCollectionPage/homeFeedCollectionSlice';
import { HomeFeedCollectionRecipes } from 'features/homeFeed/HomeFeedCollectionRecipes/HomeFeedCollectionRecipes';
import { HomeFeedCollectionRecipesColumnField } from 'features/homeFeed/HomeFeedCollectionRecipes/HomeFeedCollectionRecipes.constants';
import { generateRecipeTotalMessage } from 'features/homeFeed/homeFeeds.utils';
import { ReactComponent as NoRecipesIcon } from 'features/recipes/no-recipes.svg';
import { appCommonStrings } from 'types/appCommon.constants';
import { appDisplayCodeByLocale } from 'types/appDisplayCodeByLocale';
import type { AppNonNullable } from 'types/appNonNullable';

const { title, labels, emptySearch, limitReached } =
  homeFeedAddCollectionRecipesDialogStrings;
const { recipeTotalLimit } = homeFeedConstants;

export interface HomeFeedAddCollectionRecipesDialogProps {
  onSubmit: (selectedRecipeIds: ApiCcCuratedCollectionRecipe[]) => void;
}

export const HomeFeedAddCollectionRecipesDialog: FC<HomeFeedAddCollectionRecipesDialogProps> =
  memo(function HomeFeedAddCollectionRecipesDialog({ onSubmit }) {
    const dispatch = useAppDispatch();

    const { locale, applianceTags } = useAppSelector(selectHomeFeedCollection)!;
    const page = useAppSelector(selectHomeFeedAddCollectionRecipesDialogPage);
    const rowsPerPage = useAppSelector(
      selectHomeFeedAddCollectionRecipesDialogRowsPerPage
    );
    const rowCount = useAppSelector(
      selectHomeFeedAddCollectionRecipesDialogRowCount
    );
    const searchTerm = useAppSelector(selectRecipesSearchTerm);
    const appliedFilters = useAppSelector(selectRecipesAppliedFilters);
    const recipes = useAppSelector(
      selectHomeFeedAddCollectionRecipesDialogRecipes
    );
    const isOpen = useAppSelector(
      selectHomeFeedAddCollectionRecipesDialogIsOpen
    );
    const isFetching = useAppSelector(
      selectHomeFeedAddCollectionRecipesDialogIsFetching
    );
    const selectedRecipes = useAppSelector(
      selectHomeFeedAddCollectionRecipesDialogSelectedRecipes
    );

    const anchoredFilters: RecipesAdvancedSearchAnchoredFilter[] =
      useMemo(() => {
        const localeFilter: AppNonNullable<
          RecipesAdvancedSearchLocaleFilter<RecipesAdvancedSearchFilterType.Anchored>
        > = {
          key: RecipesAdvancedSearchKey.Locale,
          operator: RecipesAdvancedSearchOperator.AnyOf,
          value: [{ id: locale, name: appDisplayCodeByLocale[locale] }],
          type: RecipesAdvancedSearchFilterType.Anchored,
        };

        if (!applianceTags) {
          return [localeFilter];
        }

        const applianceTagsFilter: AppNonNullable<
          RecipesAdvancedSearchAppliancesTagsFilter<RecipesAdvancedSearchFilterType.Anchored>
        > = {
          key: RecipesAdvancedSearchKey.ApplianceTags,
          operator: RecipesAdvancedSearchOperator.OneOrMoreOf,
          value: applianceTags,
          type: RecipesAdvancedSearchFilterType.Anchored,
        };

        return [localeFilter, applianceTagsFilter];
      }, [applianceTags, locale]);

    useEffect(() => {
      if (isOpen) {
        dispatch(homeFeedAddCollectionRecipesDialogFetchRecipesRequested());
      }
    }, [dispatch, searchTerm, appliedFilters, page, rowsPerPage, isOpen]);

    useEffect(() => {
      if (!isOpen) {
        dispatch(recipesSearchReset());
      }
    }, [dispatch, isOpen]);

    const isRecipeLimitReached = selectedRecipes.length >= recipeTotalLimit;

    return (
      <Dialog
        title={title}
        isOpen={isOpen}
        onClose={() => dispatch(homeFeedAddCollectionRecipesDialogClosed())}
        onSubmit={() => {
          dispatch(homeFeedAddCollectionRecipesDialogClosed());
          onSubmit(selectedRecipes);
        }}
        size="md"
        components={{
          content: (
            <AnimatedSizeContainer mode={AnimatedSizeContainerMode.Height}>
              <Grid container sx={{ maxHeight: '670px' }}>
                <Grid item xs={12} container gap={6}>
                  <Grid item>
                    <ReadOnlyField
                      label={labels.locale}
                      value={localeFieldStrings.getOptionText(locale)}
                    />
                  </Grid>
                  <Grid item>
                    <ReadOnlyField
                      label={labels.appliances}
                      value={
                        applianceTags?.length
                          ? applianceTags.map((tag) => tag.name).join(', ')
                          : appCommonStrings.placeholders.noValue
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mt: 8 }}>
                  <RecipesSearch
                    anchoredFilters={anchoredFilters}
                    filtersLocale={locale}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}
                >
                  <Typography variant={PantryTypography.Body2}>
                    {labels.recipeTotal}&nbsp;
                    {generateRecipeTotalMessage(selectedRecipes.length)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ maxHeight: '350px', overflowY: 'auto', mt: 3 }}
                >
                  <HomeFeedCollectionRecipes
                    ariaLabel={labels.recipeList}
                    recipes={recipes}
                    selectedRecipes={selectedRecipes}
                    isCheckboxSelectionDisabled={isRecipeLimitReached}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowCount={rowCount}
                    isLoading={isFetching}
                    hideColumns={[
                      HomeFeedCollectionRecipesColumnField.Order,
                      HomeFeedCollectionRecipesColumnField.TotalTime,
                      HomeFeedCollectionRecipesColumnField.Action,
                    ]}
                    onPaginationChange={(event) =>
                      dispatch(
                        homeFeedAddCollectionRecipesDialogFetchRecipesPaginated(
                          event
                        )
                      )
                    }
                    onSelectedRecipesChange={(recipeIds: ApiRcpRecipeId[]) => {
                      dispatch(
                        homeFeedAddCollectionRecipesDialogSelectedRecipesChanged(
                          {
                            // Limit can be reached when user click on the "select all" checkbox
                            recipeIds: recipeIds.slice(0, recipeTotalLimit),
                          }
                        )
                      );
                    }}
                    components={{
                      /* eslint-disable @typescript-eslint/naming-convention */
                      NoRecipes: EmptySearch,
                      /* eslint-enable @typescript-eslint/naming-convention */
                    }}
                  />
                </Grid>
                <Fade
                  in={isRecipeLimitReached}
                  mountOnEnter
                  unmountOnExit
                  easing={{ enter: 'ease-in', exit: 'ease-out' }}
                  timeout={300}
                >
                  <Grid item xs={12} sx={{ mt: 4 }}>
                    <Banner
                      status={BannerStatus.Information}
                      label={limitReached.label}
                      description={limitReached.description}
                    />
                  </Grid>
                </Fade>
              </Grid>
            </AnimatedSizeContainer>
          ),
          buttons: (
            <>
              <Button
                label={labels.cancelButton}
                buttonStyle={ButtonStyle.Default}
                size={ButtonSize.Large}
                onClick={() =>
                  dispatch(homeFeedAddCollectionRecipesDialogClosed())
                }
              />
              <Button
                label={labels.saveButton}
                type={ButtonType.Submit}
                buttonStyle={ButtonStyle.Emphasis}
                size={ButtonSize.Large}
                disabled={!selectedRecipes.length}
              />
            </>
          ),
        }}
      />
    );
  });

const EmptySearch: FC = memo(function EmptySearch() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <EmptyContent
        sx={{ maxWidth: '530px' }}
        components={{ image: <NoRecipesIcon /> }}
        text={emptySearch.text}
        title={emptySearch.title}
      />
    </Box>
  );
});
