import type { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import type { ApiRequestSagaReturnType } from 'api/createApiRequestSaga';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
import {
  apiPostCuratedCollection,
  apiPutCuratedCollection,
  apiGetCuratedCollection,
  apiDeleteCuratedCollection,
} from 'api/curatedCollections';
import type { ApiResponse } from 'api/types';
import { ApiErrorType } from 'api/types/common/apiError';
import type { ApiCcCuratedCollection } from 'api/types/curatedCollection/apiCcCuratedCollection';
import type { ApiCcCuratedCollectionId } from 'api/types/curatedCollection/apiCcCuratedCollectionId';
import { ApiCcCuratedCollectionState } from 'api/types/curatedCollection/apiCcCuratedCollectionState';
import type { ApiCcCuratedCollectionWithRefs } from 'api/types/curatedCollection/apiCcCuratedCollectionWithRefs';
import type { ApiHfHomeFeed } from 'api/types/homeFeed/apiHfHomeFeed';
import {
  generateHomeFeedCollectionRoute,
  navigateSaga,
} from 'app/routes/routesUtils';
import { errorOccurred } from 'features/error/errorSlice';
import { errorSliceConstants } from 'features/error/errorSlice.constants';
import {
  apiFetchHomeFeedSaga,
  apiPutHomeFeedSaga,
  fetchCollectionsRecursively,
} from 'features/homeFeed/HomeFeed/homeFeedSagas';
import {
  homeFeedDeleteCollectionFailed,
  homeFeedDeleteCollectionRequested,
  homeFeedDeleteCollectionSucceeded,
  selectHomeFeed,
} from 'features/homeFeed/HomeFeed/homeFeedSlice';
import { homeFeedCollectionSagasStrings } from 'features/homeFeed/HomeFeedCollectionPage/homeFeedCollectionSagas.constants';
import {
  homeFeedCollectionFetchFailed,
  homeFeedCollectionFetchRequested,
  homeFeedCollectionFetchSucceed,
  homeFeedCollectionSaveFailed,
  homeFeedCollectionSaveRequested,
  homeFeedCollectionSaveSucceed,
  selectHomeFeedCollection,
  selectHasFeedCollectionErrors,
  selectHomeFeedCollectionId,
} from 'features/homeFeed/HomeFeedCollectionPage/homeFeedCollectionSlice';
import { ToastDataType, toastEnqueued } from 'features/toasts/toastsSlice';
import type { AppCcCuratedCollection } from 'types/curatedCollection/appCcCuratedCollection';

export const apiPostHomeFeedCollectionSaga = createApiRequestSaga(
  apiPostCuratedCollection
);
export const apiPutHomeFeedCollectionSaga = createApiRequestSaga(
  apiPutCuratedCollection
);
export const apiGetHomeFeedCollectionSaga = createApiRequestSaga(
  apiGetCuratedCollection
);
export const apiDeleteHomeFeedCollectionSaga = createApiRequestSaga(
  apiDeleteCuratedCollection
);

export function* saveHomeFeedCollection() {
  const hasErrors = (yield select(selectHasFeedCollectionErrors)) as ReturnType<
    typeof selectHasFeedCollectionErrors
  >;

  if (hasErrors) {
    yield put(homeFeedCollectionSaveFailed(''));
    return;
  }

  const collectionId = (yield select(
    selectHomeFeedCollectionId
  )) as AppCcCuratedCollection;
  const isEdit = !!collectionId;

  if (isEdit) {
    yield call(editCuratedCollection);
    return;
  }

  yield call(createCuratedCollection);
}

function* homeFeedCollectionSaveWatcher() {
  yield takeEvery(homeFeedCollectionSaveRequested, saveHomeFeedCollection);
}

export function* createCuratedCollection() {
  const { title, subtitle, applianceTags, recipes, locale } = (yield select(
    selectHomeFeedCollection
  )) as AppCcCuratedCollection;

  const applianceTagIds = applianceTags.map(({ id }) => id);
  const recipeIds = recipes.map(({ id }) => id);
  const state = ApiCcCuratedCollectionState.Draft;
  const body: ApiCcCuratedCollectionWithRefs = {
    title,
    subtitle,
    locale,
    applianceTagIds,
    recipeIds,
    state,
  };

  const createCollectionResponse = (yield call(
    apiPostHomeFeedCollectionSaga,
    body
  )) as ApiRequestSagaReturnType<typeof apiPostHomeFeedCollectionSaga>;

  if (!createCollectionResponse.ok) {
    yield put(
      homeFeedCollectionSaveFailed(createCollectionResponse.details.message)
    );
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  const getHomeFeedResponse = (yield call(fetchCollectionsRecursively, {
    locale,
  })) as ApiResponse<ApiHfHomeFeed>;

  if (!getHomeFeedResponse.ok) {
    yield put(
      homeFeedCollectionSaveFailed(getHomeFeedResponse.details.message)
    );
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  const addedCollections: Pick<ApiCcCuratedCollection, 'id'>[] = [
    { id: createCollectionResponse.data.id },
    ...getHomeFeedResponse.data.items.map(({ id }) => ({
      id,
    })),
  ];

  const updateHomeFeedResponse = (yield call(apiPutHomeFeedSaga, {
    locale,
    etag: getHomeFeedResponse.data.etag,
    homeFeed: {
      items: addedCollections,
    },
  })) as ApiRequestSagaReturnType<typeof apiPutHomeFeedSaga>;

  if (!updateHomeFeedResponse.ok) {
    yield put(
      homeFeedCollectionSaveFailed(updateHomeFeedResponse.details.message)
    );
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  yield put(
    homeFeedCollectionSaveSucceed({
      etag: createCollectionResponse.data.etag,
      modifiedAt: createCollectionResponse.data.modifiedAt,
    })
  );

  yield call(navigateSaga, {
    to: generateHomeFeedCollectionRoute({
      id: createCollectionResponse.data.id,
    }),
  });
}

export function* editCuratedCollection() {
  const {
    title,
    subtitle,
    recipes,
    state,
    id: collectionId,
    etag,
  } = (yield select(selectHomeFeedCollection)) as AppCcCuratedCollection;

  if (!collectionId || !etag) {
    throw new Error(
      `Error updating the collection. ID (${collectionId}) or etag (${etag}) missing`
    );
  }

  const recipeIds = recipes.map(({ id }) => id);

  const editCollectionResponse = (yield call(apiPutHomeFeedCollectionSaga, {
    id: collectionId,
    body: {
      title,
      subtitle,
      recipeIds,
      state,
    },
    etag,
  })) as ApiRequestSagaReturnType<typeof apiPutHomeFeedCollectionSaga>;

  if (!editCollectionResponse.ok) {
    yield put(
      homeFeedCollectionSaveFailed(editCollectionResponse.details.message)
    );
    const { platformError } = editCollectionResponse.details;
    yield put(
      errorOccurred(
        platformError?.error.type === ApiErrorType.PreconditionFailed
          ? {
              description:
                homeFeedCollectionSagasStrings.errors.precondition.title,
              message:
                homeFeedCollectionSagasStrings.errors.precondition.message,
            }
          : errorSliceConstants.genericError
      )
    );
    return;
  }

  yield put(
    homeFeedCollectionSaveSucceed({
      etag: editCollectionResponse.data.etag,
      modifiedAt: editCollectionResponse.data.modifiedAt,
    })
  );
}

export function* fetchCuratedCollection({
  payload: id,
}: PayloadAction<string>) {
  const response = (yield call(apiGetHomeFeedCollectionSaga, {
    id,
  })) as ApiRequestSagaReturnType<typeof apiGetHomeFeedCollectionSaga>;

  if (!response.ok) {
    yield put(homeFeedCollectionFetchFailed(response.details.message));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  yield put(homeFeedCollectionFetchSucceed(response.data));
}

function* homeFeedCollectionFetchWatcher() {
  yield takeEvery(homeFeedCollectionFetchRequested, fetchCuratedCollection);
}

export function* unpublishCuratedCollection(id: ApiCcCuratedCollectionId) {
  const fetchCollectionResponse = (yield call(apiGetHomeFeedCollectionSaga, {
    id,
  })) as ApiRequestSagaReturnType<typeof apiGetHomeFeedCollectionSaga>;
  if (!fetchCollectionResponse.ok) {
    throw new Error(fetchCollectionResponse.details.message);
  }

  if (
    fetchCollectionResponse.data.state === ApiCcCuratedCollectionState.Published
  ) {
    const { title, subtitle, recipes, etag } = fetchCollectionResponse.data;
    const unpublishResponse = (yield call(apiPutHomeFeedCollectionSaga, {
      id,
      body: {
        title,
        subtitle,
        recipeIds: recipes.map(({ id: recipeId }) => recipeId),
        state: ApiCcCuratedCollectionState.Draft,
      },
      etag,
    })) as ApiRequestSagaReturnType<typeof apiPutHomeFeedCollectionSaga>;
    if (!unpublishResponse.ok) {
      throw new Error(unpublishResponse.details.message);
    }
  }
}

export function* deleteCuratedCollection({
  payload: collectionId,
}: ReturnType<typeof homeFeedDeleteCollectionRequested>) {
  try {
    yield call(unpublishCuratedCollection, collectionId);
  } catch (e) {
    yield put(homeFeedDeleteCollectionFailed(collectionId));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  const deleteResponse = (yield call(
    apiDeleteHomeFeedCollectionSaga,
    collectionId
  )) as ApiRequestSagaReturnType<typeof apiDeleteHomeFeedCollectionSaga>;

  if (!deleteResponse.ok) {
    yield put(homeFeedDeleteCollectionFailed(collectionId));
    yield put(errorOccurred(errorSliceConstants.genericError));
    return;
  }

  const currentHomeFeed = (yield select(selectHomeFeed)) as ReturnType<
    typeof selectHomeFeed
  >;
  if (!currentHomeFeed) {
    throw new Error(
      homeFeedCollectionSagasStrings.errors.deleteCollectionNoHomeFeed
    );
  }

  const homeFeedResponse = (yield call(apiFetchHomeFeedSaga, {
    locale: currentHomeFeed.locale,
  })) as ApiRequestSagaReturnType<typeof apiFetchHomeFeedSaga>;

  // If fetching the home feed fails, we don't want to fail the whole delete operation.
  // We show a success message and keep the current etag.
  // If they user tries to reorder the collections, a sync error will be shown. They can refresh the page to reorder.
  const etag = homeFeedResponse.ok
    ? homeFeedResponse.data.etag
    : currentHomeFeed.etag;

  yield put(homeFeedDeleteCollectionSucceeded({ collectionId, etag }));
  yield put(
    toastEnqueued({
      label: homeFeedCollectionSagasStrings.deleteCollectionSuccess,
      type: ToastDataType.Success,
    })
  );
}

export function* homeFeedCollectionDeleteWatcher() {
  yield takeEvery(homeFeedDeleteCollectionRequested, deleteCuratedCollection);
}

export const homeFeedCollectionRestartableSagas = [
  homeFeedCollectionSaveWatcher,
  homeFeedCollectionFetchWatcher,
  homeFeedCollectionDeleteWatcher,
];
