import { Grid } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { useAppSelector } from 'app/store/hooks';
import { selectShowOnlyEditableFields } from 'features/translation/recipeTranslationSlice';

export const RecipeTranslationDetailsSection: FC<{ children: ReactNode[] }> =
  memo(function RecipeTranslationDetailsSection({ children }) {
    const shouldShowOnlyEditableFields = useAppSelector(
      selectShowOnlyEditableFields
    );

    return (
      <Grid container rowGap={4} columnSpacing={6}>
        <Grid item sm={12}>
          {children[0]}
        </Grid>
        {!shouldShowOnlyEditableFields && (
          <Grid item sm={4}>
            {children[1]}
          </Grid>
        )}
        <Grid item sm={!shouldShowOnlyEditableFields ? 8 : 12}>
          {children[2]}
        </Grid>
      </Grid>
    );
  });
